import React, {FC} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import AntDesign from 'react-native-vector-icons/AntDesign';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import FontAwesome5Brands from 'react-native-vector-icons/FontAwesome5';
import {IconProps} from 'react-native-vector-icons/Icon';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import SimpleIcon from 'react-native-vector-icons/SimpleLineIcons';

// eslint-disable-next-line import/order
import CustomIcons from '@/assets/icons';

loadIconFonts();

const providers = {
  simple: SimpleIcon,
  fontAwesome: FontAwesome,
  ant: AntDesign,
  material: MaterialIcons,
  materialCommunity: MaterialCommunityIcons,
  brands: FontAwesome5Brands,
  ionicons: Ionicons,
};

import {loadIconFonts} from '@/components/Icon/iconFontsLoader';
import {useTheme} from '@/theme';
import {IconsKeys} from '@/types/common';
import {Color} from '@/types/theme';

export type IconProvider = keyof typeof providers | 'custom';

export interface IIconProps extends IconProps {
  name: string;
  size?: number;
  style?: StyleProp<ViewStyle>;
  color?: Color;
  provider?: IconProvider;
  fill?: boolean;
}

const Icon: FC<IIconProps> = ({
  name,
  size,
  color = 'textColor',
  style,
  provider = 'simple',
  fill = false,
  ...rest
}) => {
  const theme = useTheme();
  const finalSize = size || 24;
  const finalColor = theme.colors[color];

  if (provider === 'custom') {
    const iconName = fill ? `${name}_fill` : name;
    const CustomIcon = CustomIcons[iconName as IconsKeys];

    return CustomIcon ? (
      <CustomIcon
        width={finalSize}
        height={finalSize}
        fill={finalColor}
        // @ts-ignore
        style={[style, {color: finalColor}]}
        {...rest}
      />
    ) : null;
  }

  const IconComponent = providers[provider];

  return (
    <IconComponent
      name={name}
      size={finalSize}
      color={finalColor}
      style={style}
      {...rest}
    />
  );
};

export default React.memo(Icon);
