import SPACES from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: theme.box.borderRadius,
    borderWidth: theme.box.activeBorderRightWidth,
    marginHorizontal: 'auto',
    maxWidth: '90%',
    justifySelf: 'center',
    padding: SPACES.s,
    flexWrap: 'wrap',

    borderStyle: 'dashed',
    borderColor: theme.colors.borderColor,
  },
  group: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));
