import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {IBaseUser} from '@/types/user';
import {getTrackArtistsNames} from '@/utils/tracks';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

import {styles} from './OwnedTrackTile.style';

interface IProps {
  user: IBaseUser;
  track: ITrack;
}

const OwnedTrackTile: FC<IProps> = ({user, track}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.tiles}>
      <View style={style.userTile}>
        <Avatar
          id={user.id}
          url={getUserAvatar(user)}
          size={36}
          resizeWidth={ImageSize.avatar}
        />
        <Text weight="semibold" flex>
          {getUserDisplayName(user)}
        </Text>
        <Icon name="check" provider="custom" />
      </View>
      <View style={style.trackTileWrapper}>
        <View style={style.trackTile}>
          {track.lossyArtworkUrl && (
            <StaticImage
              source={{uri: track.lossyArtworkUrl}}
              style={style.artwork}
              resizeWidth={ImageSize.thumbnail}
            />
          )}
          <View style={style.trackInfo}>
            <View style={style.trackTitleRow}>
              <Icon name="diamond" provider="custom" size={16} />
              <Text weight="semibold" numberOfLines={1} flex>
                {track.title}
              </Text>
            </View>
            <Text numberOfLines={1} size="xs">
              {getTrackArtistsNames(track)}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default OwnedTrackTile;
