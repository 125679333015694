import React, {FC} from 'react';
import {FormattedRelativeTime} from 'react-intl';
import {View} from 'react-native';

import Icon from '@/components/Icon';
import CollectorLink from '@/components/NftEvent/CollectorLink';
import NftEventAvatars from '@/components/NftEvent/NftEventAvatars';
import NftEventMessage from '@/components/NftEvent/NftEventMessage';
import {useEventInfo} from '@/components/NftEvent/useEventInfo';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';
import {INftEvent} from '@/types/nfts';

import {styles, AVATAR_SIZE, ICON_SIZE} from './TrackNftEvent.style';

interface IProps {
  event: INftEvent;
}

const TrackNftEvent: FC<IProps> = ({event}) => {
  const style = useThemedStyles(styles);
  const {sender, receiver, icon, relativeTimestamp} = useEventInfo(event);
  const eventMainUser = sender || receiver;

  return (
    <Space style={style.container}>
      <NftEventAvatars size={AVATAR_SIZE} participants={[sender, receiver]} />
      <Space w="xs" />
      <Space style={style.content}>
        <View style={style.contentHeader}>
          {eventMainUser && <CollectorLink collector={eventMainUser} />}
          <Text size="xxs">
            <FormattedRelativeTime
              value={relativeTimestamp}
              updateIntervalInSeconds={10}
            />
          </Text>
        </View>
        <Space h="xxs" />
        <View style={style.contentBody}>
          {icon && (
            <Space mr="xxs">
              <Icon size={ICON_SIZE} {...icon} />
            </Space>
          )}
          <View style={style.contentDescription}>
            <NftEventMessage event={event} counterparty={receiver} />
          </View>
        </View>
      </Space>
    </Space>
  );
};

export default React.memo(TrackNftEvent);
