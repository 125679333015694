import React, {FC, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';

import CardButton from '@/components/CardButton';
import Checkbox from '@/components/Checkbox';
import DashedDivider from '@/components/DashedDivider';
import Divider from '@/components/Divider';
import Modal, {IModalProps} from '@/components/Modal';
import ScrollContainer from '@/components/ScrollContainer';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import UploadError from '@/modules/DropOnSpinamp/components/UploadError';
import UploadProcessing from '@/modules/DropOnSpinamp/components/UploadProcessing';
import UploadSuccess from '@/modules/DropOnSpinamp/components/UploadSuccess';
import {IDropForm, UploadMode, UploadStep} from '@/modules/DropOnSpinamp/types';
import {isWalletCancelError} from '@/modules/Transactions';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IUser} from '@/types/user';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './ConfirmationModal.style';

interface IProps extends IModalProps {
  user: IUser;
  artworkUrl: string;
  price: string;
  title: string;
  artist: IDropForm['artist'];
  uploadMode: UploadMode;
  onSubmit: () => void;
  uploadedTrack: ITrack | undefined;
  uploadError: any | undefined;
  clearError: () => void;
  uploadStep: UploadStep | undefined;
}

const ConfirmationModal: FC<IProps> = ({
  user,
  artworkUrl,
  price,
  title,
  artist,
  uploadMode,
  onSubmit,
  uploadedTrack,
  uploadError,
  clearError,
  uploadStep,
  ...modalProps
}) => {
  const style = useThemedStyles(styles);

  const [termsConfirmed, setTermsConfirm] = useState(false);

  // We only care about those values from the moment of opening this modal, so we create a local copy of them.
  const [dropInfo] = useState({
    title,
    artworkUrl,
    price,
    uploadMode,
    artist,
  });

  const artistName =
    dropInfo.artist?.name ||
    user.artistProfile?.name ||
    getUserDisplayName(user);

  return (
    <Modal titleId="dropOnSpinamp.confirm.title" {...modalProps}>
      <ScrollContainer contentStyle={style.content}>
        {(() => {
          if (uploadError && !isWalletCancelError(uploadError)) {
            return <UploadError error={uploadError} reset={clearError} />;
          }

          if (uploadedTrack) {
            return (
              <UploadSuccess
                uploadedTrack={uploadedTrack}
                showNewArtistSection={!!dropInfo.artist}
                onClose={modalProps.onClose}
              />
            );
          }

          if (uploadStep !== undefined) {
            return (
              <UploadProcessing
                user={user}
                title={dropInfo.title}
                artistName={artistName}
                artworkUrl={dropInfo.artworkUrl}
                uploadStep={uploadStep}
              />
            );
          }

          return (
            <>
              <StaticImage
                style={style.image}
                source={{
                  uri: dropInfo.artworkUrl,
                }}
              />

              <View style={style.textsContainer}>
                <Text weight="semibold" align="center">
                  {dropInfo.title}
                </Text>
                <Text align="center">{artistName}</Text>
              </View>

              {dropInfo.uploadMode === 'collectible' && (
                <View style={style.info}>
                  <View style={style.infoRow}>
                    <Text
                      size="xs"
                      weight="semibold"
                      id="dropOnSpinamp.form.price"
                    />
                    <Text size="xs" weight="semibold">
                      {price}
                    </Text>
                  </View>
                  <Divider />
                  <View style={style.infoRow}>
                    <Text
                      size="xs"
                      weight="semibold"
                      id="dropOnSpinamp.confirm.availability"
                    />
                    <Text
                      size="xs"
                      id="dropOnSpinamp.confirm.availability.unlimited"
                    />
                  </View>
                  <Divider />
                  <View style={style.infoRow}>
                    <Text
                      size="xs"
                      weight="semibold"
                      id="dropOnSpinamp.confirm.release"
                    />
                    <Text id="dropOnSpinamp.confirm.release.now" size="xs" />
                  </View>
                </View>
              )}

              <DashedDivider style={style.dashedDivider} />

              <TouchableOpacity
                activeOpacity={0.8}
                onPress={() => setTermsConfirm(v => !v)}
                style={style.termsCheckbox}>
                <Checkbox value={termsConfirmed} />
                <Text id="dropOnSpinamp.confirm.termsCheckbox" size="xs" />
              </TouchableOpacity>

              <CardButton
                disabled={!termsConfirmed}
                onPress={onSubmit}
                text={{id: 'dropOnSpinamp.signAndPublish'}}
              />
              <TransparentButton
                onPress={modalProps.onClose}
                text={{id: 'dropOnSpinamp.confirm.edit', underline: true}}
              />
            </>
          );
        })()}
      </ScrollContainer>
    </Modal>
  );
};

export default ConfirmationModal;
