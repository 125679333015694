import {IArtist} from '@/types/common';
import {IBaseUser} from '@/types/user';

import {useActiveUser} from './useActiveUser';

export const useCanEditArtist = (artist?: IArtist) => {
  const user = useActiveUser();

  const artistId = artist?.id;
  const artistIdOfUser = user?.artistProfile?.id;

  // You can edit an artist if it's associated to the user you're currently logged in as.
  return !!artistId && !!artistIdOfUser && artistId === artistIdOfUser;
};

export const useCanEditCollector = (user?: IBaseUser | null) => {
  const activeUser = useActiveUser();

  return !!user?.id && !!activeUser?.id && user.id === activeUser.id;
};
