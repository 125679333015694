import React, {FC} from 'react';
import {View} from 'react-native';

import Beggar from '@/assets/images/beggar.svg';
import Avatar from '@/components/Avatar';
import CardButton from '@/components/CardButton';
import DashedDivider from '@/components/DashedDivider';
import Icon from '@/components/Icon';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text/Text';
import spacing from '@/constants/spacing';
import {tipChain} from '@/modules/Tip/constants';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useTheme, useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {noop} from '@/utils/functions';
import {getUserDisplayName} from '@/utils/user';

import {AVATAR_SIZE, styles} from './PendingTip.style';

const PendingTip: FC = () => {
  const style = useThemedStyles(styles);
  const theme = useTheme();

  const {user, artist, tipTransaction, paymentWallet} = useSendTipState();
  const {txHash, userOpHash} = tipTransaction;
  const isPasskey = paymentWallet && isPasskeyWallet(paymentWallet);

  return (
    <View style={style.container}>
      <View style={style.content}>
        {user && (
          <View style={style.userTile}>
            <Avatar
              id={user.id}
              url={user.avatarUrl}
              size={AVATAR_SIZE}
              resizeWidth={ImageSize.avatar}
            />
            <Text weight="semibold" numberOfLines={1} flex>
              {getUserDisplayName(user)}
            </Text>
            <SpinLoader size={spacing.l} />
          </View>
        )}

        <View style={style.coins}>
          {new Array(3).fill(0).map((_, index) => (
            <Icon key={index} name="coin" provider="custom" size={20} />
          ))}
        </View>

        <View style={style.beggarContainer}>
          <Beggar
            // @ts-ignore
            style={{color: theme.colors.textColor}}
            height={64}
          />
          <Avatar
            style={style.artistAvatar}
            id={artist.id}
            url={artist.avatarUrl}
            size={20}
            resizeWidth={ImageSize.avatar}
          />
        </View>

        <DashedDivider />

        <View style={style.textsContainer}>
          {txHash || isPasskey ? (
            <Text
              weight="semibold"
              size="s"
              id="transfer.pending.waitingForReceipt"
              align="center"
            />
          ) : (
            <Text
              weight="semibold"
              size="s"
              id="transfer.pending.waitingForSignature"
              align="center"
            />
          )}

          {(txHash || userOpHash) && (
            <View style={style.links}>
              {userOpHash && (
                <View style={style.link}>
                  <JiffyscanLink
                    userOpHash={userOpHash}
                    chainId={tipChain.id}
                  />
                </View>
              )}
              {txHash && (
                <View style={style.link}>
                  <EtherscanLink txHash={txHash} chainId={tipChain.id} />
                </View>
              )}
            </View>
          )}
        </View>
      </View>

      <CardButton
        onPress={noop}
        loaderProps={{textId: 'processing'}}
        isLoading
      />
    </View>
  );
};

export default PendingTip;
