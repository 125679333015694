import {RouteProp, useRoute} from '@react-navigation/native';
import React, {useCallback, useMemo} from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen';
import Space from '@/components/Space';
import Text from '@/components/Text/Text';
import {CollectedTrackCard, TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useAddressCollectionQuery} from '@/queries/collection';
import {useWalletByIdQuery} from '@/queries/wallets';
import {playNewQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {MainStackParams, Routes} from '@/types/routes';
import {prettifyAddress} from '@/utils/ethereum';
import {isAddressOwnedByUser} from '@/utils/user';

import WalletInfo from './components/WalletInfo';
import {styles} from './Wallet.style';

const Wallet = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const {onScroll, scrollPosition} = useAnimatedHeader();

  const {
    params: {id},
  } = useRoute<RouteProp<MainStackParams, Routes.Wallet>>();

  const activeUser = useActiveUser();
  const {wallet: fetchedWallet, query} = useWalletByIdQuery(id);

  const isOwnWallet = useMemo(
    () =>
      !!activeUser &&
      !!fetchedWallet &&
      isAddressOwnedByUser(activeUser, fetchedWallet.address),
    [activeUser, fetchedWallet?.address],
  );

  const {collection, query: collectionQuery} = useAddressCollectionQuery(
    fetchedWallet?.address,
  );

  const {isTrackActive} = useIsTrackActive(
    PlayContextType.walletCollection,
    fetchedWallet?.address,
  );

  const playCollection = useCallback(
    (track?: ITrack) => {
      if (fetchedWallet?.address) {
        const trackIds = collection.map(t => t.id);

        dispatch(
          playNewQueue({
            trackId: track?.id,
            trackIds,
            context: {
              source: 'WalletCollection',
              type: PlayContextType.walletCollection,
              id: fetchedWallet.address,
              title: `${prettifyAddress(
                fetchedWallet.address,
              )} wallet collection`,
            },
          }),
        );
      }
    },
    [collection, fetchedWallet?.address],
  );

  return (
    <Screen>
      <Header
        showBack
        title={
          fetchedWallet
            ? fetchedWallet?.ens || prettifyAddress(fetchedWallet.address)
            : ''
        }
        scrollPosition={scrollPosition}
      />
      <AsyncContent
        data={fetchedWallet}
        isLoading={query.isLoading}
        renderContent={wallet => (
          <InfinityList
            onScroll={onScroll}
            itemSize={TRACK_CARD_HEIGHT}
            data={collection}
            keyExtractor={track => track.id}
            extraData={isTrackActive}
            style={style.scrollContainer}
            contentContainerStyle={style.scrollContent}
            ListHeaderComponent={
              <WalletInfo wallet={wallet} isOwnWallet={isOwnWallet} />
            }
            renderItem={({item: track}) => (
              <CollectedTrackCard
                isOwnedTrack={isOwnWallet}
                ownerAddresses={wallet}
                track={track}
                isActive={isTrackActive(track.id)}
                count={track.count}
                onPlay={playCollection}
                showArtist
              />
            )}
            ListEmptyComponent={
              collectionQuery.isLoading ? null : (
                <Space pv="xs">
                  <Text id="wallet.emptyCollection" size="xs" align="center" />
                </Space>
              )
            }
            fetchNextPage={collectionQuery.fetchNextPage}
            hasNextPage={collectionQuery.hasNextPage}
            isFetchingNextPage={
              collectionQuery.isFetchingNextPage || collectionQuery.isLoading
            }
            refresh={() => collectionQuery.refetch()}
          />
        )}
      />
    </Screen>
  );
};

export default Wallet;
