import React, {FC} from 'react';

import TransferErrorInfo from '@/modules/Transfer/components/TransferErrorInfo';
import CryptoTransferCheckout from '@/modules/Transfer/CryptoTransfer/CryptoTransferCheckout';
import SuccessfulCryptoTransfer from '@/modules/Transfer/CryptoTransfer/SuccessfulCryptoTransfer';
import {useCryptoTransferState} from '@/modules/Transfer/CryptoTransfer/useCryptoTransferState';

const CryptoTransferRouter: FC = () => {
  const {transfer, user, senderWallet, resetTransferDetails, close} =
    useCryptoTransferState();

  if (transfer.error) {
    return (
      <TransferErrorInfo
        user={user}
        senderWallet={senderWallet}
        transactionError={transfer.error}
        txHash={transfer.txHash}
        userOpHash={transfer.userOpHash}
        chainId={transfer.chainId}
        onReset={resetTransferDetails}
        onClose={close}
      />
    );
  }

  if (transfer.step === 'success') {
    return <SuccessfulCryptoTransfer />;
  }

  return <CryptoTransferCheckout />;
};

export default CryptoTransferRouter;
