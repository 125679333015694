import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import SpinampWalletIcon from '@/components/SpinampWalletIcon';
import Text from '@/components/Text/Text';
import {useToast} from '@/modules/Toasts';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Clipboard} from '@/services/clipboard';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {ImageSize} from '@/types/media';
import {Color} from '@/types/theme';
import {prettifyAddress} from '@/utils/ethereum';

import {styles, AVATAR_SIZE} from './AddressInfo.style';
import ConnectedIndicator from './ConnectedIndicator';

interface IProps {
  address: IAddress;
  color?: Color;
  showConnectionIndicator?: boolean;
  avatarSize?: number;
}

const AddressInfo: FC<IProps> = ({
  address,
  color,
  showConnectionIndicator = false,
  avatarSize = AVATAR_SIZE,
}) => {
  const style = useThemedStyles(styles);
  const {showToast} = useToast();

  const onCopy = async (_address: string) => {
    try {
      await Clipboard.copy(_address);
      showToast({textId: 'address.copied'});
    } catch (error) {}
  };

  return (
    <View style={style.addressInfo}>
      {isPasskeyWallet(address) ? (
        <SpinampWalletIcon size={avatarSize} />
      ) : (
        <Avatar
          id={address.address}
          url={address.avatarUrl}
          size={avatarSize}
          resizeWidth={ImageSize.thumbnail}
        />
      )}

      <View>
        <View style={style.row}>
          {address.ens ? (
            <Text weight="semibold" size="s" color={color}>
              {address.ens}
            </Text>
          ) : (
            <Text weight="semibold" size="s" color={color}>
              {isPasskeyWallet(address)
                ? 'Passkey Wallet'
                : prettifyAddress(address.address)}
            </Text>
          )}

          {showConnectionIndicator && !isPasskeyWallet(address) && (
            <ConnectedIndicator address={address.address} />
          )}
        </View>
        <TouchableOpacity
          onPress={() => onCopy(address.address)}
          style={style.row}>
          <Text size="xs" color={color}>
            {prettifyAddress(address.address)}
          </Text>
          <Icon size={16} name="copy" provider="custom" color={color} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default AddressInfo;
