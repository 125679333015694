import React, {FC, useMemo} from 'react';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';

import {isTouchScreen} from '@/utils/platform';

import {IHoverOpacityProps} from './types';

const HoverOpacity: FC<IHoverOpacityProps> = props => {
  const hoveredScreen = useSharedValue(false);
  const hoverStyleScreen = useAnimatedStyle(() => ({
    opacity: withTiming(props.isActive || hoveredScreen.value ? 1 : 0.35, {
      duration: 100,
    }),
  }));

  const mouseEventsHandler = useMemo(() => {
    if (isTouchScreen) {
      return {};
    }

    return {
      onMouseEnter: () => (hoveredScreen.value = true),
      onMouseLeave: () => (hoveredScreen.value = false),
    };
  }, []);

  return (
    <Animated.View
      style={[props.containerStyle, hoverStyleScreen]}
      {...mouseEventsHandler}>
      {props.children}
    </Animated.View>
  );
};

export default HoverOpacity;
