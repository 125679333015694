import {useState} from 'react';

import {useExpoUpdatesSyncQuery} from '@/queries/expoUpdates';

export const useAppUpdateToast = () => {
  const [isHidden, setIsHidden] = useState(false);
  const {isUpdateAvailable} = useExpoUpdatesSyncQuery();

  return {
    showAppUpdateToast: isUpdateAvailable && !isHidden,
    closeAppUpdateToast: () => setIsHidden(true),
  };
};
