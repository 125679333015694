import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const SCREEN_PADDING_TOP = spacing.xs;

export const styles = createThemedStyles(() => ({
  content: {
    flex: 1,
    paddingTop: SCREEN_PADDING_TOP,
  },
}));
