import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const DIVIDER_WIDTH = 500;

export const styles = createThemedStyles((theme, responsive) => {
  const gap = responsive.isMobile ? spacing.xs : spacing.s;

  return {
    sectionTitle: {
      marginVertical: spacing.s * 2,
      flexDirection: 'row',
      alignSelf: 'flex-start',
      alignItems: 'flex-start',
      gap,
    },
    sectionDivider: {
      borderColor: theme.colors.borderColor,
      width: DIVIDER_WIDTH,
      marginHorizontal: 0,
      top: 11,
    },
    sectionDivider_left: {
      position: 'absolute',
      zIndex: -1,
      left: -gap,
      transform: [{translateX: -DIVIDER_WIDTH}],
    },
  };
});
