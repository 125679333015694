import React, {FC, ReactNode} from 'react';
import {StyleProp, View} from 'react-native';

import {IIconProps} from '@/components/Icon';
import Icon from '@/components/Icon';
import Space from '@/components/Space';
import Text, {TextProps} from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useThemedStyles} from '@/theme';

import {styles} from './Pill.style';

interface IProps {
  onPress: () => void;
  isSelected?: boolean;
  text?: TextProps;
  icon?: IIconProps;
  style?: StyleProp<any>;
  prefixComponent?: ReactNode;
  suffixComponent?: ReactNode;
  disabled?: boolean;
}

const Pill: FC<IProps> = ({
  onPress,
  text,
  icon,
  isSelected = false,
  style: pillStyle,
  prefixComponent,
  suffixComponent,
  disabled = false,
}) => {
  const style = useThemedStyles(styles);

  return (
    <TouchableCard
      disabled={disabled}
      onPress={onPress}
      pressedStyle={style.pill_pressed}
      style={[style.pill, pillStyle, isSelected && style.pill_selected]}>
      <View style={style.pillContent}>
        {prefixComponent}
        {icon && <Icon size={18} {...icon} />}
        {text && (
          <Text
            size="xs"
            align="center"
            numberOfLines={1}
            style={[style.text, isSelected && style.text_selected, text?.style]}
            weight={isSelected ? 'semibold' : 'regular'}
            {...text}
          />
        )}
        {suffixComponent && <Space mr="xs">{suffixComponent}</Space>}
      </View>
    </TouchableCard>
  );
};

export default Pill;
