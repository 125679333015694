import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContainer: {
    flex: 1,
  },
  scrollContent: {
    flexGrow: 1,
    padding: spacing.m,
  },
  section: {
    gap: spacing.xs * 1.5,
  },
  divider: {
    marginVertical: spacing.m,
  },
}));
