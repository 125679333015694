import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const IMAGE_SIZE = 120;

export const styles = createThemedStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: spacing.l,
    gap: spacing.l,
  },
  image: {
    alignSelf: 'center',
    borderRadius: theme.box.borderRadius,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    overflow: 'hidden',
  },
  textsContainer: {
    gap: spacing.xxs,
  },
  info: {
    gap: spacing.xs,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.xs,
  },
  dashedDivider: {
    borderColor: theme.colors.borderColor,
  },
  termsCheckbox: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs * 1.5,
  },
}));
