import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {IPageInfo, IPaginatedResponse} from '@/types/api';
import {INftEvent, INftEventWithTracksInfo, NftEventType} from '@/types/nfts';
import {BASE_USER_FRAGMENT} from '@/utils/apiModelParsers';
import {ETHEREUM_NULL_ADDRESS} from '@/utils/ethereum';
import {
  parseApiNftEvent,
  parseApiNftEventWithTracks,
} from '@/utils/nftActivityEvents';

const NFT_EVENT_FRAGMENT = gql`
  fragment NftEvent on NftTransfer {
    id
    createdAtTime
    contractAddress
    tokenId
    nftId
    chainId
    from
    addressByFrom {
      id
      userByUserId {
        ...BaseUserFragment
      }
    }
    to
    addressByTo {
      id
      userByUserId {
        ...BaseUserFragment
      }
    }
  }
`;

// we only need info about first track for UI. If there is more we show "Various Tracks" message
const NFT_EVENT_TRACK_FRAGMENT = gql`
  fragment NftEventTrack on NftTransfer {
    nftByNftId {
      nftsProcessedTracksByNftId(first: 1) {
        totalCount
        nodes {
          processedTrackByProcessedTrackId {
            id
            slug
            title
            lossyArtworkIpfsHash
            lossyArtworkMimeType
            artist: artistByArtistId {
              slug
              name
            }
          }
        }
      }
    }
  }
`;

const getTypeFilter = (type?: NftEventType) => {
  if (type === 'mint') {
    return {
      from: {
        equalTo: ETHEREUM_NULL_ADDRESS,
      },
    };
  }

  if (type === 'transfer') {
    return {
      from: {
        notEqualTo: ETHEREUM_NULL_ADDRESS,
      },
    };
  }

  return undefined;
};

export const fetchNftActivityForTrack = async (
  trackId: string,
  after?: string,
  type?: NftEventType,
): Promise<IPaginatedResponse<INftEvent>> => {
  const response = await pipelineApi.request(
    gql`
      query NftActivityForTrack(
        $trackId: String!
        $filter: NftTransferFilter
        $after: Cursor
      ) {
        trackTransfers(
          trackid: $trackId
          after: $after
          first: 50
          filter: $filter
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...NftEvent
          }
        }
      }
      ${NFT_EVENT_FRAGMENT}
      ${BASE_USER_FRAGMENT}
    `,
    {
      trackId,
      after,
      filter: getTypeFilter(type),
    },
  );
  const pageResponse = response.trackTransfers;
  const pageInfo: IPageInfo = pageResponse.pageInfo;
  const items: INftEvent[] = pageResponse.nodes.map(parseApiNftEvent);

  return {
    pageInfo,
    items,
  };
};

export const fetchNftActivityForArtist = async (
  artistId: string,
  after?: string,
  type?: NftEventType,
): Promise<IPaginatedResponse<INftEventWithTracksInfo>> => {
  const response = await pipelineApi.request(
    gql`
      query NftActivityForArtist(
        $artistId: String!
        $filter: NftTransferFilter
        $after: Cursor
      ) {
        artistTransfers(
          artistid: $artistId
          after: $after
          first: 50
          filter: $filter
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...NftEvent
            ...NftEventTrack
          }
        }
      }
      ${NFT_EVENT_FRAGMENT}
      ${BASE_USER_FRAGMENT}
      ${NFT_EVENT_TRACK_FRAGMENT}
    `,
    {
      artistId,
      after,
      filter: getTypeFilter(type),
    },
  );

  const pageResponse = response.artistTransfers;
  const pageInfo: IPageInfo = pageResponse.pageInfo;
  const items: INftEventWithTracksInfo[] = pageResponse.nodes.map(
    parseApiNftEventWithTracks,
  );

  return {
    pageInfo,
    items,
  };
};

export const fetchNftActivityForCollector = async (
  userId: string,
  after?: string,
  type?: NftEventType,
): Promise<IPaginatedResponse<INftEventWithTracksInfo>> => {
  const response = await pipelineApi.request(
    gql`
      query NftActivityForCollector(
        $userId: String!
        $filter: NftTransferFilter
        $after: Cursor
      ) {
        collectorTransfers(
          userid: $userId
          after: $after
          first: 50
          filter: $filter
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...NftEvent
            ...NftEventTrack
          }
        }
      }
      ${NFT_EVENT_FRAGMENT}
      ${BASE_USER_FRAGMENT}
      ${NFT_EVENT_TRACK_FRAGMENT}
    `,
    {
      userId,
      after,
      filter: getTypeFilter(type),
    },
  );

  const pageResponse = response.collectorTransfers;
  const pageInfo: IPageInfo = pageResponse.pageInfo;
  const items: INftEventWithTracksInfo[] = pageResponse.nodes.map(
    parseApiNftEventWithTracks,
  );

  return {
    pageInfo,
    items,
  };
};

export const fetchAllNftActivity = async (
  after?: string,
  type?: NftEventType,
): Promise<IPaginatedResponse<INftEventWithTracksInfo>> => {
  const response = await pipelineApi.request(
    gql`
      query AllNftActivity($after: Cursor, $filter: NftTransferFilter) {
        allNftTransfers(
          after: $after
          first: 50
          orderBy: CREATED_AT_TIME_DESC
          filter: $filter
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...NftEvent
            ...NftEventTrack
          }
        }
      }
      ${NFT_EVENT_FRAGMENT}
      ${NFT_EVENT_TRACK_FRAGMENT}
      ${BASE_USER_FRAGMENT}
    `,
    {
      after,
      filter: getTypeFilter(type),
    },
  );

  const pageResponse = response.allNftTransfers;
  const pageInfo: IPageInfo = pageResponse.pageInfo;
  const items: INftEventWithTracksInfo[] = pageResponse.nodes.map(
    parseApiNftEventWithTracks,
  );

  return {
    pageInfo,
    items,
  };
};
