import {useScrollToTop} from '@react-navigation/native';
import React, {FC, ReactNode} from 'react';
import Animated, {useAnimatedRef} from 'react-native-reanimated';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppSelector} from '@/hooks/useRedux';
import {useExploreQuery} from '@/queries/explore';
import {useTrendingPlaylistsQuery} from '@/queries/trendingPlaylists';
import {useTrendingTracksQuery} from '@/queries/trendingTracks';
import {ExploreSection} from '@/screens/Explore/ExploreSections';
import {selectSelectedUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles} from './Explore.style';

const Explore = () => {
  const style = useThemedStyles(styles);

  const loggedUserId = useAppSelector(selectSelectedUserId);

  const {scrollPosition, onScroll} = useAnimatedHeader();
  const flatListRef = useAnimatedRef<Animated.FlatList<any>>();

  useScrollToTop(flatListRef);

  const sections = loggedUserId
    ? [
        ExploreSection.SpinampWrapped,
        ExploreSection.PromotedContent,
        ExploreSection.ForYou,
        ExploreSection.ListenAgain,
        ExploreSection.Genres,
        ExploreSection.TrendingTracks,
        ExploreSection.TrendingArtists,
        ExploreSection.TrendingPlaylists,
        ExploreSection.SpinampOriginals,
        ExploreSection.LatestTracks,
        ExploreSection.LatestArtists,
        ExploreSection.Platforms,
      ]
    : [
        ExploreSection.SpinampWrapped,
        ExploreSection.PromotedContent,
        ExploreSection.ForYou,
        ExploreSection.ListenAgain,
        ExploreSection.TrendingTracks,
        ExploreSection.Genres,
        ExploreSection.TrendingArtists,
        ExploreSection.TrendingPlaylists,
        ExploreSection.SpinampOriginals,
        ExploreSection.LatestTracks,
        ExploreSection.LatestArtists,
        ExploreSection.Platforms,
      ];

  return (
    <Screen>
      <Header
        titleId="explore.title"
        showMenu
        scrollPosition={scrollPosition}
        mockTitle
      />
      <ExploreLoader>
        <Animated.FlatList
          ref={flatListRef}
          onScroll={onScroll}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={style.scrollContent}
          data={sections}
          keyExtractor={section => section.key}
          renderItem={({item: Section}) => <Section.Component />}
          // The lowest possible section number for which viewport is filled with content
          initialNumToRender={isWeb ? 10 : 7}
        />
      </ExploreLoader>
    </Screen>
  );
};

const ExploreLoader: FC<{children: ReactNode}> = ({children}) => {
  // Component deciding if we should show loader or content on explore page.
  // This is the case only for user's first app load ever, because for following reloads data will be cached.
  // However, it is super important, as it is the very fist interaction of the new user with the product.
  // We don't want to show loader for him for too long, but on the other hand we also want to avoid layout jumping,
  // so we need to find a good compromise and show loading state only before critical queries are loaded.

  // Assumptions I made:
  // 1. We don't want Listen Again and For You section preventing content rendering. For new users that will be empty anyway.
  // 2. We don't really care about sections rendered in the lower parts, as they won't be initially visible in the viewport, so it's ok for them to jump once they are loaded.
  // 3. Trending sections are critical for anon users as they are rendered at very top and in most cases they should be enough to fill the viewport (together with genres which are static).

  const {
    query: {isPending: isExploreLoading},
  } = useExploreQuery();
  const {
    query: {isPending: areTrendingTracksLoading},
  } = useTrendingTracksQuery();
  const {
    query: {isPending: areTrendingPlaylistsLoading},
  } = useTrendingPlaylistsQuery();

  return (
    <AsyncContent
      isLoading={
        isExploreLoading ||
        areTrendingTracksLoading ||
        areTrendingPlaylistsLoading
      }>
      {children}
    </AsyncContent>
  );
};

export default Explore;
