import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useBlockNumber} from 'wagmi';
import {getBalanceQueryKey} from 'wagmi/query';

// https://wagmi.sh/react/guides/migrate-from-v1-to-v2#removed-watch-property
export const useBalanceWatcher = () => {
  const queryClient = useQueryClient();
  const {data: blockNumber} = useBlockNumber({watch: true});

  useEffect(() => {
    queryClient.invalidateQueries({queryKey: getBalanceQueryKey().slice(0, 1)});
  }, [blockNumber, queryClient]);
};
