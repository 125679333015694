import antFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import fontAwesomeFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import brandsFont from 'react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf';
import ioniconsFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import materialCommunityFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import materialFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import simpleFont from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf';

import {isWeb} from '@/utils/platform';

const fontsMap = {
  AntDesign: antFont,
  FontAwesome: fontAwesomeFont,
  FontAwesome5_Brands: brandsFont,
  MaterialIcons: materialFont,
  MaterialCommunityIcons: materialCommunityFont,
  SimpleLineIcons: simpleFont,
  Ionicons: ioniconsFont,
};

export const loadIconFonts = () => {
  if (isWeb) {
    const style = document.createElement('style');

    Object.entries(fontsMap).forEach(([fontName, iconFont]) => {
      const iconFontStyles = `@font-face {
        src: url(${iconFont});
        font-family: ${fontName};
      }`;

      style.appendChild(document.createTextNode(iconFontStyles));
      document.head.appendChild(style);
    });
  }
};
