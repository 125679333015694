import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const DIVIDER_WIDTH = spacing.xl - spacing.xs;

export const styles = createThemedStyles(() => ({
  container: {
    overflow: 'hidden',
    flexDirection: 'row',
  },
  slidingTextContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    flexDirection: 'row',
    width: Platform.select({
      // web need to set direct width value for web to grow properly for some reason
      web: 10000,
    }),
  },
  textCopy: {
    paddingLeft: DIVIDER_WIDTH,
  },
  textPlaceholder: {
    opacity: 0,
  },
}));
