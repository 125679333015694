import {spinampServicesApi} from '@/services/spinampServicesApi';
import {ISigner} from '@/types/session';
import {formatFirebaseId} from '@/utils/api';

interface IHistoryTrack {
  trackId: string;
  timestamp: string;
}

export const fetchHistory = async (
  signer: ISigner,
  latestTimestamp?: string,
): Promise<IHistoryTrack[]> => {
  const signature = await signer.signMessage('get-my-events');

  const response: IHistoryTrack[] = await spinampServicesApi.get('history', {
    headers: {
      'x-signature': signature,
      'x-signer': signer.address,
    },
    params: {
      latestTimestamp,
    },
  });

  return response.map(historyTrack => ({
    ...historyTrack,
    trackId: formatFirebaseId(historyTrack.trackId),
  }));
};
