import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = spacing.xl;
export const AVATAR_CARD_HEIGHT = 53;
const ACTION_WIDTH = spacing.xl - spacing.xxs;

export const styles = createThemedStyles(() => ({
  card: {
    height: AVATAR_CARD_HEIGHT,
    justifyContent: 'center',
    paddingVertical: 0,
    paddingLeft: spacing.xxs,
    paddingRight: 0,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    justifyContent: 'space-evenly',
    paddingHorizontal: spacing.xs,
  },
  action: {
    width: ACTION_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
