import React, {FC} from 'react';

import TrackInfoCard from '@/modules/Transfer/components/TrackInfoCard';
import TransferErrorInfo from '@/modules/Transfer/components/TransferErrorInfo';
import SuccessfulTransfer from '@/modules/Transfer/TrackTransfer/SuccessfulTransfer';
import TransferCheckout from '@/modules/Transfer/TrackTransfer/TransferCheckout';
import {useTransferState} from '@/modules/Transfer/TrackTransfer/useTransferState';

const TransferRouter: FC = () => {
  const {transfer, track, user, senderWallet, resetTransferDetails, close} =
    useTransferState();

  const {step, txHash, userOpHash, error} = transfer;

  if (error) {
    return (
      <TransferErrorInfo
        header={<TrackInfoCard track={track} />}
        user={user}
        senderWallet={senderWallet}
        transactionError={error}
        txHash={txHash}
        userOpHash={userOpHash}
        chainId={transfer.chainId}
        onReset={resetTransferDetails}
        onClose={close}
      />
    );
  }

  if (step === 'success') {
    return <SuccessfulTransfer />;
  }

  return <TransferCheckout />;
};

export default TransferRouter;
