import React, {FC} from 'react';
import {View} from 'react-native';

import Icon from '@/components/Icon';
import spacing from '@/constants/spacing';
import WalletCard, {
  IWalletCardProps,
} from '@/modules/Wallets/components/WalletCard/WalletCard';
import {areAddressesEqual} from '@/utils/ethereum';

interface IProps
  extends Omit<IWalletCardProps, 'onPress' | 'isSelected' | 'suffix'> {
  selectedWalletAddress?: string;
  onSelect: (address: string) => void;
}

const SELECTED_ICON_SIZE = spacing.l;

const WalletSelectItem: FC<IProps> = ({
  wallet,
  selectedWalletAddress,
  onSelect,
  chainId,
}) => {
  const isSelected = areAddressesEqual(wallet.address, selectedWalletAddress);

  return (
    <WalletCard
      wallet={wallet}
      onPress={onSelect}
      isSelected={isSelected}
      chainId={chainId}
      suffix={
        <View style={{width: SELECTED_ICON_SIZE}}>
          {isSelected && (
            <Icon name="checked" provider="custom" size={SELECTED_ICON_SIZE} />
          )}
        </View>
      }
    />
  );
};

export default WalletSelectItem;
