import {useNavigation} from '@react-navigation/native';
import React, {FC, Fragment} from 'react';
import {TouchableOpacity, TouchableWithoutFeedback, View} from 'react-native';
import Animated from 'react-native-reanimated';

import Card from '@/components/Card/Card';
import Icon from '@/components/Icon';
import KeyboardView from '@/components/KeyboardView';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';
import {noop} from '@/utils/functions';

import {styles} from './ModalScreenLayout.style';
import {IModalScreenLayoutProps} from './types';

export const ModalScreenLayout: FC<IModalScreenLayoutProps> = ({
  children,
  title,
  titleId,
  style: customStyle,
  avoidKeyboard,
  desktopSize = 's',
  disableClickOutside = false,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation();
  const {isSmallDesktop, isDesktop} = useResponsive();

  const Wrapper = avoidKeyboard ? KeyboardView : Fragment;

  return (
    <Wrapper>
      <Animated.View style={[style.container, customStyle]}>
        <TouchableOpacity
          onPress={navigation.goBack}
          style={style.backdrop}
          disabled={disableClickOutside}
          activeOpacity={1}
        />
        {/* TouchableWithoutFeedback prevents click events bubbling to content behind the modal on web */}
        <TouchableWithoutFeedback onPress={noop}>
          <View
            style={[
              style.modal,
              isSmallDesktop && style[`modalSmallDesktop_${desktopSize}`],
              isDesktop && style[`modalDesktop_${desktopSize}`],
            ]}>
            <Card style={style.card}>
              {(title || titleId) && (
                <Space style={style.header} p="xs">
                  <TouchableOpacity
                    onPress={navigation.goBack}
                    style={style.closeIcon}>
                    <Icon
                      name="close"
                      provider="custom"
                      color="invertedTextColor"
                    />
                  </TouchableOpacity>

                  <Text
                    id={titleId}
                    align="center"
                    color="invertedTextColor"
                    weight="semibold">
                    {title}
                  </Text>
                </Space>
              )}
              {children}
            </Card>
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    </Wrapper>
  );
};

export default ModalScreenLayout;
