import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Icon from '@/components/Icon/Icon';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {getHitSlop} from '@/constants/spacing';
import Toast from '@/modules/Toasts/Toast';
import {useApplyExpoUpdateMutation} from '@/queries/expoUpdates';
import {useThemedStyles} from '@/theme';

import {styles, CLOSE_ICON_SIZE} from './AppUpdateToast.style';

interface IProps {
  onClose: () => void;
}

const AppUpdateToast: FC<IProps> = ({onClose}) => {
  const style = useThemedStyles(styles);
  const {applyUpdate, isLoading} = useApplyExpoUpdateMutation();

  return (
    <Toast>
      <FadeInOut enabled={isLoading}>
        <View style={style.container}>
          <View style={style.textContainer}>
            <View style={{width: CLOSE_ICON_SIZE}} />
            <Text
              align="center"
              color="invertedTextColor"
              size="xs"
              id="appUpdate.title"
            />
            <TouchableOpacity onPress={onClose} hitSlop={getHitSlop('s')}>
              <Icon
                name="close"
                color="invertedTextColor"
                size={CLOSE_ICON_SIZE}
              />
            </TouchableOpacity>
          </View>
          <TransparentButton
            onPress={applyUpdate}
            text={{id: 'appUpdate.action', color: 'invertedTextColor'}}
            disabled={isLoading}
          />
        </View>
      </FadeInOut>
    </Toast>
  );
};

export default AppUpdateToast;
