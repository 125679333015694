import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    gap: spacing.xs,
    flexDirection: 'row',
    alignItems: 'center',
  },
  expandIcon: {
    transform: [{translateY: -1}],
  },
}));
