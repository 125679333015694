import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';
import {useMutation} from '@tanstack/react-query';
import React, {FC, useEffect} from 'react';

import {updateArtist} from '@/api/artist';
import {useGlobalModal} from '@/components/GlobalModal/GlobalModal';
import Header from '@/components/Header';
import Screen from '@/components/Screen/Screen';
import ScreenError from '@/components/ScreenError';
import ScreenLoader from '@/components/ScreenLoader';
import ThemeCreator from '@/components/ThemeCreator';
import {useCanEditArtist} from '@/hooks/useCanEdit';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useArtistBySlugQuery} from '@/queries/artists';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {setThemeSettings} from '@/store/theme';
import {selectActiveUserSigner} from '@/store/user';
import {ThemedScreen, useThemedScreen} from '@/theme';
import {IArtistUpdate} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {
  MainStackNavigationParams,
  MainStackParams,
  Routes,
} from '@/types/routes';
import {IDynamicTheme} from '@/types/theme';
import {analytics} from '@/utils/analytics';

const ArtistThemeEdit: FC<{}> = () => {
  const {
    params: {slugOrId},
  } = useRoute<RouteProp<MainStackParams, Routes.ArtistThemeEdit>>();
  const navigation = useNavigation<MainStackNavigationParams>();
  const dispatch = useAppDispatch();
  const signer = useAppSelector(selectActiveUserSigner);

  const modal = useGlobalModal();

  const {
    artist,
    query: {isFetching: isArtistFetching},
  } = useArtistBySlugQuery(slugOrId);
  const {artistThemes} = useCustomThemesConfigQuery();

  const theme = useThemedScreen({
    customTheme: artist?.customTheme,
    predefinedThemeName:
      artist && (artist.predefinedThemeName || artistThemes[artist.id]),
  });

  const canEditArtist = useCanEditArtist(artist);

  useEffect(() => {
    // Kick the user out of the edit page if they don't have permission to edit
    if (!canEditArtist) {
      navigation.goBack();
    }
  }, [canEditArtist]);

  useEffect(() => {
    if (artist?.id) {
      analytics.themeEditVisited(Routes.ArtistThemeEdit, artist.id);
    }
  }, [artist?.id]);

  const {showToast} = useToast();

  const updateArtistUsingSignMessage = (artistChanges: IArtistUpdate) => {
    if (!signer) {
      throw new Error('missing signer');
    }

    return updateArtist(artistChanges, signer);
  };

  const mutation = useMutation({
    mutationFn: updateArtistUsingSignMessage,
    onSuccess: async (data, variables) => {
      await Promise.all([
        await queryClient.invalidateQueries({
          queryKey: [QueryKeys.artistBySlug, slugOrId],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.userProfiles],
        }),
      ]);
      navigation.goBack();
      dispatch(
        setThemeSettings({
          type: variables.customTheme ? 'artist' : 'predefined',
          name: variables.predefinedThemeName,
        }),
      );
      showToast({textId: 'profileEdit.saved'});
    },
    onError: (error: any) => {
      Sentry.captureException(error);
    },
  });

  const onSubmitForm = async ({
    customTheme,
    predefinedThemeName,
  }: IDynamicTheme) => {
    mutation.mutate({
      id: artist!.id,
      customTheme,
      predefinedThemeName,
    });
  };

  const renderContent = () => {
    const titleId = 'themeEdit.title';

    if (isArtistFetching && !artist) {
      return (
        <Screen>
          <Header showBack titleId={titleId} />
          <ScreenLoader />
        </Screen>
      );
    }

    if (!artist) {
      return (
        <Screen>
          <Header showBack titleId={titleId} />
          <ScreenError textId="notFound" />
        </Screen>
      );
    }

    return (
      <ThemeCreator
        titleId={titleId}
        onSubmit={onSubmitForm}
        isSaving={mutation.isPending}
        infoTextId="themeCreator.info.artist"
        onCancel={() => {
          modal.openModal({
            titleId: 'themeEdit.discard.title',
            descriptionId: 'themeEdit.discard.description',
            confirmTextId: 'themeEdit.discard.confirm',
            cancelTextId: 'themeEdit.discard.cancel',
            onConfirm: navigation.goBack,
          });
        }}
        initialValues={{
          customTheme: artist.customTheme,
          predefinedThemeName: artist.predefinedThemeName,
        }}
      />
    );
  };

  return <ThemedScreen theme={theme}>{renderContent()}</ThemedScreen>;
};

export default ArtistThemeEdit;
