import {Vibration} from 'react-native';
import {isHex, parseEther} from 'viem';
import {useWalletClient} from 'wagmi';

import {useConnectorName} from '@/modules/ExternalWallet/useConnectorName';
import {isWalletCancelError, TransactionError} from '@/modules/Transactions';
import {TransferStep} from '@/modules/Transfer';
import {useCryptoTransferState} from '@/modules/Transfer/CryptoTransfer/useCryptoTransferState';
import {useTrackCryptoTransferEvent} from '@/modules/Transfer/CryptoTransfer/useTrackCryptoTransferEvent';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {AddressString, IAddress} from '@/types/common';
import {areAddressesEqual, getChainClient} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

export const useCryptoTransferTransaction = () => {
  const {transfer, updateTransfer, resetTransferDetails, gasEstimation} =
    useCryptoTransferState();
  const {data: walletClient} = useWalletClient();
  const connector = useConnectorName();

  const {trackEvent} = useTrackCryptoTransferEvent();

  const setTransferStep = (step: TransferStep) => updateTransfer({step});

  const transferFromPasskey = async (senderWallet: IAddress) => {
    setTransferStep('waitingForPasskey');
    const {credentialId, signer} = senderWallet.metadata?.spinampWallet || {};
    const {privateKey} = await Passkey.get(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransactionError('WRONG_PASSKEY_SIGNER');
    }

    setTransferStep('executingTransaction');

    const userOpHash = await sendTransaction({
      privateKey,
      chainId: transfer.chainId,
      transaction: {
        to: transfer.to as AddressString,
        value: parseEther(transfer.amount),
      },
      gasSettings: {
        callGasLimit: gasEstimation.callGasLimit,
        preVerificationGas: gasEstimation.preVerificationGas,
        verificationGasLimit: gasEstimation.verificationGasLimit,
        maxFeePerGas: gasEstimation.maxFeePerGas,
        maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      },
    });

    updateTransfer({userOpHash});
    trackEvent('receivedTransactionUserOpHash');

    setTransferStep('waitingForReceipt');

    const {transactionHash, status} = await waitForReceipt({
      privateKey,
      chainId: transfer.chainId,
      hash: userOpHash,
    });

    updateTransfer({txHash: transactionHash});
    trackEvent('receivedTransactionTxHash');

    if (status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const transferFromExternal = async () => {
    if (!walletClient) {
      throw new TransactionError('WALLET_NOT_CONNECTED');
    }

    if (walletClient.account.address !== transfer.from) {
      throw new TransactionError('WRONG_WALLET_CONNECTED');
    }

    setTransferStep('executingTransaction');
    const txHash = await walletClient.sendTransaction({
      to: transfer.to as AddressString,
      value: parseEther(transfer.amount),
      gas: gasEstimation.gasLimit,
      maxFeePerGas: gasEstimation.maxFeePerGas,
      maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransactionError('USER_CANCELLED');
    }

    updateTransfer({txHash});
    trackEvent('receivedTransactionTxHash');

    setTransferStep('waitingForReceipt');

    const receipt = await getChainClient(
      transfer.chainId,
    ).waitForTransactionReceipt({
      hash: txHash,
    });

    if (receipt.status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const executeTransfer = async (senderWallet: IAddress) => {
    try {
      trackEvent('confirmed');

      resetTransferDetails();

      if (isPasskeyWallet(senderWallet)) {
        await transferFromPasskey(senderWallet);
      } else {
        await transferFromExternal();
      }

      setTransferStep('success');
      Vibration.vibrate();
      trackEvent('success');
    } catch (error: any) {
      setTransferStep('checkout');

      if (isWalletCancelError(error)) {
        trackEvent('cancelled');
        resetTransferDetails();
        return;
      }

      Sentry.captureException(error, {
        tags: {
          cryptoTransfer: true,
          passkeyWallet: isPasskeyWallet(senderWallet),
        },
        extra: {
          chainId: transfer.chainId,
          amount: transfer.amount,
          wallet: senderWallet,
          to: transfer.to,
          connector,
        },
      });

      updateTransfer({error});
      trackEvent('error');
    }
  };

  return {
    executeTransfer,
  };
};
