import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const TOOLBAR_WRAPPER_HEIGHT = spacing.l;
export const TOOLBAR_HEIGHT = TOOLBAR_WRAPPER_HEIGHT - spacing.xxs;

export const styles = createThemedStyles(theme => ({
  editorWrapper: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    borderWidth: 1,
    overflow: 'hidden',
  },
  toolbarWrapper: {
    height: TOOLBAR_WRAPPER_HEIGHT + spacing.xxs * 2,
    paddingVertical: spacing.xxs,
    borderBottomWidth: 1,
    borderColor: theme.colors.borderColor,
    overflow: 'hidden',
  },
  divider: {
    position: 'absolute',
    left: 0,
    bottom: -1,
    right: 0,
    height: 1,
    backgroundColor: theme.colors.borderColor,
    zIndex: 10,
  },
  editorMobile: {
    padding: 0,
    backgroundColor: theme.colors.background,
    minHeight: 250,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.borderColor,
  },
  editorWeb: {
    padding: 0,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    borderStyle: 'solid',
    borderWidth: 1,
    marginHorizontal: 0,
    backgroundColor: theme.colors.background,
    minHeight: 250,
    paddingHorizontal: 0,
    color: theme.colors.textColor,
    overflow: 'hidden',
  },
}));
