import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  backdropWrapper: {
    ...StyleSheet.absoluteFillObject,
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000',
    opacity: 0.3,
  },
  tooltipContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: theme.box.borderRadius,
    minWidth: 0,
    width: 'auto',
    position: 'absolute',
    padding: spacing.xs,
    shadowColor: theme.colors.textColor,
    shadowOffset: {width: -spacing.xxs / 2, height: spacing.xxs / 2},
    shadowOpacity: 0.4,
    shadowRadius: spacing.xxs,
  },
}));
