import React, {FC, ReactNode, useState} from 'react';

import ConfirmationModal, {
  IConfirmationModalProps,
} from '../ConfirmationModal/ConfirmationModal';

interface IGlobalModal extends Partial<IConfirmationModalProps> {
  titleId: string;
  descriptionId: string;
  onConfirm: () => void;
}

interface IGlobalModalContext {
  openModal: (modal: IGlobalModal) => void;
  closeModal: () => void;
}

const GlobalModalContext = React.createContext<IGlobalModalContext | null>(
  null,
);

export const GlobalModalProvider: FC<{children: ReactNode}> = ({children}) => {
  const [modal, setModal] = useState<IGlobalModal | null>(null);

  const openModal = (_modal: IGlobalModal) => {
    setModal(_modal);
  };

  const closeModal = () => {
    setModal(null);
  };

  const onConfirm = () => {
    modal?.onConfirm();
    closeModal();
  };

  return (
    <GlobalModalContext.Provider value={{openModal, closeModal}}>
      {children}
      {modal && (
        <ConfirmationModal
          {...modal}
          isOpen={true}
          onClose={closeModal}
          onConfirm={onConfirm}
        />
      )}
    </GlobalModalContext.Provider>
  );
};

export const useGlobalModal = (): IGlobalModalContext => {
  const context = React.useContext(GlobalModalContext);

  if (context == null) {
    throw new Error('useGlobalModal must be used within a GlobalModalProvider');
  }

  return context;
};
