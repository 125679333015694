import React, {FC, useEffect} from 'react';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';

import Loader from '@/components/Loader/Loader';
import {
  IScreenLoaderProps,
  screenLoaderDefaults,
} from '@/components/ScreenLoader/shared';
import {useThemedStyles} from '@/theme';

import {styles} from './ScreenLoader.style';

const ScreenLoader: FC<IScreenLoaderProps> = ({
  style,
  delay = screenLoaderDefaults.delay,
  loaderProps,
}) => {
  const screenLoaderStyle = useThemedStyles(styles);
  const opacity = useSharedValue(0);

  useEffect(() => {
    opacity.value = withDelay(delay, withTiming(1, {duration: 150}));
  }, []);

  const fadeInStyle = useAnimatedStyle(
    () => ({
      opacity: opacity.value,
    }),
    [],
  );

  return (
    <Animated.View style={[screenLoaderStyle.container, style, fadeInStyle]}>
      <Loader size="l" {...loaderProps} />
    </Animated.View>
  );
};

export default ScreenLoader;
