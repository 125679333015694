import React, {FC, ReactNode, useContext, useMemo} from 'react';

import {useThemes} from '@/hooks/useThemes';
import {makeTheme} from '@/themes/partialTheme';
import {IDynamicTheme} from '@/types/theme';

import {ThemeContext} from './context';

interface IProps extends IDynamicTheme {
  children: ReactNode;
  shouldFallbackToAppTheme?: boolean;
}

const LocalThemeProvider: FC<IProps> = ({
  children,
  predefinedThemeName,
  customTheme,
  shouldFallbackToAppTheme,
}) => {
  const {getTheme, isValidTheme} = useThemes();
  const {appTheme} = useContext(ThemeContext);

  const theme = useMemo(() => {
    if (customTheme) {
      return makeTheme({basic: customTheme});
    }

    if (predefinedThemeName && isValidTheme(predefinedThemeName)) {
      return getTheme(predefinedThemeName);
    }

    return undefined;
  }, [predefinedThemeName, customTheme, getTheme, isValidTheme]);

  if (theme) {
    return (
      <ThemeContext.Provider
        value={{
          theme,
        }}>
        {children}
      </ThemeContext.Provider>
    );
  }

  if (shouldFallbackToAppTheme && appTheme) {
    return (
      <ThemeContext.Provider
        value={{
          theme: appTheme,
        }}>
        {children}
      </ThemeContext.Provider>
    );
  }

  return <>{children}</>;
};

export default LocalThemeProvider;
