import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar/Avatar';
import Modal, {IModalProps} from '@/components/Modal';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import spacing from '@/constants/spacing';
import {ImageSize} from '@/types/media';
import {IPlaylist} from '@/types/playlists';
import {IBaseUser} from '@/types/user';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

interface IProps extends IModalProps {
  user: IBaseUser;
  playlist: IPlaylist;
  onConfirm: () => void;
}

const RemoveCollaboratorModal: FC<IProps> = ({
  user,
  playlist,
  onConfirm,
  ...modalProps
}) => {
  return (
    <Modal titleId="collaborators.remove.title" {...modalProps}>
      <Space p="s">
        <View style={{alignItems: 'center'}}>
          <Avatar
            id={user.id}
            url={getUserAvatar(user)}
            size={spacing.xxl * 2}
            resizeWidth={ImageSize.avatar * 2}
          />
          <Space h="s" />
          <Text weight="semibold" size="m">
            {getUserDisplayName(user)}
          </Text>
          <Space h="s" />
          <Text
            size="xs"
            id="collaborators.remove.description"
            values={{
              user: getUserDisplayName(user),
              playlist: playlist.title,
            }}
          />
        </View>
        <Space h="m" />
        <TouchableCard onPress={onConfirm} pv="xs">
          {({actionColor}) => (
            <Text
              weight="semibold"
              id="collaborators.remove.confirm"
              align="center"
              color={actionColor()}
            />
          )}
        </TouchableCard>
        <Space h="xs" />
        <TouchableCard onPress={modalProps.onClose} pv="xs">
          {({actionColor}) => (
            <Text
              weight="semibold"
              id="collaborators.remove.cancel"
              align="center"
              color={actionColor()}
            />
          )}
        </TouchableCard>
      </Space>
    </Modal>
  );
};

export default RemoveCollaboratorModal;
