import React, {FC} from 'react';
import {View} from 'react-native';

import ExternalLink from '@/components/ExternalLink';
import Loader from '@/components/Loader';
import Space from '@/components/Space';
import Text from '@/components/Text';
import Toast from '@/modules/Toasts/Toast';
import {IPurchaseDetails} from '@/modules/TopUp/types';

interface IProps {
  topUpTransaction: IPurchaseDetails;
  onClose: () => void;
}

const TopUpToast: FC<IProps> = ({topUpTransaction, onClose}) => {
  const {status, url} = topUpTransaction;

  return (
    <Toast onClose={onClose} closeOnPress={false}>
      <>
        {status === 'RELEASED' ? (
          <Text
            size="xs"
            id="topUpToast.success"
            align="center"
            color="invertedTextColor"
            weight="semibold"
          />
        ) : status === 'PAYMENT_FAILED' ? (
          <Text
            size="xs"
            id="topUpToast.error"
            align="center"
            color="invertedTextColor"
            weight="semibold"
          />
        ) : (
          <Loader
            textId="topUpToast.processing"
            size="xs"
            align="center"
            color="invertedTextColor"
            weight="semibold"
          />
        )}

        <Space h="xs" />

        <View style={{alignItems: 'center'}}>
          <ExternalLink url={url}>
            <Text
              underline
              size="xs"
              id="topUpToast.details"
              color="invertedTextColor"
              align="center"
            />
          </ExternalLink>
        </View>
      </>
    </Toast>
  );
};

export default TopUpToast;
