import React, {useCallback, useRef, useState} from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import TransparentButton from '@/components/TransparentButton';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import ChannelPills from '@/screens/Feed/components/ChannelPills';
import FeedOnboarding from '@/screens/Feed/components/FeedOnboarding';
import {useFeedOnboardingState} from '@/screens/Feed/useFeedOnboarding';
import {selectShouldPlay, setAreHotkeysActive} from '@/store/feed';
import {selectActiveUserId, selectOnboardingInfo} from '@/store/user';
import {analytics} from '@/utils/analytics';
import {noop} from '@/utils/functions';

import Feed from './components/Feed';

const ChannelsScreen = () => {
  const {scrollPosition} = useAnimatedHeader();
  const [isPlayAllModalOpen, setIsPlayAllModalOpen] = useState(false);

  const sessionStart = useRef(new Date().toISOString()).current;

  const activeUser = useActiveUser();
  const myUserId = useAppSelector(selectActiveUserId);
  const [spiedUserId, setSpiedUserId] = useState<string>();

  const activeUserId = spiedUserId || myUserId;

  const dispatch = useAppDispatch();

  const scrollToTopRef = useRef(noop);

  const onSearchFocus = useCallback(() => {
    dispatch(setAreHotkeysActive(false));
  }, []);

  const onSearchBlur = useCallback(() => {
    dispatch(setAreHotkeysActive(true));
  }, []);

  const channelIndexHistory = useRef(new Map());

  const feedShouldPlay = useAppSelector(selectShouldPlay);

  const {onboardingState, closeOnboardingStep} = useFeedOnboardingState();

  const isLocalOnboardingCompleted =
    useAppSelector(selectOnboardingInfo).isFeedOnboardingCompleted;

  // For anon users local state is the source of truth here
  const isOnboardingCompleted =
    isLocalOnboardingCompleted ||
    !!activeUser?.metadata?.onboarding.completedFeedOnboarding;

  return (
    <Screen>
      <Header
        titleId="channels.title"
        showMenu
        scrollPosition={scrollPosition}
        mockTitle={true}
        rightActions={
          <TransparentButton
            onPress={() => setIsPlayAllModalOpen(true)}
            text={{id: 'channels.playAll', size: 's', weight: 'regular'}}
            disabled={!isOnboardingCompleted}
          />
        }>
        <ChannelPills
          spiedUserId={spiedUserId}
          onUserSelect={setSpiedUserId}
          scrollToTop={scrollToTopRef}
          onSearchFocus={onSearchFocus}
          onSearchBlur={onSearchBlur}
          showOnboardingInfo={
            onboardingState.channels[0] === 'info' &&
            onboardingState.track.length + onboardingState.artist.length === 0
          }
          disabled={
            !isOnboardingCompleted &&
            onboardingState.track.length + onboardingState.artist.length > 0
          }
          closeOnboarding={() =>
            closeOnboardingStep({type: 'channels', item: 'info'})
          }
        />
      </Header>
      <AsyncContent
        data={activeUserId}
        isLoading={!myUserId}
        renderContent={loadedUserId => {
          if (isOnboardingCompleted) {
            return (
              <Feed
                key={loadedUserId}
                sessionStart={sessionStart}
                userId={loadedUserId}
                isPlayAllModalOpen={isPlayAllModalOpen}
                togglePlayAllModal={() => setIsPlayAllModalOpen(v => !v)}
                initialIndex={channelIndexHistory.current.get(activeUserId)}
                onIndexChange={index => {
                  // Remove this if we want to remember positions other channels too
                  if (spiedUserId) {
                    return;
                  }

                  channelIndexHistory.current.set(activeUserId, index);

                  analytics.feedIndexChanged(
                    index,
                    loadedUserId,
                    feedShouldPlay,
                  );
                }}
                isOwnFeed={loadedUserId === myUserId}
                setScrollToTop={_scrollToTop =>
                  (scrollToTopRef.current = _scrollToTop)
                }
              />
            );
          }

          return (
            <FeedOnboarding
              key={loadedUserId}
              userId={loadedUserId}
              isPlayAllModalOpen={isPlayAllModalOpen}
              togglePlayAllModal={() => setIsPlayAllModalOpen(v => !v)}
              initialIndex={0}
              onIndexChange={index => {
                analytics.feedOnboardingIndexChanged(
                  index,
                  loadedUserId,
                  feedShouldPlay,
                );
              }}
              setScrollToTop={_scrollToTop =>
                (scrollToTopRef.current = _scrollToTop)
              }
              onboardingState={onboardingState}
              closeOnboardingStep={closeOnboardingStep}
            />
          );
        }}
      />
    </Screen>
  );
};

export default ChannelsScreen;
