import {fetchOwnPlaylists} from '@/api/playlist';
import {
  createUserWithSessionKey,
  fetchUserByAddress,
  fetchUserProfiles,
  linkAddressToUser,
  linkSpinampWallet,
  updateAddressProperties,
  updateUser,
} from '@/api/user';
import {APPLE_REVIEWER_ACCOUNT} from '@/constants/appleReview';
import {predictSpinampWallet} from '@/modules/Wallets/passkeyWallet';
import {Passkey} from '@/services/passkey';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {AppDispatch, RootState} from '@/store';
import {
  assignCollectorToEvents,
  replaceCollectorOnEvents,
} from '@/store/events';
import {
  addPlaylist,
  assignCollectorToPlaylists,
  ensureFavsPlaylistForUser,
  migrateAnonPlaylists,
  removeUserPlaylists,
} from '@/store/playlists';
import {
  selectAnonSession,
  selectIsSessionInitialized,
  selectSessionsList,
  selectSessionsUserIds,
  selectSignerByUserId,
} from '@/store/user/selectors';
import {QueryKeys} from '@/types/queryKeys';
import {ISigner} from '@/types/session';
import {IBaseUser, IPasskeyCredential, IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {createFavsPlaylist} from '@/utils/playlists';
import {createInternalSigner, getSignerFromSessionKey} from '@/utils/signer';

import {
  removeSession,
  setActiveUserId,
  setSignInProcessStep,
  upsertSession,
} from './slice';

const NOT_INITIALIZED_ERROR = new Error(
  'Session is not initialized yet. Please check internet connection',
);

const handleLogin =
  (userId: string, externalSigner: ISigner) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const sessionSigner = createInternalSigner();

    dispatch(setSignInProcessStep('waitingForSignature'));

    await linkAddressToUser({
      userId,
      signerToLink: sessionSigner,
      authSigner: externalSigner,
      addressProperties: {
        isSession: true,
        isPublic: false,
        isWallet: false,
        isPasskey: false,
      },
      onLinking: () => dispatch(setSignInProcessStep('linking')),
    });

    const anonSession = selectAnonSession(getState());

    if (anonSession) {
      const returningUserPlaylists = await fetchOwnPlaylists(sessionSigner);
      dispatch(
        migrateAnonPlaylists({
          playlists: returningUserPlaylists,
          collector: userId,
        }),
      );
      dispatch(
        replaceCollectorOnEvents({
          sourceUserId: anonSession.userId,
          targetUserId: userId,
        }),
      );
      dispatch(removeSession({userId: anonSession.userId}));
    }

    dispatch(
      upsertSession({
        userId: userId,
        sessionKey: sessionSigner.sessionKey,
        authenticatorAddress: externalSigner.address,
      }),
    );

    return userId;
  };

const finalizeSigningIn =
  (userId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    // double check if newly added user has favs playlist created
    dispatch(ensureFavsPlaylistForUser(userId));

    await queryClient.cancelQueries({
      queryKey: [QueryKeys.userProfiles],
      exact: true,
    });
    await queryClient.prefetchQuery({
      queryKey: [QueryKeys.userProfiles],
      queryFn: () => fetchUserProfiles(selectSessionsUserIds(getState())),
    });

    dispatch(setActiveUserId(userId));
  };

export const initializeAnonUser =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const isSessionInitialized = selectIsSessionInitialized(getState());

    if (isSessionInitialized) {
      return;
    }

    const anonSession = await createUserWithSessionKey();
    dispatch(assignCollectorToPlaylists(anonSession.userId));
    dispatch(assignCollectorToEvents(anonSession.userId));
    dispatch(upsertSession(anonSession));
  };
export const loginWithExternalSigner =
  (externalSigner: ISigner, returningUser: IBaseUser) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const sessions = selectSessionsList(getState());

    if (sessions.length === 0) {
      throw NOT_INITIALIZED_ERROR;
    }

    try {
      const userId = await dispatch(
        handleLogin(returningUser.id, externalSigner),
      );

      // Fallback for setting proper flags on external wallet for users registered before we shipped collect on mobile
      try {
        const externalWalletData = returningUser.addresses.find(a =>
          areAddressesEqual(a.address, externalSigner.address),
        );
        if (externalWalletData && !externalWalletData.isWallet) {
          await updateAddressProperties(
            {
              id: externalSigner.address,
              isPasskey: false,
              isWallet: true,
              isSession: false,
            },
            selectSignerByUserId(getState(), userId)!,
          );
        }
      } catch (error) {
        // ignore error to not disturb login flow
        Sentry.captureException(error, {
          extra: {
            context: `Failed to update wallet properties for user: ${userId} and wallet: ${externalSigner.address}`,
          },
        });
      }

      await dispatch(finalizeSigningIn(userId));
    } finally {
      dispatch(setSignInProcessStep('idle'));
    }
  };

export const signupWithExternalSigner =
  (externalSigner: ISigner, username: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const sessions = selectSessionsList(getState());

    if (sessions.length === 0) {
      throw NOT_INITIALIZED_ERROR;
    }

    try {
      const session =
        selectAnonSession(getState()) || (await createUserWithSessionKey());
      const sessionSigner = getSignerFromSessionKey(session.sessionKey);

      dispatch(setSignInProcessStep('waitingForSignature'));

      await linkAddressToUser({
        userId: session.userId,
        signerToLink: externalSigner,
        authSigner: sessionSigner,
        addressProperties: {
          isWallet: true,
          isPublic: true,
          isPasskey: false,
          isSession: false,
        },
        onLinking: () => dispatch(setSignInProcessStep('linking')),
      });

      await updateUser(
        {
          id: session.userId,
          name: username,
        },
        sessionSigner,
      );

      dispatch(
        upsertSession({
          userId: session.userId,
          sessionKey: sessionSigner.sessionKey,
          authenticatorAddress: externalSigner.address,
        }),
      );

      await dispatch(finalizeSigningIn(session.userId));
    } finally {
      dispatch(setSignInProcessStep('idle'));
    }
  };

export const registerWithPasskey =
  (username: string) =>
  async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<IPasskeyCredential> => {
    const sessions = selectSessionsList(getState());

    if (sessions.length === 0) {
      throw NOT_INITIALIZED_ERROR;
    }

    const session =
      selectAnonSession(getState()) || (await createUserWithSessionKey());

    const {credentialId} = await Passkey.create({
      id: session.userId,
      name: username,
    });

    return {
      credentialId,
      username,
      session,
    };
  };

export const linkPasskey =
  ({credentialId: credentialId, username, session}: IPasskeyCredential) =>
  async (dispatch: AppDispatch) => {
    try {
      const passkeySigner = createInternalSigner();
      const predictedWallet = await predictSpinampWallet(
        passkeySigner.sessionKey,
      );
      await Passkey.writeBlob({
        credentialId,
        message: passkeySigner.sessionKey,
      });

      const sessionSigner = getSignerFromSessionKey(session.sessionKey);

      await linkAddressToUser({
        userId: session.userId,
        signerToLink: passkeySigner,
        authSigner: sessionSigner,
        addressProperties: {
          isPasskey: true,
          isPublic: false,
          isWallet: false,
          isSession: false,
        },
      });
      await updateUser(
        {
          id: session.userId,
          name: username,
        },
        sessionSigner,
      );
      await linkSpinampWallet(
        session.userId,
        passkeySigner,
        predictedWallet,
        credentialId,
      );

      dispatch(
        upsertSession({
          userId: session.userId,
          sessionKey: sessionSigner.sessionKey,
          authenticatorAddress: passkeySigner.address,
        }),
      );

      await dispatch(finalizeSigningIn(session.userId));
    } finally {
      dispatch(setSignInProcessStep('idle'));
    }
  };

export const loginWithPasskey =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setSignInProcessStep('requestingPasskey'));

      const {privateKey, credentialId} = await Passkey.get();
      const passkeySigner = getSignerFromSessionKey(privateKey);

      dispatch(setSignInProcessStep('checkingReturningUser'));

      const user = await fetchUserByAddress(passkeySigner.address);

      if (!user) {
        throw new Error('No user found for provided passkey');
      }

      // Double check if flags for already created passkey address are properly configured and update them if needed.
      // This is needed for legacy users who signed up with passkey before we shipped passkey wallet
      const passkeyAddress = user.addresses.find(
        ({address}) => address === passkeySigner.address,
      );
      if (
        passkeyAddress &&
        (!passkeyAddress.isPasskey ||
          passkeyAddress.isWallet ||
          passkeyAddress.isPublic)
      ) {
        await updateAddressProperties(
          {
            id: passkeyAddress.address,
            isPasskey: true,
            isWallet: false,
            isPublic: false,
            isSession: false,
          },
          passkeySigner,
        );
      }

      // Double check if user signing with passkey has his passkey wallet predicted
      if (!user.addresses.some(a => a.isPasskey && a.isWallet)) {
        const predictedWallet = await predictSpinampWallet(
          passkeySigner.sessionKey,
        );
        await linkSpinampWallet(
          user.id,
          passkeySigner,
          predictedWallet,
          credentialId,
        );
      }

      const userIds = selectSessionsUserIds(getState());

      if (userIds.includes(user.id)) {
        dispatch(setActiveUserId(user.id));
        return;
      }

      await dispatch(handleLogin(user.id, passkeySigner));
      await dispatch(finalizeSigningIn(user.id));
    } finally {
      dispatch(setSignInProcessStep('idle'));
    }
  };

// Special case for signing in to demo account for apple reviewer
export const signInToDemoAccount =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(setSignInProcessStep('requestingPasskey'));
      const passkeySigner = getSignerFromSessionKey(
        APPLE_REVIEWER_ACCOUNT.privateKey,
      );

      dispatch(setSignInProcessStep('checkingReturningUser'));

      const user = await fetchUserByAddress(passkeySigner.address);

      if (!user) {
        throw new Error('No user found for provided passkey');
      }

      const userIds = selectSessionsUserIds(getState());

      if (userIds.includes(user.id)) {
        dispatch(setActiveUserId(user.id));
        return;
      }

      await dispatch(handleLogin(user.id, passkeySigner));
      await dispatch(finalizeSigningIn(user.id));
    } finally {
      dispatch(setSignInProcessStep('idle'));
    }
  };

export const signOut =
  (userId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const sessions = selectSessionsList(getState());
    dispatch(removeSession({userId}));
    dispatch(removeUserPlaylists({userId}));
    queryClient.setQueryData<IUser[]>(
      [QueryKeys.userProfiles],
      profiles => profiles && profiles.filter(p => p.id !== userId),
    );

    const newActiveSession = sessions.find(s => s.userId !== userId);

    if (newActiveSession) {
      dispatch(setActiveUserId(newActiveSession.userId));
    } else {
      dispatch(setActiveUserId(undefined));
      dispatch(addPlaylist(createFavsPlaylist()));
    }
  };
