import React, {FC} from 'react';
import {TouchableOpacity} from 'react-native';

import Loader from '@/components/Loader';
import Text, {TextProps} from '@/components/Text';
import {HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './HeaderButton.style';
interface IProps {
  onPress: () => void;
  text: TextProps;
  loaderText?: TextProps;
  isLoading?: boolean;
  disabled?: boolean;
}

const HeaderButton: FC<IProps> = ({
  onPress,
  text,
  loaderText = {},
  disabled = false,
  isLoading = false,
}) => {
  const style = useThemedStyles(styles);

  return (
    <TouchableOpacity
      onPress={onPress}
      hitSlop={HIT_SLOP}
      disabled={disabled || isLoading}
      style={[
        isLoading && style.button_loading,
        disabled && style.button_disabled,
      ]}>
      {isLoading ? (
        <Loader weight="regular" size="xs" textId="saving" {...loaderText} />
      ) : (
        <Text size="s" {...text} />
      )}
    </TouchableOpacity>
  );
};

export default HeaderButton;
