import spacing from '@/constants/spacing';
import {
  calculateBackgroundColorDark,
  calculateBackgroundColorLight,
  createThemedStyles,
} from '@/theme';

export const TABS_HEIGHT = spacing.m + spacing.s;
const SIDE_BORDER_WIDTH = 1;
const TOP_BORDER_WIDTH = 1;
const BOTTOM_BORDER_WIDTH = 1;

export const styles = createThemedStyles(theme => ({
  tabsWrapper: {
    flexDirection: 'row',
    height: TABS_HEIGHT,
    marginHorizontal: -SIDE_BORDER_WIDTH,
  },
  tab: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: theme.colors.borderColor,
    borderBottomWidth: BOTTOM_BORDER_WIDTH,
    backgroundColor: calculateBackgroundColorDark(theme),
  },
  tab_active: {
    borderTopWidth: TOP_BORDER_WIDTH,
    borderBottomWidth: 0,
    borderRightWidth: theme.box.borderRightWidth,
    borderLeftWidth: SIDE_BORDER_WIDTH,
    borderTopRightRadius: theme.box.borderRadius,
    borderTopLeftRadius: theme.box.borderRadius,
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  tab_active_last: {
    marginRight: -theme.box.borderRightWidth,
  },
}));
