import React, {CSSProperties, useEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

import {useThemedStyles} from '@/theme';

import {toolbarOptions} from './constants';
import {styles} from './styles';
import {RichTextEditorComponent} from './types';

import './styles.css';

const RichTextEditor: RichTextEditorComponent = ({
  initialHtml,
  onHtmlChange,
  placeholderTextId,
  onFocus,
  onBlur,
}) => {
  const {formatMessage} = useIntl();
  const style = useThemedStyles(styles);
  const ref = useRef<HTMLDivElement>(null);

  // For some reason quill doesn't stop propagation of keyboard events from the editor.
  // It's conflicting with our keyboard support for player so we need to stop them manually.
  useEffect(() => {
    if (ref.current) {
      const handler = (event: KeyboardEvent) => event.stopPropagation();
      ref.current.addEventListener('keydown', handler);
      return () => ref.current?.removeEventListener('keydown', handler);
    }
  }, []);

  return (
    <div ref={ref}>
      <ReactQuill
        theme="snow"
        style={style.editorWeb as CSSProperties}
        defaultValue={initialHtml}
        onChange={onHtmlChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className="spinamp-text-editor"
        placeholder={
          placeholderTextId ? formatMessage({id: placeholderTextId}) : ''
        }
        modules={{
          toolbar: toolbarOptions,
        }}
        bounds={'.spinamp-text-editor'}
      />
    </div>
  );
};

export default RichTextEditor;
