import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const sectionSpacing = spacing.s * 2;

export const styles = createThemedStyles((theme, responsive) => ({
  scrollContent: {
    flexGrow: 1,
    padding: spacing.m,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  formContent: {
    flex: 1,
    maxWidth: responsive.isDesktop ? 400 : undefined,
  },
  firstSectionTitle: {
    marginTop: -sectionSpacing,
  },
  artistDescription: {
    marginTop: -spacing.l,
    marginBottom: spacing.l,
  },
  audioUploader: {
    marginBottom: sectionSpacing,
  },
  imagePreviewStyle: {
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.borderColor,
  },
  imagePlaceholder: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs * 1.5,
  },
  categories: {
    flexDirection: 'row',
    gap: spacing.xs,
    paddingTop: spacing.xxs,
  },
  genresContainer: {
    zIndex: 1,
  },
  collectibleTiles: {
    flexDirection: 'row',
    gap: spacing.s,
    marginBottom: sectionSpacing,
  },
  editTooltip: {
    transform: [{translateY: -1}],
  },
  walletsContainer: {
    gap: spacing.xs,
  },
  walletRow: {
    flexDirection: 'row',
    gap: spacing.xs,
  },
  walletInfo: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: spacing.xxs,
  },
  walletArrow: {
    alignSelf: 'center',
  },
  walletCard: {
    borderColor: theme.colors.borderColor,
    backgroundColor: theme.colors.background,
  },
  walletError: {
    marginTop: spacing.xs * 1.5,
  },
  submitContainer: {
    borderTopWidth: 1,
    borderColor: theme.colors.borderColor,
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing.xs,
  },
}));
