import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';

import Text from '@/components/Text';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {prettifyAddress} from '@/utils/ethereum';

import {styles} from './WalletWithAddress.style';

interface IProps {
  wallet: IAddress;
  style?: StyleProp<any>;
}

const WalletWithAddress: FC<IProps> = ({wallet, style: customStyle}) => {
  const style = useThemedStyles(styles);

  const isPasskey = isPasskeyWallet(wallet);
  const ens = wallet.ens;

  return (
    <View style={[style.container, customStyle]}>
      <Text size="xs">
        {isPasskey ? 'Passkey Wallet' : ens || prettifyAddress(wallet.address)}
      </Text>
      {(isPasskey || ens) && (
        <Text size="xs">({prettifyAddress(wallet.address)})</Text>
      )}
    </View>
  );
};

export default WalletWithAddress;
