import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';
import Animated, {FadeIn} from 'react-native-reanimated';

import Text, {TextProps} from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './UploadProgress.style';

interface IProps {
  progress: number;
  showPercentage?: boolean;
  height?: number;
  style?: StyleProp<any>;
  textProps?: TextProps;
}

const UploadProgress: FC<IProps> = ({
  progress,
  showPercentage = true,
  height = spacing.s,
  style: customStyle,
  textProps,
}) => {
  const style = useThemedStyles(styles);

  return (
    <Animated.View
      style={[style.container, {height}, customStyle]}
      entering={FadeIn}>
      <View style={[style.content, {width: `${progress}%`}]} />
      {showPercentage && (
        <Text
          weight="semibold"
          size="xs"
          color={progress > 55 ? 'invertedTextColor' : 'textColor'}
          {...textProps}>
          {progress}%
        </Text>
      )}
    </Animated.View>
  );
};

export default UploadProgress;
