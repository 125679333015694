import React from 'react';

import spinamp from '@/themes/spinamp.json';
import {IDynamicTheme, ITheme} from '@/types/theme';

export interface IContextValue {
  theme: ITheme;
  appTheme?: ITheme;
  overrideTheme?: (themesSetting: IDynamicTheme) => void;
  resetTheme?: () => void;
  overrideColors?: (stringifiedColors: string) => void;
  resetColors?: () => void;
}

export const ThemeContext = React.createContext<IContextValue>({
  theme: spinamp,
});
