import React, {FC, ReactNode} from 'react';

import Card from '@/components/Card/Card';
import Text from '@/components/Text';

interface IProps {
  children?: ReactNode;
  isError?: boolean;
}

const InfinityListEmpty: FC<IProps> = ({children, isError = false}) => {
  if (isError) {
    return (
      <Card>
        <Text id="error" weight="semibold" align="center" />
      </Card>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return null;
};

export default InfinityListEmpty;
