import {createSelector} from '@reduxjs/toolkit';

import {RepeatMode} from '@/services/trackPlayer';
import {RootState} from '@/store';
import {getQueueItemsWithTracks, getTrackById} from '@/utils/db';
import {isNotNil} from '@/utils/functions';

export const selectPlayer = (state: RootState) => state.player;

// it is used to re-trigger selectors when react-query tracks db is updated.
// Should be used in every selector which depends on react-query db, e.g. getting full track by track id.
export const selectLastUpdateTime = createSelector(
  selectPlayer,
  player => player.dbSyncTimestamp,
);

export const selectIsPlaying = createSelector(
  selectPlayer,
  player => player.isPlaying,
);

export const selectIsBuffering = createSelector(
  selectPlayer,
  player => player.isBuffering,
);

export const selectShuffledQueueItems = createSelector(
  selectPlayer,
  player => player.queue?.shuffledItems || [],
);

export const selectIsShuffle = createSelector(
  selectShuffledQueueItems,
  shuffledItems => shuffledItems.length > 0,
);

export const selectRepeatMode = createSelector(
  selectPlayer,
  player => player.repeatMode,
);

export const selectRateMode = createSelector(
  selectPlayer,
  player => player.rateMode,
);

export const selectVolume = createSelector(selectPlayer, player =>
  isNotNil(player.volume) ? player.volume : 1,
);

export const selectQueue = createSelector(selectPlayer, player => player.queue);

export const selectQueueOriginalTracks = createSelector(
  selectQueue,
  selectLastUpdateTime,
  queue => getQueueItemsWithTracks(queue?.items),
);

export const selectQueueTracks = createSelector(
  selectQueue,
  selectIsShuffle,
  selectLastUpdateTime,
  (queue, isShuffle) => {
    if (!queue) {
      return [];
    }

    return getQueueItemsWithTracks(
      isShuffle ? queue.shuffledItems : queue.items,
    );
  },
);

export const selectQueueOriginalTracksLength = createSelector(
  selectQueueOriginalTracks,
  queueTracks => queueTracks.length,
);

export const selectQueueTracksLength = createSelector(
  selectQueueTracks,
  queueTracks => queueTracks.length,
);

export const selectQueueShuffledTracksLength = createSelector(
  selectQueue,
  queue => queue?.shuffledItems.length || 0,
);

export const selectCurrentTrackKey = createSelector(
  selectQueue,
  queue => queue?.currentTrackKey,
);

export const selectCurrentTrackIndex = createSelector(
  selectQueueTracks,
  selectCurrentTrackKey,
  (items, key) => items.findIndex(item => item.key === key),
);

export const selectCurrentQueueItem = createSelector(
  selectQueue,
  selectCurrentTrackKey,
  (queue, key) => queue?.items.find(item => item.key === key),
);

export const selectCurrentTrackId = createSelector(
  selectCurrentQueueItem,
  queueItem => queueItem?.trackId || null,
);

export const selectCurrentTrack = createSelector(
  selectCurrentTrackId,
  selectLastUpdateTime,
  id => (id ? getTrackById(id) : null),
);

export const selectPreviousTrack = createSelector(
  selectCurrentTrackIndex,
  selectQueueTracks,
  (index, tracks) => tracks[index - 1],
);

export const selectNextTrack = createSelector(
  selectRepeatMode,
  selectIsShuffle,
  selectCurrentTrackIndex,
  selectQueueTracks,
  (repeatMode, isShuffle, index, tracks) => {
    const isLast = index === tracks.length - 1;

    if (isLast && (isShuffle || repeatMode === RepeatMode.Queue)) {
      return tracks[0];
    }

    return tracks[index + 1];
  },
);

export const selectHasNext = createSelector(
  selectNextTrack,
  nextTrack => !!nextTrack,
);

export const selectIsActive = createSelector(
  selectPlayer,
  player => player.isActive,
);
