import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const CLOSE_ICON_SIZE = 14;

export const styles = createThemedStyles(() => ({
  container: {
    alignItems: 'center',
    gap: spacing.xxs,
    paddingTop: spacing.xs,
  },
  textContainer: {
    flexDirection: 'row',
    gap: spacing.s,
  },
}));
