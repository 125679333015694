import React, {FC} from 'react';

import InfinityList from '@/components/InfinityList';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {TRACK_CARD_HEIGHT, TrackCardPreview} from '@/components/TrackCard';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useFavToggle} from '@/hooks/useFavToggle';
import DelayedFadeIn from '@/screens/SpinampWrapped/components/DelayedFadeIn';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useSpinampWrappedAudio} from '@/screens/SpinampWrapped/useSpinampWrappedAudio';
import {ITrack} from '@/types/common';

const TopTracks: FC = () => {
  const {activePageId, tracks, nextPage} = useSpinampWrapped();
  const isActive = activePageId === 'topTracks';

  useSpinampWrappedAudio(isActive, tracks[1]?.track);

  if (tracks.length === 0) {
    return null;
  }

  return (
    <InfinityList
      style={{flex: 1}}
      data={tracks}
      keyExtractor={t => t.track.id}
      itemSize={TRACK_CARD_HEIGHT}
      contentContainerStyle={{padding: spacing.s, paddingVertical: spacing.m}}
      ListHeaderComponent={
        <Space mb="m">
          <Text
            align="center"
            weight="bold"
            size="l"
            id="spinampWrapped.topTracks.title"
          />
          <Space mt="s" />
          <Text
            align="center"
            size="xs"
            id="spinampWrapped.topTracks.description"
          />
          <Space mt="xs" />
          <Text align="center" size="xs" id="spinampWrapped.topTracks.fav" />
        </Space>
      }
      renderItem={({item}) => <TopTrackCard track={item.track} />}
      ListFooterComponent={
        <Space mv="s" style={{alignItems: 'center'}}>
          <DelayedFadeIn isActive={isActive}>
            <TransparentButton
              onPress={nextPage}
              text={{id: 'spinampWrapped.next'}}
            />
          </DelayedFadeIn>
        </Space>
      }
    />
  );
};

const TopTrackCard: FC<{track: ITrack}> = ({track}) => {
  const {isFav, toggleFav} = useFavToggle(track.id);

  return (
    <TrackCardPreview
      onPress={toggleFav}
      track={track}
      suffixIcon={{
        name: 'heart',
        provider: 'custom',
        size: 18,
        fill: isFav,
        color: isFav ? 'favoritesColor' : 'textColor',
      }}
    />
  );
};

export default TopTracks;
