import React, {FC, ReactNode} from 'react';
import {Pressable, StyleProp} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import Icon, {IIconProps} from '@/components/Icon';
import spacing, {getHitSlop} from '@/constants/spacing';
import {useTheme, useThemedStyles} from '@/theme';
import {Color} from '@/types/theme';
import {openLinkSafe} from '@/utils/linking';
import {isWeb} from '@/utils/platform';

import {styles, SIZE} from './IconButton.style';

interface IProps {
  href?: string;
  onPress?: () => void;
  onLongPress?: () => void;
  icon?: IIconProps;
  selected?: boolean;
  selectedColor?: Color;
  size?: number;
  style?: StyleProp<any>;
  disabled?: boolean;
  rippleDisabled?: boolean;
  hitSlop?: keyof typeof spacing | null;
  children?: ReactNode;
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const IconButton: FC<IProps> = ({
  href,
  onPress,
  onLongPress,
  icon,
  selected = false,
  selectedColor = 'backgroundDark',
  size = SIZE,
  style: customStyle,
  disabled = false,
  rippleDisabled = false,
  hitSlop = 'xs',
  children,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const pressed = useSharedValue(false);
  const hovered = useSharedValue(false);

  const hoverStyle = useAnimatedStyle(() => ({
    opacity: withTiming(hovered.value && !pressed.value ? 0.4 : 1, {
      duration: 150,
    }),
  }));

  const pressStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: pressed.value
          ? withSpring(1.1, {mass: 0.1})
          : selected
          ? withSpring(1, {mass: 0.1})
          : withTiming(0, {duration: 150}),
      },
    ],
    opacity: withTiming(pressed.value || selected ? 1 : 0.2, {duration: 300}),
  }));

  const iconStyle = useAnimatedStyle(() => ({
    transform: [{scale: withSpring(pressed.value ? 0.8 : 1, {mass: 0.1})}],
  }));

  return (
    <AnimatedPressable
      // @ts-ignore
      href={href}
      hrefAttrs={{rel: 'nofollow', target: 'blank'}}
      onPress={() => {
        if (!href) {
          onPress?.();
        } else if (!isWeb) {
          openLinkSafe(href);
        }
      }}
      onLongPress={onLongPress}
      onPressIn={() => (pressed.value = true)}
      onPressOut={() => (pressed.value = false)}
      onHoverIn={() => (hovered.value = true)}
      onHoverOut={() => (hovered.value = false)}
      disabled={disabled}
      hitSlop={hitSlop && getHitSlop(hitSlop)}
      style={[
        style.button,
        {width: size, height: size},
        customStyle,
        disabled && style.button_disabled,
      ]}>
      {!rippleDisabled && (
        <Animated.View
          style={[
            style.pressIndicator,
            {
              borderRadius: size / 2,
              backgroundColor: theme.colors[selectedColor],
            },
            pressStyle,
          ]}
        />
      )}
      <Animated.View style={[hoverStyle, iconStyle]}>
        {icon && <Icon {...icon} />}
        {children}
      </Animated.View>
    </AnimatedPressable>
  );
};

export default IconButton;
