import React, {FC, ReactNode} from 'react';
import {Pressable, ViewStyle} from 'react-native';

import {RectOrSize} from '@/types/common';
import {Color} from '@/types/theme';
interface TouchableProps {
  onPress?: () => void;
  onLongPress?: () => void;
  disabled?: boolean;
  style?: ViewStyle | (ViewStyle | undefined | boolean)[];
  hoveredStyle?: ViewStyle;
  pressedStyle?: ViewStyle;
  children:
    | (({
        pressed,
        hover,
        actionColor,
      }: {
        pressed?: boolean;
        hover?: boolean;
        actionColor: (color?: Color) => Color;
      }) => ReactNode)
    | ReactNode;
  hitSlop?: RectOrSize;
}

const actionColorResolver = (
  pressed: boolean | undefined,
  hover: boolean | undefined,
  color?: Color,
): Color => {
  if (pressed) {
    return 'invertedTextColor';
  }
  if (hover) {
    return color || 'textColor';
  }
  return color || 'textColor';
};

const Touchable: FC<TouchableProps> = ({
  children,
  onPress,
  onLongPress,
  disabled,
  style,
  hoveredStyle,
  pressedStyle,
  hitSlop,
  ...props
}) => {
  return (
    <Pressable
      onPress={onPress}
      onLongPress={onLongPress}
      disabled={disabled}
      {...props}
      // @ts-ignore
      style={({pressed, hovered}) => [
        style,
        hovered && hoveredStyle,
        pressed && pressedStyle,
      ]}
      hitSlop={hitSlop}>
      {typeof children === 'function'
        ? // @ts-ignore
          ({pressed, hovered}) => {
            const actionColor = (color?: Color): Color =>
              actionColorResolver(pressed, hover, color);
            const hover = hovered && !pressed;
            return children({
              pressed,
              hover,
              actionColor,
            });
          }
        : children}
    </Pressable>
  );
};

export default Touchable;
