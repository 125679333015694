import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  content: {
    padding: spacing.l,
    gap: spacing.l + spacing.xs,
  },
  receiveInfoContainer: {
    alignItems: 'center',
    gap: spacing.s,
  },
  addressButton: {
    backgroundColor: theme.colors.background,
  },
  chainInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  dividerContainer: {
    flexDirection: 'row',
    gap: spacing.xs,
    alignItems: 'center',
  },
  dividerLine: {
    flex: 1,
  },
}));
