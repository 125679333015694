import {GlobalPressCallback} from '@/components/GlobalPressHandler';
import {isTouchScreen} from '@/utils/platform';

const handlers = {
  addEventListener: (listener: GlobalPressCallback) => {
    window.addEventListener('mouseup', listener);
    if (isTouchScreen) {
      window.addEventListener('touchmove', listener);
    }

    return () => {
      window.removeEventListener('mouseup', listener);
      if (isTouchScreen) {
        window.removeEventListener('touchmove', listener);
      }
    };
  },
};

export const useGlobalPressHandler = () => handlers;
