import React, {FC} from 'react';
import {TouchableOpacity} from 'react-native';

import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import Touchable from '@/components/Touchable/Touchable';
import {useTheme, useThemedStyles} from '@/theme';

import {styles} from './SearchHistory.style';

interface IProps {
  history: string[];
  clearHistory: () => void;
  selectItem: (phrase: string) => void;
  removeItem: (phrase: string) => void;
}

const SearchHistory: FC<IProps> = ({
  history,
  clearHistory,
  selectItem,
  removeItem,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();

  return (
    <>
      <Space style={style.searchHistoryHeader}>
        <Text
          id="search.recent"
          weight="semibold"
          size="s"
          uppercase={theme.text.sectionHeaderUppercase}
        />
        {history.length > 0 && (
          <TouchableOpacity onPress={clearHistory}>
            <Text id="search.clearAll" size="xs" weight="semibold" />
          </TouchableOpacity>
        )}
      </Space>
      <Space mt="xs" />
      {history.map(phrase => (
        <Touchable key={phrase} onPress={() => selectItem(phrase)}>
          {({pressed, hover}) => (
            <Space
              style={[
                style.searchHistoryItem,
                hover && style.searchHistoryItem_hover,
                pressed && style.searchHistoryItem_pressed,
              ]}>
              <Space pv="xs" style={style.searchHistoryPhrase} pr="xs">
                <Text numberOfLines={1}>{phrase}</Text>
              </Space>
              <TouchableOpacity
                style={style.searchRemoveAction}
                onPress={() => removeItem(phrase)}>
                <Icon provider="ant" name="close" size={14} />
              </TouchableOpacity>
            </Space>
          )}
        </Touchable>
      ))}
    </>
  );
};

export default SearchHistory;
