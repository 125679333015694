import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const TILE_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  processingContainer: {
    gap: spacing.xl,
  },
  processingTrackTile: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
    borderRadius: theme.box.borderRadius,
    height: TILE_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundLight,
    overflow: 'hidden',
    paddingRight: spacing.xs,
  },
  artwork: {
    height: '100%',
    aspectRatio: 1,
  },
  trackInfo: {
    flex: 1,
    paddingHorizontal: spacing.xs,
    gap: spacing.xxs,
  },
}));
