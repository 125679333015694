import {encodeFunctionData} from 'viem';

import {useStore} from '@/hooks/useRedux';
import {useTransactionEventTracking} from '@/modules/Transactions/eventsTracking';
import {
  ITransactionEvent,
  TransactionState,
} from '@/modules/Transactions/types';
import {useTransferState} from '@/modules/Transfer/TrackTransfer/useTransferState';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {selectTransactionById} from '@/store/transactions/selectors';
import {generateId} from '@/utils/functions';

export const useTrackTransferEvent = () => {
  const {getState} = useStore();

  const {transfer, track, senderWallet, gasEstimation} = useTransferState();

  const {mutate} = useTransactionEventTracking();

  const trackEvent = (eventName: ITransactionEvent['eventName']) => {
    const transferState = selectTransactionById(
      getState(),
      transfer.id,
      'trackTransfer',
    ) as Extract<TransactionState, {type: 'trackTransfer'}>;

    if (transferState?.userId && senderWallet) {
      mutate({
        id: generateId(),
        timestamp: Date.now(),
        transactionId: transferState.id,
        type: 'transfer' as ITransactionEvent['type'],
        userId: transferState.userId,
        eventName,
        trackId: track.id,
        chainId: transferState.chainId,
        tokenId: transferState.tokenId,
        address: senderWallet.address,
        isPasskey: isPasskeyWallet(senderWallet),
        receivingAddress: transferState.to,
        amount: gasEstimation?.totalGas?.toString(),
        txHash: transferState.txHash,
        userOpHash: transferState.userOpHash,
        error: transferState.error?.message,
        callData: encodeFunctionData(
          getTransferWriteContractInput(transferState),
        ),
        platformId: track.platformId,
      });
    }
  };

  return {
    trackEvent,
  };
};
