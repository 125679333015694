import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  mintCheckbox: {
    flexDirection: 'row',
    gap: spacing.xs,
    alignItems: 'center',
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  action: {
    minWidth: 70,
    justifyContent: 'center',
  },
}));
