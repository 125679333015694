import {useEffect} from 'react';

import {addOnlineEventListener} from '@/utils/appEvents';

const useOnOnline = (onOnline: () => void) => {
  useEffect(() => {
    const unsubscribe = addOnlineEventListener(onOnline);

    return unsubscribe;
  }, [onOnline]);
};

export default useOnOnline;
