import {FC} from 'react';
import {useWindowDimensions} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import {Toast, IToastProps} from './Toast';

export const DraggableToast: FC<IToastProps> = props => {
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  const positionX = useSharedValue(0);
  const positionY = useSharedValue(0);

  const windowSize = useWindowDimensions();

  const gesture = Gesture.Pan()
    .onUpdate(event => {
      if (event.absoluteX >= 0 && event.absoluteX <= windowSize.width) {
        translateX.value = positionX.value + event.translationX;
      }

      if (event.absoluteY >= 0 && event.absoluteY <= windowSize.height) {
        translateY.value = positionY.value + event.translationY;
      }
    })
    .onFinalize(() => {
      positionX.value = translateX.value;
      positionY.value = translateY.value;
    });

  const style = useAnimatedStyle(() => ({
    transform: [{translateX: translateX.value}, {translateY: translateY.value}],
  }));

  return (
    <GestureDetector gesture={gesture}>
      <Animated.View style={style}>
        <Toast {...props} />
      </Animated.View>
    </GestureDetector>
  );
};
