import {ITrack} from '@/types/common';
import {IBaseUser} from '@/types/user';
import {getUserDisplayName} from '@/utils/user';

import {isArweaveURL} from './arweave';
import {getImageUrl} from './ipfs';

// See https://cloudinary.com/documentation/transformation_reference#fl_attachment
export const formatFilenameForCloudinary = (filename: string) => {
  const disallowedCharacters = /[^\w\d-! .]+/g;

  return (
    filename
      .replaceAll(disallowedCharacters, '')
      // `.` is only allowed in the escaped form
      .replaceAll('.', '%2E')
  );
};

// See https://cloudinary.com/documentation/transformation_reference#fl_attachment
export const makeCloudinaryDownloadUrl = (
  url: string,
  fileType: string,
  downloadFilename: string,
) =>
  setTransformationsOnCloudinaryUrl(
    url,
    `f_${fileType},fl_attachment:${downloadFilename}`,
  );

// Function to upgrade old cloudinary urls to new ones, for backwards-compatibility
export const upgradeCloudinaryUrl = (url: string) => {
  const oldRegex = /https:\/\/media\.spinamp\.xyz\/v1\/([a-zA-Z0-9]+)/;
  const match = url.match(oldRegex);
  if (match && match[1]) {
    const ipfsCid = match[1];
    // We aren't sure if the url is for an image or for audio,
    // but in almost all cases it should be image, so we use
    // getImageUrl rather than getAudioUrl
    url = getImageUrl(ipfsCid) || '';
  }
  return url;
};

export const setTransformationsOnCloudinaryUrl = (
  url: string,
  transformationString: string,
) => {
  if (isArweaveURL(url)) {
    return url;
  }
  url = upgradeCloudinaryUrl(url);

  const [prefix, suffix] = url.split('/upload/');
  return `${prefix}/upload/${transformationString}/${suffix}`;
};

// Set of functions for generating multilayer images on cloudinary
// For now is tailored only for Spinamp Wrapped
const generateTextLayer = ({
  text,
  fontSize,
  x,
  y,
}: {
  text: string;
  fontSize: number;
  x: number;
  y: number;
}) => {
  return `/co_rgb:1e4a4f,l_text:Montserrat_${fontSize}_regular_left:${encodeURIComponent(
    text,
  )}/fl_layer_apply,x_${x},y_${y}`;
};

const generateImageLayer = ({
  imageHash,
  size,
  x,
  y,
}: {
  imageHash: string;
  size: number;
  x: number;
  y: number;
}) => {
  return `/l_ipfs_image:${encodeURIComponent(
    imageHash,
  )}/c_fill,h_${size},w_${size}/fl_layer_apply,x_${x},y_${y}`;
};

export const prepareSpinampWrappedImage = (
  user: IBaseUser,
  tracks: ITrack[],
) => {
  const url = 'https://content.spinamp.xyz/image/upload';
  return prepareSpinampWrappedImageBase(user, tracks, url);
};

export const prepareSpinampWrappedImageFromVideo = (
  user: IBaseUser,
  tracks: ITrack[],
) => {
  const url = 'https://content.spinamp.xyz/video/upload';
  return prepareSpinampWrappedImageBase(user, tracks, url);
};

export const prepareSpinampWrappedImageBase = (
  user: IBaseUser,
  tracks: ITrack[],
  url: string,
) => {
  // base image size is 800x418
  const baseImageName = 'ers2xgi9j05neetlatfx';

  // tweaked to find maximum length fitting in image
  const maxNameLength = 30;

  url += generateTextLayer({
    text: `Explore the summary of ${getUserDisplayName(user).slice(
      0,
      maxNameLength,
    )}'s 2023 Spinamp journey`,
    fontSize: 18,
    x: 0,
    y: -130,
  });

  const artworkHashes = tracks
    .map(t => t.lossyArtworkIpfsHash)
    .filter(hash => !!hash)
    .slice(0, 10);
  const imagesCount = artworkHashes.length;

  // That's default and most expected case
  if (imagesCount === 10) {
    const imageSize = 100;
    const yOffset = 25;

    artworkHashes.forEach((hash, index) => {
      url += generateImageLayer({
        imageHash: hash!,
        size: imageSize,
        x: (index % 5) * imageSize - ((5 - 1) * imageSize) / 2,
        y: index >= 5 ? imageSize - yOffset : -yOffset,
      });
    });
  } else {
    const imageSize = 70;

    artworkHashes.forEach((hash, index) => {
      url += generateImageLayer({
        imageHash: hash!,
        size: imageSize,
        x: index * imageSize - ((imagesCount - 1) * imageSize) / 2,
        y: 0,
      });
    });
  }

  return `${url}/ipfs_image/${baseImageName}`;
};
