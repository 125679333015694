import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme/createThemedStyles';

export const styles = createThemedStyles(theme => ({
  image: {
    ...StyleSheet.absoluteFillObject,
  },
  hasBorderRadius: {
    // perfectly tweaked to make sure image fits inside a rounded border, otherwise we see a slightly white space
    borderRadius: Math.max(0, theme.box.borderRadius - 3),
  },
  fallbackWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
