import React, {FC} from 'react';
import {FlatList, View} from 'react-native';

import {IColorConfig} from '../types';
import Pill from '@/components/Pill';
import Space from '@/components/Space';
import {useThemedStyles} from '@/theme';
import {IBasicTheme} from '@/types/theme';

import {styles} from './ColorPills.style';

interface IColorPillConfig extends IColorConfig {
  color?: string;
}

interface IProps {
  editedColor: keyof IBasicTheme;
  setEditedColor: (color: keyof IBasicTheme) => void;
  colors: IColorPillConfig[];
}

const Separator = () => <Space w="xs" />;

const ColorPills: FC<IProps> = ({colors, editedColor, setEditedColor}) => {
  const style = useThemedStyles(styles);

  return (
    <FlatList
      horizontal
      showsHorizontalScrollIndicator={false}
      bounces={false}
      data={colors}
      keyExtractor={color => color.name}
      ItemSeparatorComponent={Separator}
      contentContainerStyle={style.container}
      renderItem={({item: colorConfig}) => (
        <Pill
          onPress={() => setEditedColor(colorConfig.name)}
          isSelected={editedColor === colorConfig.name}
          text={{
            id: colorConfig.labelId,
          }}
          prefixComponent={
            <View
              style={[
                style.colorPreview,
                !!colorConfig.color && {backgroundColor: colorConfig.color},
              ]}
            />
          }
        />
      )}
    />
  );
};

export default ColorPills;
