import React, {FC, ReactNode, useState} from 'react';
import {LayoutChangeEvent, StyleProp} from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import {useThemedStyles} from '@/theme';

import {styles} from './Collapse.style';

interface IProps {
  isOpen: boolean;
  children: ReactNode;
  onHeightChange?: (height: number) => void;
  containerStyle?: StyleProp<any>;
}

const Collapse: FC<IProps> = ({
  isOpen,
  children,
  onHeightChange,
  containerStyle: customContainerStyle,
}) => {
  const style = useThemedStyles(styles);
  const [height, setHeight] = useState(0);

  const openAnimation = useDerivedValue(
    () =>
      withTiming(isOpen ? 1 : 0, {
        duration: 200,
      }),
    [isOpen],
  );

  const containerStyle = useAnimatedStyle(() => ({
    minHeight: interpolate(openAnimation.value, [0, 1], [0, height]),
  }));

  const contentStyle = useAnimatedStyle(
    () => ({
      // It's necessary if initial render is in open state.
      // We don't know height yet, so we want to render children in relative way before.
      position: !height && isOpen ? 'relative' : 'absolute',
    }),
    [height, isOpen],
  );

  const onLayout = (event: LayoutChangeEvent) => {
    const {layout} = event.nativeEvent;
    if (height !== layout.height) {
      onHeightChange?.(layout.height);
      setHeight(layout.height);
    }
  };

  return (
    <Animated.View
      style={[style.container, containerStyle, customContainerStyle]}>
      <Animated.View style={[style.content, contentStyle]} onLayout={onLayout}>
        {children}
      </Animated.View>
    </Animated.View>
  );
};

export default Collapse;
