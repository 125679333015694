import {
  PublicClient,
  createPublicClient,
  formatUnits,
  getAddress,
  http,
  isAddress,
  decodeEventLog,
  formatGwei as viemFormatGwei,
  Log,
  formatEther,
  parseEther,
} from 'viem';

import MetaABI from '@/abi/MetaABI.json';
import {getChainById} from '@/constants/chains';
import {specialAddresses} from '@/constants/specialAddresses';
import {ITransactionToken} from '@/modules/Collect/types';
import {Sentry} from '@/services/sentry';
import {tenderlyApi} from '@/services/tenderly';
import {AddressString, ICurrency, IExchangeRates} from '@/types/common';

import {areElementsShared} from './functions';

export const ETHEREUM_NULL_ADDRESS =
  '0x0000000000000000000000000000000000000000';

export const GAS_BUFFER_MULTIPLIER = 1.3;

export const getChainClient = (chainId: number = 1): PublicClient => {
  const chain = getChainById(chainId);

  return createPublicClient({
    chain: chain.chain,
    transport: http(chain.rpcUrl),
  });
};

export const formatAddressId = (
  address?: string,
): AddressString | undefined => {
  if (!address) {
    return undefined;
  }

  return address.toLowerCase() as AddressString;
};

export const isAddressValid = (address: string): Boolean => {
  return isAddress(address);
};

export const shortenAddress = (
  address: string,
  visibleChars: number = 4,
): string =>
  `${address.slice(0, visibleChars + 1)}...${address.slice(-visibleChars)}`;

export const getSpecialAddress = (address: string) =>
  specialAddresses[address.toLowerCase()];

export const prettifyAddress = (
  address: string,
  visibleChars: number = 4,
): string => {
  const addressConfig = getSpecialAddress(address);

  if (addressConfig) {
    return addressConfig.name;
  }

  return shortenAddress(address, visibleChars);
};

export const isValidEns = (ens: string): Boolean =>
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/.test(
    ens,
  );

export const nullifyAddress = (
  address: string | null = null,
): string | null => {
  if (address === ETHEREUM_NULL_ADDRESS) {
    return null;
  }

  return address;
};

export const getExplorerLink = (
  explorerUrl: string = 'https://etherscan.io',
  contractAddress: string,
  tokenId?: string,
) => {
  const link = `${explorerUrl}token/${contractAddress}`;

  if (tokenId) {
    return `${link}?a=${tokenId}`;
  }

  return link;
};

export const getExplorerTxLink = (
  explorerUrl: string = 'https://etherscan.io',
  txHash?: string,
) => {
  if (!txHash) {
    return undefined;
  }

  return `${explorerUrl}/tx/${txHash}`;
};

export const prettifyTokenId = (tokenId: string, visibleChars: number = 2) => {
  if (tokenId.length < 5 || tokenId.length < visibleChars) {
    return tokenId;
  }

  return `${tokenId.slice(0, visibleChars)}..${tokenId.slice(-visibleChars)}`;
};

export const truncatePrice = (price: string, digits: number = 3) => {
  return Number(price)
    .toFixed(digits)
    .replace(/\.?0+$/, '');
};

export const formatPrice = (
  value: bigint | string,
  currency: ICurrency,
  digits: number = 5,
) => {
  const price = formatUnits(BigInt(value), currency.decimals);
  const truncatedPrice = truncatePrice(price, digits);

  return `${truncatedPrice} ${currency.symbol}`;
};

export const formatGwei = (value: bigint, currency: ICurrency) => {
  const price = viemFormatGwei(value);

  return `${price} ${currency.symbol}`;
};

export const areAddressesEqual = (
  address1?: string | null,
  address2?: string | null,
) => {
  if (!address1 || !address2) {
    return false;
  }

  return formatAddressId(address1) === formatAddressId(address2);
};

export const formatAddressChecksum = (address: string) =>
  getAddress(address) as AddressString;

export const formatAddressChecksumOptional = (address?: string) => {
  if (!address) {
    return undefined;
  }

  try {
    return formatAddressChecksum(address);
  } catch (e) {
    return undefined;
  }
};

export const estimateGasLimit = async (
  chainId: number = 1,
  transaction: {
    from: string;
    to?: string;
    data?: string;
    value?: BigInt | string;
  },
) => {
  const simulationResult = await tenderlyApi.post('simulate', {
    network_id: chainId,
    from: transaction.from,
    to: transaction.to,
    input: transaction.data,
    value: transaction.value?.toString() || 0,
    estimate_gas: true,
    simulation_mode: 'quick',
    state_objects: {
      [transaction.from]: {
        balance: '9999000000000000000000',
      },
    },
  });

  if (simulationResult.data.simulation.error_message) {
    return Promise.reject(
      new Error(simulationResult.data.simulation.error_message),
    );
  }

  const gasLimit = BigInt(simulationResult.data.transaction.gas_used);

  return scaleAmount(gasLimit, GAS_BUFFER_MULTIPLIER);
};

/**
 * Returns whether one or more addresses are shared between two arrays of addresses.
 */
export const areAddressesShared = (
  addresses1: (string | null | undefined)[],
  addresses2: (string | null | undefined)[],
): boolean => areElementsShared(addresses1, addresses2, areAddressesEqual);

export const chainIdToHex = (chainId: number) => `0x${chainId.toString(16)}`;

export const scaleAmount = (value: bigint, multiplier: number) => {
  return (value * BigInt(multiplier * 100)) / 100n;
};

export const addGasToPrice = (_price: bigint | string, gas?: bigint) => {
  const price = BigInt(_price);

  if (!gas) {
    return price;
  }

  return price + gas;
};

export const ethToUsd = (
  eth: number | string,
  chainId: number,
  exchangeRates: IExchangeRates,
) => {
  const ethNumber = Number(eth);

  if (isNaN(ethNumber)) {
    return 0;
  }

  const usdPrice = ethNumber * exchangeRates[chainId];
  return Math.round(usdPrice * 100) / 100;
};

export const weiToUsd = (
  wei: bigint | string,
  chainId: number,
  exchangeRates: IExchangeRates,
) => {
  const eth = formatEther(BigInt(wei));
  return ethToUsd(eth, chainId, exchangeRates);
};

export const usdToEth = (
  usd: number,
  chainId: number,
  exchangeRates: IExchangeRates,
  digits: number = 6,
) => {
  const eth = usd / exchangeRates[chainId];

  return truncatePrice(eth.toString(), digits);
};

interface ILogEvent {
  eventName: string;
  args: {
    tokenId?: bigint;
    id?: bigint;
    ids?: bigint[];
  };
}

export const getTokenFromLogs = (
  logs: Pick<Log, 'data' | 'topics'>[],
  txHash: string,
): ITransactionToken | undefined => {
  try {
    const eventNames = ['Transfer', 'TransferSingle', 'TransferBatch'];
    const matchingEvents: Array<ILogEvent> = [];

    logs.forEach(log => {
      eventNames.forEach(eventName => {
        try {
          const decodedEvent = decodeEventLog({
            abi: MetaABI.abi,
            data: log.data,
            topics: log.topics,
            eventName,
          }) as ILogEvent;

          matchingEvents.push(decodedEvent);
        } catch (error) {
          // ignore
        }
      });
    });

    const args = matchingEvents[0].args;

    const erc721Token = args?.tokenId;

    if (erc721Token) {
      return {
        tokenId: erc721Token.toString(),
        type: 'erc721',
      };
    }

    const erc1155Token = args?.id || args?.ids?.[0];

    if (erc1155Token) {
      return {
        tokenId: erc1155Token.toString(),
        type: 'erc1155',
      };
    }

    throw new Error(`tokenId not found in logs for tx ${txHash}`);
  } catch (error) {
    Sentry.captureException(error, {
      extra: {context: 'Error reading transaction logs'},
    });
    return undefined;
  }
};
