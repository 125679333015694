import {useMutation} from '@tanstack/react-query';

import {ITransactionEvent} from '@/modules/Transactions/types';
import {MutationKeys} from '@/types/mutationKeys';

export const useTransactionEventTracking = () => {
  return useMutation<void, unknown, ITransactionEvent>({
    mutationKey: [MutationKeys.transactionEvent],
  });
};
