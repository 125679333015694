import {Dimensions} from 'react-native';

import {createThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

const screenSize = Dimensions.get('window');

export const FEED_CARD_WIDTH = isWeb
  ? 300
  : Math.round(0.75 * screenSize.width);

const ratio = isWeb || screenSize.height > 700 ? 4 / 3 : 1.25;

export const FEED_CARD_HEIGHT = Math.round(ratio * FEED_CARD_WIDTH);

export const styles = createThemedStyles(theme => ({
  container: {
    borderWidth: 1,
    borderColor: theme.colors.activeBorder,
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    paddingVertical: 0,

    flex: 1,
    flexBasis: FEED_CARD_HEIGHT,

    maxHeight: FEED_CARD_HEIGHT,
    width: FEED_CARD_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto',

    overflow: 'hidden',
  },
  flexContainer: {
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));
