import spacing, {DESKTOP_LAYOUT_SIZE} from '@/constants/spacing';
import {calculateBackgroundColor, createThemedStyles} from '@/theme';

export const styles = createThemedStyles((theme, responsive) => ({
  root: {
    flex: 1,
    width: '100%',
    height: '100%',
    backgroundColor: calculateBackgroundColor(theme),
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: responsive.isSmallDesktop ? 'flex-start' : 'center',
  },
  content: {
    flexBasis: DESKTOP_LAYOUT_SIZE.contentContainerBase,
    flexShrink: 2,
    flexGrow: responsive.isSmallDesktop ? 1 : 0,
    borderColor: theme.colors.borderColor,
    borderLeftWidth: 2,
    borderRightWidth: 2,
  },
  playerContainer: {
    alignSelf: 'flex-start',
    padding: spacing.xl,
    flexBasis: DESKTOP_LAYOUT_SIZE.playerContainerBase + 2 * spacing.xl,
    flexShrink: 3,
    flexGrow: 0,
    // it helps keeping player container in good looking proportion while adopting to window size
    aspectRatio: DESKTOP_LAYOUT_SIZE.playerContainerRatio,
    maxHeight: '100%',
  },
}));
