import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';

import {useAppDispatch} from '@/hooks/useRedux';
import ExpandableToast from '@/modules/Toasts/ExpandableToast';
import {TransactionState} from '@/modules/Transactions/types';
import {updateTransaction} from '@/store/transactions/slice';
import {RootStackNavigationParams, Routes} from '@/types/routes';

interface IProps {
  transaction: TransactionState;
}

const TransactionToast: FC<IProps> = ({transaction}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {step, error, id, type} = transaction;

  return (
    <ExpandableToast
      onPress={() => {
        dispatch(updateTransaction({id, type, update: {isMinimized: false}}));

        if (type === 'collect') {
          navigation.navigate(Routes.Collect, {
            transactionId: transaction.id,
            slug: transaction.slug,
            referral: transaction.referralAddress,
          });
        } else if (type === 'trackTransfer') {
          navigation.navigate(Routes.Transfer, {
            transferId: transaction.id,
            from: transaction.from,
            to: transaction.to,
            chainId: transaction.chainId,
            slug: transaction.slug,
            contract: transaction.contract,
            tokenId: transaction.tokenId,
            tokenType: transaction.tokenType,
          });
        } else if (type === 'cryptoTransfer') {
          navigation.navigate(Routes.CryptoTransfer, {
            transferId: transaction.id,
            from: transaction.from,
            chainId: transaction.chainId,
          });
        } else if (type === 'sendTip') {
          navigation.navigate(Routes.SendTip, {
            slug: transaction.artistSlug,
            transactionId: id,
          });
        } else if (type === 'verifyAddress') {
          navigation.navigate(Routes.VerifyTipAddress, {
            address: transaction.verifiedWalletAddress,
            transactionId: id,
          });
        }
      }}
      text={{
        id: error
          ? 'collect.toast.failed'
          : step === 'success'
          ? 'collect.toast.completed'
          : step === 'checkout'
          ? 'collect.toast.cancelled'
          : 'collect.toast.pending',
      }}
      isLoading={!error && !['success', 'checkout'].includes(step)}
    />
  );
};

export default TransactionToast;
