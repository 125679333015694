import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const CARD_INFO_CONTAINER_PADDING = spacing.xs;

export const styles = createThemedStyles(theme => {
  const borderRadius = Math.max(0, theme.box.borderRadius - 1);

  return {
    centeredText: {
      textAlign: 'center',
    },
    featureContainer: {
      flexGrow: 1,
      justifyContent: 'center',
      width: '100%',
    },
    actionLabels: {
      marginTop: spacing.xxs,
      gap: spacing.s,
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100%',
    },
    actionLink: {
      textAlign: 'center',
    },
    featureIcon: {
      alignSelf: 'center',
    },
    infoContainer: {
      padding: CARD_INFO_CONTAINER_PADDING,
      alignItems: 'flex-start',
      borderTopWidth: 1,
      borderColor: theme.colors.borderColor,
    },
    imageContainer: {
      height: '100%',
      flexShrink: 1,
      flexGrow: 1,
      overflow: 'hidden',
      borderTopRightRadius: borderRadius,
      borderTopLeftRadius: borderRadius,
    },
    refillButton: {
      minWidth: 80,
    },
    genreContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      padding: spacing.xs,
      paddingBottom: spacing.xl,
      gap: spacing.xs,
      pointerEvents: 'auto',
    },
    gradient: {
      borderBottomRightRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      pointerEvents: 'none',
    },
    gradientContainer: {
      height: 80,
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      borderBottomRightRadius: borderRadius,
      borderBottomLeftRadius: borderRadius,
      overflow: 'hidden',
      pointerEvents: 'none',
    },
    bulletContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: spacing.s,
    },
    bulletIcon: {
      alignSelf: 'center',
      marginRight: spacing.s,
    },
    bulletText: {
      flex: 1,
    },
    dismissButton: {
      position: 'absolute',
      top: spacing.xs,
      left: spacing.xs,
      zIndex: 1,
      backgroundColor: theme.colors.background,
      opacity: 0.8,
      borderRadius: theme.box.borderRadius,
    },
  };
});
