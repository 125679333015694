import {useContext} from 'react';

import {VerifyAddressContext} from '@/modules/Tip/VerifyAddress/VerifyAddressProvider';

export const useVerifyAddressState = () => {
  const context = useContext(VerifyAddressContext);

  if (!context) {
    throw new Error(
      'useVerifyAddressState must be used in VerifyAddressProvider',
    );
  }

  return context;
};
