import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  wallet: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.s,
  },
}));
