import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import spacing from '@/constants/spacing';
import {ThemeContext} from '@/theme';
import {ITheme} from '@/types/theme';

interface IProps {
  onPress: () => void;
  name: string;
  theme: ITheme;
  suffixComponent?: ReactNode;
  disabled?: boolean;
  textProps?: TextProps;
  height?: number;
}

const CARD_HEIGHT = 60;

const ThemeCard: FC<IProps> = ({
  onPress,
  theme,
  name,
  suffixComponent,
  disabled = false,
  textProps,
  height = CARD_HEIGHT,
}) => {
  return (
    <ThemeContext.Provider value={{theme}}>
      <TouchableCard
        onPress={onPress}
        disabled={disabled}
        style={{
          height,
          justifyContent: 'center',
          paddingVertical: 0,
        }}>
        {({actionColor}) => (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingHorizontal: spacing.xxs,
            }}>
            <Text
              style={{flexGrow: 0}}
              align="center"
              size="m"
              weight="semibold"
              numberOfLines={1}
              color={actionColor()}
              {...textProps}>
              {name}
            </Text>
            {suffixComponent && <Space ml="xs">{suffixComponent}</Space>}
          </View>
        )}
      </TouchableCard>
    </ThemeContext.Provider>
  );
};

export default ThemeCard;
