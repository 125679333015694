import React, {FC} from 'react';
import {View} from 'react-native';

import Button from '@/components/Button';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './FeedInlineError.style';

interface IInlineErrorStateProps {
  isFetching: boolean;
  onRefetch: () => void;
}

const FeedInlineError: FC<IInlineErrorStateProps> = ({
  isFetching,
  onRefetch,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <Text weight="semibold" id="error.description" />
      <Button
        isLoading={isFetching}
        textId="error.reload"
        onPress={onRefetch}
      />
    </View>
  );
};

export default FeedInlineError;
