import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';

import IconButton from '@/components/IconButton';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './SocialShareButtons.style';

interface IProps {
  shareOnTwitter?: () => void;
  shareOnLens?: () => void;
  shareOnWarpcast?: () => void;
  style?: StyleProp<any>;
}

const SocialShareButtons: FC<IProps> = ({
  shareOnWarpcast,
  shareOnLens,
  shareOnTwitter,
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={[style.shareContainer, customStyle]}>
      <View style={style.shareHeader}>
        <View style={style.shareLine} />
        <Text weight="semibold">Share now on</Text>
        <View style={style.shareLine} />
      </View>
      <View style={style.shareActionButtons}>
        {shareOnTwitter && (
          <IconButton
            onPress={() => shareOnTwitter()}
            icon={{name: 'twitter', provider: 'custom', size: spacing.l * 2}}
            size={spacing.l * 2}
          />
        )}
        {shareOnLens && (
          <IconButton
            onPress={() => shareOnLens()}
            icon={{name: 'hey', provider: 'custom', size: spacing.l * 2}}
            size={spacing.l * 2}
          />
        )}
        {shareOnWarpcast && (
          <IconButton
            onPress={() => shareOnWarpcast()}
            icon={{name: 'warpcast', provider: 'custom', size: spacing.l * 2}}
            size={spacing.l * 2}
          />
        )}
      </View>
    </View>
  );
};

export default SocialShareButtons;
