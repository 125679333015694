import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './NotificationsEmptyState.style';

const NotificationsEmptyState: FC = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <View style={style.container}>
      <Icon name="emptyFace" provider="custom" size={48} />
      <Text
        id="notifications.empty.title"
        weight="semibold"
        align="center"
        size="m"
      />
      <Text size="xs" id="notifications.empty.description" align="center" />
      <CardButton
        onPress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.NotificationSettings,
          })
        }
        icon={{name: 'settings', provider: 'custom'}}
        text={{id: 'notifications.empty.selectArtists'}}
      />
    </View>
  );
};

export default NotificationsEmptyState;
