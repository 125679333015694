import React, {FC} from 'react';
import {Pressable, View} from 'react-native';

import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Loader from '@/components/Loader';
import Text from '@/components/Text';
import UploadProgress from '@/components/UploadProgress';
import spacing from '@/constants/spacing';
import {IAudioUploaderProps} from '@/modules/DropOnSpinamp/components/AudioUploader/types';
import {useThemedStyles} from '@/theme';

import {styles, UPLOAD_ICON_SIZE} from './AudioUploader.style';

interface IProps {
  uploadProps: IAudioUploaderProps;
}

const AudioUploader: FC<IProps> = ({uploadProps}) => {
  const style = useThemedStyles(styles);

  if (uploadProps.uploadProgress !== undefined) {
    return (
      <View style={style.container}>
        <View style={style.uploadingLabel}>
          <Loader textId="uploading" weight="regular" size="xs" />
        </View>
        <UploadProgress
          style={style.uploadProgress}
          progress={uploadProps.uploadProgress}
          height={spacing.xs}
          showPercentage={false}
        />
      </View>
    );
  }

  if (uploadProps.file) {
    return (
      <View style={[style.container, style.container_audio]}>
        <IconButton
          onPress={uploadProps.togglePlay}
          icon={{
            provider: 'custom',
            name: uploadProps.isPlaying ? 'pause' : 'play',
          }}
        />
        <Text size="xs" style={style.audioTitle} align="center">
          {uploadProps.file.name}
        </Text>
        <IconButton
          onPress={uploadProps.reset}
          icon={{provider: 'custom', name: 'close'}}
        />
      </View>
    );
  }

  return (
    <Pressable
      // @ts-ignore
      style={({pressed, hovered}) => [
        style.container,
        style.container_upload,
        hovered && style.hovered,
        pressed && style.pressed,
      ]}
      onPress={uploadProps.selectFile}>
      <Icon provider="custom" name="upload" size={UPLOAD_ICON_SIZE} />
      <View style={style.uploadInfo}>
        <Text id="dropOnSpinamp.uploadTrack.title" weight="semibold" />
        <Text id="dropOnSpinamp.uploadTrack.description" size="xxs" />
      </View>
      <View style={style.iconPlaceholder} />
    </Pressable>
  );
};

export default AudioUploader;
