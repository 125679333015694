import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import Background from '@/components/Background/Background';
import {useResponsive} from '@/hooks/useResponsive';
import {useTheme} from '@/theme';
import {useThemedStyles} from '@/theme';
import {isEmbedded} from '@/utils/platform';

import {styles} from './Screen.style';

const Screen: FC<{children: ReactNode}> = ({children}) => {
  const {isMobile} = useResponsive();
  const theme = useTheme();
  const style = useThemedStyles(styles);

  return (
    <View style={style.wrapper}>
      {(isMobile || isEmbedded) && theme.background?.image && (
        <>
          <Background />
          <View style={style.opaqueOverlay} />
        </>
      )}
      {children}
    </View>
  );
};

export default Screen;
