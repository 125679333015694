import React, {FC, useCallback} from 'react';
import {View} from 'react-native';
import Animated, {
  FadeIn,
  useAnimatedStyle,
  withTiming,
} from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import FlatCard from '@/components/FlatCard';
import OpacityGradient from '@/components/OpacityGradient';
import SpinLogo from '@/components/SpinLogo';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {useAppDispatch} from '@/hooks/useRedux';
import {useTrendingTracksQuery} from '@/queries/trendingTracks';
import {playNewQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {ImageSize} from '@/types/media';

import {GRID_SIZE, LOGO_SIZE, styles} from './PlayerEmpty.style';

const GRID_ELEMENTS = GRID_SIZE.rows * GRID_SIZE.columns;
const FADE_DURATION = 500;

const PlayerEmpty: FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();

  const {trendingTracks} = useTrendingTracksQuery();

  const playTrack = useCallback(
    (track?: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: trendingTracks.map(t => t.id),
          trackId: track?.id,
          context: {
            source: 'TrendingExplore',
            type: PlayContextType.trending,
            titleId: 'explore.trendingTracks',
          },
        }),
      ),
    [trendingTracks],
  );

  const buttonStyle = useAnimatedStyle(() => ({
    opacity: withTiming(trendingTracks.length > 0 ? 1 : 0, {
      duration: FADE_DURATION,
    }),
  }));

  return (
    <View style={style.container}>
      <View style={style.content}>
        <SpinLogo size={LOGO_SIZE} showThemeMask={false} />
        <Text
          weight="semibold"
          size="xl"
          align="center"
          id="player.empty.title"
        />
        <Animated.View style={buttonStyle}>
          <CardButton
            disabled={trendingTracks.length === 0}
            onPress={() =>
              playTrack(
                trendingTracks[
                  Math.floor(Math.random() * trendingTracks.length)
                ],
              )
            }
            text={{id: 'player.empty.play'}}
            icon={{name: 'play', provider: 'custom'}}
          />
        </Animated.View>
      </View>
      {trendingTracks.length === 0 ? (
        <View style={style.tracksGrid}>
          {new Array(GRID_ELEMENTS).fill(0).map((_, index) => (
            <View key={index} style={style.track} />
          ))}
        </View>
      ) : (
        <Animated.View
          style={style.tracksGrid}
          entering={FadeIn.duration(FADE_DURATION)}>
          {trendingTracks.slice(0, GRID_ELEMENTS).map(track => (
            <View key={track.id} style={style.track}>
              <FlatCard
                style={style.trackButton}
                onPress={() => playTrack(track)}>
                <StaticImage
                  style={style.artwork}
                  source={{uri: track.lossyArtworkUrl}}
                  resizeWidth={ImageSize.thumbnail}
                />
              </FlatCard>
            </View>
          ))}
        </Animated.View>
      )}
      <View style={style.gradient}>
        <OpacityGradient vertical color="backgroundLight" />
      </View>
    </View>
  );
};

export default PlayerEmpty;
