import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContainer: {
    flex: 1,
  },
  scrollContent: {
    padding: spacing.s,
    flexGrow: 1,
  },
  themesGrid: {
    margin: -spacing.xs,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  themeItem: {
    padding: spacing.xs,
    flexBasis: '50%',
  },
  cardThemesCheckbox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
