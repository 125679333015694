import React, {FC, ReactNode} from 'react';
import {Modal, Pressable, View} from 'react-native';

import {useResponsive} from '@/hooks/useResponsive';
import {DESKTOP_MODAL_WIDTH} from '@/screens/SpinampWrapped/constants';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useThemedStyles} from '@/theme';

import {styles} from './Layout.style';

const Layout: FC<{children: ReactNode}> = ({children}) => {
  const style = useThemedStyles(styles);
  const {isMobile} = useResponsive();
  const {close} = useSpinampWrapped();

  if (isMobile) {
    return children;
  }

  return (
    <Modal animationType="fade" transparent visible onRequestClose={close}>
      <View style={style.container}>
        <Pressable
          style={[
            style.backdrop,
            // @ts-ignore
            {outline: 'none'},
          ]}
          onPress={close}
        />
        <View style={[style.content, {width: DESKTOP_MODAL_WIDTH}]}>
          {children}
        </View>
      </View>
    </Modal>
  );
};

export default Layout;
