import {normalizeSize} from '@/constants/sizing';
import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const PREVIEW_SIZE = normalizeSize(200);

export const styles = createThemedStyles(() => ({
  content: {
    padding: spacing.s,
  },
  centeredColumn: {
    alignItems: 'center',
  },
}));
