import React, {FC} from 'react';
import {View} from 'react-native';

import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useAppSelector} from '@/hooks/useRedux';
import DelayedFadeIn from '@/screens/SpinampWrapped/components/DelayedFadeIn';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useSpinampWrappedAudio} from '@/screens/SpinampWrapped/useSpinampWrappedAudio';
import {selectSelectedUserId} from '@/store/user';
import {getUserDisplayName} from '@/utils/user';

const Onboarding: FC = () => {
  const activeUserId = useAppSelector(selectSelectedUserId);
  const {user, tracks, activePageId, nextPage} = useSpinampWrapped();

  const isActive = activePageId === 'onboarding';

  useSpinampWrappedAudio(isActive, tracks[2]?.track);

  return (
    <View style={{flex: 1, alignItems: 'center', paddingHorizontal: spacing.m}}>
      <View style={{height: '30%'}} />
      <Text align="center" weight="bold" size="xxl" id="spinampWrapped.title" />
      <Space h="m" />
      {user && activeUserId !== user.id ? (
        <>
          <Text
            align="center"
            id="spinampWrapped.onboarding.description"
            values={{user: getUserDisplayName(user)}}
          />
        </>
      ) : (
        <>
          <Text
            align="center"
            id="spinampWrapped.onboarding.description.own1"
          />
          <Space h="xxs" />
          <Text
            align="center"
            id="spinampWrapped.onboarding.description.own2"
          />
        </>
      )}
      <Space h="l" />
      <DelayedFadeIn isActive>
        <TransparentButton
          onPress={nextPage}
          text={{id: 'spinampWrapped.onboarding.see'}}
        />
      </DelayedFadeIn>
    </View>
  );
};

export default Onboarding;
