import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {FC} from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header';
import ModalScreenWrapper from '@/components/ModalScreenWrapper/ModalScreenWrapper';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useArtistBySlugQuery} from '@/queries/artists';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {LocalThemeProvider} from '@/theme';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';
import {isIOS} from '@/utils/platform';

import {styles} from './Artist.style';
import ArtistScreenContents from './ArtistScreenContents';
import ArtistInfo from './components/ArtistInfo/ArtistInfo';

const ArtistModal: FC = () => {
  const {
    params: {slugOrId, tab = 'tracks'},
  } = useRoute<RouteProp<RootStackParams, Routes.ArtistModal>>();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {
    artist,
    query: {isLoading: isArtistLoading},
  } = useArtistBySlugQuery(slugOrId);
  const {artistThemes} = useCustomThemesConfigQuery();

  const changeTab = (newTab: string) => {
    navigation.setParams({tab: newTab});
  };
  const {scrollPosition, onScroll} = useAnimatedHeader();

  return (
    <LocalThemeProvider
      customTheme={artist?.customTheme}
      predefinedThemeName={
        artist?.predefinedThemeName || artistThemes[artist?.id || '']
      }>
      <ModalScreenWrapper isFullscreenOnMobile desktopSize="l">
        <Header
          title={artist?.name}
          showBack
          scrollPosition={scrollPosition}
          backgroundColor={artist ? 'backgroundDark' : undefined}
          modalMode={isIOS}
        />
        <AsyncContent
          data={artist}
          isLoading={isArtistLoading}
          isError={!artist}
          renderContent={loadedArtist => (
            <ArtistScreenContents
              artist={loadedArtist}
              tabControl={{tab, changeTab}}
              onScroll={onScroll}
              isOwnProfile={false}
              artistInfo={
                <ArtistInfo
                  artist={loadedArtist}
                  isOwnProfile={false}
                  linkEnabled={true}
                />
              }
            />
          )}
        />
      </ModalScreenWrapper>
    </LocalThemeProvider>
  );
};

export default ArtistModal;
