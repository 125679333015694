import {useContext} from 'react';

import {SpinampWrappedContext} from '@/screens/SpinampWrapped/SpinampWrappedContext';

export const useSpinampWrapped = () => {
  const context = useContext(SpinampWrappedContext);

  if (!context) {
    throw new Error(
      'useSpinampWrapped needs to be wrapped in SpinampWrappedContext provider',
    );
  }

  return context;
};
