import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {FC} from 'react';
import {useForm} from 'react-hook-form';
import {View} from 'react-native';

import {saveUserEmail} from '@/api/user';
import CardButton from '@/components/CardButton';
import {Form} from '@/components/Form';
import ScrollContainer from '@/components/ScrollContainer';
import Text from '@/components/Text';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {Sentry} from '@/services/sentry';
import {selectActiveUserSigner} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {QueryKeys} from '@/types/queryKeys';

import {styles} from './EmailForm.style';

interface IProps {
  email: string | undefined | null;
  onSuccess: (email: string) => Promise<void>;
}

const EmailForm: FC<IProps> = ({email, onSuccess}) => {
  const style = useThemedStyles(styles);
  const {showToast} = useToast();
  const queryClient = useQueryClient();

  const signer = useAppSelector(selectActiveUserSigner);

  const form = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: email || '',
    },
  });

  const saveEmailMutation = useMutation({
    networkMode: 'online',
    mutationFn: async (newEmail: string) => {
      if (!signer) {
        const error = new Error('missing signer in top up flow');
        Sentry.captureException(error);
        throw error;
      }

      return saveUserEmail(newEmail, signer);
    },
    onSuccess: async (data, savedEmail) => {
      if (signer) {
        queryClient.setQueryData(
          [QueryKeys.userEmail, signer.address],
          savedEmail,
        );
      }

      return onSuccess(savedEmail);
    },
    onError: () => {
      showToast({textId: 'error.network'});
    },
  });

  const onSubmit = form.handleSubmit(formValues => {
    saveEmailMutation.mutate(formValues.email);
  });

  return (
    <ScrollContainer contentStyle={style.content}>
      <Form form={form}>
        <Text id="topUp.description" size="xs" />

        <Form.Field label={{id: 'topUp.email'}}>
          <Form.Input
            name="email"
            inputMode="email"
            autoComplete="email"
            keyboardType="email-address"
            textContentType="emailAddress"
            rules={{
              pattern: {
                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                message: 'topUp.email.validation',
              },
            }}
          />
          <Form.Error name="email" />
        </Form.Field>

        <CardButton
          onPress={onSubmit}
          text={{id: 'topUp.confirm'}}
          isLoading={saveEmailMutation.isLoading}
        />
      </Form>
    </ScrollContainer>
  );
};

export default EmailForm;
