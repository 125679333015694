import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useFullUserByIdQuery} from '@/queries/user';
import {useThemedStyles} from '@/theme';
import {IPartialPlaylist} from '@/types/playlists';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './PlaylistCard.style';

interface IProps {
  playlist: IPartialPlaylist;
  showCreator?: boolean;
  onPress?: () => void;
  action?: (playlist: IPartialPlaylist) => void;
  actionIcon?: IIconProps;
}

const PlaylistCard: FC<IProps> = ({
  playlist,
  showCreator = true,
  onPress,
  action,
  actionIcon,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();

  const openPlaylist = () => {
    navigation.push(Routes.Playlist, {id: playlist.id});
  };

  const {user} = useFullUserByIdQuery(playlist.collector);

  return (
    <TouchableCard
      onPress={() => {
        onPress?.();
        openPlaylist();
      }}
      style={style.card}>
      {({actionColor}) => (
        <Space flex style={style.cardSpace}>
          <View style={style.content}>
            <Text weight="semibold" color={actionColor()} numberOfLines={1}>
              {playlist.title}
            </Text>
            {showCreator && playlist.collector && (
              <Text size="xs" numberOfLines={1} color={actionColor()}>
                {user?.artistProfile
                  ? user.artistProfile.name
                  : user
                  ? getUserDisplayName(user)
                  : playlist.collector}
              </Text>
            )}
          </View>
          {action && actionIcon && (
            <TouchableOpacity
              onPress={() => action(playlist)}
              style={style.action}>
              <Icon {...actionIcon} color={actionColor()} />
            </TouchableOpacity>
          )}
        </Space>
      )}
    </TouchableCard>
  );
};

export default React.memo(PlaylistCard);
