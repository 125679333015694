import {useContext} from 'react';

import {TooltipContext} from '@/components/Tooltip/Tooltip';

export const useTooltipContext = () => {
  const tooltipContext = useContext(TooltipContext);

  if (!tooltipContext) {
    throw new Error(
      'useTooltipContext can only be called inside TooltipProvider',
    );
  }

  return tooltipContext;
};
