import {config} from '@/constants/config';
import {IQueue, PlayContextType} from '@/types/common';
import {addParamsToUrl} from '@/utils/url';

export const getReferralPlaylistFromQueue = (queue: IQueue | null) =>
  queue?.context.type === PlayContextType.playlist
    ? queue.context.id
    : undefined;

export const getExternalReferralUrl = (
  externalUrl: string,
  platformId: string,
  referral: string = config.SPINAMP_ADDRESS,
) => {
  if (platformId !== 'sound') {
    return externalUrl;
  }

  return addParamsToUrl(externalUrl, {
    referral,
    referral_source: 'link',
  });
};
