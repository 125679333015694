import {TextSize} from '@/components/Text/Text';
import {
  isWebMetamask,
  useDisconnect,
  useExternalSigner,
} from '@/modules/ExternalWallet';
import {IAddress} from '@/types/common';
import {areAddressesEqual, prettifyAddress} from '@/utils/ethereum';

export const useCheckWalletConnection = (wallet: IAddress | undefined) => {
  const {address: connectedAddress, connect, connector} = useExternalSigner();
  const {disconnect, isDisconnecting} = useDisconnect();
  const isMetaMask = isWebMetamask(connector);

  const showConnectedWalletError =
    !!wallet &&
    wallet.isWallet &&
    !wallet.isPasskey &&
    !areAddressesEqual(wallet.address, connectedAddress);

  if (!showConnectedWalletError) {
    return undefined;
  }

  const message = {
    id: isMetaMask
      ? 'transactions.notConnected.message.metamask'
      : connectedAddress
      ? 'transactions.notConnected.message.wrongWallet'
      : 'transactions.notConnected.message',
    walletName: wallet.ens || prettifyAddress(wallet.address),
  };

  const action = isMetaMask
    ? null
    : connectedAddress
    ? {
        text: {id: 'disconnect', size: 's' as TextSize},
        onPress: disconnect,
        isLoading: isDisconnecting,
        loaderTextId: 'disconnect.loading',
      }
    : {
        text: {id: 'connect', size: 's' as TextSize},
        onPress: connect,
      };

  return {
    message,
    action,
  };
};
