import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

const MAX_TOAST_WIDTH = 600;
export const CLOSE_ICON_SIZE = 14;
const CLOSE_BUTTON_WIDTH = spacing.s * 2 + CLOSE_ICON_SIZE;

export const styles = createThemedStyles(() => ({
  card: {
    width: 'auto',
    maxWidth: MAX_TOAST_WIDTH,
    display: 'flex',
    paddingVertical: 0,
    paddingHorizontal: 0,
  },
  toast: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.s,
    paddingVertical: spacing.xs,
    gap: spacing.s,
  },
  toast_close: {
    paddingRight: CLOSE_BUTTON_WIDTH,
  },
  content: {
    flex: isWeb ? 1 : undefined,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: CLOSE_BUTTON_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
