import {useQuery} from '@tanstack/react-query';

import {fetchArtistTippers} from '@/modules/Tip/utils';
import {QueryKeys} from '@/types/queryKeys';

export const useArtistTippersQuery = (artistId?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.artistTippers, artistId],
    queryFn: async () => fetchArtistTippers(artistId!),
    enabled: !!artistId,
  });

  return {
    tippers: query.data,
    query,
  };
};
