import React, {FC} from 'react';
import {View} from 'react-native';

import Quantity from '../Quantity';
import Divider from '@/components/Divider';
import Text from '@/components/Text';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {PriceSummary} from '@/modules/Transactions';
import {useThemedStyles} from '@/theme';

import {styles} from './Offer.style';

interface IProps {
  offer: IMintOfferInfo;
  quantity: number;
  setQuantity: (quantity: number) => void;
  disabled?: boolean;
}

const Offer: FC<IProps> = ({
  offer,
  quantity,
  setQuantity,
  disabled = false,
}) => {
  const style = useThemedStyles(styles);
  const {collectInfoQuery, gasEstimation} = useCollectState();

  return (
    <View style={style.container}>
      <View style={style.row}>
        <Text size="xs" id="collect.quantity" />
        <Quantity
          quantity={quantity}
          setQuantity={setQuantity}
          disabled={disabled}
        />
      </View>

      <Divider />

      <PriceSummary
        priceLabelId="collect.trackPrice"
        price={BigInt(offer.price.value)}
        chainId={offer.chainId}
        gasEstimation={gasEstimation}
        showChain
        isPriceFetching={collectInfoQuery.isPlaceholderData}
      />
    </View>
  );
};

export default Offer;
