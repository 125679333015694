import React, {FC} from 'react';

import {FadeInOut} from '@/components/AnimationWrappers';
import Text, {TextProps} from '@/components/Text';
import {useExchangeRatesQuery} from '@/queries/crypto';
import {ethToUsd, weiToUsd} from '@/utils/ethereum';

interface IProps extends TextProps {
  eth?: number | string;
  wei?: bigint;
  chainId: number;
  spacing?: boolean;
}

const UsdPrice: FC<IProps> = ({
  eth,
  wei,
  chainId,
  spacing = true,
  ...textProps
}) => {
  const {exchangeRates} = useExchangeRatesQuery();

  const unit = spacing ? ' $' : '$';

  if (wei !== undefined && exchangeRates) {
    return (
      <Text {...textProps}>
        {weiToUsd(wei, chainId, exchangeRates)}
        {unit}
      </Text>
    );
  }

  if (eth !== undefined && exchangeRates) {
    return (
      <Text {...textProps}>
        {ethToUsd(eth, chainId, exchangeRates)}
        {unit}
      </Text>
    );
  }

  return (
    <FadeInOut>
      <Text {...textProps}>0{unit}</Text>
    </FadeInOut>
  );
};

export default React.memo(UsdPrice);
