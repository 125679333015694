import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    flex: 1,
    opacity: 0.4,
  },
  container_selected: {
    opacity: 1,
  },
  tileIcon: {
    alignSelf: 'center',
  },
  selectedIndicator: {
    position: 'absolute',
    top: -spacing.xs,
    right: -spacing.xs,
    borderWidth: 0,
    backgroundColor: theme.colors.backgroundLight,
    borderRadius: 12,
    zIndex: 2,
  },
  tileWrapper: {
    flex: 1,
  },
  tile: {
    flex: 1,
    borderRadius: theme.box.borderRadius,
    borderColor: theme.colors.primary,
    borderWidth: theme.box.baseBorderWidth,
    borderRightWidth: theme.box.borderRightWidth,
    borderBottomWidth: theme.box.borderBottomWidth,
    overflow: 'hidden',
  },
  tile_selected: {
    borderRightWidth: theme.box.baseBorderWidth,
    borderBottomWidth: theme.box.baseBorderWidth,
    paddingBottom: theme.box.borderBottomWidth - theme.box.baseBorderWidth,
    paddingRight: theme.box.borderRightWidth - theme.box.baseBorderWidth,
  },
  title: {
    padding: spacing.xxs,
    marginHorizontal: -20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs,
    backgroundColor: theme.colors.primary,
  },
  description: {
    padding: spacing.xs + spacing.xxs,
  },
}));
