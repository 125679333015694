import axios from 'axios';

import {config} from '@/constants/config';

export const tenderlyApi = axios.create({
  baseURL: config.TENDERLY_API_URL,
  headers: {
    'X-Access-Key': config.TENDERLY_API_KEY,
  },
});
