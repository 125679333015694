import React, {FC, useState} from 'react';
import {View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Text from '@/components/Text';
import UsdPrice from '@/components/UsdPrice';
import {getChainById} from '@/constants/chains';
import {useTransactionValidation} from '@/modules/Transactions';
import {
  useBalanceValidation,
  useChainValidation,
  useIsWalletConnectedValidation,
} from '@/modules/Transactions';
import DeliveryWalletInfo from '@/modules/Transfer/components/DeliveryWalletInfo';
import PendingTransfer from '@/modules/Transfer/components/PendingTransfer';
import SenderWalletInfo from '@/modules/Transfer/components/SenderWalletInfo';
import TrackInfoCard from '@/modules/Transfer/components/TrackInfoCard';
import {useTransferState} from '@/modules/Transfer/TrackTransfer/useTransferState';
import {useTransferTransaction} from '@/modules/Transfer/TrackTransfer/useTransferTransaction';
import {useDeliverWalletValidation} from '@/modules/Transfer/validation/useDeliveryWalletValidation';
import {WalletPicker} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {formatPrice} from '@/utils/ethereum';
import {isNotNil} from '@/utils/functions';

import {styles} from './TransferCheckout.style';

const TransferCheckout: FC = () => {
  const style = useThemedStyles(styles);

  const {
    user,
    transfer,
    updateTransfer,
    track,
    senderWallet,
    balance,
    deliveryWallet,
    gasEstimation,
  } = useTransferState();
  const [isWalletPickerOpen, setIsWalletPickerOpen] = useState(false);
  const {txHash, userOpHash, step} = transfer;

  const {executeTransfer} = useTransferTransaction();

  const WrongDeliveryWalletError = useDeliverWalletValidation(
    transfer.from,
    deliveryWallet,
  );
  const ConnectedWalletError = useIsWalletConnectedValidation({
    wallet: senderWallet,
  });
  const WrongChainError = useChainValidation({
    wallet: senderWallet,
    chainId: transfer.chainId,
  });
  const InsufficientBalanceError = useBalanceValidation({
    wallet: senderWallet,
    balance,
    price: gasEstimation.totalGas,
    chainId: transfer.chainId,
  });
  const InvalidTransactionError = useTransactionValidation(gasEstimation);

  const validationError = [
    WrongDeliveryWalletError,
    ConnectedWalletError,
    WrongChainError,
    InsufficientBalanceError,
    InvalidTransactionError,
  ].filter(isNotNil)[0];

  return (
    <>
      <ScrollContainer contentStyle={style.content}>
        <SenderWalletInfo senderWallet={senderWallet} from={transfer.from} />

        <Divider />

        <View>
          <Text style={style.sectionTitle} id="transfer.assetHeader" />
          <TrackInfoCard track={track} />
        </View>

        <Divider />

        <DeliveryWalletInfo
          transferStep={step}
          deliveryWallet={deliveryWallet}
          deliveryWalletAddress={transfer.to}
          onChangeWallet={() => setIsWalletPickerOpen(true)}
        />

        <PendingTransfer
          transferStep={step}
          txHash={txHash}
          userOpHash={userOpHash}
          chainId={transfer.chainId}
          senderWallet={senderWallet}
        />
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <View style={style.networkInfoContainer}>
          {InsufficientBalanceError && balance && (
            <View style={[style.networkRow, style.balance]}>
              <Text
                size="xs"
                id="transfer.balance"
                values={{
                  amount: (
                    <Text size="xs" weight="semibold">
                      {formatPrice(
                        balance.value,
                        {
                          decimals: balance.decimals,
                          symbol: balance.symbol,
                        },
                        5,
                      )}
                    </Text>
                  ),
                }}
              />
            </View>
          )}
          <View style={style.networkRow}>
            <Text size="xs">{getChainById(transfer.chainId).name}</Text>
            <FadeInOut enabled={gasEstimation.isFetching}>
              <View style={style.priceContainer}>
                <Text
                  size="xs"
                  id="transfer.networkFee"
                  values={{
                    amount: (
                      <Text size="xs" weight="semibold">
                        {formatPrice(
                          gasEstimation.totalGas || BigInt(0),
                          getChainById(transfer.chainId).chain.nativeCurrency,
                          7,
                        )}
                      </Text>
                    ),
                  }}
                />
                <UsdPrice
                  size="xxs"
                  wei={gasEstimation.totalGas || BigInt(0)}
                  chainId={transfer.chainId}
                  secondary
                />
              </View>
            </FadeInOut>
          </View>
        </View>

        {validationError?.message && (
          <View style={style.validationError}>{validationError.message}</View>
        )}

        {validationError ? (
          validationError.action
        ) : (
          <CardButton
            disabled={
              !senderWallet ||
              !!validationError ||
              gasEstimation.isFetching ||
              !gasEstimation.totalGas
            }
            text={{id: 'transfer.send'}}
            loaderProps={{textId: 'transfer.processing'}}
            isLoading={step !== 'checkout'}
            onPress={() => {
              if (senderWallet) {
                executeTransfer(senderWallet);
              }
            }}
            icon={{name: 'transfer', provider: 'custom'}}
          />
        )}
      </ShadowFooter>

      {isWalletPickerOpen && user && (
        <WalletPicker
          titleId="transfer.walletPicker.title"
          isOpen
          user={user}
          selectedWalletAddress={transfer.to}
          excludedAddress={transfer.from}
          onConfirm={address => updateTransfer({to: address})}
          chainId={transfer.chainId}
          onClose={() => setIsWalletPickerOpen(false)}
          allowAddExternalWallet
          allowCustomAddress
          avoidKeyboard
        />
      )}
    </>
  );
};

export default TransferCheckout;
