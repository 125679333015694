import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = spacing.s;

export const styles = createThemedStyles(theme => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  avatar: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    backgroundColor: theme.colors.primary,
  },
}));
