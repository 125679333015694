import {useContext} from 'react';

import {TransferStateContext} from '@/modules/Transfer/TrackTransfer/TransferStateProvider';

export const useTransferState = () => {
  const transferState = useContext(TransferStateContext);

  if (!transferState) {
    throw new Error(
      'useTransferState must be used inside TransferStateProvider',
    );
  }

  return transferState;
};
