import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    padding: spacing.m,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.s,
  },
  action: {
    height: spacing.s * 2 + spacing.xxs,
  },
}));
