import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';

import {
  Routes,
  SearchStackNavigationParams,
  SearchStackParams,
} from '@/types/routes';

import {SearchCategory} from './types';

export const useSearchCategories = () => {
  const navigation = useNavigation<SearchStackNavigationParams>();
  const {params} = useRoute<RouteProp<SearchStackParams, Routes.Search>>();
  const category = params?.category as SearchCategory;

  const setCategory = (_category?: SearchCategory) => {
    navigation.setParams({
      category: category === _category ? undefined : _category,
    });
  };

  return {
    category,
    setCategory,
  };
};
