import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {IApiResponseTrack, IApiResponseTrackWithNFTFactory} from '@/types/api';
import {ITrack, ITrackDetails, ITrackWithNFTFactory} from '@/types/common';
import {
  parseApiTrack,
  parseApiTrackWithNFTFactory,
  TRACK_FRAGMENT,
  TRACK_WITH_NFT_FACTORY_DETAILS_FRAGMENT,
} from '@/utils/apiModelParsers';

export const fetchTrackById = async (trackId: string): Promise<ITrack> => {
  const response = await pipelineApi.request(
    gql`
      query Track {
        processedTrackById(
          id: "${trackId}"
        ) {
          ...TrackDetails
          description
        }
      }
      ${TRACK_FRAGMENT}
    `,
  );

  return parseApiTrack(response.processedTrackById as IApiResponseTrack);
};

export const fetchTrackDetailsWithNFTFactoryById = async (
  trackId: string,
): Promise<ITrackWithNFTFactory> => {
  const response = await pipelineApi.request(
    gql`
      query Track {
        processedTrackById(
          id: "${trackId}"
        ) {
          ...TrackWithNftFactoryDetails
          description
        }
      }
      ${TRACK_WITH_NFT_FACTORY_DETAILS_FRAGMENT}
    `,
  );

  return parseApiTrackWithNFTFactory(
    response.processedTrackById as IApiResponseTrackWithNFTFactory,
  );
};

export const fetchTrackDetails = async (
  trackId: string,
): Promise<ITrackDetails> => {
  const response = await pipelineApi.request(
    gql`
      query TrackDetails {
        processedTrackById(
          id: "${trackId}"
        ) {
          id
          description
        }
      }
    `,
  );

  return response.processedTrackById as ITrackDetails;
};

export const fetchTrackBySlug = async (
  slug: string,
): Promise<ITrack | null> => {
  const response = await pipelineApi.request(
    gql`
      query TrackBySlug {
        allProcessedTracks(
          first: 1
          orderBy: CREATED_AT_TIME_ASC
          filter: {slug: {startsWithInsensitive: "${slug}"}}
        ) {
          nodes {
            ...TrackDetails
            description
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
  );
  const {nodes} = response.allProcessedTracks;

  if (nodes.length === 0) {
    return null;
  }

  return parseApiTrack(nodes[0] as IApiResponseTrack);
};

export const fetchTrackDetailsWithNFTFactoryBySlug = async (
  slug: string,
): Promise<ITrackWithNFTFactory | null> => {
  const response = await pipelineApi.request(
    gql`
      query TrackBySlug {
        allProcessedTracks(
          first: 1
          orderBy: CREATED_AT_TIME_ASC
          filter: {slug: {startsWithInsensitive: "${slug}"}}
        ) {
          nodes {
            ...TrackWithNftFactoryDetails
            description
          }
        }
      }
      ${TRACK_WITH_NFT_FACTORY_DETAILS_FRAGMENT}
    `,
  );
  const {nodes} = response.allProcessedTracks;

  if (nodes.length === 0) {
    return null;
  }

  return parseApiTrackWithNFTFactory(
    nodes[0] as IApiResponseTrackWithNFTFactory,
  );
};

export const fetchTrackByUrlParam = async (param: string): Promise<ITrack> =>
  (await fetchTrackBySlug(param)) || (await fetchTrackById(param));

export const fetchTrackWithNFTFactoryByUrlParam = async (
  param: string,
): Promise<ITrackWithNFTFactory> =>
  (await fetchTrackDetailsWithNFTFactoryBySlug(param)) ||
  (await fetchTrackDetailsWithNFTFactoryById(param));

export const fetchTrackByIdOrSlug = async (
  param: string,
): Promise<ITrack | null> => {
  try {
    return await fetchTrackById(param);
  } catch (error) {}

  return fetchTrackBySlug(param);
};

export const fetchTracksByIds = async (
  trackIds: string[] = [],
): Promise<ITrack[]> => {
  if (trackIds.length === 0) {
    return [];
  }

  const response = await pipelineApi.request(
    gql`
      query TracksById($trackIds: [String!]) {
        allProcessedTracks(
          orderBy: CREATED_AT_TIME_DESC
          filter: {id: {in: $trackIds}}
        ) {
          nodes {
            ...TrackDetails
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {trackIds},
  );

  const tracks: ITrack[] = response.allProcessedTracks.nodes.map(parseApiTrack);
  return trackIds
    .map(trackId => tracks.find(track => track.id === trackId))
    .filter(track => !!track) as ITrack[];
};

export const fetchTracksBySearchPhrase = async (
  search: string = '',
): Promise<ITrack[]> => {
  const response = await pipelineApi.request(
    gql`
      query SearchTracks($search: String!) {
        allProcessedTracks(
          first: 100
          orderBy: CREATED_AT_TIME_DESC
          filter: {
            or: [
              {title: {includesInsensitive: $search}}
              {artistByArtistId: {name: {includesInsensitive: $search}}}
            ]
          }
        ) {
          nodes {
            ...TrackDetails
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {search},
  );

  return response.allProcessedTracks.nodes.map(parseApiTrack);
};

export const getEarliestTrackReleaseTime = async (
  trackId: string,
  userId: string,
): Promise<string> => {
  const response = await pipelineApi.request(
    gql`
      query TrackReleaseTime($userId: String!, $trackId: String!) {
        earliestTrackReleaseTime(userid: $userId, trackid: $trackId)
      }
    `,
    {
      userId,
      trackId,
    },
  );

  return response.earliestTrackReleaseTime;
};
