import React, {FC, ReactNode} from 'react';

import Text from '@/components/Text/Text';
import AppUpdateToast, {
  useAppUpdateToast,
} from '@/modules/Toasts/AppUpdateToast';
import {GlobalToastContext} from '@/modules/Toasts/GlobalToastContext';
import {
  TipNotificationToast,
  useTipNotificationToast,
} from '@/modules/Toasts/TipNotificationToast';
import Toast from '@/modules/Toasts/Toast';
import ToastContainer from '@/modules/Toasts/ToastContainer';
import TopUpToast, {useTopUpToasts} from '@/modules/Toasts/TopUpToasts';
import TransactionToast, {
  useTransactionsToasts,
} from '@/modules/Toasts/TransactionToasts';
import {useGlobalToastsConfig} from '@/modules/Toasts/useGlobalToastsConfig';

const ToastProvider: FC<{children: ReactNode}> = ({children}) => {
  const {globalToast, globalToastActions} = useGlobalToastsConfig();

  const {showAppUpdateToast, closeAppUpdateToast} = useAppUpdateToast();

  const {topUpTransactions, closeTopUpToast} = useTopUpToasts();
  const {transactions} = useTransactionsToasts();

  const {showTipNotification, closeTipNotification, totalBalance} =
    useTipNotificationToast();

  const showToastContainer =
    globalToast ||
    showAppUpdateToast ||
    showTipNotification ||
    transactions.length > 0 ||
    topUpTransactions.length > 0;

  return (
    <GlobalToastContext.Provider value={globalToastActions}>
      {children}

      {showToastContainer && (
        <ToastContainer>
          {showAppUpdateToast && (
            <AppUpdateToast onClose={closeAppUpdateToast} />
          )}

          {showTipNotification && (
            <TipNotificationToast
              totalBalance={totalBalance}
              onClose={closeTipNotification}
            />
          )}

          {topUpTransactions.map(transaction => (
            <TopUpToast
              key={transaction.id}
              topUpTransaction={transaction}
              onClose={() => closeTopUpToast(transaction.id)}
            />
          ))}

          {transactions.map(transaction => (
            <TransactionToast key={transaction.id} transaction={transaction} />
          ))}

          {globalToast && (
            <Toast onClose={globalToastActions.hideToast} closeOnPress>
              <Text
                size="xs"
                color="invertedTextColor"
                align="center"
                id={globalToast.textId}
                values={globalToast.values}>
                {globalToast.text}
              </Text>
            </Toast>
          )}
        </ToastContainer>
      )}
    </GlobalToastContext.Provider>
  );
};

export default ToastProvider;
