import {IColors} from '@/types/theme';

export const DefaultColorPack: IColors = {
  primaryLight: '#282525',
  primary: '#100F0F',
  primaryDark: '#100F0F',

  backgroundLight: '#fbfaf9',
  background: '#F7EFE3',
  backgroundDark: '#e9ddcd',

  backdrop: 'rgba(0,0,0,0.65)',

  borderColor: '#100F0F',
  invertedBorderColor: '#db94d7',
  textColor: '#100F0F',
  invertedTextColor: '#F7EFE3',

  active: '#db94d7',
  activeBorder: '#100F0F',
  activeText: '#100F0F',

  favoritesColor: '#E42E2E',

  deepBackground: 'rgba(0,0,0,0)',
  opaqueOverlay: 'rgba(0,0,0,0)',
};
