import React from 'react';
import {View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen';
import Text from '@/components/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import TipperCard, {
  TIPPER_CARD_HEIGHT,
} from '@/modules/Tip/components/TipperCard';
import PendingTipCard from '@/modules/Tip/TipsSettings/components/PendingTipCard';
import TipsScreenEmpty from '@/modules/Tip/TipsSettings/components/TipsScreenEmpty';
import TipsSettingsError from '@/modules/Tip/TipsSettings/components/TipsSettingsError';
import TipsSummary from '@/modules/Tip/TipsSettings/components/TipsSummary';
import {useArtistTippersQuery} from '@/modules/Tip/useArtistTippers';
import {
  usePendingTipsBalance,
  useTotalTipsBalance,
} from '@/modules/Tip/useTipsBalance';
import {useTipsHistory} from '@/modules/Tip/useTipsHistory';
import {sumPendingBalance} from '@/modules/Tip/utils';
import {useThemedStyles} from '@/theme';
import {isNotNil} from '@/utils/functions';

import {styles} from './TipsSettingsScreen.style';

const TipsSettingsScreen = () => {
  const style = useThemedStyles(styles);

  const user = useActiveUser();
  const {pendingBalances, query: pendingBalancesQuery} =
    usePendingTipsBalance();
  const {totalTipsBalance, query: totalTipsBalanceQuery} = useTotalTipsBalance(
    user?.artistProfile?.id,
  );
  const {tippers, query: tippersQuery} = useArtistTippersQuery(
    user?.artistProfile?.id,
  );

  const {tipsHistory, query: tipsHistoryQuery} = useTipsHistory(
    user?.artistProfile?.id,
  );

  if (!user?.artistProfile) {
    return;
  }

  const dataToLoad =
    isNotNil(totalTipsBalance) && pendingBalances
      ? {
          totalTipsBalance,
          pendingBalances,
        }
      : undefined;

  const refresh = () =>
    Promise.all([
      pendingBalancesQuery.refetch(),
      totalTipsBalanceQuery.refetch(),
      tippersQuery.refetch(),
      tipsHistoryQuery.refetch(),
    ]);

  return (
    <Screen>
      <Header showBack titleId="tipsSettings.title" />
      <AsyncContent
        data={dataToLoad}
        isLoading={
          pendingBalancesQuery.isPending || totalTipsBalanceQuery.isPending
        }
        isError={pendingBalancesQuery.isError || totalTipsBalanceQuery.isError}
        errorComponent={<TipsSettingsError onRefresh={refresh} />}
        renderContent={loadedData => {
          const isEmpty =
            sumPendingBalance(loadedData.pendingBalances) +
              BigInt(loadedData.totalTipsBalance) ===
            BigInt(0);

          if (isEmpty) {
            return <TipsScreenEmpty artistSlug={user.artistProfile!.slug} />;
          }

          const pendingWallets = user.addresses.filter(
            w => w.isWallet && loadedData.pendingBalances[w.address] > 0,
          );

          return (
            <InfinityList
              style={style.scrollContainer}
              contentContainerStyle={style.scrollContent}
              ListHeaderComponent={
                <View style={style.header}>
                  <TipsSummary
                    totalTipsBalance={loadedData.totalTipsBalance}
                    pendingBalances={loadedData.pendingBalances}
                    tippers={tippers}
                  />

                  {pendingWallets.length > 0 && (
                    <View>
                      <Text
                        style={style.sectionTitle}
                        weight="semibold"
                        id="tipsSettings.pending"
                      />
                      <View style={style.balanceCards}>
                        {pendingWallets.map(wallet => (
                          <PendingTipCard
                            key={wallet.address}
                            wallet={wallet}
                            balances={pendingBalances}
                          />
                        ))}
                      </View>
                    </View>
                  )}

                  <Text
                    style={style.sectionTitle}
                    weight="semibold"
                    id="tipsSettings.history"
                  />
                </View>
              }
              data={tipsHistory || []}
              keyExtractor={item => item.transactionHash}
              renderItem={({item: tip}) => (
                <TipperCard
                  user={tip.from}
                  amount={tip.amount}
                  toAddress={tip.toAddress}
                />
              )}
              itemSize={TIPPER_CARD_HEIGHT}
              fetchNextPage={tipsHistoryQuery.fetchNextPage}
              hasNextPage={tipsHistoryQuery.hasNextPage}
              isFetchingNextPage={
                tipsHistoryQuery.isFetchingNextPage ||
                tipsHistoryQuery.isPending
              }
              refresh={refresh}
            />
          );
        }}
      />
    </Screen>
  );
};

export default TipsSettingsScreen;
