import React, {FC, ReactNode} from 'react';
import {StyleProp, TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import Text, {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './SelectableTile.style';

export interface ISelectableTile {
  onPress: () => void;
  active: boolean;
  title?: TextProps;
  children: ReactNode;
  disabled?: boolean;
  style?: StyleProp<any>;
}

const SelectableTile: FC<ISelectableTile> = ({
  onPress,
  active,
  title,
  children,
  disabled = false,
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);

  return (
    <TouchableOpacity
      disabled={active || disabled}
      onPress={onPress}
      activeOpacity={0.8}
      style={[style.tile, active && style.tile_active, customStyle]}>
      {title && (
        <View style={style.header}>
          <Text
            weight="semibold"
            align="center"
            size="xs"
            color="background"
            {...title}
          />
        </View>
      )}
      <View style={style.content}>{children}</View>

      {active && (
        <View style={style.selectedIndicator}>
          <Icon provider="custom" name="checked" />
        </View>
      )}
    </TouchableOpacity>
  );
};

export default SelectableTile;
