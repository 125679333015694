import React, {useEffect} from 'react';

import {ArtistCard, ARTIST_CARD_HEIGHT} from '@/components/ArtistCard';
import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen/Screen';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useLatestArtistsQuery} from '@/queries/artists';

export default function ArtistsScreen() {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const {
    artists,
    clearLatestArtistsPages,
    query: {isLoading, fetchNextPage, refetch, hasNextPage, isFetchingNextPage},
  } = useLatestArtistsQuery();

  useEffect(() => clearLatestArtistsPages, []);

  return (
    <Screen>
      <Header
        titleId="explore.latestArtists"
        showBack
        scrollPosition={scrollPosition}
        mockTitle
      />
      <AsyncContent
        isLoading={isLoading && artists.length === 0}
        isError={artists.length === 0}>
        {() => (
          <InfinityList
            data={artists}
            itemSize={ARTIST_CARD_HEIGHT}
            onScroll={onScroll}
            keyExtractor={item => item.id}
            renderItem={({item: artist}) => <ArtistCard artist={artist} />}
            refresh={refetch}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        )}
      </AsyncContent>
    </Screen>
  );
}
