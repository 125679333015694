import {useNavigation} from '@react-navigation/native';

import {RootStackNavigationParams, Routes} from '@/types/routes';

export const useLogin = () => {
  const navigation = useNavigation<RootStackNavigationParams>();

  return {
    openLoginModal: () => navigation.navigate(Routes.Login),
  };
};
