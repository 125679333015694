import {useMemo} from 'react';

import {IPlaylist} from '@/types/playlists';
import {IBaseUser} from '@/types/user';

export const usePlaylistContributors = (
  playlist?: IPlaylist,
  collaborators: IBaseUser[] = [],
) => {
  return useMemo(() => {
    const contributorsIds = Object.values(
      playlist?.contributions || {},
    ).reduce<{[id: string]: boolean}>(
      (ids, c) => ({
        ...ids,
        [c.userId]: true,
      }),
      {},
    );

    return collaborators.filter(c => contributorsIds[c.id]);
  }, [collaborators, playlist]);
};
