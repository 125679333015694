import React, {FC} from 'react';

import CollectorLink from '@/components/NftEvent/CollectorLink';
import NftLink from '@/components/NftEvent/NftLink';
import Text, {TextProps} from '@/components/Text/Text';
import {INftEvent} from '@/types/nfts';
import {IBaseUser} from '@/types/user';
import {isIOS} from '@/utils/platform';

interface IProps extends TextProps {
  event: INftEvent;
  counterparty?: IBaseUser;
  messageId?: string;
}

const NftEventMessage: FC<IProps> = ({
  event,
  counterparty,
  messageId: _messageId,
  ...textProps
}) => {
  const {chainId, contractAddress, tokenId, type} = event;
  const messageId = _messageId || `nftEvent.${type}`;

  return (
    <Text
      size="xs"
      id={messageId}
      adjustsFontSizeToFit={isIOS}
      numberOfLines={isIOS ? 1 : undefined}
      values={{
        nft: (
          <NftLink
            chainId={chainId}
            contractAddress={contractAddress}
            tokenId={tokenId}
          />
        ),
        counterparty: counterparty ? (
          <CollectorLink collector={counterparty} />
        ) : (
          ''
        ),
      }}
      {...textProps}
    />
  );
};

export default NftEventMessage;
