import React, {FC} from 'react';
import {StyleProp} from 'react-native';

import Rive from '@/components/Rive';
import {useAppSelector} from '@/hooks/useRedux';
import {selectDefaultTheme, selectThemeSettings} from '@/store/theme';

interface IProps {
  size: number;
  style?: StyleProp<any>;
}

const riveFileNames: {[themeName: string]: string} = {
  spinamp: 'wallet.spinamp',
  retro: 'wallet.retro',
  modern: 'wallet.modern',
};

const SpinampWalletIcon: FC<IProps> = ({size, style}) => {
  const defaultTheme = useAppSelector(selectDefaultTheme);
  const themeSettings = useAppSelector(selectThemeSettings);
  const themeName = themeSettings?.name || defaultTheme;
  const riveName = riveFileNames[themeName] || riveFileNames.spinamp;

  return (
    <Rive
      key={riveName}
      name={riveName}
      size={size}
      style={[{transform: [{translateY: -Math.floor(size / 25)}]}, style]}
    />
  );
};

export default SpinampWalletIcon;
