import {
  closestCenter,
  DndContext,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {DragEndEvent} from '@dnd-kit/core/dist/types';
import {SortableContext, verticalListSortingStrategy} from '@dnd-kit/sortable';
import React, {useMemo, useState} from 'react';
import {createPortal} from 'react-dom';
import {FlatList} from 'react-native';
import Animated from 'react-native-reanimated';

import DragItem from '@/components/DraggableList/DragItem';
import {IDraggableListProps} from '@/components/DraggableList/types';
import {LIST_SETTINGS} from '@/constants/listsSettings';
import {noop} from '@/utils/functions';
import {createItemLayoutGetter} from '@/utils/layout';

const dragFallback = noop;

const AnimatedFlatList = Animated.createAnimatedComponent(
  FlatList,
) as typeof FlatList;

function DraggableList<ItemType>(
  {
    draggingEnabled,
    data,
    renderItem,
    keyExtractor,
    onDragEnd,
    itemSize,
    itemSpacing,
    headerHeight,
    style,
    onScrollOffsetChange,
    ...otherProps
  }: IDraggableListProps<ItemType>,
  ref: React.ForwardedRef<FlatList<ItemType>>,
) {
  const [activeItem, setActiveItem] = useState<{
    item: ItemType;
    index: number;
  } | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 500,
        tolerance: 8,
      },
    }),
  );

  const getItemLayout = useMemo(
    () =>
      itemSize
        ? createItemLayoutGetter({
            itemHeight: itemSize,
            itemSpacing,
            headerHeight,
          })
        : undefined,
    [itemSize, itemSpacing, headerHeight],
  );

  const onStart = (event: DragStartEvent) => {
    const {active} = event;
    const index = data.findIndex(
      (item, _index) => keyExtractor(item, _index) === active.id,
    );
    setActiveItem({item: {...data[index]}, index});
  };

  const onEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (over && active.id !== over.id) {
      const from = data.findIndex(
        (item, index) => keyExtractor(item, index) === active.id,
      );
      const to = data.findIndex(
        (item, index) => keyExtractor(item, index) === over.id,
      );

      const reorderedList = [...data];
      const [removed] = reorderedList.splice(from, 1);
      reorderedList.splice(to, 0, removed);

      const payload = {
        data: reorderedList,
        from,
        to,
      };

      onDragEnd?.(payload);
    }

    setActiveItem(null);
  };

  const onCancel = () => {
    setActiveItem(null);
  };

  if (draggingEnabled) {
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={onStart}
        onDragEnd={onEnd}
        onDragCancel={onCancel}>
        <SortableContext
          items={data.map((item, index) => keyExtractor(item, index))}
          strategy={verticalListSortingStrategy}>
          <AnimatedFlatList
            data={data}
            keyExtractor={keyExtractor}
            ref={flatList => {
              if (ref && typeof ref === 'function') {
                ref(flatList);
              } else if (ref) {
                ref.current = flatList;
              }
            }}
            style={style}
            renderItem={({item, index}) => (
              <DragItem id={keyExtractor(item, index)}>
                {renderItem({
                  item,
                  index,
                  // fallback to API for draggable list on mobile
                  isDragged: false,
                  drag: dragFallback,
                })}
              </DragItem>
            )}
            windowSize={LIST_SETTINGS.windowSize}
            getItemLayout={getItemLayout}
            // We replace `onScroll` prop with `onScrollOffsetChange` (to match DraggableFlatList,
            // which only accepts an onScrollOffsetChange prop but no onScroll prop).
            onScroll={event =>
              onScrollOffsetChange?.(event.nativeEvent.contentOffset.y)
            }
            scrollEventThrottle={16}
            {...otherProps}
          />
        </SortableContext>
        <>
          {createPortal(
            <DragOverlay>
              {activeItem
                ? renderItem({
                    item: activeItem.item,
                    index: activeItem.index,
                    isDragged: true,
                    drag: dragFallback,
                  })
                : null}
            </DragOverlay>,
            document.body,
          )}
        </>
      </DndContext>
    );
  }

  // if dragging is not enabled just return standard FlatList - it has better performance
  return (
    <AnimatedFlatList
      data={data}
      keyExtractor={keyExtractor}
      ref={ref}
      style={style}
      renderItem={({item, index}) =>
        renderItem({item, index, isDragged: false, drag: dragFallback})
      }
      getItemLayout={getItemLayout}
      onScroll={event =>
        onScrollOffsetChange?.(event.nativeEvent.contentOffset.y)
      }
      {...otherProps}
    />
  );
}

export default React.forwardRef(DraggableList) as <ItemType>(
  props: IDraggableListProps<ItemType> & {
    ref?: React.ForwardedRef<FlatList<ItemType>>;
  },
) => ReturnType<typeof DraggableList>;
