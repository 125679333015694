import React, {FC, ReactNode} from 'react';
import {StyleProp} from 'react-native';

import Space, {ISpaceProps} from '@/components/Space/Space';
import {useThemedStyles} from '@/theme';

import {styles} from './Card.style';

interface IProps {
  children?: ReactNode;
  inverted?: boolean;
  highlight?: boolean;
  disabled?: boolean;
  style?: StyleProp<any>;
  pressed?: boolean;
  hovered?: boolean;
}

const Card: FC<IProps & ISpaceProps> = ({
  children,
  highlight = false,
  inverted = false,
  disabled = false,
  pressed = false,
  hovered = false,
  style,
  ...spaceProps
}) => {
  const cardStyle = useThemedStyles(styles);

  return (
    <Space
      p="s"
      fw
      style={[
        cardStyle.card,
        highlight && cardStyle.highlight,
        inverted && cardStyle.inverted,
        disabled && cardStyle.disabled,
        pressed && cardStyle.pressed,
        hovered && cardStyle.hovered,
        hovered && highlight && cardStyle.hoveredHighlighted,
        style,
      ]}
      {...spaceProps}>
      {children}
    </Space>
  );
};

export default Card;
