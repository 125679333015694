import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import SpinampWalletIcon from '@/components/SpinampWalletIcon';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './CreatePasskeyBanner.style';

interface IProps {
  onAddPasskeyWallet: () => void;
}

const CreatePasskeyBanner: FC<IProps> = ({onAddPasskeyWallet}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.addSpinampWalletContainer}>
      <SpinampWalletIcon size={spacing.s * 2} style={style.spinampWalletIcon} />

      <Text
        align="center"
        size="xs"
        weight="semibold"
        id="wallets.addSpinampWallet.header"
      />

      <Text
        align="center"
        size="xs"
        id="wallets.addSpinampWallet.description"
      />

      <CardButton
        onPress={onAddPasskeyWallet}
        text={{id: 'wallets.addSpinampWallet', size: 'xs'}}
      />
    </View>
  );
};

export default CreatePasskeyBanner;
