import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const SCREEN_PADDING = spacing.s;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    scrollContent: {
      flexGrow: 1,
      padding: SCREEN_PADDING,
      paddingBottom: Platform.select({
        default: SCREEN_PADDING,
        ios: SCREEN_PADDING + safeAreaInsets.bottom,
      }),
    },
  }),
);
