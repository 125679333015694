import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles((theme, responsive) => ({
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: spacing.s * 2,
    gap: spacing.l,
  },
  textsContainer: {
    gap: spacing.xs * 1.5,
  },
  contactRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  receivingWallet: {
    gap: spacing.xs,
  },
  receivingWalletCardAction: {
    paddingHorizontal: spacing.xs * 1.5,
  },
  spacer: {
    minHeight: responsive.isMobile ? 0 : 64,
  },
  footer: {
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.xs * 1.5,
    gap: spacing.xs,
  },
  footerPriceInfo: {
    alignItems: 'flex-end',
  },
  priceContainer: {
    alignItems: 'flex-end',
  },
  validationError: {
    marginVertical: spacing.xs,
  },
}));
