import React, {FC, ReactNode} from 'react';

import CardButton from '@/components/CardButton';
import ScrollContainer from '@/components/ScrollContainer';
import Space from '@/components/Space';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {
  EtherscanLink,
  JiffyscanLink,
  TransactionError,
} from '@/modules/Transactions';
import {PasskeyError} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {IUser} from '@/types/user';
import {isOSXBrowser} from '@/utils/platform';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './TransferErrorInfo.style';

interface IProps {
  header?: ReactNode;
  user?: IUser;
  senderWallet?: IAddress;
  transactionError?: any;
  txHash?: string;
  userOpHash?: string;
  chainId: number;
  onReset: () => void;
  onClose: () => void;
}

const TransferErrorInfo: FC<IProps> = ({
  header,
  user,
  senderWallet,
  transactionError,
  txHash,
  userOpHash,
  chainId,
  onReset,
  onClose,
}) => {
  const style = useThemedStyles(styles);

  const seeDetailsAction = txHash ? (
    <Space mt="xs">
      <EtherscanLink txHash={txHash} chainId={chainId} />
    </Space>
  ) : userOpHash ? (
    <Space mt="xs">
      <JiffyscanLink userOpHash={userOpHash} chainId={chainId} />
    </Space>
  ) : null;

  return (
    <ScrollContainer contentStyle={style.content}>
      {header}

      {(() => {
        if (
          transactionError instanceof PasskeyError &&
          transactionError.status === 'MISSING_BLOB'
        ) {
          return (
            <WarningBox
              title={{id: 'collect.error.missingBlob'}}
              message={{
                id: isOSXBrowser
                  ? 'collect.error.missingBlob.osx'
                  : 'collect.error.missingBlob.default',
              }}
            />
          );
        }

        if (
          transactionError instanceof TransactionError &&
          transactionError.status === 'WRONG_PASSKEY_SIGNER'
        ) {
          return (
            <WarningBox
              title={{id: 'collect.error.wrongSigner'}}
              message={
                user && {
                  id: 'collect.error.wrongSigner.message',
                  values: {
                    user: getUserDisplayName(user),
                  },
                }
              }
            />
          );
        }

        if (
          transactionError instanceof TransactionError &&
          transactionError.status === 'TRANSACTION_REVERTED'
        ) {
          return (
            <WarningBox
              title={{id: 'transfer.error.reverted'}}
              action={seeDetailsAction}
            />
          );
        }

        return (
          <WarningBox
            title={{id: 'transfer.error.generic'}}
            message={{id: 'transfer.error.generic.message'}}
            action={seeDetailsAction}
          />
        );
      })()}

      {senderWallet && <CardButton onPress={onReset} text={{id: 'tryAgain'}} />}
      <TransparentButton
        style={style.secondaryAction}
        onPress={onClose}
        text={{id: 'cancel'}}
      />
    </ScrollContainer>
  );
};

export default TransferErrorInfo;
