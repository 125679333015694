import {
  useQuery,
  useQueryClient,
  useMutation,
  useInfiniteQuery,
} from '@tanstack/react-query';
import {useMemo} from 'react';

import {MINUTE} from '@/constants/time';
import {useAppSelector} from '@/hooks/useRedux';
import {
  addNotification,
  fetchNotificationsHistory,
  fetchUnreadNotificationsCount,
  fetchUpcomingNotifications,
} from '@/modules/Notifications/api';
import {ISpindexerNotification} from '@/modules/Notifications/types';
import {selectActiveUserId, selectActiveUserSigner} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';
import {flattenItems, getNextPageParam} from '@/utils/pagination';

export const useUpcomingNotificationsQuery = (
  userId?: string,
  entityId?: string,
) => {
  const query = useQuery({
    queryKey: [QueryKeys.upcomingNotifications, userId, entityId],
    queryFn: () => fetchUpcomingNotifications({userId, entityId}),
    enabled: !!userId && !!entityId,
    staleTime: MINUTE,
  });

  return {
    notifications: query.data || [],
    query,
  };
};

export const useNotificationHistoryQuery = (userId: string | undefined) => {
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.notificationsHistory, userId],
    queryFn: ({pageParam}) => fetchNotificationsHistory(userId!, pageParam),
    enabled: !!userId,
    initialPageParam: undefined,
    getNextPageParam,
  });

  const notifications = useMemo(
    () => (query.data ? flattenItems(query.data) : undefined),
    [query.data],
  );

  return {
    notifications,
    query,
  };
};

export const useUnreadNotificationsCountQuery = (
  userId: string | undefined,
) => {
  const query = useQuery({
    queryKey: [QueryKeys.unreadNotificationsCount, userId],
    queryFn: () => fetchUnreadNotificationsCount(userId!),
    enabled: !!userId,
  });

  return {
    count: query.data,
    query,
  };
};

export const useNotificationMutation = () => {
  const queryClient = useQueryClient();
  const userId = useAppSelector(selectActiveUserId);
  const signer = useAppSelector(selectActiveUserSigner);

  return useMutation({
    retry: 0,
    mutationFn: async (upsert: ISpindexerNotification) => {
      if (!userId || !signer) {
        throw new Error('userId or signer missing');
      }
      return addNotification(upsert, signer);
    },
    onMutate: async upsert => {
      queryClient.setQueryData<ISpindexerNotification[]>(
        [QueryKeys.upcomingNotifications, userId, upsert.entityId],
        currentNotifications => {
          if (!currentNotifications) {
            return [upsert];
          }

          if (
            !currentNotifications.find(
              n =>
                n.entityId === upsert.entityId &&
                n.userId === upsert.userId &&
                n.entityType === upsert.entityType,
            )
          ) {
            return [...currentNotifications, upsert];
          }

          return currentNotifications.map(n =>
            n.entityId === upsert.entityId &&
            n.userId === upsert.userId &&
            n.entityType === upsert.entityType
              ? {...n, ...upsert}
              : n,
          );
        },
      );
      return upsert;
    },
    onError(error, notification) {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeys.upcomingNotifications,
          userId,
          notification.entityId,
        ],
      });
    },
  });
};
