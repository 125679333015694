import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import {useCallback, useEffect, useMemo, useRef} from 'react';

import {fetchFeed, fetchFeedCount, requestFeedGeneration} from '@/api/feed';
import useOnFocus from '@/hooks/useOnFocus';
import useOnOnline from '@/hooks/useOnOnline';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useDbQuery} from '@/queries/db';
import {isGenreChannel} from '@/screens/Feed/utils';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {selectActiveUserId} from '@/store/user';
import {IPaginatedResponse} from '@/types/api';
import {IArtist, IArtistWithTracks, ITrack} from '@/types/common';
import {
  IFeedEntityType,
  IFeedItem,
  IFeedItemRaw,
  IFeedItemWithArtist,
  IFeedItemWithTrack,
  IGeneratorConfig,
} from '@/types/feed';
import {MutationKeys} from '@/types/mutationKeys';
import {QueryKeys} from '@/types/queryKeys';
import {isArtist, isTrack, sortFeedItems} from '@/utils/feed';
import {flatChunkedArray, isNotNil, merge, omit} from '@/utils/functions';
import {getNextPageParam} from '@/utils/pagination';

function assertNever(x: never) {
  Sentry.captureMessage(
    'Error: assertNever was invoked. Unexpected: ' + JSON.stringify(x),
  );
}

const enrichFeedItem =
  (
    tracks: Record<string, ITrack>,
    artists: Record<string, IArtist>,
    trackIdsByArtist: Record<string, string[]>,
  ) =>
  (item: IFeedItem | IFeedItemRaw): IFeedItem | null => {
    switch (item.entityType) {
      case IFeedEntityType.track: {
        if ('track' in item) {
          // This item is already enriched
          return item;
        }

        const track = tracks[item.entityId];
        if (!track) {
          console.warn(`No track found for feed item id "${item.id}"`);
          Sentry.captureMessage(`No track found for feed item id "${item.id}"`);
          return null;
        }

        return {
          ...item,
          track,
          entityType: IFeedEntityType.track,
        };
      }

      case IFeedEntityType.artist: {
        if ('artist' in item) {
          // This item is already enriched (eg because it was inserted into the cache
          // from the useFeedItemMutation's onMutate)
          return item;
        }

        const selectedTracks = trackIdsByArtist[item.entityId]?.map(
          id => tracks[id],
        );

        if (selectedTracks == null) {
          Sentry.captureMessage(
            `Null or undefined entry in trackIdsByArtist for artist ${item.entityId}`,
          );
          console.warn(
            `Null or undefined entry in trackIdsByArtist for artist ${item.entityId}`,
          );
          return null;
        }

        if (!artists[item.entityId]) {
          Sentry.captureMessage(
            `No artist found for feed item id "${item.id}"`,
          );
          console.warn(`No artist found for feed item id "${item.id}"`);
          return null;
        }

        const artist = {
          ...artists[item.entityId],
          tracks: selectedTracks,
        };

        return {
          ...item,
          artist,
          entityType: IFeedEntityType.artist,
        };
      }

      case IFeedEntityType.message: {
        if (item.message) {
          return {
            ...item,
            entityType: IFeedEntityType.message,
            message: item.message,
          };
        } else {
          item satisfies IFeedItemRaw;
          return null;
        }
      }

      // don't allow localMessages returned from a query -- they must come from this device
      case IFeedEntityType.localMessage: {
        return null;
      }

      case IFeedEntityType.refill: {
        return {
          ...item,
          entityType: IFeedEntityType.refill,
        };
      }

      default: {
        assertNever(item);
        return null;
      }
    }
  };

type UseRawFeedQueryOptions = {
  refetchOnWindowFocus?: boolean;
  refetchOnReconnect?: boolean;
  refetchOnMount?: boolean;
  enabled?: boolean;
  staleTime?: number;
};

export const useRawFeedQuery = (
  userId?: string,
  options?: UseRawFeedQueryOptions,
  sessionStart?: string,
) => {
  const {updateDb} = useDbQuery();

  return useInfiniteQuery({
    queryKey: [QueryKeys.feed, userId],
    queryFn: async ({pageParam}) => {
      const pageSize = 30;

      const {items, pageInfo} = await fetchFeed(
        userId!,
        {
          first: pageSize,
          after: pageParam,
        },
        sessionStart,
      );
      const tracks: ITrack[] = items
        .filter(
          (item): item is IFeedItemWithTrack =>
            item.entityType === IFeedEntityType.track,
        )
        .map(item => item.track);

      const artists: IArtistWithTracks[] = items
        .filter(
          (item): item is IFeedItemWithArtist =>
            item.entityType === IFeedEntityType.artist,
        )
        .map(item => item.artist);

      const selectedTrackIdsByArtist = artists.reduce(
        (acc, artist) => ({
          ...acc,
          [artist.id]: artist.tracks.map(track => track.id),
        }),
        {} as Record<string, string[]>,
      );

      updateDb({
        tracks: tracks.concat(artists.flatMap(artist => artist.tracks)),
        artists,
      });

      const strippedItems: IFeedItemRaw[] = items.map(item => {
        if (isTrack(item)) {
          return omit(item, 'track');
        }
        if (isArtist(item)) {
          return omit(item, 'artist');
        }
        return item;
      });

      return {
        pageInfo,
        items: strippedItems,
        // We want to preserve the API's ability to choose the short selection of tracks we
        // should show for each artist. Returning this allows users of this hook to know
        // which tracks to use when enriching artist feed items.
        trackIdsByArtist: selectedTrackIdsByArtist,
      };
    },
    initialPageParam: undefined,
    getNextPageParam,
    enabled: !!userId,
    ...options,
  });
};

export const useFeedCountQuery = (userId?: string) => {
  return useQuery({
    queryKey: [QueryKeys.feedCount, userId],
    queryFn: () => fetchFeedCount(userId!),
    enabled: !!userId,
  });
};

export const useFeedQuery = (
  userId?: string,
  options?: UseRawFeedQueryOptions,
  sessionStart?: string,
) => {
  const query = useRawFeedQuery(userId, options, sessionStart);
  const activeUserId = useAppSelector(selectActiveUserId);

  const {db} = useDbQuery();

  const allEnrichedFeedItems = useMemo(() => {
    const allFeedItems = flatChunkedArray(
      query.data?.pages.map(page => page.items) || [],
    );

    // if an artist appears multiple times, their list of selected tracks
    // will be overwritten by the later occurrence
    const allTracksIdsByArtist = merge(
      ...(query.data?.pages.map(page => page.trackIdsByArtist) ?? []),
    );

    const enrichedFeedItems = allFeedItems.map(
      enrichFeedItem(db.tracks, db.artists, allTracksIdsByArtist),
    );

    return (
      enrichedFeedItems.filter(
        item =>
          isNotNil(item) &&
          (activeUserId === userId ||
            item.entityType !== IFeedEntityType.refill),
      ) as IFeedItem[]
    ).sort(sortFeedItems);
  }, [query.data?.pages]);

  return {
    feedItems: query.data?.pages ? allEnrichedFeedItems : null,
    query,
  };
};

interface IMutationOptions {
  onError?: (error: unknown) => void;
}

interface IUseGenerateFeedMutationOptions extends IMutationOptions {
  onSuccess: (
    data: number,
    variables: IGeneratorConfig[] | undefined,
    context: unknown,
  ) => void;
  staleTime: number;
}

export function useFeedItemMutation(options?: IMutationOptions) {
  const toast = useToast();

  const mutation = useMutation({
    mutationKey: [QueryKeys.feed],
    networkMode: 'always',
    // Hiding feed items should be immediately reflected on the UI, so we need optimistic update for that.
    // Likes are not reflected on UI directly.
    onMutate: async (newFeedItem: IFeedItemRaw) => {
      if (newFeedItem.userAction === 'hide') {
        const queryKey = [QueryKeys.feed, newFeedItem.userId];
        await queryClient.cancelQueries({queryKey});

        mutateFeedItemInCache(newFeedItem, queryKey);
      }
    },
    onError: _error => {
      if (options?.onError) {
        options.onError(_error);
      } else {
        console.error('feedItemMutation', _error);
        toast.showToast({textId: 'feed.genericError'});
      }
    },
  });

  // Set `updatedAtTime`
  const mutate = useCallback(
    (feedItem: IFeedItemRaw) =>
      mutation.mutate({
        ...feedItem,
        updatedAtTime: new Date().toISOString(),
      }),
    [mutation.mutate],
  );

  return {
    mutate,
    mutation,
  };
}

export const mutateFeedItemInCache = (
  newFeedItem: IFeedItemRaw,
  queryKey: string[],
) => {
  if (newFeedItem.userAction === 'hide') {
    queryClient.setQueryData<InfiniteData<IPaginatedResponse<IFeedItemRaw>>>(
      queryKey,
      currentData => {
        if (!currentData) {
          return currentData;
        }

        return {
          ...currentData,
          pages: currentData.pages.map(page => {
            return {
              ...page,
              items: page.items.filter(
                feedItem => feedItem.id !== newFeedItem.id,
              ),
            };
          }),
        };
      },
    );
  }
};

export function useGenerateFeedMutation(
  userId?: string,
  options?: IUseGenerateFeedMutationOptions,
  insertAtPosition?: number,
  limit?: number,
) {
  const mutation = useMutation({
    mutationKey: [MutationKeys.feedGeneration],
    mutationFn: (generatorsConfig?: IGeneratorConfig[]) =>
      requestFeedGeneration(userId!, generatorsConfig, insertAtPosition, limit),
    onSuccess: async (
      data: number,
      variables: IGeneratorConfig[] | undefined,
      context: unknown,
    ) => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.feed, userId],
      });

      options?.onSuccess(data, variables, context);
    },
    ...(options ? omit(options, 'onSuccess') : {}),
  });

  return {
    generateFeed: mutation.mutate,
    generateFeedAsync: mutation.mutateAsync,
    mutation: mutation,
  };
}

/**
 * A hook to manage a feed query that automatically regenerates the feed before refetching it.
 *
 * This is needed because getting a user's feed from spindexer is a 2-step process: regenerate
 * the feed with a POST request to populate it with any new feed items, then query it via GraphQL.
 *
 * This hook sets up automatic regeneration which mirrors React Query's; namely regenerating and then
 * triggering a refetch (via invalidation) whenever the query mounts, network reconnects, or window
 * refocuses. The generation step is skipped for specific queries on the feed that are unaffected by
 * feed generation: hides and likes.
 *
 * @param userId the ID of the user whose feed we'll be fetching
 * @param options options passed to the useQuery that fetches the feed
 * @param sessionStart which is timestamp of user app session start. Is used to fetch feed item liked after that
 * so items do not disappear immediately after liking
 */
export function useRegeneratingFeedQuery(
  userId?: string,
  options?: UseRawFeedQueryOptions,
  sessionStart?: string,
) {
  const needsGeneration = !!userId && !isGenreChannel(userId);

  const hasGenerated = useRef(false);

  const generateFeedMutation = useGenerateFeedMutation(userId);

  // If it doesn't need generation, use it as standard useQuery. Otherwise, disable query and run it manually after generation
  const {feedItems, query} = useFeedQuery(
    userId,
    {
      refetchOnWindowFocus: !needsGeneration,
      refetchOnReconnect: !needsGeneration,
      refetchOnMount: !needsGeneration,
      enabled: !needsGeneration || hasGenerated.current,
      ...options,
    },
    sessionStart,
  );

  const generateAndRefetch = useCallback(() => {
    async function run() {
      if (needsGeneration) {
        await generateFeedMutation.generateFeedAsync(undefined);
        hasGenerated.current = true;
      }
    }

    run();
  }, [userId, needsGeneration]);

  const regenerateFeed = useCallback(async () => {
    generateFeedMutation.generateFeed(undefined);
  }, [userId]);

  useEffect(generateAndRefetch, []);
  useOnOnline(generateAndRefetch);
  useOnFocus(generateAndRefetch);

  return {feedItems, query, regenerateFeed};
}
