import React, {FC, useMemo} from 'react';
import {View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import {getChainByCode} from '@/constants/chains';
import {tipChain} from '@/modules/Tip/constants';
import {IArtistTippers, IPendingBalances} from '@/modules/Tip/types';
import {sumPendingBalance} from '@/modules/Tip/utils';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {formatPrice} from '@/utils/ethereum';

import {AVATAR_SIZE, styles} from './TipsSummary.style';

interface IProps {
  totalTipsBalance: string;
  pendingBalances: IPendingBalances;
  tippers: IArtistTippers | undefined;
}

const TipsSummary: FC<IProps> = ({
  totalTipsBalance,
  pendingBalances,
  tippers,
}) => {
  const style = useThemedStyles(styles);

  const totalPendingBalance = useMemo(
    () => sumPendingBalance(pendingBalances),
    [pendingBalances],
  );

  const tipperUsers = tippers?.users.slice(0, 6);

  return (
    <View style={style.container}>
      <View style={style.column}>
        <Text id="tipsSettings.summary.total" />

        <View style={style.amountRow}>
          <Icon
            size={20}
            provider="custom"
            name={`chain_${getChainByCode('optimism').code}`}
          />
          <Text weight="semibold" size="xl">
            {formatPrice(totalTipsBalance, tipChain.chain.nativeCurrency)}
          </Text>
        </View>

        <View style={style.pendingPill}>
          {totalPendingBalance === BigInt(0) ? (
            <Text id="tipsSettings.summary.wellDone" size="xs" />
          ) : (
            <Text
              id="tipsSettings.summary.pendingAmount"
              size="xs"
              values={{
                amount: formatPrice(
                  totalPendingBalance,
                  tipChain.chain.nativeCurrency,
                ).split(' ')[0],
              }}
            />
          )}
        </View>
      </View>

      <FadeInOut enabled={!tippers}>
        <View style={[style.column, style.column_right]}>
          <Text id="tipsSettings.summary.tippedBy" />
          <Text
            weight="semibold"
            size="xl"
            id="tipsSettings.summary.fans"
            values={{number: tippers?.totalCount || 0}}
          />
          <View style={style.tippers}>
            {tipperUsers?.map((user, index) => (
              <Avatar
                key={user.id + index}
                id={user.id}
                url={user.avatarUrl}
                size={AVATAR_SIZE}
                resizeWidth={ImageSize.avatar}
                style={{
                  transform: [
                    {
                      translateX:
                        ((tipperUsers.length - 1 - index) * AVATAR_SIZE) / 3,
                    },
                  ],
                }}
              />
            ))}
            {!tippers && <View style={style.tippersPlaceholder} />}
          </View>
        </View>
      </FadeInOut>
    </View>
  );
};

export default TipsSummary;
