import {useScrollToTop} from '@react-navigation/native';
import React, {FC, useMemo, useRef} from 'react';

import {AVATAR_CARD_HEIGHT} from '@/components/AvatarCard';
import InfinityList from '@/components/InfinityList';
import Link from '@/components/Link';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import FollowedUserCard from '@/screens/Library/components/FollowedUserCard';
import {LibraryUI} from '@/screens/Library/components/LibrarySharedUI';
import {useFollowedCollectorsPlaylists} from '@/screens/Library/hooks/useFollowedPlaylists';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';
import {Routes} from '@/types/routes';

import * as style from './style';

const activityFeedLink = {
  screen: Routes.ActivityFeed,
};

const LibraryCollectors: FC = () => {
  const listRef = useRef(null);
  const {deletePlaylist, onScroll} = useLibraryContext();

  const {
    followedCollectorsPlaylists,
    isEmpty,
    query: {isLoading, isError, refetch},
  } = useFollowedCollectorsPlaylists();

  useScrollToTop(listRef);

  const ListFooter = useMemo(
    () => (
      <Space mv="xs">
        <Link to={activityFeedLink} style={style.centered}>
          <Text
            align="center"
            size="xs"
            underline
            id="library.collectors.discoverMore"
          />
        </Link>
      </Space>
    ),
    [],
  );

  if (!isEmpty) {
    return (
      <InfinityList
        ref={listRef}
        data={followedCollectorsPlaylists}
        onScroll={onScroll}
        itemSize={AVATAR_CARD_HEIGHT}
        keyExtractor={playlist => playlist.id}
        ListHeaderComponent={<LibraryUI.Title />}
        renderItem={({item: playlist}) => (
          <FollowedUserCard playlist={playlist} onUnfollow={deletePlaylist} />
        )}
        ListFooterComponent={ListFooter}
      />
    );
  }

  if (isLoading) {
    return <ScreenLoader />;
  }

  if (isError) {
    return <LibraryUI.Error onRetry={refetch} />;
  }

  return (
    <LibraryUI.Empty textId="library.collectors.empty">
      <Space mt="xxs">
        <Link style={style.centered} to={activityFeedLink}>
          <Text id="library.collectors.discoverHere" underline />
        </Link>
      </Space>
    </LibraryUI.Empty>
  );
};

export default LibraryCollectors;
