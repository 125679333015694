import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const INPUT_HEIGHT = spacing.xl;
export const INPUT_PADDING = spacing.xs;
const FONT_SIZE = 14;
const LINE_HEIGHT = Math.round(1.2 * FONT_SIZE);
const MULTILINE_LINES = 5;

export const styles = createThemedStyles(theme => ({
  container: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    paddingHorizontal: theme.box.cardHorizontalPadding,
    paddingVertical: INPUT_PADDING,
    minHeight: INPUT_HEIGHT,
    gap: spacing.xs,
    cursor: 'text',
  },
  inputWrapper: {
    flex: 1,
    gap: spacing.xxs / 2,
  },
  input: {
    color: theme.colors.textColor,
    fontSize: FONT_SIZE,
    lineHeight: LINE_HEIGHT,
    fontFamily: theme.font.light,
    opacity: 0.4,
  },
  inputActive: {
    fontFamily: theme.font.semibold,
    opacity: 1,
  },
  inputMultiline: {
    height: INPUT_PADDING * 2 + LINE_HEIGHT * MULTILINE_LINES,
  },
}));
