import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    gap: spacing.l,
  },
  secondaryAction: {
    alignSelf: 'center',
  },
}));
