import {useIsFocused} from '@react-navigation/native';
import {useContext, useEffect, useMemo} from 'react';

import {useResponsive} from '@/hooks/useResponsive';
import {useThemes} from '@/hooks/useThemes';
import {ThemeContext} from '@/theme/context';
import {makeTheme} from '@/themes/partialTheme';
import {IDynamicTheme, ITheme} from '@/types/theme';

// Theme returned from this hook should be used with ThemedScreen component to provide theme context over child components.
// On mobile it will set theme for child components.
// On desktop it will override global theme.
export const useThemedScreen = (themeSettings?: IDynamicTheme) => {
  const {isMobile} = useResponsive();
  const isFocused = useIsFocused();
  const {
    theme: globalTheme,
    overrideTheme,
    resetTheme,
  } = useContext(ThemeContext);
  const {getTheme, isValidTheme, themesData} = useThemes();

  const customTheme = themeSettings?.customTheme;
  const predefinedThemeName = themeSettings?.predefinedThemeName;

  // On desktop we need to override theme in global theme provider
  useEffect(() => {
    if (!isMobile && isFocused && overrideTheme && resetTheme) {
      if (customTheme || predefinedThemeName) {
        overrideTheme({customTheme, predefinedThemeName});
      } else {
        resetTheme();
      }
    }
  }, [isMobile, customTheme, predefinedThemeName, isFocused, themesData]);

  // On mobile we want to generate theme object based on provided dynamic theme settings
  const dynamicTheme: ITheme | undefined = useMemo(() => {
    if (!isMobile) {
      return undefined;
    }

    if (customTheme) {
      return makeTheme({basic: customTheme});
    }

    if (predefinedThemeName && isValidTheme(predefinedThemeName)) {
      return getTheme(predefinedThemeName);
    }

    return undefined;
  }, [isMobile, predefinedThemeName, customTheme, getTheme, isValidTheme]);

  return dynamicTheme || globalTheme;
};
