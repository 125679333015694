import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const PAGE_PADDING = spacing.s;

export const styles = createThemedStyles(() => ({
  scrollView: {
    flex: 1,
  },
  scrollViewContent: {
    padding: PAGE_PADDING,
    flexGrow: 1,
  },
  searchHistoryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  clearSearchRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    gap: spacing.s,
  },
}));
