import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Text from '@/components/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useUnreadNotificationsCountQuery} from '@/modules/Notifications/query';
import {useThemedStyles} from '@/theme';

import {styles} from './NotificationsBadge.style';

interface IProps {
  containerStyle?: StyleProp<any>;
  icon?: Omit<IIconProps, 'provider' | 'name'>;
}

const NotificationsBadge: FC<IProps> = ({containerStyle, icon}) => {
  const style = useThemedStyles(styles);

  const activeUser = useActiveUser();
  const {count} = useUnreadNotificationsCountQuery(activeUser?.id);

  return (
    <View style={containerStyle}>
      <Icon name="notifications" provider="custom" {...icon} />
      {!!count && (
        <View style={style.badge}>
          <Text size="xxs" weight="semibold" style={style.badgeText}>
            {count}
          </Text>
        </View>
      )}
    </View>
  );
};

export default NotificationsBadge;
