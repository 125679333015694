import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const PROGRESS_HEIGHT = spacing.xxs * 1.5;
const PROGRESS_TOUCH_AREA = spacing.m;

export const styles = createThemedStyles(theme => ({
  progressWrapper: {
    height: PROGRESS_HEIGHT,
    zIndex: 100,
  },
  hitSlopContainer: {
    height: PROGRESS_TOUCH_AREA,
    position: 'absolute',
    top: -(PROGRESS_TOUCH_AREA - PROGRESS_HEIGHT) / 2,
    right: 0,
    left: 0,
  },
  progress: {
    backgroundColor: theme.colors.active,
    borderRadius: 0,
    height: PROGRESS_HEIGHT,
  },
}));
