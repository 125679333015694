import {createContext} from 'react';

import {TTextValues} from '@/components/Text/Text';

export interface IGlobalToast {
  text?: string;
  textId?: string;
  values?: TTextValues;
}

export interface IGlobalToastContext {
  showToast: (toast: IGlobalToast) => void;
  hideToast: () => void;
}

export const GlobalToastContext = createContext<
  IGlobalToastContext | undefined
>(undefined);
