import React from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon';
import spacing, {HIT_SLOP} from '@/constants/spacing';

import {IAction} from './shared';

interface IProps {
  actions: IAction[];
}

const ExposedCardMenuContent: React.FC<IProps> = ({actions}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        gap: spacing.xxs,
        alignItems: 'center',
      }}>
      {actions.map((action, index) => (
        <TouchableOpacity
          style={{
            width: 32,
            height: 32,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          key={index}
          onPress={action.onPress}
          hitSlop={HIT_SLOP}>
          <Icon {...action.icon} />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default ExposedCardMenuContent;
