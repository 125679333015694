import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectTopUp = (state: RootState) => state.topUp;

export const selectTopUpTransactions = createSelector(
  selectTopUp,
  topUp => topUp.transactions,
);
