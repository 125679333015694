import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  mobileModal: {
    maxHeight: '80%',
  },
  modalContent: {
    padding: spacing.m,
    gap: spacing.m,
    alignItems: 'center',
  },
  modalTextContainer: {
    alignSelf: 'stretch',
    gap: spacing.m,
  },
  modalButton: {
    alignSelf: 'stretch',
  },
}));
