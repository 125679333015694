import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import CardButton from '@/components/CardButton';
import {Form} from '@/components/Form';
import Input from '@/components/Input';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {APPLE_REVIEWER_ACCOUNT} from '@/constants/appleReview';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useAppDispatch} from '@/hooks/useRedux';
import {useLoginState, useLoginError} from '@/modules/Login';
import {useUsername} from '@/modules/Login/useUsername';
import {registerWithPasskey, signInToDemoAccount} from '@/store/user';
import {useThemedStyles} from '@/theme';

import {styles} from './SignUp.style';

const SignUp: FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const {setLoginRoute, setPasskeyCredential, closeLoginModal} =
    useLoginState();
  const [isLoading, startLoading, endLoading] = useBooleanState(false);
  const {handleLoginError, resetLoginError} = useLoginError();

  const {username, setUsername, isUsernameValid} = useUsername();

  const onRegisterPasskey = async () => {
    try {
      resetLoginError();
      startLoading();

      // Special case for signing in to demo account for apple reviewer
      if (username === APPLE_REVIEWER_ACCOUNT.username) {
        await dispatch(signInToDemoAccount());
        closeLoginModal();
        return;
      }

      const credential = await dispatch(registerWithPasskey(username));
      setPasskeyCredential(credential);
      setLoginRoute('linkPasskey');
    } catch (error) {
      handleLoginError(error);
    } finally {
      endLoading();
    }
  };

  return (
    <Space style={style.content}>
      <Text weight="semibold" id="login.signup.title" align="center" size="l" />

      <Text id="login.signup.description" size="xs" />

      <Form.Field label={{id: 'login.signup.username'}}>
        <Input
          placeholder="Music Lover"
          value={username}
          onChangeText={setUsername}
        />
        <Space h="xxs" />
        <Text
          size="xxs"
          numberOfLines={1}
          id={
            isUsernameValid
              ? 'login.signup.username.description'
              : 'profileEdit.error.restrictedName.short'
          }
        />
      </Form.Field>

      <View style={style.actions}>
        <CardButton
          onPress={onRegisterPasskey}
          text={{id: 'login.signup.register'}}
          disabled={!username || !isUsernameValid}
          isLoading={isLoading}
        />

        <View style={style.separatorContainer}>
          <View style={style.separator} />
          <Text id="login.or" />
          <View style={style.separator} />
        </View>

        <TouchableOpacity onPress={() => setLoginRoute('initial')}>
          <Text
            align="center"
            id="login.signup.alreadyUser"
            size="xs"
            values={{
              login: (
                <Text
                  weight="semibold"
                  id="login.signup.login"
                  size="xs"
                  underline
                />
              ),
            }}
          />
        </TouchableOpacity>
      </View>
    </Space>
  );
};

export default SignUp;
