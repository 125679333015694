import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    borderRadius: theme.box.borderRadius,
    paddingTop: spacing.xs,
    paddingBottom: spacing.xs * 1.5,
    paddingHorizontal: spacing.m,
    gap: spacing.xs,
    alignItems: 'center',
    backgroundColor: theme.colors.background,
  },
  titleRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
}));
