import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SMALL_SIZE = 24;

export const styles = createThemedStyles(theme => ({
  scrollViewContainer: {
    width: 250,
    paddingVertical: spacing.s,
  },
  profileItem: {
    paddingVertical: spacing.xs,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.xs,
  },
  profileItem_hover: {
    backgroundColor: theme.colors.background,
  },
  profileItem_pressed: {
    backgroundColor: theme.colors.backgroundDark,
  },
  profileItemIcon: {
    width: AVATAR_SMALL_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: spacing.xs,
  },
}));
