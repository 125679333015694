import {getChainByCode} from '@/constants/chains';

export const tipChain = getChainByCode('optimism');

export const suggestedAmounts = [5, 10, 20, 50, 100];

export const tipAddress = '0x35bab455947fa53cc611f80a9edc800118e9aa82';
export const tipsAbi = [
  {inputs: [], stateMutability: 'nonpayable', type: 'constructor'},
  {inputs: [], name: 'AccessControlBadConfirmation', type: 'error'},
  {
    inputs: [
      {internalType: 'address', name: 'account', type: 'address'},
      {internalType: 'bytes32', name: 'neededRole', type: 'bytes32'},
    ],
    name: 'AccessControlUnauthorizedAccount',
    type: 'error',
  },
  {
    inputs: [{internalType: 'address', name: 'target', type: 'address'}],
    name: 'AddressEmptyCode',
    type: 'error',
  },
  {
    inputs: [
      {internalType: 'address', name: 'implementation', type: 'address'},
    ],
    name: 'ERC1967InvalidImplementation',
    type: 'error',
  },
  {inputs: [], name: 'ERC1967NonPayable', type: 'error'},
  {inputs: [], name: 'FailedInnerCall', type: 'error'},
  {inputs: [], name: 'InvalidInitialization', type: 'error'},
  {inputs: [], name: 'NotInitializing', type: 'error'},
  {inputs: [], name: 'UUPSUnauthorizedCallContext', type: 'error'},
  {
    inputs: [{internalType: 'bytes32', name: 'slot', type: 'bytes32'}],
    name: 'UUPSUnsupportedProxiableUUID',
    type: 'error',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'profile',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'verifiedAddress',
        type: 'address',
      },
    ],
    name: 'AddressVerified',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: false, internalType: 'uint64', name: 'version', type: 'uint64'},
    ],
    name: 'Initialized',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'previousAdminRole',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'newAdminRole',
        type: 'bytes32',
      },
    ],
    name: 'RoleAdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {indexed: true, internalType: 'address', name: 'sender', type: 'address'},
    ],
    name: 'RoleGranted',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {indexed: true, internalType: 'address', name: 'sender', type: 'address'},
    ],
    name: 'RoleRevoked',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {indexed: true, internalType: 'address', name: 'to', type: 'address'},
      {indexed: true, internalType: 'address', name: 'from', type: 'address'},
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'Tip',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'implementation',
        type: 'address',
      },
    ],
    name: 'Upgraded',
    type: 'event',
  },
  {
    inputs: [],
    name: 'DEFAULT_ADMIN_ROLE',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'UPGRADER_ROLE',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'UPGRADE_INTERFACE_VERSION',
    outputs: [{internalType: 'string', name: '', type: 'string'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'VERIFIER_ROLE',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'profile', type: 'address'}],
    name: 'balanceOf',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'bytes32', name: 'role', type: 'bytes32'}],
    name: 'getRoleAdmin',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'grantRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'hasRole',
    outputs: [{internalType: 'bool', name: '', type: 'bool'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: 'defaultAdmin', type: 'address'},
      {internalType: 'address', name: 'upgrader', type: 'address'},
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'profile', type: 'address'}],
    name: 'isAddressVerified',
    outputs: [{internalType: 'bool', name: '', type: 'bool'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'profile', type: 'address'}],
    name: 'payoutAddress',
    outputs: [{internalType: 'address', name: '', type: 'address'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'proxiableUUID',
    outputs: [{internalType: 'bytes32', name: '', type: 'bytes32'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'callerConfirmation', type: 'address'},
    ],
    name: 'renounceRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'bytes32', name: 'role', type: 'bytes32'},
      {internalType: 'address', name: 'account', type: 'address'},
    ],
    name: 'revokeRole',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [{internalType: 'bytes4', name: 'interfaceId', type: 'bytes4'}],
    name: 'supportsInterface',
    outputs: [{internalType: 'bool', name: '', type: 'bool'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'profile', type: 'address'}],
    name: 'tip',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: 'profile', type: 'address'},
      {internalType: 'address', name: 'newPayoutAddress', type: 'address'},
    ],
    name: 'updatePayoutAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: 'newImplementation', type: 'address'},
      {internalType: 'bytes', name: 'data', type: 'bytes'},
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: 'profile', type: 'address'},
      {internalType: 'address', name: 'payout', type: 'address'},
    ],
    name: 'verifyAddress',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {internalType: 'address', name: 'profile', type: 'address'},
      {internalType: 'address', name: 'payout', type: 'address'},
    ],
    name: 'verifyAddressAndWithdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'version',
    outputs: [{internalType: 'uint8', name: '', type: 'uint8'}],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [{internalType: 'address', name: 'source', type: 'address'}],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
