import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 140;

export const styles = createThemedStyles(() => ({
  container: {
    gap: spacing.s * 2,
    marginBottom: spacing.s * 2,
  },
  titleText: {
    maxWidth: '100%',
  },
  description: {
    paddingHorizontal: spacing.s,
  },
  socialLinks: {
    flexDirection: 'row',
    gap: spacing.s,
    flexWrap: 'wrap',
    alignSelf: 'center',
  },
}));
