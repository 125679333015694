import React, {FC} from 'react';
import {View} from 'react-native';

import DashedDivider from '@/components/DashedDivider';
import Text, {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './SectionTitle.style';

interface IProps {
  text: TextProps;
}

const SectionTitle: FC<IProps> = ({text}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.sectionTitle}>
      <DashedDivider
        style={[style.sectionDivider, style.sectionDivider_left]}
      />
      <Text size="l" weight="semibold" {...text} numberOfLines={1} />
      <DashedDivider
        style={[style.sectionDivider, style.sectionDivider_right]}
      />
    </View>
  );
};

export default SectionTitle;
