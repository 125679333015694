import {RouteProp, useRoute} from '@react-navigation/native';
import React from 'react';

import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import ScreenError from '@/components/ScreenError';
import ScreenLoader from '@/components/ScreenLoader';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useUserQuery} from '@/queries/user';
import {MainStackParams, Routes} from '@/types/routes';
import {getUserDisplayName} from '@/utils/user';

import ReleasesForYou from './components/ReleasesForYou';

const ReleasesForYouScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();

  const {params} =
    useRoute<RouteProp<MainStackParams, Routes.ImpersonatingReleasesForYou>>();
  const {slug} = params || {};

  const {user: impersonatingUser, query: impersonatingUserQuery} =
    useUserQuery(slug);

  const renderContent = () => {
    if (impersonatingUserQuery.isLoading) {
      return (
        <>
          <Header showBack />
          <ScreenLoader />;
        </>
      );
    }

    if (impersonatingUserQuery.isError) {
      return (
        <>
          <Header showBack />
          <ScreenError textId="error.title" />
        </>
      );
    }

    if (!impersonatingUser) {
      return (
        <>
          <Header showBack />
          <ScreenError textId="collector.notFound" />;
        </>
      );
    }

    return (
      <>
        <Header
          titleId="releasesForYou.impersonating.title"
          titleValues={{
            impersonatingUserName: getUserDisplayName(impersonatingUser),
          }}
          showBack
          scrollPosition={scrollPosition}
          mockTitle={true}
        />

        <ReleasesForYou
          userId={impersonatingUser.id}
          titleId="releasesForYou.impersonating.title"
          descriptionId="releasesForYou.impersonating.description"
          emptyStateTextId="releasesForYou.impersonating.emptyState"
          showSuggestedFollows={false}
          onScroll={onScroll}
        />
      </>
    );
  };

  return <Screen>{renderContent()}</Screen>;
};

export default ReleasesForYouScreen;
