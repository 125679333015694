import {useQuery} from '@tanstack/react-query';

import {fetchWalletById} from '@/api/wallet';
import {QueryKeys} from '@/types/queryKeys';

export const useWalletByIdQuery = (id?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.walletById, id],
    queryFn: () => fetchWalletById(id!),
    enabled: !!id,
    staleTime: 1000 * 60,
  });

  return {
    wallet: query.data,
    query,
  };
};
