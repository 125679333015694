import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import SocialShareButtons from '@/components/SocialShareButtons';
import Text from '@/components/Text';
import {useInviteToTip} from '@/modules/Tip/useInviteToTip';
import {useThemedStyles} from '@/theme';
import {IArtist} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './TipsEmptyState.style';

interface IProps {
  artist: IArtist;
  isOwnProfile?: boolean;
}

const TipsEmptyState: FC<IProps> = ({artist, isOwnProfile}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const shareActions = useInviteToTip(artist.slug);

  if (isOwnProfile) {
    return (
      <View style={style.container}>
        <Text id="artist.tips.empty.own.message" align="center" />
        <SocialShareButtons {...shareActions} />
      </View>
    );
  }

  return (
    <View style={style.container}>
      <Text id="artist.tips.empty.message" />
      <CardButton
        style={style.action}
        onPress={() => navigation.navigate(Routes.SendTip, {slug: artist.slug})}
        text={{id: 'artist.tips.empty.action'}}
      />
    </View>
  );
};

export default TipsEmptyState;
