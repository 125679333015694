import {
  IBox,
  IColors,
  IFonts,
  ITheme,
  IBackground,
  PartialTheme,
} from '@/types/theme';

import {BoxPacks, DefaultBoxPack} from './box';
import {DefaultColorPack} from './colors';
import {DefaultFontPack, getFontPack} from './fonts';

export const makeTheme: (theme: PartialTheme) => ITheme = (
  theme: PartialTheme,
) => {
  let font = makeThemeFont(theme);
  let box = makeThemeBox(theme);
  let colors = makeThemeColors(theme);
  let background = makeThemeBackground(theme);

  return {
    box,
    colors,
    text: {
      sectionHeaderUppercase: false,
    },
    font,
    background,
  };
};

function makeThemeFont(theme: PartialTheme): IFonts {
  let basicFont = {};
  if (theme.basic?.font) {
    basicFont = getFontPack(theme.basic.font);
  }

  return {
    ...DefaultFontPack,
    ...basicFont,
    ...theme?.font,
  };
}

function makeThemeBox(theme: PartialTheme): IBox {
  const basicBox = theme.basic?.box ? BoxPacks[theme.basic.box] : {};

  return {
    ...DefaultBoxPack,
    ...basicBox,
    ...theme?.box,
  };
}

function makeThemeColors(theme: PartialTheme): IColors {
  const allBasicColors = {
    primaryLight: theme.basic?.text,
    primary: theme.basic?.text,
    primaryDark: theme.basic?.text,

    background: theme.basic?.main,
    backgroundLight: theme.basic?.background,
    backgroundDark: theme.basic?.main,

    backdrop: theme?.colors?.backdrop,

    borderColor: theme.basic?.text,
    invertedBorderColor: theme.basic?.background,

    textColor: theme.basic?.text,
    invertedTextColor: theme.basic?.background,

    active: theme.basic?.active,
    activeBorder: theme.basic?.text,
    activeText: theme.basic?.text,

    favoritesColor: theme.basic?.favorites || theme.basic?.text,
  };

  // Remove potentially undefined entries from basicColors
  const basicColors = Object.fromEntries(
    Object.entries(allBasicColors).filter(([_, v]) => v !== undefined),
  );

  return {
    ...DefaultColorPack,
    ...basicColors,
    ...theme?.colors,
  };
}

function makeThemeBackground(theme: PartialTheme): IBackground {
  let basicBackground = {};

  return {
    ...basicBackground,
    ...theme?.background,
  };
}
