import {useInfiniteQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {
  fetchAllNftActivity,
  fetchNftActivityForArtist,
  fetchNftActivityForCollector,
  fetchNftActivityForTrack,
} from '@/api/nftActivity';
import {CACHE_CONFIG} from '@/constants/cacheConfig';
import {NftEventType} from '@/types/nfts';
import {QueryKeys} from '@/types/queryKeys';
import {flattenItems, getNextPageParam} from '@/utils/pagination';

export const useTrackNftActivityQuery = (
  trackId?: string,
  type?: NftEventType,
) => {
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.nftActivityForTrack, trackId, type],
    queryFn: ({pageParam}) =>
      fetchNftActivityForTrack(trackId!, pageParam, type),
    enabled: !!trackId,
    gcTime: CACHE_CONFIG.nftEventsCacheTime,
    initialPageParam: undefined,
    getNextPageParam,
  });
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};

export const useArtistNftActivityQuery = (
  artistId?: string,
  type?: NftEventType,
) => {
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.nftActivityForArtist, artistId, type],
    queryFn: ({pageParam}) =>
      fetchNftActivityForArtist(artistId!, pageParam, type),
    enabled: !!artistId,
    gcTime: CACHE_CONFIG.nftEventsCacheTime,
    initialPageParam: undefined,
    getNextPageParam,
  });
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};

export const useCollectorNftActivityQuery = (
  userId?: string,
  type?: NftEventType,
) => {
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.nftActivityForCollector, userId, type],
    queryFn: ({pageParam}) =>
      fetchNftActivityForCollector(userId!, pageParam, type),
    enabled: !!userId,
    gcTime: CACHE_CONFIG.nftEventsCacheTime,
    initialPageParam: undefined,
    getNextPageParam,
  });
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};

export const useAllActivityQuery = (
  type?: NftEventType,
  enabled: boolean = true,
) => {
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.allNFTActivity, type],
    queryFn: ({pageParam}) => fetchAllNftActivity(pageParam, type),
    enabled: enabled,
    refetchInterval: 60000,
    gcTime: CACHE_CONFIG.nftEventsCacheTime,
    initialPageParam: undefined,
    getNextPageParam,
  });
  const nftEvents = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    nftEvents,
    query,
  };
};
