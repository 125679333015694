import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import Background from '@/components/Background/Background';
import {LAYOUT_MAIN_ELEMENT_ID} from '@/components/Layout/constants';
import {DesktopPlayerThemed} from '@/components/Layout/DesktopPlayer';
import PromotionBanner from '@/components/PromotionBanner';
import WebMenu from '@/components/WebMenu';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';
import {useTheme} from '@/theme';

import {styles} from './Layout.style';

const Layout: FC<{children: ReactNode}> = ({children}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const {isMobile} = useResponsive();

  if (isMobile) {
    return (
      <>
        <PromotionBanner />
        {children}
      </>
    );
  }

  return (
    <View style={style.root}>
      {theme.background?.image && <Background />}
      <WebMenu />
      <View style={style.content}>
        <View style={style.opaqueOverlay} />
        <div
          id={LAYOUT_MAIN_ELEMENT_ID}
          style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
          {children}
        </div>
      </View>

      <View style={style.playerContainer}>
        <DesktopPlayerThemed />
      </View>
    </View>
  );
};

export default Layout;
