import React, {useMemo} from 'react';

import {useIsFollowedPlaylist} from '@/hooks/useIsFollowedPlaylist';
import {useDeletePlaylist, useCreatePlaylist} from '@/queries/ownedPlaylists';
import {PlaylistType} from '@/types/playlists';

import ArtistCard, {IArtistCardProps} from './ArtistCard';

const FollowableArtistCard = ({
  artist,
  actions,
  ...props
}: IArtistCardProps) => {
  const {deletePlaylistMutation} = useDeletePlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();

  const {isFollowed, unfollowId} = useIsFollowedPlaylist('artistId', artist.id);

  const toggleFollow = () => {
    if (unfollowId) {
      deletePlaylistMutation(unfollowId);
    } else if (artist) {
      createPlaylistMutation({
        title: artist.name,
        type: PlaylistType.artist,
        artistId: artist.id,
      });
    }
  };

  const actionsWithFollow = useMemo(
    () =>
      (actions ?? []).concat({
        onPress: toggleFollow,
        icon: {
          name: 'heart',
          provider: isFollowed ? 'fontAwesome' : 'simple',
          color: isFollowed ? 'favoritesColor' : 'textColor',
          size: 14,
        },
      }),
    [actions, toggleFollow, isFollowed],
  );

  return <ArtistCard artist={artist} {...props} actions={actionsWithFollow} />;
};

export default FollowableArtistCard;
