import {useRive} from '@rive-app/react-canvas';
import React, {FC} from 'react';
import {View} from 'react-native';

import {IRiveProps} from '@/components/Rive/types';

const Rive: FC<IRiveProps> = ({name, size, style}) => {
  const {RiveComponent} = useRive({
    src: `/rive/${name}.riv`,
    autoplay: true,
  });

  return (
    <View style={[style, {width: size, height: size}]}>
      <RiveComponent style={{width: size, height: size}} />
    </View>
  );
};

export default Rive;
