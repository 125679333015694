import axios from 'axios';

import {config} from '@/constants/config';

export const spinampServicesApi = axios.create({
  baseURL: config.SPINAMP_SERVICES_API_URL,
});

spinampServicesApi.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error.response),
);
