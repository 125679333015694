import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AddressInfo from '@/components/AddressInfo';
import {FadeInOut} from '@/components/AnimationWrappers';
import Divider from '@/components/Divider';
import ShadowCard from '@/components/ShadowCard';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {tipChain} from '@/modules/Tip/constants';
import {IPendingBalances} from '@/modules/Tip/types';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './PendingTipCard.style';

interface IProps {
  wallet: IAddress;
  balances: IPendingBalances | undefined;
}

const PendingTipCard: FC<IProps> = ({wallet, balances}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <ShadowCard key={wallet.address} style={style.balanceCard}>
      <View style={style.balanceInfo}>
        <AddressInfo address={wallet} />
        <View style={style.pendingColumn}>
          <Text id="tipsSettings.pending" size="xs" align="right" />
          <FadeInOut enabled={!balances}>
            <Text weight="semibold">
              {formatPrice(
                balances?.[wallet.address] || '0',
                tipChain.chain.nativeCurrency,
              )}
            </Text>
          </FadeInOut>
        </View>
      </View>

      <Divider style={style.divider} />
      <View style={style.balanceCardActions}>
        <TransparentButton
          onPress={() =>
            navigation.navigate(Routes.VerifyTipAddress, {
              address: wallet.address,
            })
          }
          text={{id: 'tipsSettings.verify'}}
        />
      </View>
    </ShadowCard>
  );
};

export default PendingTipCard;
