interface IAddressConfig {
  name: string;
  avatarUrl?: string;
}

export const specialAddresses: {[address: string]: IAddressConfig} = {
  '0xe468ce99444174bd3bbbed09209577d25d1ad673': {
    name: 'Zora Auction House',
    avatarUrl:
      'https://firebasestorage.googleapis.com/v0/b/spinamp-prod.appspot.com/o/zora.png?alt=media&token=02a817bb-106f-417f-897b-f2b387f3b3b2',
  },
};
