import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useThemedStyles} from '@/theme';
import {IArtist} from '@/types/common';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './ArtistTile.style';

interface IProps {
  artist: IArtist;
}

const ArtistTile: FC<IProps> = ({artist}) => {
  const style = useThemedStyles(styles);

  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <TouchableCard
      style={style.card}
      onPress={() =>
        navigation.navigate(Routes.MainNavigation, {
          screen: Routes.Artist,
          params: {slugOrId: artist.slug},
        })
      }>
      {({actionColor}) => (
        <>
          <StaticImage
            source={{uri: artist.avatarUrl}}
            resizeWidth={ImageSize.artist}
            style={style.artworkContainer}
            imageStyle={style.artwork}
          />
          <View style={style.info}>
            <Text weight="semibold" numberOfLines={1} color={actionColor()}>
              {artist.name}
            </Text>
          </View>
        </>
      )}
    </TouchableCard>
  );
};

export default React.memo(ArtistTile);
