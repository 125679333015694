import {useContext} from 'react';

import {GlobalToastContext} from '@/modules/Toasts/GlobalToastContext';

export const useToast = () => {
  const toastContext = useContext(GlobalToastContext);

  if (!toastContext) {
    throw new Error('useContext hook can only be called inside ToastProvider');
  }

  return toastContext;
};
