import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useAppSelector} from '@/hooks/useRedux';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {selectCardsTheming} from '@/store/theme';
import {LocalThemeProvider, useThemedStyles} from '@/theme';
import {IArtist} from '@/types/common';
import {ImageSize} from '@/types/media';
import {MainStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './ArtistCard.style';

interface IAction {
  onPress: () => void;
  icon: IIconProps;
}

export interface IArtistCardProps {
  artist: IArtist;
  onPress?: () => void;
  actions?: IAction[];
}

const ArtistCard: FC<IArtistCardProps> = ({artist, onPress, actions}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();

  const {name, slug} = artist;

  return (
    <TouchableCard
      style={style.card}
      onPress={() => {
        navigation.push(Routes.Artist, {
          slugOrId: slug,
        });
        onPress?.();
      }}>
      {({actionColor}) => {
        const defaultAvatar = (
          <Icon
            name="userMusic"
            provider="custom"
            size={24}
            color={actionColor()}
          />
        );

        return (
          <View style={style.container}>
            <View style={style.imageContainer}>
              {artist.avatarUrl ? (
                <StaticImage
                  style={style.imageWrapper}
                  source={{uri: artist.avatarUrl}}
                  resizeWidth={ImageSize.thumbnail}
                  fallback={defaultAvatar}
                />
              ) : (
                <>{defaultAvatar}</>
              )}
            </View>
            <View style={style.content}>
              <Text weight="semibold" numberOfLines={1} color={actionColor()}>
                {name}
              </Text>
            </View>
            {(actions ?? []).map((action, index) => (
              <TouchableOpacity
                key={index}
                onPress={action.onPress}
                style={style.action}>
                <Icon {...action.icon} color={actionColor()} />
              </TouchableOpacity>
            ))}
          </View>
        );
      }}
    </TouchableCard>
  );
};

const ArtistCardThemed: FC<IArtistCardProps> = props => {
  const cardsThemingEnabled = useAppSelector(selectCardsTheming);
  const {artistThemes} = useCustomThemesConfigQuery();
  const {artist} = props;

  if (cardsThemingEnabled) {
    return (
      <LocalThemeProvider
        customTheme={artist.customTheme}
        predefinedThemeName={
          artist.predefinedThemeName || artistThemes[artist.id]
        }>
        <ArtistCard {...props} />
      </LocalThemeProvider>
    );
  }

  return <ArtistCard {...props} />;
};

export default React.memo(ArtistCardThemed);
