import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  tile: {
    padding: spacing.m,
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
    alignItems: 'center',
    gap: spacing.m,
  },
  closeButton: {
    position: 'absolute',
    top: spacing.xs,
    right: spacing.xs,
  },
}));
