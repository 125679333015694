import {SpinConfig} from '@/constants/config/types';

import {staticConfig} from './static';

export const config: SpinConfig = {
  ...staticConfig,
  ENV: process.env.ENV!,
  SPINAMP_BACKEND_URL: process.env.SPINAMP_BACKEND_URL!,
  SPINAMP_PIPELINE_URL: process.env.SPINAMP_PIPELINE_URL!,
  SPINAMP_SERVICES_API_URL: process.env.SPINAMP_SERVICES_API_URL!,
  MIXPANEL_KEY: process.env.MIXPANEL_KEY!,
  SENTRY_DSN: process.env.SENTRY_DSN!,
  ALGOLIA_API_KEY: process.env.ALGOLIA_API_KEY!,
  ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID!,
  RAMP_API_KEY: process.env.RAMP_API_KEY!,
  RAMP_API_URL: process.env.RAMP_API_URL!,
  RAMP_TX_URL: process.env.RAMP_TX_URL!,
};
