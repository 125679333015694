import React, {FC, ReactNode} from 'react';
import {StyleProp, View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './ShadowFooter.style';

interface IProps {
  children: ReactNode;
  style?: StyleProp<any>;
}

const ShadowFooter: FC<IProps> = ({children, style: customStyle}) => {
  const style = useThemedStyles(styles);

  return <View style={[style.container, customStyle]}>{children}</View>;
};

export default ShadowFooter;
