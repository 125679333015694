import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import IconButton from '@/components/IconButton';
import {useThemedStyles} from '@/theme';

import {styles} from './OnboardingTile.style';

interface IProps {
  children: ReactNode;
  onClose?: () => void;
}

const OnboardingTile: FC<IProps> = ({children, onClose}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.tile}>
      {onClose && (
        <IconButton
          onPress={onClose}
          style={style.closeButton}
          icon={{name: 'close', provider: 'custom'}}
        />
      )}
      {children}
    </View>
  );
};

export default OnboardingTile;
