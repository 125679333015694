import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  header: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.borderColor,
    borderBottomWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    height: spacing.xl,
  },
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundLight,
    padding: spacing.s,
  },
  titleText: {
    maxWidth: '100%',
  },
}));
