import React, {FC, MutableRefObject, ReactNode, useRef} from 'react';
import {
  ControllerRenderProps,
  FieldValues,
  FormProvider,
  useController,
  UseControllerProps,
  UseFormReturn,
} from 'react-hook-form';
import {ScrollView, StyleProp, View, ViewProps} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import DropDown, {IDropDownProps} from '@/components/DropDown';
import IconButton from '@/components/IconButton';
import Input, {IInputProps} from '@/components/Input';
import PriceInput, {IPriceInputProps} from '@/components/PriceInput';
import RichTextEditor from '@/components/RichTextEditor';
import {IRichTextEditorProps} from '@/components/RichTextEditor/types';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text';
import spacing from '@/constants/spacing';
import {identity} from '@/utils/functions';

export const Form = <T extends FieldValues>(props: {
  children: ReactNode;
  form: UseFormReturn<T>;
}) => {
  const {children, form} = props;

  return <FormProvider {...form}>{children}</FormProvider>;
};

const FormInput = <T extends FieldValues>(
  props: UseControllerProps<T> &
    Omit<IInputProps, 'onChangeText' | 'value' | 'onBlur'> & {
      parseInput?: (value: string, previousValue?: string) => string;
    },
) => {
  const {field} = useController(props);
  const {value, onChange, onBlur} = field;
  const {parseInput = identity} = props;

  const onChangeText = (v: string) => {
    onChange(parseInput(v, value));
  };

  return (
    <Input
      ref={field.ref}
      onChangeText={onChangeText}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
};

const FormPriceInput = <T extends FieldValues>(
  props: UseControllerProps<T> &
    Omit<IPriceInputProps, 'onChange' | 'value' | 'onBlur'>,
) => {
  const {field} = useController(props);
  const {value, onChange, onBlur} = field;

  return (
    <PriceInput
      ref={field.ref}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      {...props}
    />
  );
};

const FormRichTextEditor = <T extends FieldValues>(
  props: UseControllerProps<T> &
    Omit<IRichTextEditorProps, 'initialHtml' | 'onHtmlChange'>,
) => {
  const {field} = useController(props);
  const {value, onChange} = field;

  return (
    <View ref={field.ref}>
      <RichTextEditor onHtmlChange={onChange} initialHtml={value} {...props} />
    </View>
  );
};

const FormDropDown = <T extends FieldValues>(
  props: UseControllerProps<T> &
    Omit<IDropDownProps, 'value' | 'onChange'> & {
      scrollView?: MutableRefObject<ScrollView | null>;
    },
) => {
  const {field} = useController(props);
  const {value, onChange} = field;

  const dropdown = <DropDown onChange={onChange} value={value} {...props} />;

  if (props.scrollView) {
    return (
      <FocusableView
        field={field}
        scrollView={props.scrollView}
        offset={spacing.s * 2}
        style={{zIndex: 1}}>
        {dropdown}
      </FocusableView>
    );
  }

  return dropdown;
};

const FormError: FC<{name: string; defaultMessageId?: string} & TextProps> = ({
  name,
  defaultMessageId,
  ...textProps
}) => {
  const {fieldState} = useController({name});
  const {error} = fieldState;
  const messageId = error?.message || defaultMessageId;

  if (!error || !messageId) {
    return null;
  }

  return (
    <View>
      <Animated.View
        entering={FadeIn}
        exiting={FadeOut}
        style={{position: 'absolute', left: 0, right: 0, top: spacing.xxs}}>
        <Text size="xxs" numberOfLines={1} id={messageId} {...textProps} />
      </Animated.View>
    </View>
  );
};

const Field: FC<{
  label: TextProps;
  description?: TextProps;
  children?: ReactNode;
  containerStyle?: StyleProp<any>;
  onRemove?: () => void;
  icon?: ReactNode;
}> = ({label, description, children, containerStyle, onRemove, icon}) => {
  return (
    <Space fw style={[{marginBottom: spacing.s * 2}, containerStyle]}>
      <View
        style={{gap: spacing.xxs, flexDirection: 'row', alignItems: 'center'}}>
        <Text size="xs" weight="semibold" {...label} />
        {icon}
      </View>
      <Space h="xxs" />

      {description && <Text size="xs" {...description} />}

      {children && (
        <View
          style={{
            marginTop: description ? spacing.s : 0,
            flexDirection: 'row',
            alignItems: 'center',
            gap: spacing.xs,
          }}>
          <Space flex>{children}</Space>
          {onRemove && (
            <IconButton
              onPress={onRemove}
              icon={{name: 'remove', provider: 'custom'}}
            />
          )}
        </View>
      )}
    </Space>
  );
};

const Divider = () => <Space h="l" />;

const Header: FC<{header: TextProps; description?: TextProps}> = ({
  header,
  description,
}) => (
  <Space mb="s">
    <Text size="m" weight="semibold" {...header} />
    {description && (
      <Space mt="xs">
        <Text size="xs" {...description} />
      </Space>
    )}
  </Space>
);

// react-hook-form calls "focus" method on reference to field which has validation error on submit.
// For components like Input it works out of the box, but for custom components like ImagePicker we need to cheat like here.
const FocusableView: FC<
  ViewProps & {
    field: ControllerRenderProps<any, any>;
    scrollView: MutableRefObject<ScrollView | null>;
    offset?: number;
  }
> = ({field, scrollView, offset = spacing.xs, ...props}) => {
  const ref = useRef<View | null>(null);

  field.ref({
    focus: () => {
      if (ref.current && scrollView.current) {
        ref.current.measureLayout(
          scrollView.current.getInnerViewNode(),
          (left, top) => {
            scrollView.current?.scrollTo({y: top - offset});
          },
        );
      }
    },
  });

  return <View ref={ref} {...props} />;
};

Form.Input = FormInput;
Form.PriceInput = FormPriceInput;
Form.DropDown = FormDropDown;
Form.RichTextEditor = FormRichTextEditor;
Form.Error = FormError;
Form.Field = Field;
Form.Divider = Divider;
Form.Header = Header;
Form.FocusableView = FocusableView;
