import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const SCREEN_PADDING = spacing.s;

export const styles = createThemedStyles(theme => ({
  scrollContent: {
    padding: SCREEN_PADDING,
    paddingTop: spacing.xxs,
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  textCentered: {
    textAlign: 'center',
  },
  actions: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  actionButton: {
    minWidth: 120,
  },
}));
