import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    gap: spacing.xs,
    alignItems: 'center',
  },
  divider: {
    height: 1,
    backgroundColor: theme.colors.backgroundDark,
    flex: 1,
  },
}));
