import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    flex: 1,
    paddingVertical: spacing.xl * 2,
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.l,
  },
}));
