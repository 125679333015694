import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    padding: spacing.m,
    alignItems: 'center',
    gap: spacing.l,
  },
  balance: {
    gap: spacing.xs,
  },
  image: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    width: 150,
    height: 150,
  },
  amountRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
}));
