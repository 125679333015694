import {useEffect} from 'react';
import {Dimensions} from 'react-native';
import {Gesture} from 'react-native-gesture-handler';
import {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

const SCREEN_HEIGHT = Dimensions.get('window').height;
const SWIPE_GESTURE_THRESHOLD = 10;

interface IOptions {
  isOpen: boolean;
  enabled: boolean;
  onClose: () => void;
}

export const useSwipeToClose = ({isOpen, enabled, onClose}: IOptions) => {
  const swipePosition = useSharedValue(0);
  const swipeDirection = useSharedValue<'down' | 'up'>('down');

  useEffect(() => {
    if (isOpen) {
      swipePosition.value = 0;
    }
  }, [isOpen]);

  const swipeGesture = Gesture.Pan()
    .enabled(enabled)
    .onUpdate(e => {
      swipeDirection.value =
        e.translationY >= swipePosition.value ? 'down' : 'up';
      swipePosition.value = e.translationY;
    })
    .onEnd(() => {
      if (
        swipePosition.value > SWIPE_GESTURE_THRESHOLD &&
        swipeDirection.value === 'down'
      ) {
        swipePosition.value = withTiming(SCREEN_HEIGHT, {duration: 200}, () => {
          runOnJS(onClose)();
        });
      } else if (
        swipePosition.value < -SWIPE_GESTURE_THRESHOLD &&
        swipeDirection.value === 'up'
      ) {
        swipePosition.value = withTiming(
          -SCREEN_HEIGHT,
          {duration: 200},
          () => {
            runOnJS(onClose)();
          },
        );
      } else {
        swipePosition.value = withTiming(0, {duration: 100});
      }
    });

  const swipeStyles = useAnimatedStyle(() => ({
    transform: [{translateY: swipePosition.value}],
  }));

  return {
    swipeGesture,
    swipePosition,
    swipeStyles,
  };
};
