import React, {FC} from 'react';

import PendingTip from '@/modules/Tip/SendTip/components/PendingTip';
import SuccessfulTip from '@/modules/Tip/SendTip/components/SuccessfulTip';
import TipCheckout from '@/modules/Tip/SendTip/components/TipCheckout';
import TipErrorInfo from '@/modules/Tip/SendTip/components/TipErrorInfo';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';

const TipRouter: FC = () => {
  const {tipTransaction} = useSendTipState();
  const {error, step} = tipTransaction;

  if (error) {
    return <TipErrorInfo />;
  }

  if (step === 'success') {
    return <SuccessfulTip />;
  }

  if (step === 'executingTransaction' || step === 'waitingForReceipt') {
    return <PendingTip />;
  }

  return <TipCheckout />;
};

export default TipRouter;
