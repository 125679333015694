import React, {FC} from 'react';

import CardButton from '@/components/CardButton';
import ScrollContainer from '@/components/ScrollContainer';
import Space from '@/components/Space';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {tipChain} from '@/modules/Tip/constants';
import TipAvatars from '@/modules/Tip/SendTip/components/TipAvatars';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';
import {
  EtherscanLink,
  JiffyscanLink,
  TransactionError,
} from '@/modules/Transactions';
import {PasskeyError} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {isOSXBrowser} from '@/utils/platform';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './TipErrorInfo.style';

const TipErrorInfo: FC = () => {
  const style = useThemedStyles(styles);

  const {tipTransaction, resetTransactionDetails, artist, user, close} =
    useSendTipState();
  const {error, txHash, userOpHash} = tipTransaction;

  const seeDetailsAction = txHash ? (
    <Space mt="xs">
      <EtherscanLink txHash={txHash} chainId={tipChain.id} />
    </Space>
  ) : userOpHash ? (
    <Space mt="xs">
      <JiffyscanLink userOpHash={userOpHash} chainId={tipChain.id} />
    </Space>
  ) : null;

  return (
    <ScrollContainer contentStyle={style.content}>
      <TipAvatars user={user} artist={artist} />

      {(() => {
        if (error instanceof PasskeyError && error.status === 'MISSING_BLOB') {
          return (
            <WarningBox
              title={{id: 'collect.error.missingBlob'}}
              message={{
                id: isOSXBrowser
                  ? 'collect.error.missingBlob.osx'
                  : 'collect.error.missingBlob.default',
              }}
            />
          );
        }

        if (
          error instanceof TransactionError &&
          error.status === 'WRONG_PASSKEY_SIGNER'
        ) {
          return (
            <WarningBox
              title={{id: 'collect.error.wrongSigner'}}
              message={
                user && {
                  id: 'collect.error.wrongSigner.message',
                  values: {
                    user: getUserDisplayName(user),
                  },
                }
              }
            />
          );
        }

        if (
          error instanceof TransactionError &&
          error.status === 'TRANSACTION_REVERTED'
        ) {
          return (
            <WarningBox
              title={{id: 'tip.error.reverted'}}
              action={seeDetailsAction}
            />
          );
        }

        return (
          <WarningBox
            title={{id: 'tip.error.generic'}}
            message={{id: 'tip.error.generic.message'}}
            action={seeDetailsAction}
          />
        );
      })()}

      <CardButton onPress={resetTransactionDetails} text={{id: 'tryAgain'}} />
      <TransparentButton
        style={style.secondaryAction}
        onPress={close}
        text={{id: 'cancel'}}
      />
    </ScrollContainer>
  );
};

export default TipErrorInfo;
