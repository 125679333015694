import {useEffect, useMemo, useState} from 'react';

import {IGlobalToast} from '@/modules/Toasts/GlobalToastContext';

const TOAST_TIMEOUT = 4000;

export const useGlobalToastsConfig = () => {
  const [globalToast, setGlobalToast] = useState<IGlobalToast | null>(null);

  const globalToastActions = useMemo(
    () => ({
      showToast: (newToast: IGlobalToast) => {
        setGlobalToast(newToast);
      },
      hideToast: () => {
        setGlobalToast(null);
      },
    }),
    [],
  );

  useEffect(() => {
    if (globalToast) {
      const timeoutId = setTimeout(() => {
        globalToastActions.hideToast();
      }, TOAST_TIMEOUT);

      return () => clearTimeout(timeoutId);
    }
  }, [globalToast]);

  return {
    globalToast,
    globalToastActions,
  };
};
