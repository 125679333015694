import React, {FC} from 'react';
import {useForm} from 'react-hook-form';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import {Form} from '@/components/Form';
import Modal, {IModalProps} from '@/components/Modal';
import {useThemedStyles} from '@/theme';
import {isAddressValid} from '@/utils/ethereum';

import {styles} from './AddressInputModal.style';

interface IProps extends IModalProps {
  onSubmit: (address: string) => void;
}

const AddressInputModal: FC<IProps> = ({onSubmit, ...modalProps}) => {
  const style = useThemedStyles(styles);

  const form = useForm({
    mode: 'onTouched',
    defaultValues: {
      address: '',
    },
  });

  return (
    <Modal {...modalProps} titleId="addressForm.title" avoidKeyboard>
      <Form form={form}>
        <View style={style.content}>
          <Form.Header
            header={{id: 'addressForm.header', size: 'xs', weight: 'regular'}}
          />
          <Form.Field label={{id: 'addressForm.address'}}>
            <Form.Input
              name="address"
              rules={{
                // @ts-ignore - that works properly, not sure why ts complains
                validate: isAddressValid,
              }}
              placeholder="0x00"
            />
            <Form.Error name="address" defaultMessageId="addressForm.error" />
          </Form.Field>
          <CardButton
            onPress={form.handleSubmit(data => {
              onSubmit(data.address);
              modalProps.onClose();
            })}
            text={{id: 'confirm'}}
          />
        </View>
      </Form>
    </Modal>
  );
};

export default AddressInputModal;
