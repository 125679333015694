import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = spacing.s * 4;
const CIRCLE_SIZE = AVATAR_SIZE + spacing.s;
const HEART_CIRCLE_SIZE = spacing.s * 2;

export const styles = createThemedStyles(theme => ({
  container: {
    height: CIRCLE_SIZE,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dashedCircle: {
    position: 'absolute',
    height: CIRCLE_SIZE,
    width: CIRCLE_SIZE,
    borderRadius: CIRCLE_SIZE / 2,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.borderColor,
  },
  userPlaceholder: {
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    backgroundColor: theme.colors.backgroundDark,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatarLeft: {
    left: AVATAR_SIZE / 8,
  },
  avatarRight: {
    right: AVATAR_SIZE / 8,
  },
  heartCircle: {
    position: 'absolute',
    height: HEART_CIRCLE_SIZE,
    width: HEART_CIRCLE_SIZE,
    borderRadius: HEART_CIRCLE_SIZE / 2,
    backgroundColor: theme.colors.backgroundLight,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
