import React, {useMemo} from 'react';

import CardButton from '@/components/CardButton';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {chainsById} from '@/constants/chains';
import {useTopUp} from '@/modules/TopUp/useTopUp';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {IAddress, IBalance} from '@/types/common';

interface IBalanceValidationInput {
  wallet: IAddress | undefined;
  balance: IBalance | undefined;
  price: bigint | undefined;
  chainId: number | undefined;
  onChangeWallet?: () => void;
}

export const useBalanceValidation = ({
  wallet,
  balance,
  price,
  chainId,
  onChangeWallet,
}: IBalanceValidationInput): ITransactionValidationError | undefined => {
  const {topUp} = useTopUp();

  const insufficientBalance = useMemo(() => {
    if (!balance || !price) {
      return false;
    }

    return balance.value < BigInt(price);
  }, [price, balance?.value]);

  if (!wallet || !chainId || !price) {
    return undefined;
  }

  if (insufficientBalance) {
    return {
      message: (
        <WarningBox
          title={{
            size: 'xs',
            id: 'transactions.insufficientBalance.title',
            values: {
              chain: chainsById[chainId].name,
            },
          }}
        />
      ),
      action: (
        <>
          <CardButton
            onPress={() =>
              topUp({
                address: wallet.address,
                chainId: chainId,
                amount: price,
              })
            }
            text={{id: 'transactions.insufficientBalance.topUp'}}
            icon={{name: 'creditCard', provider: 'custom'}}
          />
          {onChangeWallet && (
            <TransparentButton
              style={{alignSelf: 'center'}}
              onPress={onChangeWallet}
              text={{
                id: 'transactions.insufficientBalance.change',
              }}
            />
          )}
        </>
      ),
    };
  }

  return undefined;
};
