import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Dimensions, View} from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedScrollHandler,
  useSharedValue,
} from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {useSpinampWrappedQuery} from '@/queries/useSpinampWrappedQuery';
import {setupPages} from '@/screens/SpinampWrapped/utils';
import {selectSelectedUserId} from '@/store/user';
import {useTheme} from '@/theme';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';

import Layout from './components/Layout';
import WrappedPage from './components/WrappedPage';
import {DESKTOP_MODAL_WIDTH} from './constants';
import {SpinampWrappedContext} from './SpinampWrappedContext';

const SCREEN_WIDTH = Dimensions.get('window').width;

const SpinampWrapped = () => {
  const theme = useTheme();
  const {isMobile} = useResponsive();
  const navigation = useNavigation<RootStackNavigationParams>();

  const [page, setPage] = useState(0);

  const width = isMobile ? SCREEN_WIDTH : DESKTOP_MODAL_WIDTH;

  const scrollViewRef = useRef<Animated.ScrollView | null>(null);
  const scrollPosition = useSharedValue(0);
  const onScroll = useAnimatedScrollHandler(event => {
    scrollPosition.value = event.contentOffset.x;
    runOnJS(setPage)(Math.round(scrollPosition.value / width));
  });

  const {params} =
    useRoute<RouteProp<RootStackParams, Routes.SpinampWrapped>>();
  const activeUserId = useAppSelector(selectSelectedUserId);
  const userSlugOrId = params?.userSlugOrId || activeUserId;

  useEffect(() => {
    if (!userSlugOrId) {
      navigation.goBack();
    }
  }, [userSlugOrId]);

  const {
    user,
    tracks,
    artists,
    supportedArtists,
    totalNftsCollected,
    query: {isLoading, isError},
  } = useSpinampWrappedQuery(userSlugOrId);

  const context = useMemo(() => {
    const pages = setupPages(tracks, artists, supportedArtists);

    const close = () => {
      navigation.goBack();
    };

    const goToPage = (index: number) => {
      scrollViewRef.current?.scrollTo({x: index * width});
    };

    const nextPage = () => {
      if (page === pages.length - 1) {
        close();
      } else {
        goToPage(Math.min(page + 1, pages.length - 1));
      }
    };

    const prevPage = () => goToPage(Math.max(page - 1, 0));

    return {
      pages,
      page,
      close,
      goToPage,
      nextPage,
      prevPage,
      tracks,
      artists,
      totalNftsCollected,
      supportedArtists,
      activePageId: pages[page].id,
      user,
    };
  }, [page, tracks, artists, supportedArtists, totalNftsCollected, user]);

  if (!userSlugOrId) {
    return null;
  }

  return (
    <SpinampWrappedContext.Provider value={context}>
      <Layout>
        {isError ? (
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.colors.background,
            }}>
            <Text weight="semibold" size="m" id="spinampWrapped.error" />
            <Space h="m" />
            <CardButton
              onPress={close}
              text={{id: 'spinampWrapped.error.goBack'}}
            />
          </View>
        ) : isLoading ? (
          <View style={{flex: 1, backgroundColor: theme.colors.background}}>
            <ScreenLoader />
          </View>
        ) : (
          <Animated.ScrollView
            ref={scrollViewRef}
            horizontal
            bounces
            showsHorizontalScrollIndicator={false}
            pagingEnabled
            onScroll={onScroll}
            scrollEventThrottle={16}
            contentContainerStyle={{
              width: width * context.pages.length,
            }}>
            {context.pages.map((Page, index) => (
              <WrappedPage
                key={Page.id}
                index={index}
                width={width}
                scrollPosition={scrollPosition}>
                <Page.Component />
              </WrappedPage>
            ))}
          </Animated.ScrollView>
        )}
      </Layout>
    </SpinampWrappedContext.Provider>
  );
};

export default SpinampWrapped;
