import React, {FC} from 'react';
import Autolink from 'react-native-autolink';

import {useTheme} from '@/theme';

import Text, {TextProps} from './Text';

export interface IAutoLinkTextProps extends TextProps {
  children: string;
}

export const AutoLinkText: FC<IAutoLinkTextProps> = ({
  children,
  ...textProps
}) => {
  const theme = useTheme();

  return (
    <Text {...textProps}>
      <Autolink
        text={children}
        linkStyle={{
          color:
            textProps.customColor ||
            theme.colors[textProps.color || 'textColor'],
          textDecorationLine: 'underline',
        }}
      />
    </Text>
  );
};
