import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Animated, {
  interpolate,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

import Divider from '@/components/Divider';
import IconButton from '@/components/IconButton';
import PlaylistFormModal from '@/components/PlaylistFormModal';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useNewPlaylistModal} from '@/screens/Library/hooks/useNewPlaylistModal';
import {
  resetPlayer,
  selectIsPlaying,
  selectIsShuffle,
  togglePlay,
  toggleShuffle,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IQueueItemWithTrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';

import {styles, CONTENT_TOP_PADDING} from './QueueHeader.style';

interface IProps {
  tracks: IQueueItemWithTrack[];
  scrollToIndex: (index: number, animated: boolean) => void;
  scrollPosition: SharedValue<number>;
}

const QueueHeader: FC<IProps> = ({tracks, scrollToIndex, scrollPosition}) => {
  const style = useThemedStyles(styles);

  const navigation = useNavigation<RootStackNavigationParams>();
  const dispatch = useAppDispatch();

  const isPlaying = useAppSelector(selectIsPlaying);
  const isShuffle = useAppSelector(selectIsShuffle);

  const {isNewPlaylistModalOpen, toggleNewPlaylistModal, submitNewPlaylist} =
    useNewPlaylistModal();

  const headerDividerStyle = useAnimatedStyle(() => ({
    opacity: interpolate(
      scrollPosition.value,
      [1, CONTENT_TOP_PADDING],
      [0, 1],
    ),
  }));

  return (
    <>
      <Space style={style.header}>
        <Space style={style.headerContent}>
          <View style={style.headerRow}>
            <View style={style.headerButton}>
              <IconButton
                onPress={() => navigation.goBack()}
                icon={{provider: 'custom', name: 'arrowDown'}}
              />
            </View>
            <Space style={style.headerTitle}>
              <Text
                id="queue.header"
                weight="semibold"
                size="s"
                numberOfLines={1}
              />
            </Space>
            <View style={style.headerButtons}>
              <View style={style.headerButton}>
                <Tooltip
                  position="bottom-right"
                  hoverEnabled={false}
                  content={({close}) => (
                    <View>
                      <TouchableOpacity
                        style={style.popoverAction}
                        onPress={() => {
                          close();
                          toggleNewPlaylistModal();
                        }}>
                        <Text size="xs" id="queue.createPlaylist.title" />
                        <Text
                          size="xxs"
                          id="queue.createPlaylist.desc"
                          secondary
                        />
                      </TouchableOpacity>
                      <Divider />
                      <TouchableOpacity
                        style={style.popoverAction}
                        onPress={() => dispatch(resetPlayer())}>
                        <Text size="xs" id="queue.clear.title" />
                        <Text size="xxs" id="queue.clear.desc" secondary />
                      </TouchableOpacity>
                    </View>
                  )}>
                  {({open}) => (
                    <IconButton
                      onPress={open}
                      icon={{provider: 'custom', name: 'dotsMenu'}}
                    />
                  )}
                </Tooltip>
              </View>
              <View style={style.headerButton}>
                <IconButton
                  onPress={() => {
                    const nextIndex = dispatch(toggleShuffle());
                    scrollToIndex(nextIndex, true);
                  }}
                  icon={{provider: 'custom', name: 'shuffle'}}
                  selected={isShuffle}
                />
              </View>
              <View style={style.headerButton}>
                <IconButton
                  onPress={() => dispatch(togglePlay())}
                  icon={{
                    provider: 'custom',
                    name: isPlaying ? 'pause' : 'play',
                  }}
                />
              </View>
            </View>
          </View>
          <View style={[style.headerRow, style.headerSubtitle]}>
            <Text
              id="queue.songs"
              values={{
                number: tracks.length,
              }}
              size="xs"
              numberOfLines={1}
              adjustsFontSizeToFit
            />
          </View>
        </Space>

        {isNewPlaylistModalOpen && (
          <PlaylistFormModal
            isOpen
            createdByEnabled
            modalTitleId="addPlaylist.title"
            submitTextId="addPlaylist.add"
            onClose={toggleNewPlaylistModal}
            onSubmit={data => {
              submitNewPlaylist({
                ...data,
                trackIds: [...new Set(tracks.map(item => item.trackId))],
              });
            }}
          />
        )}
      </Space>
      <Animated.View style={[style.headerDivider, headerDividerStyle]} />
    </>
  );
};

export default QueueHeader;
