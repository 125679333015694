import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const UPLOAD_ICON_SIZE = 32;

export const styles = createThemedStyles(theme => ({
  container: {
    borderRadius: theme.box.borderRadius,
    paddingHorizontal: spacing.m,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
    backgroundColor: theme.colors.background,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container_upload: {
    flexDirection: 'row',
    gap: spacing.s * 2,
  },
  pressed: {
    opacity: 0.6,
  },
  hovered: {
    opacity: 0.8,
  },
  uploadingLabel: {
    top: spacing.xs,
    position: 'absolute',
  },
  uploadProgress: {
    backgroundColor: theme.colors.backgroundDark,
  },
  iconPlaceholder: {
    width: UPLOAD_ICON_SIZE,
  },
  uploadInfo: {
    gap: spacing.xxs,
    alignItems: 'center',
  },
  container_audio: {
    flexDirection: 'row',
    gap: spacing.xs,
  },
  audioTitle: {
    flex: 1,
  },
}));
