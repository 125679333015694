import React, {FC, ReactNode} from 'react';

import Button from '@/components/Button';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useResponsive} from '@/hooks/useResponsive';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';

const LibraryTitle: FC = () => {
  const {isDesktop} = useResponsive();
  const {selectedCategory} = useLibraryContext();

  if (!isDesktop || !selectedCategory) {
    return null;
  }

  return (
    <Space mt="xs" mb="s">
      <Text
        id={selectedCategory.titleId}
        weight="semibold"
        size="l"
        adjustsFontSizeToFit
      />
    </Space>
  );
};

const LibraryEmpty: FC<{
  children?: ReactNode;
  text?: string;
  textId?: string;
}> = ({text, textId, children}) => {
  const {isDesktop} = useResponsive();
  return (
    <Space ph="s" pv={isDesktop ? 's' : 'xxs'}>
      <Text align="center" id={textId}>
        {text}
      </Text>
      {children}
    </Space>
  );
};

export const LibraryError: FC<{
  onRetry: () => void;
}> = ({onRetry}) => (
  <Space pt="xxs" ph="s">
    <Text align="center" id="library.error" />
    <Space mt="s" />
    <Button textId="retry" onPress={onRetry} />
  </Space>
);

export const LibraryUI = {
  Title: LibraryTitle,
  Empty: LibraryEmpty,
  Error: LibraryError,
};
