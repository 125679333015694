import React, {ReactNode} from 'react';
import {IntlProvider} from 'react-intl';

import enMessages from '@/translations/en.json';

const Translation: React.FC<{children: ReactNode}> = ({children}) => {
  return (
    <IntlProvider locale="en" messages={enMessages}>
      {children}
    </IntlProvider>
  );
};

export default Translation;
