import React, {FC, ReactNode} from 'react';
import {FlatList, View} from 'react-native';

import Pill from '@/components/Pill';
import {ISearchCategoryConfig, SearchCategory} from '@/screens/Search/types';
import {useThemedStyles} from '@/theme';

import {styles} from './SearchCategories.style';

interface IProps {
  categories: ISearchCategoryConfig[];
  selectedCategory?: SearchCategory;
  setCategory: (category: SearchCategory) => void;
}

const CellRenderer: FC<{children: ReactNode}> = ({children}) => (
  <View style={{flexGrow: 1}}>{children}</View>
);

const SearchCategories: FC<IProps> = ({
  categories,
  selectedCategory,
  setCategory,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View>
      <FlatList
        horizontal
        showsHorizontalScrollIndicator={false}
        style={style.scrollContainer}
        contentContainerStyle={style.scrollContent}
        data={categories}
        keyExtractor={category => category.id}
        CellRendererComponent={CellRenderer}
        renderItem={({item: category}) => (
          <Pill
            onPress={() => setCategory(category.id)}
            isSelected={category.id === selectedCategory}
            text={{id: category.textId}}
          />
        )}
      />
    </View>
  );
};

export default SearchCategories;
