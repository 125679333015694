import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    backgroundColor: theme.colors.primary,
  },
  content: {
    backgroundColor: theme.colors.backgroundLight,
  },
}));
