export const parseDecimalInput = (value: string, previousValue = '') => {
  if (value === '') {
    return value;
  }

  const numericRegex = /^[0-9]*\.?[0-9]*$/;
  let parsedValue = value.replaceAll(',', '.');

  if (numericRegex.test(parsedValue)) {
    if (
      parsedValue.startsWith('0') &&
      parsedValue.length > 1 &&
      !parsedValue.startsWith('0.')
    ) {
      parsedValue = parsedValue.replace(/^0+/, '');
    }

    return parsedValue;
  }

  return previousValue.toString();
};
