import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  card: {
    overflow: 'hidden',
    padding: 0,
  },
  artworkContainer: {
    aspectRatio: 1,
  },
  artwork: {
    ...Platform.select({
      ios: {
        borderTopRightRadius: Math.max(0, theme.box.borderRadius - 1),
        borderTopLeftRadius: Math.max(0, theme.box.borderRadius - 1),
      },
    }),
  },
  info: {
    padding: spacing.xs,
  },
}));
