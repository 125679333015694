import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Animated, {useAnimatedStyle, withTiming} from 'react-native-reanimated';

import Icon from '@/components/Icon';
import spacing, {HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles, BORDER_WIDTH} from './Checkbox.style';

interface IProps {
  value: boolean;
  setValue?: (value: boolean) => void;
  size?: number;
}

const Checkbox: FC<IProps> = ({value, setValue, size = spacing.l}) => {
  const style = useThemedStyles(styles);

  const borderLessSize = size - BORDER_WIDTH;

  const checkboxStyle = useAnimatedStyle(() => ({
    opacity: withTiming(value ? 1 : 0, {duration: 150}),
  }));

  const containerStyle = [
    style.container,
    {
      width: borderLessSize,
      height: borderLessSize,
      borderRadius: borderLessSize / 2,
    },
  ];

  const content = (
    <Animated.View style={checkboxStyle}>
      <Icon name="checked" provider="custom" size={size} />
    </Animated.View>
  );

  if (setValue) {
    return (
      <TouchableOpacity
        style={containerStyle}
        onPress={() => setValue(!value)}
        activeOpacity={0.8}
        hitSlop={HIT_SLOP}>
        {content}
      </TouchableOpacity>
    );
  }

  return <View style={containerStyle}>{content}</View>;
};

export default Checkbox;
