import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    flex: 1,
  },
  playerCard: {
    flex: 1,
    borderWidth: theme.box.baseBorderWidth,
    borderRightWidth: theme.box.borderRightWidth,
    borderBottomWidth: theme.box.borderBottomWidth,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    overflow: 'hidden',
  },
  opaqueOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.opaqueOverlay,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
  },
  headerSide: {
    flex: 1,
    flexDirection: 'column',
  },
  headerLineTop: {
    height: 2,
    backgroundColor: theme.colors.invertedTextColor,
    paddingHorizontal: 15,
    marginHorizontal: 15,
  },
  headerLineBottomLeft: {
    height: 2,
    backgroundColor: theme.colors.invertedTextColor,
    marginHorizontal: 10,
    paddingRight: 15,
    marginRight: 20,
  },
  headerLineBottomRight: {
    height: 2,
    backgroundColor: theme.colors.invertedTextColor,
    marginHorizontal: 10,
    paddingRight: 15,
    marginLeft: 20,
  },
  headerLineSeparator: {
    height: 3,
  },
}));
