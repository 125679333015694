import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    gap: spacing.l,
    padding: spacing.s,
  },
  action: {
    alignSelf: 'center',
  },
}));
