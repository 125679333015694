import {useLayout} from '@/hooks/useLayout';
import {isWeb} from '@/utils/platform';

export const useCenteredHeader = () => {
  const {layout: leftActionsLayout, onLayout: onLeftActionsLayout} =
    useLayout(isWeb);
  const {layout: rightActionsLayout, onLayout: onRightActionsLayout} =
    useLayout(isWeb);

  const leftActionsWidth = leftActionsLayout?.width || 0;
  const rightActionsWidth = rightActionsLayout?.width || 0;
  const actionWidth = Math.max(leftActionsWidth, rightActionsWidth);

  return {
    // Title container in header is positioned absolutely, to always be in the center.
    // We need to measure width of the bigger action container to set proper left and right properties for the title.
    actionWidth,
    onLeftActionsLayout,
    onRightActionsLayout,
  };
};
