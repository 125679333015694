import {useRef, useState} from 'react';

import {convertToHex, convertToHexSafe} from '@/utils/colors';

export interface IColorPickerProps {
  color: string;
  // setColor callback should have static reference (useCallback/useMemo/ref) to make throttling work properly
  setColor: (newColor: string) => void;
  reset?: () => void;
}

export const useHexInput = ({
  color,
  setColor,
}: Omit<IColorPickerProps, 'reset'>) => {
  const [inputColor, setInputColor] = useState(convertToHexSafe(color) || '');
  const isInputFocused = useRef(false);

  const onHexInputChange = (input: string) => {
    setInputColor(input);

    try {
      setColor(convertToHex(input));
    } catch (e) {
      // ignore
    }
  };

  const onHexInputBlur = () => {
    try {
      const hex = convertToHex(inputColor);
      setColor(hex);
      setInputColor(hex);
    } catch (error) {
      setInputColor(color);
    }
    isInputFocused.current = false;
  };

  const onHexInputFocus = () => (isInputFocused.current = true);

  return {
    inputColor,
    setInputColor,
    isInputFocused: isInputFocused.current,
    onHexInputChange,
    onHexInputBlur,
    onHexInputFocus,
  };
};
