import {useCallback, useEffect, useRef, useState} from 'react';
import {debounce} from 'throttle-debounce';

import {useRecyclingHandler} from '@/hooks/useRecyclingHandler';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {initAdditionalAudio} from '@/services/additionalAudio';
import {IAdditionalAudio} from '@/services/additionalAudio/types';
import {selectShouldPlay as selectFeedShouldPlay} from '@/store/feed';
import {
  pause as pauseMainPlayer,
  setIsActive as setMainPlayerIsActive,
} from '@/store/player';
import {ITrack} from '@/types/common';
import {analytics} from '@/utils/analytics';

import {useIsFeedFocused} from './useIsFeedFocused';

export const useMainPlayerWithFeed = () => {
  const dispatch = useAppDispatch();

  const isFocused = useIsFeedFocused();
  const feedShouldPlay = useAppSelector(selectFeedShouldPlay);
  useEffect(() => {
    if (isFocused && feedShouldPlay) {
      dispatch(setMainPlayerIsActive(false));
      return () => {
        dispatch(setMainPlayerIsActive(true));
      };
    }
  }, [isFocused, feedShouldPlay]);
};

export const useFeedAudio = (
  track: ITrack | undefined,
  isActive: boolean,
  onEnded: () => void,
) => {
  const dispatch = useAppDispatch();
  const player = useRef<IAdditionalAudio>();
  const isFocused = useIsFeedFocused();
  const feedShouldPlay = useAppSelector(selectFeedShouldPlay);
  const [isBuffering, setIsBuffering] = useState(false);
  const [currentTime, setCurrentTime] = useState(track?.chorusStart || 0);
  const [duration, setDuration] = useState(track?.duration || 0);

  const setIsBufferingDebounced = useCallback(
    debounce(500, setIsBuffering),
    [],
  );

  useRecyclingHandler(track?.id, () => {
    setIsBufferingDebounced.cancel();
    setIsBuffering(false);
    setCurrentTime(track?.chorusStart || 0);
    setDuration(track?.duration || 0);
  });

  useEffect(() => {
    if (track) {
      player.current = initAdditionalAudio({
        uri: track.lossyAudioUrl,
        startTimeInSeconds: track.chorusStart,
        shouldLoop: false,
        setIsBuffering: setIsBufferingDebounced,
        setCurrentTime,
        setDuration: track.duration ? undefined : setDuration,
        onEnded: () => {
          analytics.feedTrackEnded(track.id);
          onEnded();
        },
      });

      return () => {
        player.current?.destroy();
      };
    }
  }, [track?.lossyAudioUrl]);

  useEffect(() => {
    if (isActive) {
      player.current?.load();
    }
  }, [isActive, track?.id]);

  useEffect(() => {
    if (isActive && isFocused && feedShouldPlay) {
      dispatch(pauseMainPlayer());
      player.current?.play();
      track?.id && analytics.feedAudioPlayed(track?.id);
    } else {
      player.current?.pause();
    }
  }, [isActive, isFocused, feedShouldPlay, track?.id]);

  const isPlaying = isActive && isFocused && feedShouldPlay && !!track;

  return {
    isPlaying,
    isBuffering: isPlaying && isBuffering,
    progress: {
      currentTime,
      duration,
      seekTo: player.current?.seekTo,
    },
  };
};
