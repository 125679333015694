import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    padding: spacing.s + spacing.xs,
    gap: spacing.s + spacing.xs,
  },
  icon: {
    alignSelf: 'center',
  },
  actions: {
    gap: spacing.xs + spacing.xxs,
  },
}));
