import React from 'react';

import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {openLinkSafe} from '@/utils/linking';

export const useLensFollowValidation = ():
  | ITransactionValidationError
  | undefined => {
  const {track, selectedOffer} = useCollectState();

  if (selectedOffer?.error?.type === 'lens_follow') {
    return {
      message: (
        <WarningBox
          title={{id: 'collect.lensFollow.title'}}
          message={{
            id: 'collect.lensFollow.message',
            values: {artist: track.artist.name},
          }}
          action={
            track.websiteUrl && (
              <TransparentButton
                onPress={() => openLinkSafe(track.websiteUrl!)}
                text={{id: 'collect.lensFollow.action'}}
              />
            )
          }
        />
      ),
    };
  }

  return undefined;
};
