import {Handler} from './types';

/**
 * Allows subscribing to app focus events.
 */
export const addFocusEventListener = (handler: Handler) => {
  const isFocused = () =>
    [undefined, 'visible', 'prerender'].includes(document.visibilityState);

  const listener = () => {
    if (isFocused()) {
      handler();
    }
  };

  // window.addEventListener('visibilitychange', listener, false);
  window.addEventListener('focus', listener, false);

  return () => {
    // window.removeEventListener('visibilitychange', listener);
    window.removeEventListener('focus', listener);
  };
};
