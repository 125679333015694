import React from 'react';

import Icon from '@/components/Icon';
import InfoModal from '@/components/InfoModal';
import Space from '@/components/Space';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {selectOnboardingInfo, setOnboardingInfo} from '@/store/user';

const OnboardingModal = () => {
  const dispatch = useAppDispatch();
  const {showLogoutExplanation} = useAppSelector(selectOnboardingInfo);

  const onClose = () => {
    dispatch(setOnboardingInfo({key: 'showLogoutExplanation', value: false}));
  };

  if (!showLogoutExplanation) {
    return null;
  }

  return (
    <InfoModal
      titleId="onboarding.logout.title"
      isOpen
      onClose={onClose}
      content={
        <>
          <Icon
            provider="custom"
            name="logoutModal"
            size={spacing.xl * 3}
            style={{alignSelf: 'center'}}
          />
          <Space h="m" />
          <Text
            id="onboarding.logout.description1"
            weight="semibold"
            align="center"
          />
          <Space h="m" />
          <Text id="onboarding.logout.description2" align="center" size="xs" />
        </>
      }
    />
  );
};

export default OnboardingModal;
