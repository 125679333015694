/**
 * Set of mock functions for handling push notifications. It basically does nothing but keeps the same export scheme
 * as config for mobile, so it can be easily used on all platform in userland
 */

import {useMutation} from '@tanstack/react-query';

import {noop} from '@/utils/functions';

// Copy of firebase AuthorizationStatus, so it can be imported and use on web too
const NotificationsPermissionStatus = {
  NOT_DETERMINED: -1,
  DENIED: 0,
  AUTHORIZED: 1,
  PROVISIONAL: 2,
};

const enableNotifications = () => Promise.resolve();

const requestNotificationsPermission = () =>
  Promise.resolve(NotificationsPermissionStatus.AUTHORIZED);

const checkNotificationsPermission = () =>
  Promise.resolve(NotificationsPermissionStatus.AUTHORIZED);

const subscribeToFgNotificationPress = () => noop;

const subscribeToBgNotificationPress = () => noop;

const getInitialNotification = () => Promise.resolve(null);

const subscribeToBackgroundNotifications = noop;

const useNotificationsPermissionQuery = () => ({
  areNotificationsEnabled: true,
  authorizationStatus: NotificationsPermissionStatus.AUTHORIZED,
});

const useNotificationsPermissionMutation = () => {
  return {
    requestPermission: noop,
    requestPermissionAsync: () =>
      Promise.resolve(NotificationsPermissionStatus.AUTHORIZED),
  };
};

const useRemoveDeviceTokenMutation = () =>
  useMutation({mutationFn: () => Promise.resolve()});

const useSetupPushNotifications = noop;

export {
  NotificationsPermissionStatus,
  checkNotificationsPermission,
  enableNotifications,
  requestNotificationsPermission,
  subscribeToFgNotificationPress,
  subscribeToBgNotificationPress,
  getInitialNotification,
  subscribeToBackgroundNotifications,
  useNotificationsPermissionQuery,
  useNotificationsPermissionMutation,
  useRemoveDeviceTokenMutation,
  useSetupPushNotifications,
};
