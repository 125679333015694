import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles((theme, responsive) => ({
  wrapper: {
    backgroundColor:
      responsive.isDesktop || responsive.isSmallDesktop
        ? 'transparent'
        : theme.colors.backgroundLight,
    flex: 1,
    position: 'relative',
  },
  opaqueOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.colors.opaqueOverlay,
  },
}));
