import React, {FC} from 'react';

import {ArtistCard} from '@/components/ArtistCard';
import {IIconProps} from '@/components/Icon';
import {IPlaylistWithFollowedArtist} from '@/screens/Library/types';
import {IPlaylist} from '@/types/playlists';

interface IFollowedArtistCardProps {
  playlist: IPlaylistWithFollowedArtist;
  onUnfollow: (playlist: IPlaylist) => void;
}

const actionIcon: IIconProps = {
  provider: 'fontAwesome',
  name: 'heart',
  size: 14,
  color: 'favoritesColor',
};

const FollowedArtistCard: FC<IFollowedArtistCardProps> = ({
  playlist,
  onUnfollow,
}) => (
  <ArtistCard
    artist={playlist.followedArtist}
    actions={[
      {
        onPress: () => onUnfollow(playlist),
        icon: actionIcon,
      },
    ]}
  />
);

export default React.memo(FollowedArtistCard);
