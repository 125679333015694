import {useEffect} from 'react';
import {NativeScrollEvent, NativeSyntheticEvent} from 'react-native';
import {Gesture} from 'react-native-gesture-handler';
import Animated, {
  useSharedValue,
  scrollTo,
  SharedValue,
  AnimatedRef,
} from 'react-native-reanimated';

interface ISwipeHandlerInput {
  pageWidth: SharedValue<number>;
  gap: number;
  scrollPosition: SharedValue<number>;
  scrollViewRef: AnimatedRef<Animated.ScrollView>;
}

export const useSwipeHandler = ({
  pageWidth,
  gap,
  scrollPosition,
  scrollViewRef,
}: ISwipeHandlerInput) => {
  const scrollPositionSnapshot = useSharedValue(0);
  const swipeDirection = useSharedValue<-1 | 1>(1);
  const isDragging = useSharedValue(false);

  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) =>
    (scrollPosition.value = event.nativeEvent.contentOffset.x);

  const swipeGesture = Gesture.Pan()
    .onStart(() => {
      scrollPositionSnapshot.value = scrollPosition.value;
      isDragging.value = true;
    })
    .onUpdate(e => {
      const newScrollPosition = scrollPositionSnapshot.value - e.translationX;
      swipeDirection.value = newScrollPosition >= scrollPosition.value ? 1 : -1;

      scrollTo(scrollViewRef, newScrollPosition, 0, false);
    })
    .onEnd(() => {
      const round = swipeDirection.value > 0 ? Math.floor : Math.ceil;
      const page =
        round(scrollPosition.value / (pageWidth.value + gap)) +
        swipeDirection.value;

      scrollTo(scrollViewRef, page * (pageWidth.value + gap), 0, true);
      isDragging.value = false;
    });

  // snapToInterval and onMomentumScrollEnd  props are not supported on web version of ScrollView.
  // To achieve proper snapping, we need to manually subscribe and handle scrollend event
  useEffect(() => {
    const listener = (event: any) => {
      if (!isDragging.value) {
        const page = Math.round(
          event.target.scrollLeft / (pageWidth.value + gap),
        );
        scrollTo(scrollViewRef, page * (pageWidth.value + gap), 0, true);
      }
    };

    // @ts-ignore - typescript does not indicate type properly, but scrollViewRef.current is regular div element
    scrollViewRef.current?.addEventListener('scrollend', listener);

    return () => {
      // @ts-ignore
      scrollViewRef.current?.removeEventListener('scrollend', listener);
    };
  }, [gap]);

  return {
    scrollViewRef,
    swipeGesture,
    onScroll,
    scrollPosition,
  };
};
