import React, {FC} from 'react';
import {View} from 'react-native';

import SpinLoader from '@/components/SpinLoader/SpinLoader';
import {useDebouncedLoader} from '@/hooks/useDebouncedLoader';
import {useThemedStyles} from '@/theme';

import {styles} from './DynamicImage.style';

interface IProps {
  isLoaded: boolean;
  spinnerSize?: number;
}

const ImageLoader: FC<IProps> = ({isLoaded, spinnerSize}) => {
  const style = useThemedStyles(styles);
  const showLoader = useDebouncedLoader(!isLoaded);

  if (!showLoader) {
    return null;
  }

  return (
    <View style={style.spinnerContainer}>
      <SpinLoader size={spinnerSize} />
    </View>
  );
};

export default ImageLoader;
