import spacing from '@/constants/spacing';
import {
  calculateBackgroundColorDark,
  calculateBackgroundColorLight,
  createThemedStyles,
} from '@/theme';

const SCREEN_PADDING = spacing.s;

export const styles = createThemedStyles(theme => ({
  scrollContent: {
    padding: SCREEN_PADDING,
    paddingTop: spacing.xxs,
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  header: {
    backgroundColor: calculateBackgroundColorDark(theme),
    margin: -SCREEN_PADDING,
    marginBottom: SCREEN_PADDING,
    paddingTop: SCREEN_PADDING,
  },
  content: {
    flexGrow: 1,
    padding: spacing.s,
  },
}));
