import {QueryClient} from '@tanstack/react-query';

import {sendTransactionEvents} from '@/api/events';
import {upsertFeedItem} from '@/api/feed';
import {ITransactionEvent} from '@/modules/Transactions/types';
import {type AppStore} from '@/store';
import {selectSignerByUserId} from '@/store/user';
import {IFeedItemRaw} from '@/types/feed';
import {MutationKeys} from '@/types/mutationKeys';
import {QueryKeys} from '@/types/queryKeys';
import {analytics} from '@/utils/analytics';
import {getPlatformInfo} from '@/utils/platform';

export const setMutationDefaults = (
  queryClient: QueryClient,
  store: AppStore,
) => {
  // Feed
  queryClient.setMutationDefaults([QueryKeys.feed], {
    mutationFn: async (feedItem: IFeedItemRaw) => {
      const {userId} = feedItem;
      const signer = selectSignerByUserId(store.getState(), userId);

      if (!signer) {
        return Promise.reject(new Error('Missing signer'));
      }

      return upsertFeedItem(feedItem, signer);
    },
  });

  // Transaction events tracking
  queryClient.setMutationDefaults([MutationKeys.transactionEvent], {
    onMutate: (event: ITransactionEvent) => {
      analytics.trackTransaction(event);
    },
    mutationFn: (event: ITransactionEvent) => {
      const signer = selectSignerByUserId(store.getState(), event.userId);

      if (!signer) {
        return Promise.reject('Missing signer');
      }

      return sendTransactionEvents(
        [{...event, platform: getPlatformInfo()}],
        signer,
      );
    },
  });
};
