import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {IApiResponseArtist, IPageInfo, IPaginatedResponse} from '@/types/api';
import {
  IArtist,
  IArtistUpdate,
  IArtistWithTracks,
  ITrack,
} from '@/types/common';
import {ISigner} from '@/types/session';
import {
  ARTIST_FRAGMENT,
  ARTIST_WITH_5_TRACKS_FRAGMENT,
  parseApiArtist,
  parseApiArtistWithTracks,
  parseApiTrack,
  TRACK_FRAGMENT,
} from '@/utils/apiModelParsers';
import {isNotNil} from '@/utils/functions';

import {submitEntityFieldUpdate} from './entityFieldUpdates';

export const fetchArtistById = async (artistId: string): Promise<IArtist> => {
  const artist = await pipelineApi.request(
    gql`
      query Artist {
        artistById(id: "${artistId}") {
          ...ArtistDetails
        }
      }
      ${ARTIST_FRAGMENT}
    `,
  );

  return parseApiArtist(artist.artistById as IApiResponseArtist);
};

export const fetchArtistBySlug = async (
  slug: string,
): Promise<IArtist | null> => {
  const response = await pipelineApi.request(
    gql`
      query ArtistBySlug {
        allArtists(
          first: 1
          orderBy: CREATED_AT_TIME_ASC
          filter: {slug: {startsWithInsensitive: "${slug}"}}
        ) {
          nodes {
            ...ArtistDetails
          }
        }
      }
      ${ARTIST_FRAGMENT}
    `,
  );
  const {nodes} = response.allArtists;

  if (nodes.length === 0) {
    return null;
  }

  return parseApiArtist(nodes[0] as IApiResponseArtist);
};

export const fetchArtistByUrlParam = async (param: string): Promise<IArtist> =>
  (await fetchArtistBySlug(param)) || (await fetchArtistById(param));

export const fetchArtistByIdOrSlug = async (
  param: string,
): Promise<IArtist | null> => {
  try {
    return await fetchArtistById(param);
  } catch (error) {}

  return fetchArtistBySlug(param);
};

export const fetchArtistTracks = async (
  artistId: string,
  after?: string,
): Promise<IPaginatedResponse<ITrack>> => {
  const response = await pipelineApi.request(
    gql`
      query ArtistTracks($artistId: String!, $after: Cursor) {
        allProcessedTracks(
          after: $after
          first: 100
          orderBy: CREATED_AT_TIME_DESC
          filter: {
            or: [
              {artistId: {equalTo: $artistId}}
              {supportingArtist: {equalTo: $artistId}}
            ]
          }
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...TrackDetails
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {
      artistId,
      after,
    },
  );

  const tracks: ITrack[] = response.allProcessedTracks.nodes.map(parseApiTrack);
  const pageInfo: IPageInfo = response.allProcessedTracks.pageInfo;

  return {
    pageInfo,
    items: tracks,
  };
};

export const fetchArtistDetails = async (id: string) => {
  const artist = await fetchArtistByIdOrSlug(id);
  const tracks = await fetchArtistTracks(artist!.id);

  return {
    artist,
    tracks: tracks.items,
  };
};

export const fetchArtistsByIds = async (
  artistIds: string[] = [],
): Promise<IArtist[]> => {
  if (artistIds.length === 0) {
    return [];
  }

  const response = await pipelineApi.request(
    gql`
      query ArtistsByIds($artistIds: [String!]) {
        allArtists(
          orderBy: CREATED_AT_TIME_DESC
          filter: {id: {in: $artistIds}}
        ) {
          nodes {
            ...ArtistDetails
          }
        }
      }
      ${ARTIST_FRAGMENT}
    `,
    {artistIds},
  );

  const artists: IArtist[] = response.allArtists.nodes.map(parseApiArtist);
  return artistIds
    .map(artistId => artists.find(artist => artist.id === artistId))
    .filter(artist => !!artist) as IArtist[];
};

export const fetchArtistsWithTracksByIds = async (
  artistIds: string[] = [],
): Promise<IArtistWithTracks[]> => {
  if (artistIds.length === 0) {
    return [];
  }

  const response = await pipelineApi.request(
    gql`
      query ArtistsByIds($artistIds: [String!]) {
        allArtists(
          orderBy: CREATED_AT_TIME_DESC
          filter: {id: {in: $artistIds}}
        ) {
          nodes {
            ...ArtistWithTracksDetails
          }
        }
      }
      ${ARTIST_WITH_5_TRACKS_FRAGMENT}
    `,
    {artistIds},
  );

  const artists: IArtistWithTracks[] = response.allArtists.nodes.map(
    parseApiArtistWithTracks,
  );
  return artistIds
    .map(artistId => artists.find(artist => artist.id === artistId))
    .filter(isNotNil);
};

export const fetchArtistsBySearchPhrase = async (
  search: string,
): Promise<IArtist[]> => {
  const response = await pipelineApi.request(
    gql`
      query SearchArtists($search: String!) {
        allArtists(
          first: 100
          orderBy: CREATED_AT_TIME_DESC
          filter: {name: {includesInsensitive: $search}}
        ) {
          nodes {
            ...ArtistDetails
          }
        }
      }
      ${ARTIST_FRAGMENT}
    `,
    {search},
  );

  return response.allArtists.nodes.map(parseApiArtist);
};

export const fetchLatestArtists = async (
  after?: string,
  limit: number = 100,
): Promise<IPaginatedResponse<IArtist>> => {
  const response = await pipelineApi.request(
    gql`
      query LatestArtists($after: Cursor, $limit: Int) {
        allArtists(
          after: $after
          first: $limit
          orderBy: CREATED_AT_TIME_DESC
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...ArtistDetails
          }
        }
      }
      ${ARTIST_FRAGMENT}
    `,
    {after, limit},
  );

  const artists: IArtist[] = response.allArtists.nodes.map(parseApiArtist);
  const pageInfo: IPageInfo = response.allArtists.pageInfo;

  return {
    pageInfo,
    items: artists,
  };
};

export const updateArtist = (update: IArtistUpdate, signer: ISigner) =>
  submitEntityFieldUpdate<IArtistUpdate>('artists', update, signer);

export const fetchArtistAddressById = async (
  artistId: string,
): Promise<string> => {
  // if (artistIds.length === 0) {
  //   return [];
  // }

  const response = await pipelineApi.request(
    gql`
      query ArtistsByIds($artistId: String!) {
        artistById(id: $artistId) {
          userByUserId {
            addressesByUserId {
              nodes {
                isPublic
                id
                isWallet
              }
            }
          }
        }
      }
    `,
    {artistId},
  );

  const artistAddress =
    response.artistById.userByUserId.addressesByUserId.nodes.find(
      (address: any) => address.isPublic && address.isWallet, //maybe don't need the isWallet check
    );
  return artistAddress?.id;
};
