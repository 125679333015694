import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import Header from '@/components/Header';
import HeaderButton from '@/components/HeaderButton';
import {IIconProps} from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import InfinityList, {InfinityListRef} from '@/components/InfinityList';
import KeyboardView from '@/components/KeyboardView/KeyboardView';
import Loader from '@/components/Loader';
import Screen from '@/components/Screen';
import SearchInput from '@/components/SearchInput/SearchInput';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {TRACK_CARD_HEIGHT, TrackCardPreview} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useDebouncedSearch} from '@/hooks/useDebouncedSearch';
import {useAppSelector} from '@/hooks/useRedux';
import {useFavsTracks} from '@/hooks/useUserPlaylists';
import {useToggleTrackInFollowedPlaylist} from '@/queries/followedPlaylists';
import {useToggleTrackInPlaylist} from '@/queries/ownedPlaylists';
import {useTracksSearchQuery} from '@/queries/search';
import {selectPlaylistOrFollowedPlaylist} from '@/store/playlists';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';
import {isIOS} from '@/utils/platform';
import {getPlaylistTracksMap, isInCollaborators} from '@/utils/playlists';

import {styles} from './AddToPlaylist.style';

const icons: {[key: string]: IIconProps} = {
  add: {
    name: 'add',
    provider: 'custom',
  },
  remove: {
    name: 'checked',
    provider: 'custom',
  },
};

const AddToPlaylist = () => {
  const style = useThemedStyles(styles);
  const userId = useAppSelector(selectActiveUserId);
  const {
    params: {id: playlistId},
  } = useRoute<RouteProp<RootStackParams, Routes.AddToPlaylist>>();
  const {playlist, parentPlaylistId} = useAppSelector(state =>
    selectPlaylistOrFollowedPlaylist(state, playlistId),
  );
  const playlistTracksMap = useMemo(
    () => getPlaylistTracksMap(playlist?.trackIds),
    [playlist?.trackIds],
  );
  const isOwner = userId === playlist?.collector;

  const [search, setSearch] = useState('');
  const {searchDebounced, clearSearch} = useDebouncedSearch({
    search,
    setSearch,
  });

  const listRef = useRef<InfinityListRef | null>(null);
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {favoriteTracks} = useFavsTracks();
  const {
    tracks,
    query: {isFetching},
  } = useTracksSearchQuery(searchDebounced);

  const {toggleTrackInPlaylistMutation} = useToggleTrackInPlaylist();
  const {toggleTrackInFollowedPlaylistMutation} =
    useToggleTrackInFollowedPlaylist();

  const toggleTrack = useCallback(
    (track: ITrack) => {
      if (parentPlaylistId) {
        toggleTrackInFollowedPlaylistMutation({
          parentPlaylistId,
          playlistId,
          trackId: track.id,
        });
      } else {
        toggleTrackInPlaylistMutation({
          playlistId,
          trackId: track.id,
        });
      }
    },
    [playlistId, parentPlaylistId],
  );

  useEffect(() => {
    const canCollaborate =
      isInCollaborators(playlist?.collaborators, userId) &&
      playlist?.mode === 'free-for-all';

    if (!playlist || (!isOwner && !canCollaborate)) {
      navigation.goBack();
    }
  }, [playlist, isOwner, userId]);

  useEffect(() => {
    listRef?.current?.scrollToOffset({offset: 0, animated: true});
  }, [searchDebounced]);

  if (!playlist) {
    return null;
  }

  return (
    <Screen>
      <KeyboardView>
        <Header
          titleId="addToPlaylist.title"
          titleProps={{size: 's'}}
          scrollPosition={scrollPosition}
          mockTitle
          modalMode={isIOS}
          leftActions={
            <IconButton
              onPress={() => navigation.goBack()}
              icon={{
                provider: 'custom',
                name: 'arrowDown',
              }}
            />
          }
          rightActions={
            searchDebounced ? (
              <HeaderButton
                onPress={() => navigation.goBack()}
                text={{id: 'done'}}
              />
            ) : null
          }>
          <Space ph="s" pv="xs">
            <SearchInput
              value={search}
              setValue={setSearch}
              onClear={clearSearch}
              autoFocus={false}
              placeholderId="addToPlaylist.placeholder"
            />
          </Space>
        </Header>
        <InfinityList
          ref={listRef}
          data={searchDebounced ? tracks : favoriteTracks}
          extraData={playlistTracksMap}
          contentContainerStyle={style.scrollContent}
          itemSize={TRACK_CARD_HEIGHT}
          keyExtractor={item => item.id}
          onScroll={onScroll}
          renderItem={({item: track}) => (
            <TrackCardPreview
              onPress={toggleTrack}
              track={track}
              suffixIcon={
                playlistTracksMap[track.id] ? icons.remove : icons.add
              }
            />
          )}
          ListHeaderComponent={
            <Space mt="xxs" mb="xs">
              <Text
                weight="semibold"
                id={
                  searchDebounced
                    ? 'addToPlaylist.tracks'
                    : 'addToPlaylist.favs'
                }
              />
            </Space>
          }
          ListEmptyComponent={
            isFetching ? (
              <Loader />
            ) : searchDebounced ? (
              <Text align="center" id="addToPlaylist.searchEmpty" />
            ) : null
          }
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="always"
        />
      </KeyboardView>
    </Screen>
  );
};

export default AddToPlaylist;
