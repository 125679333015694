import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
} from 'react';
import {View} from 'react-native';
import {GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  useAnimatedRef,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
} from 'react-native-reanimated';

import {getLayoutWidth} from '@/components/Layout/utils';
import PreventPressOnSwipe from '@/components/PreventPressOnSwipe';
import {
  getNextPageOffset,
  getSliderPageWidth,
  SliderDefaults,
} from '@/components/Slider/shared';
import {ISliderProps, ISliderRef} from '@/components/Slider/types';
import {useSwipeHandler} from '@/components/Slider/useSwipeHandler';
import spacing from '@/constants/spacing';
import {chunkArray} from '@/utils/functions';

const Slider = <ItemType,>(
  {
    data,
    renderItem,
    keyExtractor,
    padding = SliderDefaults.padding,
    gap = SliderDefaults.gap,
    verticalGap = SliderDefaults.verticalGap,
    offset = SliderDefaults.offset,
    visibleColumns = SliderDefaults.visibleColumns,
    rows = SliderDefaults.rows,
    containerStyle,
    onScroll: customOnScroll,
  }: ISliderProps<ItemType>,
  ref: ForwardedRef<ISliderRef>,
) => {
  const scrollViewRef = useAnimatedRef<Animated.ScrollView>();
  const scrollPosition = useSharedValue(0);

  const containerWidth = useSharedValue(getLayoutWidth());
  const pageWidth = useDerivedValue(() =>
    getSliderPageWidth({
      containerWidth: containerWidth.value,
      padding,
      offset,
      gap,
      visibleColumns,
    }),
  );
  const pageStyle = useAnimatedStyle(
    () => ({
      width: pageWidth.value,
      gap: verticalGap,
    }),
    [verticalGap],
  );

  const {swipeGesture} = useSwipeHandler({
    pageWidth,
    gap,
    scrollViewRef,
    scrollPosition,
  });

  const pages = useMemo(() => chunkArray(data, rows), [data, rows]);

  useLayoutEffect(() => {
    if (scrollViewRef.current) {
      // @ts-ignore
      const {width} = scrollViewRef.current.getBoundingClientRect();

      // In react native web, if screen mounts in unfocused state it has all elements width set to 0.
      // For that case, we fallback to getLayoutWidth(), which makes that work properly in views rendered inside NavigatorContainer.
      if (width > 0) {
        containerWidth.value = width;
      } else {
        containerWidth.value = getLayoutWidth();
      }
    }
  }, []);

  useImperativeHandle(ref, () => ({
    next: () =>
      scrollViewRef.current?.scrollTo({
        animated: true,
        x: getNextPageOffset(
          scrollPosition.value,
          pageWidth.value,
          gap,
          pages.length,
          visibleColumns,
        ),
      }),
  }));

  return (
    <GestureDetector gesture={swipeGesture}>
      <PreventPressOnSwipe style={{width: '100%'}}>
        <Animated.ScrollView
          ref={scrollViewRef}
          onScroll={event => {
            scrollPosition.value = event.nativeEvent.contentOffset.x;
            customOnScroll?.(scrollPosition.value, pageWidth.value);
          }}
          onLayout={event => {
            const {width} = event.nativeEvent.layout;

            if (width !== containerWidth.value && width > 0) {
              containerWidth.value = width;
            }
          }}
          style={containerStyle}
          contentContainerStyle={{
            paddingHorizontal: padding,
            gap,
          }}
          scrollEventThrottle={16}
          horizontal
          hitSlop={{left: -spacing.m}}
          showsHorizontalScrollIndicator={false}
          snapToInterval={pageWidth.value + gap}
          decelerationRate={0.99}>
          {pages.map((page, pageIndex) => (
            <Animated.View key={pageIndex} style={pageStyle}>
              {page.map((item, index) => (
                <View key={keyExtractor(item)}>
                  {renderItem(item, index + pageIndex * page.length)}
                </View>
              ))}
            </Animated.View>
          ))}
        </Animated.ScrollView>
      </PreventPressOnSwipe>
    </GestureDetector>
  );
};

export default forwardRef(Slider) as <ItemType>(
  props: ISliderProps<ItemType> & {
    ref?: React.ForwardedRef<ISliderRef>;
  },
) => ReturnType<typeof Slider>;
