import type {QueryState} from '@tanstack/query-core/src/query';
import {InfiniteData} from '@tanstack/react-query';

import {IPaginatedResponse} from '@/types/api';
import {IQueueItem, PlayContextType} from '@/types/common';
import {
  IFeedEntityType,
  IFeedItem,
  IFeedItemRaw,
  IFeedItemWithArtist,
  IFeedItemWithTrack,
  ILocalMessageFeedItem,
} from '@/types/feed';
import {isNotNil} from '@/utils/functions';

/**
 * Sorts by insertionId desc, id desc.
 *
 * This should match the sort we request in our GraphQL query for feed items
 * in src/api/feed.ts
 *
 * @param a
 * @param b
 * @returns positive if a < b, negative if b < a, zero if a == b
 */
export function sortFeedItems(a: IFeedItem, b: IFeedItem): number {
  if (
    isNotNil(b.position) &&
    isNotNil(a.position) &&
    b.position !== a.position
  ) {
    return b.position - a.position;
  }

  if (b.createdAtTime !== a.createdAtTime) {
    return (
      new Date(b.createdAtTime).getTime() - new Date(a.createdAtTime).getTime()
    );
  }
  if (
    isNotNil(b.insertionId) &&
    isNotNil(a.insertionId) &&
    b.insertionId !== a.insertionId
  ) {
    return b.insertionId - a.insertionId;
  }

  // If insertionId is equal, sort by id
  return b.id.localeCompare(a.id);
}

export const isTrack = (item: IFeedItem): item is IFeedItemWithTrack =>
  item.entityType === IFeedEntityType.track;
export const isArtist = (item: IFeedItem): item is IFeedItemWithArtist =>
  item.entityType === IFeedEntityType.artist;

// Feed - keep only 3 pages and filter out liked items
const PERSISTED_FEED_PAGES_COUNT = 3;
export const restoreFeedQuery = (query: {state: QueryState}) => {
  if (!query.state.data) {
    return query.state;
  }

  const data = query.state.data as InfiniteData<
    IPaginatedResponse<IFeedItemRaw>
  >;

  return {
    ...query,
    state: {
      ...query.state,
      data: {
        pageParams: data.pageParams.slice(0, PERSISTED_FEED_PAGES_COUNT),
        pages: data.pages.slice(0, PERSISTED_FEED_PAGES_COUNT).map(page => ({
          ...page,
          items: page.items.filter(item => item.userAction === null),
        })),
      },
    },
  };
};

export const mapAllFeedItemsToQueue = (
  feedItems: (IFeedItem | ILocalMessageFeedItem)[],
) => {
  const items: Pick<IQueueItem, 'trackId' | 'context'>[] = [];

  feedItems.forEach(feedItem => {
    if (feedItem.entityType === 'track') {
      items.push({
        trackId: feedItem.track.id,
        context: {
          type: PlayContextType.feed,
          id: feedItem.id,
        },
      });
    } else if (feedItem.entityType === 'artist') {
      items.push(
        ...feedItem.artist.tracks.map(t => ({
          trackId: t.id,
          context: {
            type: PlayContextType.feed,
            id: feedItem.id,
          },
        })),
      );
    }
  });

  return items;
};
