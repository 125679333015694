import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  previewPanel: {
    borderRadius: theme.box.borderRadius,
    height: 150,
  },
  colorSlider: {
    borderRadius: theme.box.borderRadius,
    height: 20,
  },
  thumb: {
    borderRadius: theme.box.borderRadius,
    borderWidth: 2,
  },
  actions: {
    flexDirection: 'row',
  },
  action: {
    flex: 1,
    minWidth: 70,
    justifyContent: 'center',
  },
}));
