import React, {FC, ReactNode, useEffect, useMemo, useState} from 'react';
import {StatusBar} from 'react-native';

import {useActiveUser} from '@/hooks/useActiveUser';
import {usePrevious} from '@/hooks/usePrevious';
import {useAppSelector} from '@/hooks/useRedux';
import {useThemes} from '@/hooks/useThemes';
import {selectCurrentScreen} from '@/store/navigation';
import {selectDefaultTheme, selectThemeSettings} from '@/store/theme';
import {makeTheme} from '@/themes/partialTheme';
import {Routes} from '@/types/routes';
import {IDynamicTheme, ITheme, IThemesConfig} from '@/types/theme';
import {isAndroid} from '@/utils/platform';

import {ThemeContext} from './context';

const CUSTOM_THEME_ROUTES = [
  Routes.Artist,
  Routes.ArtistEdit,
  Routes.ArtistThemeEdit,
  Routes.Collector,
  Routes.CollectorEdit,
  Routes.CollectorThemeEdit,
  Routes.Track,
  Routes.Player,
  Routes.Playlist,
  Routes.Platform,
  Routes.EmbedArtist,
  Routes.EmbedPlayer,
  Routes.EmbedCollection,
];

const ThemeProvider: FC<{children: ReactNode}> = ({children}) => {
  const activeUser = useActiveUser();
  const defaultThemeName: keyof IThemesConfig =
    useAppSelector(selectDefaultTheme);
  const userThemeSettings = useAppSelector(selectThemeSettings);

  const {getTheme, isValidTheme} = useThemes();

  const [screenTheme, setScreenTheme] = useState<ITheme | null>(null);

  // prioritize custom theme over predefined if both exists
  const overrideTheme = ({predefinedThemeName, customTheme}: IDynamicTheme) => {
    if (customTheme) {
      setScreenTheme(makeTheme({basic: customTheme}));
    } else if (predefinedThemeName && isValidTheme(predefinedThemeName)) {
      setScreenTheme(getTheme(predefinedThemeName));
    }
  };
  const resetTheme = () => {
    setScreenTheme(null);
  };

  const appTheme = useMemo(() => {
    if (
      userThemeSettings?.type === 'artist' &&
      activeUser?.artistProfile?.customTheme
    ) {
      return makeTheme({basic: activeUser.artistProfile.customTheme});
    }

    if (userThemeSettings?.type === 'collector' && activeUser?.customTheme) {
      return makeTheme({basic: activeUser.customTheme});
    }

    if (userThemeSettings?.type === 'predefined' && userThemeSettings?.name) {
      return getTheme(userThemeSettings?.name);
    }

    return getTheme(defaultThemeName);
  }, [defaultThemeName, getTheme, userThemeSettings, activeUser]);

  const theme = useMemo(() => {
    if (screenTheme) {
      return screenTheme;
    }

    return appTheme;
  }, [screenTheme, appTheme]);

  const currentScreen = useAppSelector(selectCurrentScreen);
  const previousScreen = usePrevious(currentScreen);

  useEffect(() => {
    if (
      previousScreen &&
      currentScreen &&
      CUSTOM_THEME_ROUTES.includes(previousScreen) &&
      !CUSTOM_THEME_ROUTES.includes(currentScreen)
    ) {
      resetTheme();
    }
  }, [previousScreen, currentScreen]);

  return (
    <>
      {isAndroid ? (
        <StatusBar
          backgroundColor="transparent"
          barStyle="dark-content"
          translucent
        />
      ) : (
        <StatusBar
          backgroundColor={theme.colors.background}
          barStyle="dark-content"
        />
      )}

      <ThemeContext.Provider
        value={{
          appTheme,
          theme,
          overrideTheme,
          resetTheme,
        }}>
        {children}
      </ThemeContext.Provider>
    </>
  );
};

export default ThemeProvider;
