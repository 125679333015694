import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {FC, useEffect, useState} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import ScrollContainer from '@/components/ScrollContainer';
import Space from '@/components/Space';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {chainsById} from '@/constants/chains';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useNotificationsPermissionMutation} from '@/modules/Notifications/pushNotifications';
import {useToast} from '@/modules/Toasts';
import {Ramp} from '@/modules/TopUp/ramp';
import EmailForm from '@/modules/TopUp/TopUpScreen/EmailForm';
import {useUserEmailQuery} from '@/queries/user';
import {Clipboard} from '@/services/clipboard';
import {saveTopUpTransaction} from '@/store/topUp';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {prettifyAddress} from '@/utils/ethereum';
import {awaitScreenTransition} from '@/utils/navigation';

import {styles} from './TopUpScreen.style';

const TopUpScreen: FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const {showToast} = useToast();

  const {params: {address, chainId, amount} = {}} =
    useRoute<RouteProp<RootStackParams, Routes.TopUp>>();

  const {requestPermission} = useNotificationsPermissionMutation();

  const {email, query: emailQuery} = useUserEmailQuery();
  const userId = useAppSelector(selectActiveUserId);

  const [step, setStep] = useState<'initial' | 'emailForm'>('initial');

  useEffect(() => {
    if (!address || !chainId) {
      navigation.goBack();
    }
  }, [address, chainId]);

  if (!address || !chainId) {
    return null;
  }

  const chain = chainsById[chainId];
  const canTopUp = chain?.rampSymbol;

  const topUpWithCard = async (_email: string) => {
    navigation.goBack();

    await awaitScreenTransition();

    requestPermission();
    analytics.topUpStarted(address, amount, chainId);

    Ramp.topUp({
      address,
      chainId,
      amount,
      userId,
      email: _email,
      onPurchaseCreated: ({id, token}) => {
        dispatch(saveTopUpTransaction({id, token, chainId}));
      },
    });
  };

  const onTopUpPress = () => {
    if (!email) {
      setStep('emailForm');
      return;
    }

    topUpWithCard(email);
  };

  const onCopy = async () => {
    try {
      await Clipboard.copy(address);
      showToast({textId: 'address.copied'});
    } catch (error) {}
  };

  return (
    <ModalScreenWrapper titleId="topUp.title" avoidKeyboard>
      {step === 'initial' && (
        <ScrollContainer contentStyle={style.content}>
          <View style={style.receiveInfoContainer}>
            <Text
              id="topUp.receive.description"
              values={{currency: chain.chain.nativeCurrency.symbol}}
              align="center"
            />

            <TransparentButton
              style={style.addressButton}
              onPress={onCopy}
              text={{children: prettifyAddress(address), weight: 'regular'}}
              suffix={
                <Space ml="xs">
                  <Icon name="copy" provider="custom" size={18} />
                </Space>
              }
            />

            <View style={style.chainInfo}>
              {chain.icon && (
                <Icon name={`chain_${chain.icon}`} provider="custom" />
              )}
              <Text align="center">{chain.name}</Text>
            </View>
          </View>

          {canTopUp && (
            <>
              <View style={style.dividerContainer}>
                <Divider style={style.dividerLine} />
                <Text id="topUp.divider" />
                <Divider style={style.dividerLine} />
              </View>
              <CardButton
                onPress={onTopUpPress}
                text={{id: 'topUp.topUpWithCard'}}
                disabled={emailQuery.isLoading}
                icon={{name: 'creditCard', provider: 'custom'}}
              />
            </>
          )}
        </ScrollContainer>
      )}
      {step === 'emailForm' && (
        <EmailForm email={email} onSuccess={topUpWithCard} />
      )}
    </ModalScreenWrapper>
  );
};

export default TopUpScreen;
