import {useState, useCallback} from 'react';
import {LayoutChangeEvent, LayoutRectangle} from 'react-native';

// ignore zero flag is useful for web, where onLayout always return 0 width after screen is not focused anymore
export function useLayout(ignoreZero = false) {
  const [layout, setLayout] = useState<LayoutRectangle | null>(null);

  const onLayout = useCallback(
    (e: LayoutChangeEvent) => {
      if (!ignoreZero || e.nativeEvent.layout.width) {
        setLayout(e.nativeEvent.layout);
      }
    },
    [ignoreZero],
  );

  return {
    onLayout,
    layout,
  };
}
