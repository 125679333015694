import {Slider} from '@miblanchard/react-native-slider';
import React, {FC, useEffect, useRef, useState} from 'react';
import {View} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';

import Loader from '@/components/Loader/Loader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useDebouncedLoader} from '@/hooks/useDebouncedLoader';
import {useIsOffline} from '@/hooks/useIsOffline';
import {usePlayerProgress} from '@/hooks/usePlayerProgress';
import {TrackPlayer} from '@/services/trackPlayer/trackPlayer';
import {useTheme, useThemedStyles} from '@/theme';
import {formatDuration} from '@/utils/dateTime';

import {styles} from './PlayerProgress.style';

interface IProps {
  onProgressChange: (value: number) => void;
  isLoading?: boolean;
}

const PlayerProgress: FC<IProps> = ({onProgressChange, isLoading = false}) => {
  const theme = useTheme();
  const style = useThemedStyles(styles);

  const isOffline = useIsOffline();
  const progress = usePlayerProgress();
  const [sliderValue, setSliderValue] = useState(progress.position);
  const isSliding = useRef(false);
  const lastSeekTimestamp = useRef(0);
  const showLoader = useDebouncedLoader(isLoading, 1000, true);

  useEffect(() => {
    if (!isSliding.current) {
      setSliderValue(progress.position);
    }
  }, [progress.position]);

  const onSlidingStart = () => {
    lastSeekTimestamp.current = Date.now();
    isSliding.current = true;
  };

  const onSlidingComplete = async (seekToValue: number) => {
    const timestamp = Date.now();
    const playbackPosition = await TrackPlayer.getPosition();
    const isForward = seekToValue >= playbackPosition;

    try {
      setSliderValue(seekToValue);
      await onProgressChange(seekToValue);
    } catch (error) {
      isSliding.current = false;
    } finally {
      // seekTo promise from react-native-track-player is resolved to soon sometimes, so we need to do looped check.
      const intervalId = setInterval(async () => {
        if (timestamp < lastSeekTimestamp.current) {
          clearInterval(intervalId);
          return;
        }

        const position = await TrackPlayer.getPosition();
        if (
          (isForward && position >= seekToValue) ||
          (!isForward && position <= playbackPosition)
        ) {
          clearInterval(intervalId);
          if (timestamp >= lastSeekTimestamp.current) {
            setSliderValue(position);
            isSliding.current = false;
          }
        }
      }, 200);
    }
  };

  return (
    <>
      {/* GestureDetector wrapper helps stopping propagating of touch events. It fixes issue with moving whole modal screen while moving the slider */}
      <GestureDetector gesture={Gesture.Pan()}>
        <Slider
          containerStyle={style.containerStyle}
          trackStyle={style.progress}
          minimumValue={0}
          maximumValue={progress.duration}
          minimumTrackTintColor={theme.colors.primary}
          maximumTrackTintColor="transparent"
          value={sliderValue}
          onSlidingStart={onSlidingStart}
          // @ts-ignore
          onValueChange={([value]) => setSliderValue(value)}
          onSlidingComplete={([value]) => onSlidingComplete(value)}
          renderThumbComponent={() => <View style={style.sliderThumb} />}
        />
      </GestureDetector>
      <Space style={style.timeInfo}>
        <Text>{formatDuration(sliderValue)}</Text>
        {isOffline ? (
          <Text size="xs" weight="regular" id="offline" />
        ) : showLoader ? (
          <Loader size="xs" weight="regular" />
        ) : null}
        <Text>{formatDuration(progress.duration)}</Text>
      </Space>
    </>
  );
};

export default PlayerProgress;
