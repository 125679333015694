import React, {FC} from 'react';
import {TouchableWithoutFeedback} from 'react-native';

import {TextProps} from '@/components/Text';
import Text from '@/components/Text/Text';
import {openLinkSafe} from '@/utils/linking';
import {isWeb} from '@/utils/platform';

interface IProps extends TextProps {
  url: string;
  name?: string;
}

const ExternalLink: FC<IProps> = ({url, name, children, ...textProps}) => {
  const content = children || <Text {...textProps}>{name || url}</Text>;

  if (isWeb) {
    return (
      <a
        href={url}
        target="_blank"
        role="link"
        style={{
          maxWidth: '100%',
        }}>
        {content}
      </a>
    );
  }

  return (
    <TouchableWithoutFeedback onPress={() => openLinkSafe(url)}>
      {content}
    </TouchableWithoutFeedback>
  );
};

export default ExternalLink;
