import React, {FC, Fragment} from 'react';
import {View} from 'react-native';

import Divider from '@/components/Divider';
import FlatCard from '@/components/FlatCard';
import Icon, {IIconProps} from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Text, {TextProps} from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import {useThemedStyles} from '@/theme';
import {isNotNil} from '@/utils/functions';

import {styles} from './PopoverMenu.style';

interface IProps {
  actions: Array<
    | {
        key: string;
        text: TextProps;
        icon?: IIconProps;
        onPress: () => void;
        shouldPersistPress?: boolean;
      }
    | null
    | undefined
  >;
}

const PopoverMenu: FC<IProps> = ({actions}) => {
  const style = useThemedStyles(styles);

  return (
    <Tooltip
      position="bottom-right"
      hoverEnabled={false}
      tooltipStyle={style.tooltip}
      content={({close}) => (
        <View>
          {actions.filter(isNotNil).map((action, index) => (
            <Fragment key={action.key}>
              {index > 0 && <Divider />}
              <FlatCard
                style={style.action}
                onPress={() => {
                  action.onPress();

                  if (!action.shouldPersistPress) {
                    close();
                  }
                }}>
                {action.icon && <Icon size={20} {...action.icon} />}
                <Text size="xs" {...action.text} />
              </FlatCard>
            </Fragment>
          ))}
        </View>
      )}>
      {({open}) => (
        <IconButton
          onPress={open}
          icon={{provider: 'custom', name: 'dotsMenu'}}
        />
      )}
    </Tooltip>
  );
};

export default PopoverMenu;
