import React, {FC} from 'react';

import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import InfoModal from '@/components/InfoModal';
import {IModalProps} from '@/components/Modal/Modal';
import {IPlaylist, PlaylistType} from '@/types/playlists';

interface IProps extends IModalProps {
  playlist: IPlaylist;
  onConfirm: () => void;
}

const descriptions: {[type in PlaylistType]?: string} = {
  [PlaylistType.followedPlaylist]: 'deletePlaylist.description.followed',
  [PlaylistType.platform]: 'deletePlaylist.description.platform',
  [PlaylistType.artist]: 'deletePlaylist.description.artist',
  [PlaylistType.custom]: 'deletePlaylist.description.custom',
  [PlaylistType.followedCollection]: 'deletePlaylist.description.collection',
};

const DeletePlaylistModal: FC<IProps> = ({
  isOpen,
  playlist,
  onConfirm,
  onClose,
}) => {
  const descriptionId =
    descriptions[playlist.type] || 'deletePlaylist.description';

  if (playlist.collaborators?.length) {
    return (
      <InfoModal
        titleId="deletePlaylist.title"
        descriptionId="deletePlaylist.collaboratorsInfo.description"
        isOpen={isOpen}
        onClose={onClose}
      />
    );
  }

  return (
    <ConfirmationModal
      titleId="deletePlaylist.title"
      descriptionId={descriptionId}
      onConfirm={onConfirm}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default DeletePlaylistModal;
