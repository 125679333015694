import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ITopUpTransaction {
  id: string;
  token: string;
  chainId: number;
}

export interface ITopUpState {
  transactions: ITopUpTransaction[];
}

export const initialState: ITopUpState = {
  transactions: [],
};

export const topUpSlice = createSlice({
  name: 'topUp',
  initialState,
  reducers: {
    saveTopUpTransaction: (state, action: PayloadAction<ITopUpTransaction>) => {
      state.transactions.push(action.payload);
    },
    removeTopUpTransaction: (
      state,
      action: PayloadAction<{
        id: string;
      }>,
    ) => {
      state.transactions = state.transactions.filter(
        tx => tx.id !== action.payload.id,
      );
    },
  },
});

export const {saveTopUpTransaction, removeTopUpTransaction} =
  topUpSlice.actions;

export default topUpSlice.reducer;
