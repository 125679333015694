import React, {FC, ReactNode} from 'react';

import {IconProvider} from '@/components/Icon/Icon';
import {useShare} from '@/hooks/useShare';
import {IShareProperties} from '@/types/common';

interface IProps {
  children: ({
    onPress,
    icon,
    textId,
  }: {
    onPress: () => Promise<void>;
    icon: {
      name: string;
      provider?: IconProvider;
    };
    textId: string;
  }) => ReactNode;
}

const Share: FC<IProps & IShareProperties> = ({children, ...shareOptions}) => {
  const {share} = useShare();

  return (
    <>
      {children({
        onPress: async () => {
          await share(shareOptions);
        },
        icon: {
          name: 'share',
          provider: 'custom',
        },
        textId: 'share',
      })}
    </>
  );
};

export default Share;
