import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {FC} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';

import {linkAddressToUser} from '@/api/user';
import {FadeInOut} from '@/components/AnimationWrappers';
import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon/Icon';
import Loader from '@/components/Loader';
import Modal, {IModalProps} from '@/components/Modal';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppSelector} from '@/hooks/useRedux';
import {
  isWebMetamask,
  useDisconnect,
  useExternalSigner,
} from '@/modules/ExternalWallet';
import {useToast} from '@/modules/Toasts';
import {useUserByAddressQuery} from '@/queries/user';
import {selectActiveUserSigner} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {QueryKeys} from '@/types/queryKeys';
import {IInternalSigner} from '@/types/session';
import {prettifyAddress, shortenAddress} from '@/utils/ethereum';
import {getUserDisplayName, isAddressOwnedByUser} from '@/utils/user';

import {styles} from './AddExternalWalletModal.style';

interface IProps extends IModalProps {
  onSuccess?: (address: string) => void;
}

const AddExternalWalletModal: FC<IProps> = ({onSuccess, ...modalProps}) => {
  const style = useThemedStyles(styles);
  const {showToast} = useToast();
  const queryClient = useQueryClient();

  const activeUser = useActiveUser();

  const {address, connect, signMessage, connector} = useExternalSigner();
  const {disconnect, isDisconnecting} = useDisconnect();
  const isMetaMask = isWebMetamask(connector);
  const isLinked =
    !!address && !!activeUser && isAddressOwnedByUser(activeUser, address);

  const userSigner = useAppSelector(selectActiveUserSigner);

  const {user: returningUser, query: returningUserQuery} =
    useUserByAddressQuery(address);

  const linkSignerMutation = useMutation({
    mutationFn: async () => {
      if (!activeUser || !address) {
        throw new Error(
          'user or address missing during adding new external wallet',
        );
      }

      await linkAddressToUser({
        userId: activeUser.id,
        signerToLink: {
          address,
          signMessage: msg => signMessage(msg),
        },
        authSigner: userSigner as IInternalSigner,
        addressProperties: {
          isPublic: true,
          isWallet: true,
          isSession: false,
          isPasskey: false,
        },
      });
      await queryClient.invalidateQueries({queryKey: [QueryKeys.userProfiles]});
      modalProps.onClose();
      onSuccess?.(address);
    },
    onError: () => {
      showToast({textId: 'error.tryAgain'});
    },
  });

  const isDisconnectDisabled = linkSignerMutation.isPending || isDisconnecting;

  const renderContent = () => {
    if (!address) {
      return (
        <>
          <Text id="wallets.addWallet.connect.description" />
          <CardButton
            onPress={connect}
            text={{id: 'wallets.addWallet.connect'}}
          />
        </>
      );
    }

    // Some parts of this component's code is very similar to LinkWallet.tsx
    // We could abstract some of it and share but it might get more difference in the future.
    if (isLinked) {
      return (
        <>
          <Text
            size="xs"
            align="center"
            id={
              isMetaMask
                ? 'login.linkWallet.alreadyLinked_metamask1'
                : 'login.linkWallet.alreadyLinked'
            }
            values={{
              address: (
                <Text size="s" weight="semibold">
                  {address && shortenAddress(address)}
                </Text>
              ),
            }}
          />
          {isMetaMask && (
            <Text
              size="xs"
              align="center"
              id="login.linkWallet.alreadyLinked_metamask2"
            />
          )}
          {!isMetaMask && (
            <CardButton
              onPress={disconnect}
              isLoading={isDisconnecting}
              text={{id: 'disconnect'}}
              loaderProps={{
                textId: 'disconnect',
                size: 'xxs',
              }}
            />
          )}
        </>
      );
    }

    if (returningUserQuery.isLoading) {
      return (
        <View style={style.loaderWrapper}>
          <Loader />
        </View>
      );
    }

    return (
      <>
        <Text weight="semibold" align="center" size="m">
          {prettifyAddress(address)}
        </Text>
        <Text id="wallets.addWallet.verify.description" />
        {returningUser && (
          <Space style={style.warningContainer}>
            <View style={style.warningHeader}>
              <Icon name="warning" provider="custom" size={22} />
              <Text id="warning" size="s" weight="semibold" />
            </View>
            <Text
              size="xxs"
              id="wallets.addWallet.alreadyLinkedWarning1"
              values={{
                returningUser: (
                  <Text weight="semibold" size="xxs">
                    {getUserDisplayName(returningUser)}
                  </Text>
                ),
                user: (
                  <Text weight="semibold" size="xxs">
                    {activeUser && getUserDisplayName(activeUser)}
                  </Text>
                ),
              }}
            />
            <Text
              size="xs"
              id="wallets.addWallet.alreadyLinkedWarning2"
              weight="semibold"
              align="center"
              values={{
                user: (
                  <Text weight="semibold" size="xs" align="center">
                    {getUserDisplayName(returningUser)}
                  </Text>
                ),
              }}
            />
          </Space>
        )}
        <CardButton
          onPress={linkSignerMutation.mutate}
          isLoading={linkSignerMutation.isPending}
          text={{id: 'wallets.addWallet.verify'}}
          loaderProps={{textId: 'wallets.addWallet.verifying'}}
        />

        {isMetaMask ? (
          <Text size="xxs" id="login.linkWallet.disclaimer_metamask" />
        ) : (
          <FadeInOut enabled={isDisconnecting}>
            <TouchableOpacity
              activeOpacity={0.8}
              disabled={isDisconnectDisabled}
              onPress={() => disconnect()}>
              <Text
                size="xxs"
                id="login.linkWallet.disclaimer"
                values={{
                  disconnect: (
                    <Text
                      id="login.linkWallet.alreadyLinked.disconnect"
                      size="xxs"
                      weight="semibold"
                      underline
                    />
                  ),
                }}
              />
            </TouchableOpacity>
          </FadeInOut>
        )}
      </>
    );
  };

  return (
    <Modal {...modalProps} titleId="wallets.addWallet.title">
      <ScrollView
        showsVerticalScrollIndicator={false}
        bounces={false}
        contentContainerStyle={style.content}>
        {renderContent()}
      </ScrollView>
    </Modal>
  );
};

export default AddExternalWalletModal;
