import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    height: 'auto',
  },
}));
