import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const TRACK_ITEM_HEIGHT = 36;

export const styles = createThemedStyles(theme => ({
  track: {
    paddingHorizontal: spacing.xs,
    height: TRACK_ITEM_HEIGHT,
  },
  trackContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs * 1.5,
  },
  trackBackground: {
    ...StyleSheet.absoluteFillObject,
  },
  trackBackground_active: {
    backgroundColor: theme.colors.invertedTextColor,
    opacity: 0.3,
  },
  trackBackground_hover: {
    backgroundColor: theme.colors.invertedTextColor,
    opacity: 0.2,
  },
  track_pressed: {
    opacity: 0.6,
  },
}));
