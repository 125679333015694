import React, {FC, ReactNode} from 'react';
import {FlatList, View, ViewStyle} from 'react-native';

import Pill from '@/components/Pill';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {INftEventFilter, NftEventType} from '@/types/nfts';

import {styles} from './NftEventsFilters.style';

interface IProps {
  filters: INftEventFilter[];
  onFilterPress: (type: NftEventType) => void;
  activeFilter?: NftEventType;
  style?: ViewStyle;
  contentContainerStyle?: ViewStyle;
}

const CellRenderer: FC<{children: ReactNode}> = ({children}) => (
  <View style={{flexGrow: 1, marginHorizontal: spacing.xxs}}>{children}</View>
);

const NftEventsFilters: FC<IProps> = ({
  filters,
  onFilterPress,
  activeFilter,
  style: overrideStyle,
  contentContainerStyle,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={[style.container, overrideStyle]}>
      <FlatList
        horizontal
        bounces={false}
        hitSlop={{left: -spacing.s}}
        data={filters}
        contentContainerStyle={[style.content, contentContainerStyle]}
        CellRendererComponent={CellRenderer}
        renderItem={({item: filter}) => (
          <Pill
            onPress={() => onFilterPress(filter.type)}
            text={{id: filter.textId}}
            isSelected={activeFilter === filter.type}
            style={style.pill}
          />
        )}
        keyExtractor={filter => filter.type}
        showsHorizontalScrollIndicator={false}
      />
    </View>
  );
};

export default NftEventsFilters;
