import React, {useState} from 'react';
import {RefreshControl} from 'react-native';

import Loader from '@/components/Loader/Loader';
import Space from '@/components/Space/Space';
import {useTheme} from '@/theme';

import {IInfinityListLoaderProps} from './types';

export const useInfinityList = ({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  refresh,
}: IInfinityListLoaderProps) => {
  const theme = useTheme();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onEndReached = () => {
    if (fetchNextPage && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  };

  const onRefresh = async () => {
    if (refresh && !isRefreshing) {
      try {
        setIsRefreshing(true);
        await refresh();
      } catch (error) {
      } finally {
        setIsRefreshing(false);
      }
    }
  };

  const FooterLoader = isFetchingNextPage ? (
    <Space pv="s">
      <Loader />
    </Space>
  ) : null;

  const refreshControl = refresh && (
    <RefreshControl
      style={{zIndex: 1}}
      refreshing={isRefreshing}
      onRefresh={onRefresh}
      tintColor={theme.colors.primaryLight}
      colors={[theme.colors.textColor]}
      progressBackgroundColor={theme.colors.background}
    />
  );

  return {
    onEndReached,
    FooterLoader,
    refreshControl,
  };
};
