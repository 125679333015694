import React, {FC, useMemo} from 'react';

import AvatarCard from '@/components/AvatarCard/AvatarCard';
import {useUserLink} from '@/hooks/useUserLink';
import {IPlaylist} from '@/types/playlists';
import {IBaseUser} from '@/types/user';

interface IProps {
  user: IBaseUser;
  contributions: IPlaylist['contributions'];
}

const CuratorCard: FC<IProps> = ({user, contributions = {}}) => {
  const {navigateToUser} = useUserLink();

  const addedSongsCount = useMemo(
    () =>
      Object.values(contributions).reduce((sum, current) => {
        if (current.userId === user.id) {
          return sum + 1;
        }
        return sum;
      }, 0),
    [user.id, contributions],
  );

  return (
    <AvatarCard
      user={user}
      onPress={() => {
        navigateToUser(user);
      }}
      description={
        addedSongsCount > 0
          ? {
              id: 'curators.songsAdded',
              values: {count: addedSongsCount},
            }
          : undefined
      }
    />
  );
};

export default React.memo(CuratorCard);
