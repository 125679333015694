import {useNavigation} from '@react-navigation/native';
import React, {createContext, FC, useMemo, useState} from 'react';
import {ScrollView, TouchableWithoutFeedback, View} from 'react-native';

import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import {IPasskeyCredential} from '@/types/user';

import {LoginRouter} from './LoginRouter';

type LoginRoute = 'initial' | 'signUp' | 'linkPasskey' | 'linkWallet';

interface ILoginError {
  messageId: string;
  error?: any;
}

interface ILoginStateContext {
  loginRoute: LoginRoute;
  setLoginRoute: (state: LoginRoute) => void;
  closeLoginModal: () => void;
  passkeyCredential: IPasskeyCredential | null;
  setPasskeyCredential: (credential: IPasskeyCredential | null) => void;
  loginError: ILoginError | null;
  setLoginError: (error: ILoginError | null) => void;
}

export const LoginStateContext = createContext<ILoginStateContext | undefined>(
  undefined,
);

export const LoginScreen: FC = () => {
  const [loginRoute, setLoginRoute] = useState<LoginRoute>('initial');
  const [passkeyCredential, setPasskeyCredential] =
    useState<IPasskeyCredential | null>(null);
  const [loginError, setLoginError] = useState<ILoginError | null>(null);
  const navigation = useNavigation();

  const contextValue = useMemo(
    () => ({
      loginRoute,
      setLoginRoute,
      passkeyCredential,
      setPasskeyCredential,
      closeLoginModal: navigation.goBack,
      loginError,
      setLoginError,
    }),
    [
      loginRoute,
      setLoginRoute,
      passkeyCredential,
      setPasskeyCredential,
      loginError,
      setLoginError,
    ],
  );

  return (
    <LoginStateContext.Provider value={contextValue}>
      <ModalScreenWrapper titleId="login.title" avoidKeyboard>
        <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
          <TouchableWithoutFeedback>
            <View>
              <LoginRouter />
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
      </ModalScreenWrapper>
    </LoginStateContext.Provider>
  );
};
