import React, {FC, useEffect, useState} from 'react';
import {StyleProp, View} from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withSequence,
  withTiming,
} from 'react-native-reanimated';

import Text, {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles, DIVIDER_WIDTH} from './SlidingText.style';

interface IProps extends TextProps {
  containerStyle?: StyleProp<any>;
}

const SlidingText: FC<IProps> = ({
  containerStyle,
  style: textStyle,
  ...textProps
}) => {
  const style = useThemedStyles(styles);
  const translateX = useSharedValue(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [textWidth, setTextWidth] = useState(0);

  const startSliding = () => {
    translateX.value = 0;

    if (containerWidth && textWidth && textWidth > containerWidth) {
      const distance = textWidth + DIVIDER_WIDTH;
      const config = {
        // 30 pixels per second
        duration: (distance * 1000) / 30,
        easing: Easing.linear,
      };

      translateX.value = withRepeat(
        withSequence(
          withDelay(1500, withTiming(-distance, config)),
          withDelay(1500, withTiming(0, {duration: 0})),
        ),
        -1,
      );
    }
  };

  const slidingTextStyle = useAnimatedStyle(() => ({
    transform: [{translateX: translateX.value}],
  }));

  const textCopyStyle = useAnimatedStyle(() => ({
    display: translateX.value === 0 ? 'none' : 'flex',
  }));

  useEffect(() => {
    if (containerWidth && textWidth) {
      startSliding();
    }
  }, [containerWidth, textWidth]);

  useEffect(() => {
    // reset animation when text content changes
    translateX.value = 0;
  }, [textProps.children, textProps.id]);

  return (
    <View
      style={[containerStyle, style.container]}
      onLayout={event => {
        if (event.nativeEvent.layout.width !== containerWidth) {
          setContainerWidth(event.nativeEvent.layout.width);
        }
      }}>
      <Animated.View style={[style.slidingTextContainer, slidingTextStyle]}>
        <Text
          onLayout={event => {
            if (event.nativeEvent.layout.width !== textWidth) {
              setTextWidth(event.nativeEvent.layout.width);
            }
          }}
          style={textStyle}
          {...textProps}
        />
        <Animated.View style={[style.textCopy, textCopyStyle]}>
          <Text style={textStyle} {...textProps} />
        </Animated.View>
      </Animated.View>
      <Text
        {...textProps}
        style={[textStyle, style.textPlaceholder]}
        numberOfLines={1}
      />
    </View>
  );
};

export default SlidingText;
