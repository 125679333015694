import React, {FC} from 'react';
import {View} from 'react-native';

import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {prettifyAddress} from '@/utils/ethereum';

import {styles} from './SenderWalletInfo.style';

interface IProps {
  senderWallet: IAddress | undefined;
  from: string;
}

const SenderWalletInfo: FC<IProps> = ({senderWallet, from}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <Text id="transfer.sendFrom" size="xs" align="center" />
      {senderWallet?.isPasskey ? (
        <Text size="xs">Passkey Wallet ({prettifyAddress(from)})</Text>
      ) : senderWallet?.ens ? (
        <Text size="xs">
          {senderWallet.ens} ({prettifyAddress(from)})
        </Text>
      ) : (
        <Text size="xs">{prettifyAddress(from)}</Text>
      )}
    </View>
  );
};

export default SenderWalletInfo;
