import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  addSpinampWalletContainer: {
    alignItems: 'center',
    gap: spacing.s,
    padding: spacing.m,
    backgroundColor: theme.colors.background,
    borderRadius: theme.box.borderRadius,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
  },
  spinampWalletIcon: {
    alignSelf: 'center',
  },
}));
