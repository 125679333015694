import {INPUT_HEIGHT, INPUT_PADDING} from '@/components/Input';
import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const ITEM_HEIGHT = spacing.s * 2;
export const DROPDOWN_HEIGHT_MOBILE = spacing.xs + ITEM_HEIGHT * 4.5;
const DROPDOWN_HEIGHT_DESKTOP = spacing.xs + ITEM_HEIGHT * 6.5;

export const styles = createThemedStyles((theme, responsive) => ({
  container: {
    zIndex: 1,
  },
  input: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
    paddingHorizontal: theme.box.cardHorizontalPadding,
    paddingVertical: INPUT_PADDING,
    minHeight: INPUT_HEIGHT,
    gap: spacing.xs,
  },
  arrowIcon: {
    alignSelf: 'flex-end',
  },
  placeholder: {
    opacity: 0.4,
  },
  dropdown: {
    zIndex: 1000,
    position: 'absolute',
    left: 0,
    right: 0,
    top: INPUT_HEIGHT + spacing.xxs,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    maxHeight: responsive.isMobile
      ? DROPDOWN_HEIGHT_MOBILE
      : DROPDOWN_HEIGHT_DESKTOP,

    shadowColor: theme.colors.textColor,
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 20,
  },
  dropdownContent: {
    paddingVertical: spacing.xxs,
  },
  item: {
    borderWidth: 0,
    height: ITEM_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
    paddingHorizontal: spacing.xs * 1.5,
  },
  empty: {
    paddingVertical: spacing.s,
  },
}));
