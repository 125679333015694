import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: spacing.xxs / 2,
  },
}));
