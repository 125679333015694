import React, {FC} from 'react';

import Text from '@/components/Text/Text';
import Touchable from '@/components/Touchable/Touchable';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';

import {styles} from './MenuItem.style';

export const MenuItemSecondary: FC<{
  onPress: () => void;
  title?: string;
  titleId?: string;
  isActive?: boolean;
}> = ({onPress, title, titleId, isActive}) => {
  const style = useThemedStyles(styles);
  const {isSmallDesktop} = useResponsive();

  if (isSmallDesktop) {
    return null;
  }

  return (
    <Touchable
      onPress={onPress}
      style={[style.secondLevelItem, style.item, isActive && style.item_active]}
      hoveredStyle={style.item_hover}
      pressedStyle={style.item_pressed}>
      <Text
        id={titleId}
        size="s"
        weight={isActive ? 'semibold' : 'regular'}
        numberOfLines={1}>
        {title}
      </Text>
    </Touchable>
  );
};
