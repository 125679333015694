import {useLoginState} from '@/modules/Login';
import {parsePasskeyError, PasskeyError} from '@/services/passkey';
import {Sentry} from '@/services/sentry';

export const useLoginError = () => {
  const {loginError, setLoginError, setLoginRoute} = useLoginState();

  return {
    loginError,
    setLoginError,
    handleLoginError: (error?: any) => {
      const errorMessageId = parsePasskeyError(error);

      if (!errorMessageId) {
        return;
      }

      Sentry.captureException(error, {
        tags: {
          login: true,
          passkey: error instanceof PasskeyError,
        },
      });

      setLoginError({
        messageId: errorMessageId,
        error,
      });
    },
    resetLoginError: () => {
      if (loginError) {
        setLoginError(null);

        if (
          loginError.error instanceof PasskeyError &&
          loginError.error.status === 'SAVE_BLOB_ERROR'
        ) {
          setLoginRoute('initial');
        }
      }
    },
  };
};
