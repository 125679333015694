import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text';
import {getHitSlop} from '@/constants/spacing';
import {useToast} from '@/modules/Toasts';
import {Clipboard} from '@/services/clipboard';
import {useThemedStyles} from '@/theme';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {shortenAddress} from '@/utils/ethereum';

import {styles} from './Address.style';

export interface IAddressProps extends TextProps {
  address: string;
  ens?: string | null;
  iconSize?: number;
  displayInfo?: 'ens' | 'address';
  showCopyToast?: boolean;
  linkEnabled?: boolean;
}

const Address: FC<IAddressProps> = ({
  address,
  ens,
  iconSize = 20,
  displayInfo = 'ens',
  showCopyToast = true,
  linkEnabled = false,
  ...textProps
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();
  const {showToast} = useToast();
  const displayName =
    ens && displayInfo === 'ens' ? ens : shortenAddress(address);

  const openCollector = () => {
    navigation.push(Routes.Collector, {
      slug: ens || address,
    });
  };

  const onCopy = async () => {
    try {
      await Clipboard.copy(address);
      if (showCopyToast) {
        showToast({textId: 'address.copied'});
      }
    } catch (error) {}
  };

  return (
    <View style={style.container}>
      {linkEnabled ? (
        <TouchableOpacity onPress={openCollector}>
          <Text underline {...textProps}>
            {displayName}
          </Text>
        </TouchableOpacity>
      ) : (
        <Text {...textProps}>{displayName}</Text>
      )}
      <Space ml="xs" />
      <TouchableOpacity
        style={style.container}
        onPress={onCopy}
        hitSlop={getHitSlop('xs')}>
        <Icon name="copy" provider="custom" size={iconSize} />
      </TouchableOpacity>
    </View>
  );
};

export default Address;
