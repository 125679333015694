import React, {FC} from 'react';
import {View} from 'react-native';

import tipsEmptyImage from '@/assets/images/tips-empty.gif';
import SocialShareButtons from '@/components/SocialShareButtons';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {tipChain} from '@/modules/Tip/constants';
import {useInviteToTip} from '@/modules/Tip/useInviteToTip';
import {useThemedStyles} from '@/theme';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './TipsScreenEmpty.style';

interface IProps {
  artistSlug: string;
}

const TipsScreenEmpty: FC<IProps> = ({artistSlug}) => {
  const style = useThemedStyles(styles);

  const shareActions = useInviteToTip(artistSlug);

  return (
    <View style={style.container}>
      <View style={style.balance}>
        <Text id="tipsSettings.summary.total" align="center" />
        <Text weight="semibold" size="xl" align="center">
          {formatPrice('0', tipChain.chain.nativeCurrency)}
        </Text>
      </View>

      <StaticImage source={tipsEmptyImage} style={style.image} />

      <Text id="tipsSettings.empty.message" align="center" />

      <SocialShareButtons {...shareActions} />
    </View>
  );
};

export default TipsScreenEmpty;
