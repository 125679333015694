import {useMemo} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {useNotificationsPermissionQuery} from '@/modules/Notifications/pushNotifications';
import {isGenreChannel} from '@/screens/Feed/utils';
import {selectActiveUserId, selectOnboardingInfo} from '@/store/user';
import {IFeedItem, IFeedEntityType, ILocalMessageFeedItem} from '@/types/feed';
import {isNative} from '@/utils/platform';

const spyWelcome: ILocalMessageFeedItem = {
  id: 'spyWelcome',
  localMessageId: 'spyWelcome',
  entityType: IFeedEntityType.localMessage,
};

const emptySpyFeed: ILocalMessageFeedItem = {
  id: 'emptySpyFeed',
  localMessageId: 'emptySpyFeed',
  entityType: IFeedEntityType.localMessage,
};

const notificationsOnboarding: ILocalMessageFeedItem = {
  id: 'notificationsOnboarding',
  localMessageId: 'notificationsOnboarding',
  entityType: IFeedEntityType.localMessage,
};

const NOTIFICATION_CARD_POSITION = 10;

export const useFeedItemsWithArtificialCards = (
  feedItems: (IFeedItem | ILocalMessageFeedItem)[] | null,
  userId: string,
) => {
  const {showFeedOnboardingSpyWelcome, showFeedOnboardingNotifications} =
    useAppSelector(selectOnboardingInfo);
  const activeUserId = useAppSelector(selectActiveUserId);

  const {areNotificationsEnabled} = useNotificationsPermissionQuery();

  return useMemo(() => {
    if (!feedItems) {
      return null;
    }
    const feedItemsWithArtificialCards = [...feedItems];

    if (userId === activeUserId) {
      if (
        isNative &&
        showFeedOnboardingNotifications &&
        !areNotificationsEnabled &&
        feedItemsWithArtificialCards.length > NOTIFICATION_CARD_POSITION
      ) {
        feedItemsWithArtificialCards.splice(
          NOTIFICATION_CARD_POSITION - 1,
          0,
          notificationsOnboarding,
        );
      }
    } else if (showFeedOnboardingSpyWelcome && !isGenreChannel(userId)) {
      feedItemsWithArtificialCards.unshift(spyWelcome);
    } else if (feedItemsWithArtificialCards.length === 0) {
      feedItemsWithArtificialCards.unshift(emptySpyFeed);
    }

    return feedItemsWithArtificialCards;
  }, [
    feedItems,
    showFeedOnboardingSpyWelcome,
    showFeedOnboardingNotifications,
    areNotificationsEnabled,
    activeUserId,
  ]);
};
