import {useContext} from 'react';

import {TipStateContext} from '@/modules/Tip/SendTip/SendTipProvider';

export const useSendTipState = () => {
  const context = useContext(TipStateContext);

  if (!context) {
    throw new Error('useTipState must be used inside provider');
  }

  return context;
};
