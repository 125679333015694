export interface IPushNotification {
  title: string;
  body: string;
  data?: {
    [key: string]: string | number | object;
  };
}

export type NotificationEntityType =
  | 'new-track'
  | 'track-release'
  | 'artist-tipped'
  | 'nft-collected';

export interface ISpindexerNotification {
  id: string;
  userId: string;
  entityType: NotificationEntityType;
  entityId: string; // track id, app version, collector id, etc.
  status: NotificationStatus;
  deliveryMethods: string;
  timeToNotify: string;
  title: string;
  body: string;
  link: string;
  metadata?: any;
}

export enum DeliveryMethod {
  'inApp' = 'inApp',
  'push' = 'push',
}

export enum NotificationStatus {
  'read' = 'read',
  'unsent' = 'unsent',
  'sent' = 'sent',
  'failed' = 'failed',
}
