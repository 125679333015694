import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  listContent: {
    backgroundColor: theme.colors.backgroundLight,
    padding: spacing.xs,
  },
  playlist: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: spacing.xxl,
  },
  newPlaylistRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  list: {
    gap: spacing.xs,
    paddingBottom: spacing.xs,
  },
}));
