import React, {forwardRef} from 'react';
import {View} from 'react-native';
import Animated from 'react-native-reanimated';

import {useThemedStyles} from '@/theme';

import {styles} from './BaseFeedCard.styles';

interface IBaseFeedCardProps {
  children?: React.ReactNode;
}

const BaseFeedCard = forwardRef<Animated.View, IBaseFeedCardProps>(
  ({children}, ref) => {
    const style = useThemedStyles(styles);

    return (
      <Animated.View style={style.container} ref={ref}>
        <View style={style.flexContainer}>{children}</View>
      </Animated.View>
    );
  },
);

export default BaseFeedCard;
