import React from 'react';
import {StyleProp, View} from 'react-native';

import styles from './Space.style';

export type SizeValues = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export interface ISpaceProps {
  w?: SizeValues | number;
  h?: SizeValues | number;
  fw?: boolean;
  fh?: boolean;
  flex?: boolean;
  style?: StyleProp<any>;
  children?: React.ReactNode;
  m?: SizeValues;
  mv?: SizeValues;
  mh?: SizeValues;
  mt?: SizeValues;
  mr?: SizeValues;
  mb?: SizeValues;
  ml?: SizeValues;
  p?: SizeValues;
  pv?: SizeValues;
  ph?: SizeValues;
  pt?: SizeValues;
  pr?: SizeValues;
  pb?: SizeValues;
  pl?: SizeValues;
}

// m, mv, mh, mt, mr, mb, ml, p, pv, ph, pt, pr, pb, pl
const Space: React.FC<ISpaceProps> = ({
  children,
  fw,
  fh,
  flex,
  style,
  ...sizesProps
}) => {
  const spaceStyle = Object.entries(sizesProps).reduce(
    // @ts-ignore
    (result, [key, value]) => [...result, value && styles[`${key}_${value}`]],
    [] as any,
  );

  return (
    <View
      style={[
        spaceStyle,
        fw && styles.fw,
        fh && styles.fh,
        flex && styles.flex,
        style,
      ]}>
      {children}
    </View>
  );
};

export default React.memo(Space);
