import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  leftButton: {
    marginLeft: -spacing.xs,
  },
  rightButton: {
    marginRight: -spacing.xs,
  },
  suggesterInfo: {
    marginTop: spacing.xxs,
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    width: spacing.l + spacing.xs,
  },
}));
