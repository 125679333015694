import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme/createThemedStyles';

export const AVATAR_SIZE = spacing.l + spacing.xxs;
export const ACTIONS_MIN_WIDTH = AVATAR_SIZE;
export const HEADER_PADDING = spacing.xs;
const HEADER_HEIGHT = spacing.xxl;
const TITLE_PADDING = spacing.xs;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    container: {
      zIndex: 2,
    },
    container_modal: {
      paddingTop: spacing.xxs,
    },
    safeArea: {
      paddingTop: safeAreaInsets.top,
    },
    headerMask: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderColor: theme.colors.borderColor,
      borderBottomWidth: 2,
      backgroundColor: theme.background?.image
        ? 'transparent'
        : theme.colors.background,
    },
    headerWrapper: {
      backgroundColor: 'transparent',
    },
    header: {
      backgroundColor: 'transparent',
      paddingHorizontal: HEADER_PADDING,
      height: HEADER_HEIGHT,
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      paddingHorizontal: TITLE_PADDING,
    },
    actionsContainer: {
      flexDirection: 'row',
      minWidth: ACTIONS_MIN_WIDTH,
      gap: spacing.xs + spacing.xxs,
    },
  }),
);
