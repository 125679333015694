import {useIsFocused, useScrollToTop} from '@react-navigation/native';
import React, {useRef} from 'react';

import FeedInfoModal from '@/components/FeedInfoModal';
import Header from '@/components/Header/Header';
import InfinityList from '@/components/InfinityList';
import LineSeparator, {LINE_SEPARATOR_HEIGHT} from '@/components/LineSeparator';
import {GlobalNftEvent, GLOBAL_NFT_EVENT_HEIGHT} from '@/components/NftEvent';
import NftEventsFilters, {
  useNftEventsFilters,
} from '@/components/NftEventsFilters';
import Screen from '@/components/Screen/Screen';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAllActivityQuery} from '@/queries/nftActivity';
import {useThemedStyles} from '@/theme';

import {styles} from './ActivityFeed.style';

const ActivityFeed = () => {
  const isFocused = useIsFocused();
  const listRef = useRef<null>(null);
  const style = useThemedStyles(styles);
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const filters = useNftEventsFilters();
  const {nftEvents, query: eventsQuery} = useAllActivityQuery(
    filters.activeFilter,
    isFocused,
  );

  useScrollToTop(listRef);

  return (
    <Screen>
      <Header
        titleId="activityFeed.title"
        showBack
        icon={{name: 'trending-up', provider: 'material'}}
        scrollPosition={scrollPosition}
        mockTitle
      />
      <InfinityList
        ref={listRef}
        itemSize={GLOBAL_NFT_EVENT_HEIGHT}
        itemSpacing={LINE_SEPARATOR_HEIGHT}
        data={nftEvents}
        keyExtractor={event => `event_${event.id}`}
        refresh={eventsQuery.refetch}
        renderItem={({item: event}) => <GlobalNftEvent event={event} />}
        fetchNextPage={eventsQuery.fetchNextPage}
        hasNextPage={eventsQuery.hasNextPage}
        onScroll={onScroll}
        ListHeaderComponent={
          <NftEventsFilters
            {...filters}
            style={style.filtersContainer}
            contentContainerStyle={style.filters}
          />
        }
        isFetchingNextPage={
          eventsQuery.isFetchingNextPage || eventsQuery.isLoading
        }
        ItemSeparatorComponent={LineSeparator}
      />
      <FeedInfoModal />
    </Screen>
  );
};

export default ActivityFeed;
