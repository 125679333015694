import React from 'react';

import Icon from '@/components/Icon';
import Link from '@/components/Link';
import {CrossPlatformStyle} from '@/components/Link/shared';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {IMessageFeedItem} from '@/types/feed';

import BaseFeedCard from './BaseFeedCard';
import {styles} from './common.styles';

interface IProps {
  feedItem: IMessageFeedItem;
}

const MessageFeedCard: React.FC<IProps> = ({feedItem}) => {
  const style = useThemedStyles(styles);

  return (
    <BaseFeedCard>
      <Space p="s" style={style.featureContainer}>
        <Icon
          style={style.featureIcon}
          size={48}
          {...feedItem.message.iconProps}
        />
        <Space pb="m" />

        <Text weight="bold" style={style.onboardingText}>
          {feedItem.message.title}
        </Text>
        <Space mb="xxs" />
        <Text style={style.onboardingText}>{feedItem.message.body}</Text>
        <Space mb="s" />
        <Link
          style={style.actionLink as CrossPlatformStyle}
          to={feedItem.message.actionLink}>
          {({hover}) => (
            <Text underline={hover}>{feedItem.message.actionText}</Text>
          )}
        </Link>
      </Space>
    </BaseFeedCard>
  );
};

export default MessageFeedCard;
