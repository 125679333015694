import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContainer: {
    flex: 1,
  },
  scrollContent: {
    flexGrow: 1,
    padding: spacing.m,
  },
  header: {
    gap: spacing.l,
  },
  sectionTitle: {
    marginBottom: spacing.xs,
  },
  balanceCards: {
    gap: spacing.xs * 1.5,
  },
}));
