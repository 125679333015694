import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const PAGE_PADDING = spacing.s;

export const styles = createThemedStyles(theme => ({
  searchHistoryHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchHistoryItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: -PAGE_PADDING,
    paddingLeft: PAGE_PADDING,
  },
  searchHistoryItem_hover: {
    backgroundColor: theme.colors.background,
  },
  searchHistoryItem_pressed: {
    backgroundColor: theme.colors.backgroundDark,
  },
  searchHistoryPhrase: {
    flex: 1,
  },
  searchRemoveAction: {
    alignSelf: 'stretch',
    width: spacing.xl,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: spacing.xs,
  },
}));
