import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon';
import Modal, {IModalProps} from '@/components/Modal';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {addTracksToQueue, playNewQueue, selectQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {PlayContextType} from '@/types/common';
import {IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import {mapAllFeedItemsToQueue} from '@/utils/feed';

import {styles} from './PlayAllModal.style';

interface IProps extends IModalProps {
  feedItems: (IFeedItem | ILocalMessageFeedItem)[];
}

const PlayAllModal: FC<IProps> = ({feedItems, ...modalProps}) => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const {showToast} = useToast();
  const queue = useAppSelector(selectQueue);

  const replaceQueue = (autoPlay = false) => {
    const trackIds = mapAllFeedItemsToQueue(feedItems);

    if (trackIds.length === 0) {
      showToast({textId: 'feed.playAll.empty'});
      return;
    }

    dispatch(
      playNewQueue({
        trackIds: mapAllFeedItemsToQueue(feedItems).map(i => i.trackId),
        context: {
          titleId: 'channels.title',
          type: PlayContextType.feed,
          source: 'Feed',
        },
        autoPlay,
      }),
    );
  };

  const addToCurrentQueue = () => {
    if (queue) {
      dispatch(
        addTracksToQueue({
          items: mapAllFeedItemsToQueue(feedItems),
        }),
      );
    } else {
      replaceQueue(false);
    }
  };

  return (
    <Modal titleId="feed.playAll.title" {...modalProps}>
      <View style={style.content}>
        <Icon provider="custom" size={64} name="playAll" style={style.icon} />
        <View style={style.textsContainer}>
          <Text
            id="feed.playAll.header"
            align="center"
            size="l"
            weight="semibold"
          />
          <Text id="feed.playAll.descriptions" align="center" />
        </View>
        <CardButton
          onPress={() => {
            modalProps.onClose();
            addToCurrentQueue();
          }}
          text={{id: 'feed.playAll.addToQueue'}}
        />
        <CardButton
          onPress={() => {
            modalProps.onClose();
            replaceQueue(true);
          }}
          text={{id: 'feed.playAll.replaceQueue'}}
        />
        <TransparentButton
          onPress={modalProps.onClose}
          text={{id: 'feed.playAll.keepSelecting'}}
        />
      </View>
    </Modal>
  );
};

export default PlayAllModal;
