import {useNavigation} from '@react-navigation/native';
import React, {useCallback, useRef} from 'react';
import {View} from 'react-native';

import {IIconProps} from '@/components/Icon';
import {IconProvider} from '@/components/Icon/Icon';
import HotkeyButton from '@/components/Key/HotkeyButton';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useHotkeys} from '@/hooks/useHotkeys';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useFeedItemMutation} from '@/queries/feed';
import {useFeedItemWithQueue} from '@/screens/Feed/components/FeedCard/useFeedItemWithQueue';
import {
  pause as pauseFeed,
  play as playFeed,
  selectShouldPlay as selectShouldFeedPlay,
} from '@/store/feed';
import {
  pause as pauseMainPlayer,
  resume as playMainPlayer,
  selectCurrentTrack,
  selectIsPlaying,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IFeedEntityType} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {identity, isNotNil, noop} from '@/utils/functions';

import {styles} from './FeedHotkeys.styles';
import {type IHotkeyableFeedItem, type IProps} from './shared';

type HotkeyHandler = () => void;

interface IHotkeyConfig {
  key: string;
  keyText: string;
  labelTextId: string;
  labelIcon?: IIconProps;
  onPress: HotkeyHandler;
  disabled?: boolean;
  hidden?: boolean;
}

type GroupedHotkeyMap = readonly (
  | readonly (IHotkeyConfig | null)[]
  | (IHotkeyConfig | null)
)[];

const getActiveTrackId = (
  feedItem: IHotkeyableFeedItem,
  artistCardsTrackIndex: {
    [feedId: string]: number;
  },
) => {
  if (feedItem.entityType === IFeedEntityType.track) {
    return feedItem.track.id;
  }

  if (feedItem.entityType === IFeedEntityType.artist) {
    const activeIndex = artistCardsTrackIndex[feedItem.id] || 0;
    return feedItem.artist.tracks[activeIndex]?.id;
  }

  return undefined;
};

const FeedHotkeys: React.FC<IProps> = ({
  disabled,
  activeFeedItem,
  onMoveLeft = noop,
  onMoveRight = noop,
  isOwnFeed,
  artistCardsTrackIndex,
  changeArtistTrack,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const {mutate: upsertFeedItem} = useFeedItemMutation();
  const {unfollow} = useArtistFollow();

  const togglePlay = useTripleTogglePlay();

  const trackId = getActiveTrackId(activeFeedItem, artistCardsTrackIndex);
  const {isInQueue, toggleTrackInQueue, isCurrentlyPlaying} =
    useFeedItemWithQueue({
      feedItem: activeFeedItem,
      trackId,
    });

  const onArtistTrackChange =
    activeFeedItem.entityType !== IFeedEntityType.artist
      ? undefined
      : (direction: 1 | -1) => {
          const activeIndex = artistCardsTrackIndex[activeFeedItem.id] || 0;
          const tracksLength = activeFeedItem.artist.tracks.length;
          const nextIndex =
            (activeIndex + tracksLength + direction) % tracksLength;
          changeArtistTrack(activeFeedItem.id, nextIndex);
        };

  const onShowDetail = () => {
    if (activeFeedItem.entityType === 'track') {
      navigation.navigate(Routes.TrackModal, {slug: activeFeedItem.track.slug});
      return;
    }

    if (activeFeedItem.entityType === 'artist') {
      const activeIndex = artistCardsTrackIndex[activeFeedItem.id] || 0;
      const slug = activeFeedItem.artist.tracks[activeIndex]?.slug;

      if (slug) {
        navigation.navigate(Routes.TrackModal, {slug});
      } else {
        navigation.navigate(Routes.ArtistModal, {
          slugOrId: activeFeedItem.artist?.slug,
        });
      }
    }
  };

  const toggleHide = !isOwnFeed
    ? null
    : activeFeedItem.entityType === 'localMessage'
    ? null
    : activeFeedItem.entityType === 'artist'
    ? () => unfollow(activeFeedItem.artist.id)
    : () => upsertFeedItem({...activeFeedItem, userAction: 'hide'});

  // A group allows us to have one or more hotkeys that will always appear next to each other.
  // Hotkeys that aren't in a group can wrap between any hotkey.
  const groupedHotkeyMap: GroupedHotkeyMap = [
    [
      {
        key: 'ArrowLeft',
        keyText: '<',
        labelTextId: 'feed.previous',
        onPress: onMoveLeft,
      },
      {
        key: 'ArrowRight',
        keyText: '>',
        labelTextId: 'feed.next',
        onPress: onMoveRight,
      },
    ],
    toggleHide && {
      key: 'x',
      keyText: 'X',
      labelTextId: 'feed.hide',
      labelIcon: {
        name: 'visibilityOff',
        provider: 'custom' as IconProvider,
      },
      onPress: () => {
        toggleHide();
        analytics.feedItemReacted(
          activeFeedItem.id,
          'hide',
          activeFeedItem.entityType,
        );
      },
    },
    [IFeedEntityType.artist, IFeedEntityType.track].includes(
      activeFeedItem.entityType,
    )
      ? {
          key: 'i',
          keyText: 'I',
          labelTextId: 'feed.detail',
          labelIcon: {
            name: 'info',
            provider: 'custom' as IconProvider,
          },
          onPress: onShowDetail,
        }
      : null,
    trackId
      ? {
          key: 'c',
          keyText: 'C',
          labelTextId: 'feed.queue',
          labelIcon: {
            name: isInQueue ? 'check' : 'add',
            provider: 'custom' as IconProvider,
          },
          onPress: toggleTrackInQueue,
          disabled: isCurrentlyPlaying,
        }
      : null,
    {
      key: ' ',
      keyText: 'SPACE',
      labelTextId: 'play',
      labelIcon: {
        name: 'play',
        provider: 'custom' as IconProvider,
      },
      onPress: togglePlay,
    },
    onArtistTrackChange
      ? {
          hidden: true,
          key: 'ArrowUp',
          keyText: 'up',
          labelTextId: 'feed.previous',
          onPress: () => onArtistTrackChange(-1),
        }
      : null,
    onArtistTrackChange
      ? {
          hidden: true,
          key: 'ArrowDown',
          keyText: 'down',
          labelTextId: 'feed.next',
          onPress: () => onArtistTrackChange(1),
        }
      : null,
  ];

  const flattenedHotkeyMap = groupedHotkeyMap
    .flatMap(identity)
    .filter(isNotNil);

  useHotkeys(!disabled, flattenedHotkeyMap, true);

  return (
    <View style={style.container}>
      {groupedHotkeyMap.filter(isNotNil).map((hotkeyOrGroup, index) => {
        if ('length' in hotkeyOrGroup) {
          return (
            <View style={style.group} key={index}>
              {hotkeyOrGroup.filter(isNotNil).map((props, index2) => (
                <HotkeyButton {...props} key={index2} />
              ))}
            </View>
          );
        }

        return <HotkeyButton {...hotkeyOrGroup} />;
      })}
    </View>
  );
};

/**
 * Controls a triple-toggle for managing play/pause state on main player and feed player.
 *
 * With the triple toggle, the third time you press space it always pauses.
 *
 * If feed is not playing: (the first time plays feed, the second plays main player, the third pauses both)
 * If feed is playing: (the first time plays main player, the second plays feed, the third pauses both)
 *
 * Triple toggle behaviour is only enabled when the main player (in addition to the feed) has a track.
 */
const useTripleTogglePlay = () => {
  const isMainPlayerPlaying = useAppSelector(selectIsPlaying);
  const dispatch = useAppDispatch();
  const isFeedPlaying = useAppSelector(selectShouldFeedPlay);

  const doesMainPlayerHaveTrack = !!useAppSelector(selectCurrentTrack);

  const tripleToggleCount = useRef(0);

  const tripleTogglePlay = useCallback(() => {
    if (tripleToggleCount.current === 2) {
      dispatch(pauseMainPlayer());
      dispatch(pauseFeed());

      tripleToggleCount.current = 0;

      return;
    }

    if (!isFeedPlaying && !isMainPlayerPlaying) {
      dispatch(playFeed());
    } else if (isMainPlayerPlaying) {
      dispatch(pauseMainPlayer());
      dispatch(playFeed());
    } else if (isFeedPlaying) {
      dispatch(playMainPlayer());
      dispatch(pauseFeed());
    }
    tripleToggleCount.current++;
  }, [isMainPlayerPlaying, isFeedPlaying]);

  const normalTogglePlay = useCallback(() => {
    if (!isFeedPlaying) {
      dispatch(playFeed());
    } else {
      dispatch(pauseFeed());
    }
  }, [isMainPlayerPlaying, isFeedPlaying]);

  const togglePlay = doesMainPlayerHaveTrack
    ? tripleTogglePlay
    : normalTogglePlay;

  return togglePlay;
};

export default React.memo(FeedHotkeys);
