import React, {FC, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import CardButton from '@/components/CardButton';
import DashedDivider from '@/components/DashedDivider';
import Icon from '@/components/Icon';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Text from '@/components/Text';
import UsdPrice from '@/components/UsdPrice';
import {config} from '@/constants/config';
import {tipChain} from '@/modules/Tip/constants';
import {usePendingTipsBalance} from '@/modules/Tip/useTipsBalance';
import {useVerifyAddressState} from '@/modules/Tip/VerifyAddress/useVerifyAddressState';
import {useVerifyTransaction} from '@/modules/Tip/VerifyAddress/useVerifyTransaction';
import {
  useBalanceValidation,
  useChainValidation,
  useIsWalletConnectedValidation,
  useTransactionValidation,
} from '@/modules/Transactions';
import {WalletCard, WalletPicker} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {formatPrice, prettifyAddress} from '@/utils/ethereum';
import {isNotNil} from '@/utils/functions';
import {openLinkSafe} from '@/utils/linking';

import {styles} from './VerifyAddressCheckout.style';

const VerifyAddressCheckout: FC = () => {
  const style = useThemedStyles(styles);
  const {pendingBalances} = usePendingTipsBalance();
  const {
    user,
    transaction,
    updateTransaction,
    verifiedWallet,
    gasEstimation,
    balance,
  } = useVerifyAddressState();
  const [isWalletPickerOpen, setIsWalletPickerOpen] = useState(false);

  const payoutWallet = user.addresses.find(
    a => a.address === transaction.payoutAddress,
  );

  const {verifyAddress} = useVerifyTransaction();

  const tipBalance = pendingBalances?.[verifiedWallet.address] || BigInt(0);
  const totalGas = gasEstimation.totalGas || BigInt(0);

  const ConnectedWalletError = useIsWalletConnectedValidation({
    wallet: verifiedWallet,
  });
  const WrongChainError = useChainValidation({
    wallet: verifiedWallet,
    chainId: tipChain.id,
  });
  const InsufficientBalanceError = useBalanceValidation({
    wallet: verifiedWallet,
    balance,
    price: totalGas,
    chainId: tipChain.id,
  });
  const InvalidTransactionError = useTransactionValidation(gasEstimation);

  const validationError = [
    ConnectedWalletError,
    WrongChainError,
    InsufficientBalanceError,
    InvalidTransactionError,
  ].filter(isNotNil)[0];

  return (
    <View style={style.container}>
      <ScrollContainer contentStyle={style.content}>
        <View style={style.textsContainer}>
          <Text
            weight="semibold"
            size="l"
            align="center"
            id="tip.verify.checkout.header"
            values={{
              amount: (
                <Text weight="semibold" size="l" align="center">
                  {formatPrice(tipBalance, tipChain.chain.nativeCurrency)}
                </Text>
              ),
            }}
          />

          <Text
            align="center"
            size="xs"
            id="tip.verify.checkout.message"
            values={{
              address: (
                <Text weight="semibold" size="xs" align="center">
                  {verifiedWallet.ens ||
                    prettifyAddress(verifiedWallet.address)}
                </Text>
              ),
            }}
          />

          <TouchableOpacity
            style={style.contactRow}
            onPress={() => openLinkSafe(config.SPINAMP_DISCORD_URL)}>
            <Icon provider="custom" name="info" size={16} />
            <Text
              size="xxs"
              align="center"
              id="tip.verify.checkout.support"
              values={{
                contact: (
                  <Text
                    size="xxs"
                    weight="semibold"
                    align="center"
                    id="tip.verify.checkout.support.contact"
                    underline
                  />
                ),
              }}
            />
          </TouchableOpacity>
        </View>

        <DashedDivider />

        <View style={style.receivingWallet}>
          <Text id="tip.verify.checkout.receivingWallet.header" />
          {payoutWallet && (
            <WalletCard
              wallet={payoutWallet}
              onPress={() => setIsWalletPickerOpen(true)}
              disabled={transaction.step !== 'checkout'}
              suffix={
                transaction.step === 'checkout' ? (
                  <Text
                    style={style.receivingWalletCardAction}
                    id="tip.verify.checkout.receivingWallet.change"
                    weight="semibold"
                    size="xs"
                  />
                ) : null
              }
            />
          )}
        </View>

        <View style={style.spacer} />
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <View style={style.footerPriceInfo}>
          <FadeInOut enabled={gasEstimation.isFetching}>
            <View style={style.priceContainer}>
              <Text
                size="xs"
                id="tip.verify.checkout.networkFee"
                values={{
                  price: (
                    <Text size="xs" weight="semibold">
                      {formatPrice(totalGas, tipChain.chain.nativeCurrency, 7)}
                    </Text>
                  ),
                }}
              />
              <UsdPrice
                size="xxs"
                secondary
                chainId={tipChain.id}
                wei={totalGas}
              />
            </View>
          </FadeInOut>
        </View>

        {validationError?.message && (
          <View style={style.validationError}>{validationError.message}</View>
        )}

        {validationError ? (
          validationError.action
        ) : (
          <CardButton
            disabled={gasEstimation.totalGas === undefined}
            text={{id: 'tip.verify.checkout.submit'}}
            isLoading={transaction.step !== 'checkout'}
            loaderProps={{textId: 'processing'}}
            onPress={() =>
              verifyAddress(verifiedWallet, transaction.payoutAddress)
            }
          />
        )}
      </ShadowFooter>

      {isWalletPickerOpen && (
        <WalletPicker
          isOpen
          titleId="tip.verify.checkout.receivingWallet.header"
          user={user}
          selectedWalletAddress={transaction.payoutAddress}
          onConfirm={address => updateTransaction({payoutAddress: address})}
          chainId={tipChain.id}
          onClose={() => setIsWalletPickerOpen(false)}
          allowAddPasskeyWallet
          allowAddExternalWallet
        />
      )}
    </View>
  );
};

export default VerifyAddressCheckout;
