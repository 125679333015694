import {batch} from 'react-redux';

import * as playlistApi from '@/api/playlist';
import {AppDispatch, RootState} from '@/store';
import {updatePlaylists, removePlaylist} from '@/store/playlists';
import {IPlaylist, PlaylistType} from '@/types/playlists';
import {isLocalPlaylistId} from '@/utils/playlists';

function getMissingServerPlaylistIds(
  serverPlaylists: IPlaylist[],
  localPlaylists: IPlaylist[],
) {
  const serverPlaylistIds = serverPlaylists.map(p => p.id);

  return localPlaylists
    .filter(
      localPlaylist =>
        localPlaylist.type !== PlaylistType.favorites &&
        !serverPlaylistIds.includes(localPlaylist.id),
    )
    .map(p => p.id);
}

async function removeAbandonedPlaylists(
  abandonedPlaylistIds: string[],
  dispatch: AppDispatch,
) {
  // request each missing id to see if the server has been abandoned
  for (const id of abandonedPlaylistIds) {
    try {
      const serverPlaylist = await playlistApi.fetchPlaylistById(id);

      if (serverPlaylist.abandoned) {
        //delete locally
        dispatch(removePlaylist({id}));
      }
    } catch (e) {
      // ignore errors
    }
  }
}

export const setPlaylists =
  ({playlists: serverPlaylists}: {playlists: IPlaylist[]}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    batch(() => {
      dispatch(updatePlaylists(serverPlaylists));

      // Remove local playlists which are not included in playlists from server
      const missingIds = getMissingServerPlaylistIds(
        serverPlaylists,
        getState().playlists.playlists,
      ).filter(id => !isLocalPlaylistId(id));
      removeAbandonedPlaylists(missingIds, dispatch);
    });
  };
