import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const SELECT_PADDING = spacing.xs;

export const styles = createThemedStyles(theme => ({
  selectContainer: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
  },
  selectOpener: {
    padding: SELECT_PADDING,
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionsContainer: {
    borderTopWidth: 1,
    borderColor: theme.colors.borderColor,
    paddingHorizontal: SELECT_PADDING,
    paddingVertical: SELECT_PADDING / 2,
  },
  option: {
    paddingVertical: SELECT_PADDING / 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
