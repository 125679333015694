import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 40;
export const ICON_SIZE = 14;
export const TRACK_NFT_EVENT_HEIGHT = 40;

export const styles = createThemedStyles(() => ({
  container: {
    minHeight: TRACK_NFT_EVENT_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
  contentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentBody: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentDescription: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
