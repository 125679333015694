import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';

import {styles} from './ClosingHeader.style';

const ClosingHeader: FC = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation();
  const {isMobile} = useResponsive();

  if (!isMobile) {
    return null;
  }

  return (
    <View style={style.header}>
      <TouchableOpacity onPress={() => navigation.goBack()}>
        <Icon name="arrowDown" provider="custom" />
      </TouchableOpacity>
    </View>
  );
};

export default ClosingHeader;
