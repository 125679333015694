import {MintDetails as ApiMintDetails} from 'api-utils';
import {gql} from 'graphql-request';

import {fetchUserByAddress} from '@/api/user';
import {
  IExternalOfferInfo,
  IMintOfferInfo,
  ITrackCollectInfo,
  OfferAvailability,
} from '@/modules/Collect/types';
import {
  createOfferId,
  parseOfferError,
  removeDuplicatedOffers,
  sortOffersFromCheapest,
} from '@/modules/Collect/utils';
import {pipelineApi} from '@/services/pipelineApi';
import {spinampServicesApi} from '@/services/spinampServicesApi';
import {AddressString} from '@/types/common';
import {formatAddressChecksumOptional} from '@/utils/ethereum';

export const fetchIsUserOwner = async (
  trackId: string,
  userAddress: string,
) => {
  const user = await fetchUserByAddress(userAddress);

  if (!user) {
    return false;
  }

  const userAddresses = user?.addresses.map(({address}) => address);
  const response = await pipelineApi.request(
    gql`
      query IsUserOwner($trackId: String!, $userAddresses: [String!]) {
        processedTrackById(id: $trackId) {
          nftsProcessedTracksByProcessedTrackId(
            filter: {
              nftByNftId: {
                nftsCollectorsByNftId: {some: {addressId: {in: $userAddresses}}}
              }
            }
          ) {
            totalCount
          }
        }
      }
    `,
    {
      trackId,
      userAddresses,
    },
  );

  return (
    response.processedTrackById.nftsProcessedTracksByProcessedTrackId
      .totalCount > 0
  );
};

export const fetchContract = async (trackId: string): Promise<string> => {
  const response = await pipelineApi.request(
    gql`
      query NftFactoryIdForTrack($trackId: String!) {
        allNftFactoriesProcessedTracks(
          first: 1
          filter: {processedTrackId: {equalTo: $trackId}}
        ) {
          nodes {
            nftFactoryByNftFactoryId {
              address
            }
          }
        }
      }
    `,
    {
      trackId,
    },
  );

  return response.allNftFactoriesProcessedTracks.nodes[0]
    .nftFactoryByNftFactoryId.address;
};

export const fetchOffersForTrack = async (
  trackId: string,
  userAddress: string,
  quantity: number,
  referralAddress?: string,
): Promise<{
  availability: OfferAvailability;
  mintOffers: IMintOfferInfo[];
  externalOffer: IExternalOfferInfo | null;
}> => {
  const offers: ApiMintDetails[] = await spinampServicesApi.get('mint', {
    params: {
      userAddress,
      processedTrackId: trackId,
      referralAddress: formatAddressChecksumOptional(referralAddress),
      quantity,
    },
  });

  const mintOffers = offers.filter(
    mintOffer =>
      mintOffer.available && mintOffer.mintTransaction && mintOffer.price,
  );

  if (mintOffers.length > 0) {
    const parsedMintOffers: IMintOfferInfo[] = mintOffers
      .map(offer => {
        const mintTransaction = offer.mintTransaction!;
        const price = offer.price!;

        return {
          id: createOfferId(trackId, 'direct', offer.metadata),
          chainId: mintTransaction.chainId,
          mintTransaction: {
            data: mintTransaction.data as AddressString,
            to: mintTransaction.to as AddressString,
            value: mintTransaction.value,
            gasLimit: mintTransaction.gasLimit,
          },
          price: {
            value: price.value,
            contract: price.asset.address,
            currency: {
              decimals: price.asset.decimals,
              symbol: price.asset.symbol,
            },
          },
          approvalTransaction: offer.approvalTransaction && {
            to: offer.approvalTransaction.to as AddressString,
            data: offer.approvalTransaction.data as AddressString,
          },
          maxQuantity: offer.maxQuantity,
          editions: offer.editions,
          error: parseOfferError(offer.error),
          metadata: offer.metadata,
          multicallSupport: offer.multicallSupport || false,
        };
      })
      .sort(sortOffersFromCheapest);

    return {
      availability: 'direct',
      externalOffer: null,
      mintOffers: removeDuplicatedOffers(parsedMintOffers),
    };
  }

  const externalOffer = offers.find(
    offer => offer.available && offer.saleType?.type === 'bid',
  );

  if (externalOffer) {
    return {
      availability: 'external',
      mintOffers: [],
      externalOffer: {
        id: createOfferId(trackId, 'external', externalOffer.metadata),
        //@ts-ignore
        url: externalOffer.saleType!.externalUrl,
        editions: externalOffer.editions,
        error: parseOfferError(externalOffer.error),
      },
    };
  }

  const unknownAvailabilityOffer = offers.find(
    offer => offer.available === null,
  );

  if (unknownAvailabilityOffer) {
    return {
      availability: 'unknown',
      mintOffers: [],
      externalOffer: null,
    };
  }

  return {
    availability: 'unavailable',
    mintOffers: [],
    externalOffer: null,
  };
};

export const fetchCollectInfoForTrack = async (
  trackId: string,
  userAddress: string,
  quantity: number,
  referralAddress?: string,
): Promise<ITrackCollectInfo> => {
  const [offer, contract, isAlreadyOwned] = await Promise.all([
    fetchOffersForTrack(trackId, userAddress, quantity, referralAddress),
    fetchContract(trackId),
    fetchIsUserOwner(trackId, userAddress),
  ]);

  return {
    availability: offer.availability,
    mintOffers: offer.mintOffers,
    externalOffer: offer.externalOffer,
    isAlreadyOwned,
    contract,
  };
};
