import {spinampServicesApi} from '@/services/spinampServicesApi';
import {ISigner} from '@/types/session';

type IOperation = 'upsert' | 'update' | 'delete';

export const submitEntityFieldUpdate = async <T>(
  entity: string,
  update: T,
  signer: ISigner,
  operation: IOperation = 'update',
) => {
  const instruction = {
    entity: entity,
    operation,
    data: update,
  };
  const signature = await signer.signMessage(JSON.stringify(instruction));

  return spinampServicesApi.post('messages', instruction, {
    headers: {
      'x-signature': signature,
      'x-signer': signer.address,
    },
  });
};
