import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const ICON_SIZE = spacing.xl;

export const styles = createThemedStyles(theme => ({
  container: {
    width: 200,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.xxs,
  },
  item_hovered: {
    backgroundColor: theme.colors.background,
  },
  item_pressed: {
    backgroundColor: theme.colors.backgroundDark,
  },
  itemIcon: {
    height: ICON_SIZE,
    width: ICON_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
  },
  externalLinkIcons: {
    flexDirection: 'row',
    marginLeft: spacing.xs,
    gap: spacing.xs,
  },
}));
