import {gql} from 'graphql-request';

import {KERNEL_ADDRESSES} from '@/modules/Wallets/passkeyWallet';
import {pipelineApi} from '@/services/pipelineApi';
import API from '@/services/spinampApi';
import {spinampServicesApi} from '@/services/spinampServicesApi';
import {
  IAddressMetadata,
  IAddressProperties,
  IExternalLink,
} from '@/types/common';
import {DeepPartial} from '@/types/helpers';
import {IInternalSigner, ISession, ISigner} from '@/types/session';
import {IBaseUser, IUser, IUserMetadata, IUserUpdate} from '@/types/user';
import {getSignedRequestBody, getSignedRequestHeader} from '@/utils/api';
import {
  BASE_USER_FRAGMENT,
  parseApiBaseUser,
  parseApiUser,
  USER_ARTIST_PROFILE_FRAGMENT,
} from '@/utils/apiModelParsers';
import {formatAddressChecksum} from '@/utils/ethereum';
import {omit} from '@/utils/functions';
import {createInternalSigner} from '@/utils/signer';
import {mergeUserMetadata} from '@/utils/user';

import {submitEntityFieldUpdate} from './entityFieldUpdates';

export const registerUser = async (signer: ISigner): Promise<string> => {
  const payload = {
    entity: 'users',
    operation: 'register',
    data: {
      isPublic: false,
      isSession: true,
      isWallet: false,
      isPasskey: false,
    },
  };
  const signature = await signer.signMessage(JSON.stringify(payload));
  const response: any = await spinampServicesApi.post('messages', payload, {
    headers: {
      'x-signature': signature,
      'x-signer': signer.address,
    },
  });

  return response.messages[0].data.id;
};

export const createUserWithSessionKey = async (): Promise<ISession> => {
  const signer = createInternalSigner();
  const userId = await registerUser(signer);

  return {
    userId,
    sessionKey: signer.sessionKey,
    isAnon: true,
  };
};

export const linkAddressToUser = async (linkData: {
  userId: string;
  signerToLink: ISigner;
  authSigner: ISigner;
  addressProperties: IAddressProperties;
  metadata?: IAddressMetadata;
  onLinking?: () => void;
}): Promise<void> => {
  const message = `I want to link this address: ${linkData.signerToLink.address} to this profile: ${linkData.userId}`;
  const sourceSignature = await linkData.signerToLink.signMessage(message);

  const payload = {
    entity: 'addresses',
    operation: 'upsert',
    data: {
      id: linkData.signerToLink.address,
      userId: linkData.userId,
      metadata: JSON.stringify(linkData.metadata),
      ...linkData.addressProperties,
    },
    metadata: {
      linkSignature: sourceSignature,
      linkMessage: message,
      linkSigner: linkData.signerToLink.address,
    },
  };

  const targetSignature = await linkData.authSigner.signMessage(
    JSON.stringify(payload),
  );

  linkData.onLinking?.();

  await spinampServicesApi.post('messages', payload, {
    headers: {
      'x-signature': targetSignature,
      'x-signer': linkData.authSigner.address,
    },
  });
};

export const linkSpinampWallet = async (
  userId: string,
  passkeySigner: IInternalSigner,
  predictedWallet: IInternalSigner,
  credentialId: string,
) => {
  await linkAddressToUser({
    userId,
    authSigner: passkeySigner,
    signerToLink: {
      address: predictedWallet.address,
      signMessage: predictedWallet.signMessage,
    },
    addressProperties: {
      isWallet: true,
      isPasskey: true,
      isPublic: true,
      isSession: false,
    },
    metadata: {
      spinampWallet: {
        version: {
          lib: 'permissionless',
          libVersion: '0.0.28',
          accountType: 'kernel',
          factoryAddress: KERNEL_ADDRESSES.FACTORY_ADDRESS,
          accountLogicAddress: KERNEL_ADDRESSES.ACCOUNT_V2_2_LOGIC,
        },
        signer: passkeySigner.address,
        credentialId,
        deployed: [],
      },
    },
  });
};

export const updateAddressProperties = async (
  addressUpdate: {id: string} & Partial<IAddressProperties>,
  signer: ISigner,
) => submitEntityFieldUpdate('addresses', addressUpdate, signer);

export const fetchUserProfiles = async (userIds: string[]) => {
  const response = await pipelineApi.request(
    gql`
      query Profiles($userIds: [String!]) {
        allUsers(filter: {id: {in: $userIds}}) {
          nodes {
            ...BaseUserFragment
            ...UserArtistProfileFragment
            metadata
          }
        }
      }
      ${BASE_USER_FRAGMENT}
      ${USER_ARTIST_PROFILE_FRAGMENT}
    `,
    {
      userIds,
    },
  );

  return response.allUsers.nodes.map(parseApiUser) as IUser[];
};

export const fetchUserById = async (userId: string) => {
  const response = await pipelineApi.request(
    gql`
      query User($userId: String!) {
        userById(id: $userId) {
          ...BaseUserFragment
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      userId,
    },
  );

  if (!response.userById) {
    return null;
  }

  return parseApiBaseUser(response.userById);
};

export const fetchUserByEns = async (ens: string) => {
  const response = await pipelineApi.request(
    gql`
      query User($ens: String) {
        allUsers(
          filter: {addressesByUserId: {some: {ensName: {equalTo: $ens}}}}
        ) {
          nodes {
            ...BaseUserFragment
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      ens,
    },
  );

  if (response.allUsers.nodes[0]) {
    return parseApiBaseUser(response.allUsers.nodes[0]);
  }

  return null;
};

export const fetchUserBySlug = async (idOrEns: string) => {
  return (await fetchUserById(idOrEns)) || (await fetchUserByEns(idOrEns));
};

export const fetchUserByAddress = async (
  address: string,
): Promise<IBaseUser | null> => {
  const response = await pipelineApi.request(
    gql`
      query Address($address: String!) {
        addressById(id: $address) {
          userByUserId {
            ...BaseUserFragment
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      address: formatAddressChecksum(address),
    },
  );

  const user = response.addressById?.userByUserId;

  if (!user) {
    return null;
  }

  return parseApiBaseUser(user);
};

// TODO: Consider deprecating `IBaseUser` and query for full `IUser` across whole app
export const fetchFullUserById = async (userId: string) => {
  const response = await pipelineApi.request(
    gql`
      query User($userId: String!) {
        userById(id: $userId) {
          ...BaseUserFragment
          ...UserArtistProfileFragment
        }
      }
      ${BASE_USER_FRAGMENT}
      ${USER_ARTIST_PROFILE_FRAGMENT}
    `,
    {
      userId,
    },
  );

  if (response.userById) {
    return parseApiUser(response.userById);
  }

  return null;
};

export const fetchUsersBySearchPhrase = async (
  search: string,
  limit: number = 50,
): Promise<IBaseUser[]> => {
  const response = await pipelineApi.request(
    gql`
      query SearchUsers($search: String, $limit: Int) {
        searchUsers(search: $search, first: $limit) {
          nodes {
            ...BaseUserFragment
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      search,
      limit,
    },
  );

  return response.searchUsers.nodes.map(parseApiBaseUser);
};

export const fetchUsersByIds = async (ids: string[]): Promise<IBaseUser[]> => {
  const response = await pipelineApi.request(
    gql`
      query Users($ids: [String!]) {
        allUsers(filter: {id: {in: $ids}}) {
          nodes {
            ...BaseUserFragment
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      ids,
    },
  );

  return response.allUsers.nodes.map(parseApiBaseUser);
};

export const fetchUsersByAddresses = async (addresses: string[]) => {
  const response = await pipelineApi.request(
    gql`
      query UsersByAddress($addresses: [String!]) {
        allAddresses(filter: {id: {in: $addresses}}) {
          nodes {
            id
            userByUserId {
              ...BaseUserFragment
            }
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      addresses,
    },
  );

  const userByAddress: {[address: string]: IBaseUser} = {};
  response.allAddresses.nodes.forEach((node: any) => {
    userByAddress[node.id] = parseApiBaseUser(node.userByUserId);
  });

  return userByAddress;
};

export const updateUser = (update: IUserUpdate, signer: ISigner) =>
  submitEntityFieldUpdate<IUserUpdate>('users', update, signer);

export const updateUserMetadata = (
  metadataUpdate: DeepPartial<IUserMetadata>,
  user: Pick<IUser, 'id' | 'metadata'>,
  signer: ISigner,
) =>
  updateUser(
    {
      id: user.id,
      metadata: JSON.stringify(
        mergeUserMetadata(user.metadata, metadataUpdate),
      ),
    },
    signer,
  );

export const addUserExternalLink = (
  userId: string,
  link: IExternalLink,
  signer: ISigner,
) =>
  submitEntityFieldUpdate(
    'externalLinks_users',
    {
      userId,
      ...omit(link, 'id'),
    },
    signer,
    'upsert',
  );

export const deleteUserExternalLink = (
  userId: string,
  link: IExternalLink,
  signer: ISigner,
) =>
  submitEntityFieldUpdate(
    'externalLinks_users',
    {
      id: link.id,
    },
    signer,
    'delete',
  );

export const editUserExternalLink = async (
  userId: string,
  link: IExternalLink,
  signer: ISigner,
) => {
  await deleteUserExternalLink(userId, link, signer);
  await addUserExternalLink(userId, link, signer);
};

export const updateExternalLinksBulk = (
  userId: string,
  {
    added,
    updated,
    deleted,
  }: {
    added: IExternalLink[];
    updated: IExternalLink[];
    deleted: IExternalLink[];
  },
  signer: ISigner,
) =>
  Promise.all([
    ...added.map(link => addUserExternalLink(userId, link, signer)),
    ...updated.map(link => editUserExternalLink(userId, link, signer)),
    ...deleted.map(link => deleteUserExternalLink(userId, link, signer)),
  ]);

export const fetchUserEmail = async (
  signer: ISigner,
): Promise<string | null> => {
  const config = {
    headers: await getSignedRequestHeader('get-my-email', signer),
  };

  try {
    const {email}: {email: string} = await API.get('email', config);
    return email;
  } catch (error: any) {
    if (error?.status === 404) {
      return null;
    }

    return Promise.reject(error);
  }
};

export const saveUserEmail = async (email: string, signer: ISigner) => {
  const body = await getSignedRequestBody({email}, signer);

  return API.post('email', body);
};
