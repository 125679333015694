import {useCallback} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {selectCurrentQueueItem, selectQueue} from '@/store/player';
import {PlayContextType} from '@/types/common';

export const useIsTrackActive = (
  contextType: PlayContextType,
  contextId?: string,
) => {
  const queue = useAppSelector(selectQueue);
  const currentItem = useAppSelector(selectCurrentQueueItem);

  // index is optional - should be passed only from screens where tracks could be duplicated to identify which
  // one of duplicated tracks is currently playing
  const isTrackActive = useCallback(
    (trackId: string, index?: number) => {
      if (
        !queue ||
        !currentItem ||
        queue.context.type !== contextType ||
        queue.context.id !== contextId
      ) {
        return false;
      }

      if (index === undefined) {
        return trackId === currentItem.trackId;
      }

      return (
        trackId === currentItem.trackId && index === currentItem.originalIndex
      );
    },
    [queue, currentItem, contextType, contextId],
  );

  return {
    isTrackActive,
  };
};
