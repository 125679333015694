import React, {FC, useRef, useState} from 'react';
import {View} from 'react-native';
import {Image, ImageProps, ImageStyle, ImageURISource} from 'react-native';

import ImageLoader from '@/components/DynamicImage/ImageLoader';
import {useOptimizedImageSource} from '@/hooks/useOptimizedImageSource';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';

import {styles} from './DynamicImage.style';

// @ts-ignore
interface IProps extends ImageProps {
  source: ImageURISource;
  resizeWidth?: ImageSize | number;
  showSpinner?: boolean;
  spinnerSize?: number;
}

const DynamicImage: FC<IProps> = ({
  style,
  source,
  resizeWidth,
  spinnerSize,
  showSpinner = false,
  ...rest
}) => {
  const optimizedSource = useOptimizedImageSource(source, resizeWidth);
  const imageStyle = useThemedStyles(styles);

  const [lastLoadedSource, setLastLoadedSource] =
    useState<ImageURISource | null>(null);

  // onLoad cannot change reference - otherwise it will refetch the image on every render
  // it means that we cannot use state variables inside and need to create backup ref for current optimizedSource
  // https://github.com/necolas/react-native-web/issues/2025
  const optimizedSourceRef = useRef(optimizedSource);
  optimizedSourceRef.current = optimizedSource;
  const onLoad = useRef(() => {
    if (optimizedSourceRef.current) {
      setLastLoadedSource({...optimizedSourceRef.current});
    }
  }).current;

  const isLoaded = lastLoadedSource?.uri === optimizedSource.uri;

  return (
    <View style={style}>
      {lastLoadedSource && (
        <Image
          source={lastLoadedSource}
          style={[imageStyle.image] as ImageStyle}
          {...rest}
        />
      )}
      <Image
        source={optimizedSource}
        style={[imageStyle.image, !isLoaded && imageStyle.hidden] as ImageStyle}
        onLoad={onLoad}
        {...rest}
      />
      {!!lastLoadedSource && showSpinner && (
        <ImageLoader isLoaded={isLoaded} spinnerSize={spinnerSize} />
      )}
    </View>
  );
};

export default DynamicImage;
