import {useEffect, useRef} from 'react';

import {useAppDispatch} from '@/hooks/useRedux';
import {initAdditionalAudio} from '@/services/additionalAudio';
import {IAdditionalAudio} from '@/services/additionalAudio/types';
import {pause} from '@/store/player';
import {ITrack} from '@/types/common';

export const useSpinampWrappedAudio = (isActive: boolean, track?: ITrack) => {
  const dispatch = useAppDispatch();
  const player = useRef<IAdditionalAudio>();

  useEffect(() => {
    if (track) {
      player.current = initAdditionalAudio({
        uri: track.lossyAudioUrl,
        startTimeInSeconds: track.chorusStart,
      });

      return () => player.current?.destroy();
    }
  }, [track?.lossyAudioUrl]);

  useEffect(() => {
    if (isActive) {
      dispatch(pause());
      player.current?.load();
      player.current?.play();

      return () => {
        player.current?.pause();
      };
    }
  }, [isActive]);
};
