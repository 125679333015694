import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const ACTION_WIDTH = spacing.xl - spacing.xxs;

export const styles = createThemedStyles(() => ({
  actionsContainer: {
    flexDirection: 'row',
  },
  action: {
    width: ACTION_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  },
  openerAction: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiddenActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    paddingRight: ACTION_WIDTH,
    flexDirection: 'row',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: -50,
    bottom: 0,
    width: 50,
  },
}));
