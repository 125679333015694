import {useEffect} from 'react';

import {addFocusEventListener} from '@/utils/appEvents';

const useOnFocus = (onFocus: () => void) => {
  useEffect(() => {
    const unsubscribe = addFocusEventListener(onFocus);

    return unsubscribe;
  }, [onFocus]);
};

export default useOnFocus;
