import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 36;
const USER_TILE_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  container: {
    padding: spacing.l,
  },
  content: {
    paddingHorizontal: spacing.m,
    paddingBottom: spacing.l,
    gap: spacing.l,
  },
  userTile: {
    alignSelf: 'center',
    width: 260,
    height: USER_TILE_HEIGHT,
    padding: spacing.xs,
    borderRadius: USER_TILE_HEIGHT / 2,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  textsContainer: {
    gap: spacing.xs,
  },
  links: {
    flexDirection: 'row',
    gap: spacing.m,
    alignItems: 'center',
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
}));
