import React, {FC, ReactNode, useState} from 'react';
import {LayoutChangeEvent, TouchableOpacity, View} from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import Text, {TextProps} from '@/components/Text';
import {HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './CollapsingText.style';

interface IProps extends TextProps {
  children: ReactNode;
  numberOfLines?: number;
}

const CollapsingText: FC<IProps> = ({
  children,
  numberOfLines = 5,
  ...textProps
}) => {
  const style = useThemedStyles(styles);
  const [isOpen, setIsOpen] = useState(false);
  const [closedHeight, setClosedHeight] = useState<number | null>(null);
  const [fullHeight, setFullHeight] = useState<number | null>(null);
  const showSeeMore =
    !!fullHeight && !!closedHeight && fullHeight > closedHeight;

  const seeMoreAnimation = useDerivedValue(
    () =>
      withTiming(showSeeMore ? 1 : 0, {
        duration: 200,
      }),
    [showSeeMore],
  );

  const openAnimation = useDerivedValue(
    () =>
      withTiming(isOpen ? 1 : 0, {
        duration: 200,
      }),
    [isOpen],
  );

  const containerStyle = useAnimatedStyle(() => ({
    minHeight: interpolate(
      openAnimation.value,
      [0, 1],
      [closedHeight || 0, fullHeight || 0],
    ),
  }));

  const closedStyle = useAnimatedStyle(() => ({
    opacity: interpolate(openAnimation.value, [0, 1], [1, 0]),
  }));

  const fullStyle = useAnimatedStyle(() => ({
    opacity: openAnimation.value,
  }));

  const seeMoreStyle = useAnimatedStyle(() => ({
    opacity: seeMoreAnimation.value,
  }));

  const onClosedLayout = (event: LayoutChangeEvent) => {
    const {layout} = event.nativeEvent;
    if (closedHeight !== layout.height) {
      setClosedHeight(layout.height);
    }
  };

  const onFullLayout = (event: LayoutChangeEvent) => {
    const {layout} = event.nativeEvent;
    if (fullHeight !== layout.height) {
      setFullHeight(layout.height);
    }
  };

  return (
    <View style={style.container}>
      <Animated.View style={[style.textContainer, containerStyle]}>
        <Animated.View style={[style.closed, closedStyle]}>
          <Text
            onLayout={onClosedLayout}
            {...textProps}
            numberOfLines={numberOfLines}>
            {children}
          </Text>
        </Animated.View>
        <Animated.View style={[style.full, fullStyle]}>
          <Text onLayout={onFullLayout} {...textProps}>
            {children}
          </Text>
        </Animated.View>
      </Animated.View>
      {showSeeMore && (
        <Animated.View style={[style.seeMoreToggler, seeMoreStyle]}>
          <TouchableOpacity
            onPress={() => setIsOpen(v => !v)}
            hitSlop={HIT_SLOP}>
            <Text
              size="xxs"
              weight="semibold"
              align="center"
              id={isOpen ? 'seeLess' : 'seeMore'}
            />
          </TouchableOpacity>
        </Animated.View>
      )}
    </View>
  );
};

export default CollapsingText;
