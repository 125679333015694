import {useIsFocused} from '@react-navigation/native';
import {useContext, useEffect} from 'react';

import {useThemes} from '@/hooks/useThemes';
import {IDynamicTheme} from '@/types/theme';

import {ThemeContext} from './context';

export const useOverrideTheme = ({
  customTheme,
  predefinedThemeName,
}: IDynamicTheme = {}) => {
  const {overrideTheme, resetTheme} = useContext(ThemeContext);
  const isFocused = useIsFocused();
  const {themesData} = useThemes();

  // This hook is responsible for setting/clearing temporary theme only for screens with custom theming.
  // Clearing temporary theme for other screens happens in navigation observer in ThemeProvider.tsx
  useEffect(() => {
    if (isFocused && overrideTheme && resetTheme) {
      if (customTheme || predefinedThemeName) {
        overrideTheme({customTheme, predefinedThemeName});
      } else {
        resetTheme();
      }
    }
  }, [customTheme, predefinedThemeName, isFocused, themesData]);
};
