import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme/createThemedStyles';

export const styles = createThemedStyles(theme => ({
  image: {
    ...StyleSheet.absoluteFillObject,
  },
  hidden: {
    opacity: 0,
  },
  spinnerContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hasBorderRadius: {
    // perfectly tweaked to make sure image fits inside a rounded border, otherwise we see a slightly white space
    borderRadius: Math.max(0, theme.box.borderRadius - 3),
  },
}));
