import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    padding: spacing.s,
    flexGrow: 1,
  },
  themeEditorContainer: {
    marginHorizontal: -spacing.s,
  },
  themeEditorContent: {
    paddingHorizontal: spacing.s,
  },
  pillsContainer: {
    marginHorizontal: -spacing.s,
  },
  horizontalScrollWrapper: {
    flexGrow: 0,
    marginHorizontal: -spacing.s,
  },
  horizontalScroll: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.s,
  },
  divider: {
    height: 1,
    backgroundColor: theme.colors.backgroundDark,
  },
  previewWrapper: {
    borderColor: theme.colors.borderColor,
    borderWidth: 2,
  },
}));
