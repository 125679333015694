import {useNetInfo} from '@react-native-community/netinfo';
import {useEffect} from 'react';

import {useAppDispatch} from '@/hooks/useRedux';
import {
  savePlaybackEvents,
  setIsSyncing as setArePlaybackEventsSyncing,
} from '@/store/events';

export const useSyncEvents = () => {
  const {isConnected} = useNetInfo();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // reset isSyncing value on app start in case where app was killed during syncing
    dispatch(setArePlaybackEventsSyncing(false));
  }, []);

  useEffect(() => {
    if (isConnected) {
      dispatch(savePlaybackEvents());

      const intervalId = setInterval(() => {
        dispatch(savePlaybackEvents());
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [isConnected]);
};
