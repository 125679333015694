import React, {FC, ReactNode} from 'react';
import {StyleProp} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import {useThemedStyles} from '@/theme';

import {styles} from './PopoverCard.style';

interface IProps {
  children: ReactNode;
  style?: StyleProp<any>;
}

const PopoverCard: FC<IProps> = ({children, style: customStyle}) => {
  const style = useThemedStyles(styles);

  return (
    <Animated.View
      entering={FadeIn.duration(150)}
      exiting={FadeOut.duration(150)}
      style={[style.container, customStyle]}>
      {children}
    </Animated.View>
  );
};

export default PopoverCard;
