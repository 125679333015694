import React, {FC, ReactNode} from 'react';
import {StyleProp} from 'react-native';

import Card from '@/components/Card/Card';
import {ISpaceProps} from '@/components/Space/Space';
import Touchable from '@/components/Touchable/Touchable';
import {RectOrSize} from '@/types/common';
import {Color} from '@/types/theme';

interface TouchableProps extends Omit<ISpaceProps, 'children'> {
  onPress?: () => void;
  disabled?: boolean;
  style?: StyleProp<any>;
  containerStyle?: StyleProp<any>;
  hoveredStyle?: StyleProp<any>;
  pressedStyle?: StyleProp<any>;
  highlight?: boolean;
  children:
    | (({
        pressed,
        hover,
        actionColor,
      }: {
        pressed?: boolean;
        hover?: boolean;
        actionColor: (color?: Color) => Color;
      }) => ReactNode)
    | ReactNode;
  hitSlop?: RectOrSize;
}

const TouchableCard: FC<TouchableProps> = ({
  children,
  onPress,
  disabled,
  highlight,
  style,
  hoveredStyle,
  pressedStyle,
  containerStyle,
  hitSlop,
  ...props
}) => {
  return (
    <Touchable
      onPress={onPress}
      disabled={disabled}
      style={containerStyle}
      hitSlop={hitSlop}>
      {({pressed, hover, actionColor}) => {
        const stylesArray = Array.isArray(style) ? [...style] : [style];
        const pressedStyleArray = pressed ? [pressedStyle] : [];
        const hoveredStyleArray = hover ? [hoveredStyle] : [];

        const mergedStyles = [
          ...stylesArray,
          ...hoveredStyleArray,
          ...pressedStyleArray,
        ];

        return (
          <Card
            pressed={pressed}
            hovered={hover}
            style={mergedStyles}
            highlight={highlight}
            disabled={disabled}
            {...props}>
            {typeof children === 'function'
              ? children({
                  pressed,
                  hover,
                  actionColor,
                })
              : children}
          </Card>
        );
      }}
    </Touchable>
  );
};

export default TouchableCard;
