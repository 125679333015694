import {StyleSheet} from 'react-native';

import {normalizeSize} from '@/constants/sizing';
import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme/createThemedStyles';

export const PLAYER_BAR_HEIGHT = normalizeSize(75);
const PROGRESS_BAR_HEIGHT = 4;

export const styles = createThemedStyles(theme => ({
  wrapper: {
    // overflow: 'hidden',
  },
  playerPreview: {
    height: PLAYER_BAR_HEIGHT,
    borderTopWidth: 1,
    paddingTop: 1,
    borderColor: theme.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.background,
  },
  artworkWrapper: {
    paddingTop: PROGRESS_BAR_HEIGHT,
    height: '100%',
    aspectRatio: 1,
  },
  artwork: {
    ...StyleSheet.absoluteFillObject,
  },
  infoSection: {
    flex: 1,
  },
  progressWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: PROGRESS_BAR_HEIGHT,
    backgroundColor: theme.colors.active,
    zIndex: 1,
  },
  progress: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: PROGRESS_BAR_HEIGHT,
    backgroundColor: theme.colors.borderColor,
  },
  controls: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    paddingRight: spacing.xxs,
    gap: spacing.xs,
  },
  control: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
