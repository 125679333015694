import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const THUMB_SIZE = spacing.xs;

const PROGRESS_WIDTH = 120;
const PROGRESS_HEIGHT = spacing.xxs;
const PROGRESS_TOUCH_AREA = spacing.s * 2;

export const styles = createThemedStyles(theme => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.m,
    cursor: 'default',
    height: PROGRESS_TOUCH_AREA,
  },
  progressContainer: {
    flex: 1,
    width: PROGRESS_WIDTH,
    height: PROGRESS_TOUCH_AREA,
    justifyContent: 'center',
  },
  sliderController: {
    height: PROGRESS_TOUCH_AREA,
    zIndex: 1,
  },
  progressBar: {
    height: PROGRESS_HEIGHT,
    borderRadius: PROGRESS_HEIGHT / 2,
    overflow: 'hidden',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  progressBackground: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.backgroundDark,
  },
  progress: {
    backgroundColor: theme.colors.textColor,
    height: PROGRESS_HEIGHT,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  thumb: {
    width: THUMB_SIZE,
    height: THUMB_SIZE,
    borderRadius: THUMB_SIZE / 2,
    backgroundColor: theme.colors.textColor,
  },
  rightActionPlaceholder: {
    width: spacing.s * 2,
  },
}));
