import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect} from 'react';
import {View} from 'react-native';

import ClosingHeader from '@/components/ClosingHeader';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import {useActiveUser} from '@/hooks/useActiveUser';
import CryptoTransferRouter from '@/modules/Transfer/CryptoTransfer/CryptoTransferRouter';
import CryptoTransferStateProvider from '@/modules/Transfer/CryptoTransfer/CryptoTransferStateProvider';
import {areTransferParamsValid} from '@/modules/Transfer/utils';
import {useThemedStyles} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';

import {styles} from './CryptoTransferScreen.style';

const CryptoTransferScreen = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation();
  const {params} =
    useRoute<RouteProp<RootStackParams, Routes.CryptoTransfer>>();
  const {transferId, ...transferParams} = params;

  const user = useActiveUser();

  useEffect(() => {
    if (!areTransferParamsValid(params)) {
      navigation.goBack();
    }
  }, [params]);

  if (!params || !areTransferParamsValid(params)) {
    return null;
  }

  return (
    <ModalScreenWrapper
      titleId="transfer.title"
      isFullscreenOnMobile
      desktopSize="l">
      <ClosingHeader />

      <View style={style.container}>
        <CryptoTransferStateProvider
          transferId={transferId}
          initialTransferParams={{
            chainId: transferParams.chainId,
            from: transferParams.from,
            to: transferParams.to,
          }}
          initialUser={user}>
          <CryptoTransferRouter />
        </CryptoTransferStateProvider>
      </View>
    </ModalScreenWrapper>
  );
};

export default CryptoTransferScreen;
