import {spinampServicesApi} from '@/services/spinampServicesApi';

interface IReleasesForYouTrack {
  id: string;
  title: string;
  timestamp: string;
}

export const fetchReleasesForYou = async (
  userId: string,
  offset?: number,
  pageSize?: number,
): Promise<IReleasesForYouTrack[]> => {
  const config = {
    params: {
      offset,
      pageSize,
    },
  };

  const response: IReleasesForYouTrack[] = await spinampServicesApi.get(
    `/feed/releases-for-you/${userId}`,
    config,
  );

  return response;
};
