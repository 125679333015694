import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TransactionState, TransactionType} from '@/modules/Transactions/types';

export interface ITransactionsState {
  [id: string]: TransactionState;
}

export const initialState: ITransactionsState = {};

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    createTransaction: (state, action: PayloadAction<TransactionState>) => {
      state[action.payload.id] = action.payload;
    },
    removeTransaction: (state, action: PayloadAction<{id: string}>) => {
      delete state[action.payload.id];
    },
    updateTransaction: <T extends TransactionType>(
      state: ITransactionsState,
      action: PayloadAction<{
        id: string;
        type: T;
        update: Partial<Extract<TransactionState, {type: T}>>;
      }>,
    ) => {
      const {id, update} = action.payload;

      if (state[id]) {
        state[id] = {
          ...state[id],
          ...update,
        };
      }
    },
  },
});

export const {createTransaction, removeTransaction, updateTransaction} =
  transactionsSlice.actions;

export default transactionsSlice.reducer;
