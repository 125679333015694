import {useIsFocused} from '@react-navigation/native';
import React, {FC, useMemo, useRef} from 'react';
import {ScrollView, View} from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  LinearTransition,
} from 'react-native-reanimated';

import Pill from '@/components/Pill';
import {useIsInitialRender} from '@/hooks/useIsInitialRender';
import {usePrevious} from '@/hooks/usePrevious';
import {
  ANIMATION_DURATION,
  libraryCategories,
} from '@/screens/Library/constants';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';
import {LibraryCategory, LibrarySubcategory} from '@/screens/Library/types';
import {useThemedStyles} from '@/theme';
import {isIOS, isWeb} from '@/utils/platform';

import {styles} from './CategoryPills.style';

const AnimationLayout = LinearTransition.duration(200);

const CategoryPills: FC = () => {
  const style = useThemedStyles(styles);
  const isFocused = useIsFocused();
  const wasFocused = usePrevious(isFocused);
  const scrollViewRef = useRef<ScrollView | null>(null);
  const isInitialRender = useIsInitialRender();
  const {selectedCategory, selectedSubcategory, setCategory, setSubcategory} =
    useLibraryContext();

  const updateCategory = (categoryId: LibraryCategory) => {
    scrollViewRef.current?.scrollTo({x: 0, y: 0, animated: true});

    if (categoryId === selectedCategory?.id) {
      setCategory(undefined);
    } else {
      setCategory(categoryId);
    }
  };

  const updateSubcategory = (subcategoryId: LibrarySubcategory) => {
    scrollViewRef.current?.scrollTo({x: 0, y: 0, animated: true});

    if (subcategoryId === selectedSubcategory?.id) {
      setSubcategory(undefined);
    } else {
      setSubcategory(subcategoryId);
    }
  };

  const sortedCategories = useMemo(
    () =>
      selectedCategory
        ? [
            selectedCategory,
            ...libraryCategories.filter(c => c.id !== selectedCategory.id),
          ]
        : libraryCategories,
    [selectedCategory],
  );

  const becameFocused = !wasFocused && isFocused;
  const enteringAnimation =
    isInitialRender || !isFocused || becameFocused
      ? undefined
      : FadeIn.duration(ANIMATION_DURATION);
  const exitingAnimation = FadeOut.duration(ANIMATION_DURATION);
  const layoutAnimation = isWeb && becameFocused ? undefined : AnimationLayout;

  return (
    <ScrollView
      ref={scrollViewRef}
      horizontal
      decelerationRate="fast"
      bounces={false}
      showsHorizontalScrollIndicator={false}
      style={style.scrollContainer}
      contentContainerStyle={style.scrollContent}>
      {sortedCategories.map(category => {
        const isSelected = category.id === selectedCategory?.id;

        // There is a bug on iOS which causes issue with calculating layout animation after screen lost focus.
        // Remounting selected category with changing key solves the issue in a way transparent for a user.
        // However, we need to disable entering/exiting animations if it's triggered by changing key.
        // https://github.com/software-mansion/react-native-reanimated/issues/4516
        const key =
          !isIOS || isFocused ? category.id : `${category.id}_unfocused`;

        return (
          <Animated.View
            key={key}
            style={style.category}
            entering={enteringAnimation}
            exiting={exitingAnimation}
            layout={layoutAnimation}>
            {isSelected && category.subcategories && (
              <Animated.View
                style={style.categoryBackground}
                entering={enteringAnimation}
                exiting={exitingAnimation}
                layout={AnimationLayout}
              />
            )}
            <Pill
              onPress={() => updateCategory(category.id)}
              text={{
                id: category.textId,
              }}
              isSelected={isSelected}
              style={style.pill}
            />
            {isSelected && category?.subcategories && (
              <View style={style.subcategories}>
                {(selectedSubcategory
                  ? [selectedSubcategory]
                  : category.subcategories
                ).map(subcategory => {
                  const isSubcategorySelected =
                    subcategory.id === selectedSubcategory?.id;

                  return (
                    <Animated.View
                      key={subcategory.id}
                      entering={enteringAnimation}
                      exiting={exitingAnimation}
                      layout={AnimationLayout}
                      style={style.subcategory}>
                      <Pill
                        onPress={() => updateSubcategory(subcategory.id)}
                        text={{
                          id: subcategory.textId,
                        }}
                        isSelected={isSubcategorySelected}
                        style={style.pill}
                      />
                    </Animated.View>
                  );
                })}
              </View>
            )}
          </Animated.View>
        );
      })}
    </ScrollView>
  );
};

export default CategoryPills;
