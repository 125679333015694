import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 40;
export const ICON_SIZE = 14;
export const THUMBNAIL_SIZE = 16;
export const ARTIST_NFT_EVENT_HEIGHT = 55;

export const styles = createThemedStyles(theme => ({
  container: {
    minHeight: ARTIST_NFT_EVENT_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
  contentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: Platform.select({
      ios: spacing.xxs,
      default: spacing.xxs / 2,
    }),
  },
  trackInfo: {
    height: THUMBNAIL_SIZE,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  artwork: {
    backgroundColor: theme.colors.backgroundDark,
    width: THUMBNAIL_SIZE,
    height: THUMBNAIL_SIZE,
    overflow: 'hidden',
    borderRadius: Math.min(2, theme.box.borderRadius),
  },
  contentBody: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentDescription: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
