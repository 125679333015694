import React from 'react';
import {View} from 'react-native';

import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './style';

interface IProps {
  text: string;
}

/**
 * A visual-only display of a keyboard key.
 */
const Key: React.FC<IProps> = ({text}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.keyContainer}>
      <Text size="m">{text}</Text>
    </View>
  );
};

export default Key;
