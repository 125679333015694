import React, {FC} from 'react';

import Checkout from '@/modules/Collect/components/Checkout';
import PendingTransaction from '@/modules/Collect/components/PendingTransaction';
import SuccessfulTransaction from '@/modules/Collect/components/SuccessfulTransaction';
import {IMintOfferInfo} from '@/modules/Collect/types';
import {useCollectState} from '@/modules/Collect/useCollectState';

interface IProps {
  selectedOffer: IMintOfferInfo;
  mintOffers: IMintOfferInfo[];
}

const MainCollectFlow: FC<IProps> = ({selectedOffer, mintOffers}) => {
  const {transaction} = useCollectState();
  const {step, txHash, approvalTxHash, userOpHash, approvalUserOpHash} =
    transaction;

  if (step === 'success') {
    return <SuccessfulTransaction selectedOffer={selectedOffer} />;
  }

  if (step === 'approvalTransaction') {
    return (
      <PendingTransaction
        type="approval"
        txHash={approvalTxHash}
        userOpHash={approvalUserOpHash}
        chainId={selectedOffer.chainId}
      />
    );
  }

  if (step === 'mainTransaction') {
    return (
      <PendingTransaction
        type="main"
        txHash={txHash}
        userOpHash={userOpHash}
        chainId={selectedOffer.chainId}
      />
    );
  }

  return <Checkout selectedOffer={selectedOffer} mintOffers={mintOffers} />;
};

export default MainCollectFlow;
