import React, {FC} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Icon from '@/components/Icon';
import Space from '@/components/Space/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useAppSelector} from '@/hooks/useRedux';
import {useDbQuery} from '@/queries/db';
import DelayedFadeIn from '@/screens/SpinampWrapped/components/DelayedFadeIn';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useSpinampWrappedAudio} from '@/screens/SpinampWrapped/useSpinampWrappedAudio';
import {selectSelectedUserId} from '@/store/user';
import {useTheme} from '@/theme';
import {ImageSize} from '@/types/media';
import {findArtistTrack} from '@/utils/db';
import {getUserDisplayName} from '@/utils/user';

const TopArtist: FC = () => {
  const {db} = useDbQuery();
  const activeUserId = useAppSelector(selectSelectedUserId);
  const {activePageId, artists, tracks, nextPage, user} = useSpinampWrapped();
  const isActive = activePageId === 'topArtist';
  const theme = useTheme();

  const topArtist = artists[0].artist;
  const artistTrack = findArtistTrack(db, topArtist?.id);

  const count = artists[0].count;

  const {getIsFollowed, toggleFollow} = useArtistFollow();

  useSpinampWrappedAudio(isActive, artistTrack || tracks[0].track);

  if (!topArtist) {
    return null;
  }

  return (
    <ScrollView
      bounces={false}
      showsVerticalScrollIndicator={false}
      style={{flex: 1}}
      contentContainerStyle={{
        flexGrow: 1,
        alignItems: 'center',
        paddingHorizontal: spacing.m,
        paddingVertical: spacing.l,
      }}>
      <Text
        align="center"
        weight="bold"
        size="l"
        id="spinampWrapped.topArtist.title"
      />
      <Space h="m" />
      <View
        style={{
          width: '65%',
          overflow: 'hidden',
          borderRadius: theme.box.borderRadius,
          aspectRatio: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {topArtist.avatarUrl && (
          <FadeInOut
            style={{
              ...StyleSheet.absoluteFillObject,
              backgroundColor: theme.colors.backgroundDark,
            }}
          />
        )}
        {topArtist.avatarUrl ? (
          <StaticImage
            source={{uri: topArtist.avatarUrl}}
            resizeWidth={ImageSize.player}
            style={{width: '100%', height: '100%'}}
          />
        ) : (
          <View
            style={{
              width: '100%',
              height: '100%',
              backgroundColor: theme.colors.backgroundDark,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon
              name="userMusic"
              provider="custom"
              size={36}
              color="primary"
            />
          </View>
        )}
      </View>

      <Space h="s" />
      <TransparentButton
        onPress={() => toggleFollow(topArtist.id)}
        text={{id: 'follow'}}
        icon={{
          provider: 'custom',
          name: 'heart',
          fill: getIsFollowed(topArtist.id),
          color: getIsFollowed(topArtist.id) ? 'favoritesColor' : 'textColor',
          size: 18,
        }}
      />

      <Space h="xl" />

      <Text
        align="center"
        size="xs"
        id={
          user && user.id !== activeUserId
            ? 'spinampWrapped.topArtist.description'
            : 'spinampWrapped.topArtist.description.own'
        }
        values={{
          user: user && getUserDisplayName(user),

          artist: (
            <Text weight="semibold" align="center" size="xs">
              {topArtist.name}
            </Text>
          ),
        }}
      />

      <Space h="s" />

      <Text
        align="center"
        size="xs"
        id="spinampWrapped.topArtist.description.count"
        values={{
          artist: topArtist.name,
          count,
        }}
      />

      <Space h="xl" />

      <DelayedFadeIn isActive={isActive}>
        <TransparentButton
          onPress={nextPage}
          text={{id: 'spinampWrapped.next'}}
        />
      </DelayedFadeIn>
    </ScrollView>
  );
};

export default TopArtist;
