import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const CHECK_ICON_SIZE = 24;

export const styles = createThemedStyles(theme => ({
  category: {
    flex: 1,
  },
  categoryButton: {
    height: spacing.xl,
    paddingVertical: spacing.xxs,
  },
  categoryButton_selected: {
    backgroundColor: theme.colors.active,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    marginRight: theme.box.borderRightWidth - 1,
    marginBottom: theme.box.borderBottomWidth - 1,
    pointerEvents: 'none',
  },
  selectedIndicator: {
    position: 'absolute',
    right: -CHECK_ICON_SIZE / 4,
    top: -CHECK_ICON_SIZE / 4,
    borderWidth: 0,
    backgroundColor: theme.colors.backgroundLight,
    borderRadius: CHECK_ICON_SIZE / 2,
  },
}));
