import React, {FC} from 'react';

import {useExternalSigner} from '@/modules/ExternalWallet';

import LinkPasskey from './components/LinkPasskey';
import LinkWallet from './components/LinkWallet';
import LoginError from './components/LoginError';
import LoginInitial from './components/LoginInitial';
import SignUp from './components/SignUp';
import {useLoginState} from './useLoginState';

export const LoginRouter: FC = () => {
  const {loginRoute, passkeyCredential, loginError} = useLoginState();
  const {isConnected} = useExternalSigner();

  if (loginError) {
    return <LoginError />;
  }

  if (loginRoute === 'linkWallet' && isConnected) {
    return <LinkWallet />;
  }

  if (loginRoute === 'linkPasskey' && passkeyCredential) {
    return <LinkPasskey />;
  }

  if (loginRoute === 'signUp') {
    return <SignUp />;
  }

  return <LoginInitial />;
};
