import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AddressInfo from '@/components/AddressInfo';
import Icon from '@/components/Icon';
import Text from '@/components/Text/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useTheme, useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {MainStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './WalletCard.style';

interface IProps {
  address: IAddress;
}

const WalletCard: FC<IProps> = ({address}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const navigation = useNavigation<MainStackNavigationParams>();

  return (
    <TouchableCard
      style={style.container}
      onPress={() => navigation.navigate(Routes.Wallet, {id: address.address})}>
      {({actionColor}) => (
        <>
          <View style={style.topSection}>
            <AddressInfo
              address={address}
              color={actionColor()}
              showConnectionIndicator
            />
          </View>

          <View
            style={[
              style.divider,
              {backgroundColor: theme.colors[actionColor()]},
            ]}
          />

          <View style={style.bottomSection}>
            <View style={style.publicRow}>
              <Icon
                provider="custom"
                color={actionColor()}
                name={address.isPublic ? 'publicIcon' : 'privateIcon'}
                size={20}
              />
              <Text
                color={actionColor()}
                size="xs"
                id={
                  address.isPublic
                    ? 'walletsCard.public'
                    : 'walletsCard.private'
                }
              />
            </View>
            <Icon provider="custom" color={actionColor()} name="arrowRight" />
          </View>
        </>
      )}
    </TouchableCard>
  );
};

export default WalletCard;
