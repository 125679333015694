export type TransactionErrorCode =
  | 'WRONG_PASSKEY_SIGNER'
  | 'WALLET_NOT_CONNECTED'
  | 'WRONG_WALLET_CONNECTED'
  | 'USER_CANCELLED'
  | 'TRANSACTION_REVERTED'
  | string;

export class TransactionError extends Error {
  status: TransactionErrorCode;

  constructor(status: TransactionErrorCode) {
    super(status);
    this.status = status;
  }
}
