import {useEffect, useRef, useState, Dispatch, SetStateAction} from 'react';
import {debounce} from 'throttle-debounce';

export const useDebouncedValue = <T>(
  value: T,
  delay: number = 300,
): [T, Dispatch<SetStateAction<T>>] => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const debouncedSetter = useRef(debounce(delay, setDebouncedValue));

  useEffect(() => {
    debouncedSetter.current(value);
  }, [value]);

  return [debouncedValue, setDebouncedValue];
};
