import {AppRegistry} from 'react-native';

import {config} from '@/constants/config';

import App from './App';
import {name as appName} from './app.json';
import './web/index.css';
import {loadFonts} from './web/fontLoader';

loadFonts();

AppRegistry.registerComponent(appName, () => App);

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById(config.WEB_APP_ROOT_ID),
});
