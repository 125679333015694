import {useCallback, useMemo} from 'react';

import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {
  addTracksToQueue,
  createNewQueue,
  removeTrackFromQueue,
  selectQueue,
} from '@/store/player';
import {PlayContextType} from '@/types/common';
import {IFeedItem, ILocalMessageFeedItem} from '@/types/feed';
import {analytics} from '@/utils/analytics';

interface IInput {
  feedItem: IFeedItem | ILocalMessageFeedItem;
  trackId?: string;
}
export const useFeedItemWithQueue = ({feedItem, trackId}: IInput) => {
  const dispatch = useAppDispatch();

  const queue = useAppSelector(selectQueue);
  const queueItem = useMemo(
    () =>
      trackId &&
      queue?.items.find(
        item =>
          item.trackId === trackId &&
          item.context?.type === PlayContextType.feed &&
          item.context?.id === feedItem.id,
      ),
    [queue?.items, trackId],
  );

  const toggleTrackInQueue = useCallback(() => {
    if (queueItem) {
      dispatch(removeTrackFromQueue({trackKey: queueItem.key}));
      analytics.feedItemRemovedToQueue(
        feedItem.id,
        feedItem.entityType,
        queueItem.trackId,
      );
      return;
    }

    if (!trackId) {
      return;
    }

    analytics.feedItemAddedToQueue(feedItem.id, feedItem.entityType, trackId);

    if (queue) {
      dispatch(
        addTracksToQueue({
          items: [
            {
              trackId,
              context: {
                type: PlayContextType.feed,
                id: feedItem.id,
              },
            },
          ],
        }),
      );
      return;
    }

    dispatch(
      createNewQueue({
        items: [
          {
            trackId,
            context: {
              type: PlayContextType.feed,
              id: feedItem.id,
            },
          },
        ],
        context: {
          titleId: 'channels.title',
          type: PlayContextType.feed,
          source: 'Feed',
        },
      }),
    );
  }, [trackId, feedItem, queueItem, queue]);

  return {
    isInQueue: !!queueItem,
    toggleTrackInQueue,
    isCurrentlyPlaying: !!(
      queue &&
      queueItem &&
      queueItem.key === queue.currentTrackKey
    ),
  };
};
