import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const IMAGE_SIZE = 120;
const MINIMIZED_IMAGE_SIZE = spacing.xxl;

export const styles = createThemedStyles(theme => ({
  container: {
    gap: spacing.m,
  },
  container_minimized: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs * 1.5,
  },
  image: {
    alignSelf: 'center',
    borderRadius: theme.box.borderRadius,
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    overflow: 'hidden',
  },
  image_minimized: {
    width: MINIMIZED_IMAGE_SIZE,
    height: MINIMIZED_IMAGE_SIZE,
  },
  textsContainer: {
    gap: spacing.xxs,
  },
  textsContainer_minimized: {
    flex: 1,
  },
}));
