import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import OpacityGradient from '@/components/OpacityGradient/OpacityGradient';
import Space from '@/components/Space/Space';
import {useResponsive} from '@/hooks/useResponsive';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useTheme, useThemedStyles} from '@/theme';
import {Color} from '@/types/theme';
import {isIOS} from '@/utils/platform';

import {styles, BORDER_WIDTH} from './WrappedPage.style';

interface IProps {
  index: number;
  width: number;
  scrollPosition: SharedValue<number>;
  children: ReactNode;
}

const WrappedPage: FC<IProps> = ({index, width, scrollPosition, children}) => {
  const style = useThemedStyles(styles);
  const {isMobile} = useResponsive();
  const theme = useTheme();

  const {close, prevPage, nextPage, pages} = useSpinampWrapped();

  const perspective = width * 2;
  const angle = Math.atan(perspective / width);

  const wrapperStyle = useAnimatedStyle(() => ({
    width,
    transform: [{translateX: scrollPosition.value}],
    zIndex: scrollPosition.value === width * index ? 1 : 0,
  }));

  const contentStyle = useAnimatedStyle(() => {
    const offset = width * index;
    const inputRange = [offset - width, offset + width];

    const translateX = interpolate(
      scrollPosition.value,
      inputRange,
      [width / 2, -width / 2],
      Extrapolation.CLAMP,
    );
    const rotateY = interpolate(
      scrollPosition.value,
      inputRange,
      [angle, -angle],
      Extrapolation.CLAMP,
    );

    const alpha = Math.abs(rotateY);
    const gamma = angle - alpha;
    const beta = Math.PI - alpha - gamma;
    const w = width / 2 - ((width / 2) * Math.sin(gamma)) / Math.sin(beta);
    const translateX2 = rotateY > 0 ? w : -w;

    return {
      transform: [
        {perspective},
        {translateX},
        {rotateY: `${rotateY}rad`},
        {translateX: translateX2},
      ],
    };
  }, [index, width]);

  const colors: Color[] = ['backgroundLight', 'background'];
  const color = colors[index % colors.length];

  return (
    <Animated.View style={[style.wrapper, isIOS && wrapperStyle, {width}]}>
      <Animated.View
        style={[
          style.content,
          isIOS && contentStyle,
          {backgroundColor: theme.colors[color]},
          index === 0 && {borderLeftWidth: isMobile ? BORDER_WIDTH : 0},
          index === pages.length - 1 && {
            borderRightWidth: isMobile ? BORDER_WIDTH : 0,
          },
        ]}>
        <View style={style.header}>
          <IconButton
            icon={{name: 'close', provider: 'custom'}}
            onPress={close}
          />
        </View>
        <View style={style.children}>
          <View style={style.gradientTop}>
            <OpacityGradient vertical color={color} />
          </View>
          {children}
          <View style={style.gradientBottom}>
            <OpacityGradient vertical color={color} />
          </View>
        </View>
        <View style={style.footer}>
          {index > 0 && (
            <IconButton
              icon={{name: 'arrowLeft', provider: 'custom'}}
              onPress={prevPage}
            />
          )}
          <Space flex />
          {index < pages.length - 1 && (
            <IconButton
              icon={{name: 'arrowRight', provider: 'custom'}}
              onPress={nextPage}
            />
          )}
        </View>
      </Animated.View>
    </Animated.View>
  );
};

export default WrappedPage;
