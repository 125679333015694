import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';
import {selectSelectedUserId} from '@/store/user';

export const selectTheme = (state: RootState) => state.theme;

export const selectDefaultTheme = createSelector(
  selectTheme,
  theme => theme.defaultTheme || 'spinamp',
);

export const selectThemeSettings = createSelector(
  selectSelectedUserId,
  selectTheme,
  (activeUserId, theme) =>
    activeUserId ? theme.themesSettings[activeUserId] : undefined,
);

export const selectCardsTheming = createSelector(
  selectTheme,
  user => user.cardsThemingEnabled,
);
