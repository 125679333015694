import {Dimensions, PixelRatio} from 'react-native';

import {isWeb} from '@/utils/platform';

const {height: SCREEN_HEIGHT} = Dimensions.get('screen');
const UI_DEVICE_SCREEN_HEIGHT = 844;
const SCALE = SCREEN_HEIGHT / UI_DEVICE_SCREEN_HEIGHT;

export const normalizeSize = (size: number) => {
  if (isWeb) {
    return size;
  }

  return PixelRatio.roundToNearestPixel(SCALE * size);
};
