// search for tracks and artists is implemented in local-first way. It means that we always show results of local filtering of central tracks db
// and in the background we do server side search which updates central db with new search results
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchArtistsBySearchPhrase} from '@/api/artist';
import {searchPlaylists} from '@/api/playlist';
import {fetchTracksBySearchPhrase} from '@/api/track';
import {fetchUsersBySearchPhrase} from '@/api/user';
import {useDbQuery} from '@/queries/db';
import {QueryKeys} from '@/types/queryKeys';
import {searchArtists, searchTracks} from '@/utils/db';
import {sortTracksByDate} from '@/utils/tracks';

const SEARCH_RESULTS_LIMIT = 50;
const SEARCH_STALE_TIME = 1000 * 60;

export const useTracksSearchQuery = (search: string = '') => {
  const {db, updateDb} = useDbQuery();
  const query = useQuery({
    queryKey: [QueryKeys.search, QueryKeys.searchTracks, search],
    queryFn: async () => {
      const tracks = await fetchTracksBySearchPhrase(search);
      updateDb({tracks});
      return null;
    },
    staleTime: SEARCH_STALE_TIME,
    gcTime: SEARCH_STALE_TIME,
    enabled: !!search,
  });

  const tracks = useMemo(
    () =>
      searchTracks(db, search)
        .sort(sortTracksByDate)
        .slice(0, SEARCH_RESULTS_LIMIT),
    [db.tracks, search],
  );

  return {
    tracks,
    query,
  };
};

export const useArtistsSearchQuery = (search: string = '') => {
  const {db, updateDb} = useDbQuery();
  const query = useQuery({
    queryKey: [QueryKeys.search, QueryKeys.searchArtists, search],
    queryFn: async () => {
      const artists = await fetchArtistsBySearchPhrase(search);
      updateDb({artists});
      return null;
    },
    staleTime: SEARCH_STALE_TIME,
    gcTime: SEARCH_STALE_TIME,
    enabled: !!search,
  });

  const artists = useMemo(
    () => searchArtists(db, search).slice(0, SEARCH_RESULTS_LIMIT),
    [db.artists, search],
  );

  return {
    artists,
    query,
  };
};

export const useUsersSearchQuery = (search: string = '') => {
  const query = useQuery({
    queryKey: [QueryKeys.search, QueryKeys.searchUsers, search],
    queryFn: () => fetchUsersBySearchPhrase(search),
    enabled: search.length > 1,
    staleTime: SEARCH_STALE_TIME,
    gcTime: SEARCH_STALE_TIME,
    placeholderData: keepPreviousData,
  });
  const users = (query.data || []).filter(u => u.addresses.length > 0);

  return {
    users: search ? users : [],
    query,
  };
};

export const usePlaylistsSearchQuery = (search: string = '') => {
  const query = useQuery({
    queryKey: [QueryKeys.search, QueryKeys.searchPlaylists, search],
    queryFn: () => searchPlaylists(search),
    enabled: !!search,
    staleTime: SEARCH_STALE_TIME,
    gcTime: SEARCH_STALE_TIME,
    placeholderData: keepPreviousData,
  });

  return {
    playlists: query.data || [],
    query,
  };
};

export const useBulkSearchQuery = (search: string) => {
  usePlaylistsSearchQuery(search);
  const {
    tracks,
    query: {isFetching: areTracksFetching},
  } = useTracksSearchQuery(search);
  const {
    artists,
    query: {isFetching: areArtistsFetching},
  } = useArtistsSearchQuery(search);
  const {
    users,
    query: {isFetching: areUsersFetching},
  } = useUsersSearchQuery(search);
  const {
    playlists,
    query: {isFetching: arePlaylistsFetching},
  } = usePlaylistsSearchQuery(search);

  return {
    areTracksFetching,
    tracks,
    areArtistsFetching,
    artists,
    areUsersFetching,
    users,
    playlists,
    arePlaylistsFetching,
  };
};
