import {
  RainbowKitProvider,
  lightTheme,
  getDefaultConfig,
} from '@rainbow-me/rainbowkit';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  zerionWallet,
  metaMaskWallet,
  safeWallet,
  trustWallet,
  coinbaseWallet,
} from '@rainbow-me/rainbowkit/wallets';
import React, {FC, ReactNode} from 'react';
import {createConfig, WagmiProvider} from 'wagmi';
import {walletConnect} from 'wagmi/connectors';

import {config} from '@/constants/config';
import {useTheme} from '@/theme';
import {isMobileBrowser} from '@/utils/platform';

import {
  chains,
  transports,
  projectId,
  wagmiStorage,
  supportedWalletIds,
} from './shared';

import '@rainbow-me/rainbowkit/styles.css';

const desktopConfig = getDefaultConfig({
  appName: config.APP_NAME,
  projectId,
  chains,
  storage: wagmiStorage,
  wallets: [
    {
      groupName: 'Popular',
      wallets: [
        injectedWallet,
        metaMaskWallet,
        rainbowWallet,
        zerionWallet,
        safeWallet,
        trustWallet,
        coinbaseWallet,
        walletConnectWallet,
      ],
    },
  ],
  transports,
});

const mobileConfig = createConfig({
  chains,
  connectors: [
    walletConnect({
      projectId,
      qrModalOptions: {
        explorerExcludedWalletIds: 'ALL',
        explorerRecommendedWalletIds: supportedWalletIds,
      },
    }),
  ],
  storage: wagmiStorage,
  transports,
});

export const wagmiConfig = isMobileBrowser ? mobileConfig : desktopConfig;

const WalletMobileProvider: FC<{children: ReactNode}> = ({children}) => {
  return <WagmiProvider config={mobileConfig}>{children}</WagmiProvider>;
};

const WalletDesktopProvider: FC<{children: ReactNode}> = ({children}) => {
  const theme = useTheme();
  const rainbowTheme = lightTheme();
  rainbowTheme.colors.modalBackground = theme.colors.backgroundLight;
  rainbowTheme.colors.modalBorder = theme.colors.borderColor;
  rainbowTheme.colors.modalText = theme.colors.textColor;
  rainbowTheme.colors.modalTextSecondary = theme.colors.textColor;
  rainbowTheme.colors.closeButton = theme.colors.textColor;
  rainbowTheme.fonts.body = theme.font.light;
  rainbowTheme.radii.modal = theme.box.borderRadius.toString() + 'px';

  return (
    <WagmiProvider config={desktopConfig}>
      <RainbowKitProvider theme={rainbowTheme} coolMode>
        {children}
      </RainbowKitProvider>
    </WagmiProvider>
  );
};

export default isMobileBrowser ? WalletMobileProvider : WalletDesktopProvider;
