import {
  init,
  ErrorBoundary,
  captureException,
  captureMessage,
  setUser,
} from '@sentry/react';

import {config} from '@/constants/config';

const initialize = () => {
  if (process.env.NODE_ENV !== 'development') {
    init({
      dsn: config.SENTRY_DSN,
      tracesSampleRate: 1.0,
      environment: config.ENV,
    });
  }
};

export {initialize, ErrorBoundary, captureException, captureMessage, setUser};
