import {merge} from 'lodash';

import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {DeepPartial} from '@/types/helpers';
import {
  IBaseUser,
  IUser,
  IUserMetadata,
  UserMetadataSchema,
} from '@/types/user';
import {
  areAddressesEqual,
  areAddressesShared,
  prettifyAddress,
} from '@/utils/ethereum';
import {Sentry} from '@/services/sentry';

export const getUserAvatar = (user: IBaseUser) =>
  user.avatarUrl ||
  user.addresses.find(a => a.avatarUrl)?.avatarUrl ||
  undefined;

export const getUserEns = (user: IBaseUser) =>
  user.addresses.find(a => a.ens)?.ens;

export const getUserName = (user: IBaseUser) => user.name || getUserEns(user);

export const getUserPublicAddress = (user: IBaseUser) =>
  user.addresses.find(a => a.isPublic)?.address;

export const getUserAddress = (user: IBaseUser) =>
  getUserPublicAddress(user) || user.id;

export const getUserDisplayName = (user: IBaseUser) =>
  getUserName(user) || prettifyAddress(getUserAddress(user));

export const getUserWallets = (user: IBaseUser) =>
  user.addresses.filter(a => a.isWallet);

const getUserWallet = (user: IBaseUser) =>
  user.addresses.find(isPasskeyWallet) || user.addresses.find(a => a.isWallet);

export const getUserPaymentWallet = (user: IUser) =>
  user.addresses.find(a => a.address === user.metadata?.defaultPaymentWallet) ||
  getUserWallet(user);

export const getUserDeliveryWallet = (user: IUser) =>
  user.addresses.find(
    a => a.address === user.metadata?.defaultDeliveryWallet,
  ) || getUserPaymentWallet(user);

export const getUserPasskeyWallet = (user: IUser) =>
  user.addresses.find(isPasskeyWallet);

export const sortUserWallets = (user: IUser) => {
  const wallets = user.addresses.filter(a => a.isWallet);
  const passkeyWalletIndex = wallets.findIndex(a => a.isPasskey);

  if (passkeyWalletIndex === -1) {
    return wallets;
  }

  const passkeyWallet = wallets[passkeyWalletIndex];
  wallets.splice(passkeyWalletIndex, 1);

  return [passkeyWallet, ...wallets];
};

export const getUserSlug = (user: IBaseUser) => getUserEns(user) || user.id;

export const getUserPublicAddresses = (user: IBaseUser) =>
  user.addresses.filter(address => address.isPublic);

export const findUserByAddress = (users: IBaseUser[], userAddress?: string) => {
  if (!userAddress) {
    return undefined;
  }

  return users.find(user =>
    user.addresses.some(({address}) => areAddressesEqual(address, userAddress)),
  );
};

export const isAddressOwnedByUser = (user: IBaseUser, address: string) =>
  areAddressesShared(
    user.addresses.map(a => a.address),
    [address],
  );

export const parseUserMetadata = (
  metadata: any | undefined,
  userId: string,
): IUserMetadata => {
  try {
    return UserMetadataSchema.parse(metadata || {});
  } catch (error: any) {
    Sentry.captureException(error, {
      extra: {
        context: `Error parsing user metadata for ${userId}`,
        metadata,
        userId,
      },
    });
    return UserMetadataSchema.parse({});
  }
};

export const mergeUserMetadata = (
  currentMetadata: IUserMetadata,
  metadataUpdate: DeepPartial<IUserMetadata>,
): IUserMetadata => merge({}, currentMetadata, metadataUpdate);

export const getIsArtistDropNotificationEnabled = (
  user: IUser | undefined,
  artistId: string,
) =>
  user?.metadata.notificationSettings.collectorProfile.trackDrop.artists[
    artistId
  ] !== false;
