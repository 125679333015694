import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  releaseTimeContainer: {
    width: '100%',
    backgroundColor: theme.colors.borderColor,
    padding: spacing.xxs,
  },
}));
