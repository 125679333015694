import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  actions: {
    alignItems: 'stretch',
    gap: spacing.l,
  },
  secondaryAction: {
    alignSelf: 'stretch',
  },
}));
