import {useIsFocused, useScrollToTop} from '@react-navigation/native';
import React, {useEffect, useRef, forwardRef, useImperativeHandle} from 'react';
import {useIntl} from 'react-intl';
import {TextInput, TextInputProps, TouchableOpacity} from 'react-native';

import Icon from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import {useTheme, useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles} from './SearchInput.style';

interface IProps extends TextInputProps {
  value: string;
  setValue: (value: string) => void;
  onClear: () => void;
  placeholderId?: string;
  autoFocus?: boolean;
  focusOnTabPress?: boolean;
}

const SearchInput = forwardRef<TextInput, IProps>(
  (
    {
      value,
      setValue,
      onClear,
      placeholderId,
      autoFocus = true,
      focusOnTabPress = false,
      ...textInputProps
    },
    ref,
  ) => {
    const theme = useTheme();
    const style = useThemedStyles(styles);
    const isScreenFocused = useIsFocused();
    const {formatMessage} = useIntl();
    const inputRef = useRef<TextInput | null>(null);

    // @ts-ignore
    useImperativeHandle(ref, () => inputRef.current);

    useEffect(() => {
      if (isScreenFocused && autoFocus) {
        inputRef.current?.focus();
      }
    }, [isScreenFocused, autoFocus]);

    useEffect(() => {
      if (!isScreenFocused) {
        inputRef.current?.blur();
      }
    }, [isScreenFocused]);

    // This hook by design is to provide scroll to top on tab press functionality, however it works well with all kind of handlers.
    useScrollToTop(
      useRef({
        scrollToTop: () => {
          if (!isWeb && focusOnTabPress) {
            inputRef?.current?.focus();
          }
        },
      }),
    );

    return (
      <Space style={style.inputWrapper}>
        <Space p="xs" style={style.iconWrapper}>
          <Icon name="magnifier" size={16} />
        </Space>
        <TextInput
          ref={inputRef}
          style={[style.input, !!value && style.inputActive]}
          value={value}
          onChangeText={setValue}
          placeholder={placeholderId && formatMessage({id: placeholderId})}
          placeholderTextColor={theme.colors.textColor}
          autoFocus={autoFocus}
          {...textInputProps}
        />
        {!!value && (
          <Space p="xs">
            <IconButton
              icon={{name: 'close', provider: 'custom'}}
              onPress={onClear}
              size={24}
            />
          </Space>
        )}
      </Space>
    );
  },
);

export default SearchInput;
