import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {useMutation} from '@tanstack/react-query';
import React, {FC, useEffect} from 'react';

import {updateUser} from '@/api/user';
import {useGlobalModal} from '@/components/GlobalModal/GlobalModal';
import Header from '@/components/Header';
import Screen from '@/components/Screen/Screen';
import ScreenError from '@/components/ScreenError';
import ScreenLoader from '@/components/ScreenLoader';
import ThemeCreator from '@/components/ThemeCreator';
import {useCanEditCollector} from '@/hooks/useCanEdit';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useUserQuery} from '@/queries/user';
import {queryClient} from '@/services/reactQuery';
import {Sentry} from '@/services/sentry';
import {setThemeSettings} from '@/store/theme';
import {selectActiveUserSigner} from '@/store/user';
import {ThemedScreen, useThemedScreen} from '@/theme';
import {QueryKeys} from '@/types/queryKeys';
import {
  MainStackNavigationParams,
  MainStackParams,
  Routes,
} from '@/types/routes';
import {IDynamicTheme} from '@/types/theme';
import {IUserUpdate} from '@/types/user';
import {analytics} from '@/utils/analytics';

const CollectorThemeEdit: FC<{}> = () => {
  const {
    params: {slug},
  } = useRoute<RouteProp<MainStackParams, Routes.CollectorEdit>>();
  const navigation = useNavigation<MainStackNavigationParams>();
  const dispatch = useAppDispatch();
  const signer = useAppSelector(selectActiveUserSigner);

  const modal = useGlobalModal();

  const {
    user,
    query: {isFetching: isUserFetching},
  } = useUserQuery(slug);

  const canEditCollector = useCanEditCollector(user);

  useEffect(() => {
    // Kick the user out of the edit page if they don't have permission to edit
    if (!canEditCollector) {
      navigation.goBack();
    }
  }, [canEditCollector]);

  useEffect(() => {
    if (user?.id) {
      analytics.themeEditVisited(Routes.CollectorThemeEdit, user.id);
    }
  }, [user?.id]);

  const theme = useThemedScreen({
    customTheme: user?.customTheme,
    predefinedThemeName: user?.predefinedThemeName,
  });

  const {showToast} = useToast();

  const updateUserUsingSignMessage = (userChanges: IUserUpdate) => {
    if (!signer) {
      throw new Error('missing signer');
    }

    return updateUser(userChanges, signer);
  };

  const mutation = useMutation({
    mutationFn: updateUserUsingSignMessage,
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.userBySlug, slug],
        }),
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.userProfiles],
        }),
      ]);
      navigation.goBack();
      dispatch(
        setThemeSettings({
          type: variables.customTheme ? 'collector' : 'predefined',
          name: variables.predefinedThemeName,
        }),
      );
      showToast({textId: 'profileEdit.saved'});
    },
    onError: (error: any) => {
      Sentry.captureException(error);
    },
  });

  const onSubmitForm = async ({
    customTheme,
    predefinedThemeName,
  }: IDynamicTheme) => {
    mutation.mutate({
      id: user!.id,
      customTheme,
      predefinedThemeName,
    });
  };

  const renderContent = () => {
    const titleId = 'themeEdit.title';

    if (isUserFetching && !user) {
      return (
        <Screen>
          <Header showBack titleId={titleId} />
          <ScreenLoader />
        </Screen>
      );
    }

    if (!user) {
      return (
        <Screen>
          <Header showBack titleId={titleId} />
          <ScreenError textId="notFound" />
        </Screen>
      );
    }

    return (
      <ThemeCreator
        titleId={titleId}
        infoTextId="themeCreator.info.collector"
        isSaving={mutation.isLoading}
        onSubmit={onSubmitForm}
        onCancel={() => {
          modal.openModal({
            titleId: 'themeEdit.discard.title',
            descriptionId: 'themeEdit.discard.description',
            confirmTextId: 'themeEdit.discard.confirm',
            cancelTextId: 'themeEdit.discard.cancel',
            onConfirm: navigation.goBack,
          });
        }}
        initialValues={{
          customTheme: user.customTheme,
          predefinedThemeName: user.predefinedThemeName,
        }}
      />
    );
  };

  return <ThemedScreen theme={theme}>{renderContent()}</ThemedScreen>;
};

export default CollectorThemeEdit;
