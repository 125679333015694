import {useNavigation} from '@react-navigation/native';
import {UseQueryResult} from '@tanstack/react-query';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {useBalance} from 'wagmi';

import {useActiveUser, useUserById} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {useTrackCollectInfoQuery} from '@/modules/Collect/queries/collect';
import {
  ICollectTransactionState,
  IMintOfferInfo,
  ITrackCollectInfo,
} from '@/modules/Collect/types';
import {
  getOptionalUserWalletById,
  getUserDefaultWalletsSettings,
} from '@/modules/Collect/utils';
import {
  useGasEstimation,
  useSyncTransactionState,
} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {updateTransaction as updateTransactionAction} from '@/store/transactions/slice';
import {IAddress, IBalance, ITrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {addGasToPrice} from '@/utils/ethereum';
import {generateId, getLastItem} from '@/utils/functions';

export type CollectModalRoute =
  | 'walletsPicker'
  | 'addExternalWallet'
  | 'addPasskeyWallet'
  | 'addCustomAddress';

export interface ICollectStateContext {
  transaction: ICollectTransactionState;
  updateTransaction: (update: Partial<ICollectTransactionState>) => void;
  track: ITrack;
  user: IUser | undefined;
  modal: CollectModalRoute;
  pushModal: (route: CollectModalRoute) => void;
  popModal: () => void;
  paymentWallet: IAddress | undefined;
  deliveryWallet: IAddress | undefined;
  transferEnabled: boolean;
  collectInfo: ITrackCollectInfo | undefined;
  collectInfoQuery: UseQueryResult<ITrackCollectInfo>;
  selectedOffer: IMintOfferInfo | undefined;
  selectedOfferTotalPrice: bigint | undefined;
  balance?: IBalance;
  gasEstimation: IGasEstimation;
  resetTxDetails: () => void;
  closeCollectModal: () => void;
}

export const CollectStateContext = createContext<
  ICollectStateContext | undefined
>(undefined);

interface IProps {
  transactionId: string | undefined;
  children: ReactNode;
  track: ITrack;
  referralAddress?: string;
}

const CollectStateProvider: FC<IProps> = ({
  track,
  referralAddress,
  children,
  transactionId,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const activeUser = useActiveUser();

  const {transaction, isInitialized} = useSyncTransactionState(
    transactionId,
    'collect',
    () =>
      ({
        id: generateId(),
        createdDate: Date.now(),
        isMinimized: false,
        type: 'collect',
        userId: activeUser?.id,
        step: 'checkout',
        slug: track.slug,
        referralAddress,
        walletsSettings: getUserDefaultWalletsSettings(activeUser),
        quantity: 1,
        collectInfo: undefined,
        selectedOfferId: undefined,
        txHash: undefined,
        approvalTxHash: undefined,
        userOpHash: undefined,
        approvalUserOpHash: undefined,
        error: undefined,
        token: undefined,
      } satisfies ICollectTransactionState),
  );

  const updateTransaction = useCallback(
    (update: Partial<ICollectTransactionState>) => {
      dispatch(
        updateTransactionAction({id: transaction.id, type: 'collect', update}),
      );
    },
    [transaction.id],
  );

  const [modalsStack, setModalsStack] = useState<CollectModalRoute[]>([]);
  const modal = getLastItem(modalsStack);
  const pushModal = (newModal: CollectModalRoute) => {
    setModalsStack(currentStack => {
      if (getLastItem(currentStack) === newModal) {
        return currentStack;
      }
      return [...currentStack, newModal];
    });
  };
  const popModal = () => {
    setModalsStack(currentStack => currentStack.slice(0, -1));
  };

  const user = useUserById(transaction.userId);

  const walletsSettings = transaction.walletsSettings;
  const paymentWallet = getOptionalUserWalletById(
    user,
    walletsSettings?.paymentAddress,
  );
  const deliveryWallet = getOptionalUserWalletById(
    user,
    walletsSettings?.deliveryAddress,
  );

  // Set user data on transaction after using signed in from collect modal
  useEffect(() => {
    if (activeUser && !transaction.userId) {
      updateTransaction({
        userId: activeUser.id,
        walletsSettings: getUserDefaultWalletsSettings(activeUser),
      });
    }
  }, [activeUser?.id, transaction.id]);

  const {collectInfo: _collectInfo, query} = useTrackCollectInfoQuery(
    {
      trackId: track.id,
      userAddress: walletsSettings?.paymentAddress,
      quantity: transaction.quantity,
      referralAddress,
    },
    {
      keepPreviousData: true,
    },
  );

  const collectInfo = transaction.collectInfo || _collectInfo;

  // get selected offer by id or auto-select first if not found
  const selectedOffer =
    collectInfo?.mintOffers.find(
      ({id}) => id === transaction.selectedOfferId,
    ) || collectInfo?.mintOffers[0];

  // set available offers in redux, so we can still show proper UI if offers changed during pending transactions
  useEffect(() => {
    if (!isInitialized || !_collectInfo || transaction.step !== 'checkout') {
      return;
    }

    updateTransaction({collectInfo: _collectInfo});
  }, [isInitialized, updateTransaction, _collectInfo, transaction.step]);

  const {data: balance} = useBalance({
    address: paymentWallet?.address,
    chainId: selectedOffer?.chainId,
    query: {
      enabled: !!paymentWallet && !!selectedOffer?.chainId,
    },
  });

  const gasEstimation = useGasEstimation({
    chainId: selectedOffer?.chainId,
    wallet: paymentWallet,
    transaction: selectedOffer && {
      to: selectedOffer.mintTransaction.to,
      data: selectedOffer.mintTransaction.data,
      value: selectedOffer.mintTransaction.value,
    },
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          collect: true,
          simulation: true,
          passkeyWallet: !!paymentWallet && isPasskeyWallet(paymentWallet),
        },
        extra: {
          trackId: track.id,
          wallet: paymentWallet,
        },
      });
    },
  });

  return (
    <CollectStateContext.Provider
      value={{
        transaction,
        updateTransaction,
        track,
        user,
        modal,
        pushModal,
        popModal,
        paymentWallet,
        deliveryWallet,
        transferEnabled:
          walletsSettings?.paymentAddress !== walletsSettings?.deliveryAddress,
        collectInfo,
        collectInfoQuery: query,
        selectedOffer,
        selectedOfferTotalPrice:
          selectedOffer &&
          addGasToPrice(selectedOffer.price.value, gasEstimation.totalGas),
        balance,
        gasEstimation,
        resetTxDetails: () =>
          updateTransaction({
            txHash: undefined,
            approvalTxHash: undefined,
            userOpHash: undefined,
            approvalUserOpHash: undefined,
            error: undefined,
            token: undefined,
          }),
        closeCollectModal: navigation.goBack,
      }}>
      {children}
    </CollectStateContext.Provider>
  );
};

export default CollectStateProvider;
