import React, {FC, ReactNode} from 'react';
import {Pressable, StyleProp, View} from 'react-native';

import Icon from '@/components/Icon';
import Loader from '@/components/Loader';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import UploadProgress from '@/components/UploadProgress';
import spacing from '@/constants/spacing';
import {IImageUploaderResult} from '@/hooks/useImageUploader';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';

import {styles} from './ImagePicker.style';

interface IProps extends IImageUploaderResult {
  previewSize: number;
  placeholder?: ReactNode;
  resizeWidth?: ImageSize | number;
  currentImageUri?: string;
  circular?: boolean;
  previewStyle?: StyleProp<any>;
  minSize?: number;
}

const ImagePicker: FC<IProps> = ({
  previewSize,
  placeholder,
  pickedImage,
  currentImageUri,
  previewStyle,
  resizeWidth,
  circular,
  selectImage,
  isUploading,
  uploadProgress,
  sizeError,
  minSize,
}) => {
  const style = useThemedStyles(styles);
  const uri = pickedImage?.uri || currentImageUri;

  return (
    <View style={style.avatarContainer}>
      <Pressable
        onPress={selectImage}
        // @ts-ignore
        style={({pressed, hovered}) => [
          hovered && style.hovered,
          pressed && style.pressed,
        ]}>
        <View
          style={[
            style.avatarEditIcon,
            circular && style.avatarEditIcon_circular,
          ]}>
          <Icon name="edit" provider="custom" size={18} />
        </View>
        <View
          style={[
            style.avatarPreviewWrapper,
            {width: previewSize, height: previewSize},
            circular && {borderRadius: previewSize / 2},
            previewStyle,
          ]}>
          {isUploading ? (
            <View style={style.uploadContainer}>
              <View>
                <View style={style.uploadingLabel}>
                  <Loader textId="uploading" weight="regular" size="xs" />
                </View>
                <UploadProgress
                  progress={uploadProgress}
                  height={spacing.xs}
                  showPercentage={false}
                  style={style.uploadProgress}
                />
              </View>
            </View>
          ) : uri ? (
            <StaticImage
              source={{
                uri,
              }}
              style={style.avatarPreview}
              resizeWidth={resizeWidth}
            />
          ) : (
            placeholder
          )}
        </View>
      </Pressable>
      <View style={style.hintContainer}>
        {sizeError && (
          <Text
            size="xxs"
            id="avatarPicker.tooSmall"
            align="center"
            values={{size: minSize}}
          />
        )}
      </View>
    </View>
  );
};

export default ImagePicker;
