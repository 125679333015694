import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';

const TRACK_PREVIEW_SIZE = spacing.xxl;

export const styles = createThemedStyles(theme => ({
  centeredColumn: {
    alignItems: 'center',
  },
  trackPreviews: {
    alignSelf: 'stretch',
    marginTop: spacing.l,
    marginHorizontal: -spacing.s,
    marginBottom: spacing.l,
    height: TRACK_PREVIEW_SIZE,
  },
  trackPreviewsContent: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  trackPreviewWrapper: {
    width: TRACK_PREVIEW_SIZE,
    height: TRACK_PREVIEW_SIZE,
    overflow: 'hidden',
  },
  trackPreview: {
    width: '100%',
    height: '100%',
  },
  trackPreviewPlaceholder: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.primary,
  },
  artwork: {
    width: ImageSize.playlistArtwork,
    aspectRatio: 1,
    overflow: 'hidden',
    borderRadius: theme.box.borderRadius,
  },
  curatorsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlignVertical: 'center',
    gap: spacing.xxs,
    maxWidth: '100%',
  },
  curators: {
    flexShrink: 1,
  },
  invitationContainer: {
    backgroundColor: theme.colors.background,
    marginHorizontal: -spacing.s,
    padding: spacing.s,
    paddingBottom: spacing.xs,
    alignSelf: 'stretch',
  },
  invitationButton: {
    alignSelf: 'center',
  },
  actions: {
    flexDirection: 'row',
    gap: spacing.l + spacing.xs,
  },
}));
