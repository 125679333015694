import React, {FC, ReactNode} from 'react';
import {StyleProp, TouchableOpacity, View} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import Card from '@/components/Card/Card';
import Icon from '@/components/Icon/Icon';
import {getHitSlop} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {isAndroid} from '@/utils/platform';

import {CLOSE_ICON_SIZE, styles} from './Toast.style';

export interface IToastProps {
  children: ReactNode;
  onPress?: () => void;
  onClose?: () => void;
  closeOnPress?: boolean;
  footer?: ReactNode;
  contentStyle?: StyleProp<any>;
}

const AnimatedTouchableOpacity =
  Animated.createAnimatedComponent(TouchableOpacity);

const getLayoutAnimation = <AnimationType,>(animation: AnimationType) => {
  if (isAndroid) {
    return undefined;
  }

  return animation;
};

export const Toast: FC<IToastProps> = ({
  children,
  onPress,
  onClose,
  closeOnPress,
  footer,
  contentStyle,
}) => {
  const style = useThemedStyles(styles);

  return (
    <AnimatedTouchableOpacity
      onPress={onPress || onClose}
      disabled={!onPress && !closeOnPress}
      activeOpacity={1}
      entering={getLayoutAnimation(FadeIn)}
      exiting={getLayoutAnimation(FadeOut)}>
      <Card inverted style={style.card}>
        <View style={[style.toast, onClose && style.toast_close]}>
          <View style={[style.content, contentStyle]}>{children}</View>

          {onClose && (
            <TouchableOpacity
              style={style.closeButton}
              onPress={onClose}
              hitSlop={getHitSlop('s')}>
              <Icon
                name="close"
                color="invertedTextColor"
                size={CLOSE_ICON_SIZE}
              />
            </TouchableOpacity>
          )}
        </View>
        {footer}
      </Card>
    </AnimatedTouchableOpacity>
  );
};

export default Toast;
