import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './Divider.style';

interface IProps {
  style?: StyleProp<any>;
}

const Divider: FC<IProps> = ({style: customStyle}) => {
  const style = useThemedStyles(styles);

  return <View style={[style.divider, customStyle]} />;
};

export default Divider;
