import {chainsByCode, supportedChains} from '@/constants/chains';
import {AddressString, IExchangeRates} from '@/types/common';
import {getChainClient} from '@/utils/ethereum';

const aggregatorV3InterfaceABI = [
  {
    inputs: [],
    name: 'decimals',
    outputs: [{internalType: 'uint8', name: '', type: 'uint8'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'description',
    outputs: [{internalType: 'string', name: '', type: 'string'}],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{internalType: 'uint80', name: '_roundId', type: 'uint80'}],
    name: 'getRoundData',
    outputs: [
      {internalType: 'uint80', name: 'roundId', type: 'uint80'},
      {internalType: 'int256', name: 'answer', type: 'int256'},
      {internalType: 'uint256', name: 'startedAt', type: 'uint256'},
      {internalType: 'uint256', name: 'updatedAt', type: 'uint256'},
      {internalType: 'uint80', name: 'answeredInRound', type: 'uint80'},
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'latestRoundData',
    outputs: [
      {internalType: 'uint80', name: 'roundId', type: 'uint80'},
      {internalType: 'int256', name: 'answer', type: 'int256'},
      {internalType: 'uint256', name: 'startedAt', type: 'uint256'},
      {internalType: 'uint256', name: 'updatedAt', type: 'uint256'},
      {internalType: 'uint80', name: 'answeredInRound', type: 'uint80'},
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'version',
    outputs: [{internalType: 'uint256', name: '', type: 'uint256'}],
    stateMutability: 'view',
    type: 'function',
  },
];

/**
 * For all chains with ETH as native currency we use ETH mainnet price.
 * For non ETH chains we fetch dedicated price (polygon only in out case).
 *
 * https://docs.chain.link/data-feeds/price-feeds/addresses?network=ethereum&page=1
 */

const chainContracts = {
  [chainsByCode.ethereum.id]: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  [chainsByCode.polygon.id]: '0xAB594600376Ec9fD91F8e885dADF0CE036862dE0',
};

export const fetchExchangeRates = async () => {
  const fetchedPrices: IExchangeRates = {};
  const allPrices: IExchangeRates = {};

  await Promise.all(
    Object.entries(chainContracts).map(async ([chainId, contractAddress]) => {
      const result = (await getChainClient(Number(chainId)).readContract({
        abi: aggregatorV3InterfaceABI,
        address: contractAddress as AddressString,
        functionName: 'latestRoundData',
      })) as bigint[];
      const answer = result[1];
      fetchedPrices[Number(chainId)] = Number(answer) / 1e8;
    }),
  );

  supportedChains.forEach(chain => {
    allPrices[chain.id] =
      fetchedPrices[chain.id] || fetchedPrices[chainsByCode.ethereum.id];
  });

  return allPrices;
};
