import {useEffect, useState} from 'react';

import {TrackPlayer} from '@/services/trackPlayer';

export const usePlayerProgress = () => {
  const [position, setPosition] = useState(0);
  const [duration, setDuration] = useState(0);

  const updateProgress = async () => {
    const [updatedPosition, updatedDuration] = await Promise.all([
      TrackPlayer.getPosition(),
      TrackPlayer.getDuration(),
    ]);
    setPosition(updatedPosition);
    setDuration(updatedDuration);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateProgress();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return {
    position,
    duration,
  };
};
