import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';

import {selectActiveUserId, selectActiveUserSigner} from '@/store/user';

import {useAppSelector} from './useRedux';

/**
 * Resumes paused React Query mutations every time the app mounts or the active user ID or signer changes.
 *
 * We define default mutation functions before resuming, so that we don't have undefined mutation functions.
 * https://tanstack.com/query/v4/docs/react/guides/mutations#persisting-offline-mutations
 */
export const useResumePausedMutations = () => {
  const queryClient = useQueryClient();
  const userId = useAppSelector(selectActiveUserId);
  const signer = useAppSelector(selectActiveUserSigner);

  useEffect(() => {
    if (!signer || !userId) {
      return;
    }

    // Define mutation defaults in @/services/react-query, so that when we resume paused
    // mutations below there are mutationFns defined for all mutations being resumed.

    queryClient.resumePausedMutations();
  }, [queryClient, signer, userId]);
};
