import React, {FC} from 'react';

import Space from '@/components/Space/Space';
import TrackCard, {ITrackCardProps} from '@/components/TrackCard';
import {useFullUserByIdQuery} from '@/queries/user';
import ContributorInfo from '@/screens/Playlist/components/ContributorInfo';
import {IPlaylist} from '@/types/playlists';

interface IProps extends ITrackCardProps {
  showContributor: boolean;
  playlist?: IPlaylist;
}

const PlaylistTrackCard: FC<IProps> = ({
  showContributor,
  playlist,
  ...trackCardProps
}) => {
  const contributor = playlist?.contributions?.[trackCardProps.track.id];
  const {user} = useFullUserByIdQuery(
    contributor?.userId || playlist?.collector,
  );

  return (
    <>
      <TrackCard {...trackCardProps} />
      {showContributor && (
        <Space mt="xxs">
          <ContributorInfo user={user} textId="playlist.addedBy" />
        </Space>
      )}
    </>
  );
};

export default PlaylistTrackCard;
