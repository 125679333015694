import React, {FC, ReactNode, useState} from 'react';
import RNRestart from 'react-native-restart';

import Button from '@/components/Button';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {applyUpdate} from '@/queries/expoUpdates';
import {Sentry} from '@/services/sentry';
import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles} from './ErrorBoundary.style';

interface IProps {
  onRestart?: () => void;
  children: ReactNode;
}

const ErrorBoundary: FC<IProps> = ({onRestart, children}) => {
  const style = useThemedStyles(styles);
  const [isLoading, setIsLoading] = useState(false);

  const reload = async () => {
    try {
      setIsLoading(true);
      await onRestart?.();
      await applyUpdate();
    } catch (error: any) {
    } finally {
      if (isWeb) {
        window.location.reload();
      } else {
        RNRestart.Restart();
      }
    }
  };

  const renderFallback = () => (
    <Space flex style={style.root} p="l">
      <Text size="xl" weight="semibold" align="center">
        Error :(
      </Text>
      <Space mt="s" />
      <Text weight="semibold" align="center">
        Something went wrong. Please try again.
      </Text>
      <Space mt="l" />
      <Button
        onPress={reload}
        text="Reload"
        ph="l"
        pv="xs"
        disabled={isLoading}
      />
    </Space>
  );

  return (
    <Sentry.ErrorBoundary fallback={renderFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
