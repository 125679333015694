import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotsContainer: {
    opacity: 0,
  },
  dots: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 10,
  },
}));
