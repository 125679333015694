import React, {FC} from 'react';
import {View} from 'react-native';

import {useTheme, useThemedStyles} from '@/theme';
import {Color} from '@/types/theme';

import {styles} from './LineSeparator.style';

interface IProps {
  color?: Color;
}

const LineSeparator: FC<IProps> = ({color = 'backgroundDark'}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();

  return (
    <View style={[style.separator, {backgroundColor: theme.colors[color]}]} />
  );
};

export default LineSeparator;
