import {useEffect, useState} from 'react';

export const useDebouncedLoader = (
  isLoading: boolean,
  delay: number = 1000,
  showOnMount: boolean = false,
): boolean => {
  const [isLoadingDebounced, setIsLoadingDebounced] = useState(
    showOnMount && isLoading,
  );

  useEffect(() => {
    if (isLoading) {
      const timeoutId = setTimeout(() => {
        setIsLoadingDebounced(true);
      }, delay);

      return () => clearTimeout(timeoutId);
    } else {
      setIsLoadingDebounced(false);
    }
  }, [isLoading, delay]);

  return isLoadingDebounced;
};
