import React, {useState} from 'react';
import {View} from 'react-native';

import Address from '@/components/Address';
import Avatar from '@/components/Avatar/Avatar';
import AvatarLayout from '@/components/AvatarLayout';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {TrackCardPreview} from '@/components/TrackCard';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useLatestTracksQuery} from '@/queries/db';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {getSpecialAddress} from '@/utils/ethereum';
import {noop} from '@/utils/functions';
import {
  getUserAddress,
  getUserAvatar,
  getUserDisplayName,
  getUserName,
} from '@/utils/user';

import {styles} from './ThemePreview.style';

const ThemePreview = () => {
  const style = useThemedStyles(styles);
  const activeUser = useActiveUser();
  const {tracks} = useLatestTracksQuery();

  const [activeTrackIndex, setActiveTrackIndex] = useState(0);
  const [isFollowing, setIsFollowing] = useState(true);

  const address = activeUser && getUserAddress(activeUser);
  const predefinedName = address && getSpecialAddress(address)?.name;
  const displayName = activeUser && (predefinedName || getUserName(activeUser));

  return (
    <View>
      <View style={style.header}>
        <Text weight="bold" numberOfLines={1} size="l">
          {activeUser ? getUserDisplayName(activeUser) : 'Profile'}
        </Text>
      </View>
      <View style={style.container}>
        <AvatarLayout
          avatar={
            activeUser && (
              <Avatar
                expandable
                id={activeUser.id}
                url={getUserAvatar(activeUser)}
                size={100}
                resizeWidth={ImageSize.user}
              />
            )
          }
          title={
            activeUser ? (
              <>
                {displayName ? (
                  <Text
                    weight="semibold"
                    size="l"
                    align="center"
                    style={style.titleText}>
                    {displayName}
                  </Text>
                ) : (
                  <Text
                    id="address"
                    weight="semibold"
                    size="l"
                    align="center"
                  />
                )}

                {address && <Address address={address} size="xs" />}
              </>
            ) : null
          }
          leftAction={
            <IconButton
              onPress={noop}
              icon={{provider: 'custom', name: 'share'}}
            />
          }
          rightAction={
            <IconButton
              onPress={() => setIsFollowing(v => !v)}
              icon={{
                name: 'heart',
                provider: 'custom',
                fill: isFollowing,
                color: isFollowing ? 'favoritesColor' : 'textColor',
              }}
            />
          }
        />

        <Space h="l" />

        {tracks.slice(0, 3).map((track, index) => (
          <Space mb="xs" key={track.id}>
            <TrackCardPreview
              track={track}
              isActive={index === activeTrackIndex}
              onPress={() => setActiveTrackIndex(index)}
            />
          </Space>
        ))}
      </View>
    </View>
  );
};

export default ThemePreview;
