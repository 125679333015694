import React, {FC} from 'react';
import {ScrollView, View} from 'react-native';

import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import Touchable from '@/components/Touchable/Touchable';
import {IUserMenuProps} from '@/components/UsersMenu';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

import {styles, AVATAR_SMALL_SIZE} from './ProfileMenu.style';

interface IProps extends IUserMenuProps {
  closeProfileMenu: () => void;
}

const ProfileMenu: FC<IProps> = ({
  closeProfileMenu,
  users,
  activeUser,
  switchUser,
  openLoginModal,
  signOut,
}) => {
  const style = useThemedStyles(styles);
  return (
    <ScrollView
      contentContainerStyle={style.scrollViewContainer}
      showsVerticalScrollIndicator={false}>
      <Space pl="s" mb="xs">
        <Text weight="semibold" size="l">
          Profiles
        </Text>
      </Space>
      {users.map(user => {
        const isActiveUser = user.id === activeUser?.id;

        return (
          <Touchable
            key={user.id}
            style={style.profileItem}
            hoveredStyle={style.profileItem_hover}
            pressedStyle={style.profileItem_pressed}
            onPress={() => {
              switchUser(user.id);
              closeProfileMenu();
            }}>
            <View style={style.profileItemIcon}>
              <Avatar
                url={getUserAvatar(user)}
                id={user.id}
                size={AVATAR_SMALL_SIZE}
                resizeWidth={ImageSize.avatar}
              />
            </View>
            <Text size="s" weight={isActiveUser ? 'semibold' : 'regular'}>
              {getUserDisplayName(user)}
            </Text>
            {isActiveUser && (
              <>
                <Space flex ml="xs" />
                <Icon name="check" size={14} />
              </>
            )}
          </Touchable>
        );
      })}
      <Touchable
        style={style.profileItem}
        hoveredStyle={style.profileItem_hover}
        pressedStyle={style.profileItem_pressed}
        onPress={openLoginModal}>
        <View style={style.profileItemIcon}>
          <Icon name="add" provider="custom" />
        </View>
        <Text
          size="s"
          weight="semibold"
          id="login.addAnotherAccount"
          numberOfLines={1}
        />
      </Touchable>
      {activeUser && (
        <Touchable
          style={style.profileItem}
          hoveredStyle={style.profileItem_hover}
          pressedStyle={style.profileItem_pressed}
          onPress={() => {
            closeProfileMenu();
            signOut();
          }}>
          <View style={style.profileItemIcon}>
            <Icon name="signout" provider="custom" />
          </View>
          <Text
            size="s"
            weight="semibold"
            id="menu.signOut"
            values={{user: getUserDisplayName(activeUser)}}
            numberOfLines={1}
          />
        </Touchable>
      )}
    </ScrollView>
  );
};

export default ProfileMenu;
