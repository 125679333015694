import {ISigner} from '@/types/session';
import {formatAddressChecksum} from '@/utils/ethereum';

export const sanitizeId = (id: string) => (id ? id.replaceAll('/', '_') : '');

export const getSignedRequestBody = async (
  data: unknown,
  signer: ISigner,
): Promise<{msg: string; sig: string; address: string}> => {
  const msg = typeof data === 'string' ? data : JSON.stringify(data);
  const sig = await signer.signMessage(msg);

  return {
    msg,
    sig,
    address: signer.address,
  };
};

export const getSignedRequestHeader = async (
  data: unknown,
  signer: ISigner,
) => {
  const body = await getSignedRequestBody(data, signer);

  return {
    auth: JSON.stringify(body),
  };
};

export const formatFirebaseId = (id: string) => {
  try {
    const idParts = id.split('/');
    const address = idParts[1];
    idParts[1] = formatAddressChecksum(address);
    return idParts.join('/');
  } catch (error) {
    return id;
  }
};
