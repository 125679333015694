import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles((theme, responsive) => ({
  shareContainer: {
    alignItems: 'center',
    marginBottom: responsive.isMobile ? 0 : spacing.s,
    gap: spacing.s,
  },
  shareHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.s,
  },
  shareLine: {
    width: spacing.s * 2,
    height: 1,
    backgroundColor: theme.colors.textColor,
  },
  shareActionButtons: {
    gap: spacing.s * 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
