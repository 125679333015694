import {useRef} from 'react';

/**
 * Hook for handling local state resetting after component instance was recycled in FlashList:
 * https://shopify.github.io/flash-list/docs/recycling
 */
export const useRecyclingHandler = (
  propToWatch: string | number | undefined | null,
  callback: () => void,
) => {
  const prevId = useRef(propToWatch);

  if (propToWatch !== prevId.current) {
    prevId.current = propToWatch;
    callback();
  }
};
