import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const CONTENT_TOP_PADDING = spacing.xs;
const CONTENT_PADDING = spacing.s;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    root: {
      flex: 1,
    },
    listContainer: {
      flex: 1,
    },
    listContent: {
      flexGrow: 1,
      paddingTop: CONTENT_TOP_PADDING,
      paddingHorizontal: CONTENT_PADDING,
      paddingBottom: CONTENT_PADDING + safeAreaInsets.bottom,
    },
  }),
);
