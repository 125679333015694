import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const CONTENT_TOP_PADDING = spacing.xs;
const CONTENT_PADDING = spacing.s;

export const HEADER_BUTTON_SIZE = spacing.l;
const HEADER_PADDING = spacing.xs * 1.5;
const BUTTONS_GAP = spacing.xs * 1.5;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    header: {
      backgroundColor: theme.colors.background,
      paddingTop: Platform.select({
        ios: HEADER_PADDING,
        default: HEADER_PADDING + safeAreaInsets.top,
      }),
      paddingBottom: HEADER_PADDING,
      paddingHorizontal: CONTENT_PADDING,
    },
    headerRow: {
      flexDirection: 'row',
      alignItems: 'center',
      gap: spacing.xs,
    },
    headerTitle: {
      flex: 1,
    },
    headerButtons: {
      flexDirection: 'row',
      gap: BUTTONS_GAP,
    },
    headerButton: {
      justifyContent: 'center',
      alignItems: 'center',
      width: HEADER_BUTTON_SIZE,
      height: HEADER_BUTTON_SIZE,
    },
    headerSubtitle: {
      paddingLeft: spacing.l + spacing.xs,
    },
    popoverAction: {
      padding: spacing.xs * 1.5,
      gap: spacing.xxs / 2,
    },
    headerDivider: {
      width: '100%',
      height: 2,
      backgroundColor: theme.colors.borderColor,
    },
  }),
);
