import {get, set, del, clear} from 'idb-keyval';

import {Storage} from './types';

export const storage: Storage = {
  getItem: async (key: string) => {
    const value = await get(key);
    return value || null;
  },
  setItem: (key: string, value: string) => set(key, value),
  removeItem: (key: string) => del(key),
  clear: () => clear(),
};
