import {useQuery} from '@tanstack/react-query';

import {fetchExchangeRates} from '@/api/crypto';
import {QueryKeys} from '@/types/queryKeys';

export const useExchangeRatesQuery = () => {
  const query = useQuery({
    queryKey: [QueryKeys.exchangeRates],
    queryFn: fetchExchangeRates,
    staleTime: 1000 * 60,
    refetchInterval: 1000 * 60,
  });

  return {
    exchangeRates: query.data,
    query,
  };
};
