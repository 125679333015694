import {useMutation} from '@tanstack/react-query';
import {useDisconnect as _useDisconnect} from 'wagmi';

import {useToast} from '@/modules/Toasts';

export const useDisconnect = () => {
  const {showToast} = useToast();
  const {disconnectAsync} = _useDisconnect();

  const {
    mutate,
    mutateAsync,
    isPending: isDisconnecting,
  } = useMutation({
    networkMode: 'online',
    mutationFn: () => disconnectAsync(),
    onError: () => {
      showToast({
        textId: 'disconnect.error',
      });
    },
  });

  return {
    disconnect: mutate,
    disconnectAsync: mutateAsync,
    isDisconnecting,
  };
};
