import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    padding: spacing.s + spacing.xs,
    gap: spacing.s + spacing.xs,
  },
  title: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs,
  },
}));
