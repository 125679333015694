import React from 'react';

import CardButton from '@/components/CardButton';
import WarningBox from '@/components/WarningBox';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {useCheckWalletChain} from '@/modules/Wallets';
import {IAddress} from '@/types/common';

interface IChainValidationInput {
  wallet: IAddress | undefined;
  chainId: number | undefined;
}

export const useChainValidation = ({
  wallet,
  chainId,
}: IChainValidationInput): ITransactionValidationError | undefined => {
  const chainError = useCheckWalletChain({wallet, chainId});

  if (!chainError) {
    return undefined;
  }

  return {
    message: (
      <WarningBox
        icon={{name: 'wrongNetwork', provider: 'custom'}}
        title={chainError.message.title}
        message={chainError.message.message}
      />
    ),
    action: chainError.action && (
      <CardButton
        onPress={chainError.action.onPress}
        text={chainError.action.text}
      />
    ),
  };
};
