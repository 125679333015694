import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import Header from '@/components/Header';
import HeaderButton from '@/components/HeaderButton';
import {IIconProps} from '@/components/Icon';
import IconButton from '@/components/IconButton';
import InfinityList, {InfinityListRef} from '@/components/InfinityList';
import KeyboardView from '@/components/KeyboardView/KeyboardView';
import Loader from '@/components/Loader';
import Screen from '@/components/Screen';
import SearchInput from '@/components/SearchInput/SearchInput';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text/Text';
import {TRACK_CARD_HEIGHT, TrackCardPreview} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useDebouncedSearch} from '@/hooks/useDebouncedSearch';
import {useAppSelector} from '@/hooks/useRedux';
import {useFavsTracks} from '@/hooks/useUserPlaylists';
import {useSuggestTrackInPlaylist} from '@/queries/followedPlaylists';
import {useTracksSearchQuery} from '@/queries/search';
import {selectPlaylistOrFollowedPlaylist} from '@/store/playlists';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';
import {isIOS} from '@/utils/platform';
import {getPlaylistTracksMap, isInCollaborators} from '@/utils/playlists';

import {styles} from './SuggestToPlaylist.style';

const actionTexts: {[key: string]: TextProps} = {
  suggested: {
    id: 'suggestToPlaylist.suggested',
  },
  added: {
    id: 'suggestToPlaylist.added',
  },
};

const addIcon: IIconProps = {
  name: 'add',
  provider: 'custom',
};

const SuggestToPlaylist = () => {
  const style = useThemedStyles(styles);
  const userId = useAppSelector(selectActiveUserId);
  const {
    params: {id: playlistId},
  } = useRoute<RouteProp<RootStackParams, Routes.SuggestToPlaylist>>();
  const {playlist, parentPlaylistId} = useAppSelector(state =>
    selectPlaylistOrFollowedPlaylist(state, playlistId),
  );
  const playlistTracksMap = useMemo(
    () => getPlaylistTracksMap(playlist?.trackIds),
    [playlist?.trackIds],
  );

  const [search, setSearch] = useState('');
  const {searchDebounced, clearSearch} = useDebouncedSearch({
    search,
    setSearch,
  });

  const listRef = useRef<InfinityListRef | null>(null);
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {favoriteTracks} = useFavsTracks();
  const {
    tracks,
    query: {isFetching},
  } = useTracksSearchQuery(searchDebounced);

  const {suggestTrackInPlaylistMutation} = useSuggestTrackInPlaylist();

  const listExtraData = useMemo(
    () => ({
      tracksMap: playlistTracksMap,
      suggestions: playlist?.suggestions || {},
    }),
    [playlistTracksMap, playlist?.suggestions],
  );

  const toggleTrack = useCallback(
    (track: ITrack) => {
      if (parentPlaylistId) {
        suggestTrackInPlaylistMutation({
          parentPlaylistId,
          playlistId,
          trackId: track.id,
        });
      }
    },
    [playlistId, parentPlaylistId],
  );

  useEffect(() => {
    const canSuggest =
      isInCollaborators(playlist?.collaborators, userId) &&
      playlist?.mode === 'solo';

    if (!playlist || !parentPlaylistId || !canSuggest) {
      navigation.goBack();
    }
  }, [playlist, userId]);

  useEffect(() => {
    listRef?.current?.scrollToOffset({offset: 0, animated: true});
  }, [searchDebounced]);

  if (!playlist) {
    return null;
  }

  return (
    <Screen>
      <KeyboardView>
        <Header
          titleId="suggestToPlaylist.title"
          titleProps={{size: 's'}}
          scrollPosition={scrollPosition}
          mockTitle
          modalMode={isIOS}
          leftActions={
            <IconButton
              onPress={() => navigation.goBack()}
              icon={{
                provider: 'custom',
                name: 'arrowDown',
              }}
            />
          }
          rightActions={
            searchDebounced ? (
              <HeaderButton
                onPress={() => navigation.goBack()}
                text={{id: 'done'}}
              />
            ) : null
          }>
          <Space ph="s" pv="xs">
            <SearchInput
              value={search}
              setValue={setSearch}
              onClear={clearSearch}
              autoFocus={false}
              placeholderId="suggestToPlaylist.placeholder"
            />
          </Space>
        </Header>
        <InfinityList
          ref={listRef}
          data={searchDebounced ? tracks : favoriteTracks}
          extraData={listExtraData}
          contentContainerStyle={style.scrollContent}
          itemSize={TRACK_CARD_HEIGHT}
          keyExtractor={item => item.id}
          onScroll={onScroll}
          renderItem={({item: track}) => {
            const isAdded = playlistTracksMap[track.id];
            const isSuggested = !!playlist.suggestions?.[track.id];
            const canSuggest = !isAdded && !isSuggested;

            return (
              <TrackCardPreview
                onPress={toggleTrack}
                track={track}
                disabled={!canSuggest}
                suffixText={
                  isAdded
                    ? actionTexts.added
                    : isSuggested
                    ? actionTexts.suggested
                    : undefined
                }
                suffixIcon={canSuggest ? addIcon : undefined}
              />
            );
          }}
          ListHeaderComponent={
            <Space mt="xxs" mb="xs">
              <Text
                weight="semibold"
                id={
                  searchDebounced
                    ? 'suggestToPlaylist.tracks'
                    : 'suggestToPlaylist.favs'
                }
              />
            </Space>
          }
          ListEmptyComponent={
            isFetching ? (
              <Loader />
            ) : searchDebounced ? (
              <Text align="center" id="suggestToPlaylist.searchEmpty" />
            ) : null
          }
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="always"
        />
      </KeyboardView>
    </Screen>
  );
};

export default SuggestToPlaylist;
