import {useNavigation} from '@react-navigation/native';
import React, {useMemo} from 'react';
import {ScrollView, View} from 'react-native';

import Checkbox from '@/components/Checkbox';
import Header from '@/components/Header';
import Icon from '@/components/Icon/Icon';
import Screen from '@/components/Screen';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useThemes} from '@/hooks/useThemes';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import EmptyState from '@/screens/ThemesSettings/components/EmptyState';
import ThemeCard from '@/screens/ThemesSettings/components/ThemeCard';
import {
  selectCardsTheming,
  selectDefaultTheme,
  selectThemeSettings,
  setCardsTheming,
  setThemeSettings,
} from '@/store/theme';
import {useThemedStyles} from '@/theme';
import {makeTheme} from '@/themes/partialTheme';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {capitalizeString} from '@/utils/string';
import {getUserDisplayName, getUserSlug} from '@/utils/user';

import {styles} from './ThemesSettings.style';

const ThemesSettings = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const activeUser = useActiveUser();
  const {availableThemes} = useCustomThemesConfigQuery();
  const currentTheme = useAppSelector(selectDefaultTheme);
  const userThemesSettings = useAppSelector(selectThemeSettings);
  const cardsThemingEnabled = useAppSelector(selectCardsTheming);
  const {getTheme} = useThemes();

  const editCollectorTheme = () => {
    if (activeUser) {
      navigation.navigate(Routes.MainNavigation, {
        screen: Routes.CollectorThemeEdit,
        params: {slug: getUserSlug(activeUser)},
      });
    }
  };

  const editArtistTheme = () => {
    if (activeUser?.artistProfile) {
      navigation.navigate(Routes.MainNavigation, {
        screen: Routes.ArtistThemeEdit,
        params: {slugOrId: activeUser.artistProfile.slug},
      });
    }
  };

  const collectorTheme = useMemo(
    () => activeUser?.customTheme && makeTheme({basic: activeUser.customTheme}),
    [activeUser?.customTheme],
  );

  const artistTheme = useMemo(
    () =>
      activeUser?.artistProfile?.customTheme &&
      makeTheme({basic: activeUser.artistProfile.customTheme}),
    [activeUser?.artistProfile?.customTheme],
  );

  return (
    <Screen>
      <Header showBack titleId="themesSettings.title" />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={style.scrollContainer}
        contentContainerStyle={style.scrollContent}>
        <Text id="themesSettings.appThemes" size="m" weight="semibold" />
        <Space mt="xxs" />
        <Text id="themesSettings.appThemesInfo" size="xs" />
        <Space mt="s" />
        <View style={style.themesGrid}>
          {availableThemes.map(theme => (
            <View key={theme.name} style={style.themeItem}>
              <ThemeCard
                onPress={() =>
                  dispatch(
                    setThemeSettings({type: 'predefined', name: theme.name}),
                  )
                }
                name={capitalizeString(theme.name)}
                theme={getTheme(theme.name)}
                disabled={
                  userThemesSettings
                    ? userThemesSettings.name === theme.name
                    : currentTheme === theme.name
                }
                suffixComponent={
                  <Text size="m" style={{lineHeight: 22}} weight="semibold">
                    {theme.emoji}
                  </Text>
                }
              />
            </View>
          ))}
          {collectorTheme && activeUser && (
            <View style={style.themeItem}>
              <ThemeCard
                onPress={() => dispatch(setThemeSettings({type: 'collector'}))}
                name={getUserDisplayName(activeUser)}
                theme={collectorTheme}
                disabled={userThemesSettings?.type === 'collector'}
              />
            </View>
          )}
          {artistTheme && activeUser?.artistProfile && (
            <View style={style.themeItem}>
              <ThemeCard
                onPress={() => dispatch(setThemeSettings({type: 'artist'}))}
                name={activeUser.artistProfile.name}
                theme={artistTheme}
                disabled={userThemesSettings?.type === 'artist'}
              />
            </View>
          )}
        </View>

        {activeUser && (
          <Space mt="xl">
            <Text
              id="themesSettings.collectorTheme"
              size="m"
              weight="semibold"
            />
            <Space mt="xxs" />
            <Text id="themesSettings.collectorThemeInfo" size="xs" />
            <Space mt="s" />
            {collectorTheme ? (
              <View style={style.themesGrid}>
                <View style={style.themeItem}>
                  <ThemeCard
                    onPress={editCollectorTheme}
                    name={getUserDisplayName(activeUser)}
                    theme={collectorTheme}
                    suffixComponent={<Icon name="pencil" size={14} />}
                  />
                </View>
              </View>
            ) : (
              <EmptyState
                onPress={editCollectorTheme}
                textId="themesSettings.collectorThemeEmpty"
              />
            )}
          </Space>
        )}

        {activeUser?.artistProfile && (
          <Space mt="xl">
            <Text id="themesSettings.artistTheme" size="m" weight="semibold" />
            <Space mt="xxs" />
            <Text id="themesSettings.artistThemeInfo" size="xs" />
            <Space mt="s" />
            {artistTheme ? (
              <View style={style.themesGrid}>
                <View style={style.themeItem}>
                  <ThemeCard
                    onPress={editArtistTheme}
                    name={activeUser.artistProfile.name}
                    theme={artistTheme}
                    suffixComponent={<Icon name="pencil" size={14} />}
                  />
                </View>
              </View>
            ) : (
              <EmptyState
                onPress={editArtistTheme}
                textId="themesSettings.artistThemeEmpty"
              />
            )}
          </Space>
        )}

        <Space mt="xl">
          <View style={style.cardThemesCheckbox}>
            <Checkbox
              value={cardsThemingEnabled}
              setValue={() => dispatch(setCardsTheming(!cardsThemingEnabled))}
            />
            <Space w="xs" />
            <Text
              size="m"
              weight="semibold"
              id="themesSettings.cardThemes.title"
            />
          </View>
          <Space h="xs" />
          <Text size="xs" id="themesSettings.cardThemes.info" />
        </Space>
      </ScrollView>
    </Screen>
  );
};

export default ThemesSettings;
