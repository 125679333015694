import {useMutation, useQueryClient} from '@tanstack/react-query';
import React, {FC, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Pressable, TouchableOpacity, View} from 'react-native';
import Animated from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import {Form, ImagePicker} from '@/components/Form';
import Header from '@/components/Header';
import Icon from '@/components/Icon';
import KeyboardView from '@/components/KeyboardView';
import Screen from '@/components/Screen';
import SelectableTile from '@/components/SelectableTile';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {getVerticalHitSlop} from '@/constants/spacing';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useImageUploader} from '@/hooks/useImageUploader';
import {useStore} from '@/hooks/useRedux';
import AudioUploader from '@/modules/DropOnSpinamp/components/AudioUploader';
import {useAudioUploader} from '@/modules/DropOnSpinamp/components/AudioUploader/useAudioUploader';
import CategoryTile from '@/modules/DropOnSpinamp/components/CategoryTile';
import ConfirmationModal from '@/modules/DropOnSpinamp/components/ConfirmationModal';
import SectionTitle from '@/modules/DropOnSpinamp/components/SectionTitle';
import SignInState from '@/modules/DropOnSpinamp/components/SignInState';
import {
  premintChain,
  genres,
  PodcastGenreKey,
  artistAvatarSize,
  artworkSize,
  currency,
} from '@/modules/DropOnSpinamp/constants';
import {IDropForm, UploadStep} from '@/modules/DropOnSpinamp/types';
import {uploadPremint} from '@/modules/DropOnSpinamp/utils';
import {useConnectorName} from '@/modules/ExternalWallet/useConnectorName';
import {isWalletCancelError} from '@/modules/Transactions';
import {
  useCheckWalletChain,
  useCheckWalletConnection,
  WalletCard,
  WalletPicker,
  WalletWithAddress,
} from '@/modules/Wallets';
import {useDbQuery} from '@/queries/db';
import {Sentry} from '@/services/sentry';
import {selectSignerByUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {IAddress, ITrack} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {IUser} from '@/types/user';
import {analytics} from '@/utils/analytics';
import {generateId} from '@/utils/functions';
import {getUserPaymentWallet} from '@/utils/user';

import {styles} from './DropOnSpinampScreen.style';

const DropOnSpinampScreen: FC = () => {
  const user = useActiveUser();

  if (user) {
    return <DropOnSpinampScreenContent user={user} />;
  }

  return <SignInState />;
};

const DropOnSpinampScreenContent: FC<{user: IUser}> = ({user}) => {
  const style = useThemedStyles(styles);
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const scrollView = useRef<Animated.ScrollView | null>(null);
  const queryClient = useQueryClient();
  const {updateDb} = useDbQuery();
  const {getState} = useStore();
  const connector = useConnectorName();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [uploadStep, setUploadStep] = useState<UploadStep>();

  const [isPublisherWalletOpen, setIsPublisherWalletOpen] = useState(false);
  const [isPayoutWalletOpen, setIsPayoutWalletOpen] = useState(false);

  const shouldCreateArtist = !user.artistProfile;

  const form = useForm<IDropForm>({
    mode: 'onTouched',
    defaultValues: {
      uploadMode: 'collectible',
      title: '',
      genre: '',
      description: '',
      category: 'song',
      price: '',
      publisherWalletAddress: getUserPaymentWallet(user)?.address as string,
      payoutWalletAddress: getUserPaymentWallet(user)?.address as string,
      audioIpfsHash: '',
      artworkIpfsHash: '',
    },
  });

  const formId = useRef(generateId());

  const artistAvatarUploadProps = useImageUploader({
    maxSize: artistAvatarSize.max,
    minSize: artistAvatarSize.min,
    circular: true,
    onUpload: hash => {
      form.setValue('artist.avatarIpfsHash', hash, {shouldValidate: true});
    },
  });
  const audioUploadProps = useAudioUploader({
    onUpload: hash => {
      form.setValue('audioIpfsHash', hash, {shouldValidate: true});
      if (hash) {
        analytics.dropOnSpinampAudioUploaded(formId.current, form.getValues());
      }
    },
  });
  const artworkUploadProps = useImageUploader({
    maxSize: artworkSize.max,
    minSize: artworkSize.min,
    onUpload: hash => {
      form.setValue('artworkIpfsHash', hash, {shouldValidate: true});
      if (hash) {
        analytics.dropOnSpinampImageUploaded(formId.current, form.getValues());
      }
    },
  });

  const uploadMode = form.watch('uploadMode');
  const genre = form.watch('genre');
  const isPodcast = genre === PodcastGenreKey;

  const publisherWalletAddress = form.watch('publisherWalletAddress');
  const publisherWallet = user?.addresses.find(
    a => a.address === publisherWalletAddress,
  );
  const payoutWalletAddress = form.watch('payoutWalletAddress');
  const payoutWallet = user?.addresses.find(
    a => a.address === payoutWalletAddress,
  );

  const walletConnectionError = useCheckWalletConnection(publisherWallet);
  const walletChainError = useCheckWalletChain({
    wallet: publisherWallet,
    chainId: premintChain.id,
  });
  const walletError = walletConnectionError || walletChainError;
  const walletErrorView = useRef<View | null>(null);
  const scrollToWalletError = () => {
    if (scrollView.current && walletErrorView.current) {
      walletErrorView.current.measureLayout(
        scrollView.current.getInnerViewNode(),
        (left, top) => {
          scrollView.current?.scrollTo({y: top});
        },
      );
    }
  };

  const uploadMutation = useMutation({
    networkMode: 'always',
    mutationFn: async (params: {
      formValues: IDropForm;
      user: IUser;
      wallet?: IAddress;
    }) => {
      if (!params.wallet) {
        throw new Error('no wallet provider to drop on spinamp form');
      }

      const internalSigner = selectSignerByUserId(getState(), user.id);

      if (!internalSigner) {
        throw new Error('no internal signer found');
      }

      return uploadPremint(
        params.formValues,
        params.user,
        params.wallet,
        internalSigner,
        formId.current,
        setUploadStep,
      );
    },
    onSuccess: async (uploadedTrack: ITrack) => {
      form.reset();
      artworkUploadProps.reset();
      audioUploadProps.reset();
      queryClient.invalidateQueries({queryKey: [QueryKeys.userProfiles]});
      updateDb({
        tracks: [uploadedTrack],
      });
    },
    onError: (error, variables) => {
      if (isWalletCancelError(error)) {
        return;
      }

      analytics.dropOnSpinampUploadError(
        formId.current,
        form.getValues(),
        error,
      );
      Sentry.captureException(error, {
        tags: {
          dropOnSpinamp: true,
        },
        extra: {
          connector,
          ...variables,
        },
      });
    },
    onSettled: () => {
      setUploadStep(undefined);
    },
  });

  const onSubmit = form.handleSubmit(formValues => {
    uploadMutation.mutate({formValues, user, wallet: publisherWallet});
    analytics.dropOnSpinampPublishClicked(formId.current, form.getValues());
  });

  const WalletError = walletConnectionError ? (
    <View style={style.walletError} ref={walletErrorView}>
      <WarningBox
        message={{
          id: walletConnectionError.message.id,
          values: {
            address: walletConnectionError.message.walletName,
          },
        }}
        action={
          walletConnectionError.action && (
            <TransparentButton
              text={walletConnectionError.action.text}
              onPress={walletConnectionError.action.onPress}
              disabled={walletConnectionError.action.isLoading}
            />
          )
        }
      />
    </View>
  ) : walletChainError ? (
    <View style={style.walletError} ref={walletErrorView}>
      <WarningBox
        icon={{name: 'wrongNetwork', provider: 'custom'}}
        title={walletChainError.message.title}
        message={walletChainError.message.message}
        action={
          walletChainError.action && (
            <TransparentButton
              text={walletChainError.action.text}
              onPress={walletChainError.action.onPress}
            />
          )
        }
      />
    </View>
  ) : null;

  return (
    <Screen>
      <KeyboardView>
        <Header
          showBack
          scrollPosition={scrollPosition}
          titleId="dropOnSpinamp.title"
          mockTitle
        />
        <Form form={form}>
          <Animated.ScrollView
            ref={scrollView}
            contentContainerStyle={style.scrollContent}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="always"
            keyboardDismissMode="on-drag"
            onScroll={onScroll}
            scrollEventThrottle={16}>
            <View style={style.formContent}>
              <View style={style.firstSectionTitle} />
              {shouldCreateArtist && (
                <>
                  <SectionTitle
                    text={{id: 'dropOnSpinamp.section.artistInfo'}}
                  />
                  <Text
                    size="xs"
                    style={style.artistDescription}
                    id="dropOnSpinamp.section.artistInfo.description"
                  />
                  <Form.Field
                    label={{id: 'dropOnSpinamp.form.artist.name', size: 's'}}>
                    <Form.Input
                      name="artist.name"
                      defaultValue={''}
                      rules={{
                        required: 'dropOnSpinamp.form.artist.name.required',
                      }}
                      placeholderId="dropOnSpinamp.form.artist.name.placeholder"
                      shouldUnregister
                    />
                    <Form.Error name="artist.name" />
                  </Form.Field>

                  <Controller
                    name="artist.avatarIpfsHash"
                    rules={{
                      required: 'dropOnSpinamp.form.artist.avatar.required',
                    }}
                    shouldUnregister
                    render={({field}) => (
                      <Form.FocusableView field={field} scrollView={scrollView}>
                        <Form.Field
                          label={{
                            id: 'dropOnSpinamp.form.artist.avatar',
                            size: 's',
                          }}>
                          <ImagePicker
                            {...artistAvatarUploadProps}
                            previewStyle={style.imagePreviewStyle}
                            previewSize={artistAvatarSize.preview}
                            minSize={artistAvatarSize.min}
                            circular
                            placeholder={
                              <View style={style.imagePlaceholder}>
                                <Icon
                                  provider="custom"
                                  name="avatarUpload"
                                  size={40}
                                />
                                <Text
                                  weight="semibold"
                                  id="dropOnSpinamp.form.artist.avatar.hint"
                                />
                                <Text
                                  size="xxs"
                                  id="dropOnSpinamp.form.artwork.hint2"
                                />
                              </View>
                            }
                          />
                          <Form.Error name="artist.avatarIpfsHash" />
                        </Form.Field>
                      </Form.FocusableView>
                    )}
                  />
                </>
              )}

              <SectionTitle text={{id: 'dropOnSpinamp.section.musicInfo'}} />

              <Controller
                name="audioIpfsHash"
                rules={{
                  required: 'dropOnSpinamp.form.track.required',
                }}
                render={({field}) => (
                  <Form.FocusableView
                    field={field}
                    scrollView={scrollView}
                    style={style.audioUploader}>
                    <AudioUploader uploadProps={audioUploadProps} />
                    <Form.Error name="audioIpfsHash" />
                  </Form.FocusableView>
                )}
              />

              <Form.Field label={{id: 'dropOnSpinamp.form.title', size: 's'}}>
                <Form.Input
                  name="title"
                  rules={{
                    required: 'dropOnSpinamp.form.title.required',
                    maxLength: 300,
                  }}
                  placeholderId="dropOnSpinamp.form.title"
                  maxLength={300}
                />
                <Form.Error name="title" />
              </Form.Field>

              <Controller
                name="artworkIpfsHash"
                rules={{
                  required: 'dropOnSpinamp.form.artwork.required',
                }}
                render={({field}) => (
                  <Form.FocusableView field={field} scrollView={scrollView}>
                    <Form.Field
                      label={{id: 'dropOnSpinamp.form.artwork', size: 's'}}>
                      <ImagePicker
                        {...artworkUploadProps}
                        previewStyle={style.imagePreviewStyle}
                        previewSize={artworkSize.preview}
                        minSize={artworkSize.min}
                        placeholder={
                          <View style={style.imagePlaceholder}>
                            <Icon
                              provider="custom"
                              name="imageUpload"
                              size={40}
                            />
                            <Text
                              weight="semibold"
                              id="dropOnSpinamp.form.artwork.hint1"
                            />
                            <Text
                              size="xxs"
                              id="dropOnSpinamp.form.artwork.hint2"
                            />
                          </View>
                        }
                      />
                      <Form.Error name="artworkIpfsHash" />
                    </Form.Field>
                  </Form.FocusableView>
                )}
              />

              <Form.Field
                label={{id: 'dropOnSpinamp.form.category', size: 's'}}>
                <View style={style.categories}>
                  <CategoryTile
                    text={{id: 'dropOnSpinamp.form.category.song'}}
                    isSelected={!isPodcast}
                    onChange={() => form.setValue('genre', '')}
                    icon={{provider: 'custom', name: 'vinyl'}}
                  />

                  <CategoryTile
                    text={{id: 'dropOnSpinamp.form.category.podcast'}}
                    isSelected={isPodcast}
                    onChange={() => form.setValue('genre', PodcastGenreKey)}
                    icon={{provider: 'custom', name: 'podcast'}}
                  />
                </View>
              </Form.Field>

              {!isPodcast && (
                <Form.Field
                  label={{id: 'dropOnSpinamp.form.genre', size: 's'}}
                  containerStyle={style.genresContainer}>
                  <Form.DropDown
                    name="genre"
                    options={genres}
                    placeholderId="dropOnSpinamp.form.genre.placeholder"
                    rules={{
                      required: 'dropOnSpinamp.form.genre.required',
                    }}
                    scrollView={scrollView}
                  />
                  <Form.Error name="genre" />
                </Form.Field>
              )}

              <Form.Field
                label={{id: 'dropOnSpinamp.form.description', size: 's'}}>
                <Form.RichTextEditor name="description" />
              </Form.Field>

              <SectionTitle
                text={{id: 'dropOnSpinamp.section.collectibleSettings'}}
              />

              <View style={style.collectibleTiles}>
                <SelectableTile
                  title={{
                    id: 'dropOnSpinamp.section.collectibleSettings.collectible',
                  }}
                  onPress={() => form.setValue('uploadMode', 'collectible')}
                  active={uploadMode === 'collectible'}>
                  <Text
                    size="xs"
                    id="dropOnSpinamp.section.collectibleSettings.collectible.desc"
                  />
                </SelectableTile>
                <SelectableTile
                  title={{
                    id: 'dropOnSpinamp.section.collectibleSettings.listenOnly',
                  }}
                  onPress={() => form.setValue('uploadMode', 'listenOnly')}
                  active={uploadMode === 'listenOnly'}>
                  <Text
                    size="xs"
                    id="dropOnSpinamp.section.collectibleSettings.listenOnly.desc"
                  />
                </SelectableTile>
              </View>

              {uploadMode === 'collectible' ? (
                <>
                  <Form.Field
                    label={{id: 'dropOnSpinamp.form.availability', size: 's'}}
                    icon={
                      <Tooltip
                        position="top"
                        containerStyle={style.tooltipContainer}
                        content={
                          <Text id="dropOnSpinamp.editDisclaimer" size="xxs" />
                        }>
                        {({open, close}) => (
                          <Pressable
                            style={style.editTooltip}
                            onPress={open}
                            onHoverIn={open}
                            onHoverOut={close}
                            hitSlop={getVerticalHitSlop('xs')}>
                            <Icon provider="custom" name="edit" size={14} />
                          </Pressable>
                        )}
                      </Tooltip>
                    }
                    description={{
                      id: 'dropOnSpinamp.form.availability.description',
                    }}
                  />

                  <Form.Field
                    label={{
                      id: 'dropOnSpinamp.form.chain',
                      size: 's',
                      values: {chain: premintChain.name},
                    }}
                    description={{
                      id: 'dropOnSpinamp.form.chain.description',
                    }}
                  />

                  <Form.Field
                    label={{id: 'dropOnSpinamp.form.wallets', size: 's'}}
                    description={{
                      id: 'dropOnSpinamp.form.wallets.description',
                    }}>
                    <View style={style.walletsContainer}>
                      <TouchableOpacity
                        activeOpacity={0.8}
                        style={style.walletRow}
                        onPress={() => setIsPublisherWalletOpen(true)}>
                        <Text
                          size="xs"
                          id="dropOnSpinamp.form.wallets.publisher"
                          flex
                        />
                        {publisherWallet && (
                          <WalletWithAddress
                            style={style.walletInfo}
                            wallet={publisherWallet}
                          />
                        )}
                        <Icon
                          provider="custom"
                          name="arrowRight"
                          style={style.walletArrow}
                        />
                      </TouchableOpacity>

                      <Divider />

                      <TouchableOpacity
                        activeOpacity={0.8}
                        style={style.walletRow}
                        onPress={() => setIsPayoutWalletOpen(true)}>
                        <Text
                          size="xs"
                          id="dropOnSpinamp.form.wallets.payout"
                          flex
                        />
                        {payoutWallet && (
                          <WalletWithAddress
                            style={style.walletInfo}
                            wallet={payoutWallet}
                          />
                        )}
                        <Icon
                          provider="custom"
                          name="arrowRight"
                          style={style.walletArrow}
                        />
                      </TouchableOpacity>

                      {WalletError}
                    </View>
                  </Form.Field>

                  <Form.Field
                    label={{id: 'dropOnSpinamp.form.price', size: 's'}}
                    description={{
                      id: 'dropOnSpinamp.form.price.description',
                    }}>
                    <Form.PriceInput
                      name="price"
                      rules={{
                        required: 'dropOnSpinamp.form.price.required',
                      }}
                      placeholder="0"
                      chainId={premintChain.id}
                      showUsd
                    />
                    <Form.Error name="price" />
                  </Form.Field>
                </>
              ) : (
                <>
                  <Form.Field
                    label={{id: 'dropOnSpinamp.form.tipOnly.wallet', size: 's'}}
                    description={{
                      id: 'dropOnSpinamp.form.tipOnly.wallet.description',
                    }}>
                    {publisherWallet && (
                      <WalletCard
                        style={style.walletCard}
                        wallet={publisherWallet}
                        onPress={() => setIsPublisherWalletOpen(true)}
                      />
                    )}
                    {WalletError}
                  </Form.Field>
                </>
              )}

              <Form.Field
                label={{id: 'dropOnSpinamp.form.releaseDate', size: 's'}}
                icon={
                  <Tooltip
                    position="top"
                    containerStyle={style.tooltipContainer}
                    content={
                      <Text id="dropOnSpinamp.editDisclaimer" size="xxs" />
                    }>
                    {({open, close}) => (
                      <Pressable
                        style={style.editTooltip}
                        onPress={open}
                        onHoverIn={open}
                        onHoverOut={close}
                        hitSlop={getVerticalHitSlop('xs')}>
                        <Icon provider="custom" name="edit" size={14} />
                      </Pressable>
                    )}
                  </Tooltip>
                }
                description={{
                  id: 'dropOnSpinamp.form.releaseDate.description',
                }}
              />
            </View>
          </Animated.ScrollView>
        </Form>
      </KeyboardView>

      <View style={style.submitContainer}>
        <CardButton
          onPress={() => {
            analytics.dropOnSpinampFormSubmitted(
              formId.current,
              form.getValues(),
            );
            form.handleSubmit(() => {
              if (walletError) {
                scrollToWalletError();
                return;
              }

              analytics.dropOnSpinampFormValidated(
                formId.current,
                form.getValues(),
              );
              setIsConfirmationOpen(true);
            })();
          }}
          text={{
            id: 'dropOnSpinamp.publish',
          }}
          icon={{provider: 'custom', name: 'vinyl'}}
        />
      </View>

      {isPublisherWalletOpen && (
        <WalletPicker
          titleId="dropOnSpinamp.form.wallets.publisher"
          user={user}
          selectedWalletAddress={publisherWalletAddress}
          onConfirm={address =>
            form.setValue('publisherWalletAddress', address, {
              shouldValidate: true,
            })
          }
          isOpen
          onClose={() => setIsPublisherWalletOpen(false)}
        />
      )}

      {isPayoutWalletOpen && (
        <WalletPicker
          titleId="dropOnSpinamp.form.wallets.payout"
          user={user}
          selectedWalletAddress={payoutWalletAddress}
          onConfirm={address =>
            form.setValue('payoutWalletAddress', address, {
              shouldValidate: true,
            })
          }
          isOpen
          onClose={() => setIsPayoutWalletOpen(false)}
        />
      )}

      {isConfirmationOpen && (
        <ConfirmationModal
          user={user}
          artworkUrl={artworkUploadProps.pickedImage?.uri || ''}
          price={`${form.getValues('price')} ${currency.symbol}`}
          title={form.getValues('title')}
          artist={form.getValues('artist')}
          uploadMode={form.getValues('uploadMode')}
          isOpen
          onSubmit={onSubmit}
          uploadStep={uploadStep}
          uploadedTrack={uploadMutation.data}
          uploadError={uploadMutation.error}
          clearError={() => uploadMutation.reset()}
          onClose={() => {
            setIsConfirmationOpen(false);
            uploadMutation.reset();
          }}
        />
      )}
    </Screen>
  );
};

export default DropOnSpinampScreen;
