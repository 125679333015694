import React, {ReactNode} from 'react';
import {useIntl} from 'react-intl';
import {TouchableOpacity} from 'react-native';

import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {HIT_SLOP} from '@/constants/spacing';
import {SearchCategory} from '@/screens/Search/types';
import {useTheme} from '@/theme';

interface IProps<T> {
  category: SearchCategory;
  selectedCategory?: SearchCategory;
  items: T[];
  titleId: string;
  onSeeMore: (category: SearchCategory) => void;
  renderItem: (item: T) => ReactNode;
  keyExtractor: (item: T) => string;
  visibleItems: number;
  isSearching: boolean;
}

const SearchSection = <T,>({
  category,
  selectedCategory,
  items,
  titleId,
  renderItem,
  keyExtractor,
  onSeeMore,
  visibleItems,
  isSearching,
}: IProps<T>) => {
  const theme = useTheme();
  const {formatMessage} = useIntl();
  const itemsToRender = selectedCategory ? items : items.slice(0, visibleItems);

  if (selectedCategory && selectedCategory !== category) {
    return null;
  }

  if (items.length > 0) {
    return (
      <Space>
        {!selectedCategory && (
          <Space mt="xs">
            <Text
              weight="semibold"
              size="s"
              id={titleId}
              uppercase={theme.text.sectionHeaderUppercase}
            />
          </Space>
        )}
        {itemsToRender.map(item => (
          <Space mt="xs" key={keyExtractor(item)}>
            {renderItem(item)}
          </Space>
        ))}
        {items.length > visibleItems && !selectedCategory && (
          <Space mt="xs">
            <TouchableOpacity
              hitSlop={HIT_SLOP}
              onPress={() => onSeeMore(category)}>
              <Text weight="semibold" id="more" align="center" />
            </TouchableOpacity>
          </Space>
        )}
      </Space>
    );
  }

  if (!selectedCategory) {
    return null;
  }

  if (isSearching) {
    return <ScreenLoader />;
  }

  return (
    <Space mt="s">
      <Text
        align="center"
        id="search.category.empty"
        values={{
          category: formatMessage({id: titleId}).toLowerCase(),
        }}
      />
    </Space>
  );
};

export default SearchSection;
