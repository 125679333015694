import React, {useState} from 'react';
import {TouchableOpacity, useWindowDimensions, View} from 'react-native';

import logo from '@/assets/images/logo.png';
import Button from '@/components/Button';
import DynamicImage from '@/components/DynamicImage/DynamicImage';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {breakpoints} from '@/constants/breakpoints';
import {config} from '@/constants/config';
import {useThemedStyles} from '@/theme';
import {analytics} from '@/utils/analytics';
import {isPWA, isMobileBrowser} from '@/utils/platform';

import {styles} from './PromotionBanner.style';

const PromotionBanner = () => {
  const style = useThemedStyles(styles);
  const {width: screenWidth} = useWindowDimensions();
  const [isHidden, setIsHidden] = useState(false);

  const shouldShowBanner =
    isMobileBrowser && !isPWA && screenWidth <= breakpoints.mobile;

  const openApp = () => {
    analytics.promotionBannerPressed();

    let pathname = window.location.pathname;

    if (pathname.startsWith('/embed/artist')) {
      pathname = pathname.replace('/embed', '');
    } else if (pathname.startsWith('/embed/playlist')) {
      pathname = pathname.replace('/embed', '');
    } else if (pathname.startsWith('/embed/player')) {
      pathname = pathname.replace('/embed/player', '/track');
    } else if (pathname.startsWith('/embed/platform')) {
      pathname = pathname.replace('/embed', '');
    } else if (pathname.startsWith('/embed/collection')) {
      pathname = pathname.replace('/embed/collection', '/collector');
    }

    window.location.href = `${config.BRANCH_APP_URL}:/${pathname}`;
  };

  if (!shouldShowBanner || isHidden) {
    return null;
  }

  return (
    <Space style={style.banner} p="xs">
      <View style={style.imageWrapper}>
        <DynamicImage style={style.logo} source={logo} />
      </View>
      <Space mr="xs" />
      <Space>
        <Text
          id="appName"
          weight="regular"
          size="xs"
          color="invertedTextColor"
        />
        <Text
          id="promotionBanner.description"
          weight="light"
          size="xxs"
          color="invertedTextColor"
        />
      </Space>
      <Space flex />
      <Button
        onPress={openApp}
        textId="promotionBanner.open"
        ph="xs"
        pv="xs"
        textProps={{size: 'xxs'}}
      />
      <Space ml="xs" />
      <TouchableOpacity onPress={() => setIsHidden(true)}>
        <Space p="xxs">
          <Icon
            name="close"
            provider="ant"
            color="invertedTextColor"
            size={14}
          />
        </Space>
      </TouchableOpacity>
    </Space>
  );
};

export default PromotionBanner;
