import {useAppDispatch} from '@/hooks/useRedux';
import {useTopUpTransactionsQuery} from '@/modules/TopUp/topUpQueries';
import {removeTopUpTransaction} from '@/store/topUp';

export const useTopUpToasts = () => {
  const dispatch = useAppDispatch();
  const topUpTransactions = useTopUpTransactionsQuery();

  const closeTopUpToast = (id: string) => {
    dispatch(removeTopUpTransaction({id}));
  };

  return {
    topUpTransactions,
    closeTopUpToast,
  };
};
