import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {ScrollView, View} from 'react-native';

import QueueIndicator from '@/components/QueueIndicator';
import Text from '@/components/Text';
import {useUsersMenu} from '@/components/UsersMenu/useUsersMenu';
import {MenuItem} from '@/components/WebMenu/components/MenuItem';
import {MenuItemSecondary} from '@/components/WebMenu/components/MenuItemSecondary';
import ProfileMenu from '@/components/WebMenu/components/ProfileMenu';
import ProfilePicker from '@/components/WebMenu/components/ProfilePicker';
import SettingsMenu from '@/components/WebMenu/components/SettingsMenu';
import {usePathname} from '@/components/WebMenu/usePathname';
import {useSubmenu} from '@/components/WebMenu/useSubmenu';
import {useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import NotificationsBadge from '@/modules/Notifications/components/NotificationsBadge';
import {tabsConfig} from '@/navigation/TabsNavigation/TabsNavigation';
import {libraryCategories} from '@/screens/Library/constants';
import {selectCurrentTrackId} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getUserSlug} from '@/utils/user';

import {styles} from './WebMenu.style';

const WebMenu = () => {
  const style = useThemedStyles(styles);
  const rootNavigation = useNavigation<RootStackNavigationParams>();
  const pathname = usePathname();
  const {isSmallDesktop} = useResponsive();

  const {submenuContent, toggleSubmenu, submenuContainer, closeSubmenu} =
    useSubmenu();

  const userMenuProps = useUsersMenu();
  const {activeUser} = userMenuProps;

  const currentTrackId = useAppSelector(selectCurrentTrackId);

  const isActive = (route: string, exact = false) => {
    const prefixedRoute = `/${route}`;

    if (exact) {
      return pathname === prefixedRoute;
    }

    return pathname.startsWith(prefixedRoute);
  };

  const submenus = [
    {
      type: 'settings',
      content: <SettingsMenu close={closeSubmenu} />,
    },
    {
      type: 'profiles',
      content: activeUser && (
        <ProfileMenu closeProfileMenu={closeSubmenu} {...userMenuProps} />
      ),
    },
  ];

  return (
    <View
      // @ts-ignore
      ref={submenuContainer}
      style={[style.menu, isSmallDesktop && style.menu_collapsed]}>
      {submenus.map(submenu => (
        <View
          key={submenu.type}
          style={[
            style.submenu,
            submenuContent === submenu.type && style.submenu_opened,
          ]}>
          {submenu.content}
        </View>
      ))}
      <View style={style.container}>
        <View style={style.opaqueOverlay} />
        <ScrollView
          style={style.scrollView}
          contentContainerStyle={style.scrollViewContent}
          showsVerticalScrollIndicator={false}>
          <MenuItem
            onPress={() => rootNavigation.navigate(Routes.Queue)}
            icon={{
              name: 'queue',
              provider: 'custom',
            }}
            textId="queue.title"
            textProps={{flex: false}}
            disabled={!currentTrackId}
            isActive={isActive('queue')}
            suffixComponent={
              isSmallDesktop ? (
                <QueueIndicator />
              ) : (
                <QueueIndicator
                  horizontal
                  rippleStyle={style.queueIndicatorRipple}
                  numberStyle={style.queueIndicatorNumber}
                />
              )
            }
          />
          <MenuItem
            onPress={() =>
              rootNavigation.navigate(Routes.MainNavigation, {
                screen: Routes.Tabs,
                params: {
                  screen: Routes.SearchNavigation,
                },
              })
            }
            icon={tabsConfig[Routes.SearchNavigation].icon}
            textId={tabsConfig[Routes.SearchNavigation].titleId}
            isActive={isActive(tabsConfig[Routes.SearchNavigation].path)}
          />
          <View style={style.navigationSection}>
            <MenuItem
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.Channels,
                  },
                })
              }
              icon={tabsConfig[Routes.Channels].icon}
              textId={tabsConfig[Routes.Channels].titleId}
              isActive={isActive('channels', !isSmallDesktop)}
            />
          </View>
          <View style={style.navigationSection}>
            <MenuItem
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.ExploreNavigation,
                    params: {
                      screen: Routes.Explore,
                    },
                  },
                })
              }
              icon={tabsConfig[Routes.ExploreNavigation].icon}
              textId={tabsConfig[Routes.ExploreNavigation].titleId}
              isActive={isActive(
                tabsConfig[Routes.ExploreNavigation].path,
                true,
              )}
            />
            <MenuItemSecondary
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.ExploreNavigation,
                    params: {
                      screen: Routes.LatestTracks,
                      initial: false,
                    },
                  },
                })
              }
              titleId="explore.latestTracks"
              isActive={isActive('explore/latest')}
            />
            <MenuItemSecondary
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.ExploreNavigation,
                    params: {
                      screen: Routes.Artists,
                      initial: false,
                    },
                  },
                })
              }
              titleId="explore.artists"
              isActive={isActive('explore/artists')}
            />
            <MenuItemSecondary
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.ExploreNavigation,
                    params: {
                      screen: Routes.TrendingTracks,
                      initial: false,
                    },
                  },
                })
              }
              titleId="explore.trendingTracks"
              isActive={isActive('explore/trending-tracks')}
            />
            <MenuItemSecondary
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.ExploreNavigation,
                    params: {
                      screen: Routes.TrendingPlaylists,
                      initial: false,
                    },
                  },
                })
              }
              titleId="explore.trendingPlaylists"
              isActive={isActive('explore/trending-playlists')}
            />
          </View>
          <MenuItem
            onPress={() =>
              rootNavigation.navigate(Routes.MainNavigation, {
                screen: Routes.ActivityFeed,
              })
            }
            icon={{
              name: 'nftActivity',
              provider: 'custom',
            }}
            textId="activityFeed.title"
            isActive={isActive('activity-feed')}
          />
          {activeUser && (
            <MenuItem
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Notifications,
                })
              }
              iconComponent={<NotificationsBadge />}
              textId="notifications.title"
              isActive={isActive('notifications')}
            />
          )}
          <View style={style.navigationSection}>
            <MenuItem
              onPress={() =>
                rootNavigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Tabs,
                  params: {
                    screen: Routes.LibraryNavigation,
                    params: {
                      screen: Routes.Library,
                      params: {
                        category: libraryCategories[0].id,
                        subcategory: '',
                      },
                    },
                  },
                })
              }
              icon={tabsConfig[Routes.LibraryNavigation].icon}
              textId={tabsConfig[Routes.LibraryNavigation].titleId}
              isActive={isActive('library', !isSmallDesktop)}
            />
            {libraryCategories.map(category => (
              <MenuItemSecondary
                key={category.id}
                onPress={() =>
                  rootNavigation.navigate(Routes.MainNavigation, {
                    screen: Routes.Tabs,
                    params: {
                      screen: Routes.LibraryNavigation,
                      params: {
                        screen: Routes.Library,
                        params: {
                          category: category.id,
                          subcategory: '',
                        },
                      },
                    },
                  })
                }
                titleId={category.titleId}
                isActive={isActive(`library/${category.id}`)}
              />
            ))}
          </View>
          {(activeUser?.artistProfile || (activeUser && isSmallDesktop)) && (
            <>
              <MenuItem
                onPress={() =>
                  rootNavigation.navigate(Routes.MainNavigation, {
                    screen: Routes.Collector,
                    params: {
                      slug: getUserSlug(activeUser),
                    },
                  })
                }
                icon={{name: 'user', provider: 'custom'}}
                textId="menu.collector"
                isActive={isActive(`collector/${getUserSlug(activeUser)}`)}
              />
              {activeUser?.artistProfile && (
                <MenuItem
                  onPress={() =>
                    rootNavigation.navigate(Routes.MainNavigation, {
                      screen: Routes.Artist,
                      params: {
                        slugOrId: activeUser.artistProfile!.slug,
                      },
                    })
                  }
                  icon={{name: 'artist', provider: 'custom'}}
                  textId="menu.artist"
                  isActive={isActive(`artist/${activeUser.artistProfile.slug}`)}
                />
              )}
            </>
          )}
          <MenuItem
            onPress={toggleSubmenu('settings')}
            icon={{name: 'settings', provider: 'custom'}}
            textId="menu.settings"
          />
          <MenuItem
            onPress={() =>
              rootNavigation.navigate(Routes.MainNavigation, {
                screen: Routes.DropOnSpinamp,
              })
            }
            icon={{name: 'upload', provider: 'custom'}}
            textId="dropOnSpinamp.title"
            suffixComponent={
              <View style={style.newLabel}>
                <Text size="xs">New</Text>
              </View>
            }
          />
        </ScrollView>
        <ProfilePicker
          {...userMenuProps}
          isProfileMenuOpen={submenuContent === 'profiles'}
          openProfileMenu={toggleSubmenu('profiles')}
        />
      </View>
    </View>
  );
};

export default WebMenu;
