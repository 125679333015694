import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  centeredColumn: {
    alignItems: 'center',
  },
  actions: {
    flexDirection: 'row',
  },
  action: {
    height: spacing.xl - spacing.xxs,
  },
}));
