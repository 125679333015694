export {default as LocalThemeProvider} from './LocalThemeProvider';
export {useTheme} from '@/theme/useTheme';
export {useThemedStyles} from '@/theme/useThemedStyles';
export {createThemedStyles} from '@/theme/createThemedStyles';
export {useOverrideTheme} from '@/theme/useOverrideTheme';
export {useThemedScreen} from '@/theme/useThemedScreen';
export {useThemedScreenStyles} from '@/theme/useThemedScreenStyles';
export {useCustomColors} from '@/theme/useCustomColors';
export {ThemedScreen} from '@/theme/ThemedScreen';
export {ThemeContext} from '@/theme/context';
export type {IContextValue} from '@/theme/context';
import {Color, ITheme} from '@/types/theme';

export const calculateBackgroundColor = (
  theme: ITheme,
  color: Color = 'background',
) => {
  return theme.background?.image ? 'transparent' : theme.colors[color];
};

export const calculateBackgroundColorLight = (theme: ITheme) => {
  return theme.background?.image ? 'transparent' : theme.colors.backgroundLight;
};

export const calculateBackgroundColorDark = (theme: ITheme) => {
  return theme.background?.image ? 'transparent' : theme.colors.backgroundDark;
};

export const isThemeSquare = (theme: ITheme) => theme.box.borderRadius <= 3;

export const isThemeMonochrome = (theme: ITheme) =>
  theme.colors.backgroundLight === theme.colors.backgroundDark;
