import React, {FC} from 'react';
import Animated, {FadeIn} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import Space, {ISpaceProps} from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {RateMode} from '@/services/trackPlayer/types';

interface IProps extends ISpaceProps {
  rateMode: RateMode;
  onRateModePress: () => void;
}

const PlaybackRateButton: FC<IProps> = ({
  rateMode,
  onRateModePress,
  ...spaceProps
}) => {
  return (
    <Animated.View entering={FadeIn}>
      <Space {...spaceProps}>
        <IconButton onPress={onRateModePress} selected={rateMode !== 1}>
          <Text
            weight="semibold"
            align="center"
            numberOfLines={1}
            ellipsizeMode="clip"
            size={rateMode === 1.5 ? 'xs' : 's'}>
            {rateMode}x
          </Text>
        </IconButton>
      </Space>
    </Animated.View>
  );
};

export default PlaybackRateButton;
