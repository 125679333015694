import {setTransformationsOnCloudinaryUrl} from '../cloudinary';
import {ImageSize} from '@/types/media';

export const getResizedUri = (uri: string, width?: number | ImageSize) => {
  if (!width || !uri.startsWith('http')) {
    return uri;
  }

  let transformations = `c_fill,w_${width.toString()}`;
  if (uri.includes('/video/')) {
    transformations += ',f_gif';
  }

  return setTransformationsOnCloudinaryUrl(uri, transformations);
};

export const getStaticImageUri = (uri: string, width?: number | ImageSize) => {
  if (!width || !uri.startsWith('http')) {
    return uri;
  }

  return setTransformationsOnCloudinaryUrl(
    uri,
    `w_${width.toString()},c_fill,f_jpg`,
  );
};
