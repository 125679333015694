import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    flex: 1,
    padding: spacing.l,
    gap: spacing.l,
  },
  content: {
    gap: spacing.s * 2,
    flex: 1,
  },
  textContainer: {
    gap: spacing.xs,
  },
  links: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.s,
  },
  links_centered: {
    justifyContent: 'center',
  },
}));
