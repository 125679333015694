import {TextProps} from '@/components/Text';
import {chainsById, getChainById} from '@/constants/chains';
import {
  isMobileMetamask as _isMobileMetamask,
  useExternalSigner,
} from '@/modules/ExternalWallet';
import {IAddress} from '@/types/common';

interface IChainValidationInput {
  wallet: IAddress | undefined;
  chainId: number | undefined;
}

interface IWrongChainResult {
  message: {
    title: TextProps;
    message: TextProps | undefined;
  };
  action:
    | {
        onPress: () => void;
        text: TextProps;
      }
    | undefined;
}

export const useCheckWalletChain = ({
  wallet,
  chainId,
}: IChainValidationInput): IWrongChainResult | undefined => {
  const {
    address: connectedWalletAddress,
    activeNetwork,
    switchNetwork,
    connector,
  } = useExternalSigner();

  const isMobileMetamask = _isMobileMetamask(connector);

  if (
    wallet?.isPasskey ||
    !connectedWalletAddress ||
    chainId === activeNetwork
  ) {
    return undefined;
  }

  const message = {
    title: {
      id: 'transactions.wrongChain.title',
    },
    message:
      isMobileMetamask && chainId
        ? {
            id: 'transactions.wrongChain.message.metamask',
            values: {
              chain: getChainById(chainId).name,
            },
          }
        : undefined,
  };

  const action =
    chainId && !isMobileMetamask
      ? {
          onPress: () => switchNetwork?.(chainId),
          text: {
            id: 'transactions.wrongChain.action',
            values: {
              chain: chainsById[chainId]?.name || chainId,
            },
          },
        }
      : undefined;

  return {
    message,
    action,
  };
};
