import React, {FC, useState} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Modal, {IModalProps} from '@/components/Modal';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Text from '@/components/Text';
import {
  AddExternalWalletModal,
  AddPasskeyWalletModal,
  WalletSelectItem,
} from '@/modules/Wallets';
import AddressInputModal from '@/modules/Wallets/components/AddressInputModal';
import AddWalletButton from '@/modules/Wallets/components/AddWalletButton';
import CreatePasskeyBanner from '@/modules/Wallets/components/CreatePasskeyBanner';
import {Passkey} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {getUserPasskeyWallet, sortUserWallets} from '@/utils/user';

import {styles} from './WalletPicker.style';

interface IProps extends IModalProps {
  user: IUser;
  selectedWalletAddress: string | undefined;
  onConfirm: (address: string) => void;
  allowAddExternalWallet?: boolean;
  allowAddPasskeyWallet?: boolean;
  allowCustomAddress?: boolean;
  chainId?: number;
  excludedAddress?: string;
}

type AddWalletModal =
  | 'addPasskeyWallet'
  | 'addExternalWallet'
  | 'addCustomAddress'
  | undefined;

const WalletPicker: FC<IProps> = ({
  user,
  selectedWalletAddress,
  onConfirm,
  allowAddExternalWallet = false,
  allowAddPasskeyWallet = false,
  allowCustomAddress = false,
  chainId,
  excludedAddress,
  ...modalProps
}) => {
  const style = useThemedStyles(styles);
  const [selectedAddress, setSelectedAddress] = useState(selectedWalletAddress);

  const [addWalletModal, setAddWalletModal] =
    useState<AddWalletModal>(undefined);

  const sortedWallets = sortUserWallets(user);
  const wallets = excludedAddress
    ? sortedWallets.filter(a => !areAddressesEqual(a.address, excludedAddress))
    : sortedWallets;
  const passkeyWallet = getUserPasskeyWallet(user);

  const confirm = (confirmedAddress: string) => {
    onConfirm(confirmedAddress);
    modalProps.onClose();
  };

  if (!modalProps.isOpen) {
    return null;
  }

  if (addWalletModal === 'addExternalWallet') {
    return (
      <AddExternalWalletModal
        isOpen
        onSuccess={setSelectedAddress}
        onClose={() => setAddWalletModal(undefined)}
      />
    );
  }

  if (addWalletModal === 'addPasskeyWallet') {
    return (
      <AddPasskeyWalletModal
        isOpen
        onSuccess={setSelectedAddress}
        onClose={() => setAddWalletModal(undefined)}
      />
    );
  }

  if (addWalletModal === 'addCustomAddress') {
    return (
      <AddressInputModal
        isOpen
        onSubmit={confirm}
        onClose={modalProps.onClose}
      />
    );
  }

  return (
    <Modal {...modalProps}>
      <ScrollContainer contentStyle={style.content}>
        {!passkeyWallet && Passkey.isSupported && allowAddPasskeyWallet && (
          <CreatePasskeyBanner
            onAddPasskeyWallet={() => setAddWalletModal('addPasskeyWallet')}
          />
        )}

        {wallets.length > 0 && (
          <View style={style.header}>
            <Text size="s" id="transfer.walletPicker.header" />
          </View>
        )}

        {wallets.length === 0 && (
          <Text id="transfer.walletPicker.noWallets" size="xs" align="center" />
        )}

        {wallets.map(address => (
          <WalletSelectItem
            key={address.address}
            wallet={address}
            selectedWalletAddress={selectedAddress}
            onSelect={setSelectedAddress}
            chainId={chainId}
          />
        ))}

        {allowAddExternalWallet && (
          <AddWalletButton
            onPress={() => setAddWalletModal('addExternalWallet')}
          />
        )}

        {allowCustomAddress && (
          <AddWalletButton
            onPress={() => setAddWalletModal('addCustomAddress')}
            text={{id: 'wallets.typeCustomAddress'}}
          />
        )}
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <CardButton
          onPress={() => {
            if (selectedAddress) {
              confirm(selectedAddress);
            }
          }}
          disabled={!selectedAddress}
          text={{id: 'confirm'}}
        />
      </ShadowFooter>
    </Modal>
  );
};

export default WalletPicker;
