import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import TrackCard from '@/components/TrackCard';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useUpdatePlaylistSuggestions} from '@/queries/ownedPlaylists';
import {usePlaylistVoteMutation} from '@/queries/publicPlaylists';
import {useFullUserByIdQuery} from '@/queries/user';
import ContributorInfo from '@/screens/Playlist/components/ContributorInfo';
import VotersModal from '@/screens/Playlist/components/VotersModal';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ICountedVotes, IPlaylist, IPlaylistVotes} from '@/types/playlists';

import {styles} from './SuggestedTrack.style';

interface IProps {
  playlist: IPlaylist;
  track: ITrack;
  isOwner: boolean;
  onPlay: (track: ITrack) => void;
  votes: IPlaylistVotes;
  countedVotes: ICountedVotes;
}

const SuggestedTrack: FC<IProps> = ({
  track,
  isOwner,
  playlist,
  onPlay,
  votes,
  countedVotes,
}) => {
  const style = useThemedStyles(styles);
  const [isVotersModalOpen, openVotersModal, closeVotersModal] =
    useBooleanState(false);

  const activeUser = useActiveUser();
  const suggesterId = playlist.suggestions?.[track.id]?.userId;
  const {user: suggester} = useFullUserByIdQuery(suggesterId);
  const isSuggester = activeUser?.id === suggesterId;
  const votesCount = countedVotes[track.id] || 0;
  const isVoted = !!activeUser && !!votes[track.id]?.[activeUser.id]?.upvote;
  const showVoteButton = !!activeUser && !isOwner;

  const {updateSuggestion} = useUpdatePlaylistSuggestions();
  const {mutate: voteOnTrack} = usePlaylistVoteMutation(playlist.id);

  const suggestionHandler = (accept: boolean) => () => {
    updateSuggestion({
      playlistId: playlist.id,
      trackId: track.id,
      accept,
    });
  };

  return (
    <View>
      <View style={style.row}>
        {isOwner && (
          <IconButton
            style={style.leftButton}
            onPress={suggestionHandler(false)}
            icon={{name: 'minus', provider: 'custom'}}
          />
        )}
        <TrackCard onPlay={onPlay} track={track} />
        {isOwner && (
          <IconButton
            style={style.rightButton}
            onPress={suggestionHandler(true)}
            icon={{name: 'add', provider: 'custom'}}
          />
        )}
        {showVoteButton && (
          <IconButton
            style={style.rightButton}
            onPress={() => voteOnTrack({trackId: track.id, upvote: !isVoted})}
            icon={{name: 'upvote', provider: 'custom', fill: isVoted}}
            disabled={isSuggester}
          />
        )}
      </View>
      {suggesterId && (
        <View style={style.suggesterInfo}>
          {isOwner && <View style={style.spacer} />}

          <ContributorInfo textId="playlist.suggestedBy" user={suggester} />

          <Space ml="xs" flex />

          <TouchableOpacity
            onPress={openVotersModal}
            disabled={votesCount === 0}>
            <Text id="playlist.votes" values={{votes: votesCount}} size="xs" />
          </TouchableOpacity>

          {(isOwner || showVoteButton) && <View style={style.spacer} />}
        </View>
      )}
      {isVotersModalOpen && (
        <VotersModal
          track={track}
          votes={votes[track.id]}
          isOpen
          onClose={closeVotersModal}
        />
      )}
    </View>
  );
};

export default React.memo(SuggestedTrack);
