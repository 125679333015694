import {useEffect} from 'react';

import {usePlayerPreloader} from '@/hooks/usePlayerPreloader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {
  selectCurrentTrack,
  selectCurrentTrackId,
  stopPlayer,
} from '@/store/player';

export const usePlayerManager = () => {
  const dispatch = useAppDispatch();
  const currentTrackId = useAppSelector(selectCurrentTrackId);
  const currentTrack = useAppSelector(selectCurrentTrack);

  usePlayerPreloader();

  useEffect(() => {
    // stop player if there is no matching track for currently played id
    if (currentTrackId && !currentTrack) {
      dispatch(stopPlayer());
    }
  }, [currentTrackId, currentTrack]);
};
