import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Text from '@/components/Text';
import {tipChain} from '@/modules/Tip/constants';
import {useVerifyAddressState} from '@/modules/Tip/VerifyAddress/useVerifyAddressState';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {useThemedStyles} from '@/theme';
import {prettifyAddress} from '@/utils/ethereum';

import {styles} from './VerifyAddressSuccess.style';

const VerifyAddressSuccess: FC = () => {
  const style = useThemedStyles(styles);

  const {close, transaction} = useVerifyAddressState();

  return (
    <View style={style.container}>
      <View style={style.content}>
        <View style={style.textContainer}>
          <Text
            id="tip.verify.success.header"
            weight="semibold"
            size="l"
            align="center"
          />
          <Text
            id="tip.verify.success.description"
            align="center"
            values={{address: prettifyAddress(transaction.payoutAddress)}}
          />
        </View>

        <CardButton onPress={close} text={{id: 'goBack'}} />
      </View>

      <View
        style={[style.links, !transaction.userOpHash && style.links_centered]}>
        {transaction.userOpHash && (
          <JiffyscanLink
            userOpHash={transaction.userOpHash}
            chainId={tipChain.id}
          />
        )}
        {transaction.txHash && (
          <EtherscanLink txHash={transaction.txHash} chainId={tipChain.id} />
        )}
      </View>
    </View>
  );
};

export default VerifyAddressSuccess;
