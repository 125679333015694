import React, {FC, useState} from 'react';
import {ScrollView, TouchableOpacity, View} from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  useSharedValue,
} from 'react-native-reanimated';

import FlatCard from '@/components/FlatCard';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Input from '@/components/Input';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {DROPDOWN_HEIGHT_MOBILE, styles} from './DropDown.style';

interface IOption {
  text?: string;
  textId?: string;
  value: string;
}

export interface IDropDownProps {
  onChange: (value: string) => void;
  value?: string;
  placeholderId?: string;
  options: IOption[];
}

const DropDown: FC<IDropDownProps> = ({
  onChange,
  value,
  placeholderId,
  options,
}) => {
  const style = useThemedStyles(styles);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const filteredOptions = options.filter(option =>
    option.text?.toLowerCase().includes(search.toLowerCase()),
  );

  const selectedOption = options.find(option => option.value === value);

  const onClose = () => {
    setSearch('');
    setIsOpen(false);
  };

  const isItemPressed = useSharedValue(false);

  return (
    <View style={style.container}>
      {isOpen ? (
        <Input
          autoFocus
          onChangeText={setSearch}
          value={search}
          onBlur={() => {
            if (!isItemPressed.value) {
              onClose();
            }
          }}
          suffixComponent={
            <IconButton
              size={24}
              onPress={onClose}
              icon={{provider: 'custom', name: 'close'}}
            />
          }
          scrollToOffset={DROPDOWN_HEIGHT_MOBILE + spacing.l}
        />
      ) : (
        <TouchableOpacity
          style={style.input}
          onPress={() => setIsOpen(true)}
          activeOpacity={0.8}>
          {selectedOption ? (
            <Text id={selectedOption.textId}>{selectedOption.text}</Text>
          ) : (
            <Text style={style.placeholder} id={placeholderId} />
          )}

          <Icon provider="custom" name="arrowDown" style={style.arrowIcon} />
        </TouchableOpacity>
      )}
      {isOpen && (
        <Animated.View
          style={style.dropdown}
          entering={FadeIn.duration(150)}
          exiting={FadeOut.duration(150)}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            bounces={false}
            contentContainerStyle={style.dropdownContent}>
            {filteredOptions.map(option => (
              <FlatCard
                key={option.value}
                style={style.item}
                // @ts-ignore
                onMouseDown={() => (isItemPressed.value = true)}
                isSelected={option.value === value}
                onPress={() => {
                  isItemPressed.value = false;
                  onChange(option.value);
                  onClose();
                }}>
                <Text size="s" flex numberOfLines={1} id={option.textId}>
                  {option.text}
                </Text>
              </FlatCard>
            ))}

            {filteredOptions.length === 0 && (
              <View style={style.empty}>
                <Text align="center" id="dropDown.empty" />
              </View>
            )}
          </ScrollView>
        </Animated.View>
      )}
    </View>
  );
};

export default DropDown;
