import {useEffect} from 'react';

import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {selectCurrentTrack, togglePlay} from '@/store/player';

export const usePlayerKeyboardHandler = () => {
  const dispatch = useAppDispatch();
  const currentTrack = useAppSelector(selectCurrentTrack);

  useEffect(() => {
    if (currentTrack) {
      const listener = (event: KeyboardEvent) => {
        if (event.code === 'Space' || event.key === '') {
          event.preventDefault();
          dispatch(togglePlay());
        }
      };

      window.addEventListener('keydown', listener);
      return () => window.removeEventListener('keydown', listener);
    }
  }, [currentTrack]);
};
