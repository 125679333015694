import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar/Avatar';
import Icon from '@/components/Icon';
import Text from '@/components/Text/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import UsdPrice from '@/components/UsdPrice';
import {useUserLink} from '@/hooks/useUserLink';
import {tipChain} from '@/modules/Tip/constants';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {IBaseUser} from '@/types/user';
import {formatPrice, prettifyAddress} from '@/utils/ethereum';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

import {styles, AVATAR_SIZE} from './TipperCard.style';

interface IProps {
  user: IBaseUser;
  amount: string;
  toAddress: string;
}

const TipperCard: FC<IProps> = ({user, amount, toAddress}) => {
  const style = useThemedStyles(styles);

  const {navigateToUser} = useUserLink();

  const avatarUrl = getUserAvatar(user);

  return (
    <TouchableCard style={style.card} onPress={() => navigateToUser(user)}>
      {({actionColor}) => (
        <View style={style.container}>
          <Avatar
            id={user.id}
            url={avatarUrl}
            size={AVATAR_SIZE}
            resizeWidth={ImageSize.thumbnail}
          />
          <View style={style.content}>
            <Text weight="semibold" numberOfLines={1} color={actionColor()}>
              {getUserDisplayName(user)}
            </Text>
            <View style={style.tippedRow}>
              <Icon
                provider="custom"
                name="heart"
                fill
                size={12}
                color="favoritesColor"
              />
              <Text
                flex
                numberOfLines={1}
                size="xs"
                id="tipper.tipped"
                values={{
                  address: prettifyAddress(toAddress),
                }}
              />
            </View>
          </View>
          <View style={style.amount}>
            <Text>
              +{formatPrice(BigInt(amount), tipChain.chain.nativeCurrency)}
            </Text>
            <UsdPrice
              secondary
              size="xs"
              chainId={tipChain.id}
              wei={BigInt(amount)}
            />
          </View>
        </View>
      )}
    </TouchableCard>
  );
};

export default TipperCard;
