import {useInfiniteQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchHistory} from '@/api/history';
import {fetchTracksByIds} from '@/api/track';
import {MINUTE} from '@/constants/time';
import {useAppSelector} from '@/hooks/useRedux';
import {useDbQuery} from '@/queries/db';
import {selectActiveUserSigner} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';
import {getLastItem, isNotNil} from '@/utils/functions';

export const useHistoryQuery = (enableQuery: boolean) => {
  const signer = useAppSelector(selectActiveUserSigner);
  const {db, updateDb} = useDbQuery();

  const query = useInfiniteQuery({
    queryKey: [QueryKeys.history, signer?.address],
    queryFn: async ({pageParam}) => {
      const historyTracks = await fetchHistory(signer!, pageParam || undefined);
      const historyTracksIds = historyTracks
        .map(({trackId}) => trackId)
        .filter(isNotNil);
      const tracks = await fetchTracksByIds(historyTracksIds);
      updateDb({tracks});
      return historyTracks;
    },
    enabled: !!signer && enableQuery,
    initialPageParam: '',
    getNextPageParam: lastPage =>
      lastPage.length < 50 ? undefined : getLastItem(lastPage)?.timestamp,
    staleTime: MINUTE,
  });

  const historyTracks = useMemo(
    () =>
      query.data?.pages
        .reduce((allData, currentPage) => allData.concat(currentPage))
        .map(item => ({
          timestamp: item.timestamp,
          track: db.tracks[item.trackId],
        }))
        .filter(item => !!item.track) || [],
    [db.tracks, query.data?.pages],
  );

  return {
    historyTracks,
    query,
  };
};
