import {useEffect, useRef, useState} from 'react';

import {config} from '@/constants/config';

type SubmenuContent = 'settings' | 'profiles';

export const useSubmenu = () => {
  const [submenuContent, setSubmenuContent] = useState<SubmenuContent | null>(
    null,
  );
  const submenuContainer = useRef<Element>(null);

  const toggleSubmenu = (content: SubmenuContent) => () => {
    if (submenuContent === content) {
      setSubmenuContent(null);
    } else {
      setSubmenuContent(content);
    }
  };

  const closeSubmenu = () => setSubmenuContent(null);

  useEffect(() => {
    if (submenuContent !== null) {
      const clickOutsideHandler = (event: MouseEvent) => {
        if (
          !submenuContainer.current ||
          !event.target ||
          !(event.target instanceof Element) ||
          submenuContainer.current.contains(event.target)
        ) {
          return;
        }

        setSubmenuContent(null);
      };

      const rootElement = document.getElementById(config.WEB_APP_ROOT_ID);
      rootElement?.addEventListener('click', clickOutsideHandler, true);
      return () => {
        rootElement?.removeEventListener('click', clickOutsideHandler, true);
      };
    }
  }, [submenuContent]);

  return {
    submenuContent,
    closeSubmenu,
    toggleSubmenu,
    submenuContainer,
  };
};
