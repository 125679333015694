import {
  createShareLinkGetter,
  shareOnLens,
  shareOnTwitter,
  shareOnWarpcast,
} from '@/utils/share';

export const useInviteToTip = (artistSlug: string) => {
  const getShareText = (spinampHandle: string) =>
    `Love what I'm spinning on ${spinampHandle}? Tip me as much as you want. Help me keep the vibes going strong. Thank you in advance!`;
  const shareUrl = createShareLinkGetter('tip')(artistSlug);

  return {
    shareOnTwitter: () => shareOnTwitter(getShareText('@spin_amp'), shareUrl),
    shareOnWarpcast: () => shareOnWarpcast(getShareText('@spinamp'), shareUrl),
    shareOnLens: () => () =>
      shareOnLens(getShareText('@lens/spinamp'), shareUrl),
  };
};
