import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  button: {
    height: 50,
    borderRadius: theme.box.borderRadius,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
  },
}));
