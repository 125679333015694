import React, {FC} from 'react';

import TransparentButton from '../TransparentButton';
import spacing from '@/constants/spacing';
import {useTrackReleaseNotification} from '@/hooks/useTrackReleaseNotification';

import {IProps} from './types';

const TrackReleaseNotificationButton: FC<IProps> = ({track, analyticsInfo}) => {
  const {subscribe, isSubscribed, iconName, textId} =
    useTrackReleaseNotification({track, analyticsInfo});

  if (isSubscribed) {
    return (
      <TransparentButton
        // possibly open a modal with the notification(s)
        rippleDisabled
        iconSpacing={spacing.xxs}
        style={{
          paddingHorizontal: 0,
          pointerEvents: 'none',
        }}
        onPress={() => {}}
        text={{
          id: textId,
        }}
        icon={{provider: 'custom', name: iconName, fill: true}}
      />
    );
  }

  return (
    <TransparentButton
      rippleDisabled
      iconSpacing={spacing.xxs}
      style={{
        paddingHorizontal: 0,
        marginLeft: spacing.xs,
      }}
      onPress={subscribe}
      text={{
        id: textId,
        numberOfLines: 1,
      }}
      icon={{provider: 'custom', name: iconName, fill: false}}
    />
  );
};

export default TrackReleaseNotificationButton;
