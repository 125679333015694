import {useStore} from '@/hooks/useRedux';
import {useTransactionEventTracking} from '@/modules/Transactions/eventsTracking';
import {
  ITransactionEvent,
  TransactionState,
} from '@/modules/Transactions/types';
import {useCryptoTransferState} from '@/modules/Transfer/CryptoTransfer/useCryptoTransferState';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {selectTransactionById} from '@/store/transactions/selectors';
import {generateId} from '@/utils/functions';

export const useTrackCryptoTransferEvent = () => {
  const {getState} = useStore();

  const {transfer, senderWallet} = useCryptoTransferState();

  const {mutate} = useTransactionEventTracking();

  const trackEvent = (eventName: ITransactionEvent['eventName']) => {
    const transactionState = selectTransactionById(
      getState(),
      transfer.id,
      'cryptoTransfer',
    ) as Extract<TransactionState, {type: 'cryptoTransfer'}>;

    if (transactionState?.userId && senderWallet) {
      mutate({
        id: generateId(),
        timestamp: Date.now(),
        transactionId: transactionState.id,
        type: 'cryptoTransfer' as ITransactionEvent['type'],
        userId: transactionState.userId,
        eventName,
        chainId: transactionState.chainId,
        address: senderWallet.address,
        isPasskey: isPasskeyWallet(senderWallet),
        receivingAddress: transactionState.to,
        amount: transactionState.amount,
        txHash: transactionState.txHash,
        userOpHash: transactionState.userOpHash,
        error: transactionState.error?.message,
      });
    }
  };

  return {
    trackEvent,
  };
};
