import {useScrollToTop} from '@react-navigation/native';
import React, {FC, useMemo, useRef} from 'react';

import {IIconProps} from '@/components/Icon';
import InfinityList from '@/components/InfinityList';
import {PLAYLIST_CARD_HEIGHT} from '@/components/PlaylistCard';
import PlaylistCard from '@/components/PlaylistCard/PlaylistCard';
import {
  useCollaborativePlaylists,
  useUserPlaylists,
} from '@/hooks/useUserPlaylists';
import {LibraryUI} from '@/screens/Library/components/LibrarySharedUI';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';
import {LibrarySubcategory} from '@/screens/Library/types';
import {PlaylistType} from '@/types/playlists';

const deleteIcon: IIconProps = {
  name: 'minus',
  provider: 'ant',
  size: 18,
};
const unfollowIcon: IIconProps = {
  provider: 'fontAwesome',
  name: 'heart',
  color: 'favoritesColor',
  size: 14,
};

const emptyMessages: Record<LibrarySubcategory, string> = {
  [LibrarySubcategory.ownPlaylists]: 'library.playlists.empty.my',
  [LibrarySubcategory.followedPlaylists]: 'library.playlists.empty.followed',
  [LibrarySubcategory.collaborativePlaylists]:
    'library.playlists.empty.collaborative',
};

const LibraryPlaylists: FC = () => {
  const customPlaylists = useUserPlaylists(PlaylistType.custom);
  const followedPlaylists = useUserPlaylists(PlaylistType.followedPlaylist);
  const collaborativePlaylists = useCollaborativePlaylists();

  const {onScroll, selectedSubcategory, deletePlaylist} = useLibraryContext();
  const listRef = useRef(null);

  useScrollToTop(listRef);

  const visiblePlaylist = useMemo(() => {
    if (selectedSubcategory?.id === LibrarySubcategory.ownPlaylists) {
      return customPlaylists;
    }

    if (selectedSubcategory?.id === LibrarySubcategory.followedPlaylists) {
      return followedPlaylists;
    }

    if (selectedSubcategory?.id === LibrarySubcategory.collaborativePlaylists) {
      return collaborativePlaylists;
    }

    return [...customPlaylists, ...followedPlaylists];
  }, [
    selectedSubcategory,
    followedPlaylists,
    customPlaylists,
    collaborativePlaylists,
  ]);

  return (
    <InfinityList
      ref={listRef}
      data={visiblePlaylist}
      onScroll={onScroll}
      keyExtractor={playlist => playlist.id}
      itemSize={PLAYLIST_CARD_HEIGHT}
      ListHeaderComponent={<LibraryUI.Title />}
      ListEmptyComponent={
        <LibraryUI.Empty
          textId={
            (selectedSubcategory && emptyMessages[selectedSubcategory.id]) ||
            'library.playlists.empty'
          }
        />
      }
      renderItem={({item: playlist}) => {
        if (playlist.followedPlaylist) {
          return (
            <PlaylistCard
              playlist={playlist.followedPlaylist}
              showCreator
              action={() => deletePlaylist(playlist)}
              actionIcon={unfollowIcon}
            />
          );
        }

        return (
          <PlaylistCard
            playlist={playlist}
            action={() => deletePlaylist(playlist)}
            actionIcon={deleteIcon}
            showCreator={false}
          />
        );
      }}
    />
  );
};

export default LibraryPlaylists;
