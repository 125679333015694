import {Dimensions, Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const ACTION_MIN_WIDTH = spacing.s * 4;

export const styles = createThemedStyles(() => ({
  container: {
    gap: spacing.s * 2,
  },
  avatarContainer: {
    alignSelf: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowActionsWrapper: {
    minWidth: 'auto',
    flexBasis: `${100 / 3}%`,
    flexGrow: 0,
    flexShrink: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowActions: {
    paddingHorizontal: spacing.s,
    flexDirection: 'row',
    minWidth: ACTION_MIN_WIDTH,
  },
  titleWrapper: {
    minWidth: 0,
    flexGrow: 1,
    // @ts-ignore
    maxWidth: Platform.select({
      web: `calc(100% - ${ACTION_MIN_WIDTH * 2}px)`,
      // @ts-ignore
      default: Dimensions.get('window').width - ACTION_MIN_WIDTH * 2,
    }),
  },
  titleContent: {
    paddingHorizontal: spacing.s,
    alignItems: 'center',
  },
}));
