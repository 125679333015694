import {ElementType, parseDocument} from 'htmlparser2';

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stripHtmlString = (html: string) => {
  const inlineTags = ['span', 'strong', 'em', 'b', 'i', 'a'];
  const document = parseDocument(html);
  let output = '';

  // types are not properly exported from htmlparser2 so we have to use any
  const parseNode = (node: any, index: number): string => {
    if (node.type === ElementType.Text) {
      return node.data;
    }

    if (node.type === ElementType.Tag) {
      const separator =
        index > 0 && !inlineTags.includes(node.name) ? '\n' : '';

      return node.children.reduce(
        (result: string, currentNode: any, currentIndex: number) =>
          result + parseNode(currentNode, currentIndex),
        separator,
      );
    }

    return '';
  };

  document.children.forEach((node, index) => {
    output += parseNode(node, index);
  });

  return output;
};
