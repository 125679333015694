import React, {FC} from 'react';
import {ViewStyle} from 'react-native';

import ImageWithPreview from '@/components/ImageWithPreview';
import Jazzicon from '@/components/Jazzicon';
import StaticImage from '@/components/StaticImage';
import {useTheme} from '@/theme';
import {ImageSize} from '@/types/media';
import {Color} from '@/types/theme';
import {getSpecialAddress} from '@/utils/ethereum';

interface IProps {
  id: string;
  size: number;
  resizeWidth?: ImageSize | number;
  url?: string | null;
  style?: ViewStyle;
  expandable?: boolean;
  backgroundColor?: Color;
}

const Avatar: FC<IProps> = ({
  url: _url,
  id,
  resizeWidth,
  size,
  style,
  expandable = false,
  backgroundColor = 'background',
}) => {
  const theme = useTheme();
  const url = _url || getSpecialAddress(id)?.avatarUrl;
  const jazzicon = <Jazzicon size={size} address={id} style={style} />;

  if (!url) {
    return jazzicon;
  }

  const imageProps = {
    source: {uri: url},
    style: [
      style,
      {
        width: size,
        height: size,
        borderRadius: size / 2,
        overflow: 'hidden',
        backgroundColor: theme.colors[backgroundColor],
      },
    ],
  };

  if (expandable && resizeWidth) {
    return (
      <ImageWithPreview
        {...imageProps}
        resizeWidth={resizeWidth}
        previewResizeWidth={ImageSize.avatarPreview}
        fallback={jazzicon}
      />
    );
  }

  return (
    <StaticImage
      resizeWidth={resizeWidth}
      {...imageProps}
      fallback={jazzicon}
    />
  );
};

export default React.memo(Avatar);
