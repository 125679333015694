import React, {FC, ReactNode, useEffect} from 'react';
import {StyleProp} from 'react-native';
import Animated, {
  Easing,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

interface IProps {
  children?: ReactNode;
  style?: StyleProp<any>;
  delay?: number;
  maxOpacity?: number;
  enabled?: boolean;
}

const FadeInOut: FC<IProps> = ({
  children,
  style,
  delay = 0,
  maxOpacity = 1,
}) => {
  const fadeAnimation = useSharedValue(0);

  const fadingStyle = useAnimatedStyle(() => ({
    opacity: interpolate(fadeAnimation.value, [0, 1], [0.4, maxOpacity]),
  }));

  useEffect(() => {
    fadeAnimation.value = withDelay(
      delay,
      withRepeat(
        withTiming(1, {duration: 1000, easing: Easing.ease}),
        -1,
        true,
      ),
    );
  }, []);

  return <Animated.View style={[style, fadingStyle]}>{children}</Animated.View>;
};

const FadeInOutWrapper: FC<IProps> = ({enabled = true, ...props}) => {
  if (enabled) {
    return <FadeInOut {...props} />;
  }

  return <>{props.children}</>;
};

export default FadeInOutWrapper;
