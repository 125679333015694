import {useNavigation} from '@react-navigation/native';
import React, {FC, ReactNode} from 'react';
import {Pressable, TouchableOpacity, View} from 'react-native';

import DynamicImage from '@/components/DynamicImage/DynamicImage';
import IconButton from '@/components/IconButton';
import SlidingText from '@/components/SlidingText';
import Space from '@/components/Space/Space';
import spacing from '@/constants/spacing';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {LocalThemeProvider, useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles, PLAYER_BAR_HEIGHT} from './PlayerBar.style';
import Progress from './Progress';

interface IPlayerBarProps {
  currentTrack?: ITrack | null;
  isPlaying: boolean;
  hasNext: boolean;
  onPlayPress: () => void;
  onNextPress: () => void;
  onPreviousPress?: () => void;
  fullPlayerEnabled?: boolean;
  hideImage?: boolean;
  queueIndicatorComponent?: ReactNode;
}

const PlayerBar: FC<IPlayerBarProps> = ({
  currentTrack,
  isPlaying,
  hasNext,
  onPlayPress,
  onPreviousPress,
  onNextPress,
  fullPlayerEnabled = true,
  hideImage = false,
  queueIndicatorComponent,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const openPlayer = () => {
    if (fullPlayerEnabled) {
      navigation.navigate(Routes.Player);
    }
  };

  const openQueue = () => {
    if (fullPlayerEnabled) {
      navigation.navigate(Routes.Queue);
    }
  };

  return (
    <View
      style={[style.wrapper, {height: currentTrack ? PLAYER_BAR_HEIGHT : 0}]}>
      <TouchableOpacity
        onPress={openPlayer}
        disabled={!fullPlayerEnabled}
        activeOpacity={0.8}>
        <Space style={style.playerPreview}>
          <Progress />
          {currentTrack?.lossyArtworkUrl && !hideImage && (
            <Space style={style.artworkWrapper}>
              <DynamicImage
                resizeWidth={ImageSize.thumbnail}
                source={{uri: currentTrack.lossyArtworkUrl}}
                style={style.artwork}
                showSpinner
                spinnerSize={spacing.m}
              />
            </Space>
          )}
          <Space ph="xs" style={style.infoSection}>
            <SlidingText key={currentTrack?.id} weight="semibold" size="s">
              {currentTrack?.title}
            </SlidingText>
            <Space mt="xxs" />
            <SlidingText key={currentTrack?.artistId} size="s">
              {currentTrack && getTrackArtistsNames(currentTrack)}
            </SlidingText>
          </Space>
          <View style={style.controls}>
            {fullPlayerEnabled && (
              <View>
                {queueIndicatorComponent}
                <IconButton
                  style={style.control}
                  onPress={openQueue}
                  icon={{provider: 'custom', name: 'queue', size: 26}}
                  hitSlop="xxs"
                />
              </View>
            )}
            {onPreviousPress && (
              <IconButton
                style={style.control}
                onPress={onPreviousPress}
                icon={{provider: 'custom', name: 'rewind', size: 26}}
                hitSlop="xxs"
              />
            )}
            <IconButton
              style={style.control}
              onPress={onPlayPress}
              icon={{
                provider: 'custom',
                name: isPlaying ? 'pause' : 'play',
                size: 26,
              }}
              hitSlop="xxs"
            />
            <Pressable>
              <IconButton
                style={style.control}
                onPress={onNextPress}
                icon={{provider: 'custom', name: 'forward', size: 26}}
                hitSlop="xxs"
                disabled={!hasNext}
              />
            </Pressable>
          </View>
        </Space>
      </TouchableOpacity>
    </View>
  );
};

export const PlayerBarThemed: FC<IPlayerBarProps> = props => {
  const {currentTrack} = props;
  const {artistThemes, platformThemes} = useCustomThemesConfigQuery();

  const trackArtistTheme =
    currentTrack?.artistId && artistThemes[currentTrack.artistId];

  const trackPlatformTheme =
    currentTrack?.platformId && platformThemes[currentTrack.platformId];

  return (
    <LocalThemeProvider
      customTheme={currentTrack?.artist?.customTheme}
      predefinedThemeName={trackArtistTheme || trackPlatformTheme}
      shouldFallbackToAppTheme>
      <PlayerBar {...props} />
    </LocalThemeProvider>
  );
};

export default PlayerBar;
