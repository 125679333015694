import React, {FC} from 'react';
import {TouchableOpacity} from 'react-native';
import Animated, {useAnimatedStyle, withTiming} from 'react-native-reanimated';

import spacing, {HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './Switch.style';

interface IProps {
  value: boolean;
  setValue: (value: boolean) => void;
  size?: number;
}

const Switch: FC<IProps> = ({value, setValue, size = spacing.m}) => {
  const style = useThemedStyles(styles);
  const containerWidth = size * 2;
  const containerBorderWidth = 2;
  const contentSize = size - 2 * containerBorderWidth;

  const containerStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(value ? 1 : 0.2, {duration: 200}),
    }),
    [value],
  );

  const contentStyle = useAnimatedStyle(
    () => ({
      transform: [
        // @ts-ignore
        {
          translateX: withTiming(
            value ? containerWidth - contentSize - 2 * containerBorderWidth : 0,
            {duration: 200},
          ),
        },
      ],
    }),
    [value, containerWidth, contentSize, containerBorderWidth],
  );

  return (
    <TouchableOpacity
      onPress={() => setValue(!value)}
      activeOpacity={0.8}
      hitSlop={HIT_SLOP}>
      <Animated.View
        style={[
          style.container,
          {width: containerWidth, height: size, borderRadius: size / 2},
          containerStyle,
        ]}>
        <Animated.View
          style={[
            // @ts-ignore
            style.content,
            {
              width: contentSize,
              height: contentSize,
              borderRadius: contentSize / 2,
              position: 'absolute',
              left: containerBorderWidth,
              top: containerBorderWidth,
              bottom: containerBorderWidth,
            },
            contentStyle,
          ]}
        />
      </Animated.View>
    </TouchableOpacity>
  );
};

export default Switch;
