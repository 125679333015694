import {useScrollToTop} from '@react-navigation/native';
import React, {FC, useRef} from 'react';

import {ARTIST_CARD_HEIGHT} from '@/components/ArtistCard';
import InfinityList from '@/components/InfinityList';
import Link from '@/components/Link';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import FollowedArtistCard from '@/screens/Library/components/FollowedArtistCard';
import {LibraryUI} from '@/screens/Library/components/LibrarySharedUI';
import {useFollowedArtistPlaylists} from '@/screens/Library/hooks/useFollowedPlaylists';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';
import {Routes} from '@/types/routes';

import * as style from './style';

const LibraryArtists: FC = () => {
  const listRef = useRef(null);
  const {
    followedArtistPlaylists,
    isEmpty,
    query: {isError, isLoading, refetch},
  } = useFollowedArtistPlaylists();
  const {deletePlaylist, onScroll} = useLibraryContext();

  useScrollToTop(listRef);

  if (!isEmpty) {
    return (
      <InfinityList
        ref={listRef}
        data={followedArtistPlaylists}
        onScroll={onScroll}
        keyExtractor={playlist => playlist.id}
        itemSize={ARTIST_CARD_HEIGHT}
        ListHeaderComponent={<LibraryUI.Title />}
        renderItem={({item: playlist}) => (
          <FollowedArtistCard playlist={playlist} onUnfollow={deletePlaylist} />
        )}
      />
    );
  }

  if (isLoading) {
    return <ScreenLoader />;
  }

  if (isError) {
    return <LibraryUI.Error onRetry={refetch} />;
  }

  return (
    <LibraryUI.Empty textId="library.artists.empty">
      <Space mt="xxs">
        <Link
          style={style.centered}
          to={{
            screen: Routes.Tabs,
            params: {
              screen: Routes.ExploreNavigation,
              params: {screen: Routes.Artists, initial: false},
            },
          }}>
          <Text align="center" id="library.artists.discoverHere" underline />
        </Link>
      </Space>
    </LibraryUI.Empty>
  );
};

export default LibraryArtists;
