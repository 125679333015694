import {useQueryClient} from '@tanstack/react-query';
import {Vibration} from 'react-native';
import {encodeFunctionData, isHex} from 'viem';
import {useWalletClient} from 'wagmi';

import {useConnectorName} from '@/modules/ExternalWallet/useConnectorName';
import {isWalletCancelError, TransactionError} from '@/modules/Transactions';
import {TransferStep} from '@/modules/Transfer';
import {useTransferState} from '@/modules/Transfer/TrackTransfer/useTransferState';
import {getTransferWriteContractInput} from '@/modules/Transfer/utils';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {AddressString, IAddress} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {areAddressesEqual, getChainClient} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

import {useTrackTransferEvent} from './useTrackTransferEvent';

export const useTransferTransaction = () => {
  const queryClient = useQueryClient();

  const {
    transfer,
    updateTransfer,
    user,
    track,
    resetTransferDetails,
    gasEstimation,
  } = useTransferState();
  const {data: walletClient} = useWalletClient();
  const connector = useConnectorName();

  const {trackEvent} = useTrackTransferEvent();

  const setTransferStep = (step: TransferStep) => updateTransfer({step});

  const transferFromPasskey = async (senderWallet: IAddress) => {
    setTransferStep('waitingForPasskey');
    const {credentialId, signer} = senderWallet.metadata?.spinampWallet || {};
    const {privateKey} = await Passkey.get(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransactionError('WRONG_PASSKEY_SIGNER');
    }

    setTransferStep('executingTransaction');

    const userOpHash = await sendTransaction({
      privateKey,
      chainId: transfer.chainId,
      transaction: {
        to: transfer.contract as AddressString,
        data: encodeFunctionData(getTransferWriteContractInput(transfer)),
      },
      gasSettings: {
        callGasLimit: gasEstimation.callGasLimit,
        preVerificationGas: gasEstimation.preVerificationGas,
        verificationGasLimit: gasEstimation.verificationGasLimit,
        maxFeePerGas: gasEstimation.maxFeePerGas,
        maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      },
    });

    updateTransfer({userOpHash});
    trackEvent('receivedTransactionUserOpHash');

    setTransferStep('waitingForReceipt');

    const {transactionHash, status} = await waitForReceipt({
      privateKey,
      chainId: transfer.chainId,
      hash: userOpHash,
    });

    updateTransfer({txHash: transactionHash});
    trackEvent('receivedTransactionTxHash');

    if (status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const transferFromExternal = async () => {
    if (!walletClient) {
      throw new TransactionError('WALLET_NOT_CONNECTED');
    }

    if (walletClient.account.address !== transfer.from) {
      throw new TransactionError('WRONG_WALLET_CONNECTED');
    }

    setTransferStep('executingTransaction');
    const txHash = await walletClient.writeContract({
      gas: gasEstimation.gasLimit,
      maxFeePerGas: gasEstimation.maxFeePerGas,
      maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      address: transfer.contract as AddressString,
      ...getTransferWriteContractInput(transfer),
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransactionError('USER_CANCELLED');
    }

    updateTransfer({txHash});
    trackEvent('receivedTransactionTxHash');

    setTransferStep('waitingForReceipt');

    const receipt = await getChainClient(
      transfer.chainId,
    ).waitForTransactionReceipt({
      hash: txHash,
    });

    if (receipt.status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const executeTransfer = async (senderWallet: IAddress) => {
    try {
      trackEvent('confirmed');

      resetTransferDetails();

      if (isPasskeyWallet(senderWallet)) {
        await transferFromPasskey(senderWallet);
      } else {
        await transferFromExternal();
      }

      setTransferStep('success');
      Vibration.vibrate();
      trackEvent('success');

      // Invalidate queries related to collected nft after few seconds, so transaction has time to be indexed.
      // Timeout value should be adjusted after indexing time is improved.
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.collection, user?.id],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.addressCollection, transfer.from],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.addressCollection, transfer.to],
          refetchType: 'all',
        });
      }, 10000);
    } catch (error: any) {
      setTransferStep('checkout');

      if (isWalletCancelError(error)) {
        trackEvent('cancelled');
        resetTransferDetails();
        return;
      }

      Sentry.captureException(error, {
        tags: {
          transfer: true,
          passkeyWallet: isPasskeyWallet(senderWallet),
        },
        extra: {
          wallet: senderWallet,
          trackId: track.id,
          connector,
        },
      });

      updateTransfer({error});
      trackEvent('error');
    }
  };

  return {
    executeTransfer,
  };
};
