import {useMutation, useQueryClient} from '@tanstack/react-query';

import {updateAddressProperties} from '@/api/user';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {selectActiveUserId, selectActiveUserSigner} from '@/store/user';
import {IAddressProperties, IAddressWithOwner} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {IUser} from '@/types/user';

export const useUpdateWalletMutation = () => {
  const {showToast} = useToast();
  const queryClient = useQueryClient();
  const activeUserId = useAppSelector(selectActiveUserId);
  const signer = useAppSelector(selectActiveUserSigner);

  return useMutation({
    retry: 0,
    mutationFn: async (
      addressUpdate: {id: string} & Partial<IAddressProperties>,
    ) => {
      if (!signer) {
        throw new Error('missing signer');
      }

      return updateAddressProperties(addressUpdate, signer!);
    },
    onMutate: addressUpdate => {
      const profiles = queryClient.getQueryData<IUser[]>([
        QueryKeys.userProfiles,
      ]);
      const wallet = queryClient.getQueryData<IAddressWithOwner>([
        QueryKeys.walletById,
        addressUpdate.id,
      ]);

      queryClient.setQueryData<IAddressWithOwner>(
        [QueryKeys.walletById, addressUpdate.id],
        currentWallet =>
          currentWallet && {
            ...currentWallet,
            ...addressUpdate,
          },
      );
      queryClient.setQueryData<IUser[]>(
        [QueryKeys.userProfiles],
        currentProfiles =>
          currentProfiles?.map(profile => {
            if (profile.id === activeUserId) {
              return {
                ...profile,
                addresses: profile.addresses.map(a => {
                  if (a.address === addressUpdate.id) {
                    return {
                      ...a,
                      ...addressUpdate,
                    };
                  }

                  return a;
                }),
              };
            }

            return profile;
          }),
      );

      return {wallet, profiles};
    },
    onError: (error, newTodo, context) => {
      context?.profiles &&
        queryClient.setQueryData([QueryKeys.userProfiles], context.profiles);
      context?.wallet &&
        queryClient.setQueryData(
          [QueryKeys.walletById, context.wallet.address],
          context.wallet,
        );
      showToast({textId: 'error.tryAgain'});
    },
    onSettled: () => {
      queryClient.invalidateQueries({queryKey: [QueryKeys.userProfiles]});
    },
  });
};
