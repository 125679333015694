import React, {FC, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {View} from 'react-native';

import Text, {TextProps} from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './Loader.style';

const MAX_DOTS_COUNT = 3;

export interface ILoaderProps extends TextProps {
  textId?: string;
  text?: string;
}

const Loader: FC<ILoaderProps> = ({textId = 'loading', text, ...textProps}) => {
  const [dots, setDots] = useState(MAX_DOTS_COUNT);
  const style = useThemedStyles(styles);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots(value => (value + 1) % (MAX_DOTS_COUNT + 1));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  const {formatMessage} = useIntl();

  const textStyle: TextProps = {
    weight: 'semibold',
    align: 'center',
    ...textProps,
  };
  return (
    <View style={style.container}>
      <Text {...textStyle}>{text ? text : formatMessage({id: textId})}</Text>
      <View>
        <Text {...textStyle} style={style.dotsContainer}>
          {new Array(MAX_DOTS_COUNT).fill('.').join('')}
        </Text>
        <Text {...textStyle} style={style.dots}>
          {new Array(dots).fill('.').join('')}
        </Text>
      </View>
    </View>
  );
};

export default Loader;
