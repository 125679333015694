import React, {FC} from 'react';
import {Pressable, TouchableOpacity, View} from 'react-native';

import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import {getVerticalHitSlop} from '@/constants/spacing';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {WalletWithAddress} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {IBaseUser} from '@/types/user';
import {formatPrice, prettifyAddress} from '@/utils/ethereum';

import {styles, ARROW_SIZE} from './CollectWalletsSettings.style';

interface IProps {
  user: IBaseUser;
}

const CollectWalletsSettings: FC<IProps> = ({user}) => {
  const style = useThemedStyles(styles);

  const {transaction, pushModal, paymentWallet, deliveryWallet, balance} =
    useCollectState();
  const hasMultipleWallets = user.addresses.filter(a => a.isWallet).length > 1;
  const differentWallets =
    paymentWallet &&
    transaction.walletsSettings?.deliveryAddress &&
    paymentWallet.address !== transaction.walletsSettings?.deliveryAddress;

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={style.container}
      onPress={() => pushModal('walletsPicker')}>
      <View style={style.infoContainer}>
        <View style={style.row}>
          <View style={style.rowLabel}>
            <Text size="xs" id="collect.walletsSettings.paymentMethod" />
          </View>
          <View style={style.addressContainer}>
            {paymentWallet && <WalletWithAddress wallet={paymentWallet} />}
            {balance && (
              <Text size="xs" align="right">
                {formatPrice(
                  balance.value,
                  {
                    decimals: balance.decimals,
                    symbol: balance.symbol,
                  },
                  5,
                )}
              </Text>
            )}
          </View>
        </View>

        {hasMultipleWallets && (
          <>
            <View>
              <Divider />
              <Icon
                name="arrowRight"
                provider="custom"
                style={style.floatingArrow}
              />
            </View>
            <View style={style.row}>
              <View style={style.rowLabel}>
                {differentWallets ? (
                  <Tooltip
                    position="top-left"
                    content={
                      <Text
                        size="xxs"
                        id="collect.walletsSettings.receivingWallet.disclaimer"
                        style={style.tooltipContent}
                      />
                    }>
                    {({open, close}) => (
                      <Pressable
                        onPress={open}
                        onHoverIn={open}
                        onHoverOut={close}
                        hitSlop={getVerticalHitSlop('xs')}
                        style={style.tooltipRow}>
                        <Text
                          size="xs"
                          id="collect.walletsSettings.receivingWallet"
                        />
                        <Icon size={16} name="info" provider="custom" />
                      </Pressable>
                    )}
                  </Tooltip>
                ) : (
                  <Text
                    size="xs"
                    id="collect.walletsSettings.receivingWallet"
                  />
                )}
              </View>
              <View style={style.addressContainer}>
                {deliveryWallet ? (
                  <WalletWithAddress wallet={deliveryWallet} />
                ) : transaction.walletsSettings?.deliveryAddress ? (
                  <Text size="xs">
                    {prettifyAddress(
                      transaction.walletsSettings.deliveryAddress,
                    )}
                  </Text>
                ) : null}
              </View>
            </View>
          </>
        )}
      </View>

      <View style={style.arrowContainer}>
        {!hasMultipleWallets && (
          <Icon name="arrowRight" provider="custom" size={ARROW_SIZE} />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default CollectWalletsSettings;
