import React, {FC} from 'react';
import {View} from 'react-native';

import IconButton from '@/components/IconButton';
import SpinampWalletIcon from '@/components/SpinampWalletIcon';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './PasskeyOnboardingBanner.style';

interface IProps {
  onClose: () => void;
}

const PasskeyOnboardingBanner: FC<IProps> = ({onClose}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <IconButton
        size={spacing.l}
        style={style.closeButton}
        icon={{name: 'close', provider: 'custom'}}
        onPress={onClose}
      />

      <SpinampWalletIcon size={spacing.s * 2} />

      <Text
        weight="semibold"
        align="center"
        id="collect.passkeyOnboarding.title"
      />

      <Text size="xs" align="center" id="collect.passkeyOnboarding.message" />
    </View>
  );
};

export default PasskeyOnboardingBanner;
