import axios, {AxiosRequestConfig} from 'axios';

import {config} from '@/constants/config';

const defaultOptions: AxiosRequestConfig = {
  baseURL: config.SPINAMP_BACKEND_URL,
};

const API = axios.create(defaultOptions);

API.interceptors.request.use(
  async axiosConfig => axiosConfig,
  async error => Promise.reject(error),
);

API.interceptors.response.use(
  response => response.data,
  error => Promise.reject(error.response),
);

export default API;
