import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {Routes} from '@/types/routes';

export interface INavigationState {
  currentScreen?: Routes;
  canGoBack: boolean;
}

export const initialState: INavigationState = {
  currentScreen: undefined,
  canGoBack: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigationState: (state, action: PayloadAction<INavigationState>) => {
      state.currentScreen = action.payload.currentScreen;
      state.canGoBack = action.payload.canGoBack;
    },
  },
});

export const {setNavigationState} = navigationSlice.actions;

export default navigationSlice.reducer;
