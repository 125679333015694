import React, {FC} from 'react';
import {View} from 'react-native';
import {makeMutable} from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import Header from '@/components/Header';
import Icon from '@/components/Icon';
import Screen from '@/components/Screen';
import Text from '@/components/Text';
import {useLogin} from '@/modules/Login';
import {useThemedStyles} from '@/theme';

import {styles} from './SignInState.style';

interface IProps {}

const SignInState: FC<IProps> = ({}) => {
  const style = useThemedStyles(styles);
  const {openLoginModal} = useLogin();

  return (
    <Screen>
      <Header showBack scrollPosition={makeMutable(0)} />
      <View style={style.container}>
        <Icon provider="custom" name="crowd" size={72} />

        <Text
          id="dropOnSpinamp.signIn.title"
          weight="semibold"
          size="l"
          align="center"
        />

        <Text id="dropOnSpinamp.signIn.description" size="xs" align="center" />

        <CardButton onPress={openLoginModal} text={{id: 'signUpOrLogin'}} />
      </View>
    </Screen>
  );
};

export default SignInState;
