import React, {FC, Fragment, ReactNode} from 'react';
import {
  Modal as RNModal,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewProps,
} from 'react-native';
import {GestureDetector} from 'react-native-gesture-handler';
import Animated, {AnimateProps} from 'react-native-reanimated';

import Card from '@/components/Card/Card';
import Icon, {IIconProps} from '@/components/Icon';
import KeyboardView from '@/components/KeyboardView/KeyboardView';
import {useSwipeToClose} from '@/components/Modal/useSwipeToClose';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';

import {styles} from './Modal.style';

export interface IModalProps {
  children?: ReactNode;
  titleId?: string;
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  avoidKeyboard?: boolean;
  disableClickOutside?: boolean;
  swipeable?: boolean;
  closeOnContentClick?: boolean;
  style?: StyleProp<any>;
  contentStyle?: StyleProp<any>;
  backdropColor?: string;
  closeIcon?: IIconProps;
  animatedContainerProps?: {key?: string} & AnimateProps<ViewProps>;
}

const Modal: FC<IModalProps> = ({
  children,
  isOpen,
  onClose,
  title,
  titleId,
  avoidKeyboard,
  disableClickOutside,
  swipeable = false,
  closeOnContentClick = false,
  style: customStyle,
  contentStyle,
  backdropColor,
  closeIcon,
  animatedContainerProps,
}) => {
  const style = useThemedStyles(styles);
  const {isMobile, isSmallDesktop, isDesktop} = useResponsive();
  const Wrapper = avoidKeyboard ? KeyboardView : Fragment;

  const {swipeGesture, swipePosition, swipeStyles} = useSwipeToClose({
    isOpen,
    enabled: swipeable && isMobile,
    onClose,
  });

  return (
    <RNModal
      animationType="fade"
      transparent
      visible={isOpen}
      onRequestClose={onClose}>
      <GestureDetector gesture={swipeGesture}>
        <Wrapper>
          <View style={style.wrapper}>
            <TouchableOpacity
              onPress={onClose}
              style={[
                style.backdrop,
                !!backdropColor && {backgroundColor: backdropColor},
              ]}
              activeOpacity={1}
              disabled={disableClickOutside}
            />
            {/* TouchableWithoutFeedback prevents click events bubbling to content behind the modal on web */}
            <TouchableWithoutFeedback
              onPress={() => {
                if (closeOnContentClick && swipePosition.value === 0) {
                  onClose();
                }
              }}>
              <Animated.View
                style={[
                  style.modal,
                  isSmallDesktop && style.modalSmallDesktop,
                  isDesktop && style.modalDesktop,
                  customStyle,
                  swipeStyles,
                  closeOnContentClick && {cursor: 'pointer'},
                ]}
                {...animatedContainerProps}>
                <Card style={[style.card, contentStyle]}>
                  {(title || titleId) && (
                    <Space style={style.header} p="xs">
                      {!disableClickOutside && (
                        <TouchableOpacity
                          onPress={onClose}
                          style={style.closeIcon}>
                          <Icon
                            name="close"
                            provider="custom"
                            {...closeIcon}
                            color="invertedTextColor"
                          />
                        </TouchableOpacity>
                      )}
                      <Text
                        id={titleId}
                        align="center"
                        color="invertedTextColor"
                        weight="semibold">
                        {title}
                      </Text>
                    </Space>
                  )}
                  {children}
                </Card>
              </Animated.View>
            </TouchableWithoutFeedback>
          </View>
        </Wrapper>
      </GestureDetector>
    </RNModal>
  );
};

export default Modal;
