import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  tooltip: {
    padding: 0,
  },
  action: {
    height: spacing.xxl,
    borderRadius: 0,
    borderWidth: 0,

    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs * 1.5,
    paddingHorizontal: spacing.xs * 1.5,
  },
}));
