import {InfiniteData} from '@tanstack/react-query';

import {IPaginatedResponse} from '@/types/api';

export const getNextPageParam = (lastPage: IPaginatedResponse<unknown>) =>
  lastPage.pageInfo.hasNextPage ? lastPage.pageInfo.endCursor : undefined;

export const flattenItems = <ItemType>(
  data?: InfiniteData<IPaginatedResponse<ItemType>>,
) => {
  if (!data) {
    return [];
  }

  return data.pages.reduce<ItemType[]>(
    (result, page) => [...result, ...page.items],
    [],
  );
};
