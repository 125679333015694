import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  content: {
    flexGrow: 1,
    padding: spacing.s,
  },
  trackInfo: {
    alignItems: 'center',
  },
  artworkWrapper: {
    width: 100,
    height: 100,
    borderRadius: theme.box.borderRadius,
    overflow: 'hidden',
    backgroundColor: theme.colors.background,
  },
  artwork: {
    height: '100%',
    width: '100%',
  },
  list: {
    gap: spacing.xs,
  },
}));
