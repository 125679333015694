import {TransactionError, TransactionErrorCode} from '@/modules/Transactions';

export type CollectErrorCode =
  | TransactionErrorCode
  | 'OFFER_NOT_AVAILABLE'
  | 'OFFER_PRICE_CHANGED'
  | 'NO_WALLET_SELECTED'
  | 'APPROVAL_TRANSACTION_REVERTED';

interface CollectErrorDetails {
  chainId: number;
  txHash: string;
}

export class CollectError extends TransactionError {
  status: CollectErrorCode;
  transactionDetails?: CollectErrorDetails;

  constructor(
    status: CollectErrorCode,
    transactionDetails?: CollectErrorDetails,
  ) {
    super(status);
    this.status = status;
    this.transactionDetails = transactionDetails;
  }
}
