import {useCallback, useState} from 'react';

import {useDeletePlaylist} from '@/queries/ownedPlaylists';
import {IPlaylist} from '@/types/playlists';

export const useDeletePlaylistModal = () => {
  const [deletedPlaylist, setDeletedPlaylist] = useState<IPlaylist | null>(
    null,
  );
  const {deletePlaylistMutation} = useDeletePlaylist();

  const deletePlaylist = useCallback((playlist: IPlaylist) => {
    setDeletedPlaylist(playlist);
  }, []);

  const confirmDeletion = useCallback(() => {
    deletedPlaylist && deletePlaylistMutation(deletedPlaylist.id);
    setDeletedPlaylist(null);
  }, [deletedPlaylist]);

  const cancelDeletion = useCallback(() => {
    setDeletedPlaylist(null);
  }, []);

  return {
    deletedPlaylist,
    deletePlaylist,
    confirmDeletion,
    cancelDeletion,
  };
};
