import {isNative, isWeb} from '@/utils/platform';

export const isMetamask = (connector?: string) => {
  if (!connector) {
    return false;
  }

  return connector.toLowerCase().includes('metamask');
};

export const isMobileMetamask = (connector?: string) =>
  isNative && isMetamask(connector);

export const isWebMetamask = (connector?: string) =>
  isWeb && isMetamask(connector);
