import {useMemo} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {useResponsive} from '@/hooks/useResponsive';
import {ThemedStyles} from '@/types/theme';

import {useTheme} from './useTheme';

export const useThemedStyles = (styles: ThemedStyles) => {
  const theme = useTheme();
  const responsive = useResponsive();
  const safeAreaInsets = useSafeAreaInsets();

  return useMemo(
    () => styles(theme, responsive, safeAreaInsets),
    [theme, responsive, safeAreaInsets],
  );
};
