import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {useSignOut} from '@/hooks/useSignOut';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {useLogin} from '@/modules/Login';
import {useUserProfilesQuery} from '@/queries/user';
import {setActiveUserId} from '@/store/user';

import {IUserMenuProps} from './types';

export const useUsersMenu = (): IUserMenuProps => {
  const dispatch = useAppDispatch();
  const {isConnected} = useExternalSigner();
  const {signOut} = useSignOut();
  const {openLoginModal} = useLogin();

  const activeUser = useActiveUser();
  const {users = []} = useUserProfilesQuery();

  const switchUser = (userId: string) => {
    dispatch(setActiveUserId(userId));
  };

  return {
    activeUser,
    users,
    switchUser,
    isConnected,
    openLoginModal,
    signOut,
  };
};
