import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const CARD_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  card: {
    height: CARD_HEIGHT,
    borderRadius: theme.box.borderRadius,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: theme.colors.backgroundDark,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
  },
  artwork: {
    height: '100%',
    aspectRatio: 1,
  },
  trackInfo: {
    flex: 1,
    paddingHorizontal: spacing.xs,
    gap: spacing.xxs / 2,
  },
}));
