import TransferABI from '@/abi/TransferABI.json';
import {
  ITrackTransferPayload,
  ITrackTransferState,
  ITransferParams,
} from '@/modules/Transfer';
import {IUser} from '@/types/user';

export const areTransferParamsValid = ({chainId, from}: ITransferParams) =>
  Boolean(from && chainId);

export const areTrackTransferParamsValid = ({
  tokenId,
  tokenType,
  contract,
  chainId,
  from,
}: ITransferParams & ITrackTransferPayload) =>
  areTransferParamsValid({chainId, from}) &&
  Boolean(contract && tokenId && ['erc721', 'erc1155'].includes(tokenType));

export const getTransferWriteContractInput = (
  transfer: ITrackTransferState,
) => {
  const tokenType = transfer.tokenType as 'erc721' | 'erc1155';

  if (!['erc721', 'erc1155'].includes(tokenType)) {
    throw new Error('Token standard not supported ');
  }

  const params = {
    erc721: {
      abi: TransferABI.erc721,
      functionName: 'transferFrom',
      args: [transfer.from, transfer.to, BigInt(transfer.tokenId)],
    },
    erc1155: {
      abi: TransferABI.erc1155,
      functionName: 'safeTransferFrom',
      args: [transfer.from, transfer.to, BigInt(transfer.tokenId), 1, ''],
    },
  };

  return params[tokenType];
};

export const getDefaultDeliveryAddress = (
  user: IUser | undefined,
  from: string,
  to: string | undefined,
) => {
  if (to) {
    return to;
  }

  if (
    user &&
    user.metadata.defaultDeliveryWallet &&
    user.metadata.defaultDeliveryWallet !== from
  ) {
    return user.metadata.defaultDeliveryWallet;
  }

  return undefined;
};
