import {useContext, useEffect} from 'react';

import {ThemeContext} from './context';

export const useCustomColors = (stringifiedColors?: string) => {
  const {overrideColors, resetColors} = useContext(ThemeContext);

  useEffect(() => {
    if (stringifiedColors) {
      overrideColors?.(stringifiedColors);

      return resetColors;
    }
  }, [stringifiedColors]);
};
