import React, {FC, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSharedValue, withTiming} from 'react-native-reanimated';

import FullScreenWrapper from '@/components/ModalScreenWrapper/FullScreenWrapper';
import {useResponsive} from '@/hooks/useResponsive';

import ModalScreenLayout from './ModalScreenLayout';
import {IModalScreenWrapperProps} from './types';

const ModalScreenWrapper: FC<IModalScreenWrapperProps> = props => {
  const opacity = useSharedValue(0);
  const {isMobile} = useResponsive();

  useEffect(() => {
    opacity.value = withTiming(1, {duration: 300});
  }, []);

  if (isMobile && props.isFullscreenOnMobile) {
    return <FullScreenWrapper>{props.children}</FullScreenWrapper>;
  }

  return createPortal(<ModalScreenLayout {...props} />, document.body);
};

export default ModalScreenWrapper;
