import React, {useState} from 'react';
import {LayoutChangeEvent, TouchableOpacity, View} from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import OpacityGradient from '@/components/OpacityGradient/OpacityGradient';
import Text from '@/components/Text';
import {HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './CollapsibleRenderHtml.style';
import RenderHtml from './RenderHtml';
import {ICollapsibleRenderHtmlProps} from './types';

const GRADIENT_HEIGHT = 15;

const CollapsibleRenderHtml: React.FC<ICollapsibleRenderHtmlProps> = ({
  closedHeight,
  html,
  color = 'backgroundDark',
}) => {
  const style = useThemedStyles(styles);
  const [isExpanded, setIsExpanded] = useState(false);
  const [fullHeight, setFullHeight] = useState(0);

  const onLayout = (event: LayoutChangeEvent) => {
    const {layout} = event.nativeEvent;
    if (fullHeight !== layout.height) {
      setFullHeight(layout.height);
    }
  };

  const openAnimation = useDerivedValue(
    () =>
      withTiming(isExpanded ? 1 : 0, {
        duration: 200,
      }),
    [isExpanded],
  );

  const showSeeMore = fullHeight > closedHeight;

  const containerStyle = useAnimatedStyle(() => {
    // It covers initial render in open state. We don't know height yet so we want to render all content without restrictions
    if (isExpanded && !fullHeight) {
      return {maxHeight: undefined};
    }

    if (fullHeight < closedHeight) {
      return {maxHeight: closedHeight};
    }

    return {
      maxHeight: interpolate(
        openAnimation.value,
        [0, 1],
        [closedHeight, fullHeight],
      ),
    };
  });

  const seeMoreStyle = useAnimatedStyle(() => ({
    opacity: withTiming(showSeeMore ? 1 : 0, {duration: 150}),
  }));

  const gradientStyle = useAnimatedStyle(() => ({
    opacity: withTiming(isExpanded ? 0 : 1, {duration: 150}),
  }));

  return (
    <>
      <Animated.View style={[style.container, containerStyle]}>
        <View style={style.content} onLayout={onLayout}>
          <RenderHtml html={html} />
        </View>
      </Animated.View>

      <View style={style.seeMoreContainer}>
        {showSeeMore && (
          <Animated.View style={seeMoreStyle}>
            <Animated.View
              style={[
                gradientStyle,
                style.gradient,
                {
                  height: GRADIENT_HEIGHT,
                  top: -GRADIENT_HEIGHT,
                },
              ]}>
              <OpacityGradient color={color} vertical />
            </Animated.View>
            <TouchableOpacity
              onPress={() => setIsExpanded(v => !v)}
              hitSlop={HIT_SLOP}>
              <Text
                size="xxs"
                weight="semibold"
                align="center"
                id={isExpanded ? 'seeLess' : 'seeMore'}
              />
            </TouchableOpacity>
          </Animated.View>
        )}
      </View>
    </>
  );
};

export default React.memo(CollapsibleRenderHtml);
