import {useNavigation} from '@react-navigation/native';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';
import {useFieldArray, useForm} from 'react-hook-form';

import {updateExternalLinksBulk, updateUser} from '@/api/user';
import {useGlobalModal} from '@/components/GlobalModal/GlobalModal';
import {useCanEditCollector} from '@/hooks/useCanEdit';
import {useImageUploader} from '@/hooks/useImageUploader';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {selectActiveUserSigner} from '@/store/user';
import {EntityUpdateError} from '@/types/api';
import {IExternalLink} from '@/types/common';
import {ImageSize} from '@/types/media';
import {QueryKeys} from '@/types/queryKeys';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {IBaseUser, IUser, IUserUpdate} from '@/types/user';
import {compareExternalLinks} from '@/utils/externalLinks';
import {getResizedUri, preloadImages} from '@/utils/image';
import {getImageUrl} from '@/utils/ipfs';
import {getUserName, getUserSlug} from '@/utils/user';

const avatarSizeInfo = {
  minSize: 100,
  maxSize: 1000,
};

export const useCollectorEditForm = (user: IBaseUser) => {
  const {showToast} = useToast();
  const queryClient = useQueryClient();
  const navigation = useNavigation<MainStackNavigationParams>();
  const signer = useAppSelector(selectActiveUserSigner);
  const modal = useGlobalModal();
  const canEditCollector = useCanEditCollector(user);

  useEffect(() => {
    // Kick the user out of the edit page if they don't have permission to edit
    if (!canEditCollector) {
      navigation.goBack();
    }
  }, [canEditCollector]);

  const form = useForm({
    mode: 'onTouched',
    defaultValues: {
      name: getUserName(user) || '',
      description: user.description || '',
      externalLinks: user.externalLinks,
    },
  });
  const externalLinksForm = useFieldArray({
    control: form.control,
    name: 'externalLinks',
  });

  const avatarUploadProps = useImageUploader({
    maxSize: avatarSizeInfo.maxSize,
    minSize: avatarSizeInfo.minSize,
    circular: true,
  });

  const mutation = useMutation<
    unknown,
    EntityUpdateError,
    IUserUpdate & {externalLinks: IExternalLink[]}
  >({
    networkMode: 'always',
    mutationFn: ({externalLinks, ...userChanges}) => {
      if (!signer) {
        throw new Error('missing signer');
      }

      const linksToSave = compareExternalLinks(
        user.externalLinks,
        externalLinks,
      );

      return Promise.all([
        updateUser(userChanges, signer),
        updateExternalLinksBulk(user.id, linksToSave, signer),
      ]);
    },
    onSuccess: async (response, variables) => {
      if (variables.avatarUrl) {
        preloadImages([
          getResizedUri(variables.avatarUrl, ImageSize.thumbnail),
        ]);
      }

      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.userBySlug, getUserSlug(user)],
        }),
        queryClient.invalidateQueries({queryKey: [QueryKeys.userProfiles]}),
      ]);

      // set updated avatarUrl to local uri, so the preview is visible instant
      const localAvatarUri = avatarUploadProps?.pickedImage?.uri;
      if (localAvatarUri) {
        queryClient.setQueryData<IBaseUser>(
          [QueryKeys.userBySlug, getUserSlug(user)],
          data =>
            data && {
              ...data,
              avatarUrl: localAvatarUri,
            },
        );

        queryClient.setQueryData<IUser[]>(
          [QueryKeys.userProfiles],
          profiles =>
            profiles &&
            profiles.map(profile => {
              if (profile.id === user.id) {
                return {
                  ...profile,
                  avatarUrl: localAvatarUri,
                };
              }
              return profile;
            }),
        );
      }

      navigation.navigate(Routes.Collector, {slug: getUserSlug(user)});
      showToast({textId: 'profileEdit.saved'});
    },
    onError: error => {
      if (error?.data?.error?.code === 'RESTRICTED_NAME_ERROR') {
        form.setError('name', {message: 'profileEdit.error.restrictedName'});
        return;
      }

      showToast({textId: 'profileEdit.errorSaving'});
    },
  });

  const onDiscard = () => {
    modal.openModal({
      titleId: 'profileEdit.discard.title',
      descriptionId: 'profileEdit.discard.description',
      confirmTextId: 'profileEdit.discard.confirm',
      cancelTextId: 'profileEdit.discard.cancel',
      onConfirm: navigation.goBack,
    });
  };

  const onSubmit = form.handleSubmit(formValues => {
    if (avatarUploadProps.isUploading) {
      return;
    }

    const {ipfsHash: avatarIPFSHash} = avatarUploadProps;
    mutation.mutate({
      id: user.id,
      name: formValues.name,
      description: formValues.description,
      avatarUrl: getImageUrl(avatarIPFSHash),
      avatarIPFSHash,
      externalLinks: formValues.externalLinks,
    });
  });

  return {
    form,
    onDiscard,
    onSubmit,
    isSubmitting: mutation.isPending,
    avatarUploadProps,
    avatarSizeInfo,
    externalLinksForm,
  };
};
