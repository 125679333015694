import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    gap: spacing.l,
    flexGrow: 1,
  },
  sectionTitle: {
    marginBottom: spacing.xs,
  },
  footer: {
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.xs * 1.5,
    gap: spacing.xs,
  },
  validationError: {
    marginVertical: spacing.xs,
  },
}));
