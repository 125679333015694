import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AvatarLayout from '@/components/AvatarLayout';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import ImageWithPreview from '@/components/ImageWithPreview';
import Link from '@/components/Link';
import {CollapsibleRenderHtml} from '@/components/RenderHtml';
import Share from '@/components/Share';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {externalPlatformsConfig} from '@/constants/externalPlatforms';
import spacing from '@/constants/spacing';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useResponsive} from '@/hooks/useResponsive';
import {
  enableNotifications,
  requestNotificationsPermission,
} from '@/modules/Notifications/pushNotifications';
import {canTipArtist} from '@/modules/Tip/utils';
import {WalletListModal} from '@/modules/Wallets';
import {useUpdateUserMetadataMutation} from '@/queries/user';
import {useThemedStyles} from '@/theme';
import {IArtist} from '@/types/common';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getArtistUrl} from '@/utils/share';
import {getIsArtistDropNotificationEnabled} from '@/utils/user';

import {styles} from './ArtistInfo.style';

interface IProps {
  artist: IArtist;
  isOwnProfile: boolean;
  linkEnabled: boolean;
}

const ArtistInfo: FC<IProps> = ({artist, isOwnProfile, linkEnabled}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();
  const {isMobile} = useResponsive();
  const user = useActiveUser();
  const isDropNotificationEnabled = getIsArtistDropNotificationEnabled(
    user,
    artist.id,
  );

  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  const [isWalletsModalOpen, openWalletsModal, closeWalletsModal] =
    useBooleanState(false);

  const {getIsFollowed, toggleFollow} = useArtistFollow();
  const isFollowed = getIsFollowed(artist.id);

  const artistLinkConfig = {
    to: {
      screen: Routes.MainNavigation,
      params: {
        screen: Routes.Artist,
        params: {slugOrId: artist.slug},
      },
    },
  };

  const artistName = (
    <Text weight="semibold" size="l" align="center" style={style.titleText}>
      {artist.name}
    </Text>
  );

  const setArtistDropNotificationEnabled = async (
    artistId: string,
    value: boolean,
  ) => {
    updateUserMetadataMutation.mutate({
      user: user!,
      metadataUpdate: {
        notificationSettings: {
          collectorProfile: {
            trackDrop: {
              artists: {
                [artistId]: value,
              },
            },
          },
        },
      },
    });
    if (value) {
      requestNotificationsPermission();
    }
  };

  return (
    <View style={style.container}>
      <AvatarLayout
        avatar={
          artist.avatarUrl && (
            <View style={style.avatarContainer}>
              <View style={style.avatarWrapper}>
                <ImageWithPreview
                  source={{uri: artist.avatarUrl}}
                  style={style.avatar}
                  resizeWidth={ImageSize.artist}
                  hasBorderRadius
                  fallback={
                    <Icon
                      name="userMusic"
                      provider="custom"
                      size={36}
                      color="primary"
                    />
                  }
                />
              </View>
              {!isOwnProfile && canTipArtist(artist) && (
                <TransparentButton
                  onPress={() =>
                    navigation.navigate(Routes.SendTip, {slug: artist.slug})
                  }
                  rippleDisabled
                  hoverOpacity={0.8}
                  text={{id: 'tips.tip'}}
                  style={style.tipButton}
                />
              )}
            </View>
          )
        }
        title={
          <>
            {linkEnabled ? (
              <Link {...artistLinkConfig}>{artistName}</Link>
            ) : (
              <>{artistName}</>
            )}
            {artist.addresses.some(a => a.isPublic) && (
              <TransparentButton
                pressedColor="background"
                onPress={openWalletsModal}
                height={spacing.l}
                text={{id: 'wallets.title', weight: 'regular', size: 'xs'}}
                contentStyle={{left: 16 / 2}}
                suffix={<Icon name="arrowRight" provider="custom" size={16} />}
              />
            )}
          </>
        }
        leftAction={
          <Share url={getArtistUrl(artist.slug)} title={artist.name}>
            {({onPress, icon}) => <IconButton onPress={onPress} icon={icon} />}
          </Share>
        }
        rightAction={
          isOwnProfile ? (
            <IconButton
              onPress={() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.ArtistEdit,
                  params: {slugOrId: artist.slug},
                })
              }
              icon={{
                name: 'edit',
                provider: 'custom',
                fill: isFollowed,
              }}
            />
          ) : (
            <View style={style.artistActions}>
              <IconButton
                onPress={() => toggleFollow(artist.id)}
                icon={{
                  name: 'heart',
                  provider: 'custom',
                  fill: isFollowed,
                  color: isFollowed ? 'favoritesColor' : 'textColor',
                }}
              />
              {isFollowed && user && (
                <IconButton
                  onPress={() =>
                    setArtistDropNotificationEnabled(
                      artist.id,
                      !isDropNotificationEnabled,
                    )
                  }
                  icon={{
                    name: 'notifications',
                    provider: 'custom',
                    fill: isDropNotificationEnabled,
                  }}
                />
              )}
            </View>
          )
        }
      />

      {!!artist.description && (
        <View style={style.description}>
          <CollapsibleRenderHtml html={artist.description} closedHeight={100} />
        </View>
      )}

      {artist.externalLinks?.length > 0 && (
        <View style={style.socialLinks}>
          {artist.externalLinks.map(externalLink => {
            const config = externalPlatformsConfig[externalLink.type];

            return (
              <IconButton
                href={externalLink.url}
                size={isMobile ? spacing.xxl : undefined}
                key={externalLink.id}
                icon={{
                  name: config?.icon || 'externalPlatform',
                  provider: 'custom',
                }}
              />
            );
          })}
        </View>
      )}

      {isWalletsModalOpen && (
        <WalletListModal
          addresses={artist.addresses}
          isOpen
          onClose={closeWalletsModal}
        />
      )}
    </View>
  );
};

export default ArtistInfo;
