import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {View} from 'react-native';
import Animated from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import Header from '@/components/Header';
import IconButton from '@/components/IconButton';
import Screen from '@/components/Screen';
import ScreenLoader from '@/components/ScreenLoader';
import Text from '@/components/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useBooleanState} from '@/hooks/useBooleanState';
import {AddExternalWalletModal, AddPasskeyWalletModal} from '@/modules/Wallets';
import PasskeyOnboarding from '@/modules/Wallets/screens/WalletList/components/PasskeyOnboarding';
import WalletOnboarding from '@/modules/Wallets/screens/WalletList/components/WalletOnboarding';
import {useUpdateUserMetadataMutation} from '@/queries/user';
import {Passkey} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {getUserPasskeyWallet, sortUserWallets} from '@/utils/user';

import WalletCard from './components/WalletCard';
import {styles} from './WalletList.style';

const WalletList = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();

  const activeUser = useActiveUser();
  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  const {scrollPosition, onScroll} = useAnimatedHeader();
  const [isPasskeyModalOpen, openPasskeyModal, closePasskeyModal] =
    useBooleanState();
  const [
    isExternalWalletModalOpen,
    openExternalWalletModal,
    closeExternalWalletModal,
  ] = useBooleanState();

  if (!activeUser) {
    return <ScreenLoader />;
  }

  const passkeyAddress = getUserPasskeyWallet(activeUser);
  const hasExternalWallet = activeUser.addresses.find(
    a => a.isWallet && !a.isPasskey,
  );
  const wallets = sortUserWallets(activeUser);

  const {onboarding} = activeUser.metadata;

  return (
    <Screen>
      <Header
        showBack
        titleId="wallets.title"
        scrollPosition={scrollPosition}
        rightActions={
          <IconButton
            icon={{name: 'settings', provider: 'custom'}}
            onPress={() => navigation.navigate(Routes.WalletsSettings)}
          />
        }
      />
      <Animated.ScrollView
        showsVerticalScrollIndicator={false}
        onScroll={onScroll}
        // @ts-ignore
        contentContainerStyle={style.scrollContent}>
        {!passkeyAddress &&
          !onboarding.seenCreatePasskey &&
          Passkey.isSupported && (
            <PasskeyOnboarding
              onCreatePasskey={openPasskeyModal}
              onClose={() =>
                updateUserMetadataMutation.mutate({
                  user: activeUser,
                  metadataUpdate: {onboarding: {seenCreatePasskey: true}},
                })
              }
            />
          )}

        {!hasExternalWallet && !onboarding.seenWhatIsWallet && (
          <WalletOnboarding
            onClose={() =>
              updateUserMetadataMutation.mutate({
                user: activeUser,
                metadataUpdate: {onboarding: {seenWhatIsWallet: true}},
              })
            }
          />
        )}

        <View style={style.listContainer}>
          <Text weight="semibold" id="wallets.title" size="m" />

          <View style={style.list}>
            {wallets.map(a => (
              <WalletCard key={a.address} address={a} />
            ))}

            <View style={style.addWalletContainer}>
              {hasExternalWallet ? (
                <CardButton
                  onPress={openExternalWalletModal}
                  text={{id: 'wallets.addExternalWallet'}}
                />
              ) : (
                <>
                  <Text size="xs" align="center" id="wallets.web3user" />
                  <CardButton
                    onPress={openExternalWalletModal}
                    text={{id: 'wallets.connectExternalWallet'}}
                    icon={{name: 'ethereum', provider: 'custom'}}
                  />
                </>
              )}
            </View>

            {!passkeyAddress && onboarding.seenCreatePasskey && (
              <CardButton
                onPress={openPasskeyModal}
                text={{id: 'wallets.addPasskeyWallet'}}
              />
            )}
          </View>
        </View>
      </Animated.ScrollView>

      {isPasskeyModalOpen && (
        <AddPasskeyWalletModal isOpen onClose={closePasskeyModal} />
      )}
      {isExternalWalletModalOpen && (
        <AddExternalWalletModal isOpen onClose={closeExternalWalletModal} />
      )}
    </Screen>
  );
};

export default WalletList;
