import React, {forwardRef} from 'react';
import {TextInput, View} from 'react-native';

import Icon from '@/components/Icon';
import Input, {IInputProps} from '@/components/Input';
import Text from '@/components/Text';
import UsdPrice from '@/components/UsdPrice';
import {getChainById} from '@/constants/chains';
import {useThemedStyles} from '@/theme';
import {IBalance} from '@/types/common';
import {formatPrice} from '@/utils/ethereum';
import {parseDecimalInput} from '@/utils/form';

import {styles} from './PriceInput.style';

export interface IPriceInputProps extends Omit<IInputProps, 'onChange'> {
  value: string;
  onChange: (value: string) => void;
  chainId: number;
  balance?: IBalance;
  showUsd?: boolean;
}

const PriceInput = forwardRef<TextInput, IPriceInputProps>(
  (
    {
      value,
      onChange,
      chainId,
      balance,
      placeholder = '0',
      style: customStyle,
      inputStyle: customInputStyle,
      showUsd = false,
      ...inputProps
    },
    ref,
  ) => {
    const style = useThemedStyles(styles);

    return (
      <Input
        ref={ref}
        value={value}
        onChangeText={v => onChange(parseDecimalInput(v, value))}
        placeholder={placeholder}
        style={customStyle}
        inputStyle={[style.priceInput, customInputStyle]}
        inputMode="decimal"
        prefixComponent={
          <View style={style.priceCurrency}>
            <Icon
              provider="custom"
              name={`chain_${getChainById(chainId).code}`}
            />
            <View>
              <Text weight="semibold">
                {getChainById(chainId).chain.nativeCurrency.symbol}
              </Text>
              {balance && (
                <Text
                  size="xs"
                  id="transfer.balance"
                  values={{
                    amount: formatPrice(balance.value, {
                      decimals: balance.decimals,
                      symbol: balance.symbol,
                    }),
                  }}
                />
              )}
            </View>
          </View>
        }
        footerComponent={
          showUsd && (
            <UsdPrice
              eth={value}
              chainId={chainId}
              size="xs"
              secondary
              align="right"
            />
          )
        }
        {...inputProps}
      />
    );
  },
);

export default PriceInput;
