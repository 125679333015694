import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles((theme, responsive) => ({
  container: {
    flex: 1,
    minHeight: responsive.isMobile ? 0 : 100,
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs,
  },
  links: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.m,
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
}));
