import React, {FC, ReactNode} from 'react';
import {Pressable, StyleProp, View} from 'react-native';
import Animated, {
  FadeOut,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import Icon from '@/components/Icon';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles, ARROW_SIZE} from './OnboardingPopover.style';

interface IProps {
  onClose: () => void;
  children: ReactNode;
  position: 'top' | 'bottom';
  align: 'left' | 'center' | 'right';
  arrowOffset?: number;
  style?: StyleProp<any>;
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const OnboardingPopover = ({
  onClose,
  children,
  position,
  align,
  arrowOffset = spacing.s * 2,
  style: customStyle,
}: IProps) => {
  const style = useThemedStyles(styles);

  const popoverHeight = useSharedValue(0);

  const popoverStyle = useAnimatedStyle(
    () => ({
      opacity: popoverHeight.value ? withTiming(1) : 0,
      ...(position === 'top' && {top: -popoverHeight.value}),
      ...(position === 'bottom' && {
        bottom: -popoverHeight.value,
      }),
    }),
    [position],
  );

  return (
    <AnimatedPressable
      onPress={onClose}
      exiting={isWeb ? undefined : FadeOut}
      onLayout={event => {
        popoverHeight.value = event.nativeEvent.layout.height;
      }}
      style={[style.popover, popoverStyle, {[align]: 0}, customStyle]}>
      {children}
      <View
        style={[
          style.arrow,
          align === 'left' && {left: arrowOffset},
          align === 'right' && {right: arrowOffset},
          position === 'top' && {bottom: -spacing.xs * 1.2},
          position === 'bottom' && {
            top: -spacing.xs * 1.2,
            transform: [{rotate: '180deg'}],
          },
        ]}>
        <Icon
          name="tooltipArrow"
          provider="custom"
          color="backgroundLight"
          size={ARROW_SIZE}
        />
      </View>
    </AnimatedPressable>
  );
};

const Play: FC = () => {
  return (
    <>
      <Text
        id="feed.onboarding.play"
        values={{
          action: <Text id="feed.onboarding.tap" weight="semibold" />,
        }}
      />
      <Icon provider="custom" name="arrowDownLong" size={18} />
    </>
  );
};

const AddToQueue: FC = () => {
  return (
    <>
      <Text
        id="feed.onboarding.addToQueue"
        values={{
          action: <Text id="feed.onboarding.oneTap" weight="semibold" />,
        }}
      />
      <Text
        id="feed.onboarding.moreActions"
        values={{
          action: <Text id="feed.onboarding.longPress" weight="semibold" />,
        }}
      />
    </>
  );
};

const Discard: FC = () => {
  return (
    <Text
      id="feed.onboarding.discard"
      values={{
        action: <Text id="feed.onboarding.tap" weight="semibold" />,
      }}
    />
  );
};

const Follow: FC = () => {
  return (
    <View style={{alignItems: 'center', gap: spacing.xxs}}>
      <Text id="feed.onboarding.follow.title" weight="semibold" />
      <Text id="feed.onboarding.follow.description" />
    </View>
  );
};

const Channels: FC = () => {
  return (
    <>
      <View style={{alignItems: 'center', gap: spacing.xxs}}>
        <Text id="feed.onboarding.channels.title" weight="semibold" />
        <Text id="feed.onboarding.channels.description" />
      </View>
      <View style={{alignItems: 'center', gap: spacing.xxs}}>
        <Text id="feed.onboarding.spy.title" weight="semibold" />
        <Text id="feed.onboarding.spy.description" />
      </View>
    </>
  );
};

OnboardingPopover.Play = Play;
OnboardingPopover.AddToQueue = AddToQueue;
OnboardingPopover.Discard = Discard;
OnboardingPopover.Follow = Follow;
OnboardingPopover.Channels = Channels;

export default OnboardingPopover;
