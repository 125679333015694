import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  listContainer: {
    flexGrow: 1,
    padding: spacing.s,
    paddingTop: spacing.xxs,
  },
  header: {
    flexDirection: 'row',
    gap: spacing.m,
  },
  coverArtCard: {
    width: '33%',
    aspectRatio: 1,
    overflow: 'hidden',
    borderBottomWidth: theme.box.baseBorderWidth,
    borderRightWidth: theme.box.baseBorderWidth,
  },
  coverArt: {
    ...StyleSheet.absoluteFillObject,
  },
  headerInfo: {
    paddingVertical: spacing.xxs / 2,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.xs,
  },
  collectButton: {
    backgroundColor: theme.colors.background,
    paddingHorizontal: spacing.s,
    height: spacing.xl,
    borderRadius: spacing.xl / 2,
    overflow: 'hidden',
  },
  detailsContainer: {
    marginTop: spacing.s * 2,
    gap: spacing.s * 2,
  },
  releaseInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.xs,
    paddingVertical: spacing.xs,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: theme.colors.backgroundDark,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filtersContainer: {
    marginHorizontal: -spacing.s,
  },
  filters: {
    paddingTop: spacing.xs,
    paddingBottom: spacing.s,
  },
}));
