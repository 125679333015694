import React from 'react';

export const formatDuration = (_seconds: number) => {
  const seconds = Math.abs(_seconds);
  const longerThenOneHour = Math.floor(seconds / 3600) > 0;
  const startingIndex = longerThenOneHour ? 11 : 14;
  return new Date(seconds * 1000).toISOString().slice(startingIndex, 19);
};

export const formatDate = (inputDate: string | number): string => {
  const date = new Date(inputDate);
  return date.toLocaleDateString();
};

export const formatDateTime = (inputDate: string | number): string => {
  const date = new Date(inputDate);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const getFormattedTimeToRelease = (_date: string) => {
  const date = new Date(_date);

  if (date <= new Date()) {
    return undefined;
  }

  const distance = Math.max(0, date.getTime() - new Date().getTime());
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));

  let result = '';

  if (days > 0) {
    result += `${days}d `;
  }

  if (hours > 0) {
    result += `${hours}h `;
  }

  if (minutes > 0) {
    result += `${minutes}m`;
  }

  if (result === '') {
    return undefined;
  }

  return result;
};
