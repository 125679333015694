import React, {FC} from 'react';
import {View} from 'react-native';

import FlatCard from '@/components/FlatCard';
import Text from '@/components/Text';
import {TransferStep} from '@/modules/Transfer';
import {WalletCard} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';
import {prettifyAddress} from '@/utils/ethereum';

import {styles} from './DeliveryWalletInfo.style';

interface IProps {
  transferStep: TransferStep;
  deliveryWallet: IAddress | undefined;
  deliveryWalletAddress: string | undefined;
  onChangeWallet: () => void;
}

const DeliveryWalletInfo: FC<IProps> = ({
  transferStep,
  deliveryWallet,
  deliveryWalletAddress,
  onChangeWallet,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View>
      <Text style={style.sectionTitle} id="transfer.receivingWallet" />
      {deliveryWallet ? (
        <WalletCard
          wallet={deliveryWallet}
          onPress={onChangeWallet}
          disabled={transferStep !== 'checkout'}
          suffix={
            transferStep === 'checkout' ? (
              <Text
                style={style.deliveryWalletCardAction}
                id="transfer.receivingWallet.change"
                weight="semibold"
                size="xs"
              />
            ) : null
          }
        />
      ) : deliveryWalletAddress ? (
        <FlatCard
          style={style.customWalletCard}
          onPress={onChangeWallet}
          disabled={transferStep !== 'checkout'}>
          <Text size="xs">{prettifyAddress(deliveryWalletAddress)}</Text>
          {transferStep === 'checkout' && (
            <Text
              style={style.deliveryWalletCardAction}
              id="transfer.receivingWallet.change"
              weight="semibold"
              size="xs"
            />
          )}
        </FlatCard>
      ) : (
        <FlatCard onPress={onChangeWallet} style={style.selectWalletCard}>
          <Text id="transfer.selectReceivingWallet" weight="semibold" />
        </FlatCard>
      )}
    </View>
  );
};

export default DeliveryWalletInfo;
