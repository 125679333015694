import {createThemedStyles} from '@/theme/createThemedStyles';

export const styles = createThemedStyles(theme => ({
  container: {
    backgroundColor: theme.colors.background,
    borderRadius: theme.box.borderRadius,
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: theme.colors.primary,
  },
}));
