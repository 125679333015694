import {RouteProp, useRoute} from '@react-navigation/native';
import React, {FC, useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {parseEther} from 'viem';

import CardButton from '@/components/CardButton';
import DashedDivider from '@/components/DashedDivider';
import Divider from '@/components/Divider';
import FlatCard from '@/components/FlatCard';
import Icon from '@/components/Icon';
import PriceInput from '@/components/PriceInput';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import Text from '@/components/Text';
import {useLogin} from '@/modules/Login';
import {suggestedAmounts, tipChain} from '@/modules/Tip/constants';
import TipAvatars from '@/modules/Tip/SendTip/components/TipAvatars';
import {useSendTip} from '@/modules/Tip/SendTip/useSendTip';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';
import {canTipArtist} from '@/modules/Tip/utils';
import {
  PriceSummary,
  useBalanceValidation,
  useChainValidation,
  useIsWalletConnectedValidation,
  useTransactionValidation,
} from '@/modules/Transactions';
import {WalletPicker, WalletWithAddress} from '@/modules/Wallets';
import {useExchangeRatesQuery} from '@/queries/crypto';
import {useThemedStyles} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';
import {formatPrice, usdToEth} from '@/utils/ethereum';
import {isNotNil} from '@/utils/functions';

import {styles} from './TipCheckout.style';

const TipCheckout: FC = () => {
  const style = useThemedStyles(styles);
  const {
    params: {source},
  } = useRoute<RouteProp<RootStackParams, Routes.SendTip>>();

  const {openLoginModal} = useLogin();
  const {
    artist,
    user,
    tipTransaction,
    updateTip,
    paymentWallet,
    balance,
    gasEstimation,
  } = useSendTipState();
  const [isWalletPickerOpen, setIsWalletPickerOpen] = useState(false);

  const {sendTip} = useSendTip();

  const {exchangeRates} = useExchangeRatesQuery();

  const parsedAmount = parseEther(tipTransaction.amount);
  const totalPrice = parsedAmount + (gasEstimation.totalGas || BigInt(0));

  const ConnectedWalletError = useIsWalletConnectedValidation({
    wallet: paymentWallet,
  });
  const WrongChainError = useChainValidation({
    wallet: paymentWallet,
    chainId: tipChain.id,
  });
  const InsufficientBalanceError = useBalanceValidation({
    wallet: paymentWallet,
    balance,
    price: totalPrice,
    chainId: tipChain.id,
  });
  const InvalidTransactionError = useTransactionValidation(gasEstimation);

  const validationError = [
    ConnectedWalletError,
    WrongChainError,
    InsufficientBalanceError,
    InvalidTransactionError,
  ].filter(isNotNil)[0];

  return (
    <View style={style.container}>
      <ScrollContainer contentStyle={style.content}>
        <View style={style.avatars}>
          <TipAvatars artist={artist} user={user} />
        </View>
        <Text
          weight="semibold"
          align="center"
          size="l"
          id="tip.checkout.header"
        />
        <Text
          size="xs"
          align="center"
          id={
            source === 'collect'
              ? 'tip.checkout.description.collect'
              : 'tip.checkout.description'
          }
        />

        <DashedDivider style={style.divider} />

        <View style={style.amountContainer}>
          <Text id="tip.checkout.amount" />
          <PriceInput
            value={tipTransaction.amount}
            onChange={amount => updateTip({amount})}
            chainId={tipChain.id}
            editable={tipTransaction.step === 'checkout'}
            showUsd
          />
          {exchangeRates && (
            <View style={style.amountShortcuts}>
              {suggestedAmounts.map(usdAmount => {
                return (
                  <FlatCard
                    key={usdAmount}
                    onPress={() =>
                      updateTip({
                        amount: usdToEth(
                          usdAmount,
                          tipChain.id,
                          exchangeRates,
                          6,
                        ),
                      })
                    }
                    style={style.amountShortcut}>
                    <Text weight="semibold" size="xxs" align="center">
                      {usdToEth(usdAmount, tipChain.id, exchangeRates, 4)}
                    </Text>
                    {exchangeRates && (
                      <Text size="xxs" secondary>
                        {usdAmount} $
                      </Text>
                    )}
                  </FlatCard>
                );
              })}
            </View>
          )}

          <Divider style={style.amountDivider} />

          <TouchableOpacity
            style={style.paymentMethodContainer}
            activeOpacity={0.8}
            onPress={() => setIsWalletPickerOpen(true)}>
            <View style={style.paymentMethodInfo}>
              <View style={style.paymentMethodRow}>
                <Text size="xs" id="tip.checkout.paymentMethod" />
                {paymentWallet && <WalletWithAddress wallet={paymentWallet} />}
              </View>
              {balance && (
                <View style={style.balance}>
                  <Text size="xs">
                    {formatPrice(balance.value, {
                      decimals: balance.decimals,
                      symbol: balance.symbol,
                    })}
                  </Text>
                </View>
              )}
            </View>
            <Icon provider="custom" name="arrowRight" />
          </TouchableOpacity>
        </View>
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <PriceSummary
          priceLabelId="tip.checkout.priceLabel"
          price={parsedAmount}
          gasEstimation={gasEstimation}
          chainId={tipChain.id}
        />

        {validationError?.message && (
          <View style={style.validationError}>{validationError.message}</View>
        )}

        {!user ? (
          <CardButton
            onPress={openLoginModal}
            text={{id: 'tip.checkout.loginToTip'}}
          />
        ) : validationError ? (
          validationError.action
        ) : (
          <CardButton
            disabled={
              parsedAmount === BigInt(0) ||
              !paymentWallet ||
              !canTipArtist(artist) ||
              gasEstimation.isFetching ||
              !gasEstimation.totalGas
            }
            isLoading={tipTransaction.step !== 'checkout'}
            text={{id: 'tip.checkout.submit'}}
            loaderProps={{textId: 'processing'}}
            onPress={() => {
              if (paymentWallet) {
                sendTip(paymentWallet);
              }
            }}
          />
        )}
      </ShadowFooter>

      {isWalletPickerOpen && user && (
        <WalletPicker
          isOpen
          titleId="tip.checkout.paymentMethod"
          user={user}
          selectedWalletAddress={paymentWallet?.address}
          onConfirm={address => updateTip({paymentWalletAddress: address})}
          chainId={tipChain.id}
          onClose={() => setIsWalletPickerOpen(false)}
          allowAddPasskeyWallet
          allowAddExternalWallet
        />
      )}
    </View>
  );
};

export default TipCheckout;
