import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {TouchableOpacity} from 'react-native';

import Address from '@/components/Address';
import Text, {TextProps} from '@/components/Text';
import {useUserLink} from '@/hooks/useUserLink';
import {IPlaylist} from '@/types/playlists';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {IUser} from '@/types/user';
import {getUserDisplayName} from '@/utils/user';

interface IProps {
  playlist: IPlaylist;
  user?: IUser | null;
  textProps?: TextProps;
}

const PlaylistCreator: FC<IProps> = ({playlist, user, textProps = {}}) => {
  const navigation = useNavigation<MainStackNavigationParams>();

  const {navigateToUser} = useUserLink();

  if (user?.artistProfile) {
    return (
      <TouchableOpacity
        onPress={() => {
          navigation.push(Routes.Artist, {
            slugOrId: user.artistProfile!.slug,
          });
        }}>
        <Text underline {...textProps}>
          {user.artistProfile.name}
        </Text>
      </TouchableOpacity>
    );
  }

  if (user) {
    return (
      <TouchableOpacity onPress={() => navigateToUser(user)}>
        <Text underline {...textProps}>
          {getUserDisplayName(user)}
        </Text>
      </TouchableOpacity>
    );
  }

  if (playlist.collector) {
    return <Address address={playlist.collector} linkEnabled {...textProps} />;
  }

  return <Text id="playlist.anonymous" {...textProps} />;
};

export default PlaylistCreator;
