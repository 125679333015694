import React from 'react';
import {View} from 'react-native';

import {usePlayerProgress} from '@/hooks/usePlayerProgress';
import {useThemedStyles} from '@/theme';
import {getPercentageProgress} from '@/utils/player';

import {styles} from './PlayerBar.style';

export default () => {
  const style = useThemedStyles(styles);
  const {position, duration} = usePlayerProgress();
  const progress = getPercentageProgress(position, duration);

  return (
    <View style={style.progressWrapper}>
      <View style={[style.progress, {width: `${progress}%`}]} />
    </View>
  );
};
