import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  collectButton: {
    paddingHorizontal: 0,
  },
  imageContainer: {
    height: '100%',
    flexShrink: 1,
    overflow: 'hidden',
  },
  image: {
    maxHeight: '100%',
    flexGrow: 1,
    overflow: 'hidden',
  },
  imagePlaceholder: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.backgroundDark,
  },
  bufferingIndicator: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoContainer: {
    flexGrow: 1,
    padding: spacing.xs,
    alignItems: 'flex-start',
    borderTopWidth: 0,
    borderColor: theme.colors.borderColor,
  },
  title: {
    marginBottom: spacing.xs,
    overflow: 'hidden',
    flexDirection: 'row',
  },
  subtitle: {
    maxWidth: '100%',
    flexDirection: 'row',
  },
  artistJoiner: {
    marginHorizontal: spacing.xxs,
  },
  actionsWrapper: {
    alignSelf: 'stretch',
  },
}));
