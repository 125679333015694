import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  buttonContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContent_loading: {
    opacity: 0,
  },
  loader: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
