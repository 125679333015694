import React, {FC, useCallback, useRef} from 'react';

import {IPreventPressOnSwipeProps} from './types';

const MAX_ALLOWED_DISTANCE = 10;

const PreventPressOnSwipe: FC<IPreventPressOnSwipeProps> = ({
  children,
  style,
}) => {
  const pressInPagePointRef = useRef({x: 0, y: 0});
  const isPressCancelled = useRef(false);

  const onPressIn = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
    pressInPagePointRef.current = {
      x: event.nativeEvent.pageX,
      y: event.nativeEvent.pageY,
    };
    isPressCancelled.current = false;
  }, []);

  const onPressOut = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const [x, y] = [event.nativeEvent.pageX, event.nativeEvent.pageY];

      const hasMovedTooMuch =
        Math.abs(pressInPagePointRef.current.x - x) > MAX_ALLOWED_DISTANCE ||
        Math.abs(pressInPagePointRef.current.y - y) > MAX_ALLOWED_DISTANCE;

      if (hasMovedTooMuch) {
        isPressCancelled.current = true;
      }
    },
    [],
  );

  const onPress = useCallback((event: React.PointerEvent<HTMLDivElement>) => {
    if (isPressCancelled.current) {
      event.stopPropagation();
      event.preventDefault();
      isPressCancelled.current = false;
      return;
    }
  }, []);

  return (
    <div
      style={style}
      onPointerDown={onPressIn}
      onPointerUp={onPressOut}
      onClickCapture={onPress}>
      {children}
    </div>
  );
};

export default PreventPressOnSwipe;
