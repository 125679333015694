import {Dimensions} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    width: Math.min(280, Dimensions.get('window').width - spacing.m),
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,

    shadowColor: theme.colors.textColor,
    shadowOffset: {width: 2, height: 2},
    shadowOpacity: 0.2,
    shadowRadius: 20,
  },
}));
