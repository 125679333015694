import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Text, {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './WarningBox.style';

interface IProps {
  icon?: IIconProps;
  title?: TextProps;
  message?: TextProps;
  action?: ReactNode;
}

const WarningBox: FC<IProps> = ({
  icon = {provider: 'custom', name: 'warning'},
  title,
  message,
  action,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <View style={style.titleRow}>
        <Icon {...icon} />
        {title && <Text weight="semibold" {...title} />}
      </View>

      {message && <Text size="xs" align="center" {...message} />}

      {action}
    </View>
  );
};

export default WarningBox;
