import React, {FC, useState} from 'react';
import {View} from 'react-native';
import Animated from 'react-native-reanimated';

import Divider from '@/components/Divider';
import Header from '@/components/Header';
import Icon from '@/components/Icon';
import Screen from '@/components/Screen';
import Text from '@/components/Text';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {WalletPicker, WalletCard} from '@/modules/Wallets';
import {useUpdateUserMetadataMutation} from '@/queries/user';
import {useThemedStyles} from '@/theme';
import {getUserDeliveryWallet, getUserPaymentWallet} from '@/utils/user';

import {styles} from './WalletsSettings.style';

type PickerModal = 'payment' | 'delivery';

const WalletsSettings: FC = () => {
  const style = useThemedStyles(styles);
  const {onScroll, scrollPosition} = useAnimatedHeader();
  const user = useActiveUser();

  const [openedModal, setOpenedModal] = useState<PickerModal>();

  const paymentWallet = user && getUserPaymentWallet(user);
  const deliveryWallet = user && getUserDeliveryWallet(user);

  const updateUserMetadataMutation = useUpdateUserMetadataMutation();

  return (
    <>
      <Screen>
        <Header
          showBack
          mockTitle
          titleId="walletsSettings.title"
          scrollPosition={scrollPosition}
        />
        {/* @ts-ignore */}
        <Animated.ScrollView
          onScroll={onScroll}
          showsVerticalScrollIndicator={false}
          style={style.scrollContainer}
          contentContainerStyle={style.scrollContent}>
          <View style={style.section}>
            <Text weight="semibold" id="walletsSettings.defaultPayment" />
            {paymentWallet && (
              <WalletCard
                wallet={paymentWallet}
                onPress={() => setOpenedModal('payment')}
                suffix={<Icon provider="custom" name="arrowRight" />}
                isSelected
              />
            )}
          </View>

          <Divider style={style.divider} />

          <View style={style.section}>
            <Text weight="semibold" id="walletsSettings.defaultDelivery" />
            {deliveryWallet && (
              <WalletCard
                wallet={deliveryWallet}
                onPress={() => setOpenedModal('delivery')}
                suffix={<Icon provider="custom" name="arrowRight" />}
                isSelected
              />
            )}
          </View>
        </Animated.ScrollView>
      </Screen>

      {user && openedModal === 'payment' && (
        <WalletPicker
          titleId="walletsSettings.defaultPayment"
          user={user}
          selectedWalletAddress={paymentWallet?.address}
          onConfirm={address =>
            updateUserMetadataMutation.mutate({
              user,
              metadataUpdate: {defaultPaymentWallet: address},
            })
          }
          isOpen
          onClose={() => setOpenedModal(undefined)}
        />
      )}

      {user && openedModal === 'delivery' && (
        <WalletPicker
          titleId="walletsSettings.defaultDelivery"
          user={user}
          selectedWalletAddress={deliveryWallet?.address}
          onConfirm={address =>
            updateUserMetadataMutation.mutate({
              user,
              metadataUpdate: {defaultDeliveryWallet: address},
            })
          }
          isOpen
          onClose={() => setOpenedModal(undefined)}
        />
      )}
    </>
  );
};

export default WalletsSettings;
