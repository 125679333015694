import spacing from '@/constants/spacing';

export const SliderDefaults = {
  gap: spacing.xs * 1.5,
  verticalGap: spacing.xs,
  padding: spacing.m,
  offset: spacing.s,
  visibleColumns: 1,
  rows: 1,
};

export const getSliderPageWidth = (config: {
  containerWidth: number;
  padding: number;
  offset: number;
  gap: number;
  visibleColumns: number;
}) => {
  'worklet';
  const {containerWidth, padding, offset, gap, visibleColumns} = config;

  return (
    (containerWidth - padding * 2 - offset - (visibleColumns - 1) * gap) /
    visibleColumns
  );
};

export const getNextPageOffset = (
  scrollPosition: number,
  pageWidth: number,
  gap: number,
  pagesCount: number,
  visibleColumns: number,
) => {
  const page = Math.round(scrollPosition / (pageWidth + gap));
  const nextPage = page + 1;

  if (nextPage > pagesCount - visibleColumns) {
    return 0;
  }

  return nextPage * (pageWidth + gap);
};
