import React, {FC} from 'react';
import {View} from 'react-native';

import {calculateBackgroundColor, useTheme} from '@/theme';
import {Color} from '@/types/theme';

interface IProps {
  color?: Color;
  height?: number;
}

const OverScrollMask: FC<IProps> = ({
  color = 'backgroundDark',
  height = 500,
}) => {
  const theme = useTheme();
  const backgroundColor = calculateBackgroundColor(theme, color);

  return (
    <View
      style={{
        position: 'absolute',
        height: height,
        top: -height,
        left: 0,
        right: 0,
        backgroundColor,
        zIndex: 0,
      }}
    />
  );
};

export default OverScrollMask;
