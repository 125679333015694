import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    flex: 1,
  },
  content: {
    paddingVertical: spacing.s * 2,
    gap: spacing.l,
  },
  avatars: {
    alignSelf: 'center',
  },
  divider: {
    marginVertical: spacing.xs,
  },
  amountContainer: {
    gap: spacing.xs,
  },
  amountShortcuts: {
    flexDirection: 'row',
    gap: spacing.xs,
  },
  amountShortcut: {
    flex: 1,
    height: spacing.xl,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.borderColor,
    gap: spacing.xxs / 2,
  },
  amountDivider: {
    marginVertical: spacing.xs * 1.5,
  },
  paymentMethodContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  paymentMethodInfo: {
    flex: 1,
    gap: spacing.xxs,
  },
  paymentMethodRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: spacing.xs,
  },
  balance: {
    alignSelf: 'flex-end',
  },
  footer: {
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.xs * 1.5,
    gap: spacing.s,
  },
  validationError: {
    marginVertical: spacing.xs,
  },
}));
