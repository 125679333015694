import spacing from '@/constants/spacing';
import {FEED_CARD_HEIGHT} from '@/screens/Feed/components/FeedCard/BaseFeedCard.styles';
import {createThemedStyles} from '@/theme';

export const PADDING_TOP = spacing.xl;
export const PADDING_BOTTOM = spacing.s;

export const CAROUSEL_HEIGHT = FEED_CARD_HEIGHT + PADDING_TOP + PADDING_BOTTOM;

export const styles = createThemedStyles(() => ({
  carousel: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: PADDING_TOP,
    paddingBottom: PADDING_BOTTOM,
  },
}));
