import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const ARTIST_CARD_HEIGHT = 32;

export const styles = createThemedStyles(() => ({
  scrollContainer: {
    flex: 1,
  },
  scrollContent: {
    padding: spacing.s,
    flexGrow: 1,
  },
  optionContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionGroup: {
    marginBottom: spacing.s,
  },
  searchBox: {
    marginVertical: spacing.xs,
  },
  artistRow: {
    height: ARTIST_CARD_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  imageContainer: {
    height: '100%',
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageWrapper: {
    height: '100%',
    width: '100%',
    borderRadius: ARTIST_CARD_HEIGHT / 2,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  },
  artistInfo: {
    flexShrink: 1,
    gap: spacing.xs,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
