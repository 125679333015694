import React, {ReactNode, useState} from 'react';
import {Keyboard, ScrollView, TouchableOpacity, View} from 'react-native';

import Collapse from '@/components/Collapse';
import Icon from '@/components/Icon/Icon';
import RotatedView from '@/components/RotatedView';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles} from './Select.style';

interface IProps<T> {
  value: T | null | undefined;
  options: {
    prefixComponent?: ReactNode;
    label?: string;
    labelId?: string;
    value: T;
  }[];
  setValue: (value: T) => void;
  maxHeight?: number;
}

function Select<T extends string | number>({
  value,
  options,
  setValue,
  maxHeight = 100,
}: IProps<T>) {
  const style = useThemedStyles(styles);
  const [isOpen, setIsOpen] = useState(false);

  const selectedOption = options.find(option => option.value === value);

  return (
    <View style={style.selectContainer}>
      <TouchableOpacity
        activeOpacity={0.8}
        style={style.selectOpener}
        onPress={() => {
          setIsOpen(v => !v);
          if (!isOpen) {
            Keyboard.dismiss();
          }
        }}>
        {selectedOption?.prefixComponent && (
          <Space mr="xs">{selectedOption.prefixComponent}</Space>
        )}
        <Text id={selectedOption?.labelId} weight="semibold">
          {selectedOption?.label}
        </Text>
        <Space flex />
        <RotatedView isRotated={isOpen} angle={180} duration={100}>
          <Icon name="arrow-down" size={14} />
        </RotatedView>
      </TouchableOpacity>
      <Collapse isOpen={isOpen}>
        <ScrollView
          style={[style.optionsContainer, {maxHeight}]}
          showsVerticalScrollIndicator={!isWeb}
          bounces={false}>
          {options.map(option => (
            <TouchableOpacity
              key={option.value}
              style={style.option}
              onPress={() => {
                setValue(option.value);
                setIsOpen(false);
              }}>
              {option?.prefixComponent && (
                <Space mr="xs">{option.prefixComponent}</Space>
              )}
              <Text
                size="xs"
                id={option.labelId}
                weight={option.value === value ? 'semibold' : 'regular'}>
                {option.label}
              </Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Collapse>
    </View>
  );
}

export default Select;
