import {http} from 'viem';
import {createStorage} from 'wagmi';
import {base, mainnet, optimism, polygon, sepolia, zora} from 'wagmi/chains';

import {getChainById} from '@/constants/chains';
import {config} from '@/constants/config';
import {storage} from '@/services/storage';
const metadata = {
  name: config.APP_NAME,
  url: config.WEB_PAGE_URL,
  description: 'Spinamp',
  icons: ['https://app.spinam.xyz/spin-logo.png'],
};

const projectId = config.WALLETCONNECT_PROJECT_ID;

const wagmiStorage = createStorage({
  storage,
});

const chains = [mainnet, polygon, optimism, base, zora, sepolia] as const;

// https://explorer.walletconnect.com/
const supportedWalletIds = [
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // Metamask
  'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // Coinbase
  '1ae92b26df02f0abca6304df07debccd18262fdf5fe82daa81593582dac9a369', // Rainbow
  'ecc4036f814562b41a5268adc86270fba1365471402006302e70169465b7ac18', // Zerion
  '225affb176778569276e484e1b92637ad061b01e13a048b35a9d280c3b58970f', // Safe
  '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // Trust
  'c03dfee351b6fcc421b4494ea33b9d4b92a984f87aa76d1663bb28705e95034a', // Uniswap
];

const transports = {
  [mainnet.id]: http(getChainById(mainnet.id).rpcUrl),
  [polygon.id]: http(getChainById(polygon.id).rpcUrl),
  [optimism.id]: http(getChainById(optimism.id).rpcUrl),
  [base.id]: http(getChainById(base.id).rpcUrl),
  [zora.id]: http(getChainById(zora.id).rpcUrl),
  [sepolia.id]: http(getChainById(sepolia.id).rpcUrl),
};

export {
  metadata,
  chains,
  projectId,
  transports,
  supportedWalletIds,
  wagmiStorage,
};
