import {createSelector} from '@reduxjs/toolkit';

import {TransactionType} from '@/modules/Transactions/types';
import {RootState} from '@/store';

export const selectTransactions = (state: RootState) => state.transactions;

export const selectTransactionById = createSelector(
  [
    selectTransactions,
    (state: any, id: string | undefined) => id,
    (state: any, id: string | undefined, type: TransactionType) => type,
  ],
  (transactions, id, type) => {
    if (id && transactions[id] && transactions[id].type === type) {
      return transactions[id];
    }

    return undefined;
  },
);

export const selectTransactionsList = createSelector(
  selectTransactions,
  transactions =>
    Object.values(transactions).sort((a, b) => (b.createdDate = a.createdDate)),
);

export const selectMinimizedTransactions = createSelector(
  selectTransactionsList,
  transactions => transactions.filter(t => t.isMinimized),
);
