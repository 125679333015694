import React, {FC} from 'react';

import {UserCard} from '@/components/AvatarCard';
import {IIconProps} from '@/components/Icon';
import {IPlaylistWithFollowedUser} from '@/screens/Library/types';
import {IPlaylist} from '@/types/playlists';

export interface IFollowedUserCardProps {
  playlist: IPlaylistWithFollowedUser;
  onUnfollow: (playlist: IPlaylist) => void;
}

const actionIcon: IIconProps = {
  provider: 'fontAwesome',
  name: 'heart',
  color: 'favoritesColor',
  size: 14,
};

const FollowedUserCard: FC<IFollowedUserCardProps> = ({
  playlist,
  onUnfollow,
}) => (
  <UserCard
    user={playlist.followedUser}
    action={() => onUnfollow(playlist)}
    actionIcon={actionIcon}
  />
);

export default React.memo(FollowedUserCard);
