import React, {FC} from 'react';

import Button from '@/components/Button';
import Modal, {IModalProps} from '@/components/Modal/Modal';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './ConfirmationModal.style';

interface IProps extends IModalProps {
  descriptionId: string;
  confirmTextId?: string;
  cancelTextId?: string;
  onConfirm: () => void;
}

const ConfirmationModal: FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  titleId,
  descriptionId,
  confirmTextId,
  cancelTextId,
  disableClickOutside,
}) => {
  const style = useThemedStyles(styles);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      titleId={titleId}
      onClose={onClose}
      disableClickOutside={disableClickOutside}>
      <Space p="s">
        <Text id={descriptionId} />

        <Space style={style.actions} mt="s">
          <Button
            onPress={onClose}
            textId={cancelTextId || 'no'}
            style={style.action}
          />
          <Space ml="xs" />
          <Button
            onPress={onConfirm}
            textId={confirmTextId || 'yes'}
            style={style.action}
          />
        </Space>
      </Space>
    </Modal>
  );
};

export default ConfirmationModal;
export type {IProps as IConfirmationModalProps};
