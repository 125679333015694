import React, {FC, useRef, useState} from 'react';
import {StyleProp} from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';

import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useAppSelector} from '@/hooks/useRedux';
import {selectQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';

import {styles} from './QueueIndicator.style';

interface IProps {
  horizontal?: boolean;
  rippleStyle?: StyleProp<any>;
  numberStyle?: StyleProp<any>;
}

const QueueIndicator: FC<IProps> = ({
  horizontal = false,
  rippleStyle,
  numberStyle,
}) => {
  const style = useThemedStyles(styles);

  const animationStep = useSharedValue(0);

  const queue = useAppSelector(selectQueue);
  const prevQueue = useRef(queue);
  const [addedTracksCount, setAddedTracksCount] = useState(0);

  if (queue && prevQueue.current) {
    const diff = queue.items.length - prevQueue.current.items.length;
    const isSameContext =
      queue.context.type === prevQueue.current.context.type &&
      queue.context.id === prevQueue.current.context.id;

    if (isSameContext && diff > 0) {
      setAddedTracksCount(diff);

      animationStep.value = withTiming(1, {duration: 300}, () => {
        animationStep.value = withDelay(
          500,
          withTiming(2, {duration: 300}, () => {
            animationStep.value = 0;
          }),
        );
      });
    }
  }

  if (queue !== prevQueue.current) {
    prevQueue.current = queue;
  }

  const opacityAnimation = useAnimatedStyle(() => ({
    opacity: interpolate(animationStep.value, [0, 1, 2], [0, 1, 0]),
  }));

  const numberAnimation = useAnimatedStyle(() => {
    if (horizontal) {
      return {
        transform: [
          {
            translateX: interpolate(
              animationStep.value,
              [0, 1, 2],
              [0, spacing.l, spacing.l * 3],
            ),
          },
        ],
      };
    }

    return {
      transform: [
        {
          translateY: interpolate(
            animationStep.value,
            [0, 1, 2],
            [0, -spacing.l, -spacing.l * 3],
          ),
        },
      ],
    };
  }, [horizontal]);

  return (
    <Animated.View style={style.container}>
      <Animated.View style={[style.ripple, rippleStyle, opacityAnimation]} />
      <Animated.View
        style={[style.number, numberStyle, numberAnimation, opacityAnimation]}>
        <Text size="s" weight="semibold">
          +{addedTracksCount}
        </Text>
      </Animated.View>
    </Animated.View>
  );
};

export default QueueIndicator;
