import {createThemedStyles} from '@/theme';
import {isIOS} from '@/utils/platform';

export const styles = createThemedStyles(() => ({
  container: {
    // for some reason image is moved to the top a little on iOS - this is workaround
    transform: isIOS ? [{translateY: 2}] : undefined,
    alignItems: 'flex-start',
  },
  secondaryImage: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  secondaryImage_hovered: {
    zIndex: 1,
  },
  artwork: {
    overflow: 'hidden',
  },
}));
