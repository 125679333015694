import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const ICON_WIDTH = 48;

export const styles = createThemedStyles(theme => ({
  profilePicker: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  profilePicker_hover: {
    backgroundColor: theme.colors.background,
  },
  profilePicker_pressed: {
    backgroundColor: theme.colors.backgroundDark,
  },
  profilePickerIcon: {
    width: ICON_WIDTH,
    paddingVertical: spacing.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileMenuOpener: {
    width: 40,
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileMenuOpener_hover: {
    transform: [{scale: 1.2}],
  },
  profileMenuOpener_press: {
    opacity: 0.6,
  },
  avatar: {
    width: ICON_WIDTH,
    paddingVertical: spacing.xs,
    marginRight: spacing.xxs,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
