import React from 'react';
import {View} from 'react-native';

import {styles} from '../FeedCard/common.styles';
import FeedCardActions from '../FeedCard/FeedCardActions';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

interface IProps {
  onClose: () => void;
}

const OnboardingActions: React.FC<IProps> = ({onClose}) => {
  const style = useThemedStyles(styles);
  return (
    <>
      <Space p="xs">
        <Text id="feed.onboarding.continue" size="xs" align="right" />
      </Space>

      <View style={style.infoContainer}>
        <View style={style.actionLabels}>
          <Text id="feed.hide" />
          <Text id="feed.play" />
          <Text id="feed.like" />
        </View>

        <FeedCardActions
          actions={[
            <IconButton
              onPress={() => {}}
              icon={{provider: 'custom', name: 'visibilityOff'}}
              disabled
            />,
            <IconButton
              onPress={() => {}}
              icon={{provider: 'custom', name: 'play'}}
              disabled
            />,
            <IconButton
              onPress={onClose}
              icon={{name: 'heart', provider: 'custom', color: 'textColor'}}
            />,
          ]}
        />
      </View>
    </>
  );
};

export default OnboardingActions;
