import {SupportedWebsites} from 'api-utils';

export interface IExternalPlatform {
  type: string;
  name: string;
  icon: string;
  profileRegex: RegExp;
  placeholder: string;
}

export const externalPlatformsConfig: {[id: string]: IExternalPlatform} =
  SupportedWebsites.reduce(
    (result, website) => ({
      ...result,
      [website.id]: {
        type: website.id,
        name: website.name,
        icon: website.id,
        profileRegex: new RegExp(website.profileRegex),
        placeholder: website.placeholder,
      },
    }),
    {
      custom: {
        type: 'custom',
        name: 'Website',
        icon: 'externalPlatform',
        profileRegex:
          /https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
        placeholder: 'https://',
      },
    },
  );
