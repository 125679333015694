import React, {FC} from 'react';

import ExternalLink from '@/components/ExternalLink';
import {chainsByName} from '@/constants/chains';
import {getExplorerLink, prettifyTokenId} from '@/utils/ethereum';

interface IProps {
  contractAddress: string;
  tokenId: string;
  chainId: string;
}

const NftLink: FC<IProps> = ({contractAddress, tokenId, chainId}) => {
  const url = getExplorerLink(
    chainsByName[chainId]?.explorerUrl,
    contractAddress,
    tokenId,
  );
  const linkName = `#${prettifyTokenId(tokenId)}`;

  return <ExternalLink url={url} name={linkName} size="xs" weight="semibold" />;
};

export default NftLink;
