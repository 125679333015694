import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  content: {
    padding: spacing.s + spacing.xs,
    gap: spacing.s + spacing.xs,
  },
  icon: {
    alignSelf: 'center',
  },
  topActions: {
    gap: spacing.s,
  },
  separatorContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.s,
  },
  separator: {
    flex: 1,
    height: 1,
    backgroundColor: theme.colors.primary,
  },
  bottomActions: {
    gap: spacing.xxs,
  },
}));
