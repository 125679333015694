import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface ThemeState {
  defaultTheme: string; // user's preferred ui theme
  themesSettings: {
    [userId: string]: {
      type: 'artist' | 'collector' | 'predefined';
      name?: string;
    };
  };
  cardsThemingEnabled: boolean;
}

export const initialState: ThemeState = {
  defaultTheme: 'spinamp',
  themesSettings: {},
  cardsThemingEnabled: false,
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setDefaultTheme: (state, action: PayloadAction<string>) => {
      state.defaultTheme = action.payload;
    },
    setUserThemeSettings: (
      state,
      action: PayloadAction<{
        activeUserId: string;
        type: 'artist' | 'collector' | 'predefined';
        name?: string | null;
      }>,
    ) => {
      const {activeUserId, name, type} = action.payload;
      state.themesSettings[activeUserId] = {
        type,
        name: type !== 'predefined' ? undefined : name || state.defaultTheme,
      };
    },
    setCardsTheming: (state, action: PayloadAction<boolean>) => {
      state.cardsThemingEnabled = action.payload;
    },
  },
});

export const {setUserThemeSettings, setDefaultTheme, setCardsTheming} =
  themeSlice.actions;

export default themeSlice.reducer;
