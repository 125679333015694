import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const ICON_WIDTH = 48;

export const styles = createThemedStyles(theme => ({
  item: {
    backgroundColor: theme.background?.image
      ? 'transparent'
      : theme.colors.backgroundLight,
  },
  item_hover: {
    backgroundColor: theme.colors.background,
  },
  item_pressed: {
    backgroundColor: theme.colors.backgroundDark,
  },
  item_active: {
    backgroundColor: theme.colors.background,
  },
  item_disabled: {
    opacity: 0.6,
  },
  firstLevelItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: spacing.s,
  },
  firstLevelItemIcon: {
    width: ICON_WIDTH,
    paddingVertical: spacing.s,
    justifyContent: 'center',
    alignItems: 'center',
  },
  secondLevelItem: {
    paddingLeft: ICON_WIDTH,
    paddingVertical: spacing.xs,
  },
}));
