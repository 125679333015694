import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 36;
const USER_TILE_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  container: {
    padding: spacing.l,
  },
  content: {
    paddingHorizontal: spacing.m,
    paddingBottom: spacing.l,
    gap: spacing.l,
  },
  userTile: {
    alignSelf: 'center',
    width: 260,
    height: USER_TILE_HEIGHT,
    padding: spacing.xs,
    borderRadius: USER_TILE_HEIGHT / 2,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.colors.borderColor,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  coins: {
    alignSelf: 'center',
    marginTop: -spacing.xs,
    marginBottom: -spacing.s,
    gap: spacing.s,
  },
  coin: {
    width: spacing.s,
    height: spacing.s,
    borderRadius: spacing.s / 2,
    borderWidth: 1,
    borderColor: theme.colors.textColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
  beggarContainer: {
    alignSelf: 'center',
  },
  artistAvatar: {
    position: 'absolute',
    top: -2,
    left: 12,
    zIndex: 1,
  },
  textsContainer: {
    gap: spacing.xs,
  },
  links: {
    flexDirection: 'row',
    gap: spacing.m,
    alignItems: 'center',
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
}));
