import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  filtersContainer: {
    marginHorizontal: -spacing.s,
  },
  filters: {
    paddingTop: 0,
    paddingBottom: spacing.s,
  },
}));
