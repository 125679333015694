import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    paddingHorizontal: spacing.s,
  },
  colorPreview: {
    borderRadius: theme.box.borderRadius,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    width: spacing.s,
    height: spacing.s,
  },
}));
