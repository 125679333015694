import React, {ReactNode, useRef} from 'react';
import {TouchableOpacity, View} from 'react-native';
import Animated, {
  Extrapolation,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated';

import Icon from '@/components/Icon/Icon';
import Slider, {
  ISliderProps,
  ISliderRef,
  SliderDefaults,
} from '@/components/Slider';
import Space from '@/components/Space';
import SpinLogo from '@/components/SpinLogo';
import Text from '@/components/Text';
import {getHitSlop, HIT_SLOP} from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles, SPIN_LOGO_SIZE} from './SpinningSlider.style';

interface IProps<ItemType> extends ISliderProps<ItemType> {
  title: ReactNode;
  onMorePress?: () => void;
}

const SpinningSlider = <ItemType,>({
  title,
  onMorePress,
  ...sliderProps
}: IProps<ItemType>) => {
  const style = useThemedStyles(styles);

  const slider = useRef<ISliderRef | null>(null);

  const scrollPosition = useSharedValue(0);
  const pageWidth = useSharedValue(0);

  const {
    visibleColumns = SliderDefaults.visibleColumns,
    gap = SliderDefaults.gap,
    rows = SliderDefaults.rows,
    offset = SliderDefaults.offset,
    padding = SliderDefaults.padding,
  } = sliderProps;

  const spinStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotate: `${
            (scrollPosition.value / (pageWidth.value + gap)) * 360
          }deg`,
        },
      ],
    };
  });

  const rewindStyle = useAnimatedStyle(() => {
    if (!pageWidth.value) {
      return {
        opacity: 0,
      };
    }

    const pages = Math.ceil(sliderProps.data.length / rows);

    return {
      opacity: interpolate(
        scrollPosition.value,
        [
          (pageWidth.value + gap) * (pages - 1 - visibleColumns),
          (pageWidth.value + gap) * (pages - visibleColumns) - offset,
        ],
        [0, 1],
        {
          extrapolateLeft: Extrapolation.CLAMP,
        },
      ),
    };
  });

  return (
    <View style={style.container}>
      <View
        style={[
          style.header,
          {
            paddingLeft: padding,
            paddingRight: padding + offset,
          },
        ]}>
        {sliderProps.data.length > rows * visibleColumns && (
          <TouchableOpacity
            hitSlop={HIT_SLOP}
            onPress={() => slider.current?.next()}
            style={style.spinButton}
            activeOpacity={0.8}>
            <Animated.View
              style={[style.spinContainer, spinStyle]}
              hitSlop={getHitSlop('xs')}>
              <SpinLogo size={SPIN_LOGO_SIZE} showThemeMask={false} />
            </Animated.View>
            <Animated.View style={[style.rewind, rewindStyle]}>
              <Icon
                provider="ant"
                name="fastbackward"
                size={12}
                style={style.rewindIcon}
              />
            </Animated.View>
          </TouchableOpacity>
        )}
        <Space flex>{title}</Space>
        {onMorePress && (
          <TouchableOpacity onPress={onMorePress} hitSlop={getHitSlop('xs')}>
            <Text id="all" weight="semibold" />
          </TouchableOpacity>
        )}
      </View>

      <Slider
        ref={slider}
        onScroll={(_scrollPosition, _pageWidth) => {
          scrollPosition.value = _scrollPosition;
          pageWidth.value = _pageWidth;
        }}
        {...sliderProps}
      />
    </View>
  );
};

export default SpinningSlider;
