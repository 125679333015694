import {StackActions, useNavigation} from '@react-navigation/native';
import {useQueryClient} from '@tanstack/react-query';

import {QueryKeys} from '@/types/queryKeys';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {IBaseUser} from '@/types/user';
import {getUserSlug} from '@/utils/user';

export const useUserLink = () => {
  const queryClient = useQueryClient();
  const navigation = useNavigation<MainStackNavigationParams>();

  const cacheUser = (user: IBaseUser) => {
    queryClient.setQueryData(
      [QueryKeys.userBySlug, getUserSlug(user)],
      data =>
        data || {
          ...user,
        },
    );
  };

  const navigateToUser = (user: IBaseUser) => {
    cacheUser(user);
    navigation.push(Routes.Collector, {
      slug: getUserSlug(user),
    });
  };

  const getUserLinkConfig = (user: IBaseUser) => {
    const params = {slug: getUserSlug(user)};

    return {
      to: {
        screen: Routes.MainNavigation,
        params: {screen: Routes.Collector, params},
      },
      action: StackActions.push(Routes.Collector, params),
    };
  };

  return {cacheUser, navigateToUser, getUserLinkConfig};
};
