import {IClipboard} from './types';

export const Clipboard: IClipboard = {
  copy: (text: string) => navigator.clipboard.writeText(text),
  copySafe: async (text: string) => {
    try {
      await Clipboard.copy(text);
    } catch (e) {}
  },
};
