import {fetchTracksByIds} from '@/api/track';
import {algolia} from '@/services/algolia';
import API from '@/services/spinampApi';
import {
  IPartialPlaylist,
  IPlaylist,
  IPlaylistVotes,
  IPlaylistWithTracks,
  IPublishPlaylistRequestPayload,
} from '@/types/playlists';
import {ISigner} from '@/types/session';
import {formatFirebaseId, getSignedRequestBody, sanitizeId} from '@/utils/api';
import {formatAddressChecksum} from '@/utils/ethereum';
import {flatChunkedArray, omit} from '@/utils/functions';
import {parsePlaylistResponse} from '@/utils/playlists';

export const publishPlaylist = async (
  playlist: IPublishPlaylistRequestPayload,
  signer: ISigner,
): Promise<IPlaylist> => {
  const body = await getSignedRequestBody(
    omit(playlist, 'id', 'followedPlaylist'),
    signer,
  );

  const createdPlaylist: IPlaylist = await API.post('/playlist', body);
  return parsePlaylistResponse(createdPlaylist);
};

export const updatePlaylist = async (
  id: string,
  data: Partial<IPlaylist>,
  signer: ISigner,
) => {
  const cleanId = sanitizeId(id);
  const body = await getSignedRequestBody(omit(data, 'contributions'), signer);
  const updatedPlaylist: {id: string; trackIds: string[]} = await API.put(
    '/playlist/' + cleanId,
    body,
  );
  return {
    id: updatedPlaylist.id,
    trackIds: updatedPlaylist.trackIds.map(formatFirebaseId),
  };
};

export const fetchPlaylistsForCreator = async (
  address: string,
): Promise<IPlaylist[]> => {
  const playlists: IPlaylist[] = await API.get('/playlist?creator=' + address);
  return playlists.map(parsePlaylistResponse);
};

export const fetchOwnPlaylists = async (
  signer: ISigner,
): Promise<IPlaylist[]> => {
  const body = await getSignedRequestBody(
    {
      action: 'get my-playlists',
    },
    signer,
  );
  const playlists: IPlaylist[] = await API.get('/playlist/my-playlists', {
    headers: {
      auth: JSON.stringify(body),
    },
  });
  return playlists.map(parsePlaylistResponse);
};

export const fetchPlaylistsForCollector = async (
  userId: string,
): Promise<IPlaylist[]> => {
  const playlists: IPlaylist[] = await API.get(`/playlist?collector=${userId}`);
  return playlists.map(parsePlaylistResponse);
};

export const fetchPlaylistsForSigners = async (signers: ISigner[]) => {
  const chunkedPlaylists = await Promise.all(signers.map(fetchOwnPlaylists));
  return flatChunkedArray(chunkedPlaylists);
};

export const deletePlaylist = async (id: string, signer: ISigner) => {
  const msg = JSON.stringify({
    action: `delete playlist ${sanitizeId(id)}`,
    params: {
      abandoned: true,
      updatedAtTime: new Date(),
    },
  });

  const body = {
    msg,
    sig: await signer.signMessage(msg),
    address: signer.address,
  };

  return API.delete('/playlist/' + sanitizeId(id), {data: body});
};

export const fetchPlaylistById = async (id: string): Promise<IPlaylist> => {
  const playlist: IPlaylist = await API.get(`/playlist/${id}`);
  return parsePlaylistResponse(playlist);
};

export const fetchPlaylistVotes = async (
  id: string,
): Promise<IPlaylistVotes> => {
  const votes: IPlaylistVotes = await API.get(`/playlist/${id}/votes`);
  return votes || {};
};

export const voteOnTrack = async (
  playlistId: string,
  trackId: string,
  upvote: boolean,
  signer: ISigner,
): Promise<IPlaylistVotes> => {
  const body = await getSignedRequestBody(
    {
      action: `vote on suggestion ${playlistId}/${trackId}`,
      params: {
        upvote,
      },
    },
    signer,
  );
  const votes: IPlaylistVotes = await API.post(
    `/playlist/${encodeURIComponent(playlistId)}/${encodeURIComponent(
      trackId,
    )}/vote`,
    body,
  );

  return votes || {};
};

export const fetchPlaylistCollector = async (playlistId: string) => {
  const playlist = await fetchPlaylistById(playlistId);
  if (playlist?.collector) {
    return formatAddressChecksum(playlist.collector);
  }
  return null;
};

export const fetchAlbumForTrack = async (
  trackId: string,
): Promise<IPlaylistWithTracks | null> => {
  const playlists: IPlaylist[] = await API.get('/playlist/album', {
    params: {trackId},
  });

  if (playlists.length === 0) {
    return null;
  }

  const playlist = playlists[0];
  const tracks = await fetchTracksByIds(playlist.trackIds);

  return {
    playlist,
    tracks,
  };
};

interface IAlgoliaPlaylist extends Omit<IPartialPlaylist, 'id'> {
  objectID: string;
}

export const searchPlaylists = async (
  query: string,
): Promise<IPartialPlaylist[]> => {
  const result: {
    hits: IAlgoliaPlaylist[];
  } = await algolia.playlists.search(query, {
    hitsPerPage: 50,
    filters: 'type:custom AND NOT abandoned:true',
  });

  return result.hits.map(p => ({
    id: p.objectID,
    title: p.title,
    collector: p.collector,
    type: p.type,
  }));
};
