import {IColorConfig, IBoxStyleConfig} from './types';

export const colorsConfig: IColorConfig[] = [
  {
    labelId: 'color.text',
    name: 'text',
  },
  {
    labelId: 'color.background',
    name: 'background',
  },
  {
    labelId: 'color.main',
    name: 'main',
  },
  {
    labelId: 'color.active',
    name: 'active',
  },
  {
    labelId: 'color.favorites',
    name: 'favorites',
  },
];

export const boxStylesConfig: IBoxStyleConfig[] = [
  {labelId: 'boxStyling.round', value: 'rounded'},
  {labelId: 'boxStyling.square', value: 'square'},
];
