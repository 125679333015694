import {defaultSystemFonts} from 'react-native-render-html';

import {FontPacks} from '@/themes/fonts';
import {ITheme} from '@/types/theme';

const fontPackFonts = Object.values(FontPacks).flatMap(fontPack =>
  Object.values(fontPack),
);

export const systemFonts = [...defaultSystemFonts, ...fontPackFonts];

export const fontSize = 12;
export const lineHeight = Math.round(1.2 * fontSize);

export const getDefaultStyle = (theme: ITheme) => ({
  color: theme.colors.textColor,
  fontFamily: theme.font.regular,
  fontSize: 12,
});

// Because we're using different fonts to achieve different text style effects,
// we need to make sure that the font family is set correctly for each tag.
// NOTE: for web version of RenderHtml, some styles needed to be defined also globally in src/web/index.css
export const getTagsStyles = (theme: ITheme) => ({
  div: {
    fontFamily: theme.font.regular,
  },
  p: {
    fontFamily: theme.font.regular,
    marginTop: 0,
    marginBottom: 0,
  },
  h1: {
    fontFamily: theme.font.regular,
    marginTop: 0,
    marginBottom: 0,
  },
  h2: {
    fontFamily: theme.font.regular,
    marginTop: 0,
    marginBottom: 0,
  },
  ul: {
    fontFamily: theme.font.regular,
    marginTop: '0.5em',
    marginBottom: 0,
  },
  li: {
    fontFamily: theme.font.regular,
    lineHeight,
  },
  strong: {
    fontFamily: theme.font.bold,
  },
  b: {
    fontFamily: theme.font.bold,
  },
  i: {
    fontFamily: theme.font.light,
  },
  em: {
    fontFamily: theme.font.light,
  },
});

export type TagsStyles = ReturnType<typeof getTagsStyles>;
