import {useScrollToTop} from '@react-navigation/native';
import React, {useCallback, useRef} from 'react';
import {FlatList} from 'react-native';

import {IDragEndParams} from '@/components/DraggableList';
import DraggableList from '@/components/DraggableList/DraggableList';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import TrackCard, {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import spacing from '@/constants/spacing';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useFavsPlaylist, useFavsTracks} from '@/hooks/useUserPlaylists';
import {
  useToggleTrackInPlaylist,
  useUpdatePlaylistTracks,
} from '@/queries/ownedPlaylists';
import {LibraryUI} from '@/screens/Library/components/LibrarySharedUI';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';
import {playNewQueue} from '@/store/player';
import {ITrack, PlayContextType} from '@/types/common';

const Separator = () => <Space mt="xs" />;

const LibraryFavorites = () => {
  const dispatch = useAppDispatch();
  const listRef = useRef<FlatList | null>(null);
  const {updatePlaylistTracksMutation} = useUpdatePlaylistTracks();
  const {toggleTrackInPlaylistMutation} = useToggleTrackInPlaylist();
  const {isTrackActive} = useIsTrackActive(PlayContextType.favorites);

  useScrollToTop(listRef);

  const {onScrollOffsetChange} = useLibraryContext();

  const favoritePlaylist = useFavsPlaylist();
  const {favoriteTracks, areTracksFetching} = useFavsTracks();

  const onPlay = useCallback(
    (track: ITrack) => {
      if (favoritePlaylist) {
        dispatch(
          playNewQueue({
            trackId: track.id,
            trackIds: favoritePlaylist.trackIds!,
            context: {
              source: 'Favorites',
              type: PlayContextType.favorites,
              titleId: 'playlists.favorites',
            },
          }),
        );
      }
    },
    [favoritePlaylist?.trackIds],
  );

  const onDelete = useCallback(
    (track: ITrack) => {
      if (favoritePlaylist) {
        toggleTrackInPlaylistMutation({
          playlistId: favoritePlaylist.id,
          trackId: track.id,
        });
      }
    },
    [favoritePlaylist?.id],
  );

  const onDragEnd = (params: IDragEndParams<ITrack>) => {
    if (favoritePlaylist) {
      const payload = {
        playlistId: favoritePlaylist!.id,
        trackIds: params.data.map(({id}) => id),
      };
      updatePlaylistTracksMutation(payload);
    }
  };

  if (
    areTracksFetching &&
    favoriteTracks.length === 0 &&
    favoritePlaylist!.trackIds!.length > 0
  ) {
    return <ScreenLoader />;
  }

  if (favoriteTracks.length === 0) {
    return <LibraryUI.Empty textId="library.favorites.empty" />;
  }

  return (
    <DraggableList
      ref={listRef}
      data={favoriteTracks}
      itemSize={TRACK_CARD_HEIGHT}
      itemSpacing={spacing.xs}
      keyExtractor={track => track.id}
      contentContainerStyle={{
        flexGrow: 1,
        padding: spacing.s,
        paddingTop: spacing.xs,
      }}
      ListHeaderComponent={<LibraryUI.Title />}
      renderItem={({item: track, isDragged, drag}) => (
        <TrackCard
          track={track}
          isActive={isTrackActive(track.id)}
          isDraggable
          isDragged={isDragged}
          drag={drag}
          onPlay={onPlay}
          onDelete={onDelete}
          showArtist
        />
      )}
      onDragEnd={onDragEnd}
      ItemSeparatorComponent={Separator}
      draggingEnabled
      showsVerticalScrollIndicator={false}
      bounces
      onScrollOffsetChange={onScrollOffsetChange}
    />
  );
};

export default LibraryFavorites;
