import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  modal: {
    // override default max sizes
    maxWidth: 'auto',
    maxHeight: 'auto',
  },
  modalContent: {
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    width: '100%',
    height: '100%',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  image: {
    ...StyleSheet.absoluteFillObject,
  },
}));
