import React, {FC} from 'react';

import Icon from '@/components/Icon';
import {useExternalSigner} from '@/modules/ExternalWallet';
import {areAddressesEqual} from '@/utils/ethereum';

interface IProps {
  address: string;
}
const ConnectedIndicator: FC<IProps> = ({address}) => {
  const {address: connectedAddress} = useExternalSigner();
  const isConnected = areAddressesEqual(address, connectedAddress);

  return (
    <Icon
      name={isConnected ? 'connected' : 'notConnected'}
      size={16}
      provider="custom"
      style={{opacity: isConnected ? 1 : 0.2}}
    />
  );
};

export default ConnectedIndicator;
