import React, {FC} from 'react';
import {View} from 'react-native';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import Animated, {
  SharedValue,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';

import {ICON_SIZE, styles} from './TabsNavigationBar.style';

interface IProps {
  onPress: () => void;
  onLongPress: () => void;
  index: number;
  activeIndex: SharedValue<number>;
  titleId: string;
  icon: IIconProps;
}

const TabsNavigationBarItem: FC<IProps> = ({
  onPress,
  onLongPress,
  index,
  activeIndex,
  titleId,
  icon,
}) => {
  const style = useThemedStyles(styles);
  const pressed = useSharedValue(false);

  const iconPressStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: pressed.value
          ? withSpring(0.8, {mass: 0.1})
          : withSpring(1, {mass: 0.1}),
      },
    ],
  }));

  const pressIndicatorStyle = useAnimatedStyle(() => ({
    transform: [
      {
        scale: pressed.value
          ? withSpring(1.3, {mass: 0.1})
          : withTiming(0, {duration: 150}),
      },
    ],
  }));

  const activeStyle = useAnimatedStyle(() => ({
    opacity: withTiming(activeIndex.value === index ? 1 : 0.5, {duration: 200}),
  }));

  const buttonProps = {
    onPressIn: () => (pressed.value = true),
    onPressOut: () => (pressed.value = false),
    onLongPress,
    onPress,
  };

  return (
    <TouchableWithoutFeedback
      {...buttonProps}
      containerStyle={style.tabItemWrapper}>
      <Animated.View style={[style.tabItem, activeStyle]}>
        <View>
          <Animated.View style={[style.pressIndicator, pressIndicatorStyle]} />
          <Animated.View style={iconPressStyle}>
            <Icon color="invertedTextColor" size={ICON_SIZE} {...icon} />
          </Animated.View>
        </View>
        <Space h="xs" />
        <Text color="invertedTextColor" size="xxs" id={titleId} />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

export default React.memo(TabsNavigationBarItem);
