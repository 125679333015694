import {Slider} from '@miblanchard/react-native-slider';
import React, {FC, useState} from 'react';
import {View} from 'react-native';

import {useTheme, useThemedStyles} from '@/theme';

import {styles} from './Progress.style';

interface IProps {
  progress: {
    currentTime: number;
    duration: number;
    seekTo?: (value: number) => Promise<void>;
  };
  onDragStart?: () => void;
  onDragEnd?: () => void;
}

const Progress: FC<IProps> = ({progress, onDragEnd, onDragStart}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();

  const [draggingValue, setDraggingValue] = useState<number | undefined>(
    undefined,
  );
  const value =
    draggingValue !== undefined ? draggingValue : progress.currentTime;

  return (
    <View style={style.progressWrapper}>
      <Slider
        containerStyle={style.hitSlopContainer}
        trackStyle={style.progress}
        minimumValue={0}
        maximumValue={progress.duration}
        minimumTrackTintColor={theme.colors.primary}
        value={value}
        onValueChange={([_value]) => setDraggingValue(_value)}
        renderThumbComponent={() => <View />}
        onSlidingStart={onDragStart}
        onSlidingComplete={async ([_value]) => {
          onDragEnd?.();

          try {
            await progress.seekTo?.(_value);
          } catch (e) {
          } finally {
            setDraggingValue(undefined);
          }
        }}
        trackClickable={true}
      />
    </View>
  );
};

export default Progress;
