import {useMemo} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {useUserProfilesQuery} from '@/queries/user';
import {selectSelectedUserId} from '@/store/user';

export const useActiveUser = () => {
  const selectedUserId = useAppSelector(selectSelectedUserId);
  const {users} = useUserProfilesQuery();

  return useMemo(
    () => users?.find(user => user.id === selectedUserId),
    [selectedUserId, users],
  );
};

export const useUserById = (userId?: string) => {
  const {users} = useUserProfilesQuery();

  return useMemo(
    () => users?.find(user => user.id === userId),
    [userId, users],
  );
};
