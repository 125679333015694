import {Vibration} from 'react-native';
import {encodeFunctionData, isHex, parseEther} from 'viem';
import {useWalletClient} from 'wagmi';

import {useConnectorName} from '@/modules/ExternalWallet/useConnectorName';
import {tipAddress, tipChain} from '@/modules/Tip/constants';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';
import {useTrackSendTipEvent} from '@/modules/Tip/SendTip/useTrackSendTipEvent';
import {TipTransactionStep} from '@/modules/Tip/types';
import {
  getAddressForTip,
  getSendTipWriteContractInput,
} from '@/modules/Tip/utils';
import {isWalletCancelError, TransactionError} from '@/modules/Transactions';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {IAddress} from '@/types/common';
import {areAddressesEqual, getChainClient} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

interface ISendTipInput {
  paymentWallet: IAddress;
  artistWalletAddress: string;
}

export const useSendTip = () => {
  const {data: walletClient} = useWalletClient();
  const connector = useConnectorName();
  const {
    artist,
    tipTransaction,
    gasEstimation,
    updateTip,
    resetTransactionDetails,
  } = useSendTipState();

  const setStep = (transactionStep: TipTransactionStep) =>
    updateTip({step: transactionStep});

  const {trackEvent} = useTrackSendTipEvent();

  const sendFromPasskey = async ({
    paymentWallet,
    artistWalletAddress,
  }: ISendTipInput) => {
    setStep('waitingForPasskey');

    const {credentialId, signer} = paymentWallet.metadata?.spinampWallet || {};
    const {privateKey} = await Passkey.get(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransactionError('WRONG_PASSKEY_SIGNER');
    }

    setStep('executingTransaction');

    const userOpHash = await sendTransaction({
      privateKey,
      chainId: tipChain.id,
      transaction: {
        to: tipAddress,
        data: encodeFunctionData(
          getSendTipWriteContractInput(artistWalletAddress),
        ),
        value: parseEther(tipTransaction.amount),
      },
      gasSettings: {
        callGasLimit: gasEstimation.callGasLimit,
        preVerificationGas: gasEstimation.preVerificationGas,
        verificationGasLimit: gasEstimation.verificationGasLimit,
        maxFeePerGas: gasEstimation.maxFeePerGas,
        maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      },
    });

    updateTip({userOpHash});
    trackEvent('receivedTransactionUserOpHash');

    setStep('waitingForReceipt');

    const {transactionHash, status} = await waitForReceipt({
      privateKey,
      chainId: tipChain.id,
      hash: userOpHash,
    });

    updateTip({txHash: transactionHash});
    trackEvent('receivedTransactionTxHash');

    if (status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const sendFromExternalWallet = async ({
    paymentWallet,
    artistWalletAddress,
  }: ISendTipInput) => {
    if (!walletClient) {
      throw new TransactionError('WALLET_NOT_CONNECTED');
    }

    if (walletClient.account.address !== paymentWallet.address) {
      throw new TransactionError('WRONG_WALLET_CONNECTED');
    }

    setStep('executingTransaction');
    const txHash = await walletClient.writeContract({
      address: tipAddress,
      value: parseEther(tipTransaction.amount),
      ...getSendTipWriteContractInput(artistWalletAddress),
      gas: gasEstimation.gasLimit,
      maxFeePerGas: gasEstimation.maxFeePerGas,
      maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransactionError('USER_CANCELLED');
    }

    updateTip({txHash});
    trackEvent('receivedTransactionTxHash');

    setStep('waitingForReceipt');

    const receipt = await getChainClient(tipChain.id).waitForTransactionReceipt(
      {
        hash: txHash,
      },
    );

    if (receipt.status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const sendTip = async (paymentWallet: IAddress) => {
    try {
      resetTransactionDetails();

      trackEvent('confirmed');

      setStep('checkingArtistAddress');
      const artistWalletAddress = await getAddressForTip(artist);

      if (!artistWalletAddress) {
        throw new Error(`Tip error: Wallet not found for artist ${artist.id}`);
      }

      if (isPasskeyWallet(paymentWallet)) {
        await sendFromPasskey({paymentWallet, artistWalletAddress});
      } else {
        await sendFromExternalWallet({paymentWallet, artistWalletAddress});
      }

      setStep('success');
      Vibration.vibrate();
      trackEvent('success');
    } catch (error) {
      setStep('checkout');

      if (isWalletCancelError(error)) {
        trackEvent('cancelled');
        resetTransactionDetails();
        return;
      }

      Sentry.captureException(error, {
        tags: {
          tip: true,
          passkeyWallet: paymentWallet && isPasskeyWallet(paymentWallet),
        },
        extra: {
          artistId: artist.id,
          wallet: paymentWallet,
          connector,
        },
      });

      updateTip({error: error});
      trackEvent('error');
    }
  };

  return {
    sendTip,
  };
};
