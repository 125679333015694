import {useNavigation} from '@react-navigation/native';
import React, {FC, useState} from 'react';

import {fetchNftInfoForUserTrack} from '@/api/collection';
import {IIconProps} from '@/components/Icon';
import {TrackCardBase, ITrackCardProps} from '@/components/TrackCard/TrackCard';
import {useToast} from '@/modules/Toasts';
import {Sentry} from '@/services/sentry';
import {IAddress} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {itemToArray} from '@/utils/functions';
import {downloadTrack} from '@/utils/tracks';

interface IProps extends ITrackCardProps {
  ownerAddresses: IAddress | IAddress[];
  isOwnedTrack: boolean;
}

const CollectedTrackCard: FC<IProps> = ({
  ownerAddresses,
  isOwnedTrack,
  track,
  ...trackCardProps
}) => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const {showToast} = useToast();

  const [isFetchingTransfer, setIsFetchingTransfer] = useState(false);

  const onTransfer = async () => {
    try {
      setIsFetchingTransfer(true);
      const {chainId, contract, tokenId, tokenType, ownerAddress} =
        await fetchNftInfoForUserTrack(
          track.id,
          itemToArray(ownerAddresses).map(a => a.address),
        );

      navigation.navigate(Routes.Transfer, {
        slug: track.slug,
        chainId,
        contract,
        tokenId,
        tokenType,
        from: ownerAddress,
      });
    } catch (error) {
      Sentry.captureException(error);
      showToast({textId: 'transfer.loadError'});
    } finally {
      setIsFetchingTransfer(false);
    }
  };

  const actions = [
    {
      icon: {
        name: 'transfer',
        provider: 'custom',
        size: 18,
      } as IIconProps,
      onPress: onTransfer,
    },
    {
      icon: {
        name: 'save',
        provider: 'ant',
        size: 18,
      } as IIconProps,
      onPress: () => downloadTrack(track),
    },
  ];

  return (
    <TrackCardBase
      {...trackCardProps}
      showLoader={isFetchingTransfer}
      track={track}
      additionalActions={isOwnedTrack ? actions : undefined}
    />
  );
};

export default React.memo(CollectedTrackCard);
