import React, {FC} from 'react';

import ExternalLink from '@/components/ExternalLink';
import NftEventsEmpty from '@/components/NftEventsEmpty';
import Text from '@/components/Text/Text';
import {usePlatformsQuery} from '@/queries/platforms';
import {ITrack} from '@/types/common';
import {NftEventType} from '@/types/nfts';

interface IProps {
  track: ITrack;
  filter?: NftEventType;
}

const TrackActivityEmpty: FC<IProps> = ({track, filter}) => {
  const {platformNames} = usePlatformsQuery();
  const {websiteUrl, platformId} = track || {};
  const platform = (platformId && platformNames[platformId]) || platformId;

  if (websiteUrl && !filter) {
    return (
      <Text
        id="nftEvent.track.emptyState.link"
        size="xs"
        align="center"
        values={{
          websiteUrl: (
            <ExternalLink
              url={websiteUrl}
              name={(platform || websiteUrl).toLowerCase()}
              weight="semibold"
              size="xs"
              underline
            />
          ),
        }}
      />
    );
  }

  return (
    <NftEventsEmpty
      messageId="track"
      filter={filter}
      size="xs"
      align="center"
    />
  );
};

export default TrackActivityEmpty;
