import {useMemo, useState} from 'react';

import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppSelector} from '@/hooks/useRedux';
import {
  usePendingTipsBalance,
  useTotalTipsBalance,
} from '@/modules/Tip/useTipsBalance';
import {sumPendingBalance} from '@/modules/Tip/utils';
import {selectCurrentScreen} from '@/store/navigation';
import {Routes} from '@/types/routes';

export const useTipNotificationToast = () => {
  const activeUser = useActiveUser();
  const [isHidden, setIsHidden] = useState(false);

  const {pendingBalances} = usePendingTipsBalance();

  const currentScreen = useAppSelector(selectCurrentScreen);

  const totalBalance = useMemo(
    () => sumPendingBalance(pendingBalances),
    [pendingBalances],
  );

  // Preload total tips if there are pending tips, so all content for TipsSettings page is preloaded
  useTotalTipsBalance(activeUser?.artistProfile?.id, totalBalance > BigInt(0));

  const showTipNotification =
    !isHidden &&
    ![Routes.TipsSettings, Routes.VerifyTipAddress].includes(
      currentScreen as Routes,
    ) &&
    totalBalance > 0;

  return {
    totalBalance,
    showTipNotification,
    closeTipNotification: () => setIsHidden(true),
  };
};
