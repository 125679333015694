import {useRoute, RouteProp} from '@react-navigation/native';
import React, {FC} from 'react';
import {ScrollView, View} from 'react-native';

import Avatar from '@/components/Avatar/Avatar';
import {Form, ExternalLinksPicker, ImagePicker} from '@/components/Form';
import Header from '@/components/Header/Header';
import HeaderButton from '@/components/HeaderButton';
import Screen from '@/components/Screen/Screen';
import ScreenError from '@/components/ScreenError';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import TransparentButton from '@/components/TransparentButton';
import {externalPlatformsConfig} from '@/constants/externalPlatforms';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useUserQuery} from '@/queries/user';
import {useCollectorEditForm} from '@/screens/CollectorEdit/useCollectorEditForm';
import {ThemedScreen, useThemedScreen, useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {MainStackParams, Routes} from '@/types/routes';
import {IBaseUser} from '@/types/user';
import {generateId} from '@/utils/functions';
import {getUserAvatar} from '@/utils/user';

import {styles, PREVIEW_SIZE} from './CollectorEdit.style';

const CollectorEdit: FC = () => {
  const {
    params: {slug},
  } = useRoute<RouteProp<MainStackParams, Routes.CollectorEdit>>();
  const {
    user,
    query: {isLoading},
  } = useUserQuery(slug);

  if (user) {
    return <CollectorEditForm user={user} />;
  }

  if (isLoading) {
    return (
      <Screen>
        <Header showBack titleId="profileEdit.title" />
        <ScreenLoader />
      </Screen>
    );
  }

  return (
    <Screen>
      <Header showBack titleId="profileEdit.title" />
      <ScreenError textId="notFound" />
    </Screen>
  );
};

const CollectorEditForm: FC<{user: IBaseUser}> = ({user}) => {
  const style = useThemedStyles(styles);
  const theme = useThemedScreen({
    customTheme: user?.customTheme,
    predefinedThemeName: user?.predefinedThemeName,
  });
  const [isLinksModalOpen, openLinksModal, closeLinksModal] = useBooleanState();

  const {
    form,
    onDiscard,
    onSubmit,
    isSubmitting,
    avatarUploadProps,
    avatarSizeInfo,
    externalLinksForm,
  } = useCollectorEditForm(user);

  return (
    <ThemedScreen theme={theme}>
      <Screen>
        <Header
          titleId="profileEdit.title"
          leftActions={
            <HeaderButton onPress={onDiscard} text={{id: 'discard'}} />
          }
          rightActions={
            <HeaderButton
              onPress={onSubmit}
              text={{id: 'save'}}
              isLoading={isSubmitting}
              disabled={avatarUploadProps.isUploading}
            />
          }
        />
        <Form form={form}>
          <ScrollView
            contentContainerStyle={style.content}
            showsVerticalScrollIndicator={false}>
            <ImagePicker
              {...avatarUploadProps}
              circular
              previewSize={PREVIEW_SIZE}
              minSize={avatarSizeInfo.minSize}
              placeholder={
                <Avatar
                  id={user.id}
                  url={getUserAvatar(user)}
                  size={PREVIEW_SIZE}
                  resizeWidth={ImageSize.avatarPreview}
                />
              }
            />

            <Form.Divider />

            <Form.Field label={{id: 'profileEdit.username'}}>
              <Form.Input
                name="name"
                rules={{
                  required: !!user.name && 'profileEdit.error.nameRequired',
                }}
                maxLength={50}
              />
              <Form.Error name="name" />
            </Form.Field>

            <Form.Field
              label={{id: 'profileEdit.description'}}
              description={{id: 'profileEdit.descriptionExplainer.collector'}}>
              <Form.RichTextEditor name="description" />
            </Form.Field>

            <Form.Header
              header={{id: 'profileEdit.externalLinks'}}
              description={{id: 'profileEdit.disclaimer'}}
            />

            {externalLinksForm.fields.length === 0 ? (
              <View style={style.centeredColumn}>
                <Text
                  align="center"
                  size="xs"
                  id="profileEdit.externalLinks.empty"
                />
                <Space h="xs" />
                <TransparentButton
                  onPress={openLinksModal}
                  text={{id: 'add'}}
                  icon={{provider: 'custom', name: 'add'}}
                />
              </View>
            ) : (
              <>
                {externalLinksForm.fields.map((link, index) => {
                  const name = `externalLinks.${index}.url`;
                  const linkConfig = externalPlatformsConfig[link.type];

                  return (
                    <Form.Field
                      key={link.id}
                      label={{
                        children: externalPlatformsConfig[link.type].name,
                      }}
                      onRemove={() => externalLinksForm.remove(index)}>
                      <Form.Input
                        name={name}
                        placeholder={linkConfig.placeholder}
                        rules={{
                          required: true,
                          pattern: linkConfig.profileRegex,
                        }}
                        prefixIcon={{
                          name: linkConfig.icon,
                          provider: 'custom',
                        }}
                      />
                      <Form.Error
                        name={name}
                        defaultMessageId="profileEdit.externalLinks.validation"
                      />
                    </Form.Field>
                  );
                })}

                <View style={style.centeredColumn}>
                  <TransparentButton
                    onPress={openLinksModal}
                    text={{id: 'add'}}
                    icon={{provider: 'custom', name: 'add'}}
                  />
                </View>
              </>
            )}

            {isLinksModalOpen && (
              <ExternalLinksPicker
                selectedLinks={externalLinksForm.fields}
                onSelect={link => {
                  externalLinksForm.append({
                    id: generateId(),
                    type: link.type,
                    url: '',
                  });
                }}
                isOpen
                onClose={closeLinksModal}
              />
            )}
          </ScrollView>
        </Form>
      </Screen>
    </ThemedScreen>
  );
};

export default CollectorEdit;
