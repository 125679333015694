import spacing from '@/constants/spacing';
import {createThemedStyles, isThemeMonochrome} from '@/theme';

const CONTAINER_HEIGHT = spacing.xs * 3;

export const styles = createThemedStyles(theme => ({
  containerStyle: {
    height: CONTAINER_HEIGHT,
  },
  progress: {
    width: '100%',
    height: spacing.xs,
    backgroundColor: theme.colors.backgroundDark,
    borderWidth: isThemeMonochrome(theme) ? 2 : 0,
    borderColor: theme.colors.borderColor,
  },
  sliderThumb: {
    height: CONTAINER_HEIGHT,
    width: spacing.xxs * 3,
    borderWidth: 3,
    borderColor: theme.colors.borderColor,
    backgroundColor: theme.colors.backgroundLight,
  },
  timeInfo: {
    marginTop: spacing.xs,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
