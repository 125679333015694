import React, {FC} from 'react';
import {View} from 'react-native';

import {useTheme} from '@/theme';

import {IProps} from './types';

const Gradient: FC<IProps> = ({
  color = 'background',
  style,
  vertical = false,
  y2 = 100,
  x2 = 100,
}) => {
  const theme = useTheme();

  return (
    <View
      style={[
        style,
        {
          height: '100%',
          background: `linear-gradient(to ${vertical ? 'top' : 'left'}, ${
            theme.colors[color]
          } ${vertical ? 100 - y2 : 100 - x2}%, transparent)`,
        },
      ]}
    />
  );
};

export default Gradient;
