import {FC} from 'react';

import {useAppBootstrap} from '@/hooks/useAppBootstrap';

const AppBootstrap: FC = () => {
  useAppBootstrap();

  return null;
};

export default AppBootstrap;
