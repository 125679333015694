import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar/Avatar';
import Link from '@/components/Link';
import {CrossPlatformStyle} from '@/components/Link/shared';
import Loader from '@/components/Loader';
import Text from '@/components/Text/Text';
import {useUserLink} from '@/hooks/useUserLink';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {IBaseUser} from '@/types/user';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

import {styles, AVATAR_SIZE} from './ContributorInfo.style';

interface IProps {
  user?: IBaseUser | null;
  textId: string;
}

const ContributorInfo: FC<IProps> = ({user, textId}) => {
  const style = useThemedStyles(styles);
  const {getUserLinkConfig} = useUserLink();

  return (
    <View style={style.container}>
      <View style={style.avatar}>
        {user && (
          <Link {...getUserLinkConfig(user)}>
            <Avatar
              id={user.id}
              size={AVATAR_SIZE}
              resizeWidth={ImageSize.avatar}
              url={getUserAvatar(user)}
            />
          </Link>
        )}
      </View>
      {user ? (
        <Link {...getUserLinkConfig(user)}>
          <Text
            size="xs"
            id={textId}
            numberOfLines={1}
            values={{
              user: getUserDisplayName(user),
            }}
          />
        </Link>
      ) : (
        <Loader size="xs" weight="regular" />
      )}
    </View>
  );
};

export default ContributorInfo;
