import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const BADGE_SIZE = spacing.s - spacing.xxs / 2;

export const styles = createThemedStyles(theme => ({
  badge: {
    position: 'absolute',
    top: -spacing.xxs / 2,
    right: -spacing.xxs / 2,
    backgroundColor: theme.colors.active,
    width: BADGE_SIZE,
    height: BADGE_SIZE,
    borderRadius: BADGE_SIZE / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    fontSize: 8,
    lineHeight: 8,
  },
}));
