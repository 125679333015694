import React, {FC} from 'react';
import {View, ViewStyle} from 'react-native';

import {TextProps} from '@/components/Text';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './ScreenError.style';

export interface IScreenErrorProps {
  textId?: string;
  text?: string;
  textProps?: TextProps;
  style?: ViewStyle;
}

const ScreenError: FC<IScreenErrorProps> = ({
  text,
  textId,
  style,
  textProps = {},
}) => {
  const screenLoaderStyle = useThemedStyles(styles);

  return (
    <View style={[screenLoaderStyle.container, style]}>
      <Text id={textId} size="l" weight="semibold" {...textProps}>
        {text}
      </Text>
    </View>
  );
};

export default ScreenError;
