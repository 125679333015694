import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  listContent: {
    padding: spacing.m,
    paddingTop: 0,
  },
  listHeader: {
    marginBottom: spacing.m,
  },
  listHeaderItem: {
    marginTop: spacing.m,
  },
}));
