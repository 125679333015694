import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon';
import OpacityGradient from '@/components/OpacityGradient';
import {useTheme, useThemedStyles} from '@/theme';

import {ACTION_WIDTH, styles} from './CardMenu.style';
import ExposedCardMenuContent from './ExposedCardMenuContent';
import {defaultProps, ICardMenuProps} from './shared';
import {useCardMenu} from './useCardMenu';

const CardMenu: FC<ICardMenuProps> = ({
  collapsedActions,
  exposedActions,
  iconColor = defaultProps.iconColor,
  backgroundColor = defaultProps.backgroundColor,
  menuIcon = defaultProps.menuIcon,
}) => {
  const theme = useTheme();
  const style = useThemedStyles(styles);
  const {isMenuOpen, openMenu} = useCardMenu();

  return (
    <View style={style.actionsContainer}>
      <View
        style={{
          zIndex: isMenuOpen ? 0 : 1,
          opacity: isMenuOpen ? 0 : 1,
          transition: 'opacity 200ms ease, transform 200ms ease',
          height: '100%',
          justifyContent: 'center',
        }}>
        {exposedActions ? (
          <ExposedCardMenuContent actions={exposedActions} />
        ) : null}
      </View>

      <View
        pointerEvents={isMenuOpen ? 'auto' : 'box-only'}
        style={[
          style.hiddenActions,
          {backgroundColor: theme.colors[backgroundColor]},
          // @ts-ignore
          {
            opacity: isMenuOpen ? 1 : 0,
            transform: `translateX(${isMenuOpen ? 0 : ACTION_WIDTH}px)`,
            transition: 'opacity 200ms ease, transform 200ms ease',
          },
        ]}>
        <OpacityGradient color={backgroundColor} style={style.gradient} />
        {(exposedActions || [])
          .concat(collapsedActions)
          .map((action, index) => (
            <TouchableOpacity
              key={index}
              onPress={action.onPress}
              style={style.action}>
              <Icon {...action.icon} color={iconColor} />
            </TouchableOpacity>
          ))}
      </View>
      <TouchableOpacity onPress={openMenu} style={style.action}>
        <View
          // @ts-ignore
          style={{
            transform: `rotate(${isMenuOpen ? -90 : 0}deg)`,
            transition: 'transform 200ms linear',
          }}>
          <Icon {...menuIcon} color={iconColor} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default CardMenu;
