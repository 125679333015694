import {useNavigation} from '@react-navigation/native';
import React, {createContext, FC, ReactNode} from 'react';
import {useBalance} from 'wagmi';

import {useAppDispatch} from '@/hooks/useRedux';
import {tipChain} from '@/modules/Tip/constants';
import {IVerifyTransactionState} from '@/modules/Tip/types';
import {getVerifyTransactionInput} from '@/modules/Tip/utils';
import {
  useGasEstimation,
  useSyncTransactionState,
} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {updateTransaction as updateTransactionAction} from '@/store/transactions/slice';
import {IAddress, IBalance} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {generateId} from '@/utils/functions';

export interface IVerifyAddressContext {
  transaction: IVerifyTransactionState;
  updateTransaction: (update: Partial<IVerifyTransactionState>) => void;
  user: IUser;
  verifiedWallet: IAddress;
  balance: IBalance | undefined;
  gasEstimation: IGasEstimation;
  resetTransactionDetails: () => void;
  close: () => void;
}

export const VerifyAddressContext = createContext<
  IVerifyAddressContext | undefined
>(undefined);

interface IProps {
  transactionId: string | undefined;
  user: IUser;
  verifiedWallet: IAddress;
  children: ReactNode;
}

export const VerifyAddressProvider: FC<IProps> = ({
  transactionId,
  user,
  verifiedWallet,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {transaction} = useSyncTransactionState(
    transactionId,
    'verifyAddress',
    () =>
      ({
        createdDate: Date.now(),
        type: 'verifyAddress',
        id: generateId(),
        isMinimized: false,
        userId: user.id,
        verifiedWalletAddress: verifiedWallet.address,
        payoutAddress: verifiedWallet.address,
        step: 'checkout',
        txHash: undefined,
        userOpHash: undefined,
        error: undefined,
      } satisfies IVerifyTransactionState),
  );

  const updateTransaction = (update: Partial<IVerifyTransactionState>) =>
    dispatch(
      updateTransactionAction({
        id: transaction.id,
        type: 'verifyAddress',
        update,
      }),
    );

  const resetTransactionDetails = () => {
    updateTransaction({
      txHash: undefined,
      userOpHash: undefined,
      error: undefined,
    });
  };

  const {data: balance} = useBalance({
    address: verifiedWallet.address,
    chainId: tipChain.id,
  });

  const gasEstimation = useGasEstimation({
    chainId: tipChain.id,
    wallet: verifiedWallet,
    transaction: getVerifyTransactionInput({
      verifiedAddress: transaction.verifiedWalletAddress,
      payoutAddress: transaction.payoutAddress,
    }),
    onError: error => {
      Sentry.captureException(error, {
        tags: {
          simulation: true,
          verifyTipAddress: true,
          passkeyWallet: isPasskeyWallet(verifiedWallet),
        },
        extra: {
          wallet: verifiedWallet,
        },
      });
    },
  });

  return (
    <VerifyAddressContext.Provider
      value={{
        transaction,
        updateTransaction,
        user,
        verifiedWallet,
        balance,
        gasEstimation,
        resetTransactionDetails,
        close: () => navigation.goBack(),
      }}>
      {children}
    </VerifyAddressContext.Provider>
  );
};
