import React, {FC, ReactNode, useEffect} from 'react';
import {StyleProp} from 'react-native';
import {
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withTiming,
} from 'react-native-reanimated';
import Animated from 'react-native-reanimated';

interface IProps {
  isActive: boolean;
  children: ReactNode;
  delay?: number;
  duration?: number;
  style?: StyleProp<any>;
}

const DelayedFadeIn: FC<IProps> = ({
  isActive,
  children,
  delay = 1500,
  duration = 300,
  style,
}) => {
  const wasActive = useSharedValue(false);

  useEffect(() => {
    if (isActive) {
      wasActive.value = true;
    }
  }, [isActive]);

  const fadeStyle = useAnimatedStyle(() => ({
    opacity: withDelay(delay, withTiming(wasActive.value ? 1 : 0, {duration})),
  }));

  return <Animated.View style={[style, fadeStyle]}>{children}</Animated.View>;
};

export default DelayedFadeIn;
