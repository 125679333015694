import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const QUEUE_ICON_SIZE = spacing.m + spacing.xxs;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    space: {
      height: responsive.isMobile ? spacing.m : spacing.l + spacing.xs,
    },
    wrapper: {
      backgroundColor: calculateBackgroundColorLight(theme),
      flex: 1,
      paddingTop: spacing.m,
      paddingHorizontal: spacing.s,
      justifyContent: 'flex-end',
    },
    artworkContainer: {
      flexShrink: 1,
      width: '100%',
      aspectRatio: 1,
      alignSelf: 'center',
    },
    artworkPositioner: {
      alignSelf: 'center',
      aspectRatio: 1,
      height: '100%',
    },
    artwork: {
      borderRadius: theme.box.borderRadius,
      borderWidth: theme.box.borderRightWidth,
      borderColor: theme.colors.borderColor,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    artworkPlaceholder: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: theme.box.borderRadius,
      backgroundColor: theme.colors.backgroundDark,
    },
    infoContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    titleContainer: {
      flex: 1,
    },
    controlsContainer: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    mainControls: {
      flex: 1,
    },
    collectButtonWrapper: {
      flex: 1,
      alignItems: 'center',
    },
    queueOpener: {
      borderTopLeftRadius: theme.box.borderRadius,
      borderTopRightRadius: theme.box.borderRadius,
      padding: spacing.s,
      paddingBottom: spacing.s + safeAreaInsets.bottom,
      backgroundColor: theme.colors.backgroundDark,
      marginHorizontal: -spacing.s,
    },
    nextTrackRow: {
      flexDirection: 'row',
      alignItems: 'center',
      height: QUEUE_ICON_SIZE,
    },
    nextTrackText: {
      flex: 1,
      paddingHorizontal: spacing.xs,
    },
    queueTextRow: {
      alignSelf: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      height: QUEUE_ICON_SIZE,
    },
    queueIcon: {
      width: QUEUE_ICON_SIZE,
    },
    queueIcon_floating: {
      position: 'absolute',
      left: -QUEUE_ICON_SIZE - spacing.xs,
    },
  }),
);
