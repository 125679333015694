import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const SIZE = spacing.s * 2;

export const styles = createThemedStyles(() => ({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
  },
  button_disabled: {
    opacity: 0.3,
  },
  pressIndicator: {
    position: 'absolute',
    width: '100%',
    aspectRatio: 1,
  },
}));
