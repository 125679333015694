import {createThemedStyles} from '@/theme';

export const BORDER_WIDTH = 2;

export const styles = createThemedStyles(theme => ({
  container: {
    backgroundColor: theme.colors.background,
    borderWidth: BORDER_WIDTH,
    borderColor: theme.colors.borderColor,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
