import {useStore} from '@/hooks/useRedux';
import {useVerifyAddressState} from '@/modules/Tip/VerifyAddress/useVerifyAddressState';
import {useTransactionEventTracking} from '@/modules/Transactions/eventsTracking';
import {
  ITransactionEvent,
  TransactionState,
} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {selectTransactionById} from '@/store/transactions/selectors';
import {generateId} from '@/utils/functions';

export const useTrackVerifyAddressEvent = () => {
  const {getState} = useStore();

  const {transaction, verifiedWallet} = useVerifyAddressState();
  const {mutate} = useTransactionEventTracking();

  const trackEvent = (eventName: ITransactionEvent['eventName']) => {
    const transactionState = selectTransactionById(
      getState(),
      transaction.id,
      'verifyAddress',
    ) as Extract<TransactionState, {type: 'verifyAddress'}>;

    if (transactionState.userId) {
      mutate({
        id: generateId(),
        timestamp: Date.now(),
        transactionId: transactionState.id,
        type: 'verifyAddress' as ITransactionEvent['type'],
        userId: transactionState.userId,
        eventName,
        address: verifiedWallet.address,
        isPasskey: isPasskeyWallet(verifiedWallet),
        txHash: transactionState.txHash,
        userOpHash: transactionState.userOpHash,
        error: transactionState.error?.message,
      });
    }
  };

  return {
    trackEvent,
  };
};
