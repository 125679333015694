import React, {FC} from 'react';

import CardButton from '@/components/CardButton';
import SpinampWalletIcon from '@/components/SpinampWalletIcon';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import OnboardingTile from '@/modules/Wallets/screens/WalletList/components/OnboardingTile';

interface IProps {
  onCreatePasskey: () => void;
  onClose: () => void;
}

const PasskeyOnboarding: FC<IProps> = ({onCreatePasskey, onClose}) => {
  return (
    <OnboardingTile onClose={onClose}>
      <SpinampWalletIcon size={spacing.xxl} />

      <Text
        align="center"
        size="xs"
        weight="semibold"
        id="wallets.addSpinampWallet.header"
      />

      <Text
        align="center"
        size="xs"
        id="wallets.addSpinampWallet.description"
      />

      <CardButton
        onPress={onCreatePasskey}
        text={{id: 'wallets.addSpinampWallet', size: 'xs'}}
      />
    </OnboardingTile>
  );
};

export default PasskeyOnboarding;
