import React from 'react';

import WarningBox from '@/components/WarningBox';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {IAddress} from '@/types/common';
import {areAddressesEqual} from '@/utils/ethereum';

export const useDeliverWalletValidation = (
  fromAddress: string,
  deliveryWallet: IAddress | undefined,
): ITransactionValidationError | undefined => {
  if (
    !deliveryWallet ||
    !areAddressesEqual(fromAddress, deliveryWallet.address)
  ) {
    return undefined;
  }

  return {
    message: (
      <WarningBox
        message={{
          id: 'transfer.validation.wrongDeliveryWallet',
        }}
      />
    ),
  };
};
