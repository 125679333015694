import {
  privateKeyToAccount,
  generatePrivateKey,
  PrivateKeyAccount,
} from 'viem/accounts';

import {IInternalSigner} from '@/types/session';

const _mapAccountToSigner = (
  account: PrivateKeyAccount,
  privateKey: `0x${string}`,
): IInternalSigner => ({
  address: account.address,
  signMessage: async msg => account.signMessage({message: msg}),
  sessionKey: privateKey,
});

export const createInternalSigner = (): IInternalSigner => {
  const privateKey = generatePrivateKey();
  const account = privateKeyToAccount(privateKey);

  return _mapAccountToSigner(account, privateKey);
};

export const getSignerFromSessionKey = (
  sessionKey: string,
): IInternalSigner => {
  const privateKey = sessionKey as `0x${string}`;
  const account = privateKeyToAccount(privateKey);
  return _mapAccountToSigner(account, privateKey);
};
