import React, {FC, ReactNode} from 'react';
import {StyleProp, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import {IIconProps} from '@/components/Icon/Icon';
import Loader from '@/components/Loader/Loader';
import Space, {ISpaceProps} from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useThemedStyles} from '@/theme';
import {Color} from '@/types/theme';

import {styles} from './Button.style';

export interface IButtonProps extends Omit<ISpaceProps, 'children'> {
  onPress: () => void;
  disabled?: boolean;
  text?: string;
  textId?: string;
  icon?: IIconProps;
  containerStyle?: StyleProp<any>;
  style?: StyleProp<any>;
  textProps?: TextProps;
  highlight?: boolean;
  isLoading?: boolean;
  loaderTextId?: string;
  loaderTextProps?: TextProps;
  suffixComponent?: ReactNode;
  children?:
    | (({
        pressed,
        hover,
        actionColor,
      }: {
        pressed?: boolean;
        hover?: boolean;
        actionColor: (color?: Color) => Color;
      }) => ReactNode)
    | ReactNode;
}

const Button: FC<IButtonProps> = ({
  onPress,
  disabled,
  textId,
  text,
  icon,
  style,
  containerStyle,
  highlight,
  loaderTextId,
  loaderTextProps = {},
  isLoading = false,
  textProps = {},
  suffixComponent,
  children,
  ...props
}) => {
  const buttonStyle = useThemedStyles(styles);
  const hasText = !!(textId || text);

  return (
    <TouchableCard
      onPress={onPress}
      disabled={disabled || isLoading}
      containerStyle={containerStyle}
      style={[buttonStyle.button, highlight && buttonStyle.highlighted, style]}
      highlight={highlight}
      ph="s"
      pv="xs"
      {...props}>
      {({pressed, hover, actionColor}) => (
        <>
          {isLoading && (
            <View style={buttonStyle.loader}>
              <Loader
                size={textProps.size || 'xs'}
                textId={loaderTextId}
                {...loaderTextProps}
              />
            </View>
          )}
          <View
            style={[
              buttonStyle.buttonContent,
              isLoading && buttonStyle.buttonContent_loading,
            ]}>
            {hasText && (
              <Text
                id={textId}
                size="xs"
                align="center"
                weight="semibold"
                color={actionColor()}
                {...textProps}>
                {text}
              </Text>
            )}
            {icon && (
              <>
                {hasText && <Space ml="xs" />}
                <Icon
                  {...icon}
                  color={highlight ? 'activeText' : actionColor(icon.color)}
                />
              </>
            )}
            {suffixComponent}
          </View>
          {typeof children === 'function'
            ? children({
                pressed,
                hover,
                actionColor,
              })
            : children}
        </>
      )}
    </TouchableCard>
  );
};

export default Button;
