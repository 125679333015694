import Collection from '@/screens/SpinampWrapped/pages/Collection';
import Onboarding from '@/screens/SpinampWrapped/pages/Onboarding';
import Summary from '@/screens/SpinampWrapped/pages/Summary';
import TopArtist from '@/screens/SpinampWrapped/pages/TopArtist';
import TopArtists from '@/screens/SpinampWrapped/pages/TopArtists';
import TopTrack from '@/screens/SpinampWrapped/pages/TopTrack';
import TopTracks from '@/screens/SpinampWrapped/pages/TopTracks';
import {ISpinampWrappedPageConfig} from '@/screens/SpinampWrapped/types';
import {IArtist, ITrack} from '@/types/common';

export const setupPages = (
  tracks: {track: ITrack; count: number}[],
  artists: {artist: IArtist; count: number}[],
  supportedArtists: IArtist[],
): ISpinampWrappedPageConfig[] => {
  let pages: ISpinampWrappedPageConfig[] = [
    {
      id: 'onboarding',
      Component: Onboarding,
    },
  ];

  if (tracks.length > 0) {
    pages.push({
      id: 'topTrack',
      Component: TopTrack,
    });
  }

  if (tracks.length > 1) {
    pages.push({
      id: 'topTracks',
      Component: TopTracks,
    });
  }

  if (artists.length > 0) {
    pages.push({
      id: 'topArtist',
      Component: TopArtist,
    });
  }

  if (artists.length > 1) {
    pages.push({
      id: 'topArtists',
      Component: TopArtists,
    });
  }

  if (supportedArtists.length > 0) {
    pages.push({
      id: 'collection',
      Component: Collection,
    });
  }

  pages.push({
    id: 'summary',
    Component: Summary,
  });

  return pages;
};
