import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';
import {selectActiveUserId} from '@/store/user';
import {PlaylistType} from '@/types/playlists';
import {
  findPlaylistById,
  findPlaylistOrFollowedPlaylistById,
} from '@/utils/playlists';

export const selectPlaylistsStore = (state: RootState) => state.playlists;

export const selectAllPlaylists = createSelector(
  selectPlaylistsStore,
  playlists => playlists.playlists,
);

export const selectUserPlaylists = createSelector(
  selectAllPlaylists,
  selectActiveUserId,
  (playlists, userId) => playlists.filter(p => p.collector === userId),
);

export const selectFavsPlaylist = createSelector(
  selectAllPlaylists,
  selectActiveUserId,
  (playlists, userId) =>
    playlists.find(
      playlist =>
        userId === playlist.collector &&
        playlist.type === PlaylistType.favorites,
    )!,
);

export const selectFavsTrackIds = createSelector(
  selectFavsPlaylist,
  favPlaylist =>
    favPlaylist?.trackIds?.reduce<{[trackId: string]: boolean | undefined}>(
      (res, trackId) => ({...res, [trackId]: true}),
      {},
    ) || {},
);

export const selectFollowedArtistsIds = createSelector(
  selectUserPlaylists,
  playlists =>
    playlists.reduce<{[artistId: string]: boolean | undefined}>(
      (res, playlist) => {
        if (playlist.artistId) {
          return {
            ...res,
            [playlist.artistId]: true,
          };
        }
        return res;
      },
      {},
    ),
);

export const selectPlaylistById = createSelector(
  [selectAllPlaylists, (state, chosenPlaylistId: string) => chosenPlaylistId],
  (playlists, chosenPlaylistId) =>
    findPlaylistById(chosenPlaylistId, playlists)!,
);

export const selectPlaylistOrFollowedPlaylist = createSelector(
  [selectUserPlaylists, (state, chosenPlaylistId: string) => chosenPlaylistId],
  (playlists, chosenPlaylistId) =>
    findPlaylistOrFollowedPlaylistById(chosenPlaylistId, playlists),
);

export const selectDeletedPlaylists = createSelector(
  selectPlaylistsStore,
  player => player.deletedPlaylists || [],
);

export const selectDeletedPlaylistById = createSelector(
  [
    selectDeletedPlaylists,
    (state, chosenPlaylistId: string) => chosenPlaylistId,
  ],
  (deletedPlaylists, chosenPlaylistId) =>
    deletedPlaylists.find(({id}) => id === chosenPlaylistId)!,
);

export const selectUnsavedPlaylists = createSelector(
  selectAllPlaylists,
  playlists => playlists.filter(p => !p.serverUpdatedAtTime),
);

export const selectUnsyncedPlaylists = createSelector(
  selectAllPlaylists,
  playlists =>
    playlists.filter(
      p =>
        p.updatedAtTime &&
        p.serverUpdatedAtTime &&
        p.updatedAtTime > p.serverUpdatedAtTime,
    ),
);

export const selectUnsyncedFollowedPlaylists = createSelector(
  selectAllPlaylists,
  playlists =>
    playlists.filter(
      ({collector, followedPlaylist: p}) =>
        p &&
        p.updatedAtTime &&
        p.serverUpdatedAtTime &&
        p.updatedAtTime > p.serverUpdatedAtTime &&
        p.collaborators?.find(c => c.userId === collector),
    ),
);
