import {useStore} from '@/hooks/useRedux';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useTransactionEventTracking} from '@/modules/Transactions/eventsTracking';
import {
  ITransactionEvent,
  TransactionState,
} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {selectTransactionById} from '@/store/transactions/selectors';
import {generateId} from '@/utils/functions';

export const useTrackCollectEvent = () => {
  const {getState} = useStore();

  const {
    transaction,
    track,
    paymentWallet,
    selectedOffer,
    selectedOfferTotalPrice,
  } = useCollectState();

  const {mutate} = useTransactionEventTracking();

  const trackEvent = (eventName: ITransactionEvent['eventName']) => {
    const transactionState = selectTransactionById(
      getState(),
      transaction.id,
      'collect',
    ) as Extract<TransactionState, {type: 'collect'}>;

    if (transactionState?.userId && transactionState?.walletsSettings) {
      mutate({
        id: generateId(),
        timestamp: Date.now(),
        transactionId: transactionState.id,
        type: 'collect' as ITransactionEvent['type'],
        userId: transactionState.userId,
        eventName,
        trackId: track.id,
        chainId: selectedOffer?.chainId,
        address: transactionState.walletsSettings.paymentAddress,
        isPasskey: !!paymentWallet && isPasskeyWallet(paymentWallet),
        receivingAddress: transactionState.walletsSettings.deliveryAddress,
        referralAddress: transactionState.referralAddress,
        amount: selectedOfferTotalPrice?.toString(),
        txHash: transactionState.txHash,
        approvalTxHash: transactionState.approvalTxHash,
        userOpHash: transactionState.userOpHash,
        approvalUserOpHash: transactionState.approvalUserOpHash,
        quantity: transactionState.quantity,
        error: transactionState.error?.message,
        callData: selectedOffer?.mintTransaction.data,
        platformId: track.platformId,
      });
    }
  };

  return {
    trackEvent,
  };
};
