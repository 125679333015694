export type SearchCategory = 'tracks' | 'artists' | 'playlists' | 'users';

export interface ISearchCategoryConfig {
  id: SearchCategory;
  textId: string;
}

export const searchCategories: ISearchCategoryConfig[] = [
  {
    id: 'tracks',
    textId: 'search.tracks',
  },
  {
    id: 'artists',
    textId: 'search.artists',
  },
  {
    id: 'playlists',
    textId: 'search.playlists',
  },
  {
    id: 'users',
    textId: 'search.collectors',
  },
];
