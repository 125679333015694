import {useStore} from '@/hooks/useRedux';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';
import {useTransactionEventTracking} from '@/modules/Transactions/eventsTracking';
import {
  ITransactionEvent,
  TransactionState,
} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {selectTransactionById} from '@/store/transactions/selectors';
import {generateId} from '@/utils/functions';

export const useTrackSendTipEvent = () => {
  const {getState} = useStore();

  const {tipTransaction, artist, paymentWallet} = useSendTipState();
  const {mutate} = useTransactionEventTracking();

  const trackEvent = (eventName: ITransactionEvent['eventName']) => {
    const transactionState = selectTransactionById(
      getState(),
      tipTransaction.id,
      'sendTip',
    ) as Extract<TransactionState, {type: 'sendTip'}>;

    if (transactionState?.userId && paymentWallet) {
      mutate({
        id: generateId(),
        timestamp: Date.now(),
        transactionId: transactionState.id,
        type: 'sendTip' as ITransactionEvent['type'],
        userId: transactionState.userId,
        eventName,
        address: paymentWallet.address,
        isPasskey: isPasskeyWallet(paymentWallet),
        amount: transactionState.amount,
        txHash: transactionState.txHash,
        userOpHash: transactionState.userOpHash,
        error: transactionState.error?.message,
        extraData: {
          artistId: artist.id,
        },
      });
    }
  };

  return {
    trackEvent,
  };
};
