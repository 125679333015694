import React, {forwardRef, ReactNode, useImperativeHandle, useRef} from 'react';
import {useIntl} from 'react-intl';
import {
  Pressable,
  StyleProp,
  TextInput,
  TextInputProps,
  View,
} from 'react-native';

import {IIconProps} from '@/components/Icon';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import spacing from '@/constants/spacing';
import {useTheme, useThemedStyles} from '@/theme';

import {styles} from './Input.style';

export interface IInputProps extends TextInputProps {
  placeholder?: string;
  placeholderId?: string;
  multiline?: boolean;
  style?: StyleProp<any>;
  inputStyle?: TextInputProps['style'];
  prefixIcon?: IIconProps;
  prefixComponent?: ReactNode;
  suffixComponent?: ReactNode;
  footerComponent?: ReactNode;
  scrollToOffset?: number;
}

const Input = forwardRef<TextInput, IInputProps>(
  (
    {
      value,
      onChangeText,
      placeholder,
      placeholderId,
      multiline = false,
      style: styleProp,
      inputStyle,
      prefixIcon,
      prefixComponent,
      suffixComponent,
      footerComponent,
      scrollToOffset = spacing.s * 2,
      ...inputProps
    },
    ref,
  ) => {
    const style = useThemedStyles(styles);
    const theme = useTheme();
    const {formatMessage} = useIntl();
    const inputRef = useRef<TextInput>(null);

    useImperativeHandle(ref, () => inputRef.current!);

    return (
      <Pressable
        style={[style.container, styleProp]}
        onPress={() => {
          inputRef.current?.focus();
        }}>
        {prefixComponent}
        {prefixIcon && <Icon {...prefixIcon} />}
        <View style={style.inputWrapper}>
          <TextInput
            ref={inputRef}
            style={[
              style.input,
              {
                paddingBottom: scrollToOffset,
                marginBottom: -scrollToOffset,
              },
              !!value && style.inputActive,
              multiline && style.inputMultiline,
              inputStyle,
            ]}
            value={value}
            autoCapitalize="none"
            onChangeText={onChangeText}
            placeholder={
              placeholderId ? formatMessage({id: placeholderId}) : placeholder
            }
            placeholderTextColor={theme.colors.textColor}
            multiline={multiline}
            textAlignVertical={multiline ? 'top' : undefined}
            {...inputProps}
          />
          {footerComponent}
        </View>
        {suffixComponent}
      </Pressable>
    );
  },
);

export default Input;
