import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  card: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  imageWrapper: {
    ...StyleSheet.absoluteFillObject,
    overflow: 'hidden',
    borderRadius: theme.box.borderRadius,
  },
  image: {
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
}));
