import {
  RampInstantEventTypes,
  RampInstantSDK,
} from '@ramp-network/ramp-instant-sdk';
import {IPurchaseCreatedEvent} from '@ramp-network/ramp-instant-sdk/dist/types/types';

import {getSharedRampParams} from '@/modules/TopUp/ramp/shared';
import {IRampService} from '@/modules/TopUp/types';

export const Ramp: IRampService = {
  topUp: params => {
    // @ts-ignore
    const ramp = new RampInstantSDK({
      ...getSharedRampParams(params),
    });

    ramp.show();

    ramp.on(
      RampInstantEventTypes.PURCHASE_CREATED,
      (event: IPurchaseCreatedEvent) => {
        const id = event.payload.purchase.id;
        const token = event.payload.purchaseViewToken;

        params.onPurchaseCreated({id, token});
      },
    );
  },
};
