import {config} from '@/constants/config';

export const getAudioUrl = (audioIpfsHash?: string | null) =>
  audioIpfsHash
    ? `${config.IPFS_GATEWAY_URL_AUDIO}${audioIpfsHash}`
    : undefined;

export const getImageUrl = (
  imageIpfsHash?: string | null,
  fallbackUrl?: string,
  lossyArtworkMimeType?: string | null,
) => {
  if (!imageIpfsHash) {
    return fallbackUrl;
  }

  if (lossyArtworkMimeType && lossyArtworkMimeType.includes('video')) {
    return `${config.IPFS_GATEWAY_URL_IMAGE_FROM_VIDEO}${imageIpfsHash}`;
  }

  return `${config.IPFS_GATEWAY_URL_IMAGE}${imageIpfsHash}`;
};
