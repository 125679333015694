import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    flex: 1,
  },
  menu: {
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  content: {
    flex: 1,
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000',
  },
}));
