import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    padding: spacing.l,
    gap: spacing.l,
  },
  icon: {
    alignSelf: 'center',
  },
  textsContainer: {
    gap: spacing.xs,
  },
}));
