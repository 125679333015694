import {useInfiniteQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchTipsHistory} from '@/modules/Tip/utils';
import {QueryKeys} from '@/types/queryKeys';
import {flattenItems, getNextPageParam} from '@/utils/pagination';

export const useTipsHistory = (artistId?: string) => {
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.tipsHistory, artistId],
    queryFn: ({pageParam}) => fetchTipsHistory(artistId!, pageParam),
    enabled: !!artistId,
    initialPageParam: undefined,
    getNextPageParam,
  });

  const tipsHistory = useMemo(() => flattenItems(query.data), [query.data]);

  return {
    tipsHistory,
    query,
  };
};
