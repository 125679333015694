import React, {FC, ReactNode} from 'react';
import {StyleProp, View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './ShadowCard.style';

interface IProps {
  children: ReactNode;
  style?: StyleProp<any>;
}
const ShadowCard: FC<IProps> = ({children, style: customStyle}) => {
  const style = useThemedStyles(styles);

  return <View style={[style.card, customStyle]}>{children}</View>;
};

export default ShadowCard;
