import {useNavigation} from '@react-navigation/native';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {encodeFunctionData, parseEther} from 'viem';
import {useBalance} from 'wagmi';

import {useActiveUser, useUserById} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {tipAddress, tipChain} from '@/modules/Tip/constants';
import {ISendTipTransactionState} from '@/modules/Tip/types';
import {getSendTipWriteContractInput} from '@/modules/Tip/utils';
import {
  useGasEstimation,
  useSyncTransactionState,
} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {updateTransaction} from '@/store/transactions/slice';
import {IAddress, IArtist, IBalance} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {generateId} from '@/utils/functions';
import {getUserPaymentWallet} from '@/utils/user';

export interface ITipStateContext {
  tipTransaction: ISendTipTransactionState;
  updateTip: (update: Partial<ISendTipTransactionState>) => void;
  resetTransactionDetails: () => void;
  artist: IArtist;
  user: IUser | undefined;
  paymentWallet: IAddress | undefined;
  balance: IBalance | undefined;
  gasEstimation: IGasEstimation;
  close: () => void;
}

export const TipStateContext = createContext<ITipStateContext | undefined>(
  undefined,
);

interface IProps {
  transactionId: string | undefined;
  artist: IArtist;
  children: ReactNode;
}
const SendTipProvider: FC<IProps> = ({transactionId, artist, children}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const activeUser = useActiveUser();

  const {transaction: tipTransaction} = useSyncTransactionState(
    transactionId,
    'sendTip',
    () =>
      ({
        createdDate: Date.now(),
        type: 'sendTip',
        id: generateId(),
        isMinimized: false,
        artistSlug: artist.slug,
        userId: activeUser?.id,
        paymentWalletAddress:
          activeUser && getUserPaymentWallet(activeUser)?.address,
        amount: '',
        step: 'checkout',
        txHash: undefined,
        userOpHash: undefined,
        error: undefined,
      } satisfies ISendTipTransactionState),
  );

  const user = useUserById(tipTransaction.userId);

  const updateTip = useCallback(
    (update: Partial<ISendTipTransactionState>) => {
      dispatch(
        updateTransaction({id: tipTransaction.id, type: 'sendTip', update}),
      );
    },
    [tipTransaction.id],
  );

  const resetTransactionDetails = () =>
    updateTip({
      txHash: undefined,
      userOpHash: undefined,
      error: undefined,
    });

  const paymentWallet = useMemo(
    () =>
      user?.addresses.find(
        a => a.address === tipTransaction.paymentWalletAddress,
      ),
    [user, tipTransaction.paymentWalletAddress],
  );

  useEffect(() => {
    if (activeUser && !tipTransaction.userId) {
      updateTip({
        userId: activeUser.id,
        paymentWalletAddress: getUserPaymentWallet(activeUser)?.address,
      });
    }
  }, [activeUser?.id, tipTransaction.id, updateTip]);

  const {data: balance} = useBalance({
    address: paymentWallet?.address,
    chainId: tipChain.id,
    query: {
      enabled: !!paymentWallet,
    },
  });

  const gasEstimation = useGasEstimation(
    {
      chainId: tipChain.id,
      wallet: paymentWallet,
      transaction: {
        to: tipAddress,
        data: encodeFunctionData(
          // use whatever address for estimation
          getSendTipWriteContractInput(tipAddress),
        ),
        value: parseEther(tipTransaction.amount).toString(),
      },
      onError: error => {
        Sentry.captureException(error, {
          tags: {
            tip: true,
            simulation: true,
            passkeyWallet: !!paymentWallet && isPasskeyWallet(paymentWallet),
          },
          extra: {
            artistId: artist.id,
            wallet: paymentWallet,
          },
        });
      },
    },
    parseEther(tipTransaction.amount) > 0,
  );

  return (
    <TipStateContext.Provider
      value={{
        tipTransaction,
        updateTip,
        resetTransactionDetails,
        artist,
        user,
        paymentWallet,
        balance,
        gasEstimation,
        close: () => navigation.goBack(),
      }}>
      {children}
    </TipStateContext.Provider>
  );
};

export default SendTipProvider;
