import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React from 'react';

import Search from '@/screens/Search/Search';
import {Routes, SearchStackParams} from '@/types/routes';

const Stack = createNativeStackNavigator<SearchStackParams>();

const SearchNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={Routes.Search}>
      <Stack.Screen name={Routes.Search} component={Search} />
    </Stack.Navigator>
  );
};

export default SearchNavigation;
