import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const ESTIMATED_ITEM_SIZE = spacing.s * 2 + spacing.s;
const BADGE_SIZE = spacing.xs;

export const styles = createThemedStyles(theme => ({
  item: {
    padding: 0,
    borderWidth: 0,
    gap: spacing.xs,
    height: 'auto',
  },
  statusRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  unreadBadge: {
    backgroundColor: theme.colors.active,
    width: BADGE_SIZE,
    height: BADGE_SIZE,
    borderRadius: BADGE_SIZE / 2,
  },
}));
