import {useRef, useState, Dispatch, SetStateAction} from 'react';

type UseBooleanStateReturn = [
  boolean,
  () => void,
  () => void,
  Dispatch<SetStateAction<boolean>>,
];

export const useBooleanState = (
  initialValue = false,
): UseBooleanStateReturn => {
  const [booleanValue, setBooleanValue] = useState(initialValue);
  const setToTrue = useRef(() => setBooleanValue(true)).current;
  const setToFalse = useRef(() => setBooleanValue(false)).current;

  return [booleanValue, setToTrue, setToFalse, setBooleanValue];
};
