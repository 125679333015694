export const getVisibleItemsCount = (
  visibleSections: number,
  isMobile: boolean,
) => {
  if (isMobile) {
    if (visibleSections < 2) {
      return 10;
    }

    if (visibleSections === 2) {
      return 4;
    }

    return 2;
  }

  if (visibleSections < 2) {
    return 20;
  }

  if (visibleSections === 2) {
    return 7;
  }

  return 3;
};
