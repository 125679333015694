import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  balanceCard: {
    padding: 0,
  },
  balanceInfo: {
    padding: spacing.s,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.s,
  },
  pendingColumn: {
    gap: spacing.xxs / 2,
  },
  divider: {
    opacity: 0.1,
  },
  balanceCardActions: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: spacing.xs * 1.5,
  },
}));
