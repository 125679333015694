// When fetching user follows from firebase, we don't have full information about followed entities like artists, collectors and playlists.
// Wy only have their ids and we need to fetch full information to present data correctly.
// This file contains set of hooks handling fetching full info about followed entities and merging them with follow info fetched from firebase.

import {uniqBy} from 'lodash';
import {useMemo} from 'react';

import {useUserPlaylists} from '@/hooks/useUserPlaylists';
import {useArtistsByIdsQuery} from '@/queries/artists';
import {useUsersByIdsQuery} from '@/queries/user';
import {
  IPlaylistWithFollowedArtist,
  IPlaylistWithFollowedUser,
} from '@/screens/Library/types';
import {PlaylistType} from '@/types/playlists';
import {isNotNil} from '@/utils/functions';

export const useFollowedArtistPlaylists = () => {
  const playlists = useUserPlaylists(PlaylistType.artist);
  const {artists: followedArtists, query} = useArtistsByIdsQuery(
    playlists.map(playlist => playlist.artistId!),
  );

  const followedArtistPlaylists: IPlaylistWithFollowedArtist[] = useMemo(
    () =>
      uniqBy(
        playlists
          .map(playlist => {
            const followedArtist = followedArtists.find(
              a => a.id === playlist.artistId,
            );

            if (!followedArtist) {
              return null;
            }

            return {
              followedArtist,
              ...playlist,
            };
          })
          .filter(isNotNil),
        'artistId',
      ),
    [playlists, followedArtists],
  );

  return {
    followedArtistPlaylists,
    isEmpty: followedArtistPlaylists.length === 0,
    query,
  };
};

export const useFollowedCollectorsPlaylists = () => {
  const playlists = useUserPlaylists(PlaylistType.followedCollection);
  const followedUsersIds = playlists.map(p => p.followedCollectionAddress!);

  const {users: followedUsers, query} = useUsersByIdsQuery(followedUsersIds);

  const followedCollectorsPlaylists: IPlaylistWithFollowedUser[] = useMemo(
    () =>
      playlists
        .map(playlist => {
          const followedUser = followedUsers.find(
            u => u.id === playlist.followedCollectionAddress,
          );

          if (!followedUser) {
            return null;
          }

          return {
            followedUser,
            ...playlist,
          };
        })
        .filter(isNotNil),
    [playlists, followedUsers],
  );

  return {
    followedCollectorsPlaylists,
    isEmpty: followedCollectorsPlaylists.length === 0,
    query,
  };
};
