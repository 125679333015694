import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon, {IIconProps} from '@/components/Icon';
import {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {CHECK_ICON_SIZE, styles} from './CategoryTile.style';

interface IProps {
  text: TextProps;
  isSelected: boolean;
  onChange: () => void;
  icon: IIconProps;
}

const CategoryTile: FC<IProps> = ({text, isSelected, onChange, icon}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.category}>
      <CardButton
        style={[
          style.categoryButton,
          isSelected && style.categoryButton_selected,
        ]}
        onPress={onChange}
        text={text}
        icon={{
          size: 20,
          ...icon,
        }}
      />
      {isSelected && (
        <View style={style.selectedIndicator}>
          <Icon provider="custom" name="checked" size={CHECK_ICON_SIZE} />
        </View>
      )}
    </View>
  );
};

export default CategoryTile;
