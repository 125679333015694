import React, {FC, useState} from 'react';
import {View} from 'react-native';
import {parseEther} from 'viem';

import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import PriceInput from '@/components/PriceInput';
import ScrollContainer from '@/components/ScrollContainer';
import ShadowFooter from '@/components/ShadowFooter';
import {PriceSummary, useTransactionValidation} from '@/modules/Transactions';
import {
  useBalanceValidation,
  useChainValidation,
  useIsWalletConnectedValidation,
} from '@/modules/Transactions';
import DeliveryWalletInfo from '@/modules/Transfer/components/DeliveryWalletInfo';
import PendingTransfer from '@/modules/Transfer/components/PendingTransfer';
import SenderWalletInfo from '@/modules/Transfer/components/SenderWalletInfo';
import {useCryptoTransferState} from '@/modules/Transfer/CryptoTransfer/useCryptoTransferState';
import {useCryptoTransferTransaction} from '@/modules/Transfer/CryptoTransfer/useCryptoTransferTransaction';
import {useDeliverWalletValidation} from '@/modules/Transfer/validation/useDeliveryWalletValidation';
import {WalletPicker} from '@/modules/Wallets';
import {useThemedStyles} from '@/theme';
import {isNotNil} from '@/utils/functions';

import {styles} from './CryptoTransferCheckout.style';

const CryptoTransferCheckout: FC = () => {
  const style = useThemedStyles(styles);

  const {
    transfer,
    updateTransfer,
    user,
    senderWallet,
    balance,
    deliveryWallet,
    gasEstimation,
  } = useCryptoTransferState();
  const [isWalletPickerOpen, setIsWalletPickerOpen] = useState(false);

  const {executeTransfer} = useCryptoTransferTransaction();

  const WrongDeliveryWalletError = useDeliverWalletValidation(
    transfer.from,
    deliveryWallet,
  );
  const ConnectedWalletError = useIsWalletConnectedValidation({
    wallet: senderWallet,
  });
  const WrongChainError = useChainValidation({
    wallet: senderWallet,
    chainId: transfer.chainId,
  });
  const InsufficientBalanceError = useBalanceValidation({
    wallet: senderWallet,
    balance,
    price: parseEther(transfer.amount) + (gasEstimation.totalGas || BigInt(0)),
    chainId: transfer.chainId,
  });
  const InvalidTransactionError = useTransactionValidation(gasEstimation);

  const validationError = [
    WrongDeliveryWalletError,
    ConnectedWalletError,
    WrongChainError,
    InsufficientBalanceError,
    InvalidTransactionError,
  ].filter(isNotNil)[0];

  return (
    <>
      <ScrollContainer contentStyle={style.content}>
        <SenderWalletInfo senderWallet={senderWallet} from={transfer.from} />

        <Divider />

        <PriceInput
          value={transfer.amount}
          onChange={(value: string) => updateTransfer({amount: value})}
          chainId={transfer.chainId}
          editable={transfer.step === 'checkout'}
          balance={balance}
          showUsd
        />

        <Divider />

        <DeliveryWalletInfo
          transferStep={transfer.step}
          deliveryWallet={deliveryWallet}
          deliveryWalletAddress={transfer.to}
          onChangeWallet={() => setIsWalletPickerOpen(true)}
        />

        <PendingTransfer
          transferStep={transfer.step}
          txHash={transfer.txHash}
          userOpHash={transfer.userOpHash}
          chainId={transfer.chainId}
          senderWallet={senderWallet}
        />
      </ScrollContainer>
      <ShadowFooter style={style.footer}>
        <PriceSummary
          priceLabelId="transfer.amount"
          price={
            parseEther(transfer.amount) + (gasEstimation.totalGas || BigInt(0))
          }
          gasEstimation={gasEstimation}
          chainId={transfer.chainId}
        />

        {validationError?.message && (
          <View style={style.validationError}>{validationError.message}</View>
        )}

        {validationError ? (
          validationError.action
        ) : (
          <CardButton
            disabled={
              !transfer.amount ||
              !senderWallet ||
              !!validationError ||
              gasEstimation.isFetching ||
              !gasEstimation.totalGas
            }
            text={{id: 'transfer.send'}}
            loaderProps={{textId: 'transfer.processing'}}
            isLoading={transfer.step !== 'checkout'}
            onPress={() => {
              if (senderWallet) {
                executeTransfer(senderWallet);
              }
            }}
            icon={{name: 'transfer', provider: 'custom'}}
          />
        )}
      </ShadowFooter>

      {isWalletPickerOpen && user && (
        <WalletPicker
          titleId="transfer.walletPicker.title"
          isOpen
          user={user}
          selectedWalletAddress={transfer.to}
          excludedAddress={transfer.from}
          onConfirm={address => updateTransfer({to: address})}
          chainId={transfer.chainId}
          onClose={() => setIsWalletPickerOpen(false)}
          allowAddExternalWallet
          allowCustomAddress
          avoidKeyboard
        />
      )}
    </>
  );
};

export default CryptoTransferCheckout;
