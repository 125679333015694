import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {Pressable, View} from 'react-native';

import IconButton from '@/components/IconButton';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useAppSelector} from '@/hooks/useRedux';
import {useFeedItemMutation} from '@/queries/feed';
import {useFeedItemWithQueue} from '@/screens/Feed/components/FeedCard/useFeedItemWithQueue';
import {createFeedItemForTrack} from '@/screens/Feed/utils';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IFeedItemWithArtist} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';

import {styles} from './ArtistFeedCardTrack.style';

interface IProps {
  track: ITrack;
  index: number;
  feedItem: IFeedItemWithArtist;
  onPress: (index: number) => void;
  isActive: boolean;
}

const ArtistFeedCardTrack: FC<IProps> = ({
  track,
  index,
  feedItem,
  onPress,
  isActive,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const {isInQueue, toggleTrackInQueue, isCurrentlyPlaying} =
    useFeedItemWithQueue({
      feedItem,
      trackId: track.id,
    });

  const userId = useAppSelector(selectActiveUserId)!;

  const {mutate: upsertFeedItem} = useFeedItemMutation();

  return (
    <Pressable
      onPress={() => onPress(index)}
      onLongPress={() => {
        navigation.navigate(Routes.TrackModal, {slug: track.slug});
      }}
      key={track.id}
      style={({pressed}) => [style.track, pressed && style.track_pressed]}>
      {/* @ts-ignore */}
      {({hovered}) => (
        <>
          <View
            style={[
              style.trackBackground,
              hovered && style.trackBackground_hover,
              isActive && style.trackBackground_active,
            ]}
          />
          <View style={style.trackContent}>
            <Text
              size="xs"
              color="invertedTextColor"
              weight={isActive ? 'semibold' : 'regular'}
              numberOfLines={1}
              flex>
              {track.title}
            </Text>

            {isActive && (
              <IconButton
                onPress={() => {
                  toggleTrackInQueue();

                  if (!isInQueue) {
                    upsertFeedItem(
                      createFeedItemForTrack(userId!, track, 'like'),
                    );
                  }
                }}
                disabled={isCurrentlyPlaying}
                size={spacing.l}
                rippleDisabled
                icon={{
                  name: isInQueue ? 'check' : 'add',
                  provider: 'custom',
                  color: 'invertedTextColor',
                }}
              />
            )}
            {isActive && (
              <IconButton
                onPress={() => {
                  navigation.navigate(Routes.TrackModal, {
                    slug: track.slug,
                  });
                }}
                size={spacing.l}
                rippleDisabled
                icon={{
                  name: 'info',
                  provider: 'custom',
                  color: 'invertedTextColor',
                }}
              />
            )}
          </View>
        </>
      )}
    </Pressable>
  );
};

export default React.memo(ArtistFeedCardTrack);
