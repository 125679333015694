import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  banner: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.primary,
  },
  imageWrapper: {
    borderRadius: 25,
    overflow: 'hidden',
  },
  logo: {
    width: spacing.l,
    height: spacing.l,
  },
}));
