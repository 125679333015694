import spacing from '@/constants/spacing';

export const createItemLayoutGetter =
  ({
    itemHeight,
    itemSpacing = spacing.xs,
    headerHeight = 0,
  }: {
    itemHeight: number;
    itemSpacing?: number;
    headerHeight?: number;
  }) =>
  (data: unknown, index: number) => ({
    length: itemHeight + itemSpacing,
    offset: (itemHeight + itemSpacing) * index + headerHeight,
    index,
  });
