import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Loader from '@/components/Loader';
import SpinLoader from '@/components/SpinLoader';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {UploadStep} from '@/modules/DropOnSpinamp/types';
import {useThemedStyles} from '@/theme';
import {IUser} from '@/types/user';
import {noop} from '@/utils/functions';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './UploadProcessing.style';

interface IProps {
  user: IUser;
  title: string;
  artistName: string;
  artworkUrl: string;
  uploadStep: UploadStep;
}

const UploadProcessing: FC<IProps> = ({
  title,
  artistName,
  artworkUrl,
  uploadStep,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.processingContainer}>
      <View style={style.processingTrackTile}>
        <StaticImage source={{uri: artworkUrl}} style={style.artwork} />
        <View style={style.trackInfo}>
          <Text weight="semibold" numberOfLines={1}>
            {title}
          </Text>
          <Text numberOfLines={1} size="xs">
            {artistName}
          </Text>
        </View>
        <SpinLoader size={spacing.l} />
      </View>

      {uploadStep === 'submitting' && (
        <Text
          weight="semibold"
          align="center"
          id="dropOnSpinamp.step.submitting"
        />
      )}
      {uploadStep === 'waitingForSignature' && (
        <Text
          weight="semibold"
          align="center"
          id="dropOnSpinamp.step.waitingForSignature"
        />
      )}
      {uploadStep === 'waitingForPasskey' && (
        <Text
          weight="semibold"
          align="center"
          id="dropOnSpinamp.step.waitingForPasskey"
        />
      )}
      {uploadStep === 'uploading' && (
        <Loader
          textId="dropOnSpinamp.step.uploading"
          size="s"
          weight="semibold"
          align="center"
        />
      )}

      <CardButton
        onPress={noop}
        isLoading
        loaderProps={{textId: 'dropOnSpinamp.processing'}}
      />
    </View>
  );
};

export default UploadProcessing;
