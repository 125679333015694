import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import {View, Pressable} from 'react-native';
import {Gesture, GestureDetector} from 'react-native-gesture-handler';
import Animated, {
  interpolate,
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import {useThemedStyles} from '@/theme';
import {isNative, isWeb} from '@/utils/platform';

import {styles} from './Drawer.style';

interface IProps {
  width: number;
  renderDrawerContent: () => ReactNode;
  swipeEdgeWidth: number;
  children: ReactNode;
}

export interface IDrawerRef {
  open: () => void;
  close: () => void;
}

const AnimationTime = 300;

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

const calculateDuration = (distanceLeft: number, velocity: number) => {
  'worklet';
  const time = (distanceLeft * 1000) / Math.abs(velocity);
  return Math.min(time, AnimationTime);
};

const Drawer = forwardRef<IDrawerRef, IProps>(
  ({width, renderDrawerContent, swipeEdgeWidth, children}, ref) => {
    const style = useThemedStyles(styles);

    const [isOpen, setIsOpen] = useState(false);
    const translateX = useSharedValue(isOpen ? width : 0);
    const gestureDirection = useSharedValue<'left' | 'right'>('right');

    const open = useCallback(() => {
      setIsOpen(true);
      translateX.value = withTiming(width, {duration: AnimationTime});
    }, [width]);

    const close = useCallback(() => {
      setIsOpen(false);
      translateX.value = withTiming(0, {duration: AnimationTime});
    }, []);

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    const containerStyle = useAnimatedStyle(() => ({
      transform: [{translateX: translateX.value}],
    }));

    const backdropStyle = useAnimatedStyle(
      () => ({
        opacity: interpolate(translateX.value, [0, width], [0, 0.7]),
      }),
      [width],
    );

    const swipeGesture = Gesture.Pan()
      .enabled(isNative)
      .minDistance(0)
      .hitSlop(isOpen ? undefined : {left: 0, width: swipeEdgeWidth})
      .onUpdate(e => {
        const newValue = isOpen ? e.translationX + width : e.translationX;

        gestureDirection.value =
          newValue >= translateX.value ? 'right' : 'left';

        if (newValue >= 0 && newValue <= width) {
          translateX.value = newValue;
        }
      })
      .onEnd(event => {
        if (gestureDirection.value === 'right') {
          const distanceLeft = width - translateX.value;
          translateX.value = withTiming(width, {
            duration: calculateDuration(distanceLeft, event.velocityX),
          });
          runOnJS(setIsOpen)(true);
        } else {
          const distanceLeft = translateX.value;
          translateX.value = withTiming(0, {
            duration: calculateDuration(distanceLeft, event.velocityX),
          });
          runOnJS(setIsOpen)(false);
        }
      });

    return (
      <GestureDetector gesture={swipeGesture}>
        <Animated.View style={[style.container, containerStyle]}>
          <View
            style={[
              style.menu,
              {
                left: -width,
                width,
              },
            ]}>
            {renderDrawerContent()}
          </View>
          <View style={style.content}>
            {children}
            <AnimatedPressable
              style={[
                style.backdrop,
                {
                  pointerEvents: isOpen ? 'auto' : isWeb ? 'none' : 'box-none',
                },
                backdropStyle,
              ]}
              onPress={close}
            />
          </View>
        </Animated.View>
      </GestureDetector>
    );
  },
);

export default Drawer;
