import React, {FC} from 'react';
import {ReactNode} from 'react';
import {ScrollView, View} from 'react-native';

import Button from '@/components/Button';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import OpacityGradient from '@/components/OpacityGradient';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useFeedItemMutation, useGenerateFeedMutation} from '@/queries/feed';
import {useFeedOnboardingData} from '@/screens/Feed/useFeedOnboarding';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {GENRES_WITHOUT_ORIGINALS, Genre, IRefillFeedItem} from '@/types/feed';
import {analytics} from '@/utils/analytics';
import {sleep} from '@/utils/functions';

import BaseFeedCard from './BaseFeedCard';
import {styles} from './common.styles';

export interface IGenresFeedCardProps {
  item: IRefillFeedItem;
  onEnd?: () => void;
  genres?: Genre[];
  feature: ReactNode;
}

// bigger than 5 to avoid the "few tracks left" toast
// smaller than 15 (current page size)
// smallish to avoid hijacking the feed
const NUM_REFILL_ITEMS = 7;

function getGenreGeneratorConfig(genre: string) {
  return [
    {
      name: 'genre-artists',
      weight: 1,
      args: [genre],
    },
    {
      name: 'genre',
      weight: 6,
      args: [genre],
    },
  ];
}

export function useGenerateGenreFeed(
  onEnd?: () => void,
  insertAtPosition?: number,
  limit?: number,
) {
  const toast = useToast();
  const activeUserId = useAppSelector(selectActiveUserId);

  const {generateFeed, mutation} = useGenerateFeedMutation(
    activeUserId,
    undefined,
    insertAtPosition,
    limit,
  );

  const generateGenre = (genre: string) =>
    generateFeed(getGenreGeneratorConfig(genre), {
      onSuccess: (generatedFeedItemsCount, _variables, _context) => {
        if (generatedFeedItemsCount === 0) {
          toast.showToast({
            textId: 'feed.genreRefill.noMoreTracks',
            values: {genre},
          });
        } else {
          if (generatedFeedItemsCount <= 5) {
            toast.showToast({
              textId: 'feed.genreRefill.fewTracksLeft',
              values: {genre, count: generatedFeedItemsCount},
            });
          }
          // sleep for a bit to allow the new feed items to be rendered
          setTimeout(() => {
            onEnd?.();
          }, 500);
        }
      },
      onError: (_error, _variables, _options) => {
        toast.showToast({
          textId: 'feed.genreRefill.error',
        });
      },
    });

  return {generateGenre, mutation};
}

interface IBaseGenresFeedCardProps extends IGenresFeedCardProps {
  onClose?: () => void;
  onGenrePress: (genre: string) => void;
  isLoading?: (genre: string) => boolean;
}

export const BaseGenresFeedCard: React.FC<IBaseGenresFeedCardProps> = ({
  feature,
  genres = GENRES_WITHOUT_ORIGINALS,
  onClose,
  onGenrePress,
  isLoading,
}) => {
  const style = useThemedStyles(styles);

  return (
    <BaseFeedCard>
      {onClose && (
        <IconButton
          onPress={onClose}
          icon={{
            provider: 'custom',
            name: 'visibilityOff',
          }}
          style={style.dismissButton}
        />
      )}

      <ScrollView
        contentContainerStyle={style.genreContainer}
        showsVerticalScrollIndicator={false}>
        {feature}
        {genres.map(g => (
          <Button
            key={g.key}
            isLoading={isLoading?.(g.key) || false}
            text={g.text}
            textProps={{weight: 'regular', size: 's'}}
            loaderTextProps={{weight: 'regular', size: 's'}}
            onPress={() => onGenrePress(g.key)}
            style={style.refillButton}
          />
        ))}
      </ScrollView>
      <View style={style.gradientContainer}>
        <OpacityGradient
          color={'background'}
          style={style.gradient}
          vertical={true}
          y2={95}
        />
      </View>
    </BaseFeedCard>
  );
};

const GenresFeedCard: React.FC<IGenresFeedCardProps> = ({
  item,
  onEnd,
  feature,
  genres = GENRES_WITHOUT_ORIGINALS,
}) => {
  const {mutation, generateGenre} = useGenerateGenreFeed(
    onEnd,
    item?.position,
    NUM_REFILL_ITEMS,
  );

  const {mutate: upsertFeedItem} = useFeedItemMutation();

  return (
    <BaseGenresFeedCard
      onClose={() => {
        upsertFeedItem({
          ...item,
          userAction: 'hide',
        });
        analytics.feedItemReacted(item.id, 'hide', item.entityType);
      }}
      onGenrePress={generateGenre}
      item={item}
      feature={feature}
      genres={genres}
      isLoading={genre =>
        mutation.isPending &&
        !!mutation.variables?.flatMap(it => it.args).includes(genre)
      }
    />
  );
};

export const OnboardingGenresFeedCard: React.FC<IGenresFeedCardProps> = ({
  item,
  onEnd,
  feature,
  genres = GENRES_WITHOUT_ORIGINALS,
}) => {
  const {refillWithGenreMutation} = useFeedOnboardingData(item.userId);

  return (
    <BaseGenresFeedCard
      onGenrePress={async genre => {
        try {
          await refillWithGenreMutation.mutateAsync(genre);
          await sleep(500);
          onEnd?.();
        } catch (e) {
          // Catch handled in mutation definition
        }
      }}
      item={item}
      feature={feature}
      genres={genres}
      isLoading={genre =>
        refillWithGenreMutation.isPending &&
        refillWithGenreMutation.variables === genre
      }
    />
  );
};

export const GenreRefillFeature: FC<{isOnboarding?: boolean}> = ({
  isOnboarding = false,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.featureContainer}>
      <Space pt="xs" />
      <Icon
        name="personCelebrate"
        provider="custom"
        size={28}
        style={style.featureIcon}
      />
      <Space p="xs">
        <Text
          id={isOnboarding ? 'feed.refill.title' : 'feed.refill.onboarding'}
          weight="semibold"
          style={style.centeredText}
          size="l"
        />
      </Space>
      {isOnboarding && (
        <Text
          id={'feed.refill.description'}
          style={style.centeredText}
          size="xs"
          weight="regular"
        />
      )}
      <Space pt="xs" />
    </View>
  );
};

export default GenresFeedCard;
