import {useNavigation} from '@react-navigation/native';
import {createContext, FC, ReactNode} from 'react';
import {parseEther} from 'viem';
import {useBalance} from 'wagmi';

import {useUserById} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {
  useGasEstimation,
  useSyncTransactionState,
} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {ICryptoTransferState, ITransferParams} from '@/modules/Transfer';
import {getDefaultDeliveryAddress} from '@/modules/Transfer/utils';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {updateTransaction} from '@/store/transactions/slice';
import {IAddress, IBalance} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {generateId} from '@/utils/functions';

export interface ICryptoTransferStateContext {
  transfer: ICryptoTransferState;
  updateTransfer: (update: Partial<ICryptoTransferState>) => void;
  user?: IUser;
  senderWallet?: IAddress;
  balance?: IBalance;
  deliveryWallet?: IAddress;
  close: () => void;
  gasEstimation: IGasEstimation;
  resetTransferDetails: () => void;
}

export const CryptoTransferStateContext = createContext<
  ICryptoTransferStateContext | undefined
>(undefined);

interface IProps {
  transferId: string | undefined;
  initialUser?: IUser;
  initialTransferParams: ITransferParams;
  children: ReactNode;
}

const TransferStateProvider: FC<IProps> = ({
  transferId,
  initialUser,
  initialTransferParams,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {transaction: transfer} = useSyncTransactionState(
    transferId,
    'cryptoTransfer',
    () =>
      ({
        id: generateId(),
        createdDate: Date.now(),
        isMinimized: false,
        type: 'cryptoTransfer',
        userId: initialUser?.id,
        step: 'checkout',
        from: initialTransferParams.from,
        to: getDefaultDeliveryAddress(
          initialUser,
          initialTransferParams.from,
          initialTransferParams.to,
        ),
        chainId: initialTransferParams.chainId,
        amount: '',
        txHash: undefined,
        userOpHash: undefined,
        error: undefined,
      } satisfies ICryptoTransferState),
  );

  const amount = transfer.amount;

  const user = useUserById(transfer.userId);

  const senderWallet = user?.addresses.find(a =>
    areAddressesEqual(a.address, transfer.from),
  );

  const deliveryWallet = user?.addresses.find(a =>
    areAddressesEqual(a.address, transfer.to),
  );

  const gasEstimation = useGasEstimation(
    {
      chainId: transfer.chainId,
      wallet: senderWallet,
      transaction: transfer.to
        ? {
            to: transfer.to,
            value: parseEther(transfer.amount).toString(),
          }
        : undefined,
      onError: error => {
        Sentry.captureException(error, {
          tags: {
            cryptoTransfer: true,
            simulation: true,
            passkeyWallet: !!senderWallet && isPasskeyWallet(senderWallet),
          },
          extra: {
            chainId: transfer.chainId,
            amount,
            wallet: senderWallet,
            to: transfer.to,
          },
        });
      },
    },
    transfer.step === 'checkout' && amount !== '',
  );

  const {data: balance} = useBalance({
    address: senderWallet?.address,
    chainId: transfer.chainId,
    query: {
      enabled: !!senderWallet,
    },
  });

  const updateTransfer = (update: Partial<ICryptoTransferState>) => {
    dispatch(
      updateTransaction({
        id: transfer.id,
        type: 'cryptoTransfer',
        update,
      }),
    );
  };

  return (
    <CryptoTransferStateContext.Provider
      value={{
        transfer,
        updateTransfer,
        user,
        senderWallet,
        balance,
        deliveryWallet,
        close: () => navigation.goBack(),
        gasEstimation,
        resetTransferDetails: () =>
          updateTransfer({
            txHash: undefined,
            userOpHash: undefined,
            error: undefined,
          }),
      }}>
      {children}
    </CryptoTransferStateContext.Provider>
  );
};

export default TransferStateProvider;
