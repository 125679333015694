import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './AvatarLayout.style';

interface IProps {
  avatar?: ReactNode;
  title: ReactNode;
  leftAction: ReactNode;
  rightAction: ReactNode;
}

const AvatarLayout: FC<IProps> = ({avatar, title, leftAction, rightAction}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      {avatar && <View style={style.avatarContainer}>{avatar}</View>}
      <View style={style.row}>
        <View style={style.rowActionsWrapper}>
          <View style={style.rowActions}>{leftAction}</View>
        </View>

        <View style={style.titleWrapper}>
          <View style={style.titleContent}>{title}</View>
        </View>

        <View style={style.rowActionsWrapper}>
          <View style={style.rowActions}>{rightAction}</View>
        </View>
      </View>
    </View>
  );
};

export default AvatarLayout;
