import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {useState} from 'react';

import {fetchArtistsWithTracksByIds} from '@/api/artist';
import {fetchTracksByIds} from '@/api/track';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useDbQuery} from '@/queries/db';
import {useUpdateUserMetadataMutation} from '@/queries/user';
import {spinampServicesApi} from '@/services/spinampServicesApi';
import {selectActiveUserId, setOnboardingInfo} from '@/store/user';
import {IArtist, ITrack} from '@/types/common';
import {
  CloseOnboardingStep,
  IFeedEntityType,
  IFeedItem,
  IFeedItemRaw,
  IFeedOnboardingConfig,
  ILocalMessageFeedItem,
} from '@/types/feed';
import {QueryKeys} from '@/types/queryKeys';
import {IUserMetadata} from '@/types/user';
import {analytics} from '@/utils/analytics';
import {flatChunkedArray, normalize} from '@/utils/functions';

const fetchOnboardingFeedItems = async (
  userId: string,
  generatorConfig: any[],
  limit?: number,
): Promise<{
  tracks: ITrack[];
  artists: IArtist[];
  feedItems: (IFeedItem | ILocalMessageFeedItem)[];
}> => {
  const items: IFeedItemRaw[] = await spinampServicesApi.post(
    `/feed/feed-generation/${userId}`,
    generatorConfig,
    {params: {mock: true, pageSize: limit}},
  );

  const trackIds = items
    .filter(item => item.entityType === 'track')
    .map(item => item.entityId);
  const artistIds = items
    .filter(item => item.entityType === 'artist')
    .map(item => item.entityId);

  const [tracks, artists] = await Promise.all([
    fetchTracksByIds(trackIds),
    fetchArtistsWithTracksByIds(artistIds),
  ]);

  const normalizedTracks = normalize(tracks, 'id');
  const normalizedArtists = normalize(artists, 'id');

  const feedItems = items
    .map(item => {
      if (item.entityType === IFeedEntityType.track) {
        return {
          ...item,
          track: normalizedTracks.items[item.entityId],
          entityType: IFeedEntityType.track,
        };
      }

      if (item.entityType === IFeedEntityType.artist) {
        return {
          ...item,
          artist: normalizedArtists.items[item.entityId],
          entityType: IFeedEntityType.artist,
        };
      }

      return item;
    })
    .reverse() as (IFeedItem | ILocalMessageFeedItem)[];

  return {
    tracks,
    artists,
    feedItems,
  };
};

export const useFeedOnboardingData = (userId: string) => {
  const dispatch = useAppDispatch();
  const {updateDb} = useDbQuery();
  const queryClient = useQueryClient();

  const queryKey = [QueryKeys.feedOnboarding];

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const {tracks, artists, feedItems} = await fetchOnboardingFeedItems(
        userId,
        [
          {
            name: 'onboarding',
            weight: 1,
            args: [],
          },
        ],
      );
      updateDb({tracks, artists});

      // If returned data is not the shape we expect we need to finish onboarding so user doesn't get stuck in broken flow
      if (
        [feedItems[0].entityType, feedItems[1].entityType].some(
          t => t !== IFeedEntityType.track,
        ) ||
        feedItems[2].entityType !== 'refill'
      ) {
        dispatch(
          setOnboardingInfo({key: 'isFeedOnboardingCompleted', value: true}),
        );
      }

      feedItems.splice(2, 0, {
        id: 'feedThemes',
        localMessageId: 'feedThemes',
        entityType: IFeedEntityType.localMessage,
      });

      return feedItems;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const feedItems = query.data;

  const refillWithGenreMutation = useMutation({
    networkMode: 'always',
    mutationFn: (genre: string) =>
      fetchOnboardingFeedItems(
        userId,
        [
          {
            name: 'genre-artists',
            weight: 1,
            args: [genre],
          },
        ],
        1,
      ),
    onSuccess: ({tracks, artists, feedItems: genreFeedItems}) => {
      updateDb({tracks, artists});

      // Escape onboarding if returned data is different from expected
      if (genreFeedItems[0]?.entityType !== IFeedEntityType.artist) {
        dispatch(
          setOnboardingInfo({key: 'isFeedOnboardingCompleted', value: true}),
        );
      }

      queryClient.setQueryData<(IFeedItem | ILocalMessageFeedItem)[]>(
        queryKey,
        (items = []) => {
          const updatedItems = [...items];
          const refillIndex = updatedItems.findIndex(
            item => item.entityType === IFeedEntityType.refill,
          );
          updatedItems.splice(refillIndex + 1, 0, genreFeedItems[0]);
          return updatedItems;
        },
      );
    },
    onError: () => {
      dispatch(
        setOnboardingInfo({key: 'isFeedOnboardingCompleted', value: true}),
      );
    },
  });

  const removeItem = (id: string) => {
    queryClient.setQueryData<IFeedItem[]>(queryKey, (items = []) =>
      items.filter(item => item.id !== id),
    );
  };

  return {
    query,
    feedItems,
    removeItem,
    refillWithGenreMutation,
    clear: () => {
      queryClient.removeQueries({queryKey});
    },
  };
};

export const useFeedOnboardingState = () => {
  const {saveOnboardingInfo} = useSaveFeedOnboarding();

  const [onboardingState, setOnboardingState] = useState<IFeedOnboardingConfig>(
    {
      track: ['play', 'add', 'remove'],
      artist: ['follow'],
      channels: ['info'],
    },
  );

  const closeOnboardingStep: CloseOnboardingStep = ({type, item}) => {
    const updatedState = {
      ...onboardingState,
      [type]: onboardingState[type].filter(i => i !== item),
    };

    setOnboardingState(updatedState);

    const isCompleted =
      flatChunkedArray(Object.values(updatedState)).length === 0;

    if (isCompleted) {
      saveOnboardingInfo();
    }
  };

  return {
    onboardingState,
    closeOnboardingStep,
  };
};

export const useSaveFeedOnboarding = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectActiveUserId);
  const activeUser = useActiveUser();
  const updateUserMetadataMutation = useUpdateUserMetadataMutation(true);

  const saveOnboardingInfo = () => {
    if (!userId) {
      return;
    }

    dispatch(
      setOnboardingInfo({key: 'isFeedOnboardingCompleted', value: true}),
    );

    updateUserMetadataMutation.mutate({
      user: activeUser || {id: userId, metadata: {} as IUserMetadata},
      metadataUpdate: {
        onboarding: {
          completedFeedOnboarding: true,
        },
      },
    });

    analytics.feedPopoverOnboardingCompleted();
  };

  return {
    saveOnboardingInfo,
  };
};
