import {SpinStaticConfig} from '@/constants/config/types';

export const staticConfig: SpinStaticConfig = {
  APP_NAME: 'Spinamp',
  APP_URL: 'spinamp',
  WEB_APP_URL: 'https://app.spinamp.xyz',
  WEB_PAGE_URL: 'https://spinamp.xyz',
  EMBED_BASE_URL: 'webapp.spinamp.xyz',
  DEV_PAGE_URL: 'https://dev.spinamp.xyz',
  EMBED_PAGE_URL: 'https://embedded.spinamp.xyz',
  BRANCH_APP_URL: 'https://spinamp.app.link',
  SPIN_APP_SHARE_URL: 'https://www.spinamp.xyz/app',
  SPINAMP_TWITTER_URL: 'https://twitter.com/spin_amp',
  SPINAMP_WARPCAST_URL: 'https://warpcast.com/spinamp',
  SPINAMP_DISCORD_URL: 'https://discord.gg/zXmSgWx7kQ',
  SPINAMP_FEEDBACK_URL: 'https://feedback.spinamp.xyz',
  ZORA_MEDIA_CONTRACT_ADDRESS: '0xabefbc9fd2f806065b4f3c237d4b59d9a97bcac7',
  ALCHEMY_API_KEY: 'Xs8mJOkbmo34AAUkRaTizGIuEG3BxfQT',
  WALLETCONNECT_PROJECT_ID: '04052d4d89dc7ad8240fa0565cb37213',
  PLAYLIST_TITLE_MAX_LENGTH: 30,
  PLAYLIST_DESCRIPTION_MAX_LENGTH: 1000,
  REQUEST_RETRY_COUNT: 3,
  IPFS_GATEWAY_URL_IMAGE:
    'https://content.spinamp.xyz/image/upload/ipfs_image/',
  IPFS_GATEWAY_URL_IMAGE_FROM_VIDEO:
    'https://content.spinamp.xyz/video/upload/ipfs_image/',
  IPFS_GATEWAY_URL_AUDIO:
    'https://content.spinamp.xyz/video/upload/ipfs_audio/',
  APP_STORE_ID: '1613783898',
  DEFAULT_SOCIAL_PREVIEW_IMAGE_URL:
    'https://www.spinamp.xyz/spinamp-preview.jpeg',
  RESERVOIR_API_URL: 'https://api.reservoir.tools',
  SPINAMP_ADDRESS: '0x4Ee23cf4bb62ae6F9176beeAf3b84E1437e4aabe',
  WEB_APP_ROOT_ID: 'spinamp-root',
  PINATA_UPLOAD_URL: 'https://api.pinata.cloud/pinning/pinFileToIPFS',
  PINATA_API_KEY:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiIyYmJiMjIyYS0xNjg5LTQzMWUtOTdkNi04YzBjNTA4NzViZDciLCJlbWFpbCI6ImFpZGFuQHNwaW5hbXAueHl6IiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImIyOGYzZGFhOGNhZjliN2Y5NmY3Iiwic2NvcGVkS2V5U2VjcmV0IjoiNWI3YTU4ZjY3OTE3ODI0MTkxZjgyYzczYTBiZTcyNjk3ZDFmNzhjMjFkZWQ4OWFlZDllMmQ3ZWU0MDhkNWIyOSIsImlhdCI6MTY5MjAzMzQ3OH0.b9pTEyj8JLGGXwgHsTt5efk8ewKaG0Y9cjR9b0ndbPs',
  WITHPAPER_CONTRACT_ID: 'dce12cc5-13c8-4a24-8951-6166d55808de',
  PIMLICO_API_KEY: 'db05b83f-0a2b-4b1a-a798-4df2b7f0f5b6',
  PIMLICO_API_URL: 'https://api.pimlico.io/v2',
  TENDERLY_API_URL:
    'https://api.tenderly.co/api/v1/account/snowfork/project/spinamp',
  TENDERLY_API_KEY: 'GpEmKC6601o06Vnu14QmIbdiBs-9VXjS',
  NEYNAR_API_URL: 'https://api.neynar.com/v2',
  NEYNAR_API_KEY: '92C11F9E-C795-4114-AC94-07AB0B400246',
};
