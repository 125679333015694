import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const IMAGE_SIZE = 120;

export const styles = createThemedStyles(theme => ({
  container: {
    gap: spacing.xs,
  },
  editionItemImage: {
    width: '100%',
    aspectRatio: 1,
    borderRadius: theme.box.borderRadius,
    overflow: 'hidden',
  },
  editionName: {
    zIndex: -1,
    width: IMAGE_SIZE,
    padding: spacing.xxs,
    marginTop: -theme.box.borderRadius / 2,
    paddingTop: spacing.xxs + theme.box.borderRadius / 2,
    alignSelf: 'center',
    borderBottomRightRadius: theme.box.borderRadius,
    borderBottomLeftRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  trackInfo: {
    marginTop: spacing.xs,
    gap: spacing.xxs,
  },
}));
