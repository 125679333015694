import React, {FC} from 'react';
import {TouchableOpacity, View, ViewStyle} from 'react-native';

import Card from '@/components/Card/Card';
import DynamicImage from '@/components/DynamicImage/DynamicImage';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './ImageCard.style';

export interface IImageCardProps {
  fallbackText?: string;
  onPress: () => void;
  bannerURL?: string;
  aspectRatio?: number;
  transparent?: boolean;
  style?: ViewStyle;
}

const ImageCard: FC<IImageCardProps> = ({
  fallbackText,
  onPress,
  bannerURL,
  aspectRatio,
  transparent = false,
  style: rootStyle,
}) => {
  const style = useThemedStyles(styles);

  const Wrapper = transparent ? View : Card;

  return (
    <TouchableOpacity style={rootStyle} onPress={onPress}>
      <Wrapper style={[style.card, !!aspectRatio && {aspectRatio}]}>
        {!bannerURL && fallbackText && (
          <Space p="xs">
            <Text align="center" weight="semibold">
              {fallbackText}
            </Text>
          </Space>
        )}
        {bannerURL && (
          <View style={style.imageWrapper}>
            <DynamicImage source={{uri: bannerURL}} style={style.image} />
          </View>
        )}
      </Wrapper>
    </TouchableOpacity>
  );
};

export default ImageCard;
