import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    header: {
      padding: spacing.xs,
      paddingTop: spacing.xs + safeAreaInsets.top,
      paddingHorizontal: spacing.s,
      backgroundColor: theme.colors.backgroundDark,
    },
  }),
);
