import React, {FC, useMemo} from 'react';

import Button from '@/components/Button';
import Input from '@/components/Input/Input';
import Modal, {IModalProps} from '@/components/Modal/Modal';
import Select from '@/components/Select';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {usePlaylistForm} from '@/hooks/usePlaylistForm';
import {useUserProfilesQuery} from '@/queries/user';
import {useThemedStyles} from '@/theme';
import {IPlaylistFormData, IPlaylistFormSubmitData} from '@/types/playlists';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './PlaylistFormModal.style';

interface IProps {
  modalTitleId: string;
  descriptionId?: string;
  submitTextId: string;
  onSubmit: (data: IPlaylistFormSubmitData) => void;
  initialData?: IPlaylistFormData;
  createdByEnabled?: boolean;
}

const PlaylistFormModal: FC<IProps & IModalProps> = ({
  isOpen,
  onClose,
  modalTitleId,
  descriptionId,
  submitTextId,
  initialData,
  onSubmit,
  createdByEnabled = false,
}) => {
  const style = useThemedStyles(styles);
  const {
    title,
    setTitle,
    description,
    setDescription,
    isFormValid,
    formattedTitle,
    formattedDescription,
    titleMaxLength,
    descriptionMaxLength,
    collector,
    setCollector,
  } = usePlaylistForm(initialData);
  const {users} = useUserProfilesQuery();

  const createdBy = useMemo(() => {
    if (!collector || !users) {
      return undefined;
    }

    return users.find(u => u.id === collector);
  }, [collector, users]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      titleId={modalTitleId}
      avoidKeyboard>
      <Space p="s">
        {descriptionId && (
          <Space mb="s">
            <Text id={descriptionId} size="xs" />
          </Space>
        )}
        <Input
          value={title}
          onChangeText={setTitle}
          autoFocus
          placeholderId="playlistForm.titlePlaceholder"
          maxLength={titleMaxLength}
        />
        <Space mt="xs" />
        <Input
          value={description}
          onChangeText={setDescription}
          maxLength={descriptionMaxLength}
          multiline
          placeholderId="playlist.about"
        />

        {createdByEnabled && users && users.length > 1 && createdBy && (
          <Space mt="s">
            <Text id="playlistForm.createdBy" weight="semibold" />
            <Space mt="xs" />
            <Select
              value={createdBy.id}
              options={users.map(u => ({
                label: getUserDisplayName(u),
                value: u.id,
              }))}
              setValue={value => setCollector(value)}
            />
          </Space>
        )}

        <Space style={style.actions} mt="s">
          <Button onPress={onClose} textId="cancel" style={style.action} />
          <Space ml="xs" />
          <Button
            onPress={() =>
              onSubmit({
                title: formattedTitle,
                description: formattedDescription,
                collector,
              })
            }
            textId={submitTextId}
            disabled={!isFormValid}
            style={style.action}
          />
        </Space>
      </Space>
    </Modal>
  );
};

export default PlaylistFormModal;
