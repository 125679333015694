import {gql} from 'graphql-request';
import {encodeFunctionData} from 'viem';

import {tipAddress, tipChain, tipsAbi} from '@/modules/Tip/constants';
import {
  IArtistTippers,
  IPendingBalances,
  ITipHistoryEntry,
} from '@/modules/Tip/types';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {pipelineApi} from '@/services/pipelineApi';
import {IPageInfo, IPaginatedResponse} from '@/types/api';
import {AddressString, IAddress, IArtist} from '@/types/common';
import {IBaseUser} from '@/types/user';
import {BASE_USER_FRAGMENT, parseApiBaseUser} from '@/utils/apiModelParsers';
import {getChainClient} from '@/utils/ethereum';

export const canTipArtist = (artist: IArtist) =>
  artist.addresses.some(a => a.isWallet);

export const getAddressForTip = async (artist: IArtist) => {
  const wallets = artist.addresses.filter(a => a.isWallet);

  const verifiedAddress = await findVerifiedArtistWallet(wallets);

  if (verifiedAddress) {
    return verifiedAddress;
  }

  const passkeyWallet = wallets.find(isPasskeyWallet);

  if (passkeyWallet) {
    return passkeyWallet.address;
  }

  const defaultWallet = wallets.find(a =>
    artist.id.toLowerCase().includes(a.address.toLowerCase()),
  );

  if (defaultWallet) {
    return defaultWallet.address;
  }

  return wallets[0]?.address;
};

export const getSendTipWriteContractInput = (artistAddress: string) => {
  return {
    abi: tipsAbi,
    functionName: 'tip',
    args: [artistAddress],
  };
};

export const fetchTipsBalance = async (artistAddress: string) => {
  const balance = await getChainClient(tipChain.id).readContract({
    address: tipAddress,
    abi: tipsAbi,
    functionName: 'balanceOf',
    args: [artistAddress],
  });

  return balance as bigint;
};

export const fetchTipsBalanceBulk = async (artistAddresses: string[]) => {
  const balances = await Promise.all(artistAddresses.map(fetchTipsBalance));
  const balanceByAddress: {[address: string]: bigint} = {};
  artistAddresses.forEach((address, index) => {
    balanceByAddress[address] = balances[index];
  });

  return balanceByAddress;
};

export const fetchTotalTipsBalance = async (artistId: string) => {
  const response = await pipelineApi.request(
    gql`
      query TipsTotalBalance($artistId: String!) {
        artistTotalTips(artistid: $artistId)
      }
    `,
    {artistId},
  );

  return (response.artistTotalTips || '0') as string;
};

export const fetchArtistTippers = async (
  artistId: string,
): Promise<IArtistTippers> => {
  const response = await pipelineApi.request(
    gql`
      query ArtistTippers($artistId: String!) {
        artistTotalTipsByUser(artistid: $artistId, first: 10) {
          totalCount
          nodes {
            amount
            userByUserId {
              ...BaseUserFragment
            }
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {artistId},
  );

  const totalCount: number = response.artistTotalTipsByUser.totalCount;
  const users: IBaseUser[] = response.artistTotalTipsByUser.nodes
    .filter((node: any) => node.userByUserId)
    .map((node: any) => parseApiBaseUser(node.userByUserId));

  return {
    totalCount,
    users,
  };
};

export const fetchTipsHistory = async (
  artistId: string,
  after?: string,
): Promise<IPaginatedResponse<ITipHistoryEntry>> => {
  const response = await pipelineApi.request(
    gql`
      query TipsHistory($artistId: String!, $after: Cursor) {
        allArtistTips(artistid: $artistId, after: $after, first: 100) {
          nodes {
            transactionHash
            amount
            to
            addressByFrom {
              userByUserId {
                ...BaseUserFragment
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }

      ${BASE_USER_FRAGMENT}
    `,
    {
      artistId,
      after,
    },
  );

  const pageResponse = response.allArtistTips;
  const pageInfo: IPageInfo = pageResponse.pageInfo;
  const items: ITipHistoryEntry[] = pageResponse.nodes
    .filter((node: any) => node.addressByFrom.userByUserId)
    .map((node: any) => ({
      transactionHash: node.transactionHash,
      from: parseApiBaseUser(node.addressByFrom.userByUserId),
      toAddress: node.to,
      amount: node.amount,
    }));

  return {
    pageInfo,
    items,
  };
};

export const fetchIsAddressVerified = async (artistAddress: string) => {
  const verifiedPayoutAddress = await getChainClient(tipChain.id).readContract({
    address: tipAddress,
    abi: tipsAbi,
    functionName: 'isAddressVerified',
    args: [artistAddress],
  });

  return verifiedPayoutAddress as boolean;
};

const findVerifiedArtistWallet = (
  wallets: IAddress[],
): Promise<string | undefined> => {
  if (wallets.length === 0) {
    return Promise.resolve(undefined);
  }

  let pendingPromises = wallets.length;
  let isResolved = false;

  return new Promise(resolve => {
    wallets.forEach(async wallet => {
      const isAddressVerified = await fetchIsAddressVerified(wallet.address);

      pendingPromises -= 1;

      if (isResolved) {
        return;
      }

      if (isAddressVerified) {
        resolve(wallet.address);
        isResolved = true;
        return;
      }

      if (pendingPromises === 0) {
        resolve(undefined);
        isResolved = true;
      }
    });
  });
};

export const getVerifyTransactionInput = (input: {
  verifiedAddress: string;
  payoutAddress: string;
}) => {
  return {
    to: tipAddress as AddressString,
    data: encodeFunctionData({
      abi: tipsAbi,
      functionName: 'verifyAddressAndWithdraw',
      args: [input.verifiedAddress, input.payoutAddress],
    }) as AddressString,
  };
};

export const sumPendingBalance = (balances: IPendingBalances = {}) =>
  Object.values(balances).reduce((sum, balance) => sum + balance, BigInt(0));
