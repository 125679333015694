import WarningBox from '@/components/WarningBox';
import {
  IGasEstimation,
  ITransactionValidationError,
} from '@/modules/Transactions/types';

export const useTransactionValidation = (
  gasEstimation: IGasEstimation,
): ITransactionValidationError | undefined => {
  if (!gasEstimation.isError) {
    return undefined;
  }

  return {
    message: (
      <WarningBox
        title={{id: 'transactions.invalidTransaction.title'}}
        message={{id: 'transactions.invalidTransaction.message'}}
      />
    ),
  };
};
