import React, {FC} from 'react';
import {Pressable, StyleProp, StyleSheet} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import {IIconProps} from '@/components/Icon';
import Icon from '@/components/Icon/Icon';
import {useTheme} from '@/theme';

interface IProps {
  onPress: () => void;
  icon: IIconProps;
  disabled?: boolean;
  style?: StyleProp<any>;
}

const PlayerControl: FC<IProps> = ({
  onPress,
  icon,
  disabled = false,
  style,
}) => {
  const theme = useTheme();
  const pressed = useSharedValue(false);
  const hovered = useSharedValue(false);

  const hoverStyle = useAnimatedStyle(() => ({
    opacity: withTiming(hovered.value && !pressed.value ? 0.4 : 1, {
      duration: 150,
    }),
  }));

  const pressStyle = useAnimatedStyle(() => ({
    opacity: withTiming(pressed.value ? 0.4 : 0, {duration: 150}),
  }));

  const iconPressStyle = useAnimatedStyle(() => ({
    transform: [{scale: withSpring(pressed.value ? 0.7 : 1, {mass: 0.1})}],
  }));

  return (
    <Pressable
      onPress={onPress}
      onPressIn={() => (pressed.value = true)}
      onPressOut={() => (pressed.value = false)}
      onHoverIn={() => (hovered.value = true)}
      onHoverOut={() => (hovered.value = false)}
      disabled={disabled}
      style={[{overflow: 'hidden'}, style]}>
      <Animated.View
        style={[
          {
            ...StyleSheet.absoluteFillObject,
            backgroundColor: theme.colors.backgroundDark,
          },
          pressStyle,
        ]}
      />
      <Animated.View style={[hoverStyle, iconPressStyle]}>
        <Icon {...icon} style={[disabled && {opacity: 0.2}]} />
      </Animated.View>
    </Pressable>
  );
};

export default PlayerControl;
