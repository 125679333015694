import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const IMAGE_HEIGHT = spacing.s * 6;
export const AVATAR_SIZE = spacing.s * 4;

export const styles = createThemedStyles(() => ({
  container: {
    flex: 1,
    padding: spacing.l,
    gap: spacing.l,
  },
  content: {
    gap: spacing.s * 2,
    flex: 1,
  },
  imageContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
  },
  userAvatar: {
    position: 'absolute',
    left: spacing.xs,
    zIndex: -1,
    transform: [{translateY: -1}],
  },
  artistAvatar: {
    position: 'absolute',
    right: spacing.xs,
    zIndex: -1,
    transform: [{translateY: -1}],
  },
  textContainer: {
    gap: spacing.xs,
  },
  shareButtons: {
    marginBottom: 0,
  },
  links: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.s,
  },
  links_centered: {
    justifyContent: 'center',
  },
}));
