import {queryClient} from '@/services/reactQuery';
import {
  IArtist,
  IDatabase,
  IQueueItem,
  IQueueItemWithTrack,
  ITrack,
} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {insensitiveIncludes, sortStrings} from '@/utils/functions';
import {sortTracksByDate} from '@/utils/tracks';

export const getDb = () => queryClient.getQueryData<IDatabase>([QueryKeys.db]);

export const mergeDb = (
  currentDb: IDatabase = {tracks: {}, artists: {}},
  {
    tracks = [],
    artists = [],
  }: {
    tracks?: ITrack[];
    artists?: IArtist[];
  },
): IDatabase => {
  const updatedData: IDatabase = {
    tracks: {
      ...currentDb.tracks,
    },
    artists: {
      ...currentDb.artists,
    },
  };

  tracks.forEach(track => {
    updatedData.tracks[track.id] = track;
    updatedData.artists[track.artistId] = track.artist;
  });
  artists.forEach(artist => {
    updatedData.artists[artist.id] = artist;
  });

  return updatedData;
};

export const getTracksDb = () => {
  return getDb()?.tracks || {};
};

export const getArtistsDb = () => {
  return getDb()?.artists || {};
};

export const getTrackById = (trackId: string): ITrack | undefined =>
  getTracksDb()[trackId];

export const getTracksByIds = (trackIds: string[] = []): ITrack[] => {
  const allTracks = getTracksDb();
  return trackIds.map(id => allTracks[id]).filter(track => !!track) || [];
};

export const getQueueItemsWithTracks = (
  items: IQueueItem[] = [],
): IQueueItemWithTrack[] => {
  const allTracks = getTracksDb();

  return items
    .map(item => ({
      ...item,
      track: allTracks[item.trackId],
    }))
    .filter(item => !!item.track);
};

export const getAllTracksList = (db: IDatabase) => Object.values(db.tracks);

export const getAllArtistsList = (db: IDatabase) => Object.values(db.artists);

export const getAllArtistsListSorted = (db: IDatabase) =>
  getAllArtistsList(db).sort((a, b) => sortStrings(a.name, b.name));

export const getTracksList = (db: IDatabase, trackIds: string[] = []) =>
  trackIds.map(id => db.tracks[id]).filter(t => !!t);

export const getTracksListSorted = (db: IDatabase, trackIds: string[] = []) =>
  getTracksList(db, trackIds).sort(sortTracksByDate);

export const getArtistsList = (db: IDatabase, artistIds: string[] = []) =>
  artistIds.map(id => db.artists[id]).filter(a => !!a);

export const getArtistsListSorted = (db: IDatabase, artistIds: string[] = []) =>
  getArtistsList(db, artistIds).sort((a, b) => sortStrings(a.name, b.name));

export const searchTracks = (db: IDatabase, search: string = '') => {
  const tracks = getAllTracksList(db);

  if (!search) {
    return tracks;
  }

  const tracksByTitle: ITrack[] = [];
  const tracksByArtistName: ITrack[] = [];

  tracks.forEach(track => {
    if (insensitiveIncludes(track.title, search)) {
      tracksByTitle.push(track);
      return;
    }

    if (insensitiveIncludes(track.artist.name, search)) {
      tracksByArtistName.push(track);
    }
  });

  return [...tracksByTitle, ...tracksByArtistName];
};

export const searchArtists = (db: IDatabase, search: string = '') => {
  const artists = getAllArtistsList(db);

  if (!search) {
    return artists;
  }

  return artists.filter(({name}) => insensitiveIncludes(name, search));
};

export const findArtistTrack = (db: IDatabase, artistId?: string) =>
  artistId && getAllTracksList(db).find(t => t.artistId === artistId);
