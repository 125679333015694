import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {useMemo} from 'react';

import {
  fetchArtistAddressById,
  fetchArtistByUrlParam,
  fetchArtistDetails,
  fetchArtistsByIds,
  fetchArtistTracks,
  fetchLatestArtists,
} from '@/api/artist';
import {MINUTE} from '@/constants/time';
import {useDbQuery} from '@/queries/db';
import {IPageInfo} from '@/types/api';
import {IArtist, ITrack} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {sortArtistsByDate} from '@/utils/artists';
import {
  getAllArtistsList,
  getAllTracksList,
  getArtistsList,
  getTracksList,
} from '@/utils/db';
import {getNextPageParam} from '@/utils/pagination';
import {sortTracksByDate} from '@/utils/tracks';

export const useArtistBySlugQuery = (slug: string) => {
  const {db, updateDb} = useDbQuery();

  // we fetch full track based on slug and save corresponding track id in cache, so we always make sure
  // we have proper the oldest matching slug for prettified slugs
  const query = useQuery({
    queryKey: [QueryKeys.artistBySlug, slug],
    queryFn: async () => {
      const artist = await fetchArtistByUrlParam(slug);
      if (artist) {
        updateDb({
          artists: [artist],
        });

        return artist.id;
      }
    },
  });

  const artistId = query.data;

  const artist = useMemo(() => {
    if (artistId) {
      return db.artists[artistId];
    }

    return getAllArtistsList(db).find(a => a.slug === slug);
  }, [artistId, slug, db.artists]);

  return {
    artist,
    query,
  };
};

export const useArtistTracksQuery = (artistId?: string) => {
  const {db, updateDb} = useDbQuery();
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.artistTracks, artistId],
    queryFn: async ({pageParam}) => {
      const {items: tracks, pageInfo} = await fetchArtistTracks(
        artistId!,
        pageParam,
      );
      updateDb({tracks});
      return {
        pageInfo,
        items: tracks.map(t => t.id),
      };
    },
    enabled: !!artistId,
    initialPageParam: undefined,
    getNextPageParam,
  });

  const tracks = useMemo(() => {
    // if there is no data return locally filtered data from central tracks cache
    if (!query.data) {
      return getAllTracksList(db)
        .filter(track => track.artistId === artistId)
        .sort(sortTracksByDate);
    }

    return query.data.pages.reduce<ITrack[]>(
      (result, page) => [...result, ...getTracksList(db, page.items)],
      [],
    );
  }, [query.data, db.tracks, artistId]);

  return {
    tracks,
    query,
  };
};

export const useLatestArtistsQuery = () => {
  const queryClient = useQueryClient();
  const {db, updateDb} = useDbQuery();
  const query = useInfiniteQuery({
    queryKey: [QueryKeys.latestArtists],
    queryFn: async ({pageParam}) => {
      const {items: artists, pageInfo} = await fetchLatestArtists(pageParam);
      updateDb({artists});
      return {
        pageInfo,
        items: artists.map(artist => artist.id),
      };
    },
    initialPageParam: undefined,
    getNextPageParam,
    staleTime: MINUTE,
  });

  const artists = useMemo(() => {
    // if there is no data return artists from central db cache
    if (!query.data) {
      return getAllArtistsList(db).slice(0, 100).sort(sortArtistsByDate);
    }

    return query.data.pages.reduce<IArtist[]>(
      (result, page) => [...result, ...getArtistsList(db, page.items)],
      [],
    );
  }, [query.data, db]);

  const clearLatestArtistsPages = () => {
    queryClient.setQueryData<
      InfiniteData<{pageInfo: IPageInfo; items: string[]}> | undefined
    >([QueryKeys.latestArtists], currentData => {
      if (!currentData) {
        return undefined;
      }
      return {
        pages: currentData.pages.slice(0, 1),
        pageParams: currentData.pageParams.slice(0, 1),
      };
    });
  };

  return {
    artists,
    clearLatestArtistsPages,
    query,
  };
};

export const useEmbedArtistQuery = (id?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.artistDetails, id],
    queryFn: () => fetchArtistDetails(id!),
    enabled: !!id,
  });

  return {
    artist: query.data?.artist,
    tracks: query.data?.tracks || [],
    query,
  };
};

export const useArtistsByIdsQuery = (artistsIds: string[] = []) => {
  const {db, updateDb} = useDbQuery();

  const query = useQuery({
    queryKey: [QueryKeys.artistsByIds, artistsIds],
    queryFn: async () => {
      const artists = await fetchArtistsByIds(artistsIds);
      updateDb({artists});
      return true;
    },
    enabled: artistsIds.length > 0,
    staleTime: 1000 * 60 * 5,
    gcTime: 0,
  });

  const artists = useMemo(
    () => getArtistsList(db, artistsIds),
    [db.artists, artistsIds],
  );

  return {
    artists,
    query,
  };
};
