import {useIsFocused} from '@react-navigation/native';
import React, {FC, RefObject, useEffect, useRef} from 'react';
import {debounce, throttle} from 'throttle-debounce';

import {ICarouselContainerProps} from './types';

const CarouselContainer: FC<ICarouselContainerProps> = ({
  carouselRef,
  feedLength,
  children,
  isEnabled,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useCarouselWheelScroll(carouselRef, containerRef, feedLength, isEnabled);

  useOverscrollContain();

  return (
    <div id="carousel-container" ref={containerRef}>
      {children}
    </div>
  );
};

const useOverscrollContain = () => {
  // To opt-out of the macOS / browser's "swipe left to navigate back"
  // feature, we set `overscroll-behavior-x: contain` on the webpage
  // while this component is mounted.
  // Chromium-based browsers need the rule on <body>, Firefox needs
  // it on <html>.

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    const previousBodyOverscrollBehavior =
      document.body.style.overscrollBehavior;
    const previousHtmlOverscrollBehavior =
      document.documentElement.style.overscrollBehavior;

    document.body.style.overscrollBehavior = 'contain';
    document.documentElement.style.overscrollBehavior = 'contain';

    return () => {
      document.body.style.overscrollBehavior = previousBodyOverscrollBehavior;
      document.documentElement.style.overscrollBehavior =
        previousHtmlOverscrollBehavior;
    };
  }, [isFocused]);
};

const useCarouselWheelScroll = (
  carouselRef: ICarouselContainerProps['carouselRef'],
  containerRef: RefObject<HTMLDivElement>,
  feedLength: number,
  isEnabled: boolean,
) => {
  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isFocused || !isEnabled) {
      return;
    }

    const handleScroll = (event: GlobalEventHandlersEventMap['wheel']) => {
      const {deltaY, deltaX} = event;

      const isScrollingVertically = Math.abs(deltaY) > Math.abs(deltaX);

      const deltaXOrY = isScrollingVertically ? deltaY : deltaX;

      let target = event.target as Element | null;

      if (isScrollingVertically) {
        // Search up the DOM to find the nearest vertically scrollable container (if there is any)
        while (target) {
          const isScrollable =
            target.scrollHeight > target.clientHeight &&
            getComputedStyle(target).overflowY !== 'hidden';
          if (isScrollable) {
            // Found vertically scollable element
            break;
          }

          target = target.parentElement;
          if (target === containerRef.current) {
            target = null;
          }
        }

        // We found a vertically scrollable parent in the DOM. Exit the custom scroll handler
        // and just let browser native scroll happen (without scroll chaining; see git history for that).
        if (target) {
          return;
        }
      }

      const currentIndex = carouselRef.current?.getCurrentIndex() ?? 0;
      const direction = deltaXOrY > 0 ? 1 : -1;
      const deltaAbs = Math.abs(deltaXOrY);
      let stifledDeltaX = deltaAbs / 20;
      if (stifledDeltaX > 0.15 && stifledDeltaX < 1) {
        stifledDeltaX = 1;
      }
      const newIndex = Math.round(currentIndex + direction * stifledDeltaX);
      if (newIndex < 0 || newIndex >= feedLength) {
        return;
      }
      carouselRef.current?.scrollTo({
        index: newIndex,
        animated: true,
      });
    };

    const throttledDebouncedHandleScroll = throttle(
      10,
      debounce(1, handleScroll),
    );

    const scrollContainer = containerRef.current;

    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', throttledDebouncedHandleScroll);
      return () => {
        scrollContainer.removeEventListener(
          'wheel',
          throttledDebouncedHandleScroll,
        );
      };
    }
  }, [feedLength, isFocused, isEnabled]);
};

export default CarouselContainer;
