import {INftEvent, INftEventWithTracksInfo, NftEventType} from '@/types/nfts';
import {parseApiBaseUser} from '@/utils/apiModelParsers';
import {nullifyAddress} from '@/utils/ethereum';
import {getImageUrl} from '@/utils/ipfs';

export const parseApiNftEvent = (rawEvent: any): INftEvent => {
  const fromAddress = nullifyAddress(rawEvent.from);
  const fromUser = rawEvent.addressByFrom?.userByUserId;
  const toAddress = nullifyAddress(rawEvent.to);
  const toUser = rawEvent.addressByTo?.userByUserId;
  const type: NftEventType = fromAddress ? 'transfer' : 'mint';

  return {
    id: rawEvent.id,
    createdAtTime: rawEvent.createdAtTime,
    senderAddress: fromAddress,
    sender: fromAddress && fromUser ? parseApiBaseUser(fromUser) : null,
    receiverAddress: toAddress,
    receiver: toAddress && toUser ? parseApiBaseUser(toUser) : null,
    tokenId: rawEvent.tokenId,
    chainId: rawEvent.chainId,
    contractAddress: rawEvent.contractAddress,
    type,
  };
};

export const parseApiNftEventWithTracks = (
  rawEvent: any,
): INftEventWithTracksInfo => {
  const event = parseApiNftEvent(rawEvent);
  const totalCount = rawEvent.nftByNftId.nftsProcessedTracksByNftId.totalCount;
  const track =
    rawEvent.nftByNftId.nftsProcessedTracksByNftId.nodes[0]
      ?.processedTrackByProcessedTrackId;

  return {
    ...event,
    tracksInfo: {
      track: track
        ? {
            id: track.id,
            title: track.title,
            slug: track.slug,
            lossyArtworkUrl:
              track.lossyArtworkIpfsHash &&
              getImageUrl(
                track.lossyArtworkIpfsHash,
                undefined,
                track.lossyArtworkMimeType,
              ),
            artist: track.artist,
          }
        : undefined,
      totalCount: totalCount || 0,
    },
  };
};
