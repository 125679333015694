import React, {FC, useState} from 'react';
import {StyleProp, View} from 'react-native';

import {useResponsive} from '@/hooks/useResponsive';
import {useTheme} from '@/theme';
import {useThemedStyles} from '@/theme';
import {setTransformationsOnCloudinaryUrl} from '@/utils/cloudinary';
import {isEmbedded} from '@/utils/platform';

import {styles} from './Background.style';

const Background: FC = () => {
  const theme = useTheme();
  const style = useThemedStyles(styles);

  const {isMobileBrowser} = useResponsive();

  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageOnLoad = () => setIsLoaded(true);

  if (!theme.background?.image) {
    return null;
  }

  let baseImage = theme?.background.image;

  let backgroundImageLowRes;
  let backgroundImageHighRes;
  let customCrop;

  if (isMobileBrowser || isEmbedded) {
    customCrop = theme?.background?.customCrop
      ? theme?.background?.customCrop
      : '';

    backgroundImageLowRes = setTransformationsOnCloudinaryUrl(
      baseImage,
      `${customCrop}/c_scale,w_250,f_auto,q_auto`,
    );

    backgroundImageHighRes = setTransformationsOnCloudinaryUrl(
      baseImage,
      `${customCrop}/c_scale,w_1300,f_auto,q_auto`,
    );
  } else {
    backgroundImageLowRes = setTransformationsOnCloudinaryUrl(
      baseImage,
      'c_fill,w_20,f_auto,q_auto',
    );

    backgroundImageHighRes = setTransformationsOnCloudinaryUrl(
      baseImage,
      'c_fill,w_2400,f_auto,q_auto',
    );
  }

  const imageStyle: StyleProp<any> = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  return (
    <View style={style.imageWrapper}>
      <img
        style={{
          ...imageStyle,
        }}
        src={backgroundImageLowRes}
      />
      <img
        style={{
          ...imageStyle,
          opacity: isLoaded ? 1 : 0,
          transition: 'opacity 200ms linear',
        }}
        src={backgroundImageHighRes}
        onLoad={handleImageOnLoad}
      />
    </View>
  );
};

export default React.memo(Background);
