import {useEffect, useRef} from 'react';
import {useStore} from 'react-redux';

import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {TransactionState, TransactionType} from '@/modules/Transactions/types';
import {selectTransactionById} from '@/store/transactions/selectors';
import {
  createTransaction,
  removeTransaction,
  updateTransaction,
} from '@/store/transactions/slice';

/**
 * Hook responsible for initializing transfer data in redux.
 * If `transactionId` is provided, it will read existing transaction from the store.
 * If `transactionId` is not provided, it will create a transaction in the store and read it
 *
 * It also removes transaction from the store or set is as minimized when component unmounts.
 */
export const useSyncTransactionState = <
  Type extends TransactionType,
  TransactionStateType extends TransactionState,
>(
  transactionId: string | undefined,
  type: Type,
  initializer: () => TransactionStateType,
) => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  const transactionDraft = useRef(initializer()).current;

  const reduxTransaction = useAppSelector(state =>
    selectTransactionById(state, transactionId || transactionDraft.id, type),
  ) as Extract<TransactionState, {type: Type}>;

  const transaction = reduxTransaction || transactionDraft;

  useEffect(() => {
    if (!reduxTransaction) {
      dispatch(createTransaction(transactionDraft));
    }
  }, [reduxTransaction]);

  useEffect(() => {
    return () => {
      const currentTransactionStep = selectTransactionById(
        getState(),
        transaction.id,
        transaction.type,
      )?.step;

      if (
        currentTransactionStep === 'checkout' ||
        currentTransactionStep === 'success'
      ) {
        dispatch(removeTransaction({id: transaction.id}));
      } else {
        dispatch(
          updateTransaction({
            id: transaction.id,
            type,
            update: {
              isMinimized: true,
            },
          }),
        );
      }
    };
  }, [transaction.id]);

  return {
    transaction,
    isInitialized: !!reduxTransaction,
  };
};
