import React, {FC, ReactNode} from 'react';
import {StyleProp, View} from 'react-native';

import DashedDivider from '@/components/DashedDivider';
import ScrollContainer from '@/components/ScrollContainer';
import TrackSummary from '@/modules/Collect/components/TrackSummary';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {useThemedStyles} from '@/theme';

import {styles} from './CollectLayout.style';

interface IProps {
  children: ReactNode;
  header?: ReactNode;
  minimized?: boolean;
  style?: StyleProp<any>;
}

const CollectLayout: FC<IProps> = ({
  children,
  header,
  minimized,
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);
  const {track} = useCollectState();

  return (
    <ScrollContainer contentStyle={style.container}>
      {header}

      <TrackSummary track={track} minimized={minimized} />

      <DashedDivider />

      <View style={[style.details, customStyle]}>{children}</View>
    </ScrollContainer>
  );
};

export default CollectLayout;
