import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon';
import ScrollContainer from '@/components/ScrollContainer';
import Text from '@/components/Text';
import {getChainById} from '@/constants/chains';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {useCryptoTransferState} from '@/modules/Transfer/CryptoTransfer/useCryptoTransferState';
import {useThemedStyles} from '@/theme';
import {prettifyAddress} from '@/utils/ethereum';

import {styles} from './SuccessfulCryptoTransfer.style';

interface IProps {}

const SuccessfulCryptoTransfer: FC<IProps> = ({}) => {
  const style = useThemedStyles(styles);
  const {transfer, close} = useCryptoTransferState();

  return (
    <ScrollContainer contentStyle={style.scrollContent}>
      <View style={style.content}>
        <Icon provider="custom" name="checked" size={64} />

        <View style={style.textsContainer}>
          <Text
            weight="semibold"
            size="l"
            id="transfer.success.title"
            align="center"
          />

          <Text
            id="transfer.success.crypto.description"
            align="center"
            values={{
              amount: `${transfer.amount || 0} ${
                getChainById(transfer.chainId).chain.nativeCurrency.symbol
              }`,
              deliveryAddress: transfer.to && prettifyAddress(transfer.to),
            }}
          />
        </View>

        <CardButton
          onPress={close}
          text={{id: 'done'}}
          style={style.doneButton}
        />

        <View style={style.transactionLinks}>
          {(transfer.txHash || transfer.userOpHash) && (
            <View style={style.links}>
              {transfer.userOpHash && (
                <View style={style.link}>
                  <JiffyscanLink
                    userOpHash={transfer.userOpHash}
                    chainId={transfer.chainId}
                  />
                </View>
              )}
              {transfer.txHash && (
                <View style={style.link}>
                  <EtherscanLink
                    txHash={transfer.txHash}
                    chainId={transfer.chainId}
                  />
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    </ScrollContainer>
  );
};

export default SuccessfulCryptoTransfer;
