import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import {useMemo} from 'react';

import {libraryCategories} from '@/screens/Library/constants';
import {
  LibraryStackNavigationParams,
  LibraryStackParams,
  Routes,
} from '@/types/routes';

export const useLibraryCategories = () => {
  const navigation = useNavigation<LibraryStackNavigationParams>();
  const {params} = useRoute<RouteProp<LibraryStackParams, Routes.Library>>();

  const selectedCategory = useMemo(
    () =>
      libraryCategories.find(category => category.id === params?.category) ||
      libraryCategories[0],
    [params?.category],
  );

  const selectedSubcategory = useMemo(
    () =>
      selectedCategory?.subcategories?.find(
        subcategory => subcategory.id === params?.subcategory,
      ),
    [selectedCategory, params?.subcategory],
  );

  const selectedCategoryId = selectedCategory?.id;
  const selectedSubcategoryId = selectedSubcategory?.id;

  const setCategory = (_category = libraryCategories[0].id) => {
    navigation.setParams({category: _category, subcategory: ''});
  };

  const setSubcategory = (_subcategory?: string) => {
    navigation.setParams({
      category: selectedCategoryId,
      subcategory: _subcategory || '',
    });
  };

  // used to remount library children components to re-trigger animation
  const activeCategoryKey = selectedCategoryId + (selectedSubcategoryId || '');

  return {
    activeCategoryKey,
    selectedCategory,
    selectedCategoryId,
    selectedSubcategory,
    selectedSubcategoryId,
    setCategory,
    setSubcategory,
  };
};
