import {IExternalLink} from '@/types/common';

export const compareExternalLinks = (
  currentLinks: IExternalLink[] = [],
  formLinks: IExternalLink[] = [],
) => {
  const added: IExternalLink[] = [];
  const deleted: IExternalLink[] = [];
  const updated: IExternalLink[] = [];

  const currentLinksMap = new Map<string, IExternalLink>();

  currentLinks.forEach(link => {
    currentLinksMap.set(link.id, link);
  });

  formLinks.forEach(link => {
    const itemInCurrentLinks = currentLinksMap.get(link.id);

    if (!itemInCurrentLinks) {
      added.push(link);
    } else if (itemInCurrentLinks.url !== link.url) {
      updated.push(link);
    }

    currentLinksMap.delete(link.id);
  });

  deleted.push(...currentLinksMap.values());

  return {
    added,
    updated,
    deleted,
  };
};
