import React, {FC} from 'react';
import {
  Dimensions,
  FlatList,
  StyleProp,
  TouchableOpacity,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  MeasuredDimensions,
  SharedValue,
  useAnimatedStyle,
} from 'react-native-reanimated';

import CardButton from '@/components/CardButton';
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Input from '@/components/Input';
import Link from '@/components/Link';
import OpacityGradient from '@/components/OpacityGradient';
import Share from '@/components/Share';
import Space from '@/components/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {TooltipOverlay} from '@/components/Tooltip';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {usePlaylistForm} from '@/hooks/usePlaylistForm';
import {useTrackReleaseNotification} from '@/hooks/useTrackReleaseNotification';
import {useFavsPlaylist, useUserPlaylists} from '@/hooks/useUserPlaylists';
import {useCollect} from '@/modules/Collect';
import {
  useCreatePlaylist,
  useToggleTrackInPlaylist,
} from '@/queries/ownedPlaylists';
import {getArtistModalLink} from '@/screens/Feed/utils';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {PlaylistType} from '@/types/playlists';
import {Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {isNative} from '@/utils/platform';
import {getTrackUrl} from '@/utils/share';

import {ARROW_SIZE, styles} from './PostLikePopover.style';

interface IProps {
  track: ITrack;
  close: () => void;
  cardMeasurement: SharedValue<MeasuredDimensions | null>;
  style?: StyleProp<any>;
  offset?: number;
  bottomOffset?: number;
}

const PostLikePopover: FC<IProps> = ({
  track,
  close,
  cardMeasurement,
  style: customStyle,
  offset = spacing.xs,
  bottomOffset = 0,
}) => {
  const style = useThemedStyles(styles);
  const screenSize = useWindowDimensions();

  const releaseNotification = useTrackReleaseNotification({
    track,
    analyticsInfo: {screen: Routes.Channels, id: track.id},
  });

  const releaseTime = track?.mintStart && new Date(track.mintStart);

  const {getIsFollowed, toggleFollow} = useArtistFollow();
  const {collect} = useCollect(track);

  const {toggleTrackInPlaylistMutation} = useToggleTrackInPlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();

  const favsPlaylist = useFavsPlaylist();
  const customPlaylists = useUserPlaylists(
    PlaylistType.custom,
    () => 0,
  ).reverse();
  const ownPlaylists = [favsPlaylist, ...customPlaylists];

  const {
    title: newPlaylistTitle,
    setTitle: setNewPlaylistTitle,
    formattedTitle: formattedPlaylistTitle,
    isFormValid,
    titleMaxLength,
  } = usePlaylistForm();

  const onCreatePlaylist = async () => {
    setNewPlaylistTitle('');
    createPlaylistMutation({
      title: formattedPlaylistTitle,
      trackIds: [track.id],
      type: PlaylistType.custom,
    });
  };

  const popoverStyle = useAnimatedStyle(() => {
    if (cardMeasurement.value) {
      return {
        bottom:
          screenSize.height -
          (cardMeasurement.value.pageY + cardMeasurement.value.height) +
          offset +
          bottomOffset,
        left: cardMeasurement.value.pageX + offset,
        width: cardMeasurement.value.width - offset * 2,
        maxHeight: cardMeasurement.value.height - offset * 2 - bottomOffset,
      };
    }

    return {};
  }, [screenSize.height]);

  return (
    <TooltipOverlay hoverEnabled={false}>
      <TouchableWithoutFeedback onPress={close}>
        <Animated.View
          style={style.backdropWrapper}
          entering={FadeIn.duration(150)}>
          <Animated.View style={style.backdrop} />
        </Animated.View>
      </TouchableWithoutFeedback>

      <Animated.View
        entering={FadeIn}
        exiting={FadeOut}
        style={[style.popoverContainer, customStyle, popoverStyle]}>
        <View style={style.inputContainer}>
          <Input
            style={style.input}
            inputStyle={style.textInput}
            value={newPlaylistTitle}
            onChangeText={setNewPlaylistTitle}
            placeholderId="playlistForm.newPlaylistTitlePlaceholder"
            maxLength={titleMaxLength}
          />
          <IconButton
            onPress={onCreatePlaylist}
            icon={{name: 'add', provider: 'custom'}}
            disabled={!isFormValid}
          />
        </View>
        <FlatList
          style={style.playlistsScrollContainer}
          contentContainerStyle={style.playlistsScrollContent}
          data={ownPlaylists}
          renderItem={({item: playlist}) => {
            const isIncluded = playlist.trackIds?.includes(track.id);

            return (
              <>
                <TouchableOpacity
                  onPress={() =>
                    toggleTrackInPlaylistMutation({
                      playlistId: playlist.id,
                      trackId: track.id,
                    })
                  }
                  style={style.playlistItem}>
                  <Text size="xs" numberOfLines={1} id={playlist.titleId} flex>
                    {playlist.title}
                  </Text>

                  {playlist.type === PlaylistType.favorites ? (
                    <Icon
                      provider="custom"
                      fill={isIncluded}
                      name="heart"
                      color={isIncluded ? 'favoritesColor' : 'textColor'}
                    />
                  ) : (
                    <Icon
                      provider="custom"
                      name={isIncluded ? 'minus' : 'add'}
                    />
                  )}
                </TouchableOpacity>
                <Divider />
              </>
            );
          }}
          keyExtractor={item => item.id}
          showsVerticalScrollIndicator={false}
        />

        <View style={style.artistRow}>
          <View style={style.gradientContainer}>
            <OpacityGradient vertical color="backgroundLight" />
          </View>
          <Space flex>
            <Link {...getArtistModalLink(track.artist.slug)} onPress={close}>
              {({hover}) => (
                <View style={style.artistLink}>
                  <StaticImage
                    source={{uri: track.artist.avatarUrl}}
                    resizeWidth={ImageSize.thumbnail}
                    style={style.artistAvatar}
                  />
                  <Text size="xs" flex numberOfLines={1} underline={hover}>
                    {track.artist.name}
                  </Text>
                </View>
              )}
            </Link>
          </Space>
          <TransparentButton
            onPress={() => toggleFollow(track.artist.id)}
            text={{
              id: getIsFollowed(track.artist.id) ? 'unfollow' : 'follow',
            }}
            style={style.artistFollowButton}
            rippleScale={1.2}
          />
        </View>
        <View style={style.actions}>
          <Share url={getTrackUrl(track)} title={track.title}>
            {({icon: shareIcon, textId: shareTextId, onPress}) => (
              <CardButton
                style={style.action}
                onPress={() => {
                  if (isNative) {
                    close();
                  }

                  onPress();
                }}
                text={{id: shareTextId}}
                icon={shareIcon}
              />
            )}
          </Share>
          {!releaseTime || releaseTime <= new Date() ? (
            <CardButton
              style={style.action}
              onPress={() => {
                if (isNative) {
                  close();
                }

                analytics.feedCollectClicked(track.id);
                collect();
              }}
              text={{id: 'collect.button'}}
              icon={{name: 'nftMint', provider: 'custom'}}
            />
          ) : (
            <CardButton
              style={style.action}
              disabled={releaseNotification.isSubscribed}
              onPress={releaseNotification.subscribe}
              text={{id: releaseNotification.textId}}
              icon={{
                provider: 'custom',
                name: releaseNotification.iconName,
                fill: releaseNotification.isSubscribed,
              }}
            />
          )}
        </View>
        <Icon
          name="tooltipArrow"
          provider="custom"
          color="backgroundLight"
          style={[
            style.arrow,
            {
              // TODO: make that smarter
              // manually place arrow in the middle of 3 action on x-axis
              right:
                ((cardMeasurement.value?.width || 0) - offset * 2) / 6 -
                ARROW_SIZE.width / 2 -
                offset / 2,
              bottom: -ARROW_SIZE.height,
            },
          ]}
        />
      </Animated.View>
    </TooltipOverlay>
  );
};

export default PostLikePopover;
