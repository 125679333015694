import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';

export const styles = createThemedStyles(theme => ({
  container: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    padding: spacing.s,
    paddingTop: spacing.xxs,
  },
  centeredColumn: {
    alignItems: 'center',
  },
  creator: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlignVertical: 'center',
  },
  actions: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    marginHorizontal: -spacing.xs / 2,
    marginVertical: -spacing.xs / 2,
  },
  actionWrapper: {
    marginHorizontal: spacing.xs / 2,
    marginVertical: spacing.xs / 2,
  },
  action: {
    height: spacing.xl - spacing.xxs,
    paddingVertical: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  artwork: {
    width: ImageSize.playlistArtwork,
    aspectRatio: 1,
    overflow: 'hidden',
    borderRadius: theme.box.borderRadius,
  },
}));
