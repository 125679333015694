import React, {FC, useCallback, useEffect, useRef} from 'react';
import {HexColorPicker} from 'react-colorful';
import {View} from 'react-native';
import {throttle} from 'throttle-debounce';

import Button from '@/components/Button';
import {IColorPickerProps, useHexInput} from '@/components/ColorPicker/shared';
import Input from '@/components/Input/Input';
import Space from '@/components/Space/Space';
import {useThemedStyles} from '@/theme';

import {styles} from './ColorPicker.style';

import './styles.css';

const ColorPicker: FC<IColorPickerProps> = ({color, setColor, reset}) => {
  const style = useThemedStyles(styles);
  const paletteSlidingColor = useRef<string | null>(null);

  const {
    inputColor,
    setInputColor,
    isInputFocused,
    onHexInputFocus,
    onHexInputBlur,
    onHexInputChange,
  } = useHexInput({color, setColor});

  const onPaletteMoveStart = (endEvent: 'touchend' | 'mouseup') => {
    paletteSlidingColor.current = color;

    window.addEventListener(
      endEvent,
      () => {
        if (paletteSlidingColor.current) {
          setInputColor(paletteSlidingColor.current);
        }
        paletteSlidingColor.current = null;
      },
      {once: true},
    );
  };

  const onPaletteMove = useCallback(
    throttle(50, (result: string) => {
      setColor(result);

      // If paletteSlidingColor.current is not defined at this stage, it means that user already released slider
      // and onPaletteMoveStart already fired, but throttled move event was executed one more time.
      // In such case we can update hex input value with result
      if (paletteSlidingColor.current) {
        paletteSlidingColor.current = result;
      } else {
        setInputColor(result);
      }
    }),
    [setColor],
  );

  useEffect(() => {
    // It is used to sync "inputColor" value after "color" is changed externally in parent component.
    // Syncing is handled in internal methods for hex input changes and palette slide changes,
    // so we only want to sync here if input field is not focused and if user is not currently sliding on palette.
    if (!isInputFocused && !paletteSlidingColor.current) {
      setInputColor(color);
    }
  }, [color]);

  return (
    <>
      <HexColorPicker
        className="spinamp-color-picker"
        color={color}
        onChange={onPaletteMove}
        onMouseDown={() => onPaletteMoveStart('mouseup')}
        onTouchStart={() => onPaletteMoveStart('touchend')}
      />
      <Space h="s" />
      <View style={style.actions}>
        <Space flex>
          <Input
            value={inputColor}
            onChangeText={onHexInputChange}
            onFocus={onHexInputFocus}
            onBlur={onHexInputBlur}
            autoFocus={false}
          />
        </Space>
        {reset && (
          <>
            <Space ml="xs" />
            <Button onPress={reset} textId="reset" style={style.action} />
          </>
        )}
      </View>
    </>
  );
};

export default React.memo(ColorPicker);
