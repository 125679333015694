import {IPickedImage, PickImage} from '@/utils/imagePicker/types';

const getImageDimensions = (
  uri: string,
): Promise<{width: number; height: number}> =>
  new Promise(async (resolve, reject) => {
    const image = document.createElement('img');

    image.onload = () => {
      return resolve({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };

    image.onerror = () => {
      reject();
    };

    image.src = uri;
  });

export const pickImage: PickImage = async (): Promise<IPickedImage> => {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.addEventListener('change', async () => {
      try {
        const file = input.files?.[0];

        if (!file) {
          return reject();
        }

        const uri = URL.createObjectURL(file);
        const type = file.type;
        const {width, height} = await getImageDimensions(uri);

        resolve({
          uri,
          width,
          height,
          type,
          file,
        });
      } catch (error) {
        reject();
      } finally {
        input.value = '';
      }
    });

    input.click();
  });
};
