import Color from 'color';

import {IBasicTheme} from '@/types/theme';

export const getAlphaFromRgb = (rgb: string): number | undefined => {
  try {
    if (!rgb.startsWith('rgba(')) {
      return undefined;
    }

    const separator = rgb.includes(',') ? ',' : ' ';
    const array = rgb.slice(4).split(')')[0].split(separator);
    const alpha = Number(array[array.length - 1]);

    if (alpha >= 0 && alpha <= 1) {
      return alpha;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
};

export const convertToHex = (color: string) => {
  // First try to use input color as it is.
  // If it fails, add # symbol at the beginning and try again
  try {
    return Color(color).hex().toLowerCase();
  } catch (error) {
    return Color(`#${color.replaceAll('#', '')}`)
      .hex()
      .toLowerCase();
  }
};

export const convertToHexSafe = (color: string) => {
  try {
    return convertToHex(color);
  } catch (error) {
    return undefined;
  }
};

// Theme colors validation
const forbiddenColorPairs: (keyof IBasicTheme)[][] = [
  ['text', 'main'],
  ['text', 'background'],
  ['text', 'active'],
  ['favorites', 'main'],
];
const minimumContrast = 1.5;

export const validateThemeColors = (colors: IBasicTheme) => {
  return forbiddenColorPairs.find(
    ([colorName, forbiddenColorName]) =>
      Color(colors[colorName]).contrast(Color(colors[forbiddenColorName])) <=
      minimumContrast,
  );
};
