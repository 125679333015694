import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './Tabs.style';

export interface ITab {
  id: string;
  text?: string;
  textId?: string;
  icon?: IIconProps;
}

interface IProps {
  tabs: ITab[];
  activeTab: string;
  setActiveTab: (newTab: string) => void;
}

const Tabs: FC<IProps> = ({tabs, activeTab, setActiveTab}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.tabsWrapper}>
      {tabs.map((tab, index) => {
        const {id, textId, text, icon} = tab;
        const isActive = tab.id === activeTab;
        const isLast = index === tabs.length - 1;

        return (
          <TouchableOpacity
            key={id}
            style={[
              style.tab,
              isActive && style.tab_active,
              isLast && isActive && style.tab_active_last,
            ]}
            onPress={() => setActiveTab(id)}>
            {icon && (
              <Space mr="xs">
                <Icon size={20} {...icon} />
              </Space>
            )}
            <Text id={textId} weight="semibold" size="s">
              {text}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default Tabs;
