import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {queryClient} from '@/services/reactQuery';
import {
  IDeletedPlaylist,
  IPlaylist,
  IUpdatePlaylistData,
  PlaylistType,
} from '@/types/playlists';
import {QueryKeys} from '@/types/queryKeys';
import {
  createFavsPlaylist,
  mergeLocalAndServerPlaylists,
  migrateAnonPlaylistHelper,
  updatePlaylistAndFollowedPlaylistById,
} from '@/utils/playlists';

export interface PlaylistsState {
  playlists: IPlaylist[];
  deletedPlaylists: IDeletedPlaylist[];
}

export const initialState: PlaylistsState = {
  playlists: [createFavsPlaylist()],
  deletedPlaylists: [],
};

export const playlistsSlice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    addPlaylist: (state, action: PayloadAction<IPlaylist>) => {
      state.playlists.push({
        ...action.payload,
      });
    },
    ensureFavsPlaylistForUser: (state, action: PayloadAction<string>) => {
      if (
        !state.playlists.some(
          playlist =>
            playlist.type === PlaylistType.favorites &&
            playlist.collector === action.payload,
        )
      ) {
        state.playlists.push({
          ...createFavsPlaylist(),
          collector: action.payload,
        });
      }
    },
    updatePlaylists: (state, action: PayloadAction<IPlaylist[]>) => {
      state.playlists = mergeLocalAndServerPlaylists(
        state.playlists,
        action.payload,
      );
    },
    assignCollectorToPlaylists: (state, action: PayloadAction<string>) => {
      state.playlists.forEach(playlist => {
        playlist.collector = playlist.collector || action.payload;
      });
    },
    migrateAnonPlaylists: (
      state,
      action: PayloadAction<{
        playlists: IPlaylist[];
        collector: string;
      }>,
    ) => {
      const anonUserPlaylists = state.playlists;
      const returningUserPlaylists = action.payload.playlists;
      const collector = action.payload.collector;

      state.playlists = migrateAnonPlaylistHelper(
        anonUserPlaylists,
        returningUserPlaylists,
        collector,
      );
    },
    removePlaylist: (
      state,
      action: PayloadAction<{id: string; isLocalOnly?: boolean}>,
    ) => {
      const playlist = state.playlists.find(
        ({id}) => id === action.payload.id,
      )

      state.playlists = state.playlists.filter(
        ({id}) => id !== action.payload.id,
      );

      const collector = playlist?.collector;
      if (playlist && !action.payload.isLocalOnly && collector) {
        const type = playlist.type;
        state.deletedPlaylists.push({
          id: action.payload.id,
          collector,
          type,
          artistId: playlist.artistId,
        });
      }
    },
    changePlaylistId: (
      state,
      action: PayloadAction<{oldId: string; newId: string}>,
    ) => {
      const playlistToChange = state.playlists.find(
        playlist => playlist.id === action.payload.oldId,
      );

      if (playlistToChange) {
        playlistToChange.id = action.payload.newId;
      }
    },
    removeUserPlaylists: (state, action: PayloadAction<{userId: string}>) => {
      state.playlists = state.playlists.filter(
        p => p.collector !== action.payload.userId,
      );
      state.deletedPlaylists = state.deletedPlaylists.filter(
        p => p.collector !== action.payload.userId,
      );
    },
    removeFromDeletedPlaylists: (
      state,
      action: PayloadAction<{id: string}>,
    ) => {
      state.deletedPlaylists = state.deletedPlaylists.filter(
        ({id}) => id !== action.payload.id,
      );
    },
    updatePlaylist: (state, action: PayloadAction<IUpdatePlaylistData>) => {
      state.playlists = updatePlaylistAndFollowedPlaylistById(
        state.playlists,
        action.payload.id,
        () => action.payload.updatedPlaylist,
      );
      // Handle edge case where user is logged into multiple accounts and some of them doesn't own/follow edited playlist.
      // Not owned/followed playlist are not stored in redux so we need to update cache for it in react query.
      queryClient.setQueryData(
        [QueryKeys.playlistById, action.payload.id],
        (data: any) => data && { ...data, ...action.payload.updatedPlaylist },
      );
    },
    updatePlaylistUpdatedServerTime: (
      state,
      action: PayloadAction<{id: string}>,
    ) => {
      state.playlists = updatePlaylistAndFollowedPlaylistById(
        state.playlists,
        action.payload.id,
        p => ({
          serverUpdatedAtTime: p.updatedAtTime,
        }),
      );
    },
    updatePlaylistUpdatedClientTime: (
      state,
      action: PayloadAction<{id: string; time?: number}>,
    ) => {
      state.playlists = updatePlaylistAndFollowedPlaylistById(
        state.playlists,
        action.payload.id,
        () => ({updatedAtTime: action.payload.time ?? Date.now()}),
      );
    },
  },
});

export const {
  addPlaylist,
  ensureFavsPlaylistForUser,
  removePlaylist,
  assignCollectorToPlaylists,
  migrateAnonPlaylists,
  updatePlaylists,
  changePlaylistId,
  removeFromDeletedPlaylists,
  removeUserPlaylists,
  updatePlaylist,
  updatePlaylistUpdatedServerTime,
  updatePlaylistUpdatedClientTime,
} = playlistsSlice.actions;

export default playlistsSlice.reducer;
