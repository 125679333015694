import {IIconProps} from '@/components/Icon';
import {IArtist} from '@/types/common';
import {IPlaylist} from '@/types/playlists';
import {IBaseUser} from '@/types/user';

export enum LibraryCategory {
  favorites = 'favorites',
  playlists = 'playlists',
  collectors = 'collectors',
  artists = 'artists',
  history = 'history',
}

export enum LibrarySubcategory {
  ownPlaylists = 'ownPlaylists',
  followedPlaylists = 'followedPlaylists',
  collaborativePlaylists = 'collaborativePlaylists',
}

export interface ILibrarySubcategory {
  id: LibrarySubcategory;
  textId: string;
  icon?: IIconProps;
}

export interface ILibraryCategory {
  id: LibraryCategory;
  textId: string;
  titleId: string;
  subcategories?: ILibrarySubcategory[];
}

export interface IPlaylistWithFollowedArtist extends IPlaylist {
  followedArtist: IArtist;
}

export interface IPlaylistWithFollowedUser extends IPlaylist {
  followedUser: IBaseUser;
}
