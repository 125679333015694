export interface IArtistUserFollow {
    id?: string;
    userId: string;
    artistId: string;
    createdAtTime?: number;
}

export const getArtistUserFollowId = (data: {
    userId: string
    artistId: string
}): string => {
    if (!data.userId || !data.artistId) {
        throw new Error('Missing userId or artistId');
    }
    return `${data.userId}/${data.artistId}`
}

