import React, {
  FC,
  Fragment,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {ScrollView, View} from 'react-native';

import Button from '@/components/Button';
import Collapse from '@/components/Collapse';
import ColorPicker from '@/components/ColorPicker';
import Header from '@/components/Header';
import HeaderButton from '@/components/HeaderButton';
import Pill from '@/components/Pill';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {IBoxStyleConfig} from '@/components/ThemeCreator/types';
import {useThemes} from '@/hooks/useThemes';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {useThemedStyles} from '@/theme';
import {ThemeContext} from '@/theme';
import {makeTheme} from '@/themes/partialTheme';
import {IBasicTheme, IDynamicTheme, ITheme} from '@/types/theme';
import {capitalizeString} from '@/utils/string';

import ColorPills from './components/ColorPills';
import ThemePreview from './components/ThemePreview';
import {boxStylesConfig, colorsConfig} from './constants';
import {styles} from './ThemeCreator.style';

interface IProps {
  onSubmit: (themeSettings: IDynamicTheme) => void;
  onCancel: () => void;
  isSaving: boolean;
  initialValues?: IDynamicTheme;
  titleId: string;
  infoTextId: string;
}

const mapThemeToForm = (theme: ITheme) => ({
  text: theme.colors.primary,
  main: theme.colors.background,
  background: theme.colors.backgroundLight,
  active: theme.colors.active,
  favorites: theme.colors.favoritesColor,
});

const ThemeCreator: FC<IProps> = ({
  onSubmit,
  onCancel,
  isSaving,
  initialValues = {},
  titleId,
  infoTextId,
}) => {
  const style = useThemedStyles(styles);
  const {getTheme} = useThemes();
  const {availableThemes} = useCustomThemesConfigQuery();
  const defaultTheme = mapThemeToForm(getTheme());

  const customThemeSnapshot = useRef<IBasicTheme>({
    ...defaultTheme,
    ...initialValues?.customTheme,
  });

  const [themeSettings, setThemeSettings] = useState<IDynamicTheme>({
    predefinedThemeName: initialValues.predefinedThemeName || null,
    customTheme: initialValues?.customTheme
      ? customThemeSnapshot.current
      : null,
  });

  const isDefaultTheme =
    !themeSettings.customTheme && !themeSettings.predefinedThemeName;

  const saveCustomThemeSnapshot = () => {
    if (themeSettings.customTheme) {
      customThemeSnapshot.current = themeSettings.customTheme;
    }
  };

  const setDefaultThemeMode = () => {
    saveCustomThemeSnapshot();
    setThemeSettings({predefinedThemeName: null, customTheme: null});
  };

  const setPredefinedThemeMode = (themeName: string) => {
    saveCustomThemeSnapshot();
    setThemeSettings({
      predefinedThemeName: themeName,
      customTheme: null,
    });
  };

  const setCustomThemeMode = () =>
    setThemeSettings({
      predefinedThemeName: null,
      customTheme: customThemeSnapshot.current,
    });

  const [editedColor, setEditedColor] = useState<keyof IBasicTheme>(
    colorsConfig[0].name,
  );

  const updateEditedColor = useCallback(
    (colorHex: string) => {
      setThemeSettings(currentSettings => ({
        ...currentSettings,
        customTheme: {
          ...currentSettings.customTheme,
          [editedColor]: colorHex,
        },
      }));
    },
    [editedColor],
  );

  const setBoxStyling = (boxStyle: IBoxStyleConfig['value']) => {
    setThemeSettings(currentSettings => ({
      ...currentSettings,
      customTheme: {...currentSettings.customTheme, box: boxStyle},
    }));
  };

  const resetCustomTheme = () =>
    setThemeSettings({
      predefinedThemeName: initialValues.predefinedThemeName || null,
      customTheme: {
        ...defaultTheme,
        ...initialValues?.customTheme,
      },
    });

  const previewTheme = useMemo(() => {
    if (themeSettings.customTheme) {
      return makeTheme({
        basic: themeSettings.customTheme,
      });
    }

    if (themeSettings.predefinedThemeName) {
      return getTheme(themeSettings.predefinedThemeName);
    }

    return undefined;
  }, [themeSettings]);

  const customThemeColors =
    themeSettings.customTheme || customThemeSnapshot.current;

  return (
    <Screen>
      <Header
        titleId={titleId}
        leftActions={<HeaderButton onPress={onCancel} text={{id: 'discard'}} />}
        rightActions={
          <HeaderButton
            onPress={() => onSubmit(themeSettings)}
            text={{id: 'save'}}
            isLoading={isSaving}
          />
        }
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={style.container}>
        <Text size="xs" id={infoTextId} />

        <Space mt="s" />

        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={style.horizontalScrollWrapper}
          contentContainerStyle={style.horizontalScroll}>
          <Button
            onPress={setDefaultThemeMode}
            disabled={isDefaultTheme}
            textId="themeCreator.default"
          />
          <Space w="xs" />
          <Button
            onPress={setCustomThemeMode}
            textId="themeCreator.custom"
            disabled={!!themeSettings.customTheme}
          />
          {availableThemes.map(predefinedTheme => (
            <ThemeContext.Provider
              key={predefinedTheme.name}
              value={{theme: getTheme(predefinedTheme.name)}}>
              <Space w="xs" />
              <Button
                onPress={() => setPredefinedThemeMode(predefinedTheme.name)}
                text={
                  capitalizeString(predefinedTheme.name) +
                  (predefinedTheme?.emoji ? ' ' + predefinedTheme.emoji : '')
                }
                disabled={
                  themeSettings.predefinedThemeName === predefinedTheme.name
                }
              />
            </ThemeContext.Provider>
          ))}
        </ScrollView>

        <Space mt="m" />
        <View style={style.divider} />
        <Space mt="m" />

        <View style={style.themeEditorContainer}>
          <Collapse isOpen={!!themeSettings.customTheme}>
            <View style={style.themeEditorContent}>
              <View style={style.pillsContainer}>
                <ColorPills
                  editedColor={editedColor}
                  setEditedColor={setEditedColor}
                  colors={colorsConfig.map(config => ({
                    ...config,
                    color: customThemeColors[config.name],
                  }))}
                />
              </View>

              <Space mt="s" />

              <ColorPicker
                color={customThemeColors[editedColor] || '#000'}
                setColor={updateEditedColor}
                reset={resetCustomTheme}
              />

              <Space mt="s" />

              <ScrollView
                horizontal
                showsHorizontalScrollIndicator={false}
                style={style.horizontalScrollWrapper}
                contentContainerStyle={style.horizontalScroll}>
                <Text id="themeCreator.boxStyling" />
                {boxStylesConfig.map(config => (
                  <Fragment key={config.value}>
                    <Space w="xs" />
                    <Pill
                      onPress={() => setBoxStyling(config.value)}
                      text={{id: config.labelId}}
                      isSelected={
                        themeSettings.customTheme?.box === config.value
                      }
                    />
                  </Fragment>
                ))}
              </ScrollView>

              <Space mt="xl" mb="m">
                <View style={style.divider} />
              </Space>
            </View>
          </Collapse>
        </View>

        {previewTheme ? (
          <>
            <Text
              align="center"
              weight="semibold"
              size="s"
              id="themeCreator.preview"
            />
            <Space mt="xs" />
            <View style={style.previewWrapper}>
              <ThemeContext.Provider value={{theme: previewTheme}}>
                <ThemePreview />
              </ThemeContext.Provider>
            </View>
          </>
        ) : (
          <Text align="center" size="xs" id="themeEdit.defaultDisclaimer" />
        )}
      </ScrollView>
    </Screen>
  );
};

export default ThemeCreator;
