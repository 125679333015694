import {Platform, StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const BORDER_WIDTH = 2;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    wrapper: {
      flex: 1,
      ...Platform.select({
        ios: {
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
        },
      }),
    },
    content: {
      flex: 1,
      ...Platform.select({
        ios: StyleSheet.absoluteFillObject,
      }),
      paddingTop: Math.max(spacing.xs, safeAreaInsets.top),
      paddingRight: safeAreaInsets.right,
      paddingBottom: Math.max(spacing.xs, safeAreaInsets.bottom),
      paddingLeft: safeAreaInsets.left,
      backgroundColor: theme.colors.backgroundLight,
      borderColor: theme.colors.borderColor,
      borderTopWidth: BORDER_WIDTH,
      borderBottomWidth: BORDER_WIDTH,
      borderRightWidth: BORDER_WIDTH / 2,
      borderLeftWidth: BORDER_WIDTH / 2,
      marginVertical: -BORDER_WIDTH * 2,
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingHorizontal: spacing.s,
    },
    children: {
      flex: 1,
    },
    gradientTop: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: spacing.m,
      zIndex: 1,
      transform: [{rotateX: '180deg'}],
    },
    gradientBottom: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: spacing.m,
      zIndex: 1,
    },
    footer: {
      flexDirection: 'row',
      paddingHorizontal: spacing.s,
    },
  }),
);
