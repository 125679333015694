import React, {FC, ReactNode} from 'react';
import {StyleProp, TouchableOpacity, View} from 'react-native';

import Avatar from '@/components/Avatar/Avatar';
import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {IBaseUser} from '@/types/user';
import {getUserAvatar, getUserDisplayName} from '@/utils/user';

import {styles, AVATAR_SIZE} from './AvatarCard.style';

export interface IAvatarCardProps {
  user: IBaseUser;
  description?: TextProps;
  onPress?: (user: IBaseUser) => void;
  action?: () => void;
  actionIcon?: IIconProps;
  suffixComponent?: ReactNode;
  style?: StyleProp<any>;
}

const AvatarCard: FC<IAvatarCardProps> = ({
  user,
  description,
  onPress,
  action,
  actionIcon,
  suffixComponent,
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);
  const avatarUrl = getUserAvatar(user);

  return (
    <TouchableCard
      style={[style.card, customStyle]}
      onPress={() => onPress?.(user)}
      containerStyle={!onPress && {pointerEvents: 'none'}}>
      {({actionColor}) => (
        <View style={style.container}>
          <Avatar
            id={user.id}
            url={avatarUrl}
            size={AVATAR_SIZE}
            resizeWidth={ImageSize.thumbnail}
          />
          <View style={style.content}>
            <Text weight="semibold" numberOfLines={1} color={actionColor()}>
              {getUserDisplayName(user)}
            </Text>
            {description && (
              <Space mt="xxs">
                <Text
                  numberOfLines={1}
                  color={actionColor()}
                  size="xs"
                  {...description}
                />
              </Space>
            )}
          </View>
          {action && actionIcon && (
            <TouchableOpacity onPress={action} style={style.action}>
              <Icon {...actionIcon} color={actionColor()} />
            </TouchableOpacity>
          )}
          {suffixComponent && <Space mr="xs">{suffixComponent}</Space>}
        </View>
      )}
    </TouchableCard>
  );
};

export default React.memo(AvatarCard);
