import React, {FC, ReactNode} from 'react';
import {Pressable, PressableProps, StyleProp} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import {useThemedStyles} from '@/theme';

import {styles} from './FlatCard.style';

const AnimatedPressable = Animated.createAnimatedComponent(Pressable);

interface IProps extends PressableProps {
  children: ReactNode;
  onPress: () => void;
  style?: StyleProp<any>;
  isSelected?: boolean;
  disabled?: boolean;
}

const FlatCard: FC<IProps> = ({
  children,
  onPress,
  style: customStyle,
  disabled,
  isSelected,
  ...pressableProps
}) => {
  const style = useThemedStyles(styles);

  const pressed = useSharedValue(false);
  const hovered = useSharedValue(false);

  const hoverStyle = useAnimatedStyle(() => ({
    opacity: withTiming(pressed.value ? 0.4 : hovered.value ? 0.65 : 1, {
      duration: 150,
    }),
  }));

  return (
    <AnimatedPressable
      disabled={disabled}
      onPressIn={() => (pressed.value = true)}
      onPressOut={() => (pressed.value = false)}
      onHoverIn={() => (hovered.value = true)}
      onHoverOut={() => (hovered.value = false)}
      onPress={onPress}
      style={[
        style.card,
        isSelected && style.card_selected,
        hoverStyle,
        customStyle,
      ]}
      {...pressableProps}>
      {children}
    </AnimatedPressable>
  );
};

export default FlatCard;
