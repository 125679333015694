import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    paddingVertical: 0,
    paddingHorizontal: spacing.m,
    flex: 1,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: spacing.s,
    paddingVertical: spacing.s,
    marginBottom: spacing.xs,
  },
  chainRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  balanceContainer: {
    alignItems: 'flex-end',
  },
  usdPrice: {
    position: 'absolute',
    top: spacing.xxs / 2,
    right: 0,
  },
  dividerWrapper: {
    marginHorizontal: -spacing.m,
  },
  divider: {
    opacity: 0.1,
  },
  actionsContainer: {
    paddingVertical: spacing.xs * 1.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  action: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
