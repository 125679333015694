import React, {FC} from 'react';

import InfinityList from '@/components/InfinityList';
import LineSeparator, {LINE_SEPARATOR_HEIGHT} from '@/components/LineSeparator';
import {TRACK_NFT_EVENT_HEIGHT, TrackNftEvent} from '@/components/NftEvent';
import NftEventsFilters, {
  useNftEventsFilters,
} from '@/components/NftEventsFilters';
import Space from '@/components/Space/Space';
import {useTrackNftActivityQuery} from '@/queries/nftActivity';
import TrackActivityEmpty from '@/screens/Track/TrackActivityEmpty';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';

import {styles} from './Track.style';

const TrackScreenContents: FC<{
  track: ITrack;
  trackInfo: React.ReactNode;
  areDetailsLoading: boolean;
  onScroll?: (event: any) => void;
  refreshEnabled?: boolean;
}> = ({track, trackInfo, areDetailsLoading, onScroll, refreshEnabled}) => {
  const style = useThemedStyles(styles);

  const filtersConfig = useNftEventsFilters();
  const {
    nftEvents,
    query: {
      isLoading: areEventsLoading,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      refetch,
    },
  } = useTrackNftActivityQuery(track.id, filtersConfig.activeFilter);
  const showFilters = nftEvents.length > 0 || !!filtersConfig.activeFilter;

  return (
    <InfinityList
      data={nftEvents}
      itemSize={TRACK_NFT_EVENT_HEIGHT}
      itemSpacing={LINE_SEPARATOR_HEIGHT}
      contentContainerStyle={style.listContainer}
      onScroll={onScroll}
      ListHeaderComponent={
        <>
          {trackInfo}
          {showFilters && (
            <NftEventsFilters
              {...filtersConfig}
              style={style.filtersContainer}
              contentContainerStyle={style.filters}
            />
          )}
        </>
      }
      keyExtractor={event => event.id}
      renderItem={({item: event}) => <TrackNftEvent event={event} />}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={
        !areDetailsLoading && (isFetchingNextPage || areEventsLoading)
      }
      ItemSeparatorComponent={LineSeparator}
      ListEmptyComponent={
        areEventsLoading ? null : (
          <Space pv="xs">
            <TrackActivityEmpty
              track={track}
              filter={filtersConfig.activeFilter}
            />
          </Space>
        )
      }
      {...(refreshEnabled && {refresh: refetch})}
    />
  );
};

export default TrackScreenContents;
