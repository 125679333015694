import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';

import PlayerControl from '@/components/PlayerControls/PlayerControl';
import {useThemedStyles} from '@/theme';

import {styles} from './PlayerControls.style';

interface IProps {
  onPlay: () => void;
  onPrevious: () => void;
  onNext: () => void;
  hasPrevious?: boolean;
  hasNext?: boolean;
  isPlaying?: boolean;
  iconSize?: number;
  controlStyle?: StyleProp<any>;
}

const PlayerControls: FC<IProps> = ({
  onPlay,
  onPrevious,
  onNext,
  hasPrevious = true,
  hasNext = false,
  isPlaying = false,
  iconSize = 24,
  controlStyle,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.controls}>
      <PlayerControl
        onPress={onPrevious}
        icon={{provider: 'custom', name: 'rewind', size: iconSize}}
        style={[style.cardControlRewind, style.cardControl, controlStyle]}
        disabled={!hasPrevious}
      />
      <PlayerControl
        onPress={onPlay}
        icon={{
          provider: 'custom',
          name: isPlaying ? 'pause' : 'play',
          size: iconSize,
        }}
        style={[style.cardControlPlay, style.cardControl, controlStyle]}
      />
      <PlayerControl
        onPress={onNext}
        icon={{
          provider: 'custom',
          name: 'forward',
          size: iconSize,
        }}
        style={[style.cardControlForward, style.cardControl, controlStyle]}
        disabled={!hasNext}
      />
    </View>
  );
};

export default PlayerControls;
