import React, {FC} from 'react';
import {View} from 'react-native';

import TipSuccessImage from '@/assets/images/tip-success.svg';
import Avatar from '@/components/Avatar';
import SocialShareButtons from '@/components/SocialShareButtons';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {tipChain} from '@/modules/Tip/constants';
import {useSendTipState} from '@/modules/Tip/SendTip/useSendTipState';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {useTheme, useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {
  shareTipOnLens,
  shareTipOnTwitter,
  shareTipOnWarpcast,
} from '@/utils/share';

import {styles, AVATAR_SIZE, IMAGE_HEIGHT} from './SuccessfulTip.style';

const SuccessfulTip: FC = () => {
  const style = useThemedStyles(styles);
  const theme = useTheme();
  const {user, artist, tipTransaction, close} = useSendTipState();

  return (
    <View style={style.container}>
      <View style={style.content}>
        <View style={style.imageContainer}>
          {user && (
            <Avatar
              style={style.userAvatar}
              id={user.id}
              url={user.avatarUrl}
              size={AVATAR_SIZE}
              resizeWidth={ImageSize.avatar}
            />
          )}
          <Avatar
            style={style.artistAvatar}
            id={artist.id}
            url={artist.avatarUrl}
            size={AVATAR_SIZE}
            resizeWidth={ImageSize.avatar}
          />
          <TipSuccessImage
            // @ts-ignore
            style={{color: theme.colors.textColor}}
            height={IMAGE_HEIGHT}
          />
        </View>
        <View style={style.textContainer}>
          <Text
            id="tip.success.header"
            weight="semibold"
            size="l"
            align="center"
          />
          <Text id="tip.success.description" align="center" />
        </View>

        <SocialShareButtons
          style={style.shareButtons}
          shareOnTwitter={() => shareTipOnTwitter(artist)}
          shareOnWarpcast={() => shareTipOnWarpcast(artist)}
          shareOnLens={() => shareTipOnLens(artist)}
        />

        <TransparentButton onPress={close} text={{id: 'goBack'}} />
      </View>

      <View
        style={[
          style.links,
          !tipTransaction.userOpHash && style.links_centered,
        ]}>
        {tipTransaction.userOpHash && (
          <JiffyscanLink
            userOpHash={tipTransaction.userOpHash}
            chainId={tipChain.id}
          />
        )}
        {tipTransaction.txHash && (
          <EtherscanLink txHash={tipTransaction.txHash} chainId={tipChain.id} />
        )}
      </View>
    </View>
  );
};

export default SuccessfulTip;
