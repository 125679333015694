import {Source} from 'react-native-fast-image';

import {ImageSize} from '@/types/media';
import {getResizedUri} from '@/utils/image';

export const useOptimizedImageSource = (
  source: Source,
  width?: ImageSize | number,
) => {
  if (!source.uri) {
    return source;
  }

  return {...source, uri: getResizedUri(source.uri, width)};
};
