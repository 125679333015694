import {useState} from 'react';
import Animated, {
  measure,
  MeasuredDimensions,
  runOnJS,
  runOnUI,
  useAnimatedRef,
  useSharedValue,
} from 'react-native-reanimated';

export const useCardPopover = () => {
  const cardRef = useAnimatedRef<Animated.View>();
  const cardMeasurement = useSharedValue<MeasuredDimensions | null>(null);

  const [isCardPopoverOpen, setIsCardPopoverOpen] = useState(false);

  const openPopover = () => {
    runOnUI(() => {
      cardMeasurement.value = measure(cardRef);
      runOnJS(setIsCardPopoverOpen)(true);
    })();
  };

  return {
    cardRef,
    cardMeasurement,
    isCardPopoverOpen,
    openPopover,
    closePopover: () => setIsCardPopoverOpen(false),
  };
};
