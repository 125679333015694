import React, {FC} from 'react';
import {Pressable, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Divider from '@/components/Divider';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import Tooltip from '@/components/Tooltip';
import UsdPrice from '@/components/UsdPrice';
import {getChainById} from '@/constants/chains';
import {getVerticalHitSlop} from '@/constants/spacing';
import {IGasEstimation} from '@/modules/Transactions/types';
import {useThemedStyles} from '@/theme';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './PriceSummary.style';

interface IProps {
  priceLabelId: string;
  price: bigint;
  chainId: number;
  gasEstimation: IGasEstimation;
  showChain?: boolean;
  isPriceFetching?: boolean;
}

const PriceSummary: FC<IProps> = ({
  priceLabelId,
  price,
  gasEstimation,
  chainId,
  showChain = false,
  isPriceFetching = false,
}) => {
  const style = useThemedStyles(styles);

  const totalGas = gasEstimation.totalGas || BigInt(0);
  const totalPrice = price + totalGas;

  const currency = getChainById(chainId).chain.nativeCurrency;

  return (
    <View style={style.container}>
      {showChain && (
        <View style={[style.row, style.chainRow]}>
          <Text size="xxs">{getChainById(chainId).name}</Text>
        </View>
      )}
      <View style={style.row}>
        <Text size="xs" id="transactions.priceSummary.total" />
        <FadeInOut enabled={isPriceFetching || gasEstimation.isFetching}>
          <Text weight="semibold">
            {formatPrice(
              totalPrice,
              getChainById(chainId).chain.nativeCurrency,
            )}
          </Text>
        </FadeInOut>
      </View>
      <View style={style.row}>
        <Tooltip
          position="top-left"
          content={
            <>
              <FadeInOut enabled={isPriceFetching || gasEstimation.isFetching}>
                <View style={style.tooltipRow}>
                  <Text size="xs" id="transactions.priceSummary.networkFee" />
                  <Text size="xs">
                    {formatPrice(
                      gasEstimation.totalGas || BigInt(0),
                      currency,
                      7,
                    )}
                  </Text>
                </View>
              </FadeInOut>
              <Divider />
              <FadeInOut enabled={isPriceFetching}>
                <View style={style.tooltipRow}>
                  <Text size="xs" id={priceLabelId} />
                  <Text size="xs">{formatPrice(price, currency)}</Text>
                </View>
              </FadeInOut>
            </>
          }>
          {({open, close}) => (
            <Pressable
              onPress={open}
              onHoverIn={open}
              onHoverOut={close}
              hitSlop={getVerticalHitSlop('xs')}
              style={style.feesRow}>
              <Text size="xxs" id="transactions.priceSummary.includingFees" />
              <Icon name="info" provider="custom" size={14} />
            </Pressable>
          )}
        </Tooltip>
        <FadeInOut enabled={gasEstimation.isFetching}>
          <UsdPrice size="xxs" chainId={chainId} wei={totalPrice} />
        </FadeInOut>
      </View>
    </View>
  );
};

export default PriceSummary;
