import React, {FC, useMemo} from 'react';
import {StyleProp, View} from 'react-native';

import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {getFormattedTimeToRelease} from '@/utils/dateTime';

import {styles} from './TimeToRelease.style';

interface IProps {
  track?: ITrack;
  style?: StyleProp<any>;
}

const TimeToRelease: FC<IProps> = ({track, style: customStyle}) => {
  const style = useThemedStyles(styles);

  const timeToRelease = useMemo(() => {
    return track?.mintStart
      ? getFormattedTimeToRelease(track.mintStart)
      : undefined;
  }, [track?.mintStart]);

  if (!timeToRelease) {
    return null;
  }

  return (
    <View style={[style.releaseTimeContainer, customStyle]}>
      <Text
        id="timeToRelease"
        values={{time: timeToRelease}}
        color="invertedTextColor"
      />
    </View>
  );
};

export default TimeToRelease;
