import React, {FC} from 'react';
import {View} from 'react-native';

import Icon from '@/components/Icon';
import Loader from '@/components/Loader';
import Text, {TextProps} from '@/components/Text';
import {DraggableToast} from '@/modules/Toasts/Toast';
import {useThemedStyles} from '@/theme';

import {styles} from './ExpandableToast.style';

interface IProps {
  onPress: () => void;
  text: TextProps;
  isLoading?: boolean;
}

const ExpandableToast: FC<IProps> = ({onPress, text, isLoading}) => {
  const style = useThemedStyles(styles);

  return (
    <DraggableToast onPress={onPress}>
      <View style={style.container}>
        {isLoading ? (
          <Loader
            textId={text.id}
            align={text.align || 'center'}
            color={text.color || 'invertedTextColor'}
            size={text.size || 'xs'}
            weight={text.weight || 'regular'}
          />
        ) : (
          <Text align="center" color="invertedTextColor" size="xs" {...text} />
        )}
        <Icon
          style={style.expandIcon}
          provider="custom"
          name="expand"
          color="invertedTextColor"
          size={18}
        />
      </View>
    </DraggableToast>
  );
};

export default ExpandableToast;
