import {getWalletClient} from '@wagmi/core';
import {createWalletClient, http, parseEther} from 'viem';
import {privateKeyToAccount} from 'viem/accounts';

import {updateArtist} from '@/api/artist';
import {uploadFile} from '@/api/files';
import {fetchTrackById} from '@/api/track';
import {config} from '@/constants/config';
import {premintChain} from '@/modules/DropOnSpinamp/constants';
import {IDropForm, UploadStep} from '@/modules/DropOnSpinamp/types';
import {wagmiConfig} from '@/modules/ExternalWallet/ExternalWalletProvider';
import {Passkey} from '@/services/passkey';
import {spinampServicesApi} from '@/services/spinampServicesApi';
import {AddressString, IAddress} from '@/types/common';
import {IInternalSigner} from '@/types/session';
import {IUser} from '@/types/user';
import {analytics} from '@/utils/analytics';
import {getChainClient} from '@/utils/ethereum';
import {makeJsonFile} from '@/utils/fileUpload';
import {getImageUrl} from '@/utils/ipfs';
import {createShareLinkGetter} from '@/utils/share';

export const uploadPremint = async (
  drop: IDropForm,
  user: IUser,
  wallet: IAddress,
  internalSigner: IInternalSigner,
  formId: string,
  setUploadStep: (step: UploadStep) => void,
) => {
  const artist = drop.artist || user.artistProfile;

  if (wallet.isPasskey) {
    setUploadStep('waitingForPasskey');
    analytics.dropOnSpinampRequestPasskey(formId);
  } else {
    setUploadStep('uploading');
  }

  const walletClient = await walletToWalletClient(wallet);

  setUploadStep('uploading');

  const contract = {
    name: drop.title,
    description: drop.description,
    image: `ipfs://${drop.artworkIpfsHash}`,
    artist: artist?.name,
  };

  const contractIpfsHash = await uploadFile(
    await makeJsonFile('contract', contract),
  );

  const collectionConfig = {
    contractAdmin: drop.publisherWalletAddress as AddressString,
    contractName: drop.title,
    contractURI: `ipfs://${contractIpfsHash}`,
    additionalAdmins: wallet.isPasskey ? [walletClient.account.address] : [],
  };

  const premintExecutorAddress = '0x888870f8bbb8449f4C33F1F370F02f3282480F36';
  const contractAddress = await getChainClient(premintChain.id).readContract({
    address: premintExecutorAddress,
    abi: [
      {
        stateMutability: 'view',
        type: 'function',
        inputs: [
          {
            name: 'contractConfig',
            internalType: 'struct ContractWithAdditionalAdminsCreationConfig',
            type: 'tuple',
            components: [
              {
                name: 'contractAdmin',
                internalType: 'address',
                type: 'address',
              },
              {name: 'contractURI', internalType: 'string', type: 'string'},
              {name: 'contractName', internalType: 'string', type: 'string'},
              {
                name: 'additionalAdmins',
                internalType: 'address[]',
                type: 'address[]',
              },
            ],
          },
        ],
        name: 'getContractWithAdditionalAdminsAddress',
        outputs: [{name: '', internalType: 'address', type: 'address'}],
      },
    ],
    functionName: 'getContractWithAdditionalAdminsAddress',
    args: [
      {
        contractAdmin: collectionConfig.contractAdmin,
        contractURI: collectionConfig.contractURI,
        contractName: collectionConfig.contractName,
        additionalAdmins: collectionConfig.additionalAdmins,
      },
    ],
  });

  const token = {
    name: drop.title,
    description: drop.description,
    image: `ipfs://${drop.artworkIpfsHash}`,
    animation_url: `ipfs://${drop.audioIpfsHash}`,
    content: {
      mime: 'audio/mpeg',
      uri: `ipfs://${drop.audioIpfsHash}`,
    },
    traits: parseGenres(drop),
    artist: artist?.name,
    external_url: createShareLinkGetter('track')(
      `optimism/${contractAddress}/1`,
    ),
  };
  const tokenIpfsHash = await uploadFile(await makeJsonFile('token', token));

  const premintConfig = {
    deleted: false,
    uid: 1, // only support 1 token per contract atm. should add check for artist address and contract uri and increment uid to have multiple tokens per contract
    version: 0,
    tokenConfig: {
      maxSupply:
        drop.uploadMode === 'listenOnly'
          ? BigInt(0)
          : BigInt('18446744073709551615'),
      pricePerToken:
        drop.uploadMode === 'listenOnly' ? BigInt(0) : parseEther(drop.price),
      payoutRecipient:
        drop.uploadMode === 'listenOnly'
          ? drop.publisherWalletAddress
          : drop.payoutWalletAddress,
      maxTokensPerAddress: 0n,
      mintDuration: 0n,
      mintStart: 0n,
      royaltyBPS: 1000,
      tokenURI: `ipfs://${tokenIpfsHash}`,
      createReferral: config.SPINAMP_ADDRESS,
      fixedPriceMinter: '0x227d5294B13EBC893E31494194532727A130Ed4B',
    },
  };

  const typedDataDefinition = {
    types: {
      CreatorAttribution: [
        {name: 'tokenConfig', type: 'TokenCreationConfig'},
        {name: 'uid', type: 'uint32'},
        {name: 'version', type: 'uint32'},
        {name: 'deleted', type: 'bool'},
      ],
      TokenCreationConfig: [
        {name: 'tokenURI', type: 'string'},
        {name: 'maxSupply', type: 'uint256'},
        {name: 'maxTokensPerAddress', type: 'uint64'},
        {name: 'pricePerToken', type: 'uint96'},
        {name: 'mintStart', type: 'uint64'},
        {name: 'mintDuration', type: 'uint64'},
        {name: 'royaltyBPS', type: 'uint32'},
        {name: 'payoutRecipient', type: 'address'},
        {name: 'fixedPriceMinter', type: 'address'},
        {name: 'createReferral', type: 'address'},
      ],
    },
    primaryType: 'CreatorAttribution' as const,
    domain: {
      chainId: premintChain.id,
      name: 'Preminter',
      version: '2',
      verifyingContract: contractAddress,
    },
    message: premintConfig,
  };
  if (!wallet.isPasskey) {
    analytics.dropOnSpinampRequestSignature(formId, drop);
    setUploadStep('waitingForSignature');
  }

  const signature = await walletClient.signTypedData(typedDataDefinition);
  analytics.dropOnSpinampSigned(formId, drop);

  setUploadStep('submitting');

  const payload = {
    collectionConfig,
    premintConfig: {
      ...premintConfig,
      tokenConfig: {
        ...premintConfig.tokenConfig,
        maxSupply: premintConfig.tokenConfig.maxSupply.toString(),
        pricePerToken: premintConfig.tokenConfig.pricePerToken.toString(),
        mintStart: premintConfig.tokenConfig.mintStart.toString(),
        mintDuration: premintConfig.tokenConfig.mintDuration.toString(),
        maxTokensPerAddress:
          premintConfig.tokenConfig.maxTokensPerAddress.toString(),
      },
    },
    chainId: premintChain.code,
    signature,
    offchainOnly: drop.uploadMode === 'listenOnly',
  };

  const {trackId}: {trackId: string} = await spinampServicesApi.post(
    'premint',
    payload,
  );
  analytics.dropOnSpinampComplete(formId, trackId);

  let track = await fetchTrackById(trackId);

  if (drop.artist) {
    await updateArtist(
      {
        id: track.artist.id,
        name: drop.artist.name,
        avatarUrl: getImageUrl(drop.artist.avatarIpfsHash),
        avatarIPFSHash: drop.artist.avatarIpfsHash,
      },
      internalSigner,
    );
    track = {
      ...track,
      artist: {
        ...track.artist,
        name: drop.artist.name,
        avatarUrl: getImageUrl(drop.artist.avatarIpfsHash),
      },
    };
  }

  return track;
};

const walletToWalletClient = async (wallet: IAddress) => {
  if (wallet.isPasskey) {
    const {privateKey} = await Passkey.get(
      wallet.metadata?.spinampWallet?.credentialId,
    );

    return createWalletClient({
      account: privateKeyToAccount(privateKey as AddressString),
      chain: premintChain.chain,
      transport: http(premintChain.rpcUrl),
    });
  }

  const walletClient = await getWalletClient(wagmiConfig, {
    chainId: premintChain.id,
  });

  if (!walletClient) {
    throw new Error('No wallet connected in drop on spinamp form');
  }

  return walletClient;
};

const parseGenres = (drop: IDropForm) => {
  if (drop.genre) {
    return [{value: drop.genre, trait_type: 'GENRE'}];
  }

  return [];
};
