import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    container: {
      flex: 1,
      paddingBottom: responsive.isMobile ? safeAreaInsets.bottom : 0,
    },
  }),
);
