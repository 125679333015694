import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const rippleSize = spacing.s * 2;

export const styles = createThemedStyles(theme => ({
  container: {
    zIndex: -1,
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ripple: {
    position: 'absolute',
    width: rippleSize,
    height: rippleSize,
    borderRadius: rippleSize / 2,
    backgroundColor: theme.colors.backgroundDark,
  },
  number: {
    position: 'absolute',
  },
}));
