import DomPurify from 'dompurify';
import React from 'react';

import {useTheme} from '@/theme';

import {getTagsStyles, getDefaultStyle} from './constants';
import type {TagsStyles} from './constants';
import {IProps} from './types';

const RenderHtml: React.FC<IProps> = ({html, style}) => {
  const theme = useTheme();
  const tagsStyles = getTagsStyles(theme);

  const applyStylesHook = applyStyles(tagsStyles);

  // Apply styling from getTagsStyles to the html
  DomPurify.addHook('uponSanitizeElement', applyStylesHook);
  const sanitisedHtml = DomPurify.sanitize(html);
  DomPurify.removeHook('uponSanitizeElement');

  return (
    <div
      dangerouslySetInnerHTML={{__html: sanitisedHtml}}
      style={{...getDefaultStyle(theme), ...style}}
    />
  );
};

/**
 * Applies the correct font family to each tag.
 *
 * Because we're using different fonts to achieve different text style effects,
 * we need to make sure that the font family is set correctly for each tag.
 *
 * @param tagsStyles
 * @returns
 */
const applyStyles =
  (tagsStyles: TagsStyles) =>
  (node: Element, data: DomPurify.SanitizeElementHookEvent) => {
    Object.entries(tagsStyles).forEach(([tag, rules]) => {
      const styleString = `font-family: ${rules.fontFamily}`;

      if (data.tagName === tag) {
        node.setAttribute('style', styleString);
      }
    });
  };

export default React.memo(RenderHtml);
