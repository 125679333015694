import {RouteProp, useRoute} from '@react-navigation/native';
import React, {FC, useCallback} from 'react';

import AsyncContent from '@/components/AsyncContent';
import Button from '@/components/Button';
import Header from '@/components/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen/Screen';
import Share from '@/components/Share';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import TrackCard, {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsFollowedPlaylist} from '@/hooks/useIsFollowedPlaylist';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useCreatePlaylist, useDeletePlaylist} from '@/queries/ownedPlaylists';
import {usePlatformTracksQuery} from '@/queries/platforms';
import {usePlatformsQuery} from '@/queries/platforms';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {playNewQueue} from '@/store/player';
import {ThemedScreen, useThemedScreenStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {PlaylistType} from '@/types/playlists';
import {MainStackParams, Routes} from '@/types/routes';
import {getPlatformName} from '@/utils/musicPlatforms';
import {getPlatformUrl} from '@/utils/share';

import {styles} from './Platform.style';

const PlatformScreen: FC = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const dispatch = useAppDispatch();
  const {
    params: {platformId},
  } = useRoute<RouteProp<MainStackParams, Routes.Platform>>();

  const {
    platformNames,
    query: {isLoading: arePlatformsLoading},
  } = usePlatformsQuery();
  const platformName = getPlatformName(platformId, platformNames);
  const {
    tracks,
    query: {
      isLoading: areTracksLoading,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    },
  } = usePlatformTracksQuery(platformId);
  const {isTrackActive} = useIsTrackActive(
    PlayContextType.platform,
    platformId,
  );

  const {deletePlaylistMutation} = useDeletePlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();

  const {isFollowed, unfollowId} = useIsFollowedPlaylist(
    'platformId',
    platformId,
  );
  const {platformThemes} = useCustomThemesConfigQuery();

  const {style, theme} = useThemedScreenStyles(styles, {
    predefinedThemeName: platformThemes[platformId],
  });

  const toggleFollow = () => {
    if (unfollowId) {
      deletePlaylistMutation(unfollowId);
    } else {
      createPlaylistMutation({
        title: platformName,
        type: PlaylistType.platform,
        platformId,
      });
    }
  };

  const play = useCallback(
    (track?: ITrack) => {
      dispatch(
        playNewQueue({
          trackId: track?.id,
          trackIds: tracks.map(t => t.id),
          context: {
            source: 'Platform',
            type: PlayContextType.platform,
            id: platformId,
            title: platformName,
          },
        }),
      );
    },
    [platformId, platformName, tracks],
  );

  return (
    <ThemedScreen theme={theme}>
      <Screen>
        <Header showBack title={platformName} scrollPosition={scrollPosition} />
        <AsyncContent
          isLoading={
            arePlatformsLoading || (areTracksLoading && tracks.length === 0)
          }
          isError={tracks.length === 0}>
          {() => {
            const ListHeaderComponent = (
              <Space mb="s" style={style.centeredColumn}>
                <Text
                  size="l"
                  weight="semibold"
                  id="platform.header"
                  align="center"
                  values={{platform: platformName}}
                />
                <Space mt="xs" style={style.actions}>
                  <Button
                    style={style.action}
                    onPress={() => play()}
                    textId="play"
                    icon={{
                      name: 'control-play',
                      size: 14,
                    }}
                  />
                  <Space ml="xs" />
                  <Share title={platformName} url={getPlatformUrl(platformId)}>
                    {({icon, textId, onPress}) => (
                      <Button
                        style={style.action}
                        onPress={onPress}
                        textId={textId}
                        icon={{
                          ...icon,
                          size: 16,
                        }}
                      />
                    )}
                  </Share>
                  <Space ml="xs" />
                  <Button
                    style={style.action}
                    onPress={toggleFollow}
                    textId={isFollowed ? 'unfollow' : 'follow'}
                    icon={{
                      name: 'heart',
                      fill: isFollowed,
                      color: isFollowed ? 'favoritesColor' : 'textColor',
                      provider: 'custom',
                      size: 16,
                    }}
                  />
                </Space>
              </Space>
            );

            return (
              <InfinityList
                data={tracks}
                itemSize={TRACK_CARD_HEIGHT}
                onScroll={onScroll}
                keyExtractor={track => track.id}
                extraData={isTrackActive}
                renderItem={({item: track}) => (
                  <TrackCard
                    track={track}
                    isActive={isTrackActive(track.id)}
                    onPlay={play}
                    showArtist
                  />
                )}
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage || areTracksLoading}
                ListHeaderComponent={ListHeaderComponent}
              />
            );
          }}
        </AsyncContent>
      </Screen>
    </ThemedScreen>
  );
};

export default PlatformScreen;
