import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  action: {
    minWidth: 70,
    justifyContent: 'center',
  },
}));
