import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const ARROW_SIZE = spacing.s;

export const styles = createThemedStyles(theme => ({
  popover: {
    backgroundColor: theme.colors.backgroundLight,
    borderRadius: theme.box.borderRadius,
    position: 'absolute',
    zIndex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing.s,
    gap: spacing.s,

    shadowColor: theme.colors.textColor,
    shadowOffset: {width: -spacing.xxs / 2, height: spacing.xxs / 2},
    shadowOpacity: 0.4,
    shadowRadius: spacing.xxs,
  },
  arrow: {
    alignSelf: 'center',
    position: 'absolute',
  },
}));
