import React, {FC} from 'react';
import {View, StyleSheet} from 'react-native';

import spinLogo from '@/assets/images/logo_cropped.png';
import DynamicImage from '@/components/DynamicImage/DynamicImage';
import spacing from '@/constants/spacing';
import {useTheme} from '@/theme';

interface IProps {
  size?: number;
  showThemeMask?: boolean;
}

const SpinLogo: FC<IProps> = ({size = spacing.xl, showThemeMask = true}) => {
  const theme = useTheme();

  return (
    <View style={{width: size, height: size}}>
      <DynamicImage source={spinLogo} style={{width: size, height: size}} />
      {showThemeMask && (
        <View
          style={{
            ...StyleSheet.absoluteFillObject,
            width: size,
            height: size,
            borderRadius: size / 2,
            backgroundColor: theme.colors.primaryLight,
            opacity: 0.2,
          }}
        />
      )}
    </View>
  );
};

export default SpinLogo;
