import {Platform} from 'react-native';

export const LIST_SETTINGS = {
  windowSize: Platform.select({
    ios: 5,
    // use default for android
    android: undefined,
    default: 20,
  }),
};
