import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import ScrollContainer from '@/components/ScrollContainer';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useBooleanState} from '@/hooks/useBooleanState';
import OnboardingTile from '@/modules/Wallets/screens/WalletList/components/OnboardingTile';
import {useThemedStyles} from '@/theme';
import {isNative} from '@/utils/platform';

import {styles} from './WalletOnboarding.style';

interface IProps {
  onClose: () => void;
}

const WalletOnboarding: FC<IProps> = ({onClose}) => {
  const style = useThemedStyles(styles);
  const [isInfoModalOpen, openInfoModal, closeInfoModal] =
    useBooleanState(false);

  return (
    <OnboardingTile onClose={onClose}>
      <Icon size={spacing.xxl} name="wallet" provider="custom" />

      <Text
        align="center"
        size="xs"
        weight="semibold"
        id="wallets.onboarding.title"
      />

      <Text align="center" size="xs" id="wallets.onboarding.description" />

      <TransparentButton
        onPress={openInfoModal}
        text={{
          id: 'wallets.onboarding.button',
          size: 'xs',
          underline: true,
        }}
        icon={{name: 'info', provider: 'custom', size: 16}}
      />

      {isInfoModalOpen && (
        <Modal
          isOpen
          onClose={closeInfoModal}
          titleId="wallets.onboarding.button"
          style={[isNative && style.mobileModal]}>
          <ScrollContainer contentStyle={style.modalContent}>
            <Icon name="wallet" provider="custom" size={spacing.l * 3} />

            <Text
              weight="semibold"
              id="wallets.onboardingModal.header"
              align="center"
            />

            <View style={style.modalTextContainer}>
              <Text size="xs" id="wallets.onboardingModal.text1" />
              <Text size="xs" id="wallets.onboardingModal.text2" />
              <Text size="xs" id="wallets.onboardingModal.text3" />
            </View>

            <CardButton
              style={style.modalButton}
              onPress={closeInfoModal}
              text={{id: 'wallets.onboardingModal.button'}}
            />
          </ScrollContainer>
        </Modal>
      )}
    </OnboardingTile>
  );
};

export default WalletOnboarding;
