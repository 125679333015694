export type PasskeyErrorCode =
  | 'MISSING_BLOB'
  | 'SAVE_BLOB_ERROR'
  | 'NO_CREDENTIAL_RETURNED'
  | 'USER_CANCELLED';

export class PasskeyError extends Error {
  status: PasskeyErrorCode;

  constructor(status: PasskeyErrorCode) {
    super(status);
    this.status = status;
  }
}
