import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar/Avatar';
import AvatarLayout from '@/components/AvatarLayout';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import {CollapsibleRenderHtml} from '@/components/RenderHtml';
import Share from '@/components/Share';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {externalPlatformsConfig} from '@/constants/externalPlatforms';
import spacing from '@/constants/spacing';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useIsFollowedPlaylist} from '@/hooks/useIsFollowedPlaylist';
import {useResponsive} from '@/hooks/useResponsive';
import {WalletListModal} from '@/modules/Wallets';
import {useCreatePlaylist, useDeletePlaylist} from '@/queries/ownedPlaylists';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {PlaylistType} from '@/types/playlists';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {IBaseUser} from '@/types/user';
import {getSpecialAddress} from '@/utils/ethereum';
import {getCollectorUrl} from '@/utils/share';
import {
  getUserAddress,
  getUserAvatar,
  getUserDisplayName,
  getUserSlug,
} from '@/utils/user';

import {styles, AVATAR_SIZE} from './CollectorInfo.style';

interface IProps {
  user: IBaseUser;
  isOwnProfile: boolean;
}

const CollectorInfo: FC<IProps> = ({user, isOwnProfile}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();
  const {isMobile} = useResponsive();

  const [isWalletsModalOpen, openWalletsModal, closeWalletsModal] =
    useBooleanState(false);

  const {deletePlaylistMutation} = useDeletePlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();

  const address = getUserAddress(user);
  const predefinedName = getSpecialAddress(address)?.name;
  const displayName = predefinedName || getUserDisplayName(user);

  const {isFollowed, unfollowId} = useIsFollowedPlaylist(
    'followedCollectionAddress',
    user.id,
  );

  const goToEdit = () =>
    navigation.navigate(Routes.CollectorEdit, {
      slug: getUserSlug(user!),
    });

  const toggleFollow = () => {
    if (user) {
      if (unfollowId) {
        deletePlaylistMutation(unfollowId);
      } else {
        createPlaylistMutation({
          title: getUserDisplayName(user),
          type: PlaylistType.followedCollection,
          followedCollectionAddress: user.id,
        });
      }
    }
  };

  return (
    <View style={style.container}>
      <AvatarLayout
        avatar={
          <Avatar
            expandable
            id={user.id}
            url={getUserAvatar(user)}
            size={AVATAR_SIZE}
            resizeWidth={ImageSize.user}
          />
        }
        title={
          <>
            <Text
              weight="semibold"
              size="l"
              align="center"
              style={style.titleText}>
              {displayName}
            </Text>
            {user.addresses.some(a => a.isPublic) && (
              <TransparentButton
                pressedColor="background"
                onPress={openWalletsModal}
                height={spacing.l}
                text={{id: 'wallets.title', weight: 'regular', size: 'xs'}}
                contentStyle={{left: 16 / 2}}
                suffix={<Icon name="arrowRight" provider="custom" size={16} />}
              />
            )}
          </>
        }
        leftAction={
          <Share
            url={getCollectorUrl(getUserSlug(user))}
            title={getUserDisplayName(user)}>
            {({onPress, icon}) => <IconButton onPress={onPress} icon={icon} />}
          </Share>
        }
        rightAction={
          isOwnProfile ? (
            <IconButton
              onPress={goToEdit}
              icon={{name: 'edit', provider: 'custom'}}
            />
          ) : (
            <IconButton
              onPress={toggleFollow}
              icon={{
                name: 'heart',
                provider: 'custom',
                fill: isFollowed,
                color: isFollowed ? 'favoritesColor' : 'textColor',
              }}
            />
          )
        }
      />

      {!!user.description && (
        <View style={style.description}>
          <CollapsibleRenderHtml html={user.description} closedHeight={100} />
        </View>
      )}

      {user.externalLinks?.length > 0 && (
        <View style={style.socialLinks}>
          {user.externalLinks.map(externalLink => {
            const config = externalPlatformsConfig[externalLink.type];

            return (
              <IconButton
                size={isMobile ? spacing.xxl : undefined}
                key={externalLink.id}
                href={externalLink.url}
                icon={{
                  name: config?.icon || 'externalPlatform',
                  provider: 'custom',
                }}
              />
            );
          })}
        </View>
      )}

      {isWalletsModalOpen && (
        <WalletListModal
          addresses={user.addresses}
          isOpen
          onClose={closeWalletsModal}
        />
      )}
    </View>
  );
};

export default CollectorInfo;
