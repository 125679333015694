import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const LOGO_SIZE = 70;
export const GRID_SIZE = {
  columns: 8,
  rows: 4,
};
const GRID_GAP = 4;

export const styles = createThemedStyles(theme => ({
  wrapper: {
    flex: 1,
  },
  container: {
    backgroundColor: theme.colors.backgroundLight,
    flex: 1,
  },
  content: {
    paddingHorizontal: spacing.m,
    paddingVertical: spacing.l,
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  tracksGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: -GRID_GAP / 2,
  },
  track: {
    padding: GRID_GAP / 2,
    aspectRatio: 1,
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: `${100 / GRID_SIZE.columns}%`,
  },
  trackButton: {
    height: '100%',
    width: '100%',
    borderRadius: 2,
    overflow: 'hidden',
    padding: 0,
    borderWidth: 0,
  },
  artwork: {
    height: '100%',
    width: '100%',
  },
  gradient: {
    pointerEvents: 'none',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '20%',
  },
}));
