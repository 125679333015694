import {useNavigation} from '@react-navigation/native';
import React, {FC, useEffect} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import Player from '@/components/Player';
import Screen from '@/components/Screen/Screen';
import {HIT_SLOP} from '@/constants/spacing';
import {useAppSelector} from '@/hooks/useRedux';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {selectCurrentTrack} from '@/store/player';
import {LocalThemeProvider, useThemedStyles} from '@/theme';
import {RootStackNavigationParams} from '@/types/routes';
import {isIOS} from '@/utils/platform';

import {styles} from './Player.style';

const PlayerScreen = () => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const currentTrack = useAppSelector(selectCurrentTrack);
  const style = useThemedStyles(styles);

  useEffect(() => {
    if (!currentTrack) {
      navigation.goBack();
    }
  }, [currentTrack]);

  if (!currentTrack) {
    return null;
  }

  return (
    <Screen>
      {!isIOS && (
        <View style={style.header}>
          <TouchableOpacity
            onPress={() => navigation.goBack()}
            hitSlop={HIT_SLOP}>
            <Icon name="arrowDown" provider="custom" />
          </TouchableOpacity>
        </View>
      )}

      <Player currentTrack={currentTrack} />
    </Screen>
  );
};

const PlayerScreenThemed: FC = () => {
  const currentTrack = useAppSelector(selectCurrentTrack);

  const {artistThemes, platformThemes} = useCustomThemesConfigQuery();

  const trackArtistTheme =
    currentTrack?.artistId && artistThemes[currentTrack.artistId];

  const trackPlatformTheme =
    currentTrack?.platformId && platformThemes[currentTrack.platformId];

  return (
    <LocalThemeProvider
      customTheme={currentTrack?.artist?.customTheme}
      predefinedThemeName={trackArtistTheme || trackPlatformTheme}
      shouldFallbackToAppTheme>
      <PlayerScreen />
    </LocalThemeProvider>
  );
};

export default PlayerScreenThemed;
