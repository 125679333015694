import React, {FC} from 'react';
import {View} from 'react-native';

import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles} from './TrackSummary.style';

interface IProps {
  track: ITrack;
  minimized?: boolean;
}

const TrackSummary: FC<IProps> = ({track, minimized = false}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={[style.container, minimized && style.container_minimized]}>
      {track.lossyArtworkUrl && (
        <StaticImage
          style={[style.image, minimized && style.image_minimized]}
          source={{
            uri: track.lossyArtworkUrl,
          }}
          resizeWidth={ImageSize.track}
        />
      )}
      <View
        style={[
          style.textsContainer,
          minimized && style.textsContainer_minimized,
        ]}>
        <Text weight="semibold" align={minimized ? 'left' : 'center'}>
          {track.title}
        </Text>
        <Text size="xs" align={minimized ? 'left' : 'center'}>
          {getTrackArtistsNames(track)}
        </Text>
      </View>
    </View>
  );
};

export default TrackSummary;
