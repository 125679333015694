import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const PLAYLIST_CARD_HEIGHT = 53;
const ACTION_WIDTH = spacing.xl - spacing.xxs;

export const styles = createThemedStyles(() => ({
  card: {
    flexDirection: 'row',
    padding: 0,
    height: PLAYLIST_CARD_HEIGHT,
  },
  cardSpace: {
    flexDirection: 'row',
  },
  content: {
    flex: 1,
    justifyContent: 'space-evenly',
    paddingHorizontal: spacing.s,
    paddingVertical: spacing.xxs,
  },
  action: {
    width: ACTION_WIDTH,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
