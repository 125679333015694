import {StyleSheet} from 'react-native';
import {EdgeInsets} from 'react-native-safe-area-context';

import {IResponsiveInfo} from '@/types/common';
import {ITheme, ThemedStyles} from '@/types/theme';

export const createThemedStyles =
  (generateTheme: ThemedStyles) =>
  (theme: ITheme, responsive: IResponsiveInfo, safeAreaInsets: EdgeInsets) =>
    StyleSheet.create(generateTheme(theme, responsive, safeAreaInsets));
