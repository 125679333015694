import {initialState as eventsInitialState} from '@/store/events';
import {initialState as playerInitialState} from '@/store/player';
import {initialState as playlistsInitialState} from '@/store/playlists';
import {initialState as themeInitialState} from '@/store/theme';
import {initialState as userInitialState} from '@/store/user';
import {generatePlaylistId} from '@/utils/playlists';

// there are some typescript issues in migrations config so just using .js file
// https://github.com/rt2zz/redux-persist/issues/1065
export const migrations = {
  0: state => {
    return {
      ...state,
    };
  },
  1: state => {
    return {
      ...state,
      player: playerInitialState,
    };
  },
  2: state => {
    return {
      ...state,
      player: playerInitialState,
    };
  },
  3: state => {
    return {
      ...state,
      player: {
        ...state.player,
        deletedPlaylistIds: state.player.deletedPlaylistIds || [],
      },
    };
  },
  4: state => {
    return {
      ...state,
      user: {
        ...state.user,
        theme: 'retro',
      },
    };
  },
  5: state => {
    return {
      ...state,
      user: {
        ...state.user,
        theme: 'modern',
        showThemeSwitcherModal: true,
      },
    };
  },
  6: state => {
    return {
      ...state,
      player: {
        ...state.player,
        shuffledTrackIds: [],
      },
    };
  },
  7: state => {
    return {
      ...state,
      events: {
        playbackEvents: [],
        isSyncing: false,
      },
    };
  },
  8: state => {
    return {
      ...state,
      player: {
        queue: null,
        playlists: state.player.playlists.filter(
          playlist => !['nowPlaying', 'nft', 'latest'].includes(playlist.id),
        ),
        isPlaying: state.player.isPlaying,
        isBuffering: state.player.isBuffering,
        repeatMode: state.player.repeatMode,
        dbSyncTimestamp: state.player.dbSyncTimestamp,
        deletedPlaylistIds: state.player.deletedPlaylistIds,
      },
    };
  },
  9: state => {
    return {
      ...state,
      player: {
        ...state.player,
        queue: null,
      },
    };
  },
  10: state => {
    return {
      ...state,
      player: {
        ...state.player,
        queue: null,
      },
    };
  },
  11: state => {
    return {
      ...state,
      user: {
        ...state.user,
        onboarding: {
          showMarketFeedInfo: true,
        },
      },
    };
  },
  12: state => {
    return {
      ...state,
      player: {
        ...state.player,
        queue: null,
      },
    };
  },
  13: state => {
    return {
      ...state,
      user: {
        ...state.user,
        searchHistory: [],
      },
      player: {
        ...state.player,
        queue: null,
      },
    };
  },
  14: state => {
    return {
      ...state,
      player: {
        ...state.player,
        queue: null,
      },
    };
  },
  15: state => {
    return {
      ...state,
      player: {
        queue: null,
        isPlaying: state.player.isPlaying,
        isBuffering: state.player.isBuffering,
        repeatMode: state.player.repeatMode,
        dbSyncTimestamp: state.player.dbSyncTimestamp,
      },
      playlists: {
        playlists: state.player.playlists,
        deletedPlaylistIds: state.player.deletedPlaylistIds,
      },
    };
  },
  16: state => {
    return {
      ...state,
      user: {
        ...state.user,
        defaultTheme: state.user.theme,
        themesSettings: {},
        theme: undefined,
      },
    };
  },
  17: state => {
    return {
      ...state,
      theme: {
        defaultTheme: state.user.defaultTheme,
        themesSettings: state.user.themesSettings,
        cardsThemingEnabled: false,
      },
      user: {
        ...state.user,
        defaultTheme: undefined,
        themesSettings: undefined,
      },
    };
  },
  18: state => {
    const isLoggedIn = state.user.linkedKeys.length > 0;

    // for logged in users we want to make them login again and resync
    if (isLoggedIn) {
      return {
        ...state,
        player: playerInitialState,
        user: {
          ...userInitialState,
          onboarding: {
            ...userInitialState.onboarding,
            showLogoutExplanation: true,
          },
          searchHistory: state.user.searchHistory,
        },
        events: eventsInitialState,
        playlists: playlistsInitialState,
      };
    }

    return {
      ...state,
      player: playerInitialState,
      user: {
        ...userInitialState,
        searchHistory: state.user.searchHistory,
      },
      events: eventsInitialState,
      playlists: {
        playlists: state.playlists.playlists.map(playlist => ({
          ...playlist,
          id: generatePlaylistId(),
          serverUpdatedAtTime: undefined,
          updatedAtTime: Date.now(),
        })),
        deletedPlaylists: [],
      },
    };
  },
  19: state => {
    return {
      ...state,
      player: {
        ...state.player,
        rateMode: 1,
      },
    };
  },
  20: state => {
    return {
      ...state,
      user: {
        ...state.user,
        onboarding: {
          ...state.user.onboarding,
          showFeedOnboarding1: true,
        },
      },
      theme: themeInitialState,
    };
  },
  21: state => {
    // MIGRATION FOR COLLECT ON MOBILE & SPINAMP WALLET
    // We need to force users to login again so all necessary flags on their wallets are set in spindexer during sign in flow
    const isLoggedIn = !!state.user.activeUserId;

    if (isLoggedIn) {
      return {
        ...state,
        theme: themeInitialState,
        player: playerInitialState,
        user: {
          ...userInitialState,
          onboarding: {
            ...userInitialState.onboarding,
            showLogoutExplanation: true,
          },
          searchHistory: state.user.searchHistory,
        },
        events: eventsInitialState,
        playlists: playlistsInitialState,
      };
    }

    return state;
  },
  22: state => {
    return {
      ...state,
      user: {
        ...state.user,
        onboarding: {
          ...state.user.onboarding,
          showFeedOnboardingSpinampWelcome: true,
          showFeedOnboardingFeedWelcome: true,
          showFeedOnboardingSpyWelcome: true,
        },
      },
    };
  },
  23: state => {
    return {
      ...state,
      user: {
        ...state.user,
        onboarding: {
          ...state.user.onboarding,
          showFeedOnboardingNotifications: true,
        },
      },
    };
  },
};
