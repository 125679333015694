import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {IAddressWithOwner} from '@/types/common';
import {BASE_USER_FRAGMENT, parseApiBaseUser} from '@/utils/apiModelParsers';

export const fetchWalletById = async (id: string) => {
  const response = await pipelineApi.request(
    gql`
      query Address($id: String!) {
        addressById(id: $id) {
          address: id
          ens: ensName
          avatarUrl: ensAvatar
          isPublic
          isSession
          isWallet
          isPasskey
          metadata
          userByUserId {
            ...BaseUserFragment
          }
        }
      }
      ${BASE_USER_FRAGMENT}
    `,
    {
      id,
    },
  );

  if (!response.addressById || response.addressById.isSession) {
    return null;
  }

  const {userByUserId, ...address} = response.addressById;
  const user = userByUserId ? parseApiBaseUser(userByUserId) : undefined;

  return {
    ...address,
    user,
  } as IAddressWithOwner;
};
