import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContainer: {
    marginHorizontal: -spacing.s,
    marginBottom: spacing.xs,
  },
  scrollContent: {
    gap: spacing.xs,
    flexDirection: 'row',
    flexGrow: 1,
    paddingHorizontal: spacing.s,
  },
}));
