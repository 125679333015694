import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import Text from '@/components/Text';
import {tipChain} from '@/modules/Tip/constants';
import Toast from '@/modules/Toasts/Toast';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {formatPrice} from '@/utils/ethereum';

import {styles} from './TipNotificationToast.style';

interface IProps {
  totalBalance: bigint;
  onClose: () => void;
}
const TipNotificationToast: FC<IProps> = ({totalBalance, onClose}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <Toast
      onClose={onClose}
      onPress={() =>
        navigation.navigate(Routes.MainNavigation, {
          screen: Routes.TipsSettings,
        })
      }
      children={
        <Text
          align="center"
          color="invertedTextColor"
          size="xs"
          id="tip.toastNotification.message"
          values={{
            amount: formatPrice(totalBalance, tipChain.chain.nativeCurrency),
          }}
        />
      }
      footer={
        <View style={style.footer}>
          <Text
            weight="semibold"
            align="center"
            size="s"
            id="tip.toastNotification.action"
          />
        </View>
      }
    />
  );
};

export default TipNotificationToast;
