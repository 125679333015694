import {useNavigation} from '@react-navigation/native';
import {createContext, FC, ReactNode} from 'react';
import {encodeFunctionData} from 'viem';
import {useBalance} from 'wagmi';

import {useUserById} from '@/hooks/useActiveUser';
import {useAppDispatch} from '@/hooks/useRedux';
import {
  useGasEstimation,
  useSyncTransactionState,
} from '@/modules/Transactions';
import {IGasEstimation} from '@/modules/Transactions/types';
import {
  ITrackTransferPayload,
  ITrackTransferState,
  ITransferParams,
} from '@/modules/Transfer';
import {
  getDefaultDeliveryAddress,
  getTransferWriteContractInput,
} from '@/modules/Transfer/utils';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Sentry} from '@/services/sentry';
import {updateTransaction} from '@/store/transactions/slice';
import {IAddress, IBalance, ITrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {IUser} from '@/types/user';
import {areAddressesEqual} from '@/utils/ethereum';
import {generateId} from '@/utils/functions';

export interface ITransferStateContext {
  transfer: ITrackTransferState;
  updateTransfer: (update: Partial<ITrackTransferState>) => void;
  track: ITrack;
  user?: IUser;
  senderWallet?: IAddress;
  balance?: IBalance;
  deliveryWallet?: IAddress;
  close: () => void;
  gasEstimation: IGasEstimation;
  resetTransferDetails: () => void;
}

export const TransferStateContext = createContext<
  ITransferStateContext | undefined
>(undefined);

interface IProps {
  transferId: string | undefined;
  initialUser?: IUser;
  track: ITrack;
  initialTransferParams: ITransferParams;
  trackTransferPayload: ITrackTransferPayload;
  children: ReactNode;
}

const TransferStateProvider: FC<IProps> = ({
  transferId,
  initialUser,
  initialTransferParams,
  trackTransferPayload,
  track,
  children,
}) => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();

  const {transaction: transfer} = useSyncTransactionState(
    transferId,
    'trackTransfer',
    () =>
      ({
        id: generateId(),
        createdDate: Date.now(),
        isMinimized: false,
        type: 'trackTransfer',
        userId: initialUser?.id,
        step: 'checkout',
        from: initialTransferParams.from,
        to: getDefaultDeliveryAddress(
          initialUser,
          initialTransferParams.from,
          initialTransferParams.to,
        ),
        chainId: initialTransferParams.chainId,
        txHash: undefined,
        userOpHash: undefined,
        error: undefined,
        slug: track.slug,
        contract: trackTransferPayload.contract,
        tokenId: trackTransferPayload.tokenId,
        tokenType: trackTransferPayload.tokenType,
      } satisfies ITrackTransferState),
  );
  const user = useUserById(transfer.userId);

  const senderWallet = user?.addresses.find(a =>
    areAddressesEqual(a.address, transfer.from),
  );

  const deliveryWallet = user?.addresses.find(a =>
    areAddressesEqual(a.address, transfer.to),
  );

  const gasEstimation = useGasEstimation(
    {
      chainId: transfer.chainId,
      wallet: senderWallet,
      transaction: transfer.to
        ? {
            to: transfer.contract,
            data: encodeFunctionData(getTransferWriteContractInput(transfer)),
          }
        : undefined,
      onError: error => {
        Sentry.captureException(error, {
          tags: {
            transfer: true,
            simulation: true,
            passkeyWallet: !!senderWallet && isPasskeyWallet(senderWallet),
          },
          extra: {
            trackId: track.id,
            wallet: senderWallet,
          },
        });
      },
    },
    transfer.step === 'checkout',
  );

  const {data: balance} = useBalance({
    address: senderWallet?.address,
    chainId: transfer.chainId,
    query: {
      enabled: !!senderWallet,
    },
  });

  const updateTransfer = (update: Partial<ITrackTransferState>) => {
    dispatch(
      updateTransaction({
        id: transfer.id,
        type: 'trackTransfer',
        update,
      }),
    );
  };

  return (
    <TransferStateContext.Provider
      value={{
        transfer,
        updateTransfer,
        track,
        user,
        senderWallet,
        balance,
        deliveryWallet,
        close: () => navigation.goBack(),
        gasEstimation,
        resetTransferDetails: () =>
          updateTransfer({
            txHash: undefined,
            userOpHash: undefined,
            error: undefined,
          }),
      }}>
      {children}
    </TransferStateContext.Provider>
  );
};

export default TransferStateProvider;
