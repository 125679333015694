import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  successContainer: {
    alignItems: 'center',
    gap: spacing.l,
    paddingBottom: spacing.s,
  },
  successTexts: {
    gap: spacing.xs,
  },
  trackCard: {
    alignSelf: 'stretch',
  },
  borderedSection: {
    alignSelf: 'stretch',
    borderTopWidth: 1,
    borderColor: theme.colors.borderColor,
    borderStyle: 'dashed',
    marginHorizontal: -spacing.l,
    paddingHorizontal: spacing.l,
    alignItems: 'center',
  },
  artistInfo: {
    marginTop: -spacing.s,
    paddingTop: spacing.l,
    gap: spacing.l,
  },
  artistInfoTexts: {
    gap: spacing.xs,
  },
  contactInfo: {
    paddingTop: spacing.s,
    marginBottom: -spacing.m,
  },
}));
