import {Dimensions} from 'react-native';

import {isWeb} from '@/utils/platform';

import {LAYOUT_MAIN_ELEMENT_ID} from './constants';

export const getLayoutWidth = () => {
  const screenWidth = Dimensions.get('window').width;

  if (isWeb) {
    return (
      document.getElementById(LAYOUT_MAIN_ELEMENT_ID)?.clientWidth ||
      screenWidth
    );
  }

  return screenWidth;
};
