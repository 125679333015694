import React, {FC} from 'react';
import {View} from 'react-native';

import Switch from '@/components/Switch';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';
import {IAddressWithOwner} from '@/types/common';

import {useUpdateWalletMutation} from './useUpdateWalletMutation';
import {styles} from './WalletSettings.style';

interface IProps {
  wallet: IAddressWithOwner;
}
const WalletSettings: FC<IProps> = ({wallet}) => {
  const style = useThemedStyles(styles);

  const updateWalletMutation = useUpdateWalletMutation();

  return (
    <View style={style.container}>
      <Text id="wallet.settings.title" weight="semibold" />
      <View style={style.settingRow}>
        <Text size="xs" id="wallet.settings.public" />
        <Switch
          value={wallet.isPublic}
          setValue={() =>
            updateWalletMutation.mutate({
              id: wallet.address,
              isPublic: !wallet.isPublic,
            })
          }
        />
      </View>
    </View>
  );
};

export default WalletSettings;
