import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const CARD_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  card: {
    height: CARD_HEIGHT,
    borderRadius: theme.box.borderRadius,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: theme.colors.backgroundDark,
    padding: spacing.xs,
  },
  card_selected: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.borderColor,
  },
}));
