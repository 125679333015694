import {useQueryClient} from '@tanstack/react-query';
import {useCallback, useMemo, useRef, useState} from 'react';

import {useUpdatePlaylist} from '@/queries/ownedPlaylists';
import {usePlaylistCollaboratorsQuery} from '@/queries/publicPlaylists';
import {IPlaylistModeTileProps} from '@/screens/PlaylistCollaborators/components/PlaylistModeTile';
import {IPlaylist, PlaylistMode} from '@/types/playlists';
import {QueryKeys} from '@/types/queryKeys';
import {IBaseUser} from '@/types/user';

const modesConfig: Pick<
  IPlaylistModeTileProps,
  'mode' | 'titleId' | 'descriptionId' | 'iconName'
>[] = [
  {
    mode: 'solo',
    titleId: 'collaborators.mode.solo',
    descriptionId: 'collaborators.mode.solo.description',
    iconName: 'playlistSolo',
  },
  {
    mode: 'free-for-all',
    titleId: 'collaborators.mode.free',
    descriptionId: 'collaborators.mode.free.description',
    iconName: 'playlistFree',
  },
];

export const useCollaboratorsSettings = (playlist: IPlaylist) => {
  const queryClient = useQueryClient();
  const didAddCollaborator = useRef(false);

  const {updatePlaylistMutation} = useUpdatePlaylist();

  const collaboratorsIds = useMemo(
    () =>
      (playlist?.collaborators || []).reduce<{[userId: string]: boolean}>(
        (result, c) => ({...result, [c.userId]: true}),
        {},
      ),
    [playlist?.collaborators],
  );
  const collaborators = usePlaylistCollaboratorsQuery(playlist);

  const [collaboratorToRemove, setCollaboratorToRemove] = useState<IBaseUser>();
  const cancelRemoving = () => setCollaboratorToRemove(undefined);

  const addCollaborator = useCallback(
    (user: IBaseUser) => {
      const currentCollaborators = playlist.collaborators || [];
      const updatedCollaborators = [...currentCollaborators, {userId: user.id}];

      updatePlaylistMutation({
        id: playlist.id,
        updatedPlaylist: {
          collaborators: updatedCollaborators,
        },
      });

      queryClient.setQueryData(
        [QueryKeys.playlistCollaborators, playlist.id, updatedCollaborators],
        () => [...collaborators, user],
      );

      didAddCollaborator.current = true;
    },
    [playlist, collaborators],
  );

  const removeCollaborator = useCallback(
    (user: IBaseUser) => {
      const currentCollaborators = playlist?.collaborators || [];
      const updatedCollaborators = currentCollaborators.filter(
        c => c.userId !== user.id,
      );

      updatePlaylistMutation({
        id: playlist.id,
        updatedPlaylist: {
          collaborators: updatedCollaborators,
        },
      });

      queryClient.setQueryData(
        [QueryKeys.playlistCollaborators, playlist.id, updatedCollaborators],
        () => collaborators.filter(c => c.id !== user.id),
      );
    },
    [playlist, collaborators],
  );

  const toggleCollaborator = useCallback(
    (user: IBaseUser) => {
      const isIncluded = playlist.collaborators?.some(
        c => c.userId === user.id,
      );

      if (isIncluded) {
        setCollaboratorToRemove(user);
      } else {
        addCollaborator(user);
      }
    },
    [playlist, addCollaborator],
  );

  const changePlaylistMode = (mode: PlaylistMode) => {
    if (playlist) {
      updatePlaylistMutation({
        id: playlist.id,
        updatedPlaylist: {
          mode,
        },
      });
    }
  };

  return {
    collaboratorsIds,
    collaborators,
    toggleCollaborator,
    collaboratorToRemove,
    cancelRemoving,
    removeCollaborator,
    modesConfig,
    changePlaylistMode,
    didAddCollaborator,
  };
};
