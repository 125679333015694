import React, {FC} from 'react';

import VerifyAddressCheckout from '@/modules/Tip/VerifyAddress/components/VerifyAddressCheckout';
import VerifyAddressError from '@/modules/Tip/VerifyAddress/components/VerifyAddressError';
import VerifyAddressPending from '@/modules/Tip/VerifyAddress/components/VerifyAddressPending';
import VerifyAddressSuccess from '@/modules/Tip/VerifyAddress/components/VerifyAddressSuccess';
import {useVerifyAddressState} from '@/modules/Tip/VerifyAddress/useVerifyAddressState';

const VerifyAddressRouter: FC = () => {
  const {transaction} = useVerifyAddressState();
  const {error, step} = transaction;

  if (error) {
    return <VerifyAddressError />;
  }

  if (step === 'success') {
    return <VerifyAddressSuccess />;
  }

  if (step === 'executingTransaction' || step === 'waitingForReceipt') {
    return <VerifyAddressPending />;
  }

  return <VerifyAddressCheckout />;
};

export default VerifyAddressRouter;
