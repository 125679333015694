import React from 'react';

import InfoModal from '@/components/InfoModal';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {selectOnboardingInfo, setOnboardingInfo} from '@/store/user';

const FeedInfoModal = () => {
  const dispatch = useAppDispatch();
  const {showMarketFeedInfo} = useAppSelector(selectOnboardingInfo);

  const onClose = () => {
    dispatch(setOnboardingInfo({key: 'showMarketFeedInfo', value: false}));
  };

  if (!showMarketFeedInfo) {
    return null;
  }

  return (
    <InfoModal
      titleId="activityFeed.informationModalTitle"
      isOpen
      onClose={onClose}
      descriptionId="activityFeed.informationModal"
    />
  );
};

export default FeedInfoModal;
