import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const BUTTON_HEIGHT = spacing.xxl;

export const styles = createThemedStyles(theme => ({
  card: {
    height: spacing.xxl,
    paddingHorizontal: spacing.s,
    borderRadius:
      theme.box.borderRadius > 3 ? BUTTON_HEIGHT / 2 : theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    borderWidth: theme.box.baseBorderWidth,
    borderColor: theme.colors.borderColor,
    borderRightWidth: theme.box.borderRightWidth,
    borderBottomWidth: theme.box.borderBottomWidth,
    overflow: 'hidden',
  },
  disabled: {
    opacity: 0.4,
  },
  hovered: {
    borderColor: theme.colors.invertedBorderColor,
  },
  pressed: {
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.invertedBorderColor,
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    gap: spacing.xxs * 1.5,
  },
  content_loading: {
    opacity: 0,
  },
  loaderContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
