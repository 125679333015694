import NetInfo from '@react-native-community/netinfo';
import {createAsyncStoragePersister} from '@tanstack/query-async-storage-persister';
import type {QueryState} from '@tanstack/query-core/src/query';
import type {QueryKey, QueryMeta} from '@tanstack/query-core/src/types';
import {
  persistQueryClientRestore,
  persistQueryClientSave,
} from '@tanstack/query-persist-client-core';
import {
  QueryClient,
  onlineManager,
  focusManager,
  MutationCache,
} from '@tanstack/react-query';
import {AppState} from 'react-native';
import {deserialize, serialize} from 'wagmi';

import {Sentry} from '@/services/sentry';
import {storage} from '@/services/storage';
import {CACHE_EXCLUDED_KEYS, QueryKeys} from '@/types/queryKeys';
import {restoreFeedQuery} from '@/utils/feed';
import {isLocalDev, isWeb} from '@/utils/platform';

const CACHE_VERSION = 22;
const CACHE_STORAGE_KEY = 'REACT_QUERY_OFFLINE_CACHE';
const getStorageKey = (version: number) => `${CACHE_STORAGE_KEY}_v${version}`;

const CACHE_TIME = Infinity;

const mutationCache = new MutationCache({
  onError: (error: any) => {
    Sentry.captureException(error, {
      extra: {
        context: 'Global mutation error handler',
      },
    });
  },
});

export const queryClient = new QueryClient({
  mutationCache,
  defaultOptions: {
    queries: {
      staleTime: 0,
      gcTime: CACHE_TIME,
      refetchOnWindowFocus: !isWeb || !isLocalDev,
    },
  },
});

queryClient.setQueryDefaults([QueryKeys.walletConnector], {
  gcTime: 0,
});

interface DehydratedQuery {
  queryHash: string;
  queryKey: QueryKey;
  state: QueryState;
  promise?: Promise<unknown>;
  meta?: QueryMeta;
}

const persister = createAsyncStoragePersister({
  key: getStorageKey(CACHE_VERSION),
  storage,
  throttleTime: 3000,
  serialize,
  deserialize: cachedData => {
    const parsedCached: any = deserialize(cachedData);

    return {
      ...parsedCached,
      clientState: {
        ...parsedCached.clientState,
        queries: parsedCached.clientState.queries.reduce(
          (queries: DehydratedQuery[], query: DehydratedQuery) => {
            // Remove queries excluded from persistence
            // @ts-ignore
            if (CACHE_EXCLUDED_KEYS.includes(query.queryKey[0])) {
              return queries;
            }

            if (query.queryKey[0] === QueryKeys.feed && query.state.data) {
              return [...queries, restoreFeedQuery(query)];
            }

            return [...queries, query];
          },
          [],
        ),
      },
    };
  },
});

const clearDeprecatedCache = async () => {
  try {
    // remove legacy cache
    if (CACHE_VERSION > 1) {
      await Promise.all(
        Array.from({length: CACHE_VERSION - 1}).map((_, i) =>
          storage.removeItem(getStorageKey(i + 1)),
        ),
      );
    }
  } catch (error) {
    //ignore error
  }
};

export const setupReactQuery = async () => {
  if (!isWeb) {
    onlineManager.setEventListener(setOnline => {
      return NetInfo.addEventListener(state => {
        setOnline(!!state.isConnected);
      });
    });

    focusManager.setEventListener(handleFocus => {
      const subscription = AppState.addEventListener('change', state => {
        if (state === 'active') {
          handleFocus();
        }
      });

      return () => {
        subscription.remove();
      };
    });
  }

  clearDeprecatedCache();

  await persistQueryClientRestore({
    queryClient,
    persister,
    maxAge: CACHE_TIME,
  });

  queryClient.getQueryCache().subscribe(event => {
    if (
      !CACHE_EXCLUDED_KEYS.includes(event.query.queryKey[0]) &&
      // Logic copied from @tanstack/query-persist-client-core/src/index.ts
      // We want save to be triggered only when event is about cache change, not observers or anything else
      ['added', 'removed', 'updated'].includes(event.type)
    ) {
      persistQueryClientSave({
        queryClient,
        persister,
      });
    }
  });
};
