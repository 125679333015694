import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    padding: spacing.l,
    gap: spacing.l,
  },
  centeredColumn: {
    gap: spacing.l,
    alignItems: 'center',
  },
  action: {
    height: spacing.xxl,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xxs,
    paddingVertical: 0,
  },
}));
