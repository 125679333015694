import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const PILL_HEIGHT = spacing.l + spacing.xs;

export const styles = createThemedStyles(theme => ({
  pill: {
    padding: spacing.xs,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    height: PILL_HEIGHT,
    borderRadius: PILL_HEIGHT / 2,
    justifyContent: 'center',
  },
  pill_pressed: {
    backgroundColor: theme.colors.backgroundDark,
  },
  pill_selected: {
    backgroundColor: theme.colors.active,
    borderColor: theme.colors.activeBorder,
  },
  pillContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xxs * 1.5,
  },
  text: {
    color: theme.colors.textColor,
  },
  text_selected: {
    color: theme.colors.activeText,
  },
}));
