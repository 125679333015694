import {useNavigation} from '@react-navigation/native';
import React, {FC, ReactNode, useCallback, useMemo, memo} from 'react';
import {View} from 'react-native';

import {ArtistCard} from '@/components/ArtistCard';
import ArtistTile from '@/components/ArtistTile';
import ImageCard from '@/components/ImageCard';
import PlaylistCard from '@/components/PlaylistCard';
import Space from '@/components/Space/Space';
import SpinningSlider from '@/components/SpinningSlider';
import Text, {TextProps} from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import TrackCard from '@/components/TrackCard/TrackCard';
import spacing from '@/constants/spacing';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {useLatestArtistsQuery} from '@/queries/artists';
import {useLatestTracksQuery} from '@/queries/db';
import {useExploreQuery} from '@/queries/explore';
import {useHistoryQuery} from '@/queries/history';
import {usePlatformsQuery} from '@/queries/platforms';
import {useReleasesForYouQuery} from '@/queries/releasesForYou';
import {useSpinampTracksQuery} from '@/queries/tracks';
import {useTrendingPlaylistsQuery} from '@/queries/trendingPlaylists';
import {useTrendingTracksQuery} from '@/queries/trendingTracks';
import {useSpinampWrappedQuery} from '@/queries/useSpinampWrappedQuery';
import {LibraryCategory} from '@/screens/Library/types';
import {playNewQueue} from '@/store/player';
import {selectActiveUserId, selectSelectedUserId} from '@/store/user';
import {useTheme} from '@/theme';
import {IArtist, ITrack, PlayContextType} from '@/types/common';
import {GENRES} from '@/types/feed';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {getPlatformName} from '@/utils/musicPlatforms';

const visibleItemsCount = 15;
const listRowsCount = 3;

const useCarouselColumnsNumber = () => {
  const {isMobile} = useResponsive();

  return isMobile ? 1 : 2;
};

const SectionLayout: FC<{children: ReactNode; fullWidth?: boolean}> = ({
  children,
  fullWidth = false,
}) => (
  <View
    style={[
      {gap: spacing.xs, marginBottom: spacing.m},
      !fullWidth && {paddingHorizontal: spacing.s},
    ]}>
    {children}
  </View>
);

const SectionTitle: FC<TextProps> = textProps => {
  const theme = useTheme();

  return (
    <Text
      weight="semibold"
      uppercase={theme.text.sectionHeaderUppercase}
      {...textProps}
    />
  );
};

const SpinampWrapped = () => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const signedUserId = useAppSelector(selectSelectedUserId);
  const {showWrapped} = useExploreQuery();
  const {isWrappedEmpty} = useSpinampWrappedQuery(signedUserId, showWrapped);

  if (!showWrapped || isWrappedEmpty) {
    return null;
  }

  return (
    <SectionLayout>
      <TouchableCard onPress={() => navigation.navigate(Routes.SpinampWrapped)}>
        {({pressed}) => {
          const color = pressed ? 'invertedTextColor' : 'textColor';

          return (
            <>
              <Text
                weight="semibold"
                size="m"
                align="center"
                color={color}
                id="spinampWrapped.title"
              />
              <Space h="xs" />
              <Text
                align="center"
                size="xxs"
                color={color}
                id="spinampWrapped.banner.description"
              />
            </>
          );
        }}
      </TouchableCard>
    </SectionLayout>
  );
};

const PromotedContent = () => {
  const navigation = useNavigation<RootStackNavigationParams>();

  const {featuredPlaylists, promotedPlatforms, featuredArtists} =
    useExploreQuery();

  const {platformNames} = usePlatformsQuery();

  return (
    <>
      {featuredPlaylists.length > 0 && (
        <SectionLayout>
          <SectionTitle id="explore.featured" />
          {featuredPlaylists.map(playlist => (
            <ImageCard
              key={playlist.id}
              onPress={() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Playlist,
                  params: {
                    id: playlist.id,
                  },
                })
              }
              bannerURL={playlist.bannerURL}
              aspectRatio={5}
              transparent
            />
          ))}
        </SectionLayout>
      )}

      {featuredArtists.length > 0 && (
        <SectionLayout>
          <SectionTitle id="explore.featuredArtists" />
          {featuredArtists.map(featuredArtist => (
            <ImageCard
              key={featuredArtist.id}
              onPress={() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Artist,
                  params: {
                    slugOrId: featuredArtist.artist.slug,
                  },
                })
              }
              fallbackText={featuredArtist.artist.name}
              bannerURL={featuredArtist.bannerURL}
              aspectRatio={5}
              transparent
            />
          ))}
        </SectionLayout>
      )}

      {promotedPlatforms.length > 0 && (
        <SectionLayout>
          <SectionTitle id="explore.featuredPlatforms" />
          {promotedPlatforms.map(platform => (
            <ImageCard
              key={platform.id}
              onPress={() =>
                navigation.navigate(Routes.MainNavigation, {
                  screen: Routes.Platform,
                  params: {platformId: platform.id},
                })
              }
              fallbackText={getPlatformName(platform.id, platformNames)}
              bannerURL={platform.bannerURL}
              aspectRatio={5}
            />
          ))}
        </SectionLayout>
      )}
    </>
  );
};

const ForYou: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const userId = useAppSelector(selectActiveUserId);
  const visibleColumns = useCarouselColumnsNumber();

  const {releasesForYouTracks} = useReleasesForYouQuery(userId, true);

  const {isTrackActive} = useIsTrackActive(PlayContextType.releasesForYou);

  const play = useCallback(
    (track: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: releasesForYouTracks?.map(t => t.id) ?? [],
          trackId: track.id,
          context: {
            source: 'ReleasesForYouExplore',
            type: PlayContextType.releasesForYou,
            titleId: 'releasesForYou.title',
          },
        }),
      ),
    [releasesForYouTracks],
  );

  if (!releasesForYouTracks || releasesForYouTracks.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="releasesForYou.title" />}
        data={releasesForYouTracks.slice(0, visibleItemsCount)}
        keyExtractor={track => track.id}
        renderItem={track => (
          <TrackCard
            track={track}
            showArtist
            isActive={isTrackActive(track.id)}
            onPlay={play}
          />
        )}
        visibleColumns={visibleColumns}
        rows={listRowsCount}
        onMorePress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.ReleasesForYou,
          })
        }
      />
    </SectionLayout>
  );
};

const ListenAgain: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const visibleColumns = useCarouselColumnsNumber();

  const {historyTracks} = useHistoryQuery(true);

  const {isTrackActive} = useIsTrackActive(PlayContextType.history);

  const play = useCallback(
    (track: ITrack, index?: number) => {
      dispatch(
        playNewQueue({
          trackIds: historyTracks.map(t => t.track.id),
          index,
          context: {
            source: 'History',
            type: PlayContextType.history,
            titleId: 'library.history',
          },
        }),
      );
    },
    [historyTracks],
  );

  if (historyTracks.length < listRowsCount) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.history" />}
        data={historyTracks.slice(0, visibleItemsCount)}
        keyExtractor={historyTrack => historyTrack.timestamp}
        renderItem={(historyTrack, index) => (
          <TrackCard
            track={historyTrack.track}
            showArtist
            isActive={isTrackActive(historyTrack.track.id, index)}
            onPlay={play}
            index={index}
          />
        )}
        rows={listRowsCount}
        visibleColumns={visibleColumns}
        onMorePress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.Tabs,
            params: {
              screen: Routes.LibraryNavigation,
              params: {
                screen: Routes.Library,
                params: {
                  category: LibraryCategory.history,
                  subcategory: '',
                },
              },
            },
          })
        }
      />
    </SectionLayout>
  );
};

const LatestTracks: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const visibleColumns = useCarouselColumnsNumber();

  const {tracks} = useLatestTracksQuery();

  const {isTrackActive} = useIsTrackActive(PlayContextType.latest);

  const play = useCallback(
    (track: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: tracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'LatestExplore',
            type: PlayContextType.latest,
            titleId: 'playlists.latest',
          },
        }),
      ),
    [tracks],
  );

  if (tracks.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.latestTracks" />}
        data={tracks.slice(0, visibleItemsCount)}
        keyExtractor={track => track.id}
        renderItem={track => (
          <TrackCard
            track={track}
            showArtist
            isActive={isTrackActive(track.id)}
            onPlay={play}
          />
        )}
        rows={listRowsCount}
        visibleColumns={visibleColumns}
        onMorePress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.Tabs,
            params: {
              screen: Routes.ExploreNavigation,
              params: {
                screen: Routes.LatestTracks,
              },
            },
          })
        }
      />
    </SectionLayout>
  );
};

const TrendingArtists: FC = () => {
  const {isMobile} = useResponsive();
  const {trendingTracks} = useTrendingTracksQuery();

  const trendingArtists = useMemo(() => {
    const artists: Record<string, IArtist> = {};
    trendingTracks.forEach(t => {
      artists[t.artist.id] = t.artist;
    });

    return Object.values(artists);
  }, [trendingTracks]);

  if (trendingArtists.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.trendingArtists" />}
        visibleColumns={isMobile ? 2 : 3}
        rows={isMobile ? 1 : 2}
        data={trendingArtists.slice(0, 30)}
        keyExtractor={artist => artist.id}
        renderItem={artist => <ArtistTile artist={artist} />}
      />
    </SectionLayout>
  );
};

export const Genres: FC = () => {
  const {isMobile} = useResponsive();
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.genres" />}
        visibleColumns={isMobile ? 2 : 3}
        rows={2}
        data={GENRES}
        keyExtractor={genre => genre.key}
        renderItem={genre => (
          <TouchableCard
            onPress={() =>
              navigation.navigate(Routes.MainNavigation, {
                screen: Routes.Genre,
                params: {genre: genre.key},
              })
            }>
            {({actionColor}) => (
              <Text
                weight="semibold"
                align="center"
                color={actionColor()}
                numberOfLines={1}
                adjustsFontSizeToFit>
                {genre.text}
              </Text>
            )}
          </TouchableCard>
        )}
      />
    </SectionLayout>
  );
};

const SpinampOriginals: FC = () => {
  const dispatch = useAppDispatch();
  const visibleColumns = useCarouselColumnsNumber();

  const {tracks} = useSpinampTracksQuery();

  const {isTrackActive} = useIsTrackActive(PlayContextType.spinampOriginals);

  const play = useCallback(
    (track: ITrack) => {
      if (!tracks) {
        return;
      }

      dispatch(
        playNewQueue({
          trackIds: tracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'SpinampOriginals',
            type: PlayContextType.spinampOriginals,
            titleId: 'explore.spinamp',
          },
        }),
      );
    },
    [tracks],
  );

  if (!tracks || tracks.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.spinamp" />}
        data={tracks.slice(0, visibleItemsCount)}
        keyExtractor={track => track.id}
        renderItem={track => (
          <TrackCard
            track={track}
            showArtist
            isActive={isTrackActive(track.id)}
            onPlay={play}
          />
        )}
        rows={listRowsCount}
        visibleColumns={visibleColumns}
      />
    </SectionLayout>
  );
};

const TrendingTracks: FC = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const visibleColumns = useCarouselColumnsNumber();

  const {trendingTracks} = useTrendingTracksQuery();

  const {isTrackActive} = useIsTrackActive(PlayContextType.trending);

  const play = useCallback(
    (track: ITrack) =>
      dispatch(
        playNewQueue({
          trackIds: trendingTracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'TrendingExplore',
            type: PlayContextType.trending,
            titleId: 'explore.trendingTracks',
          },
        }),
      ),
    [trendingTracks],
  );

  if (trendingTracks.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.trendingTracks" />}
        data={trendingTracks.slice(0, visibleItemsCount)}
        keyExtractor={track => track.id}
        renderItem={track => (
          <TrackCard
            track={track}
            showArtist
            isActive={isTrackActive(track.id)}
            onPlay={play}
          />
        )}
        rows={listRowsCount}
        visibleColumns={visibleColumns}
        onMorePress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.Tabs,
            params: {
              screen: Routes.ExploreNavigation,
              params: {
                screen: Routes.TrendingTracks,
              },
            },
          })
        }
      />
    </SectionLayout>
  );
};

export const TrendingPlaylists: FC = () => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const visibleColumns = useCarouselColumnsNumber();

  const {trendingPlaylists} = useTrendingPlaylistsQuery();

  if (trendingPlaylists.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.trendingPlaylists" />}
        data={trendingPlaylists.slice(0, visibleItemsCount)}
        keyExtractor={playlist => playlist.id}
        renderItem={playlist => <PlaylistCard playlist={playlist} />}
        rows={listRowsCount}
        visibleColumns={visibleColumns}
        onMorePress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.Tabs,
            params: {
              screen: Routes.ExploreNavigation,
              params: {
                screen: Routes.TrendingPlaylists,
              },
            },
          })
        }
      />
    </SectionLayout>
  );
};

const Platforms: FC = () => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const {featuredPlatforms} = useExploreQuery();
  const {platformNames} = usePlatformsQuery();

  if (featuredPlatforms.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.platforms" />}
        visibleColumns={3}
        data={featuredPlatforms}
        keyExtractor={platformId => platformId}
        renderItem={platformId => (
          <TouchableCard
            key={platformId}
            onPress={() =>
              navigation.navigate(Routes.MainNavigation, {
                screen: Routes.Platform,
                params: {
                  platformId,
                },
              })
            }
            style={{
              minHeight: 60,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            pv="m">
            {({actionColor}) => (
              <Text
                weight="semibold"
                align="center"
                adjustsFontSizeToFit
                numberOfLines={1}
                color={actionColor()}>
                {getPlatformName(platformId, platformNames)}
              </Text>
            )}
          </TouchableCard>
        )}
      />
    </SectionLayout>
  );
};

const LatestArtists = () => {
  const navigation = useNavigation<RootStackNavigationParams>();
  const visibleColumns = useCarouselColumnsNumber();

  const {artists} = useLatestArtistsQuery();

  if (artists.length === 0) {
    return null;
  }

  return (
    <SectionLayout fullWidth>
      <SpinningSlider
        title={<SectionTitle id="explore.latestArtists" />}
        data={artists.slice(0, visibleItemsCount)}
        keyExtractor={artist => artist.id}
        renderItem={artist => <ArtistCard artist={artist} />}
        rows={listRowsCount}
        visibleColumns={visibleColumns}
        onMorePress={() =>
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.Tabs,
            params: {
              screen: Routes.ExploreNavigation,
              params: {
                screen: Routes.Artists,
              },
            },
          })
        }
      />
    </SectionLayout>
  );
};

export const ExploreSection = {
  SpinampWrapped: {
    key: 'SpinampWrapped',
    Component: memo(SpinampWrapped),
  },
  PromotedContent: {
    key: 'PromotedContent',
    Component: memo(PromotedContent),
  },
  ForYou: {
    key: 'ForYou',
    Component: memo(ForYou),
  },
  ListenAgain: {
    key: 'ListenAgain',
    Component: memo(ListenAgain),
  },
  LatestTracks: {
    key: 'LatestTracks',
    Component: memo(LatestTracks),
  },
  TrendingArtists: {
    key: 'TrendingArtists',
    Component: memo(TrendingArtists),
  },
  Genres: {
    key: 'Genres',
    Component: memo(Genres),
  },
  SpinampOriginals: {
    key: 'SpinampOriginals',
    Component: memo(SpinampOriginals),
  },
  TrendingTracks: {
    key: 'TrendingTracks',
    Component: memo(TrendingTracks),
  },
  TrendingPlaylists: {
    key: 'TrendingPlaylists',
    Component: memo(TrendingPlaylists),
  },
  Platforms: {
    key: 'Platforms',
    Component: memo(Platforms),
  },
  LatestArtists: {
    key: 'LatestArtists',
    Component: memo(LatestArtists),
  },
};
