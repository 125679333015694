import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import WarningBox from '@/components/WarningBox';
import {PasskeyError} from '@/services/passkey';
import {useThemedStyles} from '@/theme';
import {isOSXBrowser} from '@/utils/platform';

import {styles} from './UploadError.style';

interface IProps {
  error: any;
  reset: () => void;
}

const UploadError: FC<IProps> = ({error, reset}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.content}>
      {(() => {
        if (error instanceof PasskeyError && error.status === 'MISSING_BLOB') {
          return (
            <WarningBox
              title={{id: 'collect.error.missingBlob'}}
              message={{
                id: isOSXBrowser
                  ? 'collect.error.missingBlob.osx'
                  : 'collect.error.missingBlob.default',
              }}
            />
          );
        }

        return (
          <WarningBox
            title={{id: 'dropOnSpinamp.error.title'}}
            message={{id: 'dropOnSpinamp.error.message'}}
          />
        );
      })()}

      <CardButton onPress={reset} text={{id: 'tryAgain'}} />
    </View>
  );
};

export default UploadError;
