import {Platform} from 'react-native';

import {config} from '@/constants/config';
import {PasskeyError} from '@/services/passkey/errors';
import {generateId} from '@/utils/functions';
import {isAndroid, isIOS, isOSXBrowser, isWeb} from '@/utils/platform';

/**
 * all buffer <-> string conversion methods are taken from source and examples from
 * https://github.com/peterferguson/react-native-passkeys
 */
export const bufferToBase64URLString = (buffer: ArrayBuffer) => {
  const bytes = new Uint8Array(buffer);
  let str = '';

  for (const charCode of bytes) {
    str += String.fromCharCode(charCode);
  }

  const base64String = btoa(str);

  return base64String
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/[=]/g, '');
};

export const base64URLStringToBuffer = (
  base64URLString: string,
): ArrayBuffer => {
  const base64 = base64URLString.replace(/-/g, '+').replace(/_/g, '/');
  const padLength = (4 - (base64.length % 4)) % 4;
  const padded = base64.padEnd(base64.length + padLength, '=');
  const binary = atob(padded);
  const buffer = new ArrayBuffer(binary.length);
  const bytes = new Uint8Array(buffer);

  for (let i = 0; i < binary.length; i++) {
    bytes[i] = binary.charCodeAt(i);
  }

  return buffer;
};

export const utf8StringToBuffer = (value: string) =>
  new TextEncoder().encode(value);

export const arrayBufferToStr = (buf: ArrayBuffer) =>
  new TextDecoder().decode(buf);

export const generateChallenge = () => utf8StringToBuffer(generateId());

export const relyingParty = {
  id: Platform.select({
    native: 'app.spinamp.xyz',
    web: undefined,
  }),
  name: config.APP_NAME,
};

export const parsePasskeyError = (error: any) => {
  if (error instanceof PasskeyError && error.status === 'USER_CANCELLED') {
    return;
  }

  if (error instanceof PasskeyError && error.status === 'MISSING_BLOB') {
    if (isOSXBrowser) {
      return 'passkey.error.missingBlob.osx';
    } else {
      return 'passkey.error.missingBlob.default';
    }
  }

  if (error instanceof PasskeyError && error.status === 'SAVE_BLOB_ERROR') {
    if (isIOS) {
      return 'passkey.error.saveBlobError.ios';
    } else if (isAndroid) {
      return 'passkey.error.saveBlobError.android';
    } else if (isOSXBrowser) {
      return 'passkey.error.saveBlobError.osx';
    } else {
      return 'passkey.error.saveBlobError.web';
    }
  }

  return 'passkey.error.generic';
};
