import {useIsFocused} from '@react-navigation/native';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect} from 'react';

import {useActiveUser} from '@/hooks/useActiveUser';
import {useAppSelector} from '@/hooks/useRedux';
import {markNotificationsAsReadSafe} from '@/modules/Notifications/api';
import {ISpindexerNotification} from '@/modules/Notifications/types';
import {selectActiveUserSigner} from '@/store/user';
import {QueryKeys} from '@/types/queryKeys';

export const useSyncReadStatus = (
  notifications: ISpindexerNotification[] | undefined,
) => {
  const queryClient = useQueryClient();
  const isFocused = useIsFocused();

  const activeUser = useActiveUser();
  const signer = useAppSelector(selectActiveUserSigner);

  // Call spindexer to mark notifications as read as soon as we get the data
  useEffect(() => {
    if (notifications && signer) {
      markNotificationsAsReadSafe(notifications, signer);
    }
  }, [signer, notifications]);

  // Invalidate query when screen becomes unfocused, so read indicators are updated when visiting it back
  useEffect(() => {
    if (activeUser) {
      return () => {
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.notificationsHistory, activeUser.id],
          refetchType: 'all',
        });
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.unreadNotificationsCount, activeUser.id],
        });
      };
    }
  }, [isFocused, activeUser?.id]);
};
