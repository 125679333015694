import React, {FC} from 'react';
import {View} from 'react-native';

import IconButton from '@/components/IconButton';
import Text from '@/components/Text';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './Quantity.style';

interface IProps {
  quantity: number;
  setQuantity: (quantity: number) => void;
  maxQuantity?: number;
  disabled?: boolean;
}

const Quantity: FC<IProps> = ({
  quantity,
  setQuantity,
  maxQuantity = Infinity,
  disabled = false,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.row}>
      <IconButton
        size={spacing.m}
        disabled={quantity <= 1 || disabled}
        icon={{name: 'minus', provider: 'custom', size: 16}}
        onPress={() => setQuantity(quantity - 1)}
      />
      <Text size="xs" weight="semibold">
        {quantity}
      </Text>
      <IconButton
        size={spacing.m}
        disabled={quantity >= maxQuantity || disabled}
        icon={{name: 'add', provider: 'custom', size: 16}}
        onPress={() => setQuantity(quantity + 1)}
      />
    </View>
  );
};

export default Quantity;
