import React, {FC, useMemo} from 'react';
import {ScrollView, TouchableWithoutFeedback, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import {UserCard, AVATAR_CARD_HEIGHT} from '@/components/AvatarCard';
import Card from '@/components/Card/Card';
import Modal, {IModalProps} from '@/components/Modal';
import Space from '@/components/Space/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text/Text';
import {useUsersByIdsQuery} from '@/queries/user';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {ITrackVotes} from '@/types/playlists';
import {normalize} from '@/utils/functions';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles} from './VotersModal.style';

interface IProps extends IModalProps {
  track: ITrack;
  votes: ITrackVotes;
}

const VotersModal: FC<IProps> = ({isOpen, onClose, track, votes}) => {
  const style = useThemedStyles(styles);

  const votersIds = useMemo(
    () =>
      Object.entries(votes)
        .filter(([_, vote]) => vote.upvote)
        .map(([userId]) => userId),
    [],
  );
  const {users: voters} = useUsersByIdsQuery(votersIds);
  const votersByIds = useMemo(() => normalize(voters, 'id').items, [voters]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} titleId="playlistVoters.title">
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={style.content}>
        {/* Using touchable here is a workaround for scroll gesture not working in modal for some reason */}
        <TouchableWithoutFeedback>
          <View style={style.trackInfo}>
            <View style={style.artworkWrapper}>
              <StaticImage
                source={{uri: track.lossyArtworkUrl}}
                resizeWidth={ImageSize.track}
                style={style.artwork}
                hasBorderRadius
              />
            </View>
            <Space h="l" />
            <Text numberOfLines={1} weight="semibold">
              {track.title}
            </Text>
            <Space h="xxs" />
            <Text numberOfLines={1} size="xs">
              {getTrackArtistsNames(track)}
            </Text>
            <Space h="l" />
            <Text
              id="playlistVoters.descriptions"
              values={{count: votersIds.length}}
              weight="semibold"
            />
            <Space h="s" />
          </View>
        </TouchableWithoutFeedback>
        <View style={style.list}>
          {votersIds.map(voterId => {
            const voter = votersByIds[voterId];

            if (voter) {
              return <UserCard key={voterId} user={voter} onPress={onClose} />;
            }

            return (
              <FadeInOut maxOpacity={0.6} key={voterId}>
                <Card style={{height: AVATAR_CARD_HEIGHT}} />
              </FadeInOut>
            );
          })}
        </View>
      </ScrollView>
    </Modal>
  );
};

export default VotersModal;
