import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  divider: {
    marginHorizontal: -spacing.l,
    ...Platform.select({
      web: {
        borderTopWidth: 1,
      },
      default: {
        borderWidth: 1,
      },
    }),
    borderStyle: 'dashed',
    borderColor: theme.colors.backgroundDark,
  },
  mask: {
    position: 'absolute',
    left: 0,
    right: 0,
    height: 1,
    top: 0,
  },
}));
