import React, {FC} from 'react';
import {StyleProp, View} from 'react-native';

import {useTheme, useThemedStyles} from '@/theme';
import {Color} from '@/types/theme';
import {isNative} from '@/utils/platform';

import {styles} from './DashedDivider.style';

interface IProps {
  backgroundColor?: Color;
  style?: StyleProp<any>;
}

const DashedDivider: FC<IProps> = ({
  backgroundColor = 'backgroundLight',
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);
  const theme = useTheme();

  return (
    <View style={[style.divider, customStyle]}>
      {/* On iOS you can't use borderStyle: 'dashed' with borderTopWidth, so we set borderWidth and mask bottom border with View in background color */}
      {isNative && (
        <View
          style={[style.mask, {backgroundColor: theme.colors[backgroundColor]}]}
        />
      )}
    </View>
  );
};

export default DashedDivider;
