import axios from 'axios';

import {config} from '@/constants/config';

export const neynarApi = axios.create({
  baseURL: config.NEYNAR_API_URL,
  headers: {
    api_key: config.NEYNAR_API_KEY,
  },
});

neynarApi.interceptors.response.use(response => response.data);

export const fetchWarpcastUsers = async (addresses: string[]) => {
  if (addresses.length === 0) {
    return [];
  }

  const usersByAddress: {[address: string]: Array<{username: string}>} =
    await neynarApi.get('/farcaster/user/bulk-by-address', {
      params: {
        addresses: addresses.join(','),
      },
    });

  const usernames: string[] = [];

  addresses.forEach(address => {
    const users = usersByAddress[address.toLowerCase()];

    if (users) {
      users.forEach(user => {
        usernames.push(user.username);
      });
    }
  });

  return [...new Set(usernames)];
};
