import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = spacing.s * 2;

export const styles = createThemedStyles(theme => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: spacing.s,
  },
  column: {
    gap: spacing.xs,
  },
  column_right: {
    alignItems: 'flex-end',
  },
  amountRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  pendingPill: {
    height: AVATAR_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.box.borderRadius,
    paddingHorizontal: spacing.xs,
    backgroundColor: theme.colors.background,
  },
  tippers: {
    flexDirection: 'row',
  },
  tippersPlaceholder: {
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    backgroundColor: theme.colors.background,
  },
}));
