import React, {FC} from 'react';
import {View} from 'react-native';

import Space from '@/components/Space';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {TransferStep} from '@/modules/Transfer';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useThemedStyles} from '@/theme';
import {IAddress} from '@/types/common';

import {styles} from './PendingTransfer.style';

interface IProps {
  transferStep: TransferStep;
  senderWallet: IAddress | undefined;
  chainId: number;
  userOpHash: string | undefined;
  txHash: string | undefined;
}

const PendingTransfer: FC<IProps> = ({
  transferStep,
  senderWallet,
  chainId,
  userOpHash,
  txHash,
}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      {transferStep !== 'checkout' && (
        <>
          <SpinLoader />

          <Space h="xs" />

          {txHash || (!!senderWallet && isPasskeyWallet(senderWallet)) ? (
            <Text
              weight="semibold"
              size="s"
              id="transfer.pending.waitingForReceipt"
              align="center"
            />
          ) : (
            <Text
              weight="semibold"
              size="s"
              id="transfer.pending.waitingForSignature"
              align="center"
            />
          )}

          {(txHash || userOpHash) && (
            <View style={style.links}>
              {userOpHash && (
                <View style={style.link}>
                  <JiffyscanLink userOpHash={userOpHash} chainId={chainId} />
                </View>
              )}
              {txHash && (
                <View style={style.link}>
                  <EtherscanLink txHash={txHash} chainId={chainId} />
                </View>
              )}
            </View>
          )}
        </>
      )}
    </View>
  );
};

export default PendingTransfer;
