import React, {FC} from 'react';
import {useIntl} from 'react-intl';

import {TextProps} from '@/components/Text';
import Text from '@/components/Text/Text';
import {NftEventType} from '@/types/nfts';

interface IProps extends TextProps {
  messageId: string;
  filter?: NftEventType;
}

const NftEventsEmpty: FC<IProps> = ({messageId, filter, ...textProps}) => {
  const {formatMessage} = useIntl();
  const textId = `nftEvent.${messageId}.emptyState`;

  if (filter) {
    return (
      <Text
        id={`${textId}.filtered`}
        values={{
          filter: formatMessage({
            id: `nftEvent.type.${filter}`,
          }),
        }}
        {...textProps}
      />
    );
  }

  return <Text id={textId} {...textProps} />;
};

export default NftEventsEmpty;
