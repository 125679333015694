import React, {FC, useRef, useState} from 'react';
import {TouchableWithoutFeedback, View} from 'react-native';

import ImagePreview from '@/components/ImagePreview';
import StaticImage, {IStaticImageProps} from '@/components/StaticImage';
import {useUpdateEffect} from '@/hooks/useUpdateEffect';
import {ImageSize} from '@/types/media';
import {getResizedUri, preloadImages} from '@/utils/image';

interface IProps extends Omit<IStaticImageProps, 'resizeWidth'> {
  resizeWidth: ImageSize | number;
  previewResizeWidth?: ImageSize | number;
}

const ImageWithPreview: FC<IProps> = ({
  resizeWidth,
  previewResizeWidth = ImageSize.fullScreenPreview,
  source,
  style,
  ...props
}) => {
  const isLoaded = useRef(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const openPreview = () => {
    if (isLoaded.current) {
      setIsPreviewOpen(true);
    }
  };

  const closePreview = () => setIsPreviewOpen(false);

  useUpdateEffect(() => {
    isLoaded.current = false;
  }, [source?.uri]);

  const onThumbnailLoad = () => {
    isLoaded.current = true;
    if (source.uri) {
      preloadImages([getResizedUri(source.uri, previewResizeWidth)]);
    }
  };

  return (
    <>
      <TouchableWithoutFeedback onPress={openPreview}>
        <View style={[style, {cursor: 'pointer'}]}>
          <StaticImage
            source={source}
            resizeWidth={resizeWidth}
            onLoad={onThumbnailLoad}
            style={{width: '100%', height: '100%'}}
            {...props}
          />
        </View>
      </TouchableWithoutFeedback>
      {source.uri && (
        <ImagePreview
          isOpen={isPreviewOpen}
          close={closePreview}
          uri={source.uri}
          resizeWidth={previewResizeWidth}
          thumbnailSize={resizeWidth}
        />
      )}
    </>
  );
};

export default ImageWithPreview;
