import React, {FC} from 'react';
import {TouchableOpacity} from 'react-native';

import Space from '@/components/Space/Space';
import Text from '@/components/Text';

interface IProps {
  onPress: () => void;
  textId: string;
}

const EmptyState: FC<IProps> = ({onPress, textId}) => {
  return (
    <Space mt="s">
      <Text align="center" id={textId}>
        You don't have custom theme assigned to your collector profile.
      </Text>
      <Space mt="xs" />
      <TouchableOpacity onPress={onPress}>
        <Text weight="semibold" align="center" id="themesSettings.create" />
      </TouchableOpacity>
    </Space>
  );
};

export default EmptyState;
