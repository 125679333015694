import {useLinkTo} from '@react-navigation/native';
import React, {FC} from 'react';
import {FormattedRelativeTime} from 'react-intl';
import {View} from 'react-native';
import Animated, {FadeOut} from 'react-native-reanimated';

import FlatCard from '@/components/FlatCard';
import Text from '@/components/Text';
import {config} from '@/constants/config';
import {ISpindexerNotification} from '@/modules/Notifications/types';
import {useThemedStyles} from '@/theme';

import {styles} from './NotificationItem.style';

interface IProps {
  notification: ISpindexerNotification;
}

const getNotificationLinkTo = (notification: ISpindexerNotification) =>
  notification.link.split(`${config.APP_URL}:/`)[1];

const NotificationItem: FC<IProps> = ({notification}) => {
  const style = useThemedStyles(styles);
  const linkTo = useLinkTo();

  const relativeTimestamp =
    (new Date(notification.timeToNotify).getTime() - Date.now()) / 1000;

  return (
    <FlatCard
      style={style.item}
      onPress={() => {
        linkTo(getNotificationLinkTo(notification));
      }}>
      <Text size="xs" weight="semibold">
        {notification.title}
      </Text>
      <View style={style.statusRow}>
        <FormattedRelativeTime
          value={relativeTimestamp}
          updateIntervalInSeconds={10}>
          {a => <Text size="xxs">{a}</Text>}
        </FormattedRelativeTime>
        {notification.status !== 'read' && (
          <Animated.View exiting={FadeOut} style={style.unreadBadge} />
        )}
      </View>
    </FlatCard>
  );
};

export default NotificationItem;
