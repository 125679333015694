import {useQuery} from '@tanstack/react-query';

import {fetchTrendingPlaylistsData} from '@/api/trendingPlaylists';
import {MINUTE} from '@/constants/time';
import {QueryKeys} from '@/types/queryKeys';

export const useTrendingPlaylistsQuery = () => {
  const query = useQuery({
    queryKey: [QueryKeys.trendingPlaylists],
    queryFn: fetchTrendingPlaylistsData,
    staleTime: MINUTE,
  });

  return {
    trendingPlaylists: query.data || [],
    query,
  };
};
