import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchLatestTracks} from '@/api/db';
import {
  fetchTrackByIdOrSlug,
  fetchTrackByUrlParam,
  fetchTrackDetails,
  fetchTracksByIds,
  getEarliestTrackReleaseTime,
} from '@/api/track';
import {MINUTE} from '@/constants/time';
import {useDbQuery} from '@/queries/db';
import {ITrack} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {getAllTracksList, getTracksList} from '@/utils/db';
import {getNextPageParam} from '@/utils/pagination';

export const useTrackBySlugQuery = (slug: string) => {
  const {db, updateDb} = useDbQuery();

  // we fetch full track based on slug and save corresponding track id in cache, so we always make sure
  // we have proper oldest matching slug for prettified slugs
  const query = useQuery({
    queryKey: [QueryKeys.trackBySlug, slug],
    queryFn: async () => {
      const track = await fetchTrackByUrlParam(slug);
      if (track) {
        updateDb({
          tracks: [track],
        });

        return track.id;
      }
    },
  });

  const trackId = query.data;
  const track = useMemo(() => {
    if (trackId) {
      return db.tracks[trackId];
    }

    return getAllTracksList(db).find(t => t.slug === slug);
  }, [trackId, slug, db.tracks]);

  return {
    track,
    query,
  };
};

export const useTrackDetailsQuery = (trackId?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.trackDetails, trackId],
    queryFn: () => fetchTrackDetails(trackId!),
    enabled: !!trackId,
  });

  return {
    trackDetails: query.data,
    query,
  };
};

export const useTracksByIdsQuery = (trackIds: string[] = []) => {
  const {db, updateDb} = useDbQuery();

  const query = useQuery({
    queryKey: [QueryKeys.tracksByIds, ...trackIds],
    queryFn: async () => {
      const tracks = await fetchTracksByIds(trackIds!);
      updateDb({tracks});
      return true;
    },
    enabled: trackIds.length > 0,
    staleTime: 1000 * 60 * 5,
    gcTime: 0,
  });

  const tracks = useMemo(
    () => getTracksList(db, trackIds),
    [db.tracks, trackIds],
  );

  return {
    tracks,
    query,
  };
};

export const useEmbedTrackQuery = (id?: string) => {
  const query = useQuery({
    queryKey: [QueryKeys.trackEmbed, id],
    queryFn: () => fetchTrackByIdOrSlug(id!),
    enabled: !!id,
  });

  return {
    trackDetails: query.data,
    query,
  };
};

export const useFilteredTracksQuery = (filter: any) => {
  const {db, updateDb} = useDbQuery();

  const query = useInfiniteQuery({
    queryKey: [QueryKeys.filteredTracks, filter],
    queryFn: async ({pageParam}) => {
      const {items: tracks, pageInfo} = await fetchLatestTracks(
        pageParam,
        50,
        filter,
      );
      updateDb({tracks});
      return {
        pageInfo,
        items: tracks.map(track => track.id),
      };
    },
    initialPageParam: undefined,
    getNextPageParam,
    staleTime: MINUTE,
  });

  const tracks = useMemo(() => {
    return query.data?.pages.reduce<ITrack[]>(
      (result, page) => [...result, ...getTracksList(db, page.items)],
      [],
    );
  }, [query.data, db]);

  return {
    tracks,
    query,
  };
};

export const useGenreTracksQuery = (genre: string) =>
  useFilteredTracksQuery({
    tagsByEntityId: {some: {value: {equalTo: genre}}},
  });

export const useSpinampTracksQuery = () =>
  useFilteredTracksQuery({platformId: {equalTo: 'spinamp'}});
