import {useCallback} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {requestNotificationsPermission} from '@/modules/Notifications/pushNotifications';
import {useDbQuery} from '@/queries/db';
import {useFeedItemMutation} from '@/queries/feed';
import {useCreatePlaylist, useDeletePlaylist} from '@/queries/ownedPlaylists';
import {createFeedItemForArtist} from '@/screens/Feed/utils';
import {Sentry} from '@/services/sentry';
import {selectFollowedArtistsIds, selectUserPlaylists} from '@/store/playlists';
import {selectActiveUserId} from '@/store/user';
import {PlaylistType} from '@/types/playlists';
import {analytics} from '@/utils/analytics';
import {noop} from '@/utils/functions';

/**
 * Allows seeing if an artist is followed and toggling between following/unfollowing them.
 *
 * Note: if you try to follow an artistId which is not in our local DB, this will fail.
 */
export const useArtistFollow = () => {
  const {deletePlaylistMutation} = useDeletePlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();
  const playlists = useAppSelector(selectUserPlaylists);
  const followedArtistsIds = useAppSelector(selectFollowedArtistsIds);
  const userId = useAppSelector(selectActiveUserId);

  const {db} = useDbQuery();

  const {mutate: upsertFeedItem} = useFeedItemMutation({
    // Overwrite default behavior with toast here
    onError: noop,
  });

  const follow = (artistId: string) => {
    const artist = db.artists[artistId];

    if (!artist) {
      Sentry.captureMessage(
        `Can't follow artist ${artistId} because it is not in the local db cache`,
      );

      return;
    }

    if (userId) {
      upsertFeedItem(createFeedItemForArtist(userId, artistId, 'like'));
    }

    analytics.artistFollowed(artistId);
    createPlaylistMutation({
      title: artist.name,
      type: PlaylistType.artist,
      artistId: artist.id,
    });

    requestNotificationsPermission();
  };

  const unfollow = useCallback(
    (artistId: string) => {
      if (userId) {
        upsertFeedItem(createFeedItemForArtist(userId, artistId, 'hide'));
      }

      const artistFollow = playlists.find(p => p.artistId === artistId);

      if (!artistFollow) {
        return null;
      }

      analytics.artistUnfollowed(artistId);
      deletePlaylistMutation(artistFollow.id);
    },
    [playlists],
  );

  const getIsFollowed = useCallback(
    (artistId: string) => !!followedArtistsIds[artistId],
    [followedArtistsIds],
  );

  const toggleFollow = useCallback(
    (artistId: string) =>
      getIsFollowed(artistId) ? unfollow(artistId) : follow(artistId),
    [getIsFollowed, playlists],
  );

  return {
    getIsFollowed,
    toggleFollow,
    follow,
    unfollow,
  };
};
