import {useRef, useState} from 'react';

import {uploadFile} from '@/api/files';
import {useAppDispatch} from '@/hooks/useRedux';
import {IAudioUploaderProps} from '@/modules/DropOnSpinamp/components/AudioUploader/types';
import {initAdditionalAudio} from '@/services/additionalAudio';
import {IAdditionalAudio} from '@/services/additionalAudio/types';
import {pause} from '@/store/player';
import {pickFile} from '@/utils/fileUpload';
import {IPickedFile} from '@/utils/fileUpload/types';

export const useAudioUploader = (props?: {
  onUpload: (ipfsHash: string) => void;
}): IAudioUploaderProps => {
  const dispatch = useAppDispatch();

  const [file, setFile] = useState<IPickedFile>();
  const [uploadProgress, setUploadProgress] = useState<number>();
  const [ipfsHash, setIpfsHash] = useState<string>();

  const audioPreview = useRef<IAdditionalAudio>();
  const [isPlaying, setIsPlaying] = useState(false);

  const selectFile = async () => {
    try {
      const pickedFile = await pickFile({
        type: ['audio/mpeg', 'audio/wav'],
      });

      setUploadProgress(0);
      setFile(pickedFile);

      audioPreview.current = initAdditionalAudio({
        uri: pickedFile.uri,
        onEnded: () => setIsPlaying(false),
      });
      audioPreview.current.load();

      const hash = await uploadFile(pickedFile.file, setUploadProgress);
      props?.onUpload(hash);
      setIpfsHash(hash);
    } catch (e) {
    } finally {
      setUploadProgress(undefined);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      audioPreview.current?.pause();
      setIsPlaying(false);
    } else {
      dispatch(pause());
      audioPreview.current?.play();
      setIsPlaying(true);
    }
  };

  const reset = () => {
    setFile(undefined);
    setIsPlaying(false);
    setUploadProgress(undefined);
    setIpfsHash(undefined);
    props?.onUpload('');
  };

  return {
    file,
    uploadProgress,
    selectFile,
    ipfsHash,
    isPlaying,
    togglePlay,
    reset,
  };
};
