import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const ARTIST_AVATAR_SIZE = spacing.s * 2;

export const ARROW_SIZE = {
  height: 18,
  width: 24,
};

const POPOVER_PADDING = spacing.xs * 1.5;

export const styles = createThemedStyles(theme => ({
  backdropWrapper: {
    ...StyleSheet.absoluteFillObject,
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000',
    opacity: 0.3,
  },
  popoverContainer: {
    position: 'absolute',
    justifyContent: 'flex-end',
    backgroundColor: theme.colors.backgroundLight,
    borderRadius: theme.box.borderRadius,
    padding: POPOVER_PADDING,

    shadowColor: theme.colors.textColor,
    shadowOffset: {width: -spacing.xxs / 2, height: spacing.xxs / 2},
    shadowOpacity: 0.4,
    shadowRadius: spacing.xxs,
  },
  inputContainer: {
    marginBottom: spacing.xxs * 1.5,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  input: {
    paddingVertical: spacing.xxs * 1.5,
    minHeight: spacing.l,
    backgroundColor: theme.colors.backgroundLight,
    flex: 1,
  },
  textInput: {
    fontSize: 12,
  },
  playlistsScrollContainer: {
    flex: 1,
  },
  playlistsScrollContent: {
    flexGrow: 1,
  },
  playlistItem: {
    height: spacing.l + spacing.xs,
    paddingHorizontal: spacing.xxs,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.s,
  },
  arrow: {
    position: 'absolute',
  },
  gradientContainer: {
    position: 'absolute',
    top: -spacing.s - 1,
    left: 0,
    right: 0,
    height: spacing.s,
  },
  artistRow: {
    marginTop: spacing.xs,
    marginBottom: spacing.s,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  artistLink: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  artistAvatar: {
    width: ARTIST_AVATAR_SIZE,
    height: ARTIST_AVATAR_SIZE,
    borderRadius: ARTIST_AVATAR_SIZE / 2,
    overflow: 'hidden',
  },
  artistFollowButton: {
    paddingHorizontal: 0,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs * 1.5,
  },
  action: {
    flex: 1,
    paddingHorizontal: spacing.xxs,
  },
}));
