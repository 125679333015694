import {useContext} from 'react';

import {LoginStateContext} from './LoginScreen';

export const useLoginState = () => {
  const loginState = useContext(LoginStateContext);

  if (!loginState) {
    throw new Error(
      'useLoginState must be used inside LoginStateContext provider',
    );
  }

  return loginState;
};
