import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectNavigation = (state: RootState) => state.navigation;

export const selectCurrentScreen = createSelector(
  selectNavigation,
  navigation => navigation.currentScreen,
);

export const selectCanGoBack = createSelector(
  selectNavigation,
  navigation => navigation.canGoBack,
);
