import React from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon, {IIconProps} from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

import Key from './Key';
import {styles} from './style';

interface IProps {
  keyText: string;
  labelIcon?: IIconProps;
  labelTextId: string;
  onPress?: () => void;
  disabled?: boolean;
  hidden?: boolean;
}

const HotkeyButton: React.FC<IProps> = ({
  keyText,
  labelIcon,
  labelTextId,
  onPress,
  disabled = false,
  hidden = false,
}) => {
  const style = useThemedStyles(styles);

  if (hidden) {
    return null;
  }

  return (
    <TouchableOpacity
      style={[style.item, disabled && style.item_disabled]}
      disabled={disabled}
      onPress={onPress}>
      <Key text={keyText} />
      <Space mb="xs" />
      <View style={style.labelContainer}>
        {labelIcon && <Icon size={18} {...labelIcon} />}
        <Text id={labelTextId} />
      </View>
    </TouchableOpacity>
  );
};

export default HotkeyButton;
