import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    gap: spacing.l,
  },
  details: {
    minHeight: spacing.xl * 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
