import {useDebouncedValue} from '@/hooks/useDebouncedValue';

interface IUseSearchInput {
  search: string;
  setSearch: (newSearch: string) => void;
}

export const useDebouncedSearch = ({search, setSearch}: IUseSearchInput) => {
  const [searchDebounced, setSearchDebounced] = useDebouncedValue(search, 300);

  const setSearchImmediate = (v: string) => {
    setSearch(v);
    setSearchDebounced(v);
  };

  const clearSearch = () => setSearchImmediate('');

  return {
    search,
    setSearch,
    searchDebounced,
    setSearchImmediate,
    clearSearch,
  };
};
