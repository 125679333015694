import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface FeedState {
  shouldPlay: boolean;
  isFocused?: boolean;
  areHotkeysActive: boolean;
}

export const initialState: FeedState = {
  shouldPlay: false,
  isFocused: false,
  areHotkeysActive: true,
};

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setShouldPlay: (state, action: PayloadAction<boolean>) => {
      state.shouldPlay = action.payload;
    },
    toggleFeedPlay: state => {
      state.shouldPlay = !state.shouldPlay;
    },
    play: state => {
      state.shouldPlay = true;
    },
    pause: state => {
      state.shouldPlay = false;
    },
    setIsFeedFocused: (state, action: PayloadAction<boolean>) => {
      state.isFocused = action.payload;
    },
    setAreHotkeysActive: (state, action: PayloadAction<boolean>) => {
      state.areHotkeysActive = action.payload;
    },
  },
});

export const {
  setShouldPlay,
  toggleFeedPlay,
  play,
  pause,
  setIsFeedFocused,
  setAreHotkeysActive,
} = feedSlice.actions;

export default feedSlice.reducer;
