interface ISearchParams {
  [key: string]: string;
}

export const addParamsToUrl = (url: string, params: ISearchParams) => {
  const modifiedUrl = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) {
      modifiedUrl.searchParams.set(key, value);
    }
  });
  return modifiedUrl.toString();
};

export const getDomainFromUrl = (url: string): string | undefined => {
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)\//;
  const match = url.match(domainRegex);

  return match ? match[1] : undefined;
};
