import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {ITrack} from '@/types/common';
import {parseApiTrack, TRACK_FRAGMENT} from '@/utils/apiModelParsers';

export const fetchTrendingTracks = async (): Promise<ITrack[]> => {
  const response = await pipelineApi.request(
    gql`
      query TrendingTracks {
        allTrendingTracks(first: 50) {
          nodes {
            processedTrackByTrackId {
              ...TrackDetails
            }
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
  );

  return response.allTrendingTracks.nodes.map((node: any) =>
    parseApiTrack(node.processedTrackByTrackId),
  );
};
