import {MakeJsonFile, PickFile} from './types';

export const pickFile: PickFile = ({type} = {}) =>
  new Promise((resolve, reject) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');

    if (type) {
      input.setAttribute('accept', type.join(', '));
    }

    input.addEventListener('change', async () => {
      try {
        const file = input.files?.[0];

        if (!file) {
          return reject();
        }

        const uri = URL.createObjectURL(file);

        resolve({
          file,
          type: file.type,
          name: file.name,
          uri,
          size: file.size,
        });
      } catch (error) {
        reject();
      } finally {
        input.value = '';
      }
    });

    input.click();
  });

export const makeJsonFile: MakeJsonFile = async (fileName, data) =>
  new File([JSON.stringify(data)], `${fileName}.json`, {
    type: 'application/json',
  });
