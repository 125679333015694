import {Platform} from 'react-native';

import {sendPlaybackEvents} from '@/api/events';
import {AppDispatch, RootState} from '@/store';
import {selectSessionsList, selectActiveUserId} from '@/store/user';
import {IPlaybackEvent, IPlaybackEventPayload} from '@/types/events';
import {trackPlaybackEvent} from '@/utils/analytics';
import {groupBy} from '@/utils/functions';
import {isLocalDev} from '@/utils/platform';
import {getSignerFromSessionKey} from '@/utils/signer';

import {selectIsSyncing, selectPlaybackEvents} from './selectors';
import {trackUserEvent, cleanEvents, setIsSyncing} from './slice';

export const trackEvent =
  (event: IPlaybackEventPayload) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (isLocalDev) {
      return;
    }

    const state = getState();
    const userId = selectActiveUserId(state);

    trackPlaybackEvent(event);

    dispatch(trackUserEvent({userId: userId, event}));
  };

export const savePlaybackEvents =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const isSyncing = selectIsSyncing(state);
    const sessions = selectSessionsList(state);
    const localEvents = selectPlaybackEvents(state);

    if (isSyncing || localEvents.length === 0 || sessions.length === 0) {
      return;
    }

    dispatch(setIsSyncing(true));

    // Events for each user need to be sent in separate request using dedicated signature.
    // We are awaiting them in parallel.
    const localEventsByUserId = groupBy(localEvents, 'collector');

    await Promise.all(
      sessions.map(async ({userId, sessionKey}) => {
        const userLocalEvents = localEventsByUserId[userId] || [];

        if (userLocalEvents.length === 0) {
          return;
        }

        const userEvents: IPlaybackEvent[] = userLocalEvents.map(localEvent => {
          const {localId, ...event} = localEvent;
          return {
            id: localId,
            ...event,
            additionalData: {
              ...event.additionalData,
              platform: {
                os: Platform.OS,
                version: Platform.Version,
              },
            },
          };
        });

        try {
          await sendPlaybackEvents(
            userEvents,
            getSignerFromSessionKey(sessionKey),
          );
          dispatch(
            cleanEvents({
              eventIds: userLocalEvents.map(event => event.localId),
            }),
          );
        } catch (error) {
          // ignore error - failed events will be saved in next interval
        }
      }),
    );

    dispatch(setIsSyncing(false));
  };
