import {useMemo} from 'react';
import {useWindowDimensions} from 'react-native';

import {breakpoints} from '@/constants/breakpoints';
import {IResponsiveInfo} from '@/types/common';
import {isWeb} from '@/utils/platform';

export const useResponsive = (): IResponsiveInfo => {
  const {width} = useWindowDimensions();

  return useMemo(() => {
    const isMobile = width <= breakpoints.mobile;
    const isSmallDesktop = width <= breakpoints.smallDesktop;
    const isDesktop = width > breakpoints.smallDesktop;

    return {
      isMobile: isMobile || !isWeb,
      isSmallDesktop: !isMobile && isSmallDesktop && isWeb,
      isDesktop: isDesktop && isWeb,

      isBrowser: isWeb,
      isMobileBrowser: isMobile && isWeb,
    };
  }, [width]);
};
