import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContent: {
    flexGrow: 1,
    padding: spacing.s,
    gap: spacing.m,
  },
  listContainer: {
    gap: spacing.xs * 1.5,
  },
  list: {
    gap: spacing.s,
  },
  addWalletContainer: {
    gap: spacing.xs,
  },
}));
