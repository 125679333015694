import {useLinkProps} from '@react-navigation/native';
import React, {FC, useState} from 'react';

import {renderContent, ILinkProps} from './shared';

const Link: FC<ILinkProps> = ({
  to,
  action,
  children,
  style,
  onPress: additionalOnPress,
}) => {
  const [hover, setHover] = useState(false);
  const {onPress, ...linkProps} = useLinkProps({to, action});

  return (
    <a
      draggable={false}
      style={style}
      // @ts-ignore
      onMouseEnter={() => setHover(true)}
      // @ts-ignore
      onMouseLeave={() => setHover(false)}
      // @ts-ignore
      onClick={event => {
        event.stopPropagation();
        onPress(event);
        additionalOnPress?.();
      }}
      {...linkProps}>
      {renderContent(children, hover)}
    </a>
  );
};

export default Link;
