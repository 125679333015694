import React, {FC, ReactNode} from 'react';
import {StyleProp} from 'react-native';
import Animated, {useAnimatedStyle, withTiming} from 'react-native-reanimated';

interface IProps {
  children: ReactNode;
  isRotated: boolean;
  angle?: number;
  style?: StyleProp<any>;
  duration?: number;
}

const RotatedView: FC<IProps> = ({
  isRotated,
  style,
  children,
  angle = 180,
  duration = 200,
}) => {
  const rotationStyle = useAnimatedStyle(
    () => ({
      transform: [
        {
          rotate: withTiming(`${Number(isRotated) * angle}deg`, {
            duration: 200,
          }),
        },
      ],
    }),
    [isRotated, duration, angle],
  );

  return (
    <Animated.View style={[rotationStyle, style]}>{children}</Animated.View>
  );
};

export default RotatedView;
