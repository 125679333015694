import React, {FC, useMemo} from 'react';
import {FlatList, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import Modal, {IModalProps} from '@/components/Modal';
import Text from '@/components/Text/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {
  externalPlatformsConfig,
  IExternalPlatform,
} from '@/constants/externalPlatforms';
import {useThemedStyles} from '@/theme';
import {IExternalLink} from '@/types/common';
import {normalize} from '@/utils/functions';

import {styles} from './ExternalLinksPicker.style';

interface IProps extends IModalProps {
  selectedLinks: IExternalLink[];
  onSelect: (link: IExternalPlatform) => void;
}

const ExternalLinksPicker: FC<IProps> = ({
  isOpen,
  onClose,
  selectedLinks,
  onSelect,
}) => {
  const style = useThemedStyles(styles);

  const availableLinks = useMemo(() => {
    const selectedLinkTypes = normalize(selectedLinks, 'type').items;

    return Object.values(externalPlatformsConfig).filter(
      platform => !selectedLinkTypes[platform.type],
    );
  }, [selectedLinks]);

  return (
    <Modal title="External links" isOpen={isOpen} onClose={onClose}>
      <FlatList
        contentContainerStyle={style.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        data={availableLinks}
        keyExtractor={link => link.type}
        renderItem={({item: link}) => (
          <TouchableCard
            ph="s"
            pv="xxs"
            onPress={() => {
              onSelect(link);
              onClose();
            }}>
            {({actionColor}) => (
              <View style={style.link}>
                <Icon
                  name={link.icon}
                  provider="custom"
                  color={actionColor()}
                />
                <Text weight="semibold" color={actionColor()}>
                  {link.name}
                </Text>
              </View>
            )}
          </TouchableCard>
        )}
      />
    </Modal>
  );
};

export default ExternalLinksPicker;
