import {ITrack} from '@/types/common';
import {
  ICountedVotes,
  IPlaylist,
  IPlaylistVotes,
  PlaylistType,
} from '@/types/playlists';
import {IBaseUser, IUser} from '@/types/user';
import {formatFirebaseId} from '@/utils/api';
import {prettifyAddress} from '@/utils/ethereum';
import {generateId, sortStrings} from '@/utils/functions';
import {getUserDisplayName} from '@/utils/user';

export const generatePlaylistId = () => `local_${generateId()}`;

export const createFavsPlaylist = () => ({
  id: generatePlaylistId(),
  titleId: 'playlists.favorites',
  isPermanent: true,
  trackIds: [],
  type: PlaylistType.favorites,
  updatedAtTime: Date.now(),
});

export const parsePlaylistResponse = (playlist: IPlaylist) => ({
  ...playlist,
  trackIds: playlist.trackIds && playlist.trackIds.map(formatFirebaseId),
  artistId: playlist.artistId && formatFirebaseId(playlist.artistId),
});

export const sortPlaylistsByTitle = (a: IPlaylist, b: IPlaylist) =>
  sortStrings(a.title, b.title);

export const playlistContainsTrack = (
  playlist: IPlaylist,
  trackId: string,
): boolean => {
  return Boolean(playlist.trackIds && playlist.trackIds.includes(trackId));
};

export const toggleTrackId = (trackIds: string[] = [], trackId: string) =>
  trackIds.includes(trackId)
    ? trackIds.filter(id => id !== trackId)
    : [trackId, ...trackIds];

export const toggleTrackAndHandleSuggestion = (
  playlist: IPlaylist,
  trackId: string,
  userId?: string,
) => {
  const updatedTrackIds = toggleTrackId(playlist.trackIds, trackId);
  const contributions = {...playlist.contributions};
  const suggestions = {...playlist.suggestions};
  const suggestion = suggestions[trackId];

  delete suggestions[trackId];

  const contributorId = suggestion?.userId || userId;
  const isAdding = updatedTrackIds.length > playlist.trackIds!.length;

  if (contributorId && isAdding && !contributions[trackId]) {
    contributions[trackId] = {
      userId: contributorId,
    };
  }

  return {
    trackIds: updatedTrackIds,
    contributions,
    suggestions,
  };
};

export const getUpdatedSuggestions = (
  playlist: IPlaylist,
  trackId: string,
  userId?: string,
) => {
  if (!userId || playlist.suggestions?.[trackId]) {
    return playlist.suggestions;
  }

  return {
    ...playlist.suggestions,
    [trackId]: {
      userId,
      timestamp: Date.now(),
    },
  };
};

export const comparePlaylistsTimestamps = (
  localPlaylist: IPlaylist,
  serverPlaylist: IPlaylist,
): IPlaylist => {
  let followedPlaylist: IPlaylist | undefined;

  if (serverPlaylist.followedPlaylist) {
    if (localPlaylist.followedPlaylist) {
      followedPlaylist = comparePlaylistsTimestamps(
        localPlaylist.followedPlaylist,
        serverPlaylist.followedPlaylist,
      );
    } else {
      // it's only to support migration from before we returned full followedPlaylist from firebase
      followedPlaylist = {
        ...serverPlaylist.followedPlaylist,
        serverUpdatedAtTime: serverPlaylist.followedPlaylist.updatedAtTime,
      };
    }
  }

  const serverPlaylistUpdateTimestamp = new Date(
    serverPlaylist.updatedAtTime ?? 0,
  ).getTime();
  const localPlaylistSyncTimestamp = new Date(
    localPlaylist.serverUpdatedAtTime ?? Date.now(),
  ).getTime();
  const localPlaylistUpdateTimestamp = new Date(
    localPlaylist.updatedAtTime ?? 0,
  ).getTime();

  // If updated time from server is greater than server update time known locally, return server playlist.
  // It means that successful update was made and saved in the meantime from other device, so we want to discard all unsaved local changes
  if (serverPlaylistUpdateTimestamp > localPlaylistSyncTimestamp) {
    return {
      ...serverPlaylist,
      serverUpdatedAtTime: serverPlaylist.updatedAtTime,
      followedPlaylist,
    };
  }

  // If server update timestamp is equal in both local and server playlists, check if there is some unsaved local update
  // If there is no, return server playlist so the server stays main source of truth
  if (
    serverPlaylistUpdateTimestamp === localPlaylistSyncTimestamp &&
    localPlaylistUpdateTimestamp <= localPlaylistSyncTimestamp
  ) {
    return {
      ...serverPlaylist,
      serverUpdatedAtTime: serverPlaylist.updatedAtTime,
      followedPlaylist,
    };
  }

  // Otherwise return local playlist, so unsaved updates can be saved later
  return {
    ...localPlaylist,
    followedPlaylist,
  };
};

export const mergeLocalAndServerPlaylists = (
  localPlaylists: IPlaylist[],
  serverPlaylists: IPlaylist[],
): IPlaylist[] => {
  const mergedPlaylists = [...localPlaylists];

  serverPlaylists.forEach(serverPlaylist => {
    const indexInLocalPlaylists = mergedPlaylists.findIndex(
      localPlaylist => localPlaylist.id === serverPlaylist.id,
    );

    if (indexInLocalPlaylists > -1) {
      mergedPlaylists[indexInLocalPlaylists] = comparePlaylistsTimestamps(
        mergedPlaylists[indexInLocalPlaylists],
        serverPlaylist,
      );
    } else if (serverPlaylist.updatedAtTime) {
      mergedPlaylists.push({
        ...serverPlaylist,
        serverUpdatedAtTime: serverPlaylist.updatedAtTime,
        followedPlaylist: serverPlaylist.followedPlaylist
          ? {
              ...serverPlaylist.followedPlaylist,
              serverUpdatedAtTime: serverPlaylist.updatedAtTime,
            }
          : undefined,
      });
    }
  });

  return mergedPlaylists;
};

export const isLocalPlaylistId = (playlistId: string): boolean =>
  playlistId.startsWith('local');

export const sortPlaylistsByUpdateTime = (a: IPlaylist, b: IPlaylist) =>
  new Date(b.updatedAtTime || '').getTime() -
  new Date(a.updatedAtTime || '').getTime();

export const copyUniqueAnonPlaylists = (
  anonPlaylists: IPlaylist[],
  returningUserPlaylists: IPlaylist[],
  collector: string,
) => {
  const playlistsToCopy: IPlaylist[] = [];

  anonPlaylists.forEach(playlist => {
    // Favs playlist needs to be merged between users. It's handled in different place so we need to skip it here.
    if (playlist.type === PlaylistType.favorites) {
      return;
    }

    if (playlist.type === PlaylistType.custom) {
      playlistsToCopy.push(playlist);
      return;
    }

    if (
      playlist.type === PlaylistType.artist &&
      !returningUserPlaylists.some(
        existingPlaylist =>
          existingPlaylist.type === PlaylistType.artist &&
          existingPlaylist.artistId === playlist.artistId,
      )
    ) {
      playlistsToCopy.push(playlist);
      return;
    }

    if (
      playlist.type === PlaylistType.followedPlaylist &&
      !returningUserPlaylists.some(
        existingPlaylist =>
          existingPlaylist.type === PlaylistType.followedPlaylist &&
          existingPlaylist.followedPlaylistId === playlist.followedPlaylistId,
      )
    ) {
      playlistsToCopy.push(playlist);
      return;
    }

    if (
      playlist.type === PlaylistType.followedCollection &&
      !returningUserPlaylists.some(
        existingPlaylist =>
          existingPlaylist.type === PlaylistType.followedCollection &&
          existingPlaylist.followedCollectionAddress ===
            playlist.followedCollectionAddress,
      )
    ) {
      playlistsToCopy.push(playlist);
      return;
    }
  });

  return playlistsToCopy.map(playlist => ({
    ...playlist,
    id: generatePlaylistId(),
    serverUpdatedAtTime: undefined,
    updatedAtTime: Date.now(),
    collector: collector,
  }));
};

export const getMergedFavsPlaylist = (
  anonPlaylists: IPlaylist[],
  returningUserPlaylists: IPlaylist[],
  collector: string,
): IPlaylist => {
  const anonFavs = anonPlaylists.find(p => p.type === PlaylistType.favorites)!;
  const userFavs = returningUserPlaylists.find(
    p => p.type === PlaylistType.favorites,
  );

  if (userFavs) {
    return {
      ...userFavs,
      collector: collector,
      trackIds: [
        ...new Set([
          ...(anonFavs.trackIds || []),
          ...(userFavs.trackIds || []),
        ]),
      ],
      updatedAtTime: Date.now(),
    };
  }

  return {
    ...anonFavs,
    id: generatePlaylistId(),
    serverUpdatedAtTime: undefined,
    updatedAtTime: Date.now(),
    collector: collector,
  };
};

// Copy anon playlists which doesn't exist in existing user playlist.
// Mark them as local (remove id and serverUpdatedAt), so they can be recreated in useSyncPlaylists later.
export const migrateAnonPlaylistHelper = (
  anonUserPlaylists: IPlaylist[],
  returningUserPlaylists: IPlaylist[],
  collector: string,
) => {
  const uniqueAnonPlaylists = copyUniqueAnonPlaylists(
    anonUserPlaylists,
    returningUserPlaylists,
    collector,
  );

  return [
    ...uniqueAnonPlaylists,
    ...mergeLocalAndServerPlaylists(
      [],
      returningUserPlaylists.filter(p => p.type !== PlaylistType.favorites),
    ),
    getMergedFavsPlaylist(anonUserPlaylists, returningUserPlaylists, collector),
  ];
};

export const getPlaylistCreator = (
  playlist: IPlaylist,
  user?: IUser | null,
) => {
  if (user?.artistProfile) {
    return user.artistProfile.name;
  }

  if (user) {
    return getUserDisplayName(user);
  }

  if (playlist.collector) {
    return prettifyAddress(playlist.collector);
  }

  return null;
};

export const getPlaylistTracksMap = (trackIds: string[] = []) => {
  const tracksMap: {[id: string]: boolean} = {};

  trackIds.forEach(id => {
    tracksMap[id] = true;
  });

  return tracksMap;
};

export const findPlaylistById = (id: string, playlists: IPlaylist[] = []) =>
  playlists.find(playlist => playlist.id === id);

export const findPlaylistOrFollowedPlaylistById = (
  playlistId: string,
  userPlaylists: IPlaylist[] = [],
) => {
  let playlist: IPlaylist | undefined;
  let parentPlaylistId: string | undefined;

  userPlaylists.some(p => {
    if (p.id === playlistId) {
      playlist = p;
      return true;
    }

    if (p.followedPlaylist?.id === playlistId) {
      playlist = p.followedPlaylist;
      parentPlaylistId = p.id;

      return true;
    }

    return false;
  });

  return {playlist, parentPlaylistId};
};

export const updatePlaylistAndFollowedPlaylistById = (
  playlists: IPlaylist[],
  id: string,
  updater: (playlist: IPlaylist) => Partial<IPlaylist>,
) => {
  return playlists.map(playlist => {
    if (playlist.id === id) {
      return {
        ...playlist,
        ...updater(playlist),
      };
    }

    if (playlist.followedPlaylist?.id === id) {
      return {
        ...playlist,
        followedPlaylist: {
          ...playlist.followedPlaylist,
          ...updater(playlist.followedPlaylist),
        },
      };
    }

    return playlist;
  });
};

export const getPlaylistFromError = (error: any): IPlaylist | undefined => {
  return error?.data?.playlist;
};

export const isInCollaborators = (
  collaborators: IPlaylist['collaborators'] = [],
  userId?: string,
) => {
  if (!userId) {
    return false;
  }

  return collaborators.some(c => c.userId === userId);
};

export const shouldShowContributors = (playlist?: IPlaylist) => {
  if (!playlist?.contributions) {
    return false;
  }

  return (
    Object.values(playlist.contributions).filter(
      ({userId}) => userId !== playlist.collector,
    ).length > 0
  );
};

export const getCuratorsList = ({
  contributors,
  isOwner,
  activeUserId,
}: {
  contributors: IBaseUser[];
  isOwner: boolean;
  activeUserId?: string;
}) => {
  const curators = [];
  const isUserIncluded =
    isOwner || contributors.some(c => c.id === activeUserId);
  const limit = isUserIncluded ? 2 : 1;

  if (isUserIncluded) {
    curators.push('you');
  }

  curators.push(
    ...contributors
      .filter(c => c.id !== activeUserId)
      .map(c => getUserDisplayName(c)),
  );

  if (curators.length > limit) {
    return [...curators.slice(0, limit), `+${curators.length - limit}`].join(
      ', ',
    );
  }

  return curators.join(', ');
};

export const countTrackVotes = (votes: IPlaylistVotes, trackId: string) =>
  Object.values(votes[trackId] || {}).reduce((sum, vote) => {
    if (vote.upvote) {
      return sum + 1;
    }

    return sum;
  }, 0);

export const getCountedVotes = (votes: IPlaylistVotes) => {
  const countedVotes: ICountedVotes = {};

  Object.keys(votes).forEach(trackId => {
    countedVotes[trackId] = countTrackVotes(votes, trackId);
  });

  return countedVotes;
};

export const getOrderedSuggestions = (
  tracks: ITrack[],
  countedVotes: ICountedVotes,
) =>
  tracks.sort((a, b) => (countedVotes[b.id] || 0) - (countedVotes[a.id] || 0));

export const getPlaylistThemeConfig = ({
  playlistId,
  creator,
  playlistThemes,
  artistThemes,
}: {
  playlistId: string;
  creator: IUser | null;
  playlistThemes: {[id: string]: string};
  artistThemes: {[id: string]: string};
}) => {
  if (playlistThemes[playlistId]) {
    return {
      predefinedThemeName: playlistThemes[playlistId],
    };
  }

  if (
    creator?.artistProfile?.predefinedThemeName ||
    creator?.artistProfile?.customTheme
  ) {
    return {
      predefinedThemeName: creator.artistProfile.predefinedThemeName,
      customTheme: creator.artistProfile.customTheme,
    };
  }

  if (creator?.artistProfile && artistThemes[creator.artistProfile.id]) {
    return {
      predefinedThemeName: artistThemes[creator.artistProfile.id],
    };
  }

  if (creator?.predefinedThemeName || creator?.customTheme) {
    return {
      predefinedThemeName: creator.predefinedThemeName,
      customTheme: creator.customTheme,
    };
  }

  return undefined;
};
