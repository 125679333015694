import {useIsFocused, useScrollToTop} from '@react-navigation/native';
import React, {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';

import InfinityList from '@/components/InfinityList';
import ScreenLoader from '@/components/ScreenLoader';
import TrackCard, {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useHistoryQuery} from '@/queries/history';
import {LibraryUI} from '@/screens/Library/components/LibrarySharedUI';
import {useLibraryContext} from '@/screens/Library/hooks/useLibraryContext';
import {playNewQueue} from '@/store/player';
import {ITrack, PlayContextType} from '@/types/common';

const LibraryHistory = () => {
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const isFocused = useIsFocused();
  const {onScroll} = useLibraryContext();

  useScrollToTop(listRef);

  const {
    historyTracks,
    query: {
      isLoading,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
      isError,
      refetch,
    },
  } = useHistoryQuery(isFocused);

  const {isTrackActive} = useIsTrackActive(PlayContextType.history);

  const onPlay = useCallback(
    (track: ITrack, index?: number) => {
      dispatch(
        playNewQueue({
          trackIds: historyTracks.map(t => t.track.id),
          index,
          context: {
            source: 'History',
            type: PlayContextType.history,
            titleId: 'library.history',
          },
        }),
      );
    },
    [historyTracks],
  );

  if (isLoading && historyTracks.length === 0) {
    return <ScreenLoader />;
  }

  if (isError) {
    return <LibraryUI.Error onRetry={refetch} />;
  }

  return (
    <InfinityList
      ref={listRef}
      data={historyTracks}
      itemSize={TRACK_CARD_HEIGHT}
      keyExtractor={item => item.track.id + item.timestamp}
      extraData={isTrackActive}
      onScroll={onScroll}
      ListHeaderComponent={<LibraryUI.Title />}
      renderItem={({item, index}) => (
        <TrackCard
          track={item.track}
          isActive={isTrackActive(item.track.id, index)}
          index={index}
          showArtist
          onPlay={onPlay}
        />
      )}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage || isLoading}
      ListEmptyComponent={<LibraryUI.Empty textId="playSomething" />}
    />
  );
};

export default LibraryHistory;
