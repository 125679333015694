import NetInfo, {NetInfoState} from '@react-native-community/netinfo';

import {Handler} from './types';

/**
 * Allows subscribing to app online status events.
 */
export const addOnlineEventListener = (handler: Handler) => {
  let isConnected = true;

  const listener = (state: NetInfoState) => {
    if (state.isConnected && !isConnected) {
      // Going online
      isConnected = true;
      handler();
    } else if (state.isConnected === false && isConnected) {
      // Going offline
      isConnected = false;
    }
  };

  // const subscription = AppState.addEventListener('change', listener);
  const unsubscribe = NetInfo.addEventListener(listener);

  return unsubscribe;
};
