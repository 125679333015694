import {BottomTabBarProps} from '@react-navigation/bottom-tabs';
import {createContext} from 'react';

export interface ITabsContextValue {
  setTabsContext: (tabsProps: Omit<BottomTabBarProps, 'insets'>) => void;
}

export const TabsContext = createContext<ITabsContextValue>({
  setTabsContext: () => undefined,
});
