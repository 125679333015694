import React, {useCallback, useMemo} from 'react';
import {Platform, ScrollView, StyleSheet, Vibration, View} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import {getArtistModalLink} from '../../utils';
import IconButton from '@/components/IconButton';
import Link from '@/components/Link';
import SpinLoader from '@/components/SpinLoader';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import ArtistFeedCardTrack from '@/screens/Feed/components/ArtistFeedCardTrack';
import BaseFeedCard from '@/screens/Feed/components/FeedCard/BaseFeedCard';
import {FEED_CARD_WIDTH} from '@/screens/Feed/components/FeedCard/BaseFeedCard.styles';
import {useFeedAudio} from '@/screens/Feed/components/FeedCard/useFeedAudio';
import OnboardingPopover from '@/screens/Feed/components/OnboardingPopover';
import Progress from '@/screens/Feed/components/Progress';
import TimeToRelease from '@/screens/Feed/components/TimeToRelease';
import {setShouldPlay, toggleFeedPlay} from '@/store/feed';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {FeedOnboardingItem, IFeedItemWithArtist} from '@/types/feed';
import {ImageSize} from '@/types/media';
import {analytics} from '@/utils/analytics';
import {noop} from '@/utils/functions';

import {ARTIST_AVATAR_SIZE, styles} from './ArtistFeedCard.styles';

interface IArtistFeedCardProps {
  feedItem: IFeedItemWithArtist;
  isActive: boolean;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
  trackIndex: number | undefined;
  setTrackIndex: (feedItemId: string, index: number) => void;
  onHide?: (id: string) => void;
  isOnboarding?: boolean;
  onboardingStep?: FeedOnboardingItem<'artist'>;
  closeOnboardingStep?: (item: FeedOnboardingItem<'artist'>) => void;
}

const ArtistFeedCard: React.FC<IArtistFeedCardProps> = ({
  feedItem,
  isActive,
  next,
  setCarouselEnabled,
  trackIndex = 0,
  setTrackIndex,
  onHide,
  onboardingStep,
  isOnboarding = false,
  closeOnboardingStep = noop,
}) => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectActiveUserId);

  const {artist} = feedItem;
  const tracks = artist.tracks.slice(0, 5);
  const feedCardTrack = tracks[trackIndex];

  const {follow, unfollow, getIsFollowed} = useArtistFollow();
  const isFollowed = useMemo(
    () => getIsFollowed(feedItem.artist.id),
    [feedItem.artist.id, getIsFollowed],
  );

  const onTrackEnd = () => {
    if (trackIndex === tracks.length - 1) {
      setTrackIndex(feedItem.id, 0);
      next();
    } else if (trackIndex !== -1) {
      setTrackIndex(feedItem.id, trackIndex + 1);
    }
  };

  const {isPlaying, isBuffering, progress} = useFeedAudio(
    feedCardTrack,
    isActive,
    onTrackEnd,
  );

  const backgroundImageUri =
    feedCardTrack?.lossyArtworkUrl || feedItem.artist.avatarUrl;

  const onTrackPress = useCallback(
    (newIndex: number) => {
      setTrackIndex(feedItem.id, newIndex);
      dispatch(setShouldPlay(true));
    },
    [feedItem.id],
  );

  return (
    <BaseFeedCard>
      {progress && (
        <Progress
          progress={progress}
          onDragStart={() => setCarouselEnabled(false)}
          onDragEnd={() => setCarouselEnabled(true)}
        />
      )}
      <View style={style.tracksContainer}>
        {backgroundImageUri && (
          <Animated.View
            key={feedCardTrack?.id}
            style={style.artwork}
            entering={FadeIn}
            exiting={Platform.select({
              native: FadeOut.delay(300),
            })}>
            <StaticImage
              source={{uri: backgroundImageUri}}
              resizeWidth={ImageSize.feedCard}
              style={StyleSheet.absoluteFillObject}
            />
          </Animated.View>
        )}
        <View style={[style.mask, style.artworkMask]} />
        {isBuffering && (
          <View style={style.bufferingIndicator}>
            <SpinLoader />
          </View>
        )}
        <ScrollView
          style={style.trackList}
          contentContainerStyle={style.trackListContent}
          showsVerticalScrollIndicator={false}
          bounces={false}>
          {tracks.map((track, index) => (
            <ArtistFeedCardTrack
              key={track.id}
              feedItem={feedItem}
              index={index}
              track={track}
              onPress={onTrackPress}
              isActive={track.id === feedCardTrack?.id}
            />
          ))}
          {tracks.length === 0 && (
            <View style={style.empty}>
              <Text
                align="center"
                id="feed.artistFeedCard.empty"
                color="invertedTextColor"
              />
            </View>
          )}
        </ScrollView>
        <TimeToRelease track={feedCardTrack} style={style.timeToRelease} />
      </View>
      <View style={style.actionsContainer}>
        {isActive && onboardingStep === 'follow' && (
          <OnboardingPopover
            position="top"
            align="right"
            arrowOffset={(FEED_CARD_WIDTH - ARTIST_AVATAR_SIZE) / 4 - spacing.s}
            style={{
              transform: [{translateX: -spacing.xs}, {translateY: spacing.s}],
            }}
            onClose={() => closeOnboardingStep('follow')}>
            <OnboardingPopover.Follow />
          </OnboardingPopover>
        )}
        <View style={style.actionsRow}>
          <View style={style.action}>
            {userId === feedItem.userId && (
              <IconButton
                onPress={() => {
                  unfollow(feedItem.artist.id);
                  analytics.feedItemReacted(
                    feedItem.id,
                    'hide',
                    feedItem.entityType,
                  );
                  onHide?.(feedItem.id);
                }}
                icon={{
                  provider: 'custom',
                  name: 'visibilityOff',
                }}
                disabled={isOnboarding}
              />
            )}
          </View>
          <View style={style.artistAvatarContainer}>
            {feedItem.artist.avatarUrl && (
              <StaticImage
                source={{uri: feedItem.artist.avatarUrl}}
                style={style.artistAvatar}
                resizeWidth={ImageSize.thumbnail}
              />
            )}
            <View style={style.mask} />
            <IconButton
              rippleDisabled
              onPress={() => dispatch(toggleFeedPlay())}
              icon={{
                provider: 'custom',
                name: isPlaying ? 'pause' : 'play',
                color: 'invertedTextColor',
              }}
              disabled={!feedCardTrack}
            />
          </View>
          <View style={style.action}>
            <TransparentButton
              rippleDisabled
              onPress={() => {
                if (!isFollowed) {
                  Vibration.vibrate();
                  follow(feedItem.artist.id);
                  analytics.feedItemReacted(
                    feedItem.id,
                    'like',
                    feedItem.entityType,
                  );
                } else {
                  unfollow(feedItem.artist.id);
                }

                closeOnboardingStep('follow');
              }}
              text={{
                id: isFollowed ? 'unfollow' : 'follow',
              }}
            />
          </View>
        </View>
        <View style={style.artistLinkWrapper}>
          <Link
            {...getArtistModalLink(feedItem.artist.slug)}
            style={style.artistLink}>
            {({hover}) => (
              <Text
                align="center"
                numberOfLines={1}
                underline={hover}
                weight="semibold">
                {feedItem.artist.name}
              </Text>
            )}
          </Link>
        </View>
      </View>
    </BaseFeedCard>
  );
};

export default React.memo(ArtistFeedCard);
