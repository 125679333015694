import {useContext} from 'react';

import {CollectStateContext} from './CollectStateProvider';

export const useCollectState = () => {
  const collectState = useContext(CollectStateContext);

  if (!collectState) {
    throw new Error(
      'useCollectState must be used within CollectStateContext Provider',
    );
  }

  return collectState;
};
