import {noop} from '@/utils/functions';

export const applyUpdate = () => Promise.resolve();

export const useExpoUpdatesSyncQuery = () => noop;

export const useApplyExpoUpdateMutation = () => {
  return {
    applyUpdate: noop,
    isLoading: false,
  };
};
