import {useQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {useActiveUser} from '@/hooks/useActiveUser';
import {fetchTipsBalanceBulk, fetchTotalTipsBalance} from '@/modules/Tip/utils';
import {QueryKeys} from '@/types/queryKeys';

export const usePendingTipsBalance = () => {
  const user = useActiveUser();

  const addresses = useMemo(
    () => user?.addresses.filter(a => a.isWallet).map(a => a.address) || [],
    [user],
  );

  const query = useQuery({
    queryKey: [QueryKeys.pendingTipsBalance, user?.id, ...addresses],
    queryFn: async () => fetchTipsBalanceBulk(addresses),
    enabled: !!user?.artistProfile && addresses.length > 0,
  });

  return {
    pendingBalances: query.data,
    query,
  };
};

export const useTotalTipsBalance = (
  artistId: string | undefined,
  enabled = true,
) => {
  const query = useQuery({
    queryKey: [QueryKeys.totalTipsBalance, artistId],
    queryFn: async () => fetchTotalTipsBalance(artistId!),
    enabled: enabled && !!artistId,
  });

  return {
    totalTipsBalance: query.data,
    query,
  };
};
