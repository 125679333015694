import {AppDispatch, RootState} from '@/store';
import {selectSelectedUserId} from '@/store/user';

import {setUserThemeSettings, setDefaultTheme} from './slice';

export const setThemeSettings =
  (action: {
    type: 'artist' | 'collector' | 'predefined';
    name?: string | null;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const activeUserId = selectSelectedUserId(getState());
    const {name, type} = action;

    if (activeUserId) {
      dispatch(
        setUserThemeSettings({
          activeUserId,
          type,
          name,
        }),
      );
    } else if (name) {
      dispatch(setDefaultTheme(name));
    }
  };
