import {
  ILibraryCategory,
  LibraryCategory,
  LibrarySubcategory,
} from '@/screens/Library/types';

export const libraryCategories: ILibraryCategory[] = [
  {
    id: LibraryCategory.artists,
    textId: 'library.category.artists',
    titleId: 'library.artists.title',
  },
  {
    id: LibraryCategory.favorites,
    textId: 'library.category.favorites',
    titleId: 'library.favorites.title',
  },
  {
    id: LibraryCategory.playlists,
    textId: 'library.category.playlists',
    titleId: 'library.playlists.title',
    subcategories: [
      {
        id: LibrarySubcategory.ownPlaylists,
        textId: 'library.subcategory.ownPlaylists',
      },
      {
        id: LibrarySubcategory.followedPlaylists,
        textId: 'library.subcategory.followedPlaylists',
      },
      {
        id: LibrarySubcategory.collaborativePlaylists,
        textId: 'library.subcategory.collaborativePlaylists',
      },
    ],
  },
  {
    id: LibraryCategory.collectors,
    textId: 'library.category.collectors',
    titleId: 'library.collectors.title',
  },
  {
    id: LibraryCategory.history,
    textId: 'library.category.history',
    titleId: 'library.history',
  },
];

export const ANIMATION_DURATION = 200;
