import {StyleProp} from 'react-native';

import {TextProps} from '@/components/Text';

export interface IScreenLoaderProps {
  loaderProps?: TextProps;
  style?: StyleProp<any>;
  delay?: number;
}

export const screenLoaderDefaults = {
  delay: 1000,
};
