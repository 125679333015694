import {useState} from 'react';

import {INftEventFilter, NftEventType} from '@/types/nfts';

const filters: INftEventFilter[] = [
  {
    type: 'transfer',
    textId: 'nftEvent.type.transfer',
  },
  {
    type: 'mint',
    textId: 'nftEvent.type.mint',
  },
];

export const useNftEventsFilters = (initialFilter?: NftEventType) => {
  const [activeFilter, setActiveFilter] = useState<NftEventType | undefined>(
    initialFilter,
  );

  const onFilterPress = (type: NftEventType) => {
    if (type === activeFilter) {
      setActiveFilter(undefined);
    } else {
      setActiveFilter(type);
    }
  };

  return {
    filters,
    activeFilter,
    onFilterPress,
  };
};
