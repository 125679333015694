import spacing from '@/constants/spacing';
import {calculateBackgroundColorLight, createThemedStyles} from '@/theme';

const FILTER_HEIGHT = spacing.m + spacing.xs;

export const styles = createThemedStyles(theme => ({
  container: {
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  content: {
    paddingTop: spacing.s,
    paddingBottom: spacing.xxs,
    paddingHorizontal: spacing.xs,
    flexGrow: 1,
  },
  pill: {
    height: FILTER_HEIGHT,
    paddingHorizontal: spacing.xs,
    paddingVertical: 0,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
