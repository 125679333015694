import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const TILE_PADDING = spacing.xs + spacing.xxs;

export const styles = createThemedStyles(theme => ({
  tile: {
    flex: 1,
    backgroundColor: theme.colors.background,
    borderRadius: theme.box.borderRadius,
    borderColor: theme.colors.primary,
    borderWidth: theme.box.baseBorderWidth,
    borderRightWidth: theme.box.borderRightWidth,
    borderBottomWidth: theme.box.borderBottomWidth,
  },
  tile_active: {
    backgroundColor: theme.colors.active,
    borderRightWidth: theme.box.baseBorderWidth,
    borderBottomWidth: theme.box.baseBorderWidth,
    marginRight: theme.box.borderRightWidth - theme.box.baseBorderWidth,
    marginBottom: theme.box.borderBottomWidth - theme.box.baseBorderWidth,
  },
  header: {
    marginTop: -1,
    borderTopLeftRadius: theme.box.borderRadius,
    borderTopRightRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.primary,
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.s,
  },
  content: {
    flex: 1,
    padding: TILE_PADDING,
  },
  selectedIndicator: {
    position: 'absolute',
    top: -spacing.xs,
    right: -spacing.xs,
    borderWidth: 0,
    backgroundColor: theme.colors.backgroundLight,
    borderRadius: 12,
    zIndex: 2,
  },
}));
