import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {FC, useEffect} from 'react';
import {View} from 'react-native';

import ClosingHeader from '@/components/ClosingHeader';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import {useActiveUser} from '@/hooks/useActiveUser';
import VerifyAddressRouter from '@/modules/Tip/VerifyAddress/components/VerifyAddressRouter';
import {VerifyAddressProvider} from '@/modules/Tip/VerifyAddress/VerifyAddressProvider';
import {useThemedStyles} from '@/theme';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';

import {styles} from './VerifyAddressScreen.style';

const VerifyAddressScreen: FC = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();

  const {
    params: {transactionId, address},
  } = useRoute<RouteProp<RootStackParams, Routes.VerifyTipAddress>>();

  const activeUser = useActiveUser();
  const verifiedWallet = activeUser?.addresses.find(a => a.address === address);

  useEffect(() => {
    if (!verifiedWallet) {
      navigation.goBack();
    }
  }, [verifiedWallet]);

  if (!activeUser || !verifiedWallet) {
    return null;
  }

  return (
    <ModalScreenWrapper
      titleId="tip.verify.title"
      isFullscreenOnMobile
      desktopSize="l">
      <ClosingHeader />
      <View style={style.container}>
        <VerifyAddressProvider
          transactionId={transactionId}
          user={activeUser}
          verifiedWallet={verifiedWallet}>
          <VerifyAddressRouter />
        </VerifyAddressProvider>
      </View>
    </ModalScreenWrapper>
  );
};

export default VerifyAddressScreen;
