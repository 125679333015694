import {gql} from 'graphql-request';

import {submitEntityFieldUpdate} from '@/api/entityFieldUpdates';
import {
  DeliveryMethod,
  ISpindexerNotification,
  NotificationStatus,
} from '@/modules/Notifications/types';
import {pipelineApi} from '@/services/pipelineApi';
import API from '@/services/spinampApi';
import {IPageInfo, IPaginatedResponse} from '@/types/api';
import {ITrack} from '@/types/common';
import {ISigner} from '@/types/session';
import {getSignedRequestBody} from '@/utils/api';

const SPINDEXER_NOTIFICATION_FRAGMENT = gql`
  fragment SpindexerNotification on Notification {
    id
    userId
    entityId
    entityType
    timeToNotify
    status
    deliveryMethods
    title
    body
    link
    metadata
  }
`;

export const saveDeviceToken = async (token: string, signer: ISigner) => {
  const body = await getSignedRequestBody({token}, signer);
  return API.post('/deviceTokens', body);
};

export const removeDeviceToken = async (token: string, signer: ISigner) => {
  const body = await getSignedRequestBody(
    {action: `delete deviceToken ${token}`},
    signer,
  );
  return API.delete(`/deviceTokens/${token}`, {data: body});
};

export const addNotification = (
  notification: ISpindexerNotification,
  signer: ISigner,
) => submitEntityFieldUpdate('notifications', notification, signer, 'upsert');

export const markNotificationAsRead = (
  notification: ISpindexerNotification,
  signer: ISigner,
) =>
  submitEntityFieldUpdate<Partial<ISpindexerNotification>>(
    'notifications',
    {
      userId: notification.userId,
      entityId: notification.entityId,
      entityType: notification.entityType,
      status: NotificationStatus.read,
    },
    signer,
    'update',
  );

export const markNotificationsAsRead = (
  notifications: ISpindexerNotification[],
  signer: ISigner,
) => {
  const unreadNotifications = notifications.filter(
    n => n.status !== NotificationStatus.read,
  );
  return Promise.all(
    unreadNotifications.map(notification =>
      markNotificationAsRead(notification, signer),
    ),
  );
};

export const markNotificationsAsReadSafe = async (
  notifications: ISpindexerNotification[],
  signer: ISigner,
) => {
  try {
    await markNotificationsAsRead(notifications, signer);
  } catch (error) {
    // Silent catch
  }
};

export const createTrackReleaseNotification = (
  userId: string,
  track: ITrack,
  timeToNotify: string,
) => {
  return {
    userId,
    entityId: track.id,
    entityType: 'track-release',
    timeToNotify: timeToNotify,
    status: NotificationStatus.unsent,
    deliveryMethods: JSON.stringify([DeliveryMethod.push]),
    title: `Collect in 10 minutes!`,
    body: `${track.artist.name} - ${track.title}`,
    link: `spinamp://track/${track.slug}`,
  } as ISpindexerNotification;
};

export const fetchNotificationById = async (
  notificationId: string,
): Promise<ISpindexerNotification> => {
  const response = await pipelineApi.request(
    gql`
      query NotificationById($notificationId: String!) {
        notificationById(id: $notificationId) {
          ...SpindexerNotification
        }
      }
      ${SPINDEXER_NOTIFICATION_FRAGMENT}
    `,
    {
      notificationId,
    },
  );

  return response.notificationById;
};

export const fetchUpcomingNotifications = async (
  condition: Partial<ISpindexerNotification>,
): Promise<ISpindexerNotification[]> => {
  const response = await pipelineApi.request(
    gql`
      query UserNotificationsForEntity($condition: NotificationCondition!) {
        allNotifications(
          condition: $condition
          filter: {status: {equalTo: "unsent"}}
        ) {
          items: nodes {
            ...SpindexerNotification
          }
        }
      }
      ${SPINDEXER_NOTIFICATION_FRAGMENT}
    `,
    {
      condition,
    },
  );

  return response.allNotifications.items;
};

export const fetchNotificationsHistory = async (
  userId: string,
  after?: string,
): Promise<IPaginatedResponse<ISpindexerNotification>> => {
  const response = await pipelineApi.request(
    gql`
      query UserNotificationsHistory($userId: String!, $after: Cursor) {
        userById(id: $userId) {
          notificationsByUserId(
            orderBy: TIME_TO_NOTIFY_DESC
            after: $after
            first: 100
            filter: {status: {notEqualTo: "unsent"}}
          ) {
            nodes {
              ...SpindexerNotification
            }
            pageInfo {
              hasNextPage
              endCursor
            }
          }
        }
      }
      ${SPINDEXER_NOTIFICATION_FRAGMENT}
    `,
    {
      userId,
      after,
    },
  );

  const pageResponse = response.userById.notificationsByUserId;
  const pageInfo: IPageInfo = pageResponse.pageInfo;
  const items: ISpindexerNotification[] = pageResponse.nodes;

  return {
    pageInfo,
    items,
  };
};

export const fetchUnreadNotificationsCount = async (
  userId: string,
): Promise<number> => {
  const response = await pipelineApi.request(
    gql`
      query UnreadNotificationsCount($userId: String!) {
        userById(id: $userId) {
          notificationsByUserId(
            orderBy: TIME_TO_NOTIFY_DESC
            filter: {status: {in: ["sent", "failed"]}}
          ) {
            totalCount
          }
        }
      }
    `,
    {
      userId,
    },
  );

  return response.userById.notificationsByUserId.totalCount;
};
