import {useEffect, useRef, useState} from 'react';

import {useGlobalPressHandler} from '@/hooks/useGlobalPressHandler';

export const useCardMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // we want to lazy mount menu on demand, but keep it mounted when close to handle animation
  const wasOpen = useRef(false);
  const isMenuMounted = isMenuOpen || wasOpen.current;

  const openMenu = () => {
    if (!isMenuOpen) {
      setIsMenuOpen(true);
      wasOpen.current = true;
    }
  };

  const globalPressHandler = useGlobalPressHandler();

  useEffect(() => {
    if (isMenuOpen) {
      return globalPressHandler.addEventListener(() =>
        requestAnimationFrame(() => setIsMenuOpen(false)),
      );
    }
  }, [isMenuOpen]);

  return {
    isMenuOpen,
    isMenuMounted,
    openMenu,
  };
};
