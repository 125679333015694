import React, {FC, useEffect} from 'react';
import {StyleProp} from 'react-native';
import Animated, {
  Easing,
  FadeIn,
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';

import SpinLogo from '@/components/SpinLogo';
import spacing from '@/constants/spacing';

interface IProps {
  size?: number;
  style?: StyleProp<any>;
}

const SpinLoader: FC<IProps> = ({size = spacing.l, style: customStyle}) => {
  const spinAnimation = useSharedValue(0);
  const rotationStyle = useAnimatedStyle(() => ({
    transform: [
      {
        rotate: `${spinAnimation.value}deg`,
      },
    ],
    opacity: interpolate(spinAnimation.value, [0, 1080], [0.6, 1]),
  }));

  useEffect(() => {
    spinAnimation.value = withRepeat(
      withTiming(360 * 3, {duration: 2000, easing: Easing.ease}),
      -1,
      true,
    );
  }, []);

  return (
    <Animated.View
      style={[{width: size, height: size}, customStyle]}
      entering={FadeIn.duration(200)}>
      <Animated.View style={[rotationStyle, {width: size, height: size}]}>
        <SpinLogo size={size} />
      </Animated.View>
    </Animated.View>
  );
};

export default SpinLoader;
