import {Platform, StyleSheet} from 'react-native';

import spacing, {DESKTOP_LAYOUT_SIZE} from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    flex: 1,
    paddingHorizontal: spacing.s,
    justifyContent: 'center',
    ...Platform.select({
      web: StyleSheet.absoluteFillObject,
    }),
  },
  backdrop: {
    backgroundColor: theme.colors.backdrop,
    ...StyleSheet.absoluteFillObject,
  },
  modal: {
    maxHeight: '90%',
    width: '100%',
    overflow: 'hidden',
    alignSelf: 'center',
  },
  modalSmallDesktop_s: {
    width: '50%',
  },
  modalSmallDesktop_l: {
    width: '60%',
  },
  modalDesktop_s: {
    maxWidth: DESKTOP_LAYOUT_SIZE.maxModalWidth,
  },
  modalDesktop_l: {
    maxWidth: DESKTOP_LAYOUT_SIZE.maxModalWidth * 1.2,
  },
  card: {
    maxHeight: '100%',
    padding: 0,
    backgroundColor: theme.colors.backgroundLight,
    overflow: 'hidden',
  },
  header: {
    backgroundColor: theme.colors.primaryDark,
    paddingVertical: spacing.xs,
    paddingHorizontal: spacing.l + spacing.xs * 2,
  },
  closeIcon: {
    position: 'absolute',
    left: spacing.xs,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
