import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  card: {
    borderRadius: theme.box.borderRadius,
    backgroundColor: theme.colors.background,
    borderWidth: theme.box.baseBorderWidth,
    borderColor: theme.colors.borderColor,
    borderRightWidth: theme.box.borderRightWidth,
    borderBottomWidth: theme.box.borderBottomWidth,
  },
  highlight: {
    backgroundColor: theme.colors.active,
    borderWidth: theme.box.activeBorderWidth,
    borderRightWidth: theme.box.activeBorderRightWidth,
    borderBottomWidth: theme.box.activeBorderBottomWidth,
    borderColor: theme.colors.activeBorder,
  },
  inverted: {
    backgroundColor: theme.colors.primary,
    borderColor: theme.colors.invertedBorderColor,
  },
  disabled: {
    opacity: 0.4,
  },
  hovered: {
    borderColor:
      theme.colors.hoverBorderColor || theme.colors.invertedBorderColor,
    backgroundColor: theme.colors.hoverBackgroundColor || undefined,
  },
  pressed: {
    backgroundColor: theme.colors.primary,
  },
  hoveredHighlighted: {
    borderColor: theme.colors.active,
  },
}));
