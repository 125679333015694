import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React from 'react';

import Artists from '@/screens/Artists/Artists';
import Explore from '@/screens/Explore/Explore';
import LatestTracks from '@/screens/LatestTracks/LatestTracks';
import TrendingPlaylists from '@/screens/TrendingPlaylists/TrendingPlaylists';
import TrendingTracks from '@/screens/TrendingTracks/TrendingTracks';
import {ExploreStackParams, Routes} from '@/types/routes';

const Stack = createNativeStackNavigator<ExploreStackParams>();

const ExploreNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={Routes.Explore}>
      <Stack.Screen name={Routes.Explore} component={Explore} />
      <Stack.Screen name={Routes.Artists} component={Artists} />
      <Stack.Screen name={Routes.LatestTracks} component={LatestTracks} />
      <Stack.Screen name={Routes.TrendingTracks} component={TrendingTracks} />
      <Stack.Screen
        name={Routes.TrendingPlaylists}
        component={TrendingPlaylists}
      />
    </Stack.Navigator>
  );
};

export default ExploreNavigation;
