import React, {useCallback, useEffect, useRef, useState} from 'react';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import ScreenLoader from '@/components/ScreenLoader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import ArtistFeedCard from '@/screens/Feed/components/FeedCard/ArtistFeedCard';
import {
  GenreRefillFeature,
  OnboardingGenresFeedCard,
} from '@/screens/Feed/components/FeedCard/GenresFeedCard';
import LocalMessageFeedCard from '@/screens/Feed/components/FeedCard/LocalMessageFeedCard';
import MessageFeedCard from '@/screens/Feed/components/FeedCard/MessageFeedCard';
import TrackFeedCard from '@/screens/Feed/components/FeedCard/TrackFeedCard';
import {
  IFeedCarouselRef,
  SetScrollToTop,
} from '@/screens/Feed/components/FeedCarousel/shared';
import FeedInlineError from '@/screens/Feed/components/FeedInlineError';
import PlayAllModal from '@/screens/Feed/components/PlayAllModal';
import {useFeedOnboardingData} from '@/screens/Feed/useFeedOnboarding';
import {setShouldPlay as setFeedShouldPlay} from '@/store/feed';
import {selectIsPlaying} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {
  CloseOnboardingStep,
  IFeedEntityType,
  IFeedOnboardingConfig,
} from '@/types/feed';
import {noop} from '@/utils/functions';
import {isWeb} from '@/utils/platform';

import {styles} from './Feed.styles';
import FeedCarousel from './FeedCarousel/FeedCarousel';

interface IFeedOnboardingProps {
  userId: string;
  isPlayAllModalOpen: boolean;
  togglePlayAllModal: () => void;
  setScrollToTop?: SetScrollToTop;
  initialIndex?: number;
  onIndexChange?: (index: number) => void;
  onboardingState: IFeedOnboardingConfig;
  closeOnboardingStep: CloseOnboardingStep;
}

const FeedOnboarding: React.FC<IFeedOnboardingProps> = ({
  userId,
  isPlayAllModalOpen,
  togglePlayAllModal,
  setScrollToTop,
  initialIndex = 0,
  onIndexChange,
  onboardingState,
  closeOnboardingStep,
}) => {
  const dispatch = useAppDispatch();
  const style = useThemedStyles(styles);

  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const feedCarouselRef = useRef<IFeedCarouselRef | null>(null);
  const next = useCallback(() => feedCarouselRef.current?.next(), []);

  const isMainPlayerPlaying = useAppSelector(selectIsPlaying);

  useEffect(() => {
    if (isMainPlayerPlaying) {
      dispatch(setFeedShouldPlay(false));
    }
  }, [isMainPlayerPlaying]);

  const [artistCardsTrackIndex, setArtistCardsTrackIndex] = useState<{
    [feedId: string]: number;
  }>({});

  const changeArtistTrack = useCallback((feedItemId: string, index: number) => {
    setArtistCardsTrackIndex(current => ({
      ...current,
      [feedItemId]: index,
    }));
  }, []);

  const {
    feedItems,
    removeItem,
    query: feedQuery,
  } = useFeedOnboardingData(userId);

  return (
    <Animated.View entering={FadeIn} exiting={FadeOut} style={style.content}>
      {(() => {
        if (feedItems && feedItems.length > 0) {
          return (
            <FeedCarousel
              ref={feedCarouselRef}
              feedItems={feedItems}
              activeIndex={activeIndex}
              setActiveIndex={index => {
                setActiveIndex(index);
                onIndexChange?.(index);
              }}
              setScrollToTop={setScrollToTop}
              isOwnFeed={true}
              isCarouselEnabled={false}
              artistCardsTrackIndex={artistCardsTrackIndex}
              changeArtistTrack={changeArtistTrack}
              extraData={onboardingState}
              renderItem={({item, index}) => {
                const isActive = index === activeIndex;
                // On mobile we don't want to set keys to make use of FlashList recycling benefits.
                const key = isWeb ? item.id : undefined;
                // On the other hand LocalMessageFeedCard and FeedCard are not displayed that often and are cheap to render,
                // so we can set key for them for easier development.

                switch (item.entityType) {
                  case IFeedEntityType.track:
                    return (
                      <TrackFeedCard
                        key={key}
                        feedItem={item}
                        isActive={isActive}
                        next={next}
                        setCarouselEnabled={noop}
                        onHide={removeItem}
                        onboardingStep={onboardingState.track[0]}
                        closeOnboardingStep={onboardingItem =>
                          closeOnboardingStep({
                            type: 'track',
                            item: onboardingItem,
                          })
                        }
                      />
                    );
                  case IFeedEntityType.artist:
                    return (
                      <ArtistFeedCard
                        key={key}
                        feedItem={item}
                        isActive={isActive}
                        next={next}
                        setCarouselEnabled={noop}
                        onHide={removeItem}
                        trackIndex={artistCardsTrackIndex[item.id]}
                        setTrackIndex={changeArtistTrack}
                        onboardingStep={onboardingState.artist[0]}
                        isOnboarding
                        closeOnboardingStep={onboardingItem =>
                          closeOnboardingStep({
                            type: 'artist',
                            item: onboardingItem,
                          })
                        }
                      />
                    );
                  case IFeedEntityType.localMessage:
                    return (
                      <LocalMessageFeedCard
                        key={item.id}
                        feedItem={item}
                        next={next}
                        isActive={isActive}
                      />
                    );
                  case IFeedEntityType.refill:
                    return (
                      <OnboardingGenresFeedCard
                        key={item.id}
                        item={item}
                        onEnd={next}
                        feature={<GenreRefillFeature isOnboarding />}
                      />
                    );
                  case IFeedEntityType.message:
                    return <MessageFeedCard key={key} feedItem={item} />;
                }
              }}
            />
          );
        }

        if (feedQuery.isPending) {
          return <ScreenLoader />;
        }

        return (
          <FeedInlineError
            isFetching={feedQuery.isFetching}
            onRefetch={feedQuery.refetch}
          />
        );
      })()}

      {isPlayAllModalOpen && (
        <PlayAllModal
          feedItems={feedItems || []}
          isOpen
          onClose={togglePlayAllModal}
        />
      )}
    </Animated.View>
  );
};

export default FeedOnboarding;
