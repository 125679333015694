import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useAppDispatch} from '@/hooks/useRedux';
import {useLoginState, useLoginError} from '@/modules/Login';
import {linkPasskey} from '@/store/user';
import {useThemedStyles} from '@/theme';

import {styles} from './LinkPasskey.style';

const LinkPasskey: FC = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const {
    passkeyCredential,
    setPasskeyCredential,
    closeLoginModal,
    setLoginRoute,
  } = useLoginState();
  const [isLoading, startLoading, endLoading] = useBooleanState(false);
  const {handleLoginError, resetLoginError} = useLoginError();

  const onConfirm = async () => {
    try {
      resetLoginError();
      startLoading();
      await dispatch(linkPasskey(passkeyCredential!));
      closeLoginModal();
      setPasskeyCredential(null);
    } catch (error) {
      handleLoginError(error);
    } finally {
      endLoading();
    }
  };

  return (
    <Space style={style.content}>
      <Icon style={style.icon} provider="custom" name="lock" size={76} />

      <Text
        weight="semibold"
        size="m"
        id="login.passkey.title"
        align="center"
      />

      <Text id="login.passkey.description" size="xs" />

      <View style={style.actions}>
        <CardButton
          onPress={onConfirm}
          text={{id: 'login.passkey.confirm', size: 'xs'}}
          icon={{name: 'key', provider: 'custom'}}
          isLoading={isLoading}
        />
        <TouchableOpacity onPress={() => setLoginRoute('initial')}>
          <Text
            align="center"
            id="login.passkey.cancel"
            size="xs"
            values={{
              goBack: (
                <Text
                  weight="semibold"
                  id="login.passkey.cancel.goBack"
                  size="xs"
                  underline
                />
              ),
            }}
          />
        </TouchableOpacity>
      </View>
    </Space>
  );
};

export default LinkPasskey;
