import React, {FC} from 'react';
import {ScrollView, View} from 'react-native';

import {styles} from '../FeedCard/common.styles';
import Icon from '@/components/Icon';
import Space from '@/components/Space';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useAppDispatch} from '@/hooks/useRedux';
import {useThemes} from '@/hooks/useThemes';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import BaseFeedCard from '@/screens/Feed/components/FeedCard/BaseFeedCard';
import ThemeCard from '@/screens/ThemesSettings/components/ThemeCard';
import {setThemeSettings} from '@/store/theme';
import {useThemedStyles} from '@/theme';
import {sleep} from '@/utils/functions';
import {capitalizeString} from '@/utils/string';

interface IProps {
  next?: () => void;
  isActive?: boolean;
}

const ThemesFeedCard: FC<IProps> = ({next, isActive = false}) => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();

  const {availableThemes} = useCustomThemesConfigQuery();
  const {getTheme} = useThemes();

  return (
    <BaseFeedCard>
      <ScrollView
        showsVerticalScrollIndicator={false}
        bounces={false}
        style={{flex: 1}}
        contentContainerStyle={{
          padding: spacing.m,
          gap: spacing.xs * 1.5,
          alignItems: 'center',
          flexGrow: 1,
        }}>
        <Icon name="palette" provider="custom" style={style.featureIcon} />
        <Text
          id="feed.onboarding.themes.title"
          weight="semibold"
          style={style.centeredText}
          size="l"
        />
        <Text
          id="feed.onboarding.themes.message"
          style={style.centeredText}
          size="xs"
        />
        <View
          style={{
            alignSelf: 'stretch',
            margin: -spacing.xs / 2,
            flexDirection: 'row',
            flexWrap: 'wrap',
            paddingTop: spacing.xs,
          }}>
          {availableThemes.map(theme => (
            <View
              key={theme.name}
              style={{
                padding: spacing.xs / 2,
                flexBasis: '50%',
              }}>
              <ThemeCard
                onPress={async () => {
                  dispatch(
                    setThemeSettings({type: 'predefined', name: theme.name}),
                  );
                  await sleep(800);
                  next?.();
                }}
                height={50}
                name={capitalizeString(theme.name)}
                theme={getTheme(theme.name)}
                disabled={!isActive}
                textProps={{
                  size: 's',
                }}
              />
            </View>
          ))}
        </View>
        <Space flex />
        <TransparentButton
          onPress={() => next?.()}
          text={{
            id: 'feed.onboarding.themes.skip',
            underline: true,
            size: 'xs',
          }}
        />
      </ScrollView>
    </BaseFeedCard>
  );
};

export default ThemesFeedCard;
