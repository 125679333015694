import React, {FC} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Card from '@/components/Card/Card';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useArtistFollow} from '@/hooks/useArtistFollow';
import {useResponsive} from '@/hooks/useResponsive';
import {useDbQuery} from '@/queries/db';
import DelayedFadeIn from '@/screens/SpinampWrapped/components/DelayedFadeIn';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useSpinampWrappedAudio} from '@/screens/SpinampWrapped/useSpinampWrappedAudio';
import {useTheme} from '@/theme';
import {ImageSize} from '@/types/media';
import {findArtistTrack} from '@/utils/db';

const TopArtists: FC = () => {
  const theme = useTheme();
  const {db} = useDbQuery();
  const {isDesktop} = useResponsive();
  const {activePageId, artists, nextPage, tracks} = useSpinampWrapped();
  const isActive = activePageId === 'topArtists';
  const {getIsFollowed, toggleFollow} = useArtistFollow();

  const artistToPlay = (artists[1] || artists[0]).artist;
  const trackToPlay = findArtistTrack(db, artistToPlay?.id) || tracks[0].track;

  useSpinampWrappedAudio(isActive, trackToPlay);

  if (artists.length === 0) {
    return null;
  }

  return (
    <ScrollView
      style={{flex: 1}}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingVertical: spacing.m,
        padding: spacing.s,
      }}>
      <Space mb="m">
        <Text
          align="center"
          weight="bold"
          size="l"
          id="spinampWrapped.topArtists.title"
        />
        <Space mt="s" />
        <Text
          align="center"
          size="xs"
          id="spinampWrapped.topArtists.description"
        />
        <Space mt="xs" />
        <Text align="center" size="xs" id="spinampWrapped.topArtists.follow" />
      </Space>

      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}>
        {artists.map(({artist}) => (
          <View
            key={artist.id}
            style={{
              flexBasis: `${100 / (isDesktop ? 3 : 2)}%`,
              padding: spacing.xs / 2,
            }}>
            <Card
              style={{
                paddingHorizontal: 0,
                paddingVertical: 0,
                overflow: 'hidden',
              }}>
              <View
                style={{
                  width: '100%',
                  aspectRatio: 1,
                  backgroundColor: theme.colors.backgroundLight,
                }}>
                {artist.avatarUrl && (
                  <FadeInOut
                    style={{
                      ...StyleSheet.absoluteFillObject,
                      backgroundColor: theme.colors.backgroundDark,
                    }}
                  />
                )}
                {artist.avatarUrl ? (
                  <StaticImage
                    source={{uri: artist.avatarUrl}}
                    resizeWidth={ImageSize.artist}
                    style={{width: '100%', height: '100%'}}
                  />
                ) : (
                  <View
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundColor: theme.colors.backgroundDark,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <Icon
                      name="userMusic"
                      provider="custom"
                      size={36}
                      color="primary"
                    />
                  </View>
                )}
              </View>

              <Space
                ph="xs"
                pv="xxs"
                style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text size="xs" weight="semibold" flex numberOfLines={1}>
                  {artist.name}
                </Text>
                <Space w="xs" />
                <IconButton
                  onPress={() => toggleFollow(artist.id)}
                  icon={{
                    name: 'heart',
                    provider: 'custom',
                    size: 18,
                    fill: getIsFollowed(artist.id),
                    color: getIsFollowed(artist.id)
                      ? 'favoritesColor'
                      : 'textColor',
                  }}
                />
              </Space>
            </Card>
          </View>
        ))}
      </View>

      <Space mv="s" style={{alignItems: 'center'}}>
        <DelayedFadeIn isActive={isActive}>
          <TransparentButton
            onPress={nextPage}
            text={{id: 'spinampWrapped.next'}}
          />
        </DelayedFadeIn>
      </Space>
    </ScrollView>
  );
};

export default TopArtists;
