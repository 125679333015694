import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './TipsSettingsError.style';

interface IProps {
  onRefresh: () => void;
}

const TipsSettingsError: FC<IProps> = ({onRefresh}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <Text id="tipsSettings.loadError" align="center" />
      <CardButton onPress={onRefresh} text={{id: 'tryAgain'}} />
    </View>
  );
};

export default TipsSettingsError;
