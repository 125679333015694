import spacing from '@/constants/spacing';
import {
  calculateBackgroundColorLight,
  calculateBackgroundColorDark,
  createThemedStyles,
} from '@/theme';

const SCREEN_PADDING = spacing.s;

export const styles = createThemedStyles(theme => ({
  headerActions: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scrollContent: {
    flexGrow: 1,
    padding: SCREEN_PADDING,
    paddingTop: spacing.xxs,
    backgroundColor: calculateBackgroundColorLight(theme),
  },
  header: {
    backgroundColor: calculateBackgroundColorDark(theme),
    margin: -SCREEN_PADDING,
    marginBottom: SCREEN_PADDING,
    paddingTop: SCREEN_PADDING,
  },
}));
