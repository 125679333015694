import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import InfinityList, {InfinityListEmpty} from '@/components/InfinityList';
import Screen from '@/components/Screen/Screen';
import TrackCard, {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useTrendingTracksQuery} from '@/queries/trendingTracks';
import {playNewQueue} from '@/store/player';
import {ITrack, PlayContextType} from '@/types/common';

const TrendingTracks = () => {
  const dispatch = useDispatch();
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const {
    trendingTracks,
    query: {isLoading, isError},
  } = useTrendingTracksQuery();
  const {isTrackActive} = useIsTrackActive(PlayContextType.trending);

  const onPlay = useCallback(
    (track: ITrack) => {
      dispatch(
        playNewQueue({
          trackIds: trendingTracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'TrendingMore',
            type: PlayContextType.trending,
            titleId: 'explore.trendingTracks',
          },
        }),
      );
    },
    [trendingTracks],
  );

  return (
    <Screen>
      <Header
        titleId="explore.trendingTracks"
        showBack
        scrollPosition={scrollPosition}
        mockTitle
      />
      <AsyncContent isLoading={isLoading}>
        {() => (
          <InfinityList
            onScroll={onScroll}
            data={trendingTracks}
            itemSize={TRACK_CARD_HEIGHT}
            keyExtractor={track => track.id}
            extraData={isTrackActive}
            renderItem={({item: track}) => (
              <TrackCard
                track={track}
                showArtist
                onPlay={onPlay}
                isActive={isTrackActive(track.id)}
              />
            )}
            ListEmptyComponent={<InfinityListEmpty isError={isError} />}
          />
        )}
      </AsyncContent>
    </Screen>
  );
};

export default TrendingTracks;
