import {useQueryClient} from '@tanstack/react-query';
import {Vibration} from 'react-native';
import {isHex} from 'viem';
import {useWalletClient} from 'wagmi';

import {useConnectorName} from '@/modules/ExternalWallet/useConnectorName';
import {tipChain} from '@/modules/Tip/constants';
import {VerifyAddressStep} from '@/modules/Tip/types';
import {getVerifyTransactionInput} from '@/modules/Tip/utils';
import {useTrackVerifyAddressEvent} from '@/modules/Tip/VerifyAddress/useTrackVerifyAddressEvent';
import {useVerifyAddressState} from '@/modules/Tip/VerifyAddress/useVerifyAddressState';
import {isWalletCancelError, TransactionError} from '@/modules/Transactions';
import {sendTransaction, waitForReceipt} from '@/modules/Wallets/passkeyWallet';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {Passkey} from '@/services/passkey';
import {Sentry} from '@/services/sentry';
import {IAddress} from '@/types/common';
import {QueryKeys} from '@/types/queryKeys';
import {areAddressesEqual, getChainClient} from '@/utils/ethereum';
import {getSignerFromSessionKey} from '@/utils/signer';

export const useVerifyTransaction = () => {
  const queryClient = useQueryClient();
  const {data: walletClient} = useWalletClient();
  const connector = useConnectorName();

  const {updateTransaction, resetTransactionDetails, gasEstimation} =
    useVerifyAddressState();

  const {trackEvent} = useTrackVerifyAddressEvent();

  const setStep = (step: VerifyAddressStep) => updateTransaction({step});

  const verifyPasskeyWallet = async (
    verifiedWallet: IAddress,
    payoutAddress: string,
  ) => {
    setStep('waitingForPasskey');

    const {credentialId, signer} = verifiedWallet.metadata?.spinampWallet || {};
    const {privateKey} = await Passkey.get(credentialId);

    if (
      signer &&
      !areAddressesEqual(signer, getSignerFromSessionKey(privateKey).address)
    ) {
      throw new TransactionError('WRONG_PASSKEY_SIGNER');
    }

    setStep('executingTransaction');

    const txInput = getVerifyTransactionInput({
      verifiedAddress: verifiedWallet.address,
      payoutAddress,
    });

    const userOpHash = await sendTransaction({
      privateKey,
      chainId: tipChain.id,
      transaction: txInput,
      gasSettings: {
        callGasLimit: gasEstimation.callGasLimit,
        preVerificationGas: gasEstimation.preVerificationGas,
        verificationGasLimit: gasEstimation.verificationGasLimit,
        maxFeePerGas: gasEstimation.maxFeePerGas,
        maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
      },
    });

    updateTransaction({userOpHash});
    trackEvent('receivedTransactionUserOpHash');

    setStep('waitingForReceipt');

    const {transactionHash, status} = await waitForReceipt({
      privateKey,
      chainId: tipChain.id,
      hash: userOpHash,
    });

    updateTransaction({txHash: transactionHash});
    trackEvent('receivedTransactionTxHash');
    console.log({
      transactionHash,
      status,
    });

    if (status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const verifyExternalWallet = async (
    verifiedWallet: IAddress,
    payoutAddress: string,
  ) => {
    if (!walletClient) {
      throw new TransactionError('WALLET_NOT_CONNECTED');
    }

    if (walletClient.account.address !== verifiedWallet.address) {
      throw new TransactionError('WRONG_WALLET_CONNECTED');
    }

    setStep('executingTransaction');

    const txInput = getVerifyTransactionInput({
      verifiedAddress: verifiedWallet.address,
      payoutAddress,
    });

    const txHash = await walletClient.sendTransaction({
      ...txInput,
      gas: gasEstimation.gasLimit,
      maxFeePerGas: gasEstimation.maxFeePerGas,
      maxPriorityFeePerGas: gasEstimation.maxPriorityFeePerGas,
    });

    if (!txHash || !isHex(txHash)) {
      // For some wallets canceling transaction results in returning "null" txHash instead of throwing an error
      throw new TransactionError('USER_CANCELLED');
    }

    updateTransaction({txHash});
    trackEvent('receivedTransactionTxHash');

    setStep('waitingForReceipt');

    console.log({txHash});

    const receipt = await getChainClient(tipChain.id).waitForTransactionReceipt(
      {hash: txHash},
    );

    if (receipt.status !== 'success') {
      throw new TransactionError('TRANSACTION_REVERTED');
    }
  };

  const verifyAddress = async (
    verifiedWallet: IAddress,
    payoutAddress: string,
  ) => {
    try {
      resetTransactionDetails();

      trackEvent('confirmed');

      if (verifiedWallet.isPasskey) {
        await verifyPasskeyWallet(verifiedWallet, payoutAddress);
      } else {
        await verifyExternalWallet(verifiedWallet, payoutAddress);
      }

      setStep('success');
      queryClient.invalidateQueries({queryKey: [QueryKeys.pendingTipsBalance]});
      Vibration.vibrate();
      trackEvent('success');
    } catch (error) {
      setStep('checkout');

      if (isWalletCancelError(error)) {
        trackEvent('cancelled');
        resetTransactionDetails();
        return;
      }

      Sentry.captureException(error, {
        tags: {
          verifyTipAddress: true,
          passkeyWallet: isPasskeyWallet(verifiedWallet),
        },
        extra: {
          wallet: verifiedWallet,
          connector,
        },
      });

      updateTransaction({error});
      trackEvent('error');
    }
  };

  return {
    verifyAddress,
  };
};
