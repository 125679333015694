import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar';
import CardButton from '@/components/CardButton';
import DashedDivider from '@/components/DashedDivider';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text/Text';
import spacing from '@/constants/spacing';
import {tipChain} from '@/modules/Tip/constants';
import {useVerifyAddressState} from '@/modules/Tip/VerifyAddress/useVerifyAddressState';
import {EtherscanLink, JiffyscanLink} from '@/modules/Transactions';
import {isPasskeyWallet} from '@/modules/Wallets/utils';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {noop} from '@/utils/functions';

import {styles, AVATAR_SIZE} from './VerifyAddressPending.style';

const VerifyAddressPending: FC = () => {
  const style = useThemedStyles(styles);

  const {user, verifiedWallet, transaction} = useVerifyAddressState();
  const {txHash, userOpHash} = transaction;
  const isPasskey = isPasskeyWallet(verifiedWallet);

  return (
    <View style={style.container}>
      <View style={style.content}>
        {user.artistProfile && (
          <View style={style.userTile}>
            <Avatar
              id={user.id}
              url={user.artistProfile.avatarUrl}
              size={AVATAR_SIZE}
              resizeWidth={ImageSize.avatar}
            />
            <Text weight="semibold" numberOfLines={1} flex>
              {user.artistProfile.name}
            </Text>
            <SpinLoader size={spacing.l} />
          </View>
        )}

        <DashedDivider />

        <View style={style.textsContainer}>
          {txHash || isPasskey ? (
            <Text
              weight="semibold"
              size="s"
              id="transfer.pending.waitingForReceipt"
              align="center"
            />
          ) : (
            <Text
              weight="semibold"
              size="s"
              id="transfer.pending.waitingForSignature"
              align="center"
            />
          )}

          {(txHash || userOpHash) && (
            <View style={style.links}>
              {userOpHash && (
                <View style={style.link}>
                  <JiffyscanLink
                    userOpHash={userOpHash}
                    chainId={tipChain.id}
                  />
                </View>
              )}
              {txHash && (
                <View style={style.link}>
                  <EtherscanLink txHash={txHash} chainId={tipChain.id} />
                </View>
              )}
            </View>
          )}
        </View>
      </View>

      <CardButton
        onPress={noop}
        loaderProps={{textId: 'processing'}}
        isLoading
      />
    </View>
  );
};

export default VerifyAddressPending;
