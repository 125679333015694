import {gql} from 'graphql-request';

import {pipelineApi} from '@/services/pipelineApi';
import {IPageInfo, IPaginatedResponse} from '@/types/api';
import {IMusicPlatformData, ITrack} from '@/types/common';
import {parseApiTrack, TRACK_FRAGMENT} from '@/utils/apiModelParsers';

export const fetchAllPlatforms = async (): Promise<IMusicPlatformData[]> => {
  const response = await pipelineApi.request(gql`
    query Platforms {
      allPlatforms {
        nodes {
          name
          id
        }
      }
    }
  `);

  return response.allPlatforms.nodes;
};

export const fetchPlatformById = async (
  id: string,
): Promise<{id: string; name: string}> => {
  const response = await pipelineApi.request(gql`
    query PlatformById {
      platformById(id: "${id}") {
        id
        name
      }
    }
  `);

  return response.platformById;
};

export const fetchPlatformTracks = async (
  platformId: string,
  after?: string,
): Promise<IPaginatedResponse<ITrack>> => {
  const response = await pipelineApi.request(
    gql`
      query PlatformTracks($platformId: String!, $after: Cursor) {
        allProcessedTracks(
          after: $after
          first: 100
          orderBy: CREATED_AT_TIME_DESC
          filter: {platformId: {equalTo: $platformId}}
        ) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            ...TrackDetails
          }
        }
      }
      ${TRACK_FRAGMENT}
    `,
    {platformId, after},
  );

  const tracks: ITrack[] = response.allProcessedTracks.nodes.map(parseApiTrack);
  const pageInfo: IPageInfo = response.allProcessedTracks.pageInfo;

  return {
    pageInfo,
    items: tracks,
  };
};
