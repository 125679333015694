// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinamp-color-picker.react-colorful {
    width: auto;
    height: auto;
}

.spinamp-color-picker .react-colorful__saturation {
    height: 200px;
    box-sizing: border-box;
    border-radius: 3px;
}

.spinamp-color-picker .react-colorful__hue {
    margin-top: 16px;
    border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ColorPicker/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".spinamp-color-picker.react-colorful {\n    width: auto;\n    height: auto;\n}\n\n.spinamp-color-picker .react-colorful__saturation {\n    height: 200px;\n    box-sizing: border-box;\n    border-radius: 3px;\n}\n\n.spinamp-color-picker .react-colorful__hue {\n    margin-top: 16px;\n    border-radius: 3px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
