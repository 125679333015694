import {ITrack} from '@/types/common';
import {getResizedUri} from '@/utils/image';

const getCoverArtwork = (src?: string) => {
  if (!src) {
    return undefined;
  }

  return [96, 128, 192, 256, 384, 512, 1024, 2048].map(size => ({
    src: getResizedUri(src, size),
    sizes: `${size}x${size}`,
    type: 'image/jpeg',
  }));
};

export const setRemoteHandler = (
  event: MediaSessionAction,
  handler: MediaSessionActionHandler,
) => {
  if ('mediaSession' in navigator) {
    try {
      navigator.mediaSession.setActionHandler(event, handler);
    } catch (error) {
      // ignore error
    }
  }
};

export const updateMetadata = (track: ITrack) => {
  if ('mediaSession' in navigator) {
    try {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: track.title,
        artist: track.artist.name,
        artwork: getCoverArtwork(track.lossyArtworkUrl as string),
      });
    } catch (error) {
      // ignore error}
    }
  }
};
