import {Slider} from '@miblanchard/react-native-slider';
import React, {FC, useRef} from 'react';
import {Pressable, View} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  withTiming,
} from 'react-native-reanimated';

import IconButton from '@/components/IconButton';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {changeVolume, selectVolume} from '@/store/player';
import {useTheme, useThemedStyles} from '@/theme';

import {styles, THUMB_SIZE} from './VolumeSlider.style';

const MAX_VOLUME = 1;
const MIN_VOLUME = 0;

const VolumeSlider: FC = () => {
  const style = useThemedStyles(styles);
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const volume = useAppSelector(selectVolume);
  const volumeBeforeMute = useRef(volume);

  const isMuted = volume === MIN_VOLUME;

  const onVolumeChange = (_volume: number) => {
    dispatch(changeVolume(_volume));
  };

  const onMutePress = () => {
    if (isMuted) {
      onVolumeChange(volumeBeforeMute.current || MAX_VOLUME);
      volumeBeforeMute.current = MAX_VOLUME;
    } else {
      volumeBeforeMute.current = volume;
      onVolumeChange(MIN_VOLUME);
    }
  };

  const isHovered = useSharedValue(false);

  const thumbStyle = useAnimatedStyle(() => ({
    transform: [
      {translateX: -THUMB_SIZE / 2},
      {scale: withSpring(isHovered.value ? 1 : 0)},
    ],
  }));

  const progressStyle = useAnimatedStyle(() => ({
    backgroundColor: withTiming(
      isHovered.value ? theme.colors.active : theme.colors.textColor,
      {duration: 250},
    ),
  }));

  const percentageProgress = (volume * 100) / MAX_VOLUME;

  return (
    <View style={style.container}>
      <IconButton
        onPress={onMutePress}
        icon={{provider: 'custom', name: isMuted ? 'mute' : 'volume'}}
      />
      <Pressable
        style={style.progressContainer}
        onHoverIn={() => (isHovered.value = true)}
        onHoverOut={() => (isHovered.value = false)}>
        <Slider
          containerStyle={style.sliderController}
          minimumValue={MIN_VOLUME}
          maximumValue={MAX_VOLUME}
          minimumTrackTintColor="transparent"
          maximumTrackTintColor="transparent"
          value={volume}
          onValueChange={([value]) => onVolumeChange(value)}
          renderThumbComponent={() => null}
          trackClickable
        />

        <View style={style.progressBar}>
          <View style={style.progressBackground} />
          <Animated.View
            style={[
              style.progress,
              progressStyle,
              {
                width: `${percentageProgress}%`,
              },
            ]}
          />
        </View>

        <Animated.View
          style={[
            style.thumb,
            {
              pointerEvents: 'none',
              position: 'absolute',
              left: `${percentageProgress}%`,
            },
            thumbStyle,
          ]}
        />
      </Pressable>

      <View style={style.rightActionPlaceholder} />
    </View>
  );
};

export default VolumeSlider;
