import React, {FC, ReactNode} from 'react';
import {View} from 'react-native';

import {useTheme} from '@/theme';

interface IProps {
  children: ReactNode;
}

const FullScreenWrapper: FC<IProps> = ({children}) => {
  const theme = useTheme();

  return (
    <View style={{flex: 1, backgroundColor: theme.colors.backgroundLight}}>
      {children}
    </View>
  );
};

export default FullScreenWrapper;
