import {useQuery} from '@tanstack/react-query';

import {fetchSpinampWrappedSummary} from '@/api/wrapped';
import {MINUTE} from '@/constants/time';
import {useDbQuery} from '@/queries/db';
import {QueryKeys} from '@/types/queryKeys';

export const useSpinampWrappedQuery = (
  userSlugOrId?: string,
  enabled = true,
) => {
  const {updateDb} = useDbQuery();

  const query = useQuery({
    queryKey: [QueryKeys.spinampWrapped, userSlugOrId],
    queryFn: async () => {
      const {user, listen, collection} = await fetchSpinampWrappedSummary(
        userSlugOrId!,
      );

      updateDb({
        tracks: listen.tracks.map(t => t.track),
        artists: [
          ...listen.artists.map(a => a.artist),
          ...collection.supportedArtists,
        ],
      });

      return {
        user,
        listen,
        collection,
      };
    },
    enabled: !!userSlugOrId && enabled,
    staleTime: 30 * MINUTE,
  });

  const user = query.data?.user;
  const tracks = query.data?.listen.tracks || [];
  const artists = query.data?.listen.artists || [];
  const totalNftsCollected = query.data?.collection.totalNftsCollected || 0;
  const supportedArtists = query.data?.collection.supportedArtists || [];

  return {
    user,
    tracks,
    artists,
    totalNftsCollected,
    supportedArtists,
    isWrappedEmpty: tracks.length === 0,
    query,
  };
};
