import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = spacing.s * 2 + spacing.xxs;
export const TIPPER_CARD_HEIGHT = 54;

export const styles = createThemedStyles(() => ({
  card: {
    height: TIPPER_CARD_HEIGHT,
    borderRadius: TIPPER_CARD_HEIGHT / 2,
    justifyContent: 'center',
    paddingLeft: spacing.xs,
    paddingRight: spacing.s,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
  },
  content: {
    flex: 1,
    justifyContent: 'space-evenly',
    gap: spacing.xxs / 2,
  },
  tippedRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
  amount: {
    alignItems: 'flex-end',
    gap: spacing.xxs / 2,
  },
}));
