import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useEffect, useRef, useState} from 'react';
import {BackHandler, Platform, TextInput, View} from 'react-native';

import AvatarCard from '@/components/AvatarCard';
import Header from '@/components/Header';
import HeaderButton from '@/components/HeaderButton';
import Icon from '@/components/Icon';
import IconButton from '@/components/IconButton';
import InfinityList, {InfinityListRef} from '@/components/InfinityList';
import KeyboardView from '@/components/KeyboardView/KeyboardView';
import Loader from '@/components/Loader';
import Screen from '@/components/Screen';
import SearchInput from '@/components/SearchInput/SearchInput';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useBooleanState} from '@/hooks/useBooleanState';
import {useDebouncedSearch} from '@/hooks/useDebouncedSearch';
import {useAppSelector} from '@/hooks/useRedux';
import {useUsersSearchQuery} from '@/queries/search';
import RemoveCollaboratorModal from '@/screens/PlaylistCollaborators/components/RemoveCollaboratorModal';
import ShareModal from '@/screens/PlaylistCollaborators/components/ShareModal';
import {selectPlaylistById} from '@/store/playlists';
import {useThemedStyles} from '@/theme';
import {
  RootStackNavigationParams,
  RootStackParams,
  Routes,
} from '@/types/routes';
import {isIOS} from '@/utils/platform';

import PlaylistModeTile from './components/PlaylistModeTile';
import {styles} from './PlaylistCollaborators.style';
import {useCollaboratorsSettings} from './useCollaboratorsSettings';

const icons = {
  add: <Icon provider="custom" name="add" />,
  remove: <Icon provider="custom" name="checked" />,
};

const PlaylistCollaborators = () => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();
  const activeUser = useActiveUser();
  const {
    params: {id: playlistId},
  } = useRoute<RouteProp<RootStackParams, Routes.PlaylistCollaborators>>();
  const playlist = useAppSelector(state =>
    selectPlaylistById(state, playlistId),
  );
  const [isShareModalOpen, openShareModal, closeShareModal] =
    useBooleanState(false);

  const {
    collaboratorsIds,
    collaborators,
    toggleCollaborator,
    collaboratorToRemove,
    cancelRemoving,
    removeCollaborator,
    changePlaylistMode,
    modesConfig,
    didAddCollaborator,
  } = useCollaboratorsSettings(playlist);

  const searchInputRef = useRef<TextInput | null>(null);
  const [search, setSearch] = useState('');
  const {searchDebounced, clearSearch} = useDebouncedSearch({
    search,
    setSearch,
  });

  const listRef = useRef<InfinityListRef | null>(null);
  const {scrollPosition, onScroll} = useAnimatedHeader();

  const {
    users: searchResultsUsers,
    query: {isFetching},
  } = useUsersSearchQuery(searchDebounced);

  useEffect(() => {
    if (!playlist) {
      navigation.goBack();
    }
  }, [playlist]);

  useEffect(() => {
    listRef?.current?.scrollToOffset({offset: 0, animated: true});

    if (searchDebounced === '' && didAddCollaborator.current) {
      didAddCollaborator.current = false;
      setTimeout(() => {
        openShareModal();
      }, Platform.select({web: 200, default: 400}));
    }

    // disable android back button and ios gesture if user is searching
    if (searchDebounced !== '') {
      const subscription = BackHandler.addEventListener(
        'hardwareBackPress',
        () => {
          clearSearch();
          return true;
        },
      );
      navigation.setOptions({gestureEnabled: false});

      return () => {
        navigation.setOptions({gestureEnabled: true});
        subscription.remove();
      };
    }
  }, [searchDebounced]);

  useEffect(() => {
    if (!playlist || !activeUser || playlist.collector !== activeUser.id) {
      navigation.goBack();
    }
  }, [playlist, activeUser]);

  if (!playlist) {
    return null;
  }

  const OwnerCard = activeUser && (
    <AvatarCard
      user={activeUser}
      suffixComponent={<Text id="collaborators.owner" />}
    />
  );

  return (
    <Screen>
      <KeyboardView>
        <Header
          titleId="collaborators.title"
          titleProps={{size: 's'}}
          scrollPosition={scrollPosition}
          mockTitle
          modalMode={isIOS}
          leftActions={
            <IconButton
              onPress={() => {
                if (searchDebounced) {
                  clearSearch();
                  searchInputRef.current?.blur();
                } else {
                  navigation.goBack();
                }
              }}
              icon={{
                provider: 'custom',
                name: 'arrowDown',
              }}
            />
          }
          rightActions={
            searchDebounced ? (
              <HeaderButton
                onPress={() => {
                  clearSearch();
                  searchInputRef.current?.blur();
                }}
                text={{id: 'done'}}
              />
            ) : null
          }>
          <Space ph="s" pv="xs">
            <SearchInput
              ref={searchInputRef}
              value={search}
              setValue={setSearch}
              onClear={clearSearch}
              autoFocus={false}
              placeholderId="collaborators.searchPlaceholder"
            />
          </Space>
        </Header>
        <InfinityList
          ref={listRef}
          data={searchDebounced ? searchResultsUsers : collaborators}
          contentContainerStyle={style.scrollContent}
          itemSize={TRACK_CARD_HEIGHT}
          keyExtractor={item => item.id}
          onScroll={onScroll}
          ListHeaderComponent={
            !searchDebounced ? (
              <>
                <Text id="collaborators.permissions" weight="semibold" />
                <Space h="s" />
                <View style={style.playlistModes}>
                  {modesConfig.map(config => (
                    <PlaylistModeTile
                      key={config.mode}
                      onPress={changePlaylistMode}
                      selected={(playlist.mode || 'solo') === config.mode}
                      {...config}
                    />
                  ))}
                </View>
                <Space h="l" />
                <Text id="collaborators.collaborators" weight="semibold" />
                <Space h="s" />
                {OwnerCard}
                <Space h="xs" />
              </>
            ) : null
          }
          renderItem={({item: user}) => {
            if (user.id === activeUser?.id) {
              return <>{OwnerCard}</>;
            }

            const isAdded = collaboratorsIds[user.id];

            return (
              <AvatarCard
                user={user}
                onPress={toggleCollaborator}
                suffixComponent={isAdded ? icons.remove : icons.add}
              />
            );
          }}
          ListEmptyComponent={
            isFetching ? (
              <Loader />
            ) : searchDebounced ? (
              <Text align="center" id="collaborators.searchEmpty" />
            ) : null
          }
          keyboardDismissMode="on-drag"
          keyboardShouldPersistTaps="always"
        />
      </KeyboardView>

      {collaboratorToRemove && (
        <RemoveCollaboratorModal
          isOpen
          user={collaboratorToRemove}
          playlist={playlist}
          onConfirm={() => {
            removeCollaborator(collaboratorToRemove);
            cancelRemoving();
          }}
          onClose={cancelRemoving}
        />
      )}

      {isShareModalOpen && (
        <ShareModal isOpen playlistId={playlistId} onClose={closeShareModal} />
      )}
    </Screen>
  );
};

export default PlaylistCollaborators;
