import React, {FC, ReactNode, useState} from 'react';

import SelectPlaylistModal from '@/components/SelectPlaylistModal/SelectPlaylistModal';
import {ITrack} from '@/types/common';

interface IProps {
  track: ITrack;
  children: ({openModal}: {openModal: () => void}) => ReactNode;
}

const TrackPlaylistToggler: FC<IProps> = ({track, children}) => {
  const [isPlaylistSelectOpen, setIsPlaylistSelectOpen] = useState(false);
  const togglePlaylistSelect = () => setIsPlaylistSelectOpen(value => !value);

  return (
    <>
      {children({openModal: togglePlaylistSelect})}
      {isPlaylistSelectOpen && (
        <SelectPlaylistModal
          track={track}
          isOpen
          onClose={togglePlaylistSelect}
        />
      )}
    </>
  );
};

export default TrackPlaylistToggler;
