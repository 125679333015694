import {Platform} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const AVATAR_SIZE = 40;
export const ICON_SIZE = 14;
export const GLOBAL_NFT_EVENT_HEIGHT = 55;

export const styles = createThemedStyles(() => ({
  container: {
    minHeight: GLOBAL_NFT_EVENT_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
  contentHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: Platform.select({
      ios: spacing.xxs,
      default: spacing.xxs / 2,
    }),
  },
  trackInfo: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentBody: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentDescription: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
