import {useInfiniteQuery} from '@tanstack/react-query';
import {useMemo} from 'react';

import {fetchReleasesForYou} from '@/api/releasesForYou';
import {fetchTracksByIds} from '@/api/track';
import {MINUTE} from '@/constants/time';
import {QueryKeys} from '@/types/queryKeys';
import {getTracksList} from '@/utils/db';
import {flatChunkedArray} from '@/utils/functions';

import {useDbQuery} from './db';

const PAGE_SIZE = 50;

export const useReleasesForYouQuery = (
  userId?: string,
  enableQuery: boolean = true,
) => {
  const {db, updateDb} = useDbQuery();

  const query = useInfiniteQuery({
    queryKey: [QueryKeys.releasesForYou, userId],
    queryFn: async ({pageParam}) => {
      const releasesForYouTracks = await fetchReleasesForYou(
        userId!,
        pageParam || undefined,
        PAGE_SIZE,
      );
      const releasesForYouTrackIds = releasesForYouTracks.map(({id}) => id);

      // Let's get the full, fresh tracks from the API, and then update the local
      // DB so we can pull them out of the local DB to return them from this hook.
      const tracks = await fetchTracksByIds(releasesForYouTrackIds);
      updateDb({tracks});

      return releasesForYouTrackIds;
    },
    enabled: !!userId && enableQuery,
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      const offset = pages.length * PAGE_SIZE;
      return lastPage.length < PAGE_SIZE ? undefined : offset;
    },
    staleTime: 5 * MINUTE,
  });

  const releasesForYouTracks = useMemo(
    () =>
      getTracksList(db, [
        ...new Set(flatChunkedArray(query.data?.pages || [])),
      ]),
    [db.tracks, query.data?.pages],
  );

  return {
    // return null if we haven't heard back from the server rather than an empty array
    releasesForYouTracks: query.data?.pages ? releasesForYouTracks : null,
    query,
  };
};
