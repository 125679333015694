import React, {FC, useMemo, useState} from 'react';
import {StyleProp, View} from 'react-native';

import {fetchTracksByIds} from '@/api/track';
import CardButton from '@/components/CardButton';
import Text from '@/components/Text';
import {useAppDispatch} from '@/hooks/useRedux';
import {ISpindexerNotification} from '@/modules/Notifications/types';
import {useToast} from '@/modules/Toasts';
import {useDbQuery} from '@/queries/db';
import {playNewQueue} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {PlayContextType} from '@/types/common';

import {styles} from './PlayNotificationsBanner.style';

interface IProps {
  notifications: ISpindexerNotification[];
  style?: StyleProp<any>;
}

const PlayNotificationsBanner: FC<IProps> = ({
  notifications,
  style: customStyle,
}) => {
  const style = useThemedStyles(styles);
  const {showToast} = useToast();
  const dispatch = useAppDispatch();
  const {updateDb} = useDbQuery();

  const [isFetchingTracks, setIsFetchingTracks] = useState(false);

  const trackIds = useMemo(() => {
    const _trackIds: string[] = [];

    notifications?.forEach(notification => {
      if (Array.isArray(notification.metadata?.trackIds)) {
        _trackIds.push(...notification.metadata.trackIds);
      }
      if (
        notification.entityType === 'track-release' &&
        notification.entityType
      ) {
        _trackIds.push(notification.entityId);
      }
    });

    return [...new Set(_trackIds)];
  }, [notifications]);

  if (trackIds.length === 0) {
    return null;
  }

  const play = async () => {
    try {
      setIsFetchingTracks(true);
      const tracks = await fetchTracksByIds(trackIds);
      updateDb({tracks});
      dispatch(
        playNewQueue({
          trackIds: tracks.map(t => t.id),
          context: {
            source: 'Notifications',
            type: PlayContextType.notifications,
            titleId: 'notifications.title',
          },
        }),
      );
    } catch (e) {
      showToast({textId: 'error.tryAgain'});
    } finally {
      setIsFetchingTracks(false);
    }
  };

  return (
    <View style={[style.container, customStyle]}>
      <Text
        id="notifications.play.message"
        values={{count: notifications.length}}
        size="xs"
      />
      <CardButton
        style={style.action}
        onPress={play}
        text={{id: 'notifications.play.action', size: 'xs'}}
        icon={{name: 'play', provider: 'custom', size: 20}}
        isLoading={isFetchingTracks}
        loaderProps={{size: 'xs'}}
      />
    </View>
  );
};

export default React.memo(PlayNotificationsBanner);
