import React from 'react';
import {SharedValue} from 'react-native-reanimated';

import {libraryCategories} from '@/screens/Library/constants';
import {
  ILibraryCategory,
  ILibrarySubcategory,
  LibraryCategory,
  LibrarySubcategory,
} from '@/screens/Library/types';
import {IPlaylist} from '@/types/playlists';
import {noop} from '@/utils/functions';

interface IContextValue {
  selectedCategory: ILibraryCategory;
  selectedSubcategory?: ILibrarySubcategory;
  setCategory: (category?: LibraryCategory) => void;
  setSubcategory: (category?: LibrarySubcategory) => void;

  deletePlaylist: (playlist: IPlaylist) => void;

  scrollPosition?: SharedValue<number>;
  onScroll?: any;
  onScrollOffsetChange?: any;
}

export const LibraryContext = React.createContext<IContextValue>({
  selectedCategory: libraryCategories[0],
  deletePlaylist: noop,
  setCategory: noop,
  setSubcategory: noop,
});
