import {useMemo} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {useToggleTrackInPlaylist} from '@/queries/ownedPlaylists';
import {selectFavsPlaylist, selectFavsTrackIds} from '@/store/playlists';
import {analytics} from '@/utils/analytics';

export const useFavToggle = (trackId?: string) => {
  const favPlaylist = useAppSelector(selectFavsPlaylist);
  const favTrackIds = useAppSelector(selectFavsTrackIds);

  const isFav = useMemo(
    () => !!trackId && !!favTrackIds[trackId],
    [trackId, favPlaylist],
  );

  const {toggleTrackInPlaylistAsyncMutation} = useToggleTrackInPlaylist();

  const toggleFav = () => {
    if (favPlaylist && trackId) {
      if (isFav) {
        analytics.trackUnfaved(trackId);
      } else {
        analytics.trackFaved(trackId);
      }

      toggleTrackInPlaylistAsyncMutation({
        playlistId: favPlaylist.id,
        trackId: trackId,
      });
    }
  };

  return {
    isFav,
    toggleFav,
  };
};
