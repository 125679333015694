import {useNavigation} from '@react-navigation/native';
import React, {useCallback, useEffect, useRef} from 'react';
import {FlatList} from 'react-native';
import Animated, {FadeOut} from 'react-native-reanimated';

import DraggableList, {IDragEndParams} from '@/components/DraggableList';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space/Space';
import {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import spacing from '@/constants/spacing';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import QueueHeader from '@/screens/Queue/components/QueueHeader';
import QueueItem from '@/screens/Queue/components/QueueItem';
import {
  playTrackInQueue,
  removeTrackFromQueue,
  reorderQueueTracks,
  selectCurrentTrackIndex,
  selectCurrentTrackKey,
  selectQueue,
  selectQueueTracks,
} from '@/store/player';
import {useThemedStyles} from '@/theme';
import {IQueueItemWithTrack} from '@/types/common';
import {RootStackNavigationParams} from '@/types/routes';
import {isIOS} from '@/utils/platform';

import {styles} from './Queue.style';

const Separator = () => <Space mt="xs" />;

const getScrollIndex = (currentIndex: number) => Math.max(0, currentIndex - 1);

const Queue = () => {
  const style = useThemedStyles(styles);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<RootStackNavigationParams>();
  const listRef = useRef<FlatList | null>(null);

  const queue = useAppSelector(selectQueue);
  const queueTracks = useAppSelector(selectQueueTracks);
  const currentTrackKey = useAppSelector(selectCurrentTrackKey);

  const currentTrackIndex = useAppSelector(selectCurrentTrackIndex);

  const onPlay = useCallback((trackKey: string) => {
    dispatch(
      playTrackInQueue({
        trackKey,
      }),
    );
  }, []);

  const onDelete = useCallback((trackKey: string) => {
    dispatch(removeTrackFromQueue({trackKey}));
  }, []);

  const onDragEnd = (params: IDragEndParams<IQueueItemWithTrack>) => {
    dispatch(
      reorderQueueTracks(
        params.data.map(item => ({
          trackId: item.trackId,
          key: item.key,
          originalIndex: item.originalIndex,
        })),
      ),
    );
  };

  const scrollToIndex = useCallback(
    (index: number, animated: boolean = false) => {
      listRef.current?.scrollToIndex({
        index: getScrollIndex(index),
        animated,
      });
    },
    [],
  );

  const {scrollPosition, onScrollOffsetChange} = useAnimatedHeader();

  useEffect(() => {
    requestAnimationFrame(() => scrollToIndex(currentTrackIndex, false));
  }, []);

  useEffect(() => {
    if (!currentTrackKey) {
      navigation.goBack();
    }
  }, [currentTrackKey]);

  if (!queue || !currentTrackKey) {
    return null;
  }

  return (
    <Animated.View
      style={style.root}
      // Prevents blank screen flash between queue getting cleared and screen being dissmised
      exiting={isIOS ? FadeOut.delay(200) : undefined}>
      <Screen>
        <QueueHeader
          tracks={queueTracks}
          scrollToIndex={scrollToIndex}
          scrollPosition={scrollPosition}
        />
        <DraggableList
          // @ts-ignore
          ref={listRef}
          itemSize={TRACK_CARD_HEIGHT}
          itemSpacing={spacing.xs}
          draggingEnabled
          onDragEnd={onDragEnd}
          data={queueTracks}
          style={style.listContainer}
          contentContainerStyle={style.listContent}
          keyExtractor={item => item.key}
          initialNumToRender={20}
          renderItem={({item, isDragged, drag}) => (
            <QueueItem
              isCurrentlyPlaying={item.key === currentTrackKey}
              trackKey={item.key}
              track={item.track}
              isDraggable
              isDragged={isDragged}
              drag={drag}
              showArtist
              onPlay={onPlay}
              onDelete={onDelete}
              showAddToQueue={false}
              navigationAction="navigate"
            />
          )}
          ItemSeparatorComponent={Separator}
          showsVerticalScrollIndicator={false}
          onScrollOffsetChange={onScrollOffsetChange}
        />
      </Screen>
    </Animated.View>
  );
};

export default Queue;
