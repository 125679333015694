import {useNavigation} from '@react-navigation/native';
import React from 'react';
import {Vibration, View} from 'react-native';

import {
  createFeedItemForTrack,
  getArtistModalLink,
  getTrackModalLink,
} from '../../utils';
import {FadeInOut} from '@/components/AnimationWrappers';
import IconButton from '@/components/IconButton';
import Link from '@/components/Link';
import SlidingText from '@/components/SlidingText';
import SpinLoader from '@/components/SpinLoader';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TrackReleaseNotificationButton from '@/components/TrackReleaseNotificationButton/TrackReleaseNotificationButton';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useFeedItemMutation} from '@/queries/feed';
import BaseFeedCard from '@/screens/Feed/components/FeedCard/BaseFeedCard';
import {FEED_CARD_WIDTH} from '@/screens/Feed/components/FeedCard/BaseFeedCard.styles';
import FeedCardActions from '@/screens/Feed/components/FeedCard/FeedCardActions';
import {useCardPopover} from '@/screens/Feed/components/FeedCard/useCardPopover';
import {useFeedAudio} from '@/screens/Feed/components/FeedCard/useFeedAudio';
import {useFeedItemWithQueue} from '@/screens/Feed/components/FeedCard/useFeedItemWithQueue';
import OnboardingPopover from '@/screens/Feed/components/OnboardingPopover';
import PostLikePopover from '@/screens/Feed/components/PostLikePopover';
import Progress from '@/screens/Feed/components/Progress';
import TimeToRelease from '@/screens/Feed/components/TimeToRelease';
import {
  selectShouldPlay,
  setShouldPlay as setFeedShouldPlay,
} from '@/store/feed';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {FeedOnboardingItem, IFeedItemWithTrack} from '@/types/feed';
import {ImageSize} from '@/types/media';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {analytics} from '@/utils/analytics';
import {noop, sleep} from '@/utils/functions';
import {getTrackArtists} from '@/utils/tracks';

import {styles} from './TrackFeedCard.styles';

interface IProps {
  feedItem: IFeedItemWithTrack;
  isActive: boolean;
  next: () => void;
  setCarouselEnabled: (enabled: boolean) => void;
  onHide?: (id: string) => void;
  onboardingStep?: FeedOnboardingItem<'track'>;
  closeOnboardingStep?: (item: FeedOnboardingItem<'track'>) => void;
}

const NEXT_DELAY = 800;

const TrackFeedCard: React.FC<IProps> = ({
  feedItem,
  isActive,
  next,
  setCarouselEnabled,
  onHide,
  onboardingStep,
  closeOnboardingStep = noop,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectActiveUserId);

  const isOwnFeed = userId === feedItem.userId;
  const {track} = feedItem;

  const {mutate} = useFeedItemMutation();

  const toggleHide = () => {
    if (onboardingStep) {
      onHide?.(feedItem.id);
      closeOnboardingStep('remove');
    } else {
      mutate(createFeedItemForTrack(userId!, feedItem.track, 'hide'));
      analytics.feedItemReacted(feedItem.id, 'hide', feedItem.entityType);
    }
  };

  const handleLike = async () => {
    if (onboardingStep) {
      closeOnboardingStep('add');
    } else {
      mutate(createFeedItemForTrack(userId!, feedItem.track, 'like'));
      analytics.feedItemReacted(feedItem.id, 'like', feedItem.entityType);
    }

    toggleTrackInQueue();

    if (!isInQueue) {
      Vibration.vibrate();
      await sleep(NEXT_DELAY);
      next();
    }
  };

  const {
    cardRef,
    cardMeasurement,
    isCardPopoverOpen,
    openPopover,
    closePopover,
  } = useCardPopover();

  const releaseTime = track?.mintStart ? new Date(track.mintStart) : undefined;

  const {isInQueue, toggleTrackInQueue, isCurrentlyPlaying} =
    useFeedItemWithQueue({
      feedItem,
      trackId: track.id,
    });

  const feedShouldPlay = useAppSelector(selectShouldPlay);

  const {isPlaying, isBuffering, progress} = useFeedAudio(
    track,
    isActive,
    () => {
      if (!onboardingStep) {
        next();
      }
    },
  );

  const collectAction = (
    <TransparentButton
      rippleDisabled
      iconSpacing={spacing.xxs / 2}
      style={style.collectButton}
      onPress={() => {
        navigation.navigate(Routes.Collect, {slug: track!.slug});
        analytics.feedCollectClicked(track!.id);
      }}
      text={{
        id: 'buyButton.collect',
        weight: 'semibold',
      }}
      icon={{
        name: 'nftMint',
        provider: 'custom',
      }}
    />
  );

  const notifyAction =
    track && releaseTime ? (
      <TrackReleaseNotificationButton
        track={track}
        analyticsInfo={{screen: Routes.Channels, id: track.id}}
      />
    ) : null;

  const hideAction = (
    <IconButton
      onPress={toggleHide}
      disabled={onboardingStep === 'play' || onboardingStep === 'add'}
      icon={{
        provider: 'custom',
        name: 'visibilityOff',
      }}
    />
  );

  const getLeftAction = () => {
    if (onboardingStep) {
      return hideAction;
    }

    if (isOwnFeed && !isInQueue) {
      return hideAction;
    }

    if (releaseTime && releaseTime > new Date()) {
      return notifyAction;
    }

    return collectAction;
  };

  const playAction = (
    <IconButton
      onPress={() => {
        analytics.feedCardPlayed(feedItem.id, !feedShouldPlay);
        dispatch(setFeedShouldPlay(!feedShouldPlay));
        closeOnboardingStep('play');
      }}
      icon={{
        provider: 'custom',
        name: isPlaying ? 'pause' : 'play',
      }}
      disabled={!track}
    />
  );

  const likeAction = (
    <IconButton
      onPress={handleLike}
      onLongPress={openPopover}
      icon={{
        name: isInQueue ? 'check' : 'add',
        provider: 'custom',
      }}
      disabled={
        isCurrentlyPlaying ||
        onboardingStep === 'play' ||
        onboardingStep === 'remove'
      }
    />
  );

  return (
    <BaseFeedCard ref={cardRef}>
      {progress && (
        <Progress
          progress={progress}
          onDragStart={() => setCarouselEnabled(false)}
          onDragEnd={() => setCarouselEnabled(true)}
        />
      )}
      <View style={style.imageContainer}>
        <View style={style.image}>
          <StaticImage
            resizeWidth={ImageSize.feedCard}
            source={{uri: track.lossyArtworkUrl}}
            style={{width: '100%', height: '100%'}}
            resizeMode="cover"
            fallback={<FadeInOut enabled style={style.imagePlaceholder} />}
          />
        </View>
        {isBuffering && (
          <View style={style.bufferingIndicator}>
            <SpinLoader />
          </View>
        )}
      </View>
      <TimeToRelease track={track} />

      <View style={style.infoContainer}>
        <Link style={{width: '100%'}} {...getTrackModalLink(track.slug)}>
          {({hover}) => (
            <SlidingText
              size="m"
              weight="semibold"
              style={style.title}
              underline={hover}
              numberOfLines={1}
              selectable={false}>
              {track.title}
            </SlidingText>
          )}
        </Link>
        <View style={style.subtitle}>
          {getTrackArtists(track).map((a, _index) => (
            <React.Fragment key={_index}>
              {_index > 0 && (
                <View style={style.artistJoiner}>
                  <Text size="m" numberOfLines={1}>
                    &
                  </Text>
                </View>
              )}
              <Link {...getArtistModalLink(a.slug)} style={{maxWidth: '100%'}}>
                {({hover}) => (
                  <Text
                    size="m"
                    underline={hover}
                    numberOfLines={2}
                    selectable={false}>
                    {a.name}
                  </Text>
                )}
              </Link>
            </React.Fragment>
          ))}
        </View>
        <View style={style.actionsWrapper}>
          {isActive && onboardingStep === 'remove' && (
            <OnboardingPopover
              position="top"
              align="left"
              arrowOffset={FEED_CARD_WIDTH / 3 / 2 - spacing.xs * 2}
              onClose={() => {
                next();
                closeOnboardingStep('remove');
              }}>
              <OnboardingPopover.Discard />
            </OnboardingPopover>
          )}
          {isActive && onboardingStep === 'play' && (
            <OnboardingPopover
              position="top"
              align="center"
              onClose={() => closeOnboardingStep('play')}>
              <OnboardingPopover.Play />
            </OnboardingPopover>
          )}
          {isActive && onboardingStep === 'add' && (
            <OnboardingPopover
              position="top"
              align="right"
              arrowOffset={FEED_CARD_WIDTH / 3 / 2 - spacing.xs * 2}
              onClose={() => {
                next();
                closeOnboardingStep('add');
              }}>
              <OnboardingPopover.AddToQueue />
            </OnboardingPopover>
          )}

          <FeedCardActions
            actions={[getLeftAction(), playAction, likeAction]}
          />
        </View>
      </View>

      {isCardPopoverOpen && track && (
        <PostLikePopover
          cardMeasurement={cardMeasurement}
          track={track}
          close={closePopover}
          bottomOffset={spacing.xxl}
        />
      )}
    </BaseFeedCard>
  );
};

export default React.memo(TrackFeedCard);
