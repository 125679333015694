import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const COLLECTOR_CARD_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  container: {
    alignItems: 'center',
    gap: spacing.l,
  },
  collectorCard: {
    height: COLLECTOR_CARD_HEIGHT,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    borderRadius: COLLECTOR_CARD_HEIGHT / 2,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
    paddingHorizontal: spacing.xs,
    width: '100%',
    maxWidth: 260,
  },
  textsContainer: {
    gap: spacing.s,
    alignItems: 'center',
    minHeight: 58,
  },
  links: {
    flexDirection: 'row',
    gap: spacing.m,
    alignItems: 'center',
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
  processingButton: {
    alignSelf: 'stretch',
  },
}));
