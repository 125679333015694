import {useMutation} from '@tanstack/react-query';
import {useStore} from 'react-redux';

import * as playlistApi from '@/api/playlist';
import {useAppDispatch} from '@/hooks/useRedux';
import {
  selectPlaylistById,
  updatePlaylist,
  updatePlaylistUpdatedClientTime,
  updatePlaylistUpdatedServerTime,
} from '@/store/playlists';
import {selectActiveUserId, selectSignerByUserId} from '@/store/user';
import {IPlaylist, IUpdateFollowedPlaylistData} from '@/types/playlists';
import {
  getPlaylistFromError,
  getUpdatedSuggestions,
  toggleTrackAndHandleSuggestion,
} from '@/utils/playlists';

export const useSyncUnsavedFollowedPlaylist = () => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  return useMutation({
    mutationFn: (parentPlaylist: IPlaylist) =>
      playlistApi.updatePlaylist(
        parentPlaylist.followedPlaylist!.id,
        parentPlaylist.followedPlaylist!,
        selectSignerByUserId(getState(), parentPlaylist.collector)!,
      ),
    onSuccess: (data, parentPlaylist) => {
      dispatch(
        updatePlaylistUpdatedServerTime({
          id: parentPlaylist.followedPlaylist!.id,
        }),
      );
    },
    onError: (error: any, parentPlaylist) => {
      const playlist = getPlaylistFromError(error);

      if (playlist && parentPlaylist.followedPlaylist) {
        dispatch(
          updatePlaylist({
            id: parentPlaylist.followedPlaylist.id,
            updatedPlaylist: playlist,
          }),
        );
        dispatch(
          updatePlaylistUpdatedServerTime({
            id: parentPlaylist.followedPlaylist.id,
          }),
        );
      }
    },
  });
};

export const useUpdateFollowedPlaylist = () => {
  const {getState} = useStore();
  const dispatch = useAppDispatch();

  const mutation = useMutation({
    onMutate: async ({id, updatedPlaylist}: IUpdateFollowedPlaylistData) => {
      dispatch(
        updatePlaylist({
          id,
          updatedPlaylist,
        }),
      );
      dispatch(updatePlaylistUpdatedClientTime({id}));
    },
    mutationFn: ({parentPlaylistId}: IUpdateFollowedPlaylistData) => {
      const parentPlaylist = selectPlaylistById(getState(), parentPlaylistId);
      const playlist = parentPlaylist.followedPlaylist!;

      return playlistApi.updatePlaylist(
        playlist.id,
        playlist,
        selectSignerByUserId(getState(), parentPlaylist.collector)!,
      );
    },
    onSuccess: (data, {id}) => {
      dispatch(updatePlaylistUpdatedServerTime({id}));
    },
    onError: (error: any, {id}) => {
      const playlist = getPlaylistFromError(error);

      if (playlist) {
        dispatch(
          updatePlaylist({
            id,
            updatedPlaylist: playlist,
          }),
        );
        dispatch(updatePlaylistUpdatedServerTime({id}));
      }
    },
  });

  return {
    ...mutation,
    updateFollowedPlaylistMutation: mutation.mutate,
  };
};
export const useToggleTrackInFollowedPlaylist = () => {
  const {getState} = useStore();
  const {updateFollowedPlaylistMutation, ...mutation} =
    useUpdateFollowedPlaylist();

  return {
    toggleTrackInFollowedPlaylistMutation: ({
      playlistId,
      parentPlaylistId,
      trackId,
    }: {
      playlistId: string;
      parentPlaylistId: string;
      trackId: string;
    }) => {
      const parentPlaylist = selectPlaylistById(getState(), parentPlaylistId);
      const playlist = parentPlaylist.followedPlaylist!;
      const {trackIds, contributions, suggestions} =
        toggleTrackAndHandleSuggestion(
          playlist,
          trackId,
          parentPlaylist.collector,
        );

      return updateFollowedPlaylistMutation({
        id: playlistId,
        parentPlaylistId,
        updatedPlaylist: {
          trackIds,
          contributions,
          suggestions,
        },
      });
    },
    ...mutation,
  };
};
export const useUpdateFollowedPlaylistTracks = () => {
  const dispatch = useAppDispatch();
  const {updateFollowedPlaylistMutation, ...mutation} =
    useUpdateFollowedPlaylist();

  return {
    updateFollowedPlaylistTracksMutation: ({
      playlistId,
      parentPlaylistId,
      trackIds,
    }: {
      playlistId: string;
      parentPlaylistId: string;
      trackIds: string[];
    }) => {
      dispatch(
        updatePlaylist({
          id: playlistId,
          updatedPlaylist: {trackIds},
        }),
      );
      return updateFollowedPlaylistMutation({
        id: playlistId,
        parentPlaylistId,
        updatedPlaylist: {
          trackIds,
        },
      });
    },
    ...mutation,
  };
};
export const useSuggestTrackInPlaylist = () => {
  const {getState} = useStore();
  const {updateFollowedPlaylistMutation, ...mutation} =
    useUpdateFollowedPlaylist();

  return {
    suggestTrackInPlaylistMutation: ({
      playlistId,
      parentPlaylistId,
      trackId,
    }: {
      playlistId: string;
      parentPlaylistId: string;
      trackId: string;
    }) => {
      const parentPlaylist = selectPlaylistById(getState(), parentPlaylistId);
      const playlist = parentPlaylist.followedPlaylist!;
      const suggestions = getUpdatedSuggestions(
        playlist,
        trackId,
        selectActiveUserId(getState()),
      );

      return updateFollowedPlaylistMutation({
        id: playlistId,
        parentPlaylistId,
        updatedPlaylist: {
          suggestions,
        },
      });
    },
    ...mutation,
  };
};
