import {useMemo} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {useTracksByIdsQuery} from '@/queries/tracks';
import {selectFavsPlaylist, selectUserPlaylists} from '@/store/playlists';
import {selectActiveUserId} from '@/store/user';
import {IPlaylist, PlaylistMode, PlaylistType} from '@/types/playlists';
import {sortPlaylistsByUpdateTime} from '@/utils/playlists';

export const useUserPlaylists = (
  type?: PlaylistType,
  sort = sortPlaylistsByUpdateTime,
) => {
  const playlists = useAppSelector(selectUserPlaylists);

  return useMemo(() => {
    const filteredPlaylists = type
      ? playlists.filter(playlist => type === playlist.type)
      : playlists;

    if (sort) {
      return filteredPlaylists.sort(sort);
    }

    return filteredPlaylists;
  }, [playlists, type, sort]);
};

export const useFavsPlaylist = () => {
  return useAppSelector(selectFavsPlaylist);
};

export const useFavsTracks = () => {
  const favoritePlaylist = useFavsPlaylist();
  const {
    tracks: favoriteTracks,
    query: {isFetching: areTracksFetching},
  } = useTracksByIdsQuery(favoritePlaylist?.trackIds);

  return {
    favoriteTracks,
    areTracksFetching,
  };
};

export const useCollaborativePlaylists = () => {
  const userId = useAppSelector(selectActiveUserId);
  const playlists = useAppSelector(selectUserPlaylists);

  return useMemo(
    () =>
      playlists
        .filter(
          playlist =>
            playlist.followedPlaylist &&
            playlist.followedPlaylist.collaborators?.some(
              c => c.userId === userId,
            ),
        )
        .sort(sortPlaylistsByUpdateTime),
    [playlists, userId],
  );
};

export const useGroupedCollaborativePlaylists = () => {
  const parentPlaylists = useCollaborativePlaylists();

  return useMemo(() => {
    const grouped: Record<PlaylistMode, IPlaylist[]> = {
      solo: [],
      'free-for-all': [],
    };

    parentPlaylists.forEach(parentPlaylist => {
      const mode = parentPlaylist.followedPlaylist!.mode || 'solo';
      grouped[mode].push(parentPlaylist);
    });

    return grouped;
  }, [parentPlaylists]);
};
