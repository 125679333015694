import mixpanel from 'mixpanel-browser';

import {config} from '@/constants/config';
import {isEmbedded, isLocalDev} from '@/utils/platform';

mixpanel.init(config.MIXPANEL_KEY, {
  // We use a rewrite in our vercel.json to reverse-proxy mixpanel via /mixpanelProxy
  // If you want the requests to Mixpanel to work locally (for example for debugging Mixpanel)
  // you will need to either remove this or run `npx vercel dev --listen 3031` to have vercel's local dev server
  // run the rewrite locally
  api_host: '/mixpanelProxy',
  ignore_dnt: true,
});

if (isLocalDev || isEmbedded) {
  mixpanel.disable();
}

export const setUserProperty = (key: string, value: any) =>
  mixpanel.people.set(key, value);

export default mixpanel;
