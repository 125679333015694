import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    paddingHorizontal: spacing.xs,
    paddingVertical: spacing.xs,
  },
  divider: {
    width: '100%',
    height: 1,
    opacity: 0.1,
    marginVertical: spacing.xs * 1.5,
  },
  bottomSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: spacing.s,
  },
  publicRow: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xxs,
  },
}));
