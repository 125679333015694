import React, {FC, useCallback} from 'react';

import {ITrackCardProps} from '@/components/TrackCard';
import TrackCard from '@/components/TrackCard/TrackCard';

interface IQueueItemProps extends Omit<ITrackCardProps, 'onPlay' | 'onDelete'> {
  trackKey: string;
  isCurrentlyPlaying: boolean;
  onPlay: (key: string) => void;
  onDelete: (key: string) => void;
}

const QueueItem: FC<IQueueItemProps> = ({
  trackKey,
  onPlay: _onPlay,
  onDelete: _onDelete,
  isCurrentlyPlaying,
  ...trackCardProps
}) => {
  const onPlay = useCallback(() => {
    _onPlay(trackKey);
  }, [trackKey, _onPlay]);

  const onDelete = useCallback(() => {
    _onDelete(trackKey);
  }, [trackKey, _onDelete]);

  return (
    <TrackCard
      isActive={isCurrentlyPlaying}
      onPlay={onPlay}
      onDelete={isCurrentlyPlaying ? undefined : onDelete}
      {...trackCardProps}
    />
  );
};

export default QueueItem;
