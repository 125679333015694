import React from 'react';

import CardButton from '@/components/CardButton';
import TransparentButton from '@/components/TransparentButton';
import WarningBox from '@/components/WarningBox';
import {ITransactionValidationError} from '@/modules/Transactions/types';
import {useCheckWalletConnection} from '@/modules/Wallets';
import {IAddress} from '@/types/common';
interface IWalletValidationInput {
  wallet: IAddress | undefined;
  onChangeWallet?: () => void;
}

export const useIsWalletConnectedValidation = ({
  wallet,
  onChangeWallet,
}: IWalletValidationInput): ITransactionValidationError | undefined => {
  const walletConnectionError = useCheckWalletConnection(wallet);

  if (walletConnectionError) {
    const {message, action} = walletConnectionError;

    return {
      message: (
        <WarningBox
          message={{
            id: message.id,
            values: {
              address: message.walletName,
            },
          }}
        />
      ),
      action: (
        <>
          {action && (
            <CardButton
              text={action.text}
              onPress={action.onPress}
              isLoading={action.isLoading}
              loaderProps={
                action.loaderTextId ? {textId: action.loaderTextId} : undefined
              }
            />
          )}

          {onChangeWallet && (
            <TransparentButton
              style={{alignSelf: 'center'}}
              onPress={onChangeWallet}
              text={{id: 'transactions.notConnected.change'}}
            />
          )}
        </>
      ),
    };
  }

  return undefined;
};
