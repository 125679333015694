import {Link} from '@react-navigation/native';
import React, {FC} from 'react';

import Text from '@/components/Text/Text';
import {useUserLink} from '@/hooks/useUserLink';
import {IBaseUser} from '@/types/user';
import {getUserDisplayName} from '@/utils/user';

interface IProps {
  collector: IBaseUser;
}

const CollectorLink: FC<IProps> = ({collector}) => {
  const {cacheUser, getUserLinkConfig} = useUserLink();

  return (
    <Link
      onPress={() => cacheUser(collector)}
      // @ts-ignore
      onContextMenu={cacheUser}
      {...getUserLinkConfig(collector)}>
      <Text size="xs" weight="semibold">
        {getUserDisplayName(collector)}
      </Text>
    </Link>
  );
};

export default CollectorLink;
