import {createThemedStyles} from '@/theme';

export const CANCEL_BUTTON_WIDTH = 60;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    header: {
      paddingTop: safeAreaInsets.top,
      backgroundColor: theme.colors.background,
      borderBottomWidth: 2,
      borderColor: theme.colors.borderColor,
    },
    headerContent: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cancelButtonContainer: {
      width: CANCEL_BUTTON_WIDTH,
      overflow: 'hidden',
    },
    cancelButton: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: CANCEL_BUTTON_WIDTH,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
