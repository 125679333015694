import React, {FC} from 'react';
import {View} from 'react-native';

import Icon from '@/components/Icon';
import Modal, {IModalProps} from '@/components/Modal';
import Share from '@/components/Share';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {useThemedStyles} from '@/theme';
import {getPlaylistUrl} from '@/utils/share';

import {styles} from './ShareModal.style';

interface IProps extends IModalProps {
  playlistId: string;
}

const ShareModal: FC<IProps> = ({playlistId, ...modalProps}) => {
  const style = useThemedStyles(styles);

  return (
    <Modal titleId="collaborators.share.title" {...modalProps}>
      <View style={style.content}>
        <View style={style.centeredColumn}>
          <Icon name="sharePlaylist" size={48} provider="custom" />
          <Text weight="semibold" id="collaborators.share.header" />
        </View>

        <View>
          <Text size="xs" id="collaborators.share.description1" />
          <Space mt="s" />
          <Text size="xxs" id="collaborators.share.description2" />
        </View>

        <Share url={getPlaylistUrl(playlistId)}>
          {({onPress, icon}) => (
            <TouchableCard
              onPress={async () => {
                await onPress();
                modalProps.onClose();
              }}
              containerStyle={style.actionWrapper}
              style={style.action}>
              {({actionColor}) => (
                <>
                  <Icon {...icon} color={actionColor()} />
                  <Text id="share" weight="semibold" color={actionColor()} />
                </>
              )}
            </TouchableCard>
          )}
        </Share>
        <TouchableCard
          onPress={modalProps.onClose}
          containerStyle={style.actionWrapper}
          style={style.action}>
          {({actionColor}) => (
            <Text
              id="collaborators.share.skip"
              weight="semibold"
              color={actionColor()}
            />
          )}
        </TouchableCard>
      </View>
    </Modal>
  );
};

export default ShareModal;
