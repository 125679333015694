import {EffectCallback, useEffect} from 'react';

import {useIsInitialRender} from './useIsInitialRender';

/**
 * Like useEffect, but does not invoke `effect` on initial render.
 *
 * @param effect
 * @param dependencies
 */
export const useUpdateEffect = (
  effect: EffectCallback,
  dependencies: readonly unknown[],
) => {
  const isInitialRender = useIsInitialRender();

  useEffect(() => {
    if (isInitialRender) {
      return;
    }

    return effect();
  }, dependencies);
};
