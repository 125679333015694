import React, {FC, ReactNode} from 'react';
import {StyleProp, TouchableWithoutFeedback, View} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';

import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles} from './ScrollContainer.style';

interface IProps {
  children: ReactNode;
  contentStyle?: StyleProp<any>;
}

const ScrollContainer: FC<IProps> = ({children, contentStyle}) => {
  const style = useThemedStyles(styles);

  return (
    <ScrollView
      showsVerticalScrollIndicator={isWeb}
      bounces={false}
      style={style.scrollContainer}
      contentContainerStyle={style.scrollContent}>
      <TouchableWithoutFeedback>
        <View style={[style.scrollTouchable, contentStyle]}>{children}</View>
      </TouchableWithoutFeedback>
    </ScrollView>
  );
};

export default ScrollContainer;
