import {StyleSheet} from 'react-native';

import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const ICON_SIZE = 24;

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    tabBar: {
      width: '100%',
      backgroundColor: theme.colors.primary,
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    tabItemWrapper: {
      flex: 1,
    },
    tabItem: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: spacing.xs,
      paddingBottom: Math.max(spacing.xs, safeAreaInsets.bottom),
    },
    pressIndicator: {
      ...StyleSheet.absoluteFillObject,
      borderRadius: ICON_SIZE / 2,
      height: ICON_SIZE,
      width: ICON_SIZE,
      backgroundColor: theme.colors.background,
      opacity: 0.2,
    },
  }),
);
