import React, {FC} from 'react';
import {Keyboard, TouchableOpacity, View} from 'react-native';
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import SearchInput from '@/components/SearchInput/SearchInput';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';
import {isWeb} from '@/utils/platform';

import {styles, CANCEL_BUTTON_WIDTH} from './SearchHeader.style';

interface IProps {
  search: string;
  setSearch: (search: string) => void;
  clearSearch: () => void;
}

const SearchHeader: FC<IProps> = ({search, setSearch, clearSearch}) => {
  const style = useThemedStyles(styles);
  const {isMobile, isDesktop} = useResponsive();

  const isFocused = useSharedValue(false);
  const onFocus = () => (isFocused.value = true);
  const onBlur = () => (isFocused.value = false);

  const cancelButtonStyle = useAnimatedStyle(
    () => ({
      width: withTiming(isFocused.value ? CANCEL_BUTTON_WIDTH : 0, {
        duration: 150,
      }),
    }),
    [isFocused],
  );

  return (
    <View style={style.header}>
      <Space
        ph="xs"
        pt={isWeb ? 'xs' : 'xxs'}
        pb="xs"
        style={style.headerContent}>
        <Space flex>
          <SearchInput
            value={search}
            setValue={setSearch}
            onClear={clearSearch}
            autoFocus={isDesktop}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholderId="search.placeholder"
            focusOnTabPress
          />
        </Space>
        {isMobile && (
          <Animated.View
            style={[style.cancelButtonContainer, cancelButtonStyle]}>
            <TouchableOpacity
              onPress={() => {
                clearSearch();
                Keyboard.dismiss();
              }}
              style={style.cancelButton}>
              <Space pl="xs" pr="xxs">
                <Text id="cancel" weight="semibold" size="xs" />
              </Space>
            </TouchableOpacity>
          </Animated.View>
        )}
      </Space>
    </View>
  );
};

export default SearchHeader;
