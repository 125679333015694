import {useNavigation} from '@react-navigation/native';
import React, {FC, useMemo, useState} from 'react';
import {FlatList, TouchableOpacity, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Card from '@/components/Card/Card';
import CollapsingText from '@/components/CollapsingText';
import DeletePlaylistModal from '@/components/DeletePlaylistModal/DeletePlaylistModal';
import Icon from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import ImageWithPreview from '@/components/ImageWithPreview';
import PlaylistCreator from '@/components/PlaylistCreator';
import PlaylistFormModal from '@/components/PlaylistFormModal';
import Share from '@/components/Share';
import Space from '@/components/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import TransparentButton from '@/components/TransparentButton';
import {usePlaylistContributors} from '@/hooks/usePlaylistContributors';
import {useAppSelector} from '@/hooks/useRedux';
import {useToast} from '@/modules/Toasts';
import {useDbQuery} from '@/queries/db';
import {
  useCreatePlaylist,
  useDeletePlaylist,
  useForkPlaylist,
} from '@/queries/ownedPlaylists';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ImageSize} from '@/types/media';
import {IPlaylist, PlaylistType} from '@/types/playlists';
import {MainStackNavigationParams, Routes} from '@/types/routes';
import {IBaseUser, IUser} from '@/types/user';
import {getImageUrl} from '@/utils/ipfs';
import {getCuratorsList, isLocalPlaylistId} from '@/utils/playlists';
import {getPlaylistUrl} from '@/utils/share';

import {styles} from './PlaylistInfo.style';

interface IProps {
  playlist: IPlaylist;
  collaborators: IBaseUser[];
  isOwnPlaylist: boolean;
  isCollaborator: boolean;
  isFollowed: boolean;
  unfollowId?: string;
  onPlay: () => void;
  creator?: IUser | null;
}

const PlaylistInfo: FC<IProps> = ({
  playlist,
  collaborators,
  isOwnPlaylist,
  isCollaborator,
  isFollowed,
  unfollowId,
  creator,
  onPlay,
}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<MainStackNavigationParams>();
  const {db} = useDbQuery();
  const {showToast} = useToast();

  const activeUserId = useAppSelector(selectActiveUserId);
  const isLocalPlaylist = isLocalPlaylistId(playlist.id);

  const contributors = usePlaylistContributors(playlist, collaborators);
  const curatorsList = useMemo(
    () =>
      getCuratorsList({
        contributors,
        isOwner: isOwnPlaylist,
        activeUserId,
      }),
    [contributors, isOwnPlaylist, activeUserId],
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isForkModalOpen, setIsForkModalOpen] = useState(false);
  const toggleForkModal = () => setIsForkModalOpen(v => !v);

  const {deletePlaylistMutation} = useDeletePlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();

  const {fork} = useForkPlaylist();

  const toggleFollow = () => {
    if (unfollowId) {
      deletePlaylistMutation(unfollowId);
    } else {
      createPlaylistMutation({
        title: playlist.title!,
        type: PlaylistType.followedPlaylist,
        followedPlaylistId: playlist.id,
        followedPlaylist: {
          ...playlist,
          serverUpdatedAtTime: playlist.updatedAtTime,
        },
      });
    }
  };

  const onDeleteConfirm = () => {
    if (playlist) {
      deletePlaylistMutation(playlist.id);
      setIsDeleteModalOpen(false);
      if (navigation.canGoBack()) {
        navigation.goBack();
      }
    }
  };

  return (
    <>
      <View style={style.centeredColumn}>
        {playlist.artworkIPFSHash ? (
          <Space mb="l">
            <ImageWithPreview
              source={{uri: getImageUrl(playlist.artworkIPFSHash)}}
              style={style.artwork}
              resizeWidth={ImageSize.playlistArtwork * 2}
              hasBorderRadius
            />
          </Space>
        ) : playlist.trackIds?.length ? (
          <FlatList
            data={playlist.trackIds}
            horizontal
            showsHorizontalScrollIndicator={false}
            bounces={false}
            keyExtractor={trackIds => trackIds}
            style={style.trackPreviews}
            contentContainerStyle={style.trackPreviewsContent}
            renderItem={({item: trackId, index}) => {
              const track = db.tracks[trackId];

              return (
                <View style={style.trackPreviewWrapper}>
                  <FadeInOut
                    maxOpacity={0.8}
                    delay={index * 150}
                    style={style.trackPreviewPlaceholder}
                  />
                  {track && (
                    <StaticImage
                      source={{uri: track.lossyArtworkUrl}}
                      resizeWidth={ImageSize.thumbnail}
                      style={style.trackPreview}
                    />
                  )}
                </View>
              );
            }}
          />
        ) : null}

        {playlist.album && (
          <Card inverted pv="xxs" mb="xs" style={{width: 'auto'}}>
            <Text color="invertedTextColor" size="xs" id="album" />
          </Card>
        )}
        <Text weight="semibold" size="xl" align="center">
          {playlist.title}
        </Text>

        <Space mt="xs" />

        {contributors.length > 0 ? (
          <TouchableOpacity
            style={style.curatorsContainer}
            onPress={() => {
              navigation.navigate(Routes.PlaylistCurators, {
                id: playlist.id,
              });
            }}
            activeOpacity={0.8}>
            <Icon name="curators" provider="custom" size={20} />
            <Text
              style={style.curators}
              id="playlist.createdByMessage"
              numberOfLines={1}
              values={{
                link: (
                  <Text id="playlist.createdBy" underline numberOfLines={1} />
                ),
                users: curatorsList,
              }}
            />
          </TouchableOpacity>
        ) : (
          <View style={style.curatorsContainer}>
            <Text id="playlist.createdBy" numberOfLines={1} />
            {isOwnPlaylist ? (
              <Text id="playlist.you" weight="semibold" />
            ) : (
              <PlaylistCreator playlist={playlist} user={creator} />
            )}
          </View>
        )}

        {isCollaborator && !isFollowed && (
          <Space mt="l" style={style.invitationContainer}>
            <Text id="playlist.invitation" size="xs" align="center" />
            <Space h="xs" />
            <TransparentButton
              style={style.invitationButton}
              onPress={() => {
                toggleFollow();
                showToast({
                  textId:
                    playlist?.mode === 'free-for-all'
                      ? 'playlist.invitation.toast.free'
                      : 'playlist.invitation.toast.solo',
                });
              }}
              text={{id: 'follow', size: 'xs'}}
              icon={{
                provider: 'custom',
                name: 'heart',
                size: 16,
              }}
            />
          </Space>
        )}

        <Space mv="l">
          <Space style={style.actions}>
            {isOwnPlaylist && (
              <IconButton
                onPress={() => setIsDeleteModalOpen(true)}
                icon={{name: 'remove', provider: 'custom'}}
              />
            )}
            {!isOwnPlaylist && (
              <IconButton
                onPress={toggleFollow}
                icon={{
                  name: 'heart',
                  provider: 'custom',
                  fill: isFollowed,
                  color: isFollowed ? 'favoritesColor' : 'textColor',
                }}
              />
            )}
            {!isLocalPlaylist && (
              <IconButton
                onPress={toggleForkModal}
                icon={{name: 'fork', provider: 'custom'}}
              />
            )}
            {!isLocalPlaylist && (
              <Share title={playlist.title} url={getPlaylistUrl(playlist.id)}>
                {({icon, onPress}) => (
                  <IconButton onPress={onPress} icon={icon} />
                )}
              </Share>
            )}
            <IconButton
              onPress={onPlay}
              icon={{name: 'play', provider: 'custom'}}
            />
          </Space>
        </Space>
      </View>

      {(isOwnPlaylist || !!playlist.description) && (
        <Space mb="l">
          <Text weight="semibold" id="playlist.about" size="m" />
          <Space mt="xs" />
          {playlist.description ? (
            <CollapsingText size="xs">{playlist.description}</CollapsingText>
          ) : (
            <Text size="xs" id="playlist.descriptionEmpty" align="center" />
          )}
        </Space>
      )}

      {isDeleteModalOpen && (
        <DeletePlaylistModal
          isOpen
          playlist={playlist}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={onDeleteConfirm}
        />
      )}
      {isForkModalOpen && (
        <PlaylistFormModal
          isOpen
          initialData={{title: playlist.title}}
          modalTitleId="forkPlaylist.title"
          descriptionId="forkPlaylist.description"
          submitTextId="forkPlaylist.fork"
          onClose={toggleForkModal}
          onSubmit={({title, description, collector}) => {
            fork({
              basePlaylist: playlist,
              title,
              description,
              collector,
            });
            toggleForkModal();
          }}
        />
      )}
    </>
  );
};

export default PlaylistInfo;
