import {
  initialize,
  ErrorBoundary,
  captureException,
  captureMessage,
  setUser,
} from './sentry.web';

export const Sentry = {
  initialize,
  ErrorBoundary,
  captureException,
  captureMessage,
  setUser,
};
