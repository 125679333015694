import React from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import Screen from '@/components/Screen/Screen';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useAppSelector} from '@/hooks/useRedux';
import {selectActiveUserId} from '@/store/user';

import ReleasesForYou from './components/ReleasesForYou';

const ReleasesForYouScreen = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();

  const myUserId = useAppSelector(selectActiveUserId);

  return (
    <Screen>
      <Header
        titleId="releasesForYou.title"
        showBack
        scrollPosition={scrollPosition}
        mockTitle={true}
      />

      <AsyncContent isLoading={!myUserId}>
        <ReleasesForYou userId={myUserId!} onScroll={onScroll} />
      </AsyncContent>
    </Screen>
  );
};

export default ReleasesForYouScreen;
