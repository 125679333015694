import React, {FC} from 'react';
import {ScrollView, View} from 'react-native';

import Button from '@/components/Button';
import Icon from '@/components/Icon/Icon';
import Input from '@/components/Input/Input';
import Modal, {IModalProps} from '@/components/Modal/Modal';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import TouchableCard from '@/components/Touchable/TouchableCard';
import {usePlaylistForm} from '@/hooks/usePlaylistForm';
import {
  useFavsPlaylist,
  useGroupedCollaborativePlaylists,
  useUserPlaylists,
} from '@/hooks/useUserPlaylists';
import {
  useSuggestTrackInPlaylist,
  useToggleTrackInFollowedPlaylist,
} from '@/queries/followedPlaylists';
import {
  useCreatePlaylist,
  useToggleTrackInPlaylist,
} from '@/queries/ownedPlaylists';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {IPlaylist, PlaylistType} from '@/types/playlists';

import {styles} from './SelectPlaylistModal.style';

interface IProps {
  track: ITrack;
}

const SelectPlaylistModal: FC<IProps & IModalProps> = ({
  isOpen,
  onClose,
  track,
}) => {
  const style = useThemedStyles(styles);

  const {toggleTrackInPlaylistMutation} = useToggleTrackInPlaylist();
  const {toggleTrackInFollowedPlaylistMutation} =
    useToggleTrackInFollowedPlaylist();
  const {suggestTrackInPlaylistMutation} = useSuggestTrackInPlaylist();
  const {createPlaylistMutation} = useCreatePlaylist();

  const favsPlaylist = useFavsPlaylist();
  const customPlaylists = useUserPlaylists(PlaylistType.custom);
  const ownPlaylists = [favsPlaylist, ...customPlaylists];
  const collaborativePlaylists = useGroupedCollaborativePlaylists();

  const {
    title: newPlaylistTitle,
    setTitle: setNewPlaylistTitle,
    formattedTitle: formattedPlaylistTitle,
    isFormValid,
    titleMaxLength,
  } = usePlaylistForm();
  const isAddDisabled = !isFormValid;

  const toggleTrack = (playlist: IPlaylist) => {
    toggleTrackInPlaylistMutation({
      playlistId: playlist.id,
      trackId: track.id,
    });
    onClose();
  };

  const toggleCollaborativePlaylistTrack = (parentPlaylist: IPlaylist) => {
    toggleTrackInFollowedPlaylistMutation({
      parentPlaylistId: parentPlaylist.id,
      playlistId: parentPlaylist.followedPlaylist!.id,
      trackId: track.id,
    });
    onClose();
  };

  const suggestTrack = (parentPlaylist: IPlaylist) => {
    suggestTrackInPlaylistMutation({
      parentPlaylistId: parentPlaylist.id,
      playlistId: parentPlaylist.followedPlaylist!.id,
      trackId: track.id,
    });
    onClose();
  };

  const onAddPlaylist = async () => {
    setNewPlaylistTitle('');
    createPlaylistMutation({
      title: formattedPlaylistTitle,
      trackIds: [track.id],
      type: PlaylistType.custom,
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      avoidKeyboard
      titleId="selectPlaylist.addToPlaylist">
      <ScrollView
        contentContainerStyle={style.listContent}
        showsVerticalScrollIndicator={false}
        bounces={false}>
        <Space style={style.newPlaylistRow} mb="s">
          <Space flex>
            <Input
              value={newPlaylistTitle}
              onChangeText={setNewPlaylistTitle}
              placeholderId="playlistForm.newPlaylistTitlePlaceholder"
              maxLength={titleMaxLength}
            />
          </Space>
          <Space ml="xs" />
          <Button
            onPress={onAddPlaylist}
            disabled={isAddDisabled}
            textId="selectPlaylist.add"
            style={style.addButton}
            icon={{
              name: 'plus',
              provider: 'ant',
              size: 12,
            }}
          />
        </Space>
        <View style={style.list}>
          {ownPlaylists.map(playlist => {
            const isIncluded = playlist.trackIds?.includes(track.id);

            return (
              <TouchableCard
                onPress={() => toggleTrack(playlist)}
                key={playlist.id}
                style={style.playlist}
                p="xs">
                {({actionColor}) => (
                  <>
                    <Text
                      weight="semibold"
                      numberOfLines={1}
                      id={playlist.titleId}
                      color={actionColor()}>
                      {playlist.title}
                    </Text>
                    {playlist.type === PlaylistType.favorites ? (
                      <Icon
                        provider="custom"
                        fill={isIncluded}
                        name="heart"
                        color={isIncluded ? 'favoritesColor' : actionColor()}
                      />
                    ) : (
                      <Icon
                        provider="custom"
                        name={isIncluded ? 'minus' : 'add'}
                        color={actionColor()}
                      />
                    )}
                  </>
                )}
              </TouchableCard>
            );
          })}

          {collaborativePlaylists['free-for-all'].map(parentPlaylist => {
            const playlist = parentPlaylist.followedPlaylist!;
            const isIncluded = playlist.trackIds?.includes(track.id);

            return (
              <TouchableCard
                onPress={() => toggleCollaborativePlaylistTrack(parentPlaylist)}
                key={parentPlaylist.id}
                style={style.playlist}
                p="xs">
                {({actionColor}) => (
                  <>
                    <Text
                      weight="semibold"
                      numberOfLines={1}
                      id={playlist.titleId}
                      color={actionColor()}>
                      {playlist.title}
                    </Text>
                    <Icon
                      provider="custom"
                      name={isIncluded ? 'minus' : 'add'}
                      color={actionColor()}
                    />
                  </>
                )}
              </TouchableCard>
            );
          })}

          {collaborativePlaylists.solo?.map(parentPlaylist => {
            const playlist = parentPlaylist.followedPlaylist!;
            const isAdded = playlist.trackIds?.includes(track.id);
            const isSuggested = playlist?.suggestions?.[track.id];
            const canAdd = !isAdded && !isSuggested;

            return (
              <TouchableCard
                onPress={() => suggestTrack(parentPlaylist)}
                disabled={!canAdd}
                key={parentPlaylist.id}
                style={style.playlist}
                p="xs">
                {({actionColor}) => (
                  <>
                    <Text
                      weight="semibold"
                      numberOfLines={1}
                      id={playlist.titleId}
                      color={actionColor()}>
                      {playlist.title}
                    </Text>

                    {isAdded ? (
                      <Text id="suggestToPlaylist.added" />
                    ) : isSuggested ? (
                      <Text id="suggestToPlaylist.suggested" />
                    ) : (
                      <Text id="suggestToPlaylist.suggest" />
                    )}
                  </>
                )}
              </TouchableCard>
            );
          })}
        </View>
      </ScrollView>
    </Modal>
  );
};

export default SelectPlaylistModal;
