import {useState} from 'react';

import {uploadFile} from '@/api/files';
import {useBooleanState} from '@/hooks/useBooleanState';
import {pickImage} from '@/utils/imagePicker/imagePicker';
import {IPickedImage} from '@/utils/imagePicker/types';

interface IProps {
  maxSize?: number;
  minSize?: number;
  circular?: boolean;
  onUpload?: (hash: string) => void;
}

export interface IImageUploaderResult {
  pickedImage: IPickedImage | undefined;
  sizeError: boolean;
  uploadProgress: number;
  isUploading: boolean;
  selectImage: () => void;
  ipfsHash: string | undefined;
  reset: () => void;
}

export const useImageUploader = ({
  maxSize,
  minSize,
  circular,
  onUpload,
}: IProps): IImageUploaderResult => {
  const [pickedImage, setPickedImage] = useState<IPickedImage>();
  const [sizeError, setSizeError, clearSizeError] = useBooleanState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, uploadStarted, uploadEnded] = useBooleanState(false);
  const [ipfsHash, setIpfsHash] = useState<string>();

  const selectImage = async () => {
    try {
      clearSizeError();

      const asset = await pickImage({
        resizeHeight: maxSize,
        resizeWidth: maxSize,
        circular,
      });

      if (minSize && (asset.width < minSize || asset.height < minSize)) {
        setSizeError();
        return;
      }

      setPickedImage(asset);
      upload(asset);
    } catch (error) {}
  };

  const upload = async (image: IPickedImage) => {
    try {
      uploadStarted();
      setUploadProgress(0);
      const _ipfsHash = await uploadFile(image.file, setUploadProgress);
      setIpfsHash(_ipfsHash);
      onUpload?.(_ipfsHash);
    } catch (error) {
      console.log(error);
    } finally {
      uploadEnded();
    }
  };

  const reset = () => {
    setIpfsHash(undefined);
    onUpload?.('');
    setPickedImage(undefined);
    clearSizeError();
    setUploadProgress(0);
    uploadEnded();
  };

  return {
    pickedImage,
    sizeError,
    uploadProgress,
    isUploading,
    ipfsHash,
    selectImage,
    reset,
  };
};
