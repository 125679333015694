import API from '@/services/spinampApi';
import {IApiResponseCustomThemesConfig, IApiResponseThemes} from '@/types/api';
import {formatFirebaseId} from '@/utils/api';

export const fetchCustomThemesConfig =
  async (): Promise<IApiResponseCustomThemesConfig> => {
    const themesConfig: IApiResponseCustomThemesConfig = await API.get(
      '/config/theme',
    );

    // we need to convert artist ids in themes config to follow new checksum-case format
    return {
      ...themesConfig,
      artistThemes: Object.entries(themesConfig.artistThemes).reduce(
        (result, [artistId, theme]) => ({
          ...result,
          [formatFirebaseId(artistId)]: theme,
        }),
        {},
      ),
    };
  };

export const fetchThemes = (): Promise<IApiResponseThemes> =>
  API.get('/themes');
