import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  content: {
    gap: spacing.xs * 1.5,
    padding: spacing.s,
  },
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: spacing.xs * 1.5,
    paddingVertical: spacing.xs,
  },
}));
