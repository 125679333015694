import React, {FC, useState} from 'react';
import {Dimensions, View} from 'react-native';

import Modal from '@/components/Modal';
import StaticImage from '@/components/StaticImage';
import {useResponsive} from '@/hooks/useResponsive';
import {useThemedStyles} from '@/theme';
import {IImageDimensions, ImageSize} from '@/types/media';

import {styles} from './ImagePreview.style';

interface IProps {
  isOpen: boolean;
  close: () => void;
  uri: string;
  resizeWidth: ImageSize | number;
  thumbnailSize?: ImageSize | number;
}

const SCREEN_WIDTH = Dimensions.get('window').width;
const SCREEN_HEIGHT = Dimensions.get('window').height;

const ImagePreview: FC<IProps> = ({
  isOpen,
  close,
  uri,
  resizeWidth,
  thumbnailSize,
}) => {
  const style = useThemedStyles(styles);
  const [dimensions, setDimensions] = useState<IImageDimensions | null>(null);
  const {isMobile} = useResponsive();

  const modalWidth = Math.min(
    isMobile ? SCREEN_WIDTH : 0.9 * SCREEN_WIDTH,
    resizeWidth,
  );
  const modalHeight = Math.min(
    isMobile ? SCREEN_HEIGHT : 0.8 * SCREEN_HEIGHT,
    resizeWidth,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      style={[
        style.modal,
        {
          width: modalWidth,
          height: modalHeight,
        },
        dimensions && {
          maxWidth: dimensions.width,
          maxHeight: dimensions.height,
        },
      ]}
      contentStyle={style.modalContent}
      swipeable
      closeOnContentClick
      backdropColor="rgba(0, 0, 0, 0.9)">
      <View style={style.imageWrapper}>
        {thumbnailSize && (
          <StaticImage
            resizeWidth={thumbnailSize}
            source={{uri}}
            style={style.image}
            resizeMode="contain"
          />
        )}
        <StaticImage
          resizeWidth={resizeWidth}
          source={{uri}}
          style={style.image}
          resizeMode="contain"
          onLoad={setDimensions}
        />
      </View>
    </Modal>
  );
};

export default ImagePreview;
