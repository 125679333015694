import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';
import {ScrollView, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import Touchable from '@/components/Touchable/Touchable';
import {externalLinks} from '@/constants/externalLinks';
import {useActiveUser} from '@/hooks/useActiveUser';
import {useThemedStyles} from '@/theme';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {openLinkSafe} from '@/utils/linking';

import {styles} from './SettingsMenu.style';

interface IProps {
  close: () => void;
}

const SettingsMenu: FC<IProps> = ({close}) => {
  const style = useThemedStyles(styles);
  const navigation = useNavigation<RootStackNavigationParams>();
  const activeUser = useActiveUser();

  const openExternalLink = (uri: string) => () => {
    openLinkSafe(uri);
    close();
  };

  return (
    <ScrollView showsVerticalScrollIndicator={false} style={style.container}>
      <Space p="s">
        <Text id="menu.settings" weight="semibold" size="l" />
      </Space>

      <Touchable
        onPress={() => {
          navigation.navigate(Routes.MainNavigation, {
            screen: Routes.ThemesSettings,
          });
          close();
        }}
        style={style.item}
        hoveredStyle={style.item_hovered}
        pressedStyle={style.item_pressed}>
        <View style={style.itemIcon}>
          <Icon name="palette" provider="custom" size={22} />
        </View>
        <Text size="s" id="themesSettings.title" />
      </Touchable>

      {activeUser && (
        <Touchable
          onPress={() => {
            navigation.navigate(Routes.MainNavigation, {
              screen: Routes.Wallets,
            });
            close();
          }}
          style={style.item}
          hoveredStyle={style.item_hovered}
          pressedStyle={style.item_pressed}>
          <View style={style.itemIcon}>
            <Icon name="wallet" provider="custom" size={22} />
          </View>
          <Text size="s" id="wallets.title" />
        </Touchable>
      )}

      {activeUser?.artistProfile && (
        <Touchable
          onPress={() => {
            navigation.navigate(Routes.MainNavigation, {
              screen: Routes.TipsSettings,
            });
            close();
          }}
          style={style.item}
          hoveredStyle={style.item_hovered}
          pressedStyle={style.item_pressed}>
          <View style={style.itemIcon}>
            <Icon name="tip" provider="custom" size={22} />
          </View>
          <Text size="s" id="tipsSettings.title" />
        </Touchable>
      )}

      <Space ph="xs" mt="m" mb="xs">
        <Text weight="semibold" id="menu.contact" size="s" />
      </Space>
      {externalLinks.spinamp.map(link => (
        <Touchable
          key={link.url}
          onPress={openExternalLink(link.url)}
          style={style.item}
          hoveredStyle={style.item_hovered}
          pressedStyle={style.item_pressed}>
          <View style={style.itemIcon}>
            <Icon {...link.icon} />
          </View>
          <Text size="s" id={link.textId}>
            {link.text}
          </Text>
        </Touchable>
      ))}

      <Space mt="xs" style={style.externalLinkIcons}>
        {externalLinks.contact.map(link => (
          <IconButton
            key={link.url}
            onPress={openExternalLink(link.url)}
            style={style.externalLinkItem}
            icon={link.icon}
          />
        ))}
      </Space>
    </ScrollView>
  );
};

export default SettingsMenu;
