import React, {FC} from 'react';
import {View} from 'react-native';

import Avatar from '@/components/Avatar';
import Icon from '@/components/Icon';
import {useThemedStyles} from '@/theme';
import {IArtist} from '@/types/common';
import {ImageSize} from '@/types/media';
import {IUser} from '@/types/user';

import {AVATAR_SIZE, styles} from './TipAvatars.style';

interface IProps {
  artist: IArtist;
  user: IUser | undefined;
}

const TipAvatars: FC<IProps> = ({artist, user}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <View style={style.dashedCircle} />
      {user ? (
        <Avatar
          style={style.avatarLeft}
          id={user.id}
          url={user.avatarUrl}
          size={AVATAR_SIZE}
          resizeWidth={ImageSize.avatar}
        />
      ) : (
        <View style={[style.userPlaceholder, style.avatarLeft]}>
          <Icon provider="custom" name="user" />
        </View>
      )}
      <Avatar
        style={style.avatarRight}
        id={artist.id}
        url={artist.avatarUrl}
        size={AVATAR_SIZE}
        resizeWidth={ImageSize.avatar}
      />
      <View style={style.heartCircle}>
        <Icon
          provider="custom"
          name="heart"
          fill
          color="favoritesColor"
          size={16}
        />
      </View>
    </View>
  );
};

export default TipAvatars;
