import {useNavigationState} from '@react-navigation/native';
import {useEffect, useState} from 'react';

export const usePathname = () => {
  const [pathname, setPathname] = useState('');
  const navigationState = useNavigationState(state => state);

  useEffect(() => {
    setPathname(window.location.pathname);
  }, [navigationState]);

  return pathname;
};
