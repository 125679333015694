import {useNavigation} from '@react-navigation/native';

import {ITopUpParams} from '@/modules/TopUp/types';
import {useUserEmailQuery} from '@/queries/user';
import {RootStackNavigationParams, Routes} from '@/types/routes';

export const useTopUp = () => {
  const navigation = useNavigation<RootStackNavigationParams>();

  // prefetch user email
  useUserEmailQuery();

  const topUp = ({
    address,
    chainId,
    amount,
  }: Pick<ITopUpParams, 'address' | 'chainId' | 'amount' | 'email'>) => {
    navigation.navigate(Routes.TopUp, {
      address,
      amount: amount?.toString(),
      chainId,
    });
  };

  return {
    topUp,
  };
};
