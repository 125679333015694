import React, {createContext, FC, ReactNode, useState} from 'react';
import {StyleProp, TouchableWithoutFeedback} from 'react-native';
import Animated, {
  FadeIn,
  measure,
  MeasuredDimensions,
  runOnJS,
  runOnUI,
  useAnimatedRef,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import TooltipOverlay from '@/components/Tooltip/TooltipOverlay';
import spacing from '@/constants/spacing';
import {useThemedStyles} from '@/theme';

import {styles} from './Tooltip.style';

interface IRenderProps {
  open: () => void;
  close: () => void;
}
interface IProps {
  content: ReactNode | ((props: IRenderProps) => ReactNode);
  children: (props: IRenderProps) => ReactNode;
  containerStyle?: StyleProp<any>;
  tooltipStyle?: StyleProp<any>;
  // more positions can be added when needed
  position?:
    | 'top'
    | 'bottom'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right';

  offset?: number;
  hoverEnabled?: boolean;
}

export const TooltipContext = createContext<IRenderProps | undefined>(
  undefined,
);

const Tooltip: FC<IProps> = ({
  content,
  children,
  containerStyle,
  tooltipStyle,
  position = 'right',
  offset = spacing.xs,
  hoverEnabled = true,
}) => {
  const ref = useAnimatedRef();
  const containerMeasurement = useSharedValue<MeasuredDimensions | null>(null);
  const tooltipSize = useSharedValue<{
    width: number;
    height: number;
  } | null>(null);

  const style = useThemedStyles(styles);
  const [isVisible, setIsVisible] = useState(false);

  const onOpen = () => {
    runOnUI(() => {
      containerMeasurement.value = measure(ref);
      runOnJS(setIsVisible)(true);
    })();
  };

  const onClose = () => {
    setIsVisible(false);
    containerMeasurement.value = null;
    tooltipSize.value = null;
  };

  const tooltipAnimatedStyle = useAnimatedStyle(() => {
    if (!tooltipSize.value || !containerMeasurement.value) {
      return {
        opacity: 0,
      };
    }

    const opacity = withTiming(1, {duration: 150});
    const {
      pageX,
      pageY,
      height: containerHeight,
      width: containerWidth,
    } = containerMeasurement.value;
    const {height: tooltipHeight, width: tooltipWidth} = tooltipSize.value;

    if (position === 'right') {
      return {
        top: pageY + containerHeight / 2 - tooltipHeight / 2,
        left: pageX + containerWidth + offset,
        opacity,
      };
    }

    if (position === 'top-left') {
      return {
        top: pageY - tooltipHeight - offset,
        left: pageX,
        opacity,
      };
    }

    if (position === 'top-right') {
      return {
        top: pageY - tooltipHeight - offset,
        left: pageX + containerWidth - tooltipWidth,
        opacity,
      };
    }

    if (position === 'bottom-left') {
      return {
        top: pageY + containerHeight + offset,
        left: pageX,
        opacity,
      };
    }

    if (position === 'bottom-right') {
      return {
        top: pageY + containerHeight + offset,
        left: pageX + containerWidth - tooltipWidth,
        opacity,
      };
    }

    if (position === 'bottom') {
      return {
        top: pageY + containerHeight + offset,
        left: pageX + containerWidth / 2 - tooltipWidth / 2,
        opacity,
      };
    }

    if (position === 'top') {
      return {
        top: pageY - tooltipHeight - offset,
        left: pageX + containerWidth / 2 - tooltipWidth / 2,
        opacity,
      };
    }

    return {};
  }, [position, offset]);

  return (
    <TooltipContext.Provider value={{open: onOpen, close: onClose}}>
      <Animated.View style={[containerStyle]} ref={ref}>
        {children({open: onOpen, close: onClose})}
        {isVisible && (
          <TooltipOverlay hoverEnabled={hoverEnabled}>
            <TouchableWithoutFeedback onPress={() => setIsVisible(false)}>
              <Animated.View
                style={style.backdropWrapper}
                entering={FadeIn.duration(150)}>
                <Animated.View style={style.backdrop} />
              </Animated.View>
            </TouchableWithoutFeedback>

            <TouchableWithoutFeedback onPress={() => {}}>
              <Animated.View
                onLayout={({nativeEvent}) => {
                  tooltipSize.value = {
                    width: nativeEvent.layout.width,
                    height: nativeEvent.layout.height,
                  };
                }}
                style={[
                  style.tooltipContainer,
                  tooltipStyle,
                  tooltipAnimatedStyle,
                ]}>
                {typeof content === 'function'
                  ? content({open: onOpen, close: onClose})
                  : content}
              </Animated.View>
            </TouchableWithoutFeedback>
          </TooltipOverlay>
        )}
      </Animated.View>
    </TooltipContext.Provider>
  );
};

export default Tooltip;
