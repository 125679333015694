import React from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import InfinityList, {InfinityListEmpty} from '@/components/InfinityList';
import PlaylistCard, {PLAYLIST_CARD_HEIGHT} from '@/components/PlaylistCard';
import Screen from '@/components/Screen/Screen';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useTrendingPlaylistsQuery} from '@/queries/trendingPlaylists';

const TrendingPlaylists = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const {
    trendingPlaylists,
    query: {isLoading, isError},
  } = useTrendingPlaylistsQuery();

  return (
    <Screen>
      <Header
        titleId="explore.trendingPlaylists"
        showBack
        scrollPosition={scrollPosition}
        mockTitle
      />
      <AsyncContent isLoading={isLoading}>
        {() => (
          <InfinityList
            onScroll={onScroll}
            data={trendingPlaylists}
            itemSize={PLAYLIST_CARD_HEIGHT}
            keyExtractor={item => item.id}
            renderItem={({item}) => <PlaylistCard playlist={item} />}
            ListEmptyComponent={<InfinityListEmpty isError={isError} />}
          />
        )}
      </AsyncContent>
    </Screen>
  );
};

export default TrendingPlaylists;
