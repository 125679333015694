import React, {useCallback, useEffect, useRef, useState} from 'react';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import {useInfinityList} from '@/components/InfinityList/useInfinityList';
import ScreenLoader from '@/components/ScreenLoader';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useRegeneratingFeedQuery} from '@/queries/feed';
import ArtistFeedCard from '@/screens/Feed/components/FeedCard/ArtistFeedCard';
import GenresFeedCard, {
  GenreRefillFeature,
} from '@/screens/Feed/components/FeedCard/GenresFeedCard';
import LocalMessageFeedCard from '@/screens/Feed/components/FeedCard/LocalMessageFeedCard';
import MessageFeedCard from '@/screens/Feed/components/FeedCard/MessageFeedCard';
import TrackFeedCard from '@/screens/Feed/components/FeedCard/TrackFeedCard';
import {
  IFeedCarouselRef,
  SetScrollToTop,
} from '@/screens/Feed/components/FeedCarousel/shared';
import FeedInlineError from '@/screens/Feed/components/FeedInlineError';
import PlayAllModal from '@/screens/Feed/components/PlayAllModal';
import {setShouldPlay as setFeedShouldPlay} from '@/store/feed';
import {selectIsPlaying} from '@/store/player';
import {selectActiveUserId} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {IFeedEntityType} from '@/types/feed';
import {analytics} from '@/utils/analytics';
import {isWeb} from '@/utils/platform';

import {styles} from './Feed.styles';
import FeedCarousel from './FeedCarousel/FeedCarousel';
import {useFeedItemsWithArtificialCards} from './useFeedItemsWithArtificialCards';

interface IFeedProps {
  sessionStart: string;
  userId: string;
  isPlayAllModalOpen: boolean;
  togglePlayAllModal: () => void;
  isOwnFeed: boolean;
  setScrollToTop?: SetScrollToTop;
  initialIndex?: number;
  onIndexChange?: (index: number) => void;
}

const Feed: React.FC<IFeedProps> = ({
  sessionStart,
  userId,
  isPlayAllModalOpen,
  togglePlayAllModal,
  isOwnFeed,
  setScrollToTop,
  initialIndex = 0,
  onIndexChange,
}) => {
  const dispatch = useAppDispatch();
  const style = useThemedStyles(styles);

  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [isCarouselEnabled, setIsCarouselEnabled] = useState(true);
  const feedCarouselRef = useRef<IFeedCarouselRef | null>(null);
  const next = useCallback(() => feedCarouselRef.current?.next(), []);

  const isMainPlayerPlaying = useAppSelector(selectIsPlaying);
  const myUserId = useAppSelector(selectActiveUserId);

  useEffect(() => {
    if (myUserId && userId !== myUserId) {
      analytics.feedSpyRequested(myUserId, userId);
    }
  }, [myUserId, userId]);

  useEffect(() => {
    if (isMainPlayerPlaying) {
      dispatch(setFeedShouldPlay(false));
    }
  }, [isMainPlayerPlaying]);

  const {
    feedItems,
    query: feedQuery,
    regenerateFeed,
  } = useRegeneratingFeedQuery(userId, {}, sessionStart);

  const {onEndReached} = useInfinityList({
    fetchNextPage: feedQuery.fetchNextPage,
    isFetchingNextPage: feedQuery.isFetchingNextPage,
    hasNextPage: feedQuery.hasNextPage,
  });

  const feedItemsWithOnboarding = useFeedItemsWithArtificialCards(
    feedItems,
    userId,
  );

  const [artistCardsTrackIndex, setArtistCardsTrackIndex] = useState<{
    [feedId: string]: number;
  }>({});

  const changeArtistTrack = useCallback((feedItemId: string, index: number) => {
    setArtistCardsTrackIndex(current => ({
      ...current,
      [feedItemId]: index,
    }));
  }, []);

  return (
    <Animated.View entering={FadeIn} exiting={FadeOut} style={style.content}>
      {(() => {
        if (feedItemsWithOnboarding && feedItemsWithOnboarding.length > 0) {
          return (
            <FeedCarousel
              ref={feedCarouselRef}
              feedItems={feedItemsWithOnboarding}
              activeIndex={activeIndex}
              setActiveIndex={index => {
                setActiveIndex(index);
                onIndexChange?.(index);
              }}
              onRefresh={regenerateFeed}
              onEndReached={onEndReached}
              setScrollToTop={setScrollToTop}
              isOwnFeed={isOwnFeed}
              isCarouselEnabled={isCarouselEnabled}
              artistCardsTrackIndex={artistCardsTrackIndex}
              changeArtistTrack={changeArtistTrack}
              renderItem={({item, index}) => {
                const isActive = index === activeIndex;
                // On mobile we don't want to set keys to make use of FlashList recycling benefits.
                const key = isWeb ? item.id : undefined;
                // On the other hand LocalMessageFeedCard and FeedCard are not displayed that often and are cheap to render,
                // so we can set key for them for easier development.

                switch (item.entityType) {
                  case IFeedEntityType.track:
                    return (
                      <TrackFeedCard
                        key={key}
                        feedItem={item}
                        isActive={isActive}
                        next={next}
                        setCarouselEnabled={setIsCarouselEnabled}
                      />
                    );
                  case IFeedEntityType.artist:
                    return (
                      <ArtistFeedCard
                        key={key}
                        feedItem={item}
                        isActive={isActive}
                        next={next}
                        setCarouselEnabled={setIsCarouselEnabled}
                        trackIndex={artistCardsTrackIndex[item.id]}
                        setTrackIndex={changeArtistTrack}
                      />
                    );
                  case IFeedEntityType.localMessage:
                    return (
                      <LocalMessageFeedCard key={item.id} feedItem={item} />
                    );
                  case IFeedEntityType.refill:
                    return (
                      <GenresFeedCard
                        key={item.id}
                        item={item}
                        onEnd={next}
                        feature={<GenreRefillFeature />}
                      />
                    );
                  case IFeedEntityType.message:
                    return <MessageFeedCard key={key} feedItem={item} />;
                }
              }}
            />
          );
        }

        if (feedQuery.isPending) {
          return <ScreenLoader />;
        }

        return (
          <FeedInlineError
            isFetching={feedQuery.isFetching}
            onRefetch={feedQuery.refetch}
          />
        );
      })()}

      {isPlayAllModalOpen && (
        <PlayAllModal
          feedItems={feedItems || []}
          isOpen
          onClose={togglePlayAllModal}
        />
      )}
    </Animated.View>
  );
};

export default Feed;
