import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  button_loading: {
    opacity: 0.6,
    cursor: 'default',
  },
  button_disabled: {
    opacity: 0.4,
    cursor: 'default',
  },
}));
