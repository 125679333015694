import React, {useEffect} from 'react';
import {TouchableOpacity} from 'react-native';
import Animated, {FadeIn, FadeOut} from 'react-native-reanimated';

import DeletePlaylistModal from '@/components/DeletePlaylistModal/DeletePlaylistModal';
import Header from '@/components/Header';
import Icon from '@/components/Icon';
import PlaylistFormModal from '@/components/PlaylistFormModal';
import Screen from '@/components/Screen/Screen';
import Space from '@/components/Space/Space';
import {HIT_SLOP} from '@/constants/spacing';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsInitialRender} from '@/hooks/useIsInitialRender';
import {useResponsive} from '@/hooks/useResponsive';
import CategoryPills from '@/screens/Library/components/CategoryPills';
import LibraryArtists from '@/screens/Library/components/LibraryArtists';
import LibraryCollectors from '@/screens/Library/components/LibraryCollectors';
import LibraryFavorites from '@/screens/Library/components/LibraryFavorites';
import LibraryHistory from '@/screens/Library/components/LibraryHistory';
import LibraryPlaylists from '@/screens/Library/components/LibraryPlaylists';
import {ANIMATION_DURATION} from '@/screens/Library/constants';
import {LibraryContext} from '@/screens/Library/context';
import {useDeletePlaylistModal} from '@/screens/Library/hooks/useDeletePlaylistModal';
import {useLibraryCategories} from '@/screens/Library/hooks/useLibraryCategories';
import {useNewPlaylistModal} from '@/screens/Library/hooks/useNewPlaylistModal';
import {LibraryCategory} from '@/screens/Library/types';
import {isWeb} from '@/utils/platform';

const LibraryScreens = {
  [LibraryCategory.favorites]: LibraryFavorites,
  [LibraryCategory.playlists]: LibraryPlaylists,
  [LibraryCategory.artists]: LibraryArtists,
  [LibraryCategory.collectors]: LibraryCollectors,
  [LibraryCategory.history]: LibraryHistory,
};

const Library = () => {
  const {isDesktop} = useResponsive();
  const {scrollPosition, onScroll, onScrollOffsetChange} = useAnimatedHeader();
  const isInitialRender = useIsInitialRender();

  const {isNewPlaylistModalOpen, toggleNewPlaylistModal, submitNewPlaylist} =
    useNewPlaylistModal();

  const {deletedPlaylist, deletePlaylist, confirmDeletion, cancelDeletion} =
    useDeletePlaylistModal();

  const {
    activeCategoryKey,
    selectedCategory,
    selectedCategoryId,
    selectedSubcategory,
    setCategory,
    setSubcategory,
  } = useLibraryCategories();

  useEffect(() => {
    scrollPosition.value = 0;
  }, [selectedCategory, selectedSubcategory]);

  const ActiveScreen = LibraryScreens[selectedCategoryId];

  return (
    <LibraryContext.Provider
      value={{
        selectedCategory,
        selectedSubcategory,
        setCategory,
        setSubcategory,
        deletePlaylist,
        scrollPosition,
        onScroll,
        onScrollOffsetChange,
      }}>
      <Screen>
        <Header
          titleId="library.title"
          scrollPosition={isDesktop ? undefined : scrollPosition}
          mockTitle
          showMenu
          rightActions={
            <TouchableOpacity
              onPress={toggleNewPlaylistModal}
              hitSlop={HIT_SLOP}>
              <Space pr="xxs">
                <Icon name="add" provider="material" size={22} />
              </Space>
            </TouchableOpacity>
          }>
          {!isDesktop && <CategoryPills />}
        </Header>

        <Animated.View
          key={activeCategoryKey}
          style={{flex: 1}}
          entering={
            isWeb || isInitialRender
              ? undefined
              : FadeIn.duration(ANIMATION_DURATION).delay(ANIMATION_DURATION)
          }
          exiting={isWeb ? undefined : FadeOut.duration(ANIMATION_DURATION)}>
          <ActiveScreen />
        </Animated.View>

        {isNewPlaylistModalOpen && (
          <PlaylistFormModal
            isOpen
            createdByEnabled
            modalTitleId="addPlaylist.title"
            submitTextId="addPlaylist.add"
            onClose={toggleNewPlaylistModal}
            onSubmit={data => {
              submitNewPlaylist(data);
              setCategory(LibraryCategory.playlists);
            }}
          />
        )}
        {!!deletedPlaylist && (
          <DeletePlaylistModal
            isOpen
            playlist={deletedPlaylist}
            onClose={cancelDeletion}
            onConfirm={confirmDeletion}
          />
        )}
      </Screen>
    </LibraryContext.Provider>
  );
};

export default Library;
