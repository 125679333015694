import React, {FC} from 'react';
import {View} from 'react-native';

import WalletSettings from '../WalletSettings';
import WalletTile from '../WalletTile';
import AddressInfo from '@/components/AddressInfo';
import Avatar from '@/components/Avatar';
import Link from '@/components/Link';
import Slider from '@/components/Slider';
import Text from '@/components/Text/Text';
import {supportedChains} from '@/constants/chains';
import spacing from '@/constants/spacing';
import {useUserLink} from '@/hooks/useUserLink';
import {useThemedStyles} from '@/theme';
import {IAddressWithOwner} from '@/types/common';
import {ImageSize} from '@/types/media';
import {getUserDisplayName} from '@/utils/user';

import {styles} from './WalletInfo.style';

interface IProps {
  wallet: IAddressWithOwner;
  isOwnWallet?: boolean;
}

const WalletInfo: FC<IProps> = ({wallet, isOwnWallet = false}) => {
  const style = useThemedStyles(styles);
  const {getUserLinkConfig} = useUserLink();

  return (
    <View style={style.container}>
      <View style={style.header}>
        <AddressInfo address={wallet} showConnectionIndicator={isOwnWallet} />

        {isOwnWallet && (
          <Slider
            containerStyle={style.tiles}
            data={supportedChains}
            renderItem={chain => (
              <WalletTile wallet={wallet} chainId={chain.id} />
            )}
            keyExtractor={chain => chain.id.toString()}
          />
        )}

        {!isOwnWallet && wallet.user && (
          <View style={style.ownerContainer}>
            <Link {...getUserLinkConfig(wallet.user)}>
              {({hover}) => (
                <View style={style.ownerRow}>
                  <Text
                    id="wallet.owner"
                    size="xs"
                    underline={hover}
                    numberOfLines={1}
                  />
                  <Avatar
                    id={wallet.user!.id}
                    url={wallet.user!.avatarUrl}
                    size={spacing.s}
                    resizeWidth={ImageSize.thumbnail}
                  />
                  <Text size="xs" underline={hover} numberOfLines={1}>
                    {getUserDisplayName(wallet.user!)}
                  </Text>
                </View>
              )}
            </Link>
          </View>
        )}
      </View>

      {isOwnWallet && <WalletSettings wallet={wallet} />}

      <Text weight="semibold" id="wallet.collected" />
    </View>
  );
};

export default WalletInfo;
