import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen/Screen';
import TrackCard, {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useLatestTracksQuery} from '@/queries/db';
import {playNewQueue} from '@/store/player';
import {ITrack, PlayContextType} from '@/types/common';

const LatestTracks = () => {
  const dispatch = useDispatch();
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const {
    tracks,
    clearLatestTracksPages,
    query: {isLoading, fetchNextPage, refetch, hasNextPage, isFetchingNextPage},
  } = useLatestTracksQuery();

  useEffect(() => clearLatestTracksPages, []);

  const {isTrackActive} = useIsTrackActive(PlayContextType.latest);

  const onPlay = useCallback(
    (track: ITrack) => {
      dispatch(
        playNewQueue({
          trackIds: tracks.map(t => t.id),
          trackId: track.id,
          context: {
            source: 'LatestMore',
            type: PlayContextType.latest,
            titleId: 'playlists.latest',
          },
        }),
      );
    },
    [tracks],
  );

  return (
    <Screen>
      <Header
        titleId="explore.latestTracks"
        showBack
        scrollPosition={scrollPosition}
        mockTitle
      />
      <AsyncContent
        isLoading={isLoading && tracks.length === 0}
        isError={tracks.length === 0}>
        {() => (
          <InfinityList
            data={tracks}
            itemSize={TRACK_CARD_HEIGHT}
            keyExtractor={item => item.id}
            refresh={refetch}
            extraData={isTrackActive}
            onScroll={onScroll}
            renderItem={({item}) => (
              <TrackCard
                track={item}
                isActive={isTrackActive(item.id)}
                showArtist
                onPlay={onPlay}
              />
            )}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
          />
        )}
      </AsyncContent>
    </Screen>
  );
};

export default LatestTracks;
