import {useNavigation} from '@react-navigation/native';
import React, {FC} from 'react';

import CardButton from '@/components/CardButton';
import {IArtist} from '@/types/common';
import {RootStackNavigationParams, Routes} from '@/types/routes';
import {awaitScreenTransition} from '@/utils/navigation';

interface IProps {
  artist: IArtist;
}

const TipButton: FC<IProps> = ({artist}) => {
  const navigation = useNavigation<RootStackNavigationParams>();

  return (
    <CardButton
      onPress={async () => {
        navigation.goBack();
        await awaitScreenTransition();
        navigation.navigate(Routes.SendTip, {slug: artist.slug});
      }}
      text={{id: 'collect.tip'}}
    />
  );
};

export default TipButton;
