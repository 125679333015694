import React, {FC} from 'react';
import {View} from 'react-native';

import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon';
import Text from '@/components/Text';
import {enableNotifications} from '@/modules/Notifications/pushNotifications';
import {useThemedStyles} from '@/theme';

import {styles} from './NotificationsEnableBanner.style';

const NotificationsEnableBanner: FC = () => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.container}>
      <Icon name="warning" provider="custom" size={48} />
      <Text
        id="notifications.banner.title"
        weight="semibold"
        align="center"
        size="m"
      />
      <Text size="xs" id="notifications.banner.description" align="center" />
      <CardButton
        onPress={() => enableNotifications()}
        text={{id: 'notifications.banner.enable'}}
      />
    </View>
  );
};

export default NotificationsEnableBanner;
