import React, {FC, ReactNode} from 'react';

import CardButton from '@/components/CardButton';
import Modal, {IModalProps} from '@/components/Modal/Modal';
import Space from '@/components/Space/Space';
import Text, {TextProps} from '@/components/Text';
import {useThemedStyles} from '@/theme';

import {styles} from './InfoModal.style';

interface IProps {
  descriptionId?: string;
  descriptionProps?: TextProps;
  content?: ReactNode;
  confirmTextId?: string;
}

const InfoModal: FC<IProps & IModalProps> = ({
  isOpen,
  onClose,
  title,
  titleId,
  descriptionId,
  descriptionProps = {},
  content,
  confirmTextId = 'ok',
}) => {
  const style = useThemedStyles(styles);

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      titleId={titleId}
      onClose={onClose}
      disableClickOutside={false}>
      <Space p="m">
        {descriptionId && <Text id={descriptionId} {...descriptionProps} />}

        {content}

        <Space h="m" />

        <CardButton
          onPress={onClose}
          text={{id: confirmTextId}}
          style={style.action}
        />
      </Space>
    </Modal>
  );
};

export default InfoModal;
