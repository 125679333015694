import {createSelector} from '@reduxjs/toolkit';

import {RootState} from '@/store';

export const selectEvents = (state: RootState) => state.events;

export const selectPlaybackEvents = createSelector(
  selectEvents,
  events => events.playbackEvents,
);

export const selectIsSyncing = createSelector(
  selectEvents,
  events => events.isSyncing,
);
