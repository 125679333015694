import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  sectionTitle: {
    marginBottom: spacing.xs,
  },
  deliveryWalletCardAction: {
    paddingHorizontal: spacing.xs * 1.5,
  },
  customWalletCard: {
    gap: spacing.xs,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  selectWalletCard: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
