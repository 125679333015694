import React, {FC} from 'react';
import {View} from 'react-native';

import ClosingHeader from '@/components/ClosingHeader';
import ExternalLink from '@/components/ExternalLink';
import Space from '@/components/Space/Space';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text';
import TextDivider from '@/components/TextDivider';
import CollectLayout from '@/modules/Collect/components/CollectLayout';
import MainCollectFlow from '@/modules/Collect/components/MainCollectFlow';
import TipButton from '@/modules/Collect/components/TipButton';
import TransactionErrorInfo from '@/modules/Collect/components/TransactionErrorInfo';
import {useCollectState} from '@/modules/Collect/useCollectState';
import {canTipArtist} from '@/modules/Tip/utils';
import {useThemedStyles} from '@/theme';
import {getDomainFromUrl} from '@/utils/url';

import {styles} from './CollectScreenContent.style';

const CollectScreenContent: FC = () => {
  const style = useThemedStyles(styles);

  const {transaction, track, collectInfo, collectInfoQuery, selectedOffer} =
    useCollectState();
  const showTipButton = canTipArtist(track.artist);

  const renderContent = () => {
    if (transaction.error) {
      return <TransactionErrorInfo />;
    }

    if (collectInfo?.mintOffers && selectedOffer) {
      return (
        <MainCollectFlow
          selectedOffer={selectedOffer}
          mintOffers={collectInfo.mintOffers}
        />
      );
    }

    if (collectInfo?.externalOffer) {
      return (
        <CollectLayout>
          <Text size="xs" align="center" id="collect.external" />
          <Space h="s" />
          <View style={{flexDirection: 'row'}}>
            <ExternalLink
              url={collectInfo.externalOffer.url}
              size="xs"
              align="center"
              weight="semibold"
              numberOfLines={1}
            />
          </View>
        </CollectLayout>
      );
    }

    if (collectInfo?.availability === 'unknown') {
      return (
        <CollectLayout>
          <View style={style.content}>
            <Text
              size="xs"
              align="center"
              id={
                track.websiteUrl
                  ? 'collect.unknownAvailability.link'
                  : 'collect.unknownAvailability'
              }
              values={{
                url: track.websiteUrl && (
                  <ExternalLink
                    url={track.websiteUrl}
                    name={getDomainFromUrl(track.websiteUrl)}
                    weight="semibold"
                    align="center"
                    size="xs"
                    underline
                  />
                ),
              }}
            />
            {showTipButton && (
              <>
                <TextDivider text={{id: 'or'}} />
                <TipButton artist={track.artist} />
              </>
            )}
          </View>
        </CollectLayout>
      );
    }

    if (collectInfo?.availability === 'unavailable') {
      return (
        <CollectLayout>
          {canTipArtist(track.artist) ? (
            <View style={style.content}>
              <Text
                size="m"
                weight="semibold"
                align="center"
                id="collect.unavailable.tip.header"
              />
              <Text
                size="xs"
                align="center"
                id="collect.unavailable.tip.message"
              />
              <TipButton artist={track.artist} />
            </View>
          ) : (
            <Text size="xs" align="center" id="collect.unavailable" />
          )}
        </CollectLayout>
      );
    }

    if (collectInfoQuery.error) {
      return (
        <CollectLayout>
          <View style={style.content}>
            <Text size="xs" align="center" id="collect.loadError" />
            {showTipButton && (
              <>
                <TextDivider text={{id: 'or'}} />
                <TipButton artist={track.artist} />
              </>
            )}
          </View>
        </CollectLayout>
      );
    }

    return (
      <CollectLayout>
        <SpinLoader />
      </CollectLayout>
    );
  };

  return (
    <View style={style.container}>
      <ClosingHeader />
      {renderContent()}
    </View>
  );
};

export default CollectScreenContent;
