import {useMemo} from 'react';

import {useAppSelector} from '@/hooks/useRedux';
import {selectUserPlaylists} from '@/store/playlists';
import {IPlaylist} from '@/types/playlists';
import {areAddressesEqual} from '@/utils/ethereum';

/**
 * Returns whether the playlist with field equal to id is followed by the active user.
 *
 * @param field the field of the playlist to compare
 * @param id the value that the field must match
 * @returns whether the playlist is followed and the id of the playlist so it can be unfollowed
 */
export const useIsFollowedPlaylist = (field: keyof IPlaylist, id: string) => {
  const playlists = useAppSelector(selectUserPlaylists);

  const followedPlaylist = useMemo(
    () =>
      playlists.find(playlist => {
        if (field === 'followedCollectionAddress') {
          return areAddressesEqual(playlist.followedCollectionAddress, id);
        }

        return playlist[field] === id;
      }),
    [playlists, field, id],
  );

  return {
    isFollowed: !!followedPlaylist,
    unfollowId: followedPlaylist?.id,
  };
};
