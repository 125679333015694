import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(
  (theme, responsive, safeAreaInsets) => ({
    container: {
      flex: 1,
      paddingBottom: responsive.isMobile ? safeAreaInsets.bottom : 0,
    },
    content: {
      flex: 1,
      paddingVertical: spacing.xl * 2,
      justifyContent: 'center',
      alignItems: 'center',
      gap: spacing.l,
    },
  }),
);
