import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  container: {
    padding: spacing.m,
    alignItems: 'center',
    gap: spacing.l,
  },
}));
