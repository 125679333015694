import spacing from '@/constants/spacing';
import {CARD_INFO_CONTAINER_PADDING} from '@/screens/Feed/components/FeedCard/common.styles';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  actionsContainer: {
    marginTop: spacing.xs,
    marginHorizontal: -CARD_INFO_CONTAINER_PADDING,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
  },
  action: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
