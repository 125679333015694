import React, {FC, ReactNode} from 'react';

import {IIconProps} from '@/components/Icon';
import {useAppSelector} from '@/hooks/useRedux';
import {useUserLink} from '@/hooks/useUserLink';
import {selectCardsTheming} from '@/store/theme';
import {LocalThemeProvider} from '@/theme';
import {IBaseUser} from '@/types/user';

import AvatarCard, {IAvatarCardProps} from './AvatarCard';

export interface IUserCardProps extends Omit<IAvatarCardProps, 'action'> {
  user: IBaseUser;
  onPress?: () => void;
  action?: (user: IBaseUser) => void;
  actionIcon?: IIconProps;
  suffixComponent?: ReactNode;
}

const UserCard: FC<IUserCardProps> = ({
  user,
  onPress,
  action,
  actionIcon,
  ...avatarCardProps
}) => {
  const {navigateToUser} = useUserLink();

  return (
    <AvatarCard
      user={user}
      onPress={() => {
        navigateToUser(user);
        onPress?.();
      }}
      action={action && (() => action(user))}
      actionIcon={actionIcon}
      {...avatarCardProps}
    />
  );
};

const UserCardThemed: FC<IUserCardProps> = props => {
  const cardsThemingEnabled = useAppSelector(selectCardsTheming);
  const {user} = props;

  if (cardsThemingEnabled) {
    return (
      <LocalThemeProvider
        customTheme={user.customTheme}
        predefinedThemeName={user.predefinedThemeName}>
        <UserCard {...props} />
      </LocalThemeProvider>
    );
  }

  return <UserCard {...props} />;
};

export default React.memo(UserCardThemed);
