import React, {ReactNode} from 'react';
import {View} from 'react-native';

import {useThemedStyles} from '@/theme';

import {styles} from './FeedCardActions.styles';

interface IProps {
  actions: ReactNode[];
}

const FeedCardActions: React.FC<IProps> = ({actions}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.actionsContainer}>
      {actions.map((action, index) => {
        if (!action) {
          return null;
        }

        return (
          <View key={index} style={style.action}>
            {action}
          </View>
        );
      })}
    </View>
  );
};

export default FeedCardActions;
