import {StyleSheet} from 'react-native';

import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: '#000',
    opacity: 0.8,
  },
  content: {
    borderRadius: theme.box.borderRadius,
    overflow: 'hidden',
    height: '90%',
  },
}));
