export type FontPack = {
  light: string;
  regular: String;
  semibold: String;
  bold: String;
};

export const FontPacks = {
  SpaceGrotesk: {
    light: 'SpaceGrotesk-Light',
    regular: 'SpaceGrotesk-Medium',
    semibold: 'SpaceGrotesk-SemiBold',
    bold: 'SpaceGrotesk-Bold',
  },
  NeueHaasDisplay: {
    light: 'NeueHaasDisplay-Light',
    regular: 'NeueHaasDisplay-Light',
    semibold: 'NeueHaasDisplay-Light',
    bold: 'NeueHaasDisplay-Light',
  },
  AzeretMono: {
    light: 'AzeretMono-Light',
    regular: 'AzeretMono-Regular',
    semibold: 'AzeretMono-SemiBold',
    bold: 'AzeretMono-Bold',
  },
  Poppins: {
    light: 'Poppins-Light',
    regular: 'Poppins-Regular',
    semibold: 'Poppins-SemiBold',
    bold: 'Poppins-Bold',
  },
};

export const getFontPack = (font: string) => {
  if (font in FontPacks) {
    return (FontPacks as any)[font];
  }

  return {
    light: font,
    regular: font,
    semibold: font,
    bold: font,
  };
};

export const DefaultFontPack = FontPacks.Poppins;
