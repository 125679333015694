import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {ILocalPlaybackEvent, IPlaybackEventPayload} from '@/types/events';
import {generateId} from '@/utils/functions';

export interface EventsState {
  playbackEvents: ILocalPlaybackEvent[];
  isSyncing: boolean;
}

export const initialState: EventsState = {
  playbackEvents: [],
  isSyncing: false,
};

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    trackUserEvent: (
      state,
      action: PayloadAction<{userId?: string; event: IPlaybackEventPayload}>,
    ) => {
      const {userId, event} = action.payload;
      state.playbackEvents.push({
        ...event,
        collector: userId,
        localId: generateId(),
        timestamp: event.timestamp || Date.now(),
      });
    },
    assignCollectorToEvents: (state, action: PayloadAction<string>) => {
      state.playbackEvents.forEach(event => {
        event.collector = event.collector || action.payload;
      });
    },
    replaceCollectorOnEvents: (
      state,
      action: PayloadAction<{sourceUserId: string; targetUserId: string}>,
    ) => {
      const {sourceUserId, targetUserId} = action.payload;

      state.playbackEvents.forEach(event => {
        if (event.collector === sourceUserId) {
          event.collector = targetUserId;
        }
      });
    },
    cleanEvents: (state, action: PayloadAction<{eventIds: string[]}>) => {
      const {eventIds} = action.payload;

      state.playbackEvents = state.playbackEvents.filter(
        event => !eventIds.includes(event.localId),
      );
    },
    setIsSyncing: (state, action: PayloadAction<boolean>) => {
      state.isSyncing = action.payload;
    },
  },
});

export const {
  trackUserEvent,
  cleanEvents,
  assignCollectorToEvents,
  replaceCollectorOnEvents,
  setIsSyncing,
} = eventsSlice.actions;

export default eventsSlice.reducer;
