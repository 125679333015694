import React, {FC, useMemo} from 'react';
import {TouchableOpacity, View} from 'react-native';

import SlidingText from '@/components/SlidingText';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {getHitSlop} from '@/constants/spacing';
import {ITrack} from '@/types/common';
import {getTrackArtists} from '@/utils/tracks';

interface IProps {
  track: ITrack;
  goToTrack: (slug: string) => void;
  goToArtist: (slug: string) => void;
}

const TrackSlidingInfo: FC<IProps> = ({track, goToTrack, goToArtist}) => {
  // we need to memoize children for SlidingText to make it work properly
  const artistTextNode = useMemo(
    () => (
      <>
        {getTrackArtists(track).map((a, index) => (
          <React.Fragment key={a.id}>
            {index > 0 && (
              <View>
                <Text numberOfLines={1}> & </Text>
              </View>
            )}
            <TouchableOpacity
              onPress={() => goToArtist(a.slug)}
              hitSlop={getHitSlop('xs')}>
              <Text numberOfLines={1}>{a.name}</Text>
            </TouchableOpacity>
          </React.Fragment>
        ))}
      </>
    ),
    [track],
  );

  return (
    <>
      <TouchableOpacity onPress={() => goToTrack(track.slug)}>
        <SlidingText size="l" weight="semibold" numberOfLines={1}>
          {track.title}
        </SlidingText>
      </TouchableOpacity>
      <Space h="xxs" />
      <SlidingText>{artistTextNode}</SlidingText>
    </>
  );
};

export default React.memo(TrackSlidingInfo);
