import {useAppSelector} from '@/hooks/useRedux';
import {UseTrackReleaseNotification} from '@/hooks/useTrackReleaseNotification/types';
import {useLogin} from '@/modules/Login';
import {createTrackReleaseNotification} from '@/modules/Notifications/api';
import {useNotificationsPermissionMutation} from '@/modules/Notifications/pushNotifications';
import {
  useNotificationMutation,
  useUpcomingNotificationsQuery,
} from '@/modules/Notifications/query';
import {selectSelectedUserId} from '@/store/user';
import {analytics} from '@/utils/analytics';
import {noop} from '@/utils/functions';

export const useTrackReleaseNotification: UseTrackReleaseNotification = ({
  track,
  analyticsInfo,
}) => {
  const {openLoginModal} = useLogin();
  const userId = useAppSelector(selectSelectedUserId);
  const releaseTime = track.mintStart ? new Date(track.mintStart) : new Date();

  const {notifications} = useUpcomingNotificationsQuery(userId, track?.id);

  const notificationMutation = useNotificationMutation();

  const {requestPermissionAsync} = useNotificationsPermissionMutation();

  const subscribe = async () => {
    analytics.notificationRequested(
      'track-release',
      analyticsInfo.screen,
      analyticsInfo.id,
    );

    if (!userId) {
      openLoginModal();
      return;
    }

    // request permission in case it was never requested before
    await requestPermissionAsync();

    // notificationTime is 1 hour before releaseTime
    const notificationTime = new Date(
      releaseTime.getTime() - 3600 * 1000,
    ).toISOString();
    const notification = createTrackReleaseNotification(
      userId,
      track,
      notificationTime,
    );
    notificationMutation.mutate(notification);
  };

  const isSubscribed = notifications.length > 0;

  return {
    isSubscribed,
    subscribe: isSubscribed ? noop : subscribe,
    textId: isSubscribed ? 'track.notifiedRelease' : 'track.notifyRelease',
    iconName: 'notifications',
  };
};
