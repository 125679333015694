import {RouteProp, useRoute} from '@react-navigation/native';
import React, {FC} from 'react';
import {View} from 'react-native';

import AsyncContent from '@/components/AsyncContent';
import ClosingHeader from '@/components/ClosingHeader';
import ModalScreenWrapper from '@/components/ModalScreenWrapper';
import SpinLoader from '@/components/SpinLoader';
import Text from '@/components/Text';
import TipRouter from '@/modules/Tip/SendTip/components/TipRouter';
import SendTipProvider from '@/modules/Tip/SendTip/SendTipProvider';
import {useArtistBySlugQuery} from '@/queries/artists';
import {useThemedStyles} from '@/theme';
import {RootStackParams, Routes} from '@/types/routes';

import {styles} from './SendTipScreen.style';

export const SendTipScreen: FC = () => {
  const style = useThemedStyles(styles);
  const {
    params: {transactionId, slug},
  } = useRoute<RouteProp<RootStackParams, Routes.SendTip>>();

  const {artist, query} = useArtistBySlugQuery(slug);

  return (
    <ModalScreenWrapper
      titleId="tip.title"
      isFullscreenOnMobile
      desktopSize="l">
      <ClosingHeader />
      <View style={style.container}>
        <AsyncContent
          data={artist}
          isLoading={query.isLoading}
          isError={!artist}
          renderContent={loadedArtist => (
            <SendTipProvider
              transactionId={transactionId}
              artist={loadedArtist}>
              <TipRouter />
            </SendTipProvider>
          )}
          loaderComponent={
            <View style={style.content}>
              <SpinLoader />
            </View>
          }
          errorComponent={
            <View style={style.content}>
              <Text id="notFound" weight="semibold" />
            </View>
          }
        />
      </View>
    </ModalScreenWrapper>
  );
};

export default SendTipScreen;
