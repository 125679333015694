import {createPortal} from 'react-dom';
import {StyleSheet, View} from 'react-native';

import {ITooltipOverlayProps} from '@/components/Tooltip/TooltipOverlay/types';
import {isTouchScreen} from '@/utils/platform';

const TooltipOverlay = ({children, hoverEnabled}: ITooltipOverlayProps) => {
  return createPortal(
    <View
      style={{
        ...StyleSheet.absoluteFillObject,
        pointerEvents: hoverEnabled && !isTouchScreen ? 'none' : 'auto',
      }}>
      {children}
    </View>,
    document.body,
  );
};

export default TooltipOverlay;
