import {useCallback, useMemo} from 'react';

import {useThemesQuery} from '@/queries/themes';
import {makeTheme} from '@/themes/partialTheme';
import spinamp from '@/themes/spinamp.json'; // hardcoded theme fallback
import {IThemesConfig} from '@/types/theme';

export const useThemes = (isRoot: boolean = false) => {
  const {themesData} = useThemesQuery(isRoot);

  const themesConfigMap: IThemesConfig = useMemo(
    () => ({
      spinamp,
      ...themesData,
    }),
    [themesData],
  );

  const isValidTheme = useCallback(
    (themeName?: string) => {
      if (!themeName) {
        return false;
      }

      return Object.keys(themesConfigMap).includes(themeName);
    },
    [themesConfigMap],
  );

  const getTheme = useCallback(
    (themeName?: string) => {
      if (!themeName) {
        return spinamp;
      }

      if (themeName in themesConfigMap) {
        return makeTheme(themesConfigMap[themeName]);
      }

      return spinamp;
    },
    [themesConfigMap],
  );

  return {
    isValidTheme,
    getTheme,
    themesData,
  };
};
