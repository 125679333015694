import React from 'react';
import {View} from 'react-native';
import Animated, {FadeIn} from 'react-native-reanimated';
import {AnimatedView} from 'react-native-reanimated/lib/typescript/reanimated2/component/View';

import Background from '@/components/Background/Background';
import HoverOpacity from '@/components/HoverOpacity/HoverOpacity';
import PlayerEmpty from '@/components/Layout/PlayerEmpty';
import Player from '@/components/Player';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useAppSelector} from '@/hooks/useRedux';
import {useCustomThemesConfigQuery} from '@/queries/themes';
import {selectCurrentTrack, selectIsActive} from '@/store/player';
import {LocalThemeProvider, useTheme, useThemedStyles} from '@/theme';

import {styles} from './DesktopPlayer.style';

const DesktopPlayer = () => {
  const style = useThemedStyles(styles);
  const currentTrack = useAppSelector(selectCurrentTrack);
  const isActive = useAppSelector(selectIsActive);

  const theme = useTheme();
  return (
    <HoverOpacity containerStyle={style.container} isActive={isActive}>
      <View style={style.playerCard}>
        <View style={style.opaqueOverlay} />
        {theme.background?.image && <Background />}
        <Space style={style.header} p="xxs">
          <View style={style.headerSide}>
            <View style={style.headerLineTop} />
            <View style={style.headerLineSeparator} />
            <View style={style.headerLineBottomLeft} />
          </View>
          <Space ph="xs">
            <Text color="invertedTextColor" size="xs" id="appName" uppercase />
          </Space>
          <View style={style.headerSide}>
            <View style={style.headerLineTop} />
            <View style={style.headerLineSeparator} />
            <View style={style.headerLineBottomRight} />
          </View>
        </Space>
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.colors.opaqueOverlay,
          }}
        />
        {currentTrack ? (
          <Animated.View style={{flex: 1}} entering={FadeIn}>
            <Player currentTrack={currentTrack} />
          </Animated.View>
        ) : (
          <PlayerEmpty />
        )}
      </View>
    </HoverOpacity>
  );
};

export const DesktopPlayerThemed = () => {
  const currentTrack = useAppSelector(selectCurrentTrack);
  const {artistThemes, platformThemes} = useCustomThemesConfigQuery();

  const trackArtistTheme =
    currentTrack?.artistId && artistThemes[currentTrack.artistId];

  const trackPlatformTheme =
    currentTrack?.platformId && platformThemes[currentTrack.platformId];

  return (
    <LocalThemeProvider
      customTheme={currentTrack?.artist?.customTheme}
      predefinedThemeName={trackArtistTheme || trackPlatformTheme}
      shouldFallbackToAppTheme>
      <DesktopPlayer />
    </LocalThemeProvider>
  );
};
