import React, {FC} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';

import {FadeInOut} from '@/components/AnimationWrappers';
import Card from '@/components/Card/Card';
import IconButton from '@/components/IconButton';
import Space from '@/components/Space/Space';
import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text/Text';
import TransparentButton from '@/components/TransparentButton';
import spacing from '@/constants/spacing';
import {useFavToggle} from '@/hooks/useFavToggle';
import {useAppSelector} from '@/hooks/useRedux';
import DelayedFadeIn from '@/screens/SpinampWrapped/components/DelayedFadeIn';
import {useSpinampWrapped} from '@/screens/SpinampWrapped/useSpinampWrapped';
import {useSpinampWrappedAudio} from '@/screens/SpinampWrapped/useSpinampWrappedAudio';
import {selectSelectedUserId} from '@/store/user';
import {useTheme} from '@/theme';
import {ImageSize} from '@/types/media';
import {getTrackArtistsNames} from '@/utils/tracks';
import {getUserDisplayName} from '@/utils/user';

const TopTrack: FC = () => {
  const theme = useTheme();
  const activeUserId = useAppSelector(selectSelectedUserId);
  const {tracks, activePageId, nextPage, user} = useSpinampWrapped();
  const isActive = activePageId === 'topTrack';

  const topTrack = tracks[0].track;
  const count = tracks[0].count;

  const {isFav, toggleFav} = useFavToggle(topTrack.id);

  useSpinampWrappedAudio(isActive, topTrack);

  if (!topTrack) {
    return null;
  }

  return (
    <ScrollView
      bounces={false}
      showsVerticalScrollIndicator={false}
      style={{flex: 1}}
      contentContainerStyle={{
        flexGrow: 1,
        alignItems: 'center',
        paddingHorizontal: spacing.m,
        paddingVertical: spacing.l,
      }}>
      <Text
        align="center"
        weight="bold"
        size="l"
        id="spinampWrapped.topTrack.title"
      />
      <Space h="m" />
      <Card
        style={{
          width: '65%',
          paddingHorizontal: 0,
          paddingVertical: 0,
          overflow: 'hidden',
        }}>
        <View style={{width: '100%', aspectRatio: 1}}>
          <FadeInOut
            style={{
              ...StyleSheet.absoluteFillObject,
              backgroundColor: theme.colors.backgroundDark,
            }}
          />
          <StaticImage
            style={{width: '100%', height: '100%'}}
            source={{uri: topTrack.lossyArtworkUrl}}
            resizeWidth={ImageSize.player}
          />
        </View>
        <Space p="xs" style={{flexDirection: 'row', alignItems: 'center'}}>
          <Space flex pr="s">
            <Text weight="semibold" size="s">
              {topTrack.title}
            </Text>
            <Space mt="xxs" />
            <Text size="xs">{getTrackArtistsNames(topTrack)}</Text>
          </Space>
          <IconButton
            icon={{
              provider: 'custom',
              name: 'heart',
              fill: isFav,
              size: 20,
              color: isFav ? 'favoritesColor' : 'textColor',
            }}
            onPress={toggleFav}
          />
        </Space>
      </Card>

      <Space h="xl" />

      <Text
        align="center"
        size="xs"
        id={
          user && user.id !== activeUserId
            ? 'spinampWrapped.topTrack.description'
            : 'spinampWrapped.topTrack.description.own'
        }
        values={{
          user: user && getUserDisplayName(user),
          title: (
            <Text weight="semibold" align="center" size="xs">
              {topTrack.title}
            </Text>
          ),
          artist: (
            <Text weight="semibold" align="center" size="xs">
              {getTrackArtistsNames(topTrack)}
            </Text>
          ),
        }}
      />
      <Space h="s" />
      <Text
        align="center"
        size="xs"
        id="spinampWrapped.topTrack.description.count"
        values={{count}}
      />

      <Space h="xl" />

      <DelayedFadeIn isActive={isActive}>
        <TransparentButton
          onPress={nextPage}
          text={{id: 'spinampWrapped.next'}}
        />
      </DelayedFadeIn>
    </ScrollView>
  );
};

export default TopTrack;
