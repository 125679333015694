import {useConnectModal} from '@rainbow-me/rainbowkit';
import {
  useAccount,
  useSignMessage,
  useWalletClient,
  useSwitchChain,
  useConnect,
} from 'wagmi';

import {IUseExternalSigner} from '@/modules/ExternalWallet/useExternalSigner/types';
import {analytics} from '@/utils/analytics';
import {formatAddressChecksum} from '@/utils/ethereum';
import {isMobileBrowser} from '@/utils/platform';

export const useExternalSigner = (): IUseExternalSigner => {
  const {openConnectModal} = useConnectModal();
  const {connect: directConnect, connectors} = useConnect();
  const {data: walletClient} = useWalletClient();

  const account = useAccount();
  const {signMessageAsync} = useSignMessage();
  const address = account?.address
    ? formatAddressChecksum(account.address)
    : undefined;
  const chainId = account?.chainId;

  const {switchChain} = useSwitchChain();

  const connect = () => {
    analytics.externalWalletConnectStarted();
    if (isMobileBrowser) {
      directConnect({connector: connectors[0]});
    } else {
      openConnectModal?.();
    }

    return Promise.resolve(undefined);
  };

  const signMessage = async (message: string): Promise<string> => {
    if (!address) {
      return Promise.reject('No wallet connected!');
    }

    return signMessageAsync({message});
  };

  const switchNetwork = (_chainId: number) => {
    switchChain({chainId: _chainId});
  };

  return {
    isConnected: !!address && account.isConnected,
    connector: account?.connector?.id,
    address,
    connect,
    signMessage,
    switchNetwork,
    activeNetwork: chainId,
    sendTransaction: walletClient?.sendTransaction,
  };
};
