import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  container: {
    paddingTop: spacing.xl,
    paddingHorizontal: spacing.l,
    gap: spacing.l,
    alignItems: 'center',
  },
}));
