import {useMemo} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {useResponsive} from '@/hooks/useResponsive';
import {useThemedScreen} from '@/theme/useThemedScreen';
import {IDynamicTheme, ThemedStyles} from '@/types/theme';

export const useThemedScreenStyles = (
  styles: ThemedStyles,
  themeSettings?: IDynamicTheme,
) => {
  const theme = useThemedScreen(themeSettings);
  const responsive = useResponsive();
  const safeAreaInsets = useSafeAreaInsets();

  const style = useMemo(
    () => styles(theme, responsive, safeAreaInsets),
    [theme, responsive, safeAreaInsets],
  );

  return {
    style,
    theme,
  };
};
