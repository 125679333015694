import React, {FC} from 'react';
import {Linking} from 'react-native';

import {useLoginError} from '../../useLoginError';
import CardButton from '@/components/CardButton';
import Icon from '@/components/Icon/Icon';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {useLoginState} from '@/modules/Login';
import {PasskeyError} from '@/services/passkey';
import {useThemedStyles} from '@/theme';

import {styles} from './LoginError.style';

const LoginError: FC = () => {
  const style = useThemedStyles(styles);
  const {loginError, resetLoginError} = useLoginError();

  return (
    <Space style={style.content}>
      <Space style={style.title}>
        <Icon name="warning" provider="custom" />
        <Text weight="semibold" id="login.error.title" size="m" />
      </Space>
      {loginError && <Text id={loginError.messageId} size="xs" />}

      {loginError?.error instanceof PasskeyError &&
        loginError.error.status === 'SAVE_BLOB_ERROR' && (
          <CardButton
            onPress={() => {
              Linking.openURL('App-Prefs:PASSWORDS&path=PASSWORD_OPTIONS');
              resetLoginError();
            }}
            text={{id: 'login.error.enableKeychain'}}
          />
        )}

      <CardButton
        onPress={resetLoginError}
        text={{id: 'login.error.tryAgain'}}
      />
    </Space>
  );
};

export default LoginError;
