import {PasskeyError} from '@/services/passkey/errors';
import {
  arrayBufferToStr,
  generateChallenge,
  relyingParty,
  base64URLStringToBuffer,
  utf8StringToBuffer,
} from '@/services/passkey/shared';
import {IPasskey} from '@/services/passkey/types';
import {isIOSBrowser, isMobileBrowser, webPlatform} from '@/utils/platform';

export const Passkey: IPasskey = {
  isSupported: false,
  isBlobSupported: false,
  get: async credentialId => {
    try {
      const abortController = new AbortController();
      const credential = await navigator.credentials.get({
        publicKey: {
          challenge: generateChallenge(),
          userVerification: 'required',
          extensions: {
            // @ts-ignore
            largeBlob: {
              read: true,
            },
          },
          allowCredentials: credentialId
            ? [
                {
                  id: base64URLStringToBuffer(credentialId),
                  type: 'public-key',
                },
              ]
            : undefined,
        },
        signal: abortController.signal,
      });

      if (!credential) {
        return Promise.reject(new PasskeyError('NO_CREDENTIAL_RETURNED'));
      }

      // @ts-ignore
      const blob = credential.getClientExtensionResults()?.largeBlob?.blob;

      if (!blob) {
        return Promise.reject(new PasskeyError('MISSING_BLOB'));
      }

      return {
        credentialId: credential.id,
        privateKey: arrayBufferToStr(blob),
      };
    } catch (error: any) {
      if (error?.name === 'NotAllowedError') {
        return Promise.reject(new PasskeyError('USER_CANCELLED'));
      }

      return Promise.reject(error);
    }
  },
  create: async ({id, name}) => {
    try {
      const credential = await navigator.credentials.create({
        publicKey: {
          challenge: generateChallenge(),
          rp: {
            name: relyingParty.name,
          },
          user: {
            id: utf8StringToBuffer(id),
            name: name,
            displayName: name,
          },
          pubKeyCredParams: [
            {
              type: 'public-key',
              alg: -7,
            },
          ],
          extensions: {
            // @ts-ignore
            largeBlob: {
              support: 'required',
            },
          },
          excludeCredentials: [],
          authenticatorSelection: {
            authenticatorAttachment: isMobileBrowser
              ? undefined
              : 'cross-platform',
            requireResidentKey: true,
            residentKey: 'required',
            userVerification: 'required',
          },
        },
      });

      if (!credential) {
        return Promise.reject(new PasskeyError('NO_CREDENTIAL_RETURNED'));
      }

      return {
        credentialId: credential.id,
      };
    } catch (error: any) {
      if (error?.name === 'NotAllowedError') {
        return Promise.reject(new PasskeyError('USER_CANCELLED'));
      }

      return Promise.reject(error);
    }
  },
  writeBlob: async ({credentialId, message}) => {
    try {
      const credential = await navigator.credentials.get({
        publicKey: {
          challenge: generateChallenge(),
          userVerification: 'required',
          extensions: {
            // @ts-ignore
            largeBlob: {
              write: utf8StringToBuffer(message),
            },
          },
          allowCredentials: [
            {
              id: base64URLStringToBuffer(credentialId),
              type: 'public-key',
            },
          ],
        },
      });

      // @ts-ignore
      if (!credential?.getClientExtensionResults()?.largeBlob?.written) {
        return Promise.reject(new PasskeyError('SAVE_BLOB_ERROR'));
      }
    } catch (error: any) {
      if (error?.name === 'NotAllowedError') {
        return Promise.reject(new PasskeyError('USER_CANCELLED'));
      }

      return Promise.reject(new PasskeyError('SAVE_BLOB_ERROR'));
    }
  },
};

// https://web.dev/articles/passkey-registration?hl=pl#feature_detection
const checkSupport = async () => {
  try {
    if (
      !window.PublicKeyCredential ||
      !window.PublicKeyCredential
        .isUserVerifyingPlatformAuthenticatorAvailable ||
      // @ts-ignore
      !window.PublicKeyCredential.isConditionalMediationAvailable
    ) {
      return false;
    }

    const results = await Promise.all([
      PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(),
      // @ts-ignore
      PublicKeyCredential.isConditionalMediationAvailable(),
    ]);

    const isSupported = results.every(r => !!r);

    Passkey.isSupported = isSupported;
    Passkey.isBlobSupported = isIOSBrowser
      ? isSupported && Number(webPlatform.os.version.split('.')[0]) >= 17
      : isSupported;
  } catch (e) {
    Passkey.isSupported = false;
    Passkey.isBlobSupported = false;
  }
};

checkSupport();
