import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import React, {useCallback, useEffect, useRef} from 'react';
import {Keyboard, ScrollView, View} from 'react-native';

import {ArtistCard} from '@/components/ArtistCard';
import {UserCard} from '@/components/AvatarCard';
import KeyboardView from '@/components/KeyboardView/KeyboardView';
import PlaylistCard from '@/components/PlaylistCard/PlaylistCard';
import Screen from '@/components/Screen/Screen';
import ScreenLoader from '@/components/ScreenLoader';
import Space from '@/components/Space/Space';
import Text from '@/components/Text';
import TrackCard from '@/components/TrackCard/TrackCard';
import {useDebouncedSearch} from '@/hooks/useDebouncedSearch';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch, useAppSelector} from '@/hooks/useRedux';
import {useResponsive} from '@/hooks/useResponsive';
import {useBulkSearchQuery} from '@/queries/search';
import {searchCategories, SearchCategory} from '@/screens/Search/types';
import {useSearchCategories} from '@/screens/Search/useSearchCategories';
import {getVisibleItemsCount} from '@/screens/Search/utils';
import {playNewQueue} from '@/store/player';
import {
  clearSearchHistory,
  removeSearchPhrase,
  saveSearchPhrase,
  selectSearchHistory,
} from '@/store/user';
import {useThemedStyles} from '@/theme';
import {ITrack, PlayContextType} from '@/types/common';
import {
  Routes,
  SearchStackNavigationParams,
  SearchStackParams,
} from '@/types/routes';
import {getUserName} from '@/utils/user';

import SearchCategories from './components/SearchCategories/SearchCategories';
import SearchHeader from './components/SearchHeader/SearchHeader';
import SearchHistory from './components/SearchHistory/SearchHistory';
import SearchSection from './components/SearchSection/SearchSection';
import {styles} from './Search.style';

const Search = () => {
  const dispatch = useAppDispatch();
  const style = useThemedStyles(styles);
  const navigation = useNavigation<SearchStackNavigationParams>();
  const scrollView = useRef<ScrollView | null>(null);
  const {isMobile} = useResponsive();
  const searchHistory = useAppSelector(selectSearchHistory);
  const {isTrackActive} = useIsTrackActive(PlayContextType.search);

  const {params} = useRoute<RouteProp<SearchStackParams, Routes.Search>>();

  const {search, setSearch, searchDebounced, setSearchImmediate, clearSearch} =
    useDebouncedSearch({
      search: params?.query || '',
      setSearch: (q: string) => {
        navigation.setParams({query: q});
      },
    });

  const {category, setCategory} = useSearchCategories();

  const {
    tracks,
    artists,
    playlists,
    users,
    areTracksFetching,
    areArtistsFetching,
    arePlaylistsFetching,
    areUsersFetching,
  } = useBulkSearchQuery(searchDebounced);

  useEffect(() => {
    scrollView.current?.scrollTo({y: 0, x: 0, animated: true});
  }, [searchDebounced]);

  const saveSearch = (_phrase?: string) => {
    const phrase = _phrase || searchDebounced;

    if (phrase) {
      dispatch(saveSearchPhrase(phrase));
    }
  };

  const selectSearchFromHistory = (phrase: string) => {
    saveSearch(phrase);
    setSearchImmediate(phrase);
  };

  const seeMore = (_category: SearchCategory) => {
    scrollView.current?.scrollTo({y: 0});
    setCategory(_category);
    saveSearch();
  };

  const onPlay = useCallback(
    (track: ITrack) => {
      dispatch(
        playNewQueue({
          trackId: track.id,
          trackIds: tracks.map(({id}) => id),
          context: {
            titleId: 'queue.searchResults',
            source: 'Search',
            type: PlayContextType.search,
          },
        }),
      );
    },
    [tracks],
  );

  const renderContent = () => {
    const visibleSections = [
      artists.length,
      tracks.length,
      playlists.length,
      users.length,
    ].filter(l => l > 0).length;
    const isEmpty = visibleSections === 0;
    const visibleItems = getVisibleItemsCount(visibleSections, isMobile);
    const isSearching =
      areTracksFetching ||
      areArtistsFetching ||
      arePlaylistsFetching ||
      areUsersFetching;

    if (!searchDebounced && searchHistory.length === 0) {
      return <Text align="center" id="search.initialMessage" />;
    }

    if (!searchDebounced) {
      return (
        <SearchHistory
          history={searchHistory}
          clearHistory={() => dispatch(clearSearchHistory())}
          selectItem={selectSearchFromHistory}
          removeItem={phrase => dispatch(removeSearchPhrase(phrase))}
        />
      );
    }

    if (isEmpty && isSearching) {
      return <ScreenLoader />;
    }

    if (isEmpty) {
      return (
        <Space mt="s">
          <Text id="search.empty" align="center" />
        </Space>
      );
    }

    return (
      <View style={style.content}>
        <SearchSection
          category="tracks"
          selectedCategory={category}
          items={tracks}
          titleId="search.tracks"
          onSeeMore={seeMore}
          renderItem={track => (
            <TrackCard
              onPlay={onPlay}
              isActive={isTrackActive(track.id)}
              track={track}
              showArtist
              onPress={() => saveSearch(track.title)}
            />
          )}
          keyExtractor={track => track.id}
          visibleItems={visibleItems}
          isSearching={isSearching}
        />

        <SearchSection
          category="artists"
          selectedCategory={category}
          items={artists}
          titleId="search.artists"
          onSeeMore={seeMore}
          renderItem={artist => (
            <ArtistCard
              artist={artist}
              onPress={() => saveSearch(artist.name)}
            />
          )}
          keyExtractor={artist => artist.id}
          visibleItems={visibleItems}
          isSearching={isSearching}
        />

        <SearchSection
          category="playlists"
          selectedCategory={category}
          items={playlists}
          titleId="search.playlists"
          onSeeMore={seeMore}
          renderItem={playlist => (
            <PlaylistCard
              playlist={playlist}
              onPress={() => saveSearch(playlist.title)}
            />
          )}
          keyExtractor={playlist => playlist.id}
          visibleItems={visibleItems}
          isSearching={isSearching}
        />

        <SearchSection
          category="users"
          selectedCategory={category}
          items={users}
          titleId="search.collectors"
          onSeeMore={seeMore}
          renderItem={user => (
            <UserCard
              user={user}
              onPress={() => saveSearch(getUserName(user) || searchDebounced)}
            />
          )}
          keyExtractor={user => user.id}
          visibleItems={visibleItems}
          isSearching={isSearching}
        />
      </View>
    );
  };

  return (
    <Screen>
      <KeyboardView iosBehavior="default">
        <SearchHeader
          search={search}
          setSearch={setSearch}
          clearSearch={() => {
            clearSearch();
            setCategory(undefined);
          }}
        />

        <ScrollView
          ref={scrollView}
          style={style.scrollView}
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="on-drag"
          showsVerticalScrollIndicator={false}
          onTouchEnd={() => Keyboard.dismiss()}
          contentContainerStyle={style.scrollViewContent}>
          {!!searchDebounced && (
            <SearchCategories
              categories={searchCategories}
              setCategory={setCategory}
              selectedCategory={category}
            />
          )}
          {renderContent()}
        </ScrollView>
      </KeyboardView>
    </Screen>
  );
};

export default Search;
