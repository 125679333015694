import {useMemo, useState} from 'react';

export const useUsername = () => {
  const [username, setUsername] = useState('');

  const isUsernameValid = useMemo(
    () => !username.includes('.eth') && !username.includes('.lens'),
    [username],
  );

  return {
    username,
    setUsername,
    isUsernameValid,
  };
};
