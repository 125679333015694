import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

const CARD_HEIGHT = 50;

export const styles = createThemedStyles(theme => ({
  tiles: {
    alignSelf: 'stretch',
    borderRadius: CARD_HEIGHT / 2,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    backgroundColor: theme.colors.background,
  },
  userTile: {
    backgroundColor: theme.colors.backgroundLight,
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    height: CARD_HEIGHT,
    borderRadius: CARD_HEIGHT / 2,
    marginTop: -2,
    marginHorizontal: -1,
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing.xs,
    paddingHorizontal: spacing.xs,
  },
  trackTileWrapper: {
    padding: spacing.xs,
  },
  trackTile: {
    borderWidth: 1,
    borderColor: theme.colors.borderColor,
    height: CARD_HEIGHT,
    borderRadius: CARD_HEIGHT / 2,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.backgroundLight,
    overflow: 'hidden',
  },
  artwork: {
    height: '100%',
    aspectRatio: 1,
  },
  trackInfo: {
    flex: 1,
    paddingHorizontal: spacing.xs,
    gap: spacing.xxs,
  },
  trackTitleRow: {
    flexDirection: 'row',
    gap: spacing.xxs / 2,
    alignItems: 'center',
  },
}));
