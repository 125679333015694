import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  content: {
    padding: spacing.s + spacing.xs,
    gap: spacing.s + spacing.xs,
  },
  error: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xs,
  },
  loaderWrapper: {
    paddingVertical: spacing.xxl,
  },
  warningContainer: {
    borderRadius: theme.box.borderRadius,
    padding: spacing.s,
    backgroundColor: theme.colors.background,
    gap: spacing.xs * 1.5,
  },
  warningHeader: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing.xxs,
  },
}));
