import React, {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {Text, TextProps as RNTextProps} from 'react-native';

import {useThemedStyles, useTheme} from '@/theme';
import {Color} from '@/types/theme';
import {isIOS, isWeb} from '@/utils/platform';

import {styles} from './Text.style';

export type TTextValues = {
  [value: string]: string | number | ReactNode | undefined;
};

export type TextSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export type TextProps = RNTextProps & {
  id?: string;
  children?: React.ReactNode;
  size?: TextSize;
  weight?: 'light' | 'regular' | 'bold' | 'semibold';
  align?: 'left' | 'right' | 'center';
  uppercase?: boolean;
  header?: boolean;
  underline?: boolean;
  color?: Color;
  customColor?: string;
  lineThrough?: boolean;
  flex?: boolean;
  values?: TTextValues;
  secondary?: boolean;
};

const CustomText: React.FC<TextProps> = ({
  id = '',
  size = 's',
  uppercase = false,
  style = {},
  color = 'textColor',
  customColor,
  lineThrough = false,
  children = null,
  header = false,
  weight = 'regular',
  underline = false,
  flex = false,
  align = 'left',
  values,
  adjustsFontSizeToFit,
  secondary = false,
  ...rest
}) => {
  const styleSheet = useThemedStyles(styles);
  const theme = useTheme();
  const textStyles = [
    styleSheet.TEXT,
    // @ts-ignore
    styleSheet[`${header ? 'HEADER' : 'TEXT'}_${size.toUpperCase()}`],
    // @ts-ignore
    weight && styleSheet[`TEXT_${weight.toUpperCase()}`],
    lineThrough && styleSheet.lineThrough,
    uppercase && styleSheet.TEXT_UPPERCASE,
    underline && styleSheet.TEXT_UNDERLINE,
    {
      color: customColor || theme.colors[color],
      textAlign: align,
    },
    secondary && styleSheet.TEXT_SECONDARY,
    flex && {flex: 1},
    style,
  ];
  return (
    <Text
      style={textStyles}
      selectable={isWeb}
      adjustsFontSizeToFit={adjustsFontSizeToFit && isIOS}
      {...rest}>
      {id ? <FormattedMessage id={id} values={values} /> : children}
    </Text>
  );
};

export default CustomText;
