import React, {FC} from 'react';
import {TouchableOpacity, View} from 'react-native';

import Icon from '@/components/Icon/Icon';
import Text from '@/components/Text/Text';
import {useThemedStyles} from '@/theme';
import {PlaylistMode} from '@/types/playlists';

import {styles} from './PlaylistModeTile.style';

export interface IPlaylistModeTileProps {
  mode: PlaylistMode;
  onPress: (mode: PlaylistMode) => void;
  selected: boolean;
  iconName: string;
  titleId: string;
  descriptionId: string;
}

const PlaylistModeTile: FC<IPlaylistModeTileProps> = ({
  mode,
  onPress,
  selected,
  iconName,
  titleId,
  descriptionId,
}) => {
  const style = useThemedStyles(styles);

  return (
    <TouchableOpacity
      disabled={selected}
      onPress={() => onPress(mode)}
      style={[style.container, selected && style.container_selected]}>
      <Icon
        name={iconName}
        provider="custom"
        size={48}
        style={style.tileIcon}
      />
      <View style={style.tileWrapper}>
        <View style={[style.tile, selected && style.tile_selected]}>
          <View style={style.title}>
            <Text id={titleId} weight="semibold" color="invertedTextColor" />
          </View>
          <View style={style.description}>
            <Text id={descriptionId} size="xs" />
          </View>
        </View>
        {selected && (
          <View style={style.selectedIndicator}>
            <Icon provider="custom" name="checked" />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default PlaylistModeTile;
