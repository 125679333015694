import {RouteProp, useRoute} from '@react-navigation/native';
import React, {FC, useCallback} from 'react';

import AsyncContent from '@/components/AsyncContent';
import Header from '@/components/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen';
import {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import TrackCard from '@/components/TrackCard/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {useIsTrackActive} from '@/hooks/useIsTrackActive';
import {useAppDispatch} from '@/hooks/useRedux';
import {useGenreTracksQuery} from '@/queries/tracks';
import {playNewQueue} from '@/store/player';
import {ITrack, PlayContextType} from '@/types/common';
import {MainStackParams, Routes} from '@/types/routes';

const Genre: FC = () => {
  const {scrollPosition, onScroll} = useAnimatedHeader();
  const dispatch = useAppDispatch();

  const {
    params: {genre},
  } = useRoute<RouteProp<MainStackParams, Routes.Genre>>();

  const {tracks, query} = useGenreTracksQuery(genre);

  const play = useCallback(
    (track?: ITrack) => {
      if (!tracks) {
        return;
      }

      dispatch(
        playNewQueue({
          trackId: track?.id,
          trackIds: tracks.map(t => t.id),
          context: {
            source: 'Genre',
            type: PlayContextType.genre,
            id: genre,
            title: genre,
          },
        }),
      );
    },
    [genre, tracks],
  );

  const {isTrackActive} = useIsTrackActive(PlayContextType.genre, genre);

  return (
    <Screen>
      <Header
        showBack
        title={genre}
        scrollPosition={scrollPosition}
        mockTitle
      />
      <AsyncContent
        data={tracks}
        isLoading={query.isPending}
        renderContent={genreTracks => (
          <InfinityList
            data={genreTracks}
            itemSize={TRACK_CARD_HEIGHT}
            onScroll={onScroll}
            extraData={isTrackActive}
            keyExtractor={track => track.id}
            renderItem={({item: track}) => (
              <TrackCard
                track={track}
                isActive={isTrackActive(track.id)}
                onPlay={play}
                showArtist
              />
            )}
            fetchNextPage={query.fetchNextPage}
            hasNextPage={query.hasNextPage}
            isFetchingNextPage={query.isFetchingNextPage}
          />
        )}
      />
    </Screen>
  );
};

export default Genre;
