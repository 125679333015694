import {setRemoteHandler} from '@/services/trackPlayer/mediaSession';
import {TrackPlayer} from '@/services/trackPlayer/trackPlayer.web';
import {AppStore} from '@/store';
import {
  handlePlaybackError,
  onTrackEnded,
  pause,
  playNextTrack,
  playPreviousTrack,
  resume,
  seekTo,
  setIsBuffering,
  stopPlayer,
} from '@/store/player';

export const registerPlayer = (store: AppStore) => {
  TrackPlayer.addEventListener('ended', () => {
    store.dispatch(onTrackEnded());
  });
  TrackPlayer.addEventListener('waiting', () => {
    store.dispatch(setIsBuffering(true));
  });
  TrackPlayer.addEventListener('playing', () => {
    store.dispatch(setIsBuffering(false));
  });
  TrackPlayer.addEventListener('error', () => {
    store.dispatch(handlePlaybackError());
  });

  TrackPlayer.addEventListener('play', () => {
    setRemoteHandler('play', () => store.dispatch(resume()));
    setRemoteHandler('pause', () => store.dispatch(pause()));
    setRemoteHandler('stop', () => store.dispatch(stopPlayer()));
    setRemoteHandler('previoustrack', () =>
      store.dispatch(playPreviousTrack()),
    );
    setRemoteHandler('nexttrack', () => store.dispatch(playNextTrack()));
    setRemoteHandler('seekto', ({seekTime}) => {
      if (typeof seekTime === 'number') {
        store.dispatch(seekTo(seekTime));
      }
    });
  });
};
