import spacing from '@/constants/spacing';
import {createThemedStyles, isThemeSquare} from '@/theme';

const CONTROL_HEIGHT = spacing.xl + spacing.xs;

export const styles = createThemedStyles(theme => {
  const controlBorderRadius = isThemeSquare(theme)
    ? theme.box.borderRadius
    : CONTROL_HEIGHT / 2;

  return {
    controls: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    cardControlWrapper: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardControl: {
      flex: 1,
      height: CONTROL_HEIGHT,
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: theme.colors.borderColor,
      borderWidth: Math.max(1, theme.box.baseBorderWidth),
      borderBottomWidth: Math.max(1, theme.box.borderBottomWidth),
      backgroundColor: theme.colors.backgroundLight,
    },
    cardControlRewind: {
      borderTopLeftRadius: controlBorderRadius,
      borderBottomLeftRadius: controlBorderRadius,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRightWidth: 0,
    },
    cardControlPlay: {
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderRadius: 0,
    },
    cardControlForward: {
      borderTopRightRadius: controlBorderRadius,
      borderBottomRightRadius: controlBorderRadius,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeftWidth: 0,
    },
  };
});
