import {createNativeStackNavigator} from '@react-navigation/native-stack';
import React from 'react';

import Library from '@/screens/Library/Library';
import {LibraryStackParams, Routes} from '@/types/routes';

const Stack = createNativeStackNavigator<LibraryStackParams>();

const LibraryNavigation = () => {
  return (
    <Stack.Navigator
      screenOptions={{headerShown: false}}
      initialRouteName={Routes.Library}>
      <Stack.Screen name={Routes.Library} component={Library} />
    </Stack.Navigator>
  );
};

export default LibraryNavigation;
