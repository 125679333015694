import {useContext} from 'react';

import {CryptoTransferStateContext} from '@/modules/Transfer/CryptoTransfer/CryptoTransferStateProvider';

export const useCryptoTransferState = () => {
  const transferState = useContext(CryptoTransferStateContext);

  if (!transferState) {
    throw new Error(
      'useTransferState must be used inside TransferStateProvider',
    );
  }

  return transferState;
};
