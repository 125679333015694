import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(theme => ({
  footer: {
    paddingTop: spacing.xs + theme.box.borderBottomWidth,
    paddingBottom: spacing.xs,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.invertedBorderColor,
    borderBottomRightRadius: Math.max(
      0,
      theme.box.borderRadius - theme.box.borderBottomWidth,
    ),
    borderBottomLeftRadius: Math.max(
      0,
      theme.box.borderRadius - theme.box.borderBottomWidth,
    ),
  },
}));
