import {RouteProp, useRoute} from '@react-navigation/native';
import React from 'react';

import AsyncContent from '@/components/AsyncContent';
import {UserCard} from '@/components/AvatarCard';
import Header from '@/components/Header';
import InfinityList from '@/components/InfinityList';
import Screen from '@/components/Screen';
import Space from '@/components/Space/Space';
import Text from '@/components/Text/Text';
import {TRACK_CARD_HEIGHT} from '@/components/TrackCard';
import {useAnimatedHeader} from '@/hooks/useAnimatedHeader';
import {usePlaylistContributors} from '@/hooks/usePlaylistContributors';
import {
  usePlaylistByIdQuery,
  usePlaylistCollaboratorsQuery,
} from '@/queries/publicPlaylists';
import {useFullUserByIdQuery} from '@/queries/user';
import CuratorCard from '@/screens/PlaylistCurators/components/CuratorCard';
import {useThemedStyles} from '@/theme';
import {MainStackParams, Routes} from '@/types/routes';

import {styles} from './PlaylistCurators.style';

const PlaylistCurators = () => {
  const style = useThemedStyles(styles);
  const {
    params: {id: playlistId},
  } = useRoute<RouteProp<MainStackParams, Routes.PlaylistCurators>>();
  const {
    playlist,
    query: {isLoading},
  } = usePlaylistByIdQuery(playlistId);
  const {
    user: owner,
    query: {isLoading: isOwnerLoading},
  } = useFullUserByIdQuery(playlist?.collector);
  const collaborators = usePlaylistCollaboratorsQuery(playlist);
  const contributors = usePlaylistContributors(playlist, collaborators);

  const {scrollPosition, onScroll} = useAnimatedHeader();

  return (
    <Screen>
      <Header
        titleId="curators.title"
        titleProps={{size: 's'}}
        scrollPosition={scrollPosition}
        showBack
      />
      <AsyncContent
        isLoading={isLoading}
        data={playlist}
        renderContent={loadedPlaylist => (
          <InfinityList
            data={contributors}
            contentContainerStyle={style.scrollContent}
            itemSize={TRACK_CARD_HEIGHT}
            keyExtractor={item => item.id}
            onScroll={onScroll}
            ListHeaderComponent={
              <>
                <Text id="curators.title" weight="semibold" />
                <Space h="xs" />
                <Text
                  size="xs"
                  id="curators.description"
                  values={{
                    playlist: (
                      <Text weight="semibold" size="xs">
                        {loadedPlaylist.title}
                      </Text>
                    ),
                  }}
                />
                <Space h="s" />

                {owner && (
                  <Space mb="xs">
                    <UserCard
                      user={owner}
                      suffixComponent={<Text id="collaborators.owner" />}
                    />
                  </Space>
                )}
              </>
            }
            renderItem={({item: user}) => (
              <CuratorCard
                user={user}
                contributions={loadedPlaylist.contributions}
              />
            )}
            isFetchingNextPage={
              isOwnerLoading ||
              loadedPlaylist.collaborators?.length !== collaborators.length
            }
          />
        )}
      />
    </Screen>
  );
};

export default PlaylistCurators;
