import {IBox} from '@/types/theme';

export type BoxPack = IBox;

export const BoxPacks = {
  square: {
    borderRadius: 3,
    baseBorderWidth: 1,
    borderRightWidth: 2,
    borderBottomWidth: 3,
    tabBorderRadius: 3,
    cardHorizontalPadding: 10,
    activeBorderWidth: 1,
    activeBorderRightWidth: 2,
    activeBorderBottomWidth: 3,
    layoutBorderWidth: 1,
    layoutBorderRightWidth: 2,
    layoutBorderBottomWidth: 3,
  },
  rounded: {
    borderRadius: 15,
    baseBorderWidth: 1,
    borderRightWidth: 2,
    borderBottomWidth: 4,
    tabBorderRadius: 15,
    cardHorizontalPadding: 10,
    activeBorderWidth: 1,
    activeBorderRightWidth: 2,
    activeBorderBottomWidth: 4,
    layoutBorderWidth: 1,
    layoutBorderRightWidth: 2,
    layoutBorderBottomWidth: 4,
  },
};

export const DefaultBoxPack = BoxPacks.rounded;
