import React, {FC} from 'react';
import {View} from 'react-native';

import StaticImage from '@/components/StaticImage';
import Text from '@/components/Text';
import {useThemedStyles} from '@/theme';
import {ITrack} from '@/types/common';
import {ImageSize} from '@/types/media';
import {getTrackArtistsNames} from '@/utils/tracks';

import {styles} from './TrackInfoCard.style';

interface IProps {
  track: ITrack;
}

const TrackInfoCard: FC<IProps> = ({track}) => {
  const style = useThemedStyles(styles);

  return (
    <View style={style.card}>
      {track.lossyArtworkUrl && (
        <StaticImage
          source={{uri: track.lossyArtworkUrl}}
          style={style.artwork}
          resizeWidth={ImageSize.thumbnail}
        />
      )}
      <View style={style.trackInfo}>
        <Text numberOfLines={1} weight="semibold">
          {track.title}
        </Text>
        <Text numberOfLines={1} size="xs">
          {getTrackArtistsNames(track)}
        </Text>
      </View>
    </View>
  );
};

export default TrackInfoCard;
