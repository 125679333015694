import spacing from '@/constants/spacing';
import {createThemedStyles} from '@/theme';

export const styles = createThemedStyles(() => ({
  scrollContent: {
    flexGrow: 1,
  },
  content: {
    gap: spacing.l,
    alignItems: 'center',
  },
  textsContainer: {
    alignItems: 'stretch',
    gap: spacing.xs,
    paddingHorizontal: spacing.s,
  },
  doneButton: {
    marginTop: spacing.xs,
    marginBottom: spacing.s,
  },
  transactionLinks: {
    alignItems: 'stretch',
    flex: 1,
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
  },
  links: {
    flexDirection: 'row',
    gap: spacing.m,
    alignItems: 'center',
  },
  link: {
    flex: 1,
    alignItems: 'center',
  },
}));
