import React, {FC, ReactNode} from 'react';

import {useResponsive} from '@/hooks/useResponsive';
import {ITheme} from '@/types/theme';

import {ThemeContext} from './context';

interface IProps {
  children: ReactNode;
  theme: ITheme;
}

export const ThemedScreen: FC<IProps> = ({theme, children}) => {
  const {isMobile} = useResponsive();

  if (isMobile) {
    return (
      <ThemeContext.Provider value={{theme}}>{children}</ThemeContext.Provider>
    );
  }
  return <>{children}</>;
};
